import moment from 'moment';
import Moment from 'moment';


// Formats the dates for SQL Queries in Axios get

export function sqlDateFormat(date, timeStart, timeEnd) {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    hours = timeStart,
    minutes = timeEnd;

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [month, day, year].join('/') + ' ' + [hours, minutes].join(':');
}

// Formats customer date range
export function getFormattedDate(date) {
  let d = new Date(date);
  d.setDate(d.getDate() + 1);
  return d.toLocaleDateString();
}

// Returns the start of the current hour

export function currentHourStart() {
  let date = new Date(); //Current date
  let currentHour = date.getHours();
  date.setHours(currentHour);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
}

export function lastBusinessDayStartEnd(){
  let dates = {}
  if(moment().format('dddd') === "Monday"){
    dates = {
      startDate: moment().subtract(3,"days").format('YYYY-MM-DD 00:00'),
      endDate: moment().subtract(1,"days").format('YYYY-MM-DD 23:59'),
    }
  } else if(moment().format('dddd') === "Sunday"){
    dates = {
      startDate: moment().subtract(2,"days").format('YYYY-MM-DD 00:00'),
      endDate: moment().subtract(1,"days").format('YYYY-MM-DD 23:59'),
    }

  } else {
    dates = {
      startDate: moment().subtract(1,"days").format('YYYY-MM-DD 00:00'),
      endDate: moment().subtract(1,"days").format('YYYY-MM-DD 23:59'),
    }
  }
  return dates
}

// Today, Yesterday, Last 7 Days, The Last 365, and The Last 30

export function dayChangeStart(days) {
  let date = new Date(); //Current date
  let minusDays = date.getDate() - days;
  date.setDate(minusDays);

  return sqlDateFormat(date, '00', '00');
}

export function dayChangeEnd(days) {
  let date = new Date(); //Current date
  let minusDays = date.getDate() - days;
  date.setDate(minusDays);

  return sqlDateFormat(date, '23', '59');
}

// Last Week

export function lastWeekStart() {
  let date = new Date(); //Current date
  let firstDay = date.getDay() + 1;
  let firstDate = date.getDate() - 7 - firstDay + 1;
  date.setDate(firstDate);

  return sqlDateFormat(date, '00', '00');
}

export function lastWeekEnd() {
  let date = new Date(); //Current date
  let firstDay = date.getDate() - date.getDay();
  let lastDay = firstDay;

  date.setDate(lastDay);

  return sqlDateFormat(date, '23', '59');
}

// This Week

export function thisWeekStart() {
  let date = new Date(); //Current date
  let firstDay = date.getDay() + 1;
  let firstDate = date.getDate() - firstDay + 1;
  date.setDate(firstDate);

  return sqlDateFormat(date, '00', '00');
}

export function thisWeekEnd() {
  let date = new Date(); //Current date
  let firstDay = date.getDate() - date.getDay();
  let lastDay = firstDay + 7;

  date.setDate(lastDay);

  return sqlDateFormat(date, '23', '59');
}

// Finds the start date of a specific week

export function weekStart(i) {
  let date = new Date(); //Current date
  let firstDay = date.getDay() + 1;
  let firstDate = date.getDate() - firstDay + 1;
  date.setDate(firstDate);
  let week = date.getDate() - i + 7;
  date.setDate(week);

  return sqlDateFormat(date, '00', '00');
}

// Finds the end date of a specific week

export function weekEnd(i) {
  let date = new Date(); //Current date
  let firstDay = date.getDate() - date.getDay();
  let lastDay = firstDay + 7;
  date.setDate(lastDay);
  let week = date.getDate() - i + 7;
  date.setDate(week);

  return sqlDateFormat(date, '23', '59');
}

// Calculates the start date for 6 weeks ago, was used in LineChart component but replaced with weekStart(42)

/*
export function sixWeekStart() {
  let date = new Date(); //Current date
  let firstDay = date.getDay() + 1;
  let firstDate = date.getDate() - 42 - firstDay + 1;
  date.setDate(firstDate);

  return sqlDateFormat(date, '00', '00');
}
*/

// This Month

export function thisMonthEnd() {
  let date = new Date(); //Current date
  let days = new Date(date.getYear(), date.getMonth() + 1, 0).getDate();
  date.setDate(days);

  return sqlDateFormat(date, '23', '59');
}

export function thisMonthStart() {
  let date = new Date(); //Current date
  date.setDate(1);
  return sqlDateFormat(date, '00', '00');
}

// This Year

export function thisYearStart() {
  let date = new Date(); //Current date
  date.setMonth(0);
  date.setDate(1);

  return sqlDateFormat(date, '00', '00');
}

export function thisYearEnd() {
  let date = new Date(); //Current date
  date.setMonth(11);
  date.setDate(31);

  return sqlDateFormat(date, '23', '59');
}

export function currentTime() {
  let d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    hours = d.getHours(),
    minutes = d.getMinutes();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  if (hours.length < 2) hours = '0' + hours;
  if (minutes.length < 2) minutes = '0' + minutes;

  return [month, day, year].join('/') + ' ' + [hours, minutes].join(':');
}

export function dayChangeAdd(days) {
  let date = new Date(); //Current date
  let minusDays = date.getDate() + days;
  date.setDate(minusDays);

  return sqlDateFormat(date, '23', '59');
}

// Calculates how many days from two dates
export function days_between(date1, date2) {
  // To set two dates to two variables
  let dateConv = new Date(date1);
  let dateConv2 = new Date(date2);

  // To calculate the time difference of two dates
  let timeDiff = dateConv.getTime() - dateConv2.getTime();

  // To calculate the no. of days between two dates
  return timeDiff / (1000 * 3600 * 24);
}

// Converts sql date into valid JS date object, then subtract is from the current date, and returns the difference back as a date
export function calcOldestQuoteTime(sqlDate) {
  let quoteDate = new Date(sqlDate);
  quoteDate.setHours(quoteDate.getHours() + 5); // Add 5 hours due to timezone difference
  const currentDate = new Date();
  // Calculations for making seconds into different times
  let diffInSeconds = Math.abs(currentDate - quoteDate) / 1000;
  let days = Math.floor(diffInSeconds / 60 / 60 / 24);
  let hours = Math.floor((diffInSeconds / 60 / 60) % 24);
  let minutes = Math.floor((diffInSeconds / 60) % 60);
  let seconds = Math.floor(diffInSeconds % 60);
  let milliseconds = Math.round(
    (diffInSeconds - Math.floor(diffInSeconds)) * 1000
  );

  if (hours != 0) {
    if (days != 0) {
      return days + ' Days ' + hours + ' Hours ' + minutes + ' Minutes ';
    } else {
      return hours + ' Hours ' + minutes + ' Minutes ';
    }
  } else {
    if (days != 0) {
      return days + ' Days ' + minutes + ' Minutes ';
    } else {
      return minutes + ' Minutes ';
    }
  }
}


export function getTimeSeconds(dtDate) {
  let quoteDate = new Date(dtDate);
  quoteDate.setHours(quoteDate.getHours() + 4); // Add 5 hours due to timezone difference
  const currentDate = new Date();
  // Calculations for making seconds into different times
  let diffInSeconds = Math.abs(currentDate - quoteDate) / 1000;

  return diffInSeconds;
}

export function displayDateRange(e){
  let dates = {}
  if (e.target.value === 'Last Week') {
    dates = {
      startDate: lastWeekStart(),
      endDate: lastWeekEnd(),
    }
  } else if (e.target.value === 'This Week') {
    dates = {
      startDate: thisWeekStart(),
      endDate: thisWeekEnd(),
    }
  } else if (e.target.value === 'This Month') {
    dates = {
      startDate: thisMonthStart(),
      endDate: thisMonthEnd(),
    }
  } else if (e.target.value === 'This Month-to-Date') {
    dates = {
      startDate: thisMonthStart(),
      endDate: dayChangeEnd(0),
    }
  } else if (e.target.value === 'This Year') {
    dates = {
      startDate: thisYearStart(),
      endDate: thisYearEnd(),
    }
  } else if (e.target.value === 'This Year-to-Date') {
    dates = {
      startDate: thisYearStart(),
      endDate: dayChangeEnd(0),
    }
  } else if (e.target.value === 'The Last 365') {
    dates = {
      startDate: dayChangeStart(365),
      endDate: dayChangeEnd(0),
    }
  } else if (e.target.value === 'The Last 30') {
    dates = {
      startDate: dayChangeStart(30),
      endDate: dayChangeEnd(0),
    }
  } else {
    dates = {
      showManualDate: true,
    }
  }

  return dates
}

export function quickTimeChange(math, e, startDate, endDate, timeFrame){
  let start = startDate
  let end = endDate

  let dates = {}

  if (math === 'subtract') {
    if (
      timeFrame === 'Yesterday' ||
      timeFrame === 'Today'
    ) {
      dates = {
        startDate: Moment(start)
          .subtract(1, 'days')
          .format('YYYY-MM-DD 00:00'),
        endDate: Moment(end).subtract(1, 'days').format('YYYY-MM-DD 23:59'),
      }
    } else if (timeFrame === 'Last 7 Days') {
      dates = {
        startDate: Moment(start)
          .subtract(7, 'days')
          .format('YYYY-MM-DD 00:00'),
        endDate: Moment(end).subtract(7, 'days').format('YYYY-MM-DD 23:59'),
      }
    } else if (timeFrame === 'Last Week') {
      dates = {
        startDate: Moment(start)
          .subtract(1, 'weeks')
          .startOf('week')
          .format('YYYY-MM-DD 00:00'),
        endDate: Moment(end)
          .subtract(1, 'weeks')
          .endOf('week')
          .format('YYYY-MM-DD 23:59'),
      }
    } else if (timeFrame === 'This Week') {
      dates = {
        startDate: Moment(start)
          .subtract(1, 'weeks')
          .format('YYYY-MM-DD 00:00'),
        endDate: Moment(end).subtract(1, 'weeks').format('YYYY-MM-DD 23:59'),
      }
    } else if (timeFrame === 'This Month') {
      dates = {
        startDate: Moment(start)
          .subtract(1, 'months')
          .startOf('month')
          .format('YYYY-MM-DD 00:00'),
        endDate: Moment(end)
          .subtract(1, 'months')
          .endOf('month')
          .format('YYYY-MM-DD 23:59'),
      }
    } else if (timeFrame === 'This Month-to-Date') {
      dates = {
        startDate: Moment(start)
          .subtract(1, 'months')
          .startOf('month')
          .format('YYYY-MM-DD 00:00'),
        endDate: Moment(end).subtract(1, 'months').format('YYYY-MM-DD 23:59'),
      }
    } else if (timeFrame === 'This Year') {
      dates = {
        startDate: Moment(start)
          .subtract(1, 'years')
          .startOf('year')
          .format('YYYY-MM-DD 00:00'),
        endDate: Moment(end)
          .subtract(1, 'years')
          .endOf('year')
          .format('YYYY-MM-DD 23:59'),
      }
    } else if (timeFrame === 'This Year-to-Date') {
      dates = {
        startDate: Moment(start)
          .subtract(1, 'years')
          .startOf('year')
          .format('YYYY-MM-DD 00:00'),
        endDate: Moment(end).subtract(1, 'years').format('YYYY-MM-DD 23:59'),
      }
    } else if (timeFrame === 'The Last 365') {
      dates = {
        startDate: Moment(start)
          .subtract(1, 'years')
          .format('YYYY-MM-DD 00:00'),
        endDate: Moment(end).subtract(1, 'years').format('YYYY-MM-DD 23:59'),
      }
    } else if (timeFrame === 'The Last 30') {
      dates = {
        startDate: Moment(start)
          .subtract(30, 'days')
          .format('YYYY-MM-DD 00:00'),
        endDate: Moment(end).subtract(30, 'days').format('YYYY-MM-DD 23:59'),
      }
    } else {
      let days = end.diff(start, 'days');
      let startDate = Moment(start)
        .subtract(days, 'days')
        .format('YYYY-MM-DD 00:00');
      let endDate = Moment(end)
        .subtract(days, 'days')
        .format('YYYY-MM-DD 23:59');
      dates = {
        startDate: startDate,
        endDate: endDate,
      }
    }
  }

  if (math === 'add') {
    let start = Moment(this.state.startDate);
    let end = Moment(this.state.endDate);

    if (
      timeFrame === 'Yesterday' ||
      timeFrame === 'Today'
    ) {
      dates = {
        startDate: Moment(start).add(1, 'days').format('YYYY-MM-DD 00:00'),
        endDate: Moment(end).add(1, 'days').format('YYYY-MM-DD 23:59'),
      }
    } else if (timeFrame === 'Last 7 Days') {
      dates = {
        startDate: Moment(start).add(7, 'days').format('YYYY-MM-DD 00:00'),
        endDate: Moment(end).add(7, 'days').format('YYYY-MM-DD 23:59'),
      }
    } else if (timeFrame === 'Last Week') {
      dates = {
        startDate: Moment(start)
          .add(1, 'weeks')
          .startOf('week')
          .format('YYYY-MM-DD 00:00'),
        endDate: Moment(end)
          .add(1, 'weeks')
          .endOf('week')
          .format('YYYY-MM-DD 23:59'),
      }
    } else if (timeFrame === 'This Week') {
      dates = {
        startDate: Moment(start).add(1, 'weeks').format('YYYY-MM-DD 00:00'),
        endDate: Moment(end).add(1, 'weeks').format('YYYY-MM-DD 23:59'),
      }
    } else if (timeFrame === 'This Month') {
      dates = {
        startDate: Moment(start)
          .add(1, 'months')
          .startOf('month')
          .format('YYYY-MM-DD 00:00'),
        endDate: Moment(end)
          .add(1, 'months')
          .endOf('month')
          .format('YYYY-MM-DD 23:59'),
      }
    } else if (timeFrame === 'This Month-to-Date') {
      dates = {
        startDate: Moment(start)
          .add(1, 'months')
          .startOf('month')
          .format('YYYY-MM-DD 00:00'),
        endDate: Moment(end).add(1, 'months').format('YYYY-MM-DD 23:59'),
      }
    } else if (timeFrame === 'This Year') {
      dates = {
        startDate: Moment(start)
          .add(1, 'years')
          .startOf('year')
          .format('YYYY-MM-DD 00:00'),
        endDate: Moment(end)
          .add(1, 'years')
          .endOf('year')
          .format('YYYY-MM-DD 23:59'),
      }
    } else if (timeFrame === 'This Year-to-Date') {
      dates = {
        startDate: Moment(start)
          .add(1, 'years')
          .startOf('year')
          .format('YYYY-MM-DD 00:00'),
        endDate: Moment(end).add(1, 'years').format('YYYY-MM-DD 23:59'),
      }
    } else if (timeFrame === 'The Last 365') {
      dates = {
        startDate: Moment(start).add(1, 'years').format('YYYY-MM-DD 00:00'),
        endDate: Moment(end).add(1, 'years').format('YYYY-MM-DD 23:59'),
      }
    } else if (timeFrame === 'The Last 30') {
      dates = {
        startDate: Moment(start).add(30, 'days').format('YYYY-MM-DD 00:00'),
        endDate: Moment(end).add(30, 'days').format('YYYY-MM-DD 23:59'),
      }
    } else {
      let days = end.diff(start, 'days');
      let startDate = Moment(start)
        .add(days, 'days')
        .format('YYYY-MM-DD 00:00');
      let endDate = Moment(end).add(days, 'days').format('YYYY-MM-DD 23:59');
      dates = {
        startDate: startDate,
        endDate: endDate,
      }
    }
  }

  return dates
}