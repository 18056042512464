import React, { Component } from 'react';
import { calcOpenInstallChartData, matchQuotesAndNotes, matchQuotesAndPOLines, matchQuotesAndPackages } from '../../../assets/functions/process/cam';
import OpenInstallsHeader from './OpenInstalls/OpenInstallsHeader';
import OpenInstallsTable from './OpenInstalls/OpenInstallsTable';
import { getSQLData, setSQLData } from '../../../assets/functions/fetch';
import { nullDateCheck } from '../../../assets/functions/system';
import { Wrapper } from '../../styled/ui';
import HideShowSubTitle from '../../misc/HideShowSubTitle';
import { sendEmail } from '../../../assets/functions/email';
import moment from 'moment';
import NewOrders from './OpenInstalls/NewOrders';
import NeedHardware from './OpenInstalls/NeedHardware';
import NeedShipDate from './OpenInstalls/NeedShipDate';
import OpenInstallsv2 from './OpenInstalls/OpenInstallsv2';
import ReadyToStart from './OpenInstalls/ReadyToStart';
import InhouseFuture from './OpenInstalls/InhouseFuture';
import ContractorFuture from './OpenInstalls/ContractorFuture';
import ToBeShipped from './OpenInstalls/ToBeShipped';
import ContractorOngoing from './OpenInstalls/ContractorOngoing';
import InhouseOngoing from './OpenInstalls/InhouseOngoing';
import ErrorInstalls from './OpenInstalls/ErrorInstalls';
import InTransit from './OpenInstalls/InTransit';

export default class OpenInstalls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      hideAll: false,
      showAll: false,
      viewing: "New Orders",
    };
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleDateSubmit = this.handleDateSubmit.bind(this)
    this.updateAsSoldMargin = this.updateAsSoldMargin.bind(this)
    this.handleUpdateTag = this.handleUpdateTag.bind(this)
    this.handleSaveTag = this.handleSaveTag.bind(this)
    this.handleHideShow = this.handleHideShow.bind(this)
    this.handleShowPO = this.handleShowPO.bind(this)
    this.handleSetLabor = this.handleSetLabor.bind(this)
    this.handleConfirmShipment = this.handleConfirmShipment.bind(this)
    this.setLaborOnly = this.setLaborOnly.bind(this)
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {

    try {
      const fetch = await getSQLData("cam/openInstalls", {})
      const fetchPOs = await getSQLData("cam/openInstallPOLines", {})
      const fetchInstallNotes = await getSQLData("cam/openInstallNotes", {})
      const fetchPackages = await getSQLData("cam/openInstallPackages", {})
      
      if(fetch && fetchPOs && fetchInstallNotes && fetchPackages){

        let data = fetch.data.data[0].recordset  
        let POData = fetchPOs.data.data[0].recordset
        let quoteNotes = fetchInstallNotes.data.data[0].recordset
        let packages = fetchPackages.data.data[0].recordset
        
        this.setState({
          data: data,
          POData: POData,
          quoteNotes: quoteNotes,
          packages: packages,
        }, () => this.processData())
      }
         
    } catch (error) {
      console.error(error);
    }
  }

  processData() {
    let data = this.state.data
    let quoteNotes = this.state.quoteNotes
    let POData = this.state.POData
    let packages = this.state.packages

    let chartData = calcOpenInstallChartData(data, 20)
    
    data = matchQuotesAndPOLines(data, POData)
    data = matchQuotesAndNotes(data, quoteNotes)
    data = matchQuotesAndPackages(data, packages)

    let newOrders = []
    let needHardware = []
    let needShipDate = []
    let toBeShipped = []
    let inTransit = []
    let contractorOngoing = []
    let contractorFuture = []
    let inhouseOngoing = []
    let inhouseFuture = []
    let errorInstall = []

    for(let i=0; i<data.length; i++){
      data[i].repNotes = []
      data[i].nonRepNotes = []
      for(let j=0; j<data[i].notes.length; j++){
        if(data[i].notes[j].nRep === data[i].nRep){
          data[i].repNotes.push(data[i].notes[j])
        } else {
          data[i].nonRepNotes.push(data[i].notes[j])
        }
      }

      data[i].nMargin = data[i].nQuoteTotal - data[i].POTotal
      data[i].index = i

      if(!data[i].nAsSoldMargin || (!data[i].dtHardwareExpected && !data[i].bLaborOnly)){newOrders.push(data[i]); continue;}
      if(!data[i].dtHardwareSetup && !data[i].bLaborOnly){needHardware.push(data[i]); continue;}
      if(!data[i].nLaborLines > 0 && !data[i].dtShipDate && !data[i].bLaborOnly){needShipDate.push(data[i]); continue;}
      if(data[i].outbound.length === 0 && !data[i].bLaborOnly && (!data[i].nLaborLines > 0)){toBeShipped.push(data[i]); continue;}
      if(data[i].outbound.length > data[i].outboundReceived){inTransit.push(data[i]); continue;}
      if(moment(data[i].dtTargetStart).format()>moment().format() && (!data[i].nLaborLines > 0)){contractorFuture.push(data[i]); continue;}
      if(!data[i].dtComplete && (!data[i].nLaborLines > 0)){contractorOngoing.push(data[i]); continue;}
      if(moment(data[i].dtTargetStart).format()>moment().format()){inhouseFuture.push(data[i]); continue;}
      if(!data[i].dtComplete){inhouseOngoing.push(data[i]); continue;}
      errorInstall.push(data[i])

    }

    toBeShipped = toBeShipped.sort((a,b) => a.dtShipDate > b.dtShipDate ? 1 : a.dtShipDate < b.dtShipDate ? -1 : 0)
    inhouseFuture = inhouseFuture.sort((a,b) => a.dtTargetStart > b.dtTargetStart ? 1 : a.dtTargetStart < b.dtTargetStart ? -1 : 0)
    needHardware = needHardware.sort((a,b) => a.dtHardwareExpected > b.dtHardwareExpected ? 1 : a.dtHardwareExpected < b.dtHardwareExpected ? -1 : 0)

    this.setState({
      chartData: chartData,
      data: data,
      newOrders: newOrders,
      needHardware: needHardware,
      needShipDate: needShipDate,
      toBeShipped: toBeShipped,
      inTransit: inTransit,
      contractorFuture: contractorFuture,
      contractorOngoing: contractorOngoing,
      inhouseFuture: inhouseFuture,
      inhouseOngoing: inhouseOngoing,
      errorInstall: errorInstall,
      loaded: true,
    })
  }

  handleDateChange = (event, id, paramName) => {
    console.log(event.target.value, id, paramName)
    let data = this.state.data
    data[id][paramName] = event.target.value


    this.setState({data: data})
  };

  handleDateSubmit = async(date, id, type) => {
    const queryLookup = {
      dtComplete: "cam/setQuoteCompleteDate",
      dtTargetComplete: "cam/setQuoteTargetComplete",
      dtTargetStart: "cam/setQuoteTargetStart",
      dtShipDate: "cam/setShipDate",
      dtHardwareExpected: "cam/setHardwareExpected",
      dtHardwareSetup: "cam/setHardwareSetup",
    }

    try {
      const fetch = await setSQLData(queryLookup[type], {
        quoteNum: id,
        date: nullDateCheck(date),
      })
      
      if(fetch){
        this.fetchData();
      }
      
    } catch (error) {
      console.error(error);
    }
  }

  updateAsSoldMargin = async(value, id) => {
    try {
      const fetch = await setSQLData("cam/setAsSoldMargin", {
        quoteNum: id,
        margin: value,
      })

      if(fetch){
        this.fetchData();
      }

    } catch (error){

    }
  }

  handleUpdateTag(getTag, getIndex) {
    let data = this.state.data

    data[getIndex].sQuoteTags = getTag
    data[getIndex].changed = true

    console.log(data,getTag, getIndex)

    this.setState({data: data})
  }

  handleSubmitDate = async (date, id) => {
    try {
      const fetch = await setSQLData("cam/setQuoteCompleteDate", {
        quoteNum: id,
        date: nullDateCheck(date),
      })
      
      if(fetch){
        this.fetchData();
      }
      
    } catch (error) {
      console.error(error);
    }
  };
  handleTargetSubmitDate = async (date, id) => {
    try {
      const fetch = await setSQLData("cam/setQuoteTargetComplete", {
        quoteNum: id,
        date: nullDateCheck(date),
      })
      
      if(fetch){
        this.fetchData();
      }
      
    } catch (error) {
      console.error(error);
    }
  };
  handleSaveTag = async (tag, id) => {
    try {
      const fetch = await setSQLData("cam/setQuoteTag", {
        quoteNum: id,
        quoteTag: tag,
      })
      
      if(fetch){
        this.fetchData();
      }
      
    } catch (error) {
      console.error(error);
    }
  };

  handleHideShow(){
    this.setState({hideAll: !this.state.hideAll})
  }

  handleShowPO(index){
    let data = this.state.data
    data[index].showPOs = !data[index].showPOs
    this.setState({data: data})
  }

  handleSetLabor = async (quoteIndex, lineIndex) => {
    let lineID = this.state.data[quoteIndex].POLines[lineIndex].LineID
    let newValue = (this.state.data[quoteIndex].POLines[lineIndex].nItem === 832 ? null : 832)

    console.log(quoteIndex)

    try {
      const fetch = await setSQLData("cam/setPOLineItem", {
        lineID: lineID,
        itemNum: newValue,
      })
      
      if(fetch){
        this.fetchData();
      }
      
    } catch (error) {
      console.error(error);
    }
  }

  handleConfirmShipment = async (tracking) => {
    try {
      const fetch = await setSQLData("logistics/trackingCompleteStatus", {
        trackingID: tracking.Id,
        trackingStatus: tracking.bCompleted ? 0 : 1,
      })

      if(fetch){
        this.fetchData()
      }
    } catch (error){
      console.log(error)
    }
  }
  setLaborOnly = async (quote) => {
    try {
      const fetch = await setSQLData("cam/setLaborOnly", {
        quoteNum: quote.id,
        laborOnly: quote.bLaborOnly ? 0 : 1,
      })

      if(fetch){
        this.fetchData()
      }
    } catch (error){
      console.log(error)
    }
  }

  render() {
    let newOrders = this.state.newOrders
    let showAll = this.state.showAll
    return (
      <>
      {this.state.loaded && (
        <>
        
      <Wrapper amount={this.state.data.length}>
        {!this.state.hideAll && (
          <>
            <div>
              {(this.state.newOrders.length > 0 || this.state.showAll) && (<button onClick={()=>this.setState({viewing: "New Orders"})}>New Orders ({newOrders.length})</button>)}
              {(this.state.needHardware.length > 0 || this.state.showAll) && (<button onClick={()=>this.setState({viewing: "Need Hardware"})}>Need Hardware ({this.state.needHardware.length})</button>)}
              {(this.state.needShipDate.length > 0 || this.state.showAll) && (<button onClick={()=>this.setState({viewing: "Need Ship Date"})}>Need Ship Date ({this.state.needShipDate.length})</button>)}
              {(this.state.toBeShipped.length > 0 || this.state.showAll) && (<button onClick={()=>this.setState({viewing: "To Be Shipped"})}>To Be Shipped ({this.state.toBeShipped.length})</button>)}
              {(this.state.inTransit.length > 0 || this.state.showAll) && (<button onClick={()=>this.setState({viewing: "In Transit"})}>In Transit ({this.state.inTransit.length})</button>)}
              {(this.state.contractorOngoing.length > 0 || this.state.showAll) && (<button onClick={()=>this.setState({viewing: "Contractor - Open Installs"})}>Contractor - Open Installs ({this.state.contractorOngoing.length})</button>)}
              {(this.state.contractorFuture.length > 0 || this.state.showAll) && (<button onClick={()=>this.setState({viewing: "Contractor - Future Installs"})}>Contractor - Future Installs ({this.state.contractorFuture.length})</button>)}
              {(this.state.inhouseOngoing.length > 0 || this.state.showAll) && (<button onClick={()=>this.setState({viewing: "In House - Open Installs"})}>In House - Open Installs ({this.state.inhouseOngoing.length})</button>)}
              {(this.state.inhouseFuture.length > 0 || this.state.showAll) && (<button onClick={()=>this.setState({viewing: "In House - Future Installs"})}>In House - Future Installs ({this.state.inhouseFuture.length})</button>)}
              {(this.state.errorInstall.length > 0 || this.state.showAll) && (<button onClick={()=>this.setState({viewing: "Error"})}>Error ({this.state.errorInstall.length})</button>)}
            </div>
            <div>
              <button onClick={()=>this.setState({showAll: !showAll})}>Show All</button>
            </div>

            {this.state.viewing === "New Orders" && (
              <NewOrders
                data={this.state.newOrders}
                handleDateChange={this.handleDateChange}
                handleDateSubmit={this.handleDateSubmit}
                handleUpdateTag={this.handleUpdateTag}
                handleSaveTag={this.handleSaveTag}
                handleShowPO={this.handleShowPO}
                handleSetLabor={this.handleSetLabor}
                updateAsSoldMargin={this.updateAsSoldMargin}
                setLaborOnly={this.setLaborOnly}
              />
            )}
            {this.state.viewing === "Need Hardware" && (
              <NeedHardware
                data={this.state.needHardware}
                handleDateChange={this.handleDateChange}
                handleDateSubmit={this.handleDateSubmit}
                handleUpdateTag={this.handleUpdateTag}
                handleSaveTag={this.handleSaveTag}
                handleShowPO={this.handleShowPO}
                handleSetLabor={this.handleSetLabor}
                setLaborOnly={this.setLaborOnly}
              />
            )}
            {this.state.viewing === "Need Ship Date" && (
              <NeedShipDate
                data={this.state.needShipDate}
                handleDateChange={this.handleDateChange}
                handleDateSubmit={this.handleDateSubmit}
                handleUpdateTag={this.handleUpdateTag}
                handleSaveTag={this.handleSaveTag}
                handleShowPO={this.handleShowPO}
                handleSetLabor={this.handleSetLabor}
                setLaborOnly={this.setLaborOnly}
              />
            )}
            {this.state.viewing === "To Be Shipped" && (
              <ToBeShipped
                data={this.state.toBeShipped}
                handleDateChange={this.handleDateChange}
                handleDateSubmit={this.handleDateSubmit}
                handleUpdateTag={this.handleUpdateTag}
                handleSaveTag={this.handleSaveTag}
                handleShowPO={this.handleShowPO}
                handleSetLabor={this.handleSetLabor}
                setLaborOnly={this.setLaborOnly}
              />
            )}
            {this.state.viewing === "In Transit" && (
              <InTransit
                data={this.state.inTransit}
                handleDateChange={this.handleDateChange}
                handleDateSubmit={this.handleDateSubmit}
                handleUpdateTag={this.handleUpdateTag}
                handleSaveTag={this.handleSaveTag}
                handleShowPO={this.handleShowPO}
                handleSetLabor={this.handleSetLabor}
                setLaborOnly={this.setLaborOnly}
              />
            )}
            {this.state.viewing === "Contractor - Open Installs" && (
              <ContractorOngoing
                data={this.state.contractorOngoing}
                handleDateChange={this.handleDateChange}
                handleDateSubmit={this.handleDateSubmit}
                handleUpdateTag={this.handleUpdateTag}
                handleSaveTag={this.handleSaveTag}
                handleShowPO={this.handleShowPO}
                handleSetLabor={this.handleSetLabor}
              />
            )}
            {this.state.viewing === "Contractor - Future Installs" && (
              <ContractorFuture
                data={this.state.contractorFuture}
                handleDateChange={this.handleDateChange}
                handleDateSubmit={this.handleDateSubmit}
                handleUpdateTag={this.handleUpdateTag}
                handleSaveTag={this.handleSaveTag}
                handleShowPO={this.handleShowPO}
                handleSetLabor={this.handleSetLabor}
                handleConfirmShipment={this.handleConfirmShipment}
              />
            )}
            {this.state.viewing === "In House - Open Installs" && (
              <InhouseOngoing
                data={this.state.inhouseOngoing}
                handleDateChange={this.handleDateChange}
                handleDateSubmit={this.handleDateSubmit}
                handleUpdateTag={this.handleUpdateTag}
                handleSaveTag={this.handleSaveTag}
                handleShowPO={this.handleShowPO}
                handleSetLabor={this.handleSetLabor}
              />
            )}
            {this.state.viewing === "In House - Future Installs" && (
              <InhouseFuture
                data={this.state.inhouseFuture}
                handleDateChange={this.handleDateChange}
                handleDateSubmit={this.handleDateSubmit}
                handleUpdateTag={this.handleUpdateTag}
                handleSaveTag={this.handleSaveTag}
                handleShowPO={this.handleShowPO}
                handleSetLabor={this.handleSetLabor}
                handleConfirmShipment={this.handleConfirmShipment}
              />
            )}
            {this.state.viewing === "Error" && (
              <ErrorInstalls
                data={this.state.errorInstall}
                handleDateChange={this.handleDateChange}
                handleDateSubmit={this.handleDateSubmit}
                handleUpdateTag={this.handleUpdateTag}
                handleSaveTag={this.handleSaveTag}
                handleShowPO={this.handleShowPO}
                handleSetLabor={this.handleSetLabor}
              />
            )}
          </>
        )}  

      </Wrapper>
      
      </>
      )}
      </>
    );
  }
}
