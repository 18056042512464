import React, { Component } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStickyNote, faEnvelope, faBuilding, faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { getSQLData, setSQLData } from '../../../assets/functions/fetch';
import EmailDetailsModal from './EmailDetailsModal';
import CompanyDetailsModal from './CompanyDetailsModal';
import { StyledTable, IconButton } from './StyledComponents';
import LogMarketingContactModal from './MarketingContactModal'; // Ensure this is imported

const repNames = {
  5: 'Andy Wendt',
  54: 'Brian Kubala',
  63: 'Hunter Stewart',
  64: 'Jeff Bruck',
  71: 'Jill Staley',
  1: 'John Beagle',
  45: 'Johnny Beagle',
  44: 'Kevin Boles',
  68: 'Kevin Thompson',
  40: 'Kristen Fisher',
  51: 'Mark Gillaugh',
  22: 'Randy Moore',
  27: 'Robert Beagle',
  14: 'Susan Beagle',
  8: 'Tony Cipollone',
};

class ClosedQuotesModal extends Component {
  state = {
    closedQuotes: [],
    notes: {},
    emails: {},
    activeNotes: {},
    activeEmails: {},
    selectedEmail: null,
    selectedCompany: null, // For handling company modal
    showMarketingContactModal: null, // For handling marketing contact modal
  };

  componentDidMount() {
    this.loadClosedQuotes();
  }

  loadClosedQuotes = async () => {
    try {
      const closedQuotesFetch = await getSQLData('logistics/closedQuotes', { numQuotes: 10 });
      if (closedQuotesFetch && closedQuotesFetch.data && closedQuotesFetch.data.data[0]) {
        const closedQuotes = closedQuotesFetch.data.data[0].recordset;
        this.setState({ closedQuotes }, this.fetchAllNotesAndEmails);
      }
    } catch (error) {
      console.error('Error loading closed quotes:', error);
    }
  };

  fetchAllNotesAndEmails = async () => {
    const { closedQuotes } = this.state;
    const quoteIds = closedQuotes.map((q) => q.id);

    try {
      const notesFetch = await getSQLData('logistics/notesClosed', { quoteIds });
      const emailsFetch = await getSQLData('logistics/emailsClosed', { quoteIds });

      const notesRecordset = notesFetch.data.data[0]?.recordset || [];
      const emailsRecordset = emailsFetch.data.data[0]?.recordset || [];

      let notes = {};
      let emails = {};

      closedQuotes.forEach((quote) => {
        notes[quote.id] = notesRecordset.filter((note) => note.nQuote === quote.id);
        emails[quote.id] = emailsRecordset.filter((email) => email.nQuote === quote.id);
      });

      this.setState({ notes, emails });
    } catch (error) {
      console.error('Error fetching notes or emails:', error);
    }
  };

  toggleNotes = (quoteId) => {
    this.setState((prevState) => ({
      activeNotes: {
        ...prevState.activeNotes,
        [quoteId]: !prevState.activeNotes[quoteId],
      },
    }));
  };

  toggleEmails = (quoteId) => {
    this.setState((prevState) => ({
      activeEmails: {
        ...prevState.activeEmails,
        [quoteId]: !prevState.activeEmails[quoteId],
      },
    }));
  };

  openEmailModal = (email) => {
    this.setState({ selectedEmail: email });
  };

  closeEmailModal = () => {
    this.setState({ selectedEmail: null });
  };

  openCompanyModal = (quoteId, companyId, companyName) => {
    this.setState({
      selectedCompany: { quoteId, companyId, companyName },
    });
  };

  closeCompanyModal = () => {
    this.setState({ selectedCompany: null });
  };

  openMarketingContactModal = (nCompany, quoteId) => {
    this.setState({ showMarketingContactModal: { nCompany, quoteId } });
  };

  closeMarketingContactModal = () => {
    this.setState({ showMarketingContactModal: null });
  };

  logMarketingContact = async (quoteId, repId) => {
    const contactData = {
      nRep: repId,
      nContact: quoteId,
      sType: 'Marketing',
      sDisposition: 'Reached Out',
      sNote: 'Marketing contact made via megaphone icon',
    };

    try {
      const response = await setSQLData('logistics/logMarketingContact', contactData);
      if (response.success) {
        console.log(`Marketing contact logged for quote ${quoteId}`);
      } else {
        console.error('Error logging marketing contact:', response.error);
      }
    } catch (error) {
      console.error('Error logging marketing contact:', error);
    }
  };

  render() {
    const { closedQuotes, notes, emails, activeNotes, activeEmails, selectedEmail, selectedCompany, showMarketingContactModal } = this.state;

    return (
      <>
        <StyledTable>
          <thead>
            <tr>
              <th>ID</th>
              <th>Company</th>
              <th>Status</th>
              <th>Closed Date</th>
              <th>Rep</th>
              <th>Notes</th>
              <th>Emails</th>
              <th>Company Details</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {closedQuotes.map((quote, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td>{quote.id}</td>
                  <td>{quote.sCompany}</td>
                  <td>{quote.sStatus}</td>
                  <td>{moment(quote.dtFirstClosed).utcOffset(0).format('MM/DD/YYYY hh:mm A')}</td>
                  <td>{repNames[quote.nRep] || `Rep ID: ${quote.nRep}`}</td>
                  <td>
                    <IconButton onClick={() => this.toggleNotes(quote.id)}>
                      <FontAwesomeIcon icon={faStickyNote} />
                    </IconButton>
                  </td>
                  <td>
                    <IconButton onClick={() => this.toggleEmails(quote.id)}>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </IconButton>
                  </td>
                  <td>
                    <IconButton onClick={() => this.openCompanyModal(quote.id, quote.nCompany, quote.sCompany)}>
                      <FontAwesomeIcon icon={faBuilding} />
                    </IconButton>
                  </td>
                  <td>
                    <IconButton onClick={() => this.openMarketingContactModal(quote.nCompany, quote.id)}>
                      <FontAwesomeIcon icon={faBullhorn} />
                    </IconButton>
                  </td>
                </tr>

                {activeNotes[quote.id] && notes[quote.id] && (
                  <tr>
                    <td colSpan="9">
                      <div>
                        <h4>Notes</h4>
                        {notes[quote.id]
                          .slice()
                          .reverse()
                          .map((note, idx) => {
                            const nAttention = Array.isArray(note.nAttention)
                              ? note.nAttention[0]
                              : note.nAttention;
                            const nAttentionPriority = Array.isArray(note.nAttentionPriority)
                              ? note.nAttentionPriority[0]
                              : note.nAttentionPriority;
                            const attentionRep =
                              nAttention && nAttention !== 0
                                ? repNames[nAttention] || `Rep ID: ${nAttention}`
                                : null;
                            const noteStyle = nAttentionPriority === 1 ? { color: 'darkred' } : {};

                            return (
                              <p key={idx} style={noteStyle}>
                                •{' '}
                                {attentionRep && (
                                  <>
                                    ATTN: <b>{attentionRep}</b>{' '}
                                  </>
                                )}
                                {note.sNote}
                                <small>
                                  <em>
                                    {' '}
                                    {moment(note.dtTimeStamp).utcOffset(0).format('MM/DD/YYYY hh:mm A')}{' '}
                                  </em>
                                  by <b>{repNames[note.nRep] || `Rep ID: ${note.nRep}`}</b>
                                </small>
                              </p>
                            );
                          })}
                      </div>
                    </td>
                  </tr>
                )}

                {activeEmails[quote.id] && emails[quote.id] && (
                  <tr>
                    <td colSpan="9">
                      <div>
                        <h4>Emails</h4>
                        {emails[quote.id]
                          .slice()
                          .reverse()
                          .map((email, idx) => (
                            <div key={idx} onClick={() => this.openEmailModal(email)}>
                              <p>
                                <strong>{email.sSubject}</strong>
                              </p>
                              <p>
                                From: {email.sFrom} | To: {email.sTo}
                              </p>
                              <hr />
                            </div>
                          ))}
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </StyledTable>

        {selectedEmail && <EmailDetailsModal email={selectedEmail} onClose={this.closeEmailModal} />}

        {selectedCompany && (
          <CompanyDetailsModal
            quoteId={selectedCompany.quoteId}
            companyId={selectedCompany.companyId}
            companyName={selectedCompany.companyName}
            onClose={this.closeCompanyModal}
          />
        )}

        {showMarketingContactModal && (
          <LogMarketingContactModal
            nCompany={showMarketingContactModal.nCompany}
            quoteId={showMarketingContactModal.quoteId}
            onClose={this.closeMarketingContactModal}
          />
        )}
      </>
    );
  }
}

export default ClosedQuotesModal;
