import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import { headShake } from 'react-animations';
import MediaQuery from 'react-responsive';
import Moment from 'moment';

const headShakeAnimation = keyframes`${headShake}`;

function leadColor(dtDateStamp) {
  let time = getTimeSeconds(dtDateStamp);

  if (time > 300) {
    return '#ff5959';
  }
}

function getTime(dtDate) {
  let quoteDate = new Date(dtDate);
  quoteDate.setHours(quoteDate.getHours() + 5); // Add 5 hours due to timezone difference, change to 4 during daylight savings
  const currentDate = new Date();
  // Calculations for making seconds into different times
  let diffInSeconds = Math.abs(currentDate - quoteDate) / 1000;
  let days = Math.floor(diffInSeconds / 60 / 60 / 24);
  let hours = Math.floor((diffInSeconds / 60 / 60) % 24);
  let minutes = Math.floor((diffInSeconds / 60) % 60);
  let seconds = Math.floor(diffInSeconds % 60);
  let milliseconds = Math.round(
    (diffInSeconds - Math.floor(diffInSeconds)) * 1000
  );

  if (hours != 0) {
    if (days != 0) {
      return days + 'd ' + hours + 'h ' + minutes + 'm ';
    } else {
      return hours + 'h ' + minutes + 'm ';
    }
  } else {
    if (days != 0) {
      return days + 'd ' + minutes + 'm ';
    } else {
      return minutes + 'm ';
    }
  }
}

function getTimeSeconds(dtDate) {
  let quoteDate = new Date(dtDate);
  quoteDate.setHours(quoteDate.getHours() + 5); // Add 5 hours due to timezone difference
  const currentDate = new Date();
  // Calculations for making seconds into different times
  let diffInSeconds = Math.abs(currentDate - quoteDate) / 1000;

  return diffInSeconds;
}

const Wrapper = styled.a`
  width: 95%;
  position: relative;
  padding: 0.2em;
  margin: 0.2em 0.4em 0.2em 0;
  border-radius: 5px;
  transition: box-shadow 0.6s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  animation: 1s ${headShakeAnimation};
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.1);
  background-color: #fff;

  p {
    padding: 0.3em 0.5em;
    text-align: left;
  }

  p:first-child {
    font-weight: 500;
  }

  &:hover {
    color: #222;
    -webkit-transform: scale(1.015);
    transform: scale(1.015);
  }
`;

const AlertWrapper = styled.div`
  position: absolute;
  right: 15px;
  bottom: 25%;
  font-size: 1.5rem;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-animation-name: hvr-pulse;
  animation-name: hvr-pulse;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
`;

class UnassignedLead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: '',
    };
  }

  render() {
    return (
      <Wrapper
        href={'https://cc.cfrinc.us/quote.asp?id=' + this.props.id}
        target="_blank"
        style={{
          textAlign: 'center',
          backgroundColor: leadColor(this.props.dtDateStamp),
        }}
      >
        <p>
          <i className="fas fa-calendar" style={{ marginRight: '0.4em' }}></i>{' '}
          {Moment(this.props.dtDateStamp).utc().format('MM/DD/YY - hh:mm A')}
        </p>
        <p>
          <i className="fas fa-clock" style={{ marginRight: '0.2em' }}></i>{' '}
          {getTime(this.props.dtDateStamp)} old
        </p>
        <MediaQuery minDeviceWidth={900}>
          <AlertWrapper>
            <i className="fas fa-exclamation"></i>
          </AlertWrapper>
        </MediaQuery>
      </Wrapper>
    );
  }
}

export default UnassignedLead;
