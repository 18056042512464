import React, { Component } from 'react';
import styled from 'styled-components';
import Moment from 'moment';
import { whichRep } from '../../../assets/functions/calculations';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin: 0 auto 0.7em auto;
  width: 85%;
  height: fit-content;
  text-align: center;
  background-color: #f2f5f7;
  border-radius: 5px;
  padding: 0.5em 0 0.7em 0;

  input {
    width: 80%;
    padding: 0.7em;
    margin: 0.5em auto;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  i {
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 1.5rem;
    transition: all 0.2s;
    ${(props) => {
      if (props.nStatus === 1)
        return `
            color: #82c91e;
        `;
      if (props.nStatus === 2)
        return `
            color: red;
        `;
      else {
        return `
            color: #d5dee5;
          }
        `;
      }
    }}
  }

  h4 {
    text-align: left;
    margin: 10px 5%;
    cursor: pointer;

    span {
      font-size: 14px;
      font-weight: 400;
    }
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  div {
    width: 100%;
    margin: 0.5em 0;
  }

  h5 {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    margin: 0.8em 0 0 10%;
  }

  p {
    text-align: left;
    margin: 0.5em 0 0 10%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: top;
`;

const Button = styled.span`
  width: fit-content;
  background-color: #fff;
  margin: 0 0.2em;
  padding: 1em;
  cursor: pointer;
  border: none;
  transition: all 0.2s;
  border-radius: 4px;

  &:hover {
    background-color: #4aabed;
    color: #fff;
  }
`;

export default class Followups extends Component {
  state = { expanded: false };

  handleOnClick = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  render() {
    return (
      <Wrapper nStatus={this.props.nStatus}>
        <h4 onClick={this.handleOnClick}>
          {whichRep(this.props.name)}
          <span>
            {' '}
            {' - '}
            {Moment.utc(this.props.dtDate).format('MM/DD/YYYY hh:mm A')}
          </span>
        </h4>
        {this.props.nStatus === 2 ? (
          <i class="fas fa-times-circle"></i>
        ) : (
          <i class="fas fa-check"></i>
        )}
        <div>
          {this.state.expanded && (
            <InputContainer>
              <div>
                <div>
                  <h5>Date Added</h5>
                  <input
                    type="datetime-local"
                    value={Moment.utc(this.props.dateAdded).format(
                      'YYYY-MM-DDThh:mm'
                    )}
                  />
                </div>
                {this.props.dtCompleted ? (
                  <div>
                    <h5>Date Completed</h5>
                    <input
                      type="datetime-local"
                      value={Moment.utc(this.props.dtCompleted).format(
                        'YYYY-MM-DDThh:mm'
                      )}
                    />
                  </div>
                ) : null}
                <div>
                  <h5>Note</h5>
                  <p>{this.props.sNote}</p>
                </div>
              </div>
              <ButtonContainer>
                <Button>Complete</Button>
                <Button>Closed</Button>
                <Button>Discontinued</Button>
              </ButtonContainer>
            </InputContainer>
          )}
        </div>
      </Wrapper>
    );
  }
}
