import React, { Component } from 'react'
import { setSQLData } from '../../../../assets/functions/fetch';

export default class ImportArchive extends Component {



    handleOnChange = (e) => {
        this.setState({importFile: e.target.files[0]})
      }

      handleOnSubmit = (e) => {
        const fileReader = new FileReader();
        let error = false
        let importError = "None"
        let importData = []
        let importFile = this.state.importFile
    
        fileReader.onload = function (file) {
    
          const text = file.target.result
          const rows = text.split('\n')
          
          for(let i=1; i<rows.length; i++){
            let rawData = rows[i].split(',')
            let entry = {
                nRep: parseInt(rawData[0]),
                nQuote: parseInt(rawData[1]),
                nMargin: parseFloat(rawData[2]),
            }
            importData.push(entry)
        }
    
          function processImportData(index) {
            if (index < importData.length) {
                console.log(importData[index])
              setSQLData("accounting/commission_log_import", importData[index]);

              
              index++;
              setTimeout(() => {
                processImportData(index);
              }, 100);
            }
          }
          
          processImportData(0);
    
            
        }.bind(this)
    
        fileReader.readAsText(importFile)
      }

  render() {
    return (
        <form>
            <input type={"file"} id={"csvFileInput"} accept={".csv"} onChange={this.handleOnChange} />
            <button onClick={(e) => {this.handleOnSubmit(e)}}>Import CSV</button>
        </form>
    )
  }
}
