import React, { Component } from 'react';
import styled from 'styled-components';
import { activeReps, whichRep } from '../../assets/functions/calculations';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1em;

  div:first-child {
    display: flex;
    flex-direction: row;
  }

  span {
    font-size: 1.1rem;
    margin: 0 0.5em;
    color: #c3d1da;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: #444;
    }
  }
`;

const OpenWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5em;
  font-weight: bold;
  border: 3px #c3d1da solid;
  border-radius: 50px;
  margin-right: 1em;
  cursor: default;

  i {
    ${(props) => {
      if (props.status === 'Open')
        return `
            color: #82c91e;
        `;
      if (props.status === 'Closed')
        return `
            color: red;
        `;
      if (props.status === 'Discontinued')
        return `
            color: #999;
        `;
    }}
    margin-right: 0.5em;
  }

  p {
    margin-right: 0.3em;
  }
`;

const PaidWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5em;
  font-weight: bold;
  border: 3px #c3d1da solid;
  border-radius: 50px;
  margin-right: 1em;
  cursor: default;

  i {
    ${(props) => {
      if (props.nPaid)
        return `
            color: #82c91e;
        `;
      if (!props.nPaid)
        return `
            color: red;
        `;
    }}
    margin-right: 0.5em;
  }

  p {
    margin-right: 0.3em;
  }
`;

const AgentContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0.5em auto 0 auto;

  div:first-child {
    display: flex;
    flex-direction: column;
    margin: 0.5em 0.5em 0 0.5em;
  }

  div {
    display: flex;
    flex-direction: column;
    margin: 0.5em 0.5em 0 0.5em;

    select {
      width: 100%;
      padding: 0.2em 0 0.2em 0.5em;
      margin: 0.5em 0em;
      border: 2px solid #e8edf1;
      border-radius: 5px;
    }
  }
`;
export default class QuoteControl extends Component {
  render() {
    let paid;

    if (this.props.nPaid) {
      paid = 'Paid';
    } else {
      paid = 'Unpaid';
    }
    console.log(this.props)

    return (
      <Wrapper>
        <div>
          <OpenWrapper status={this.props.status}>
            <i class="fas fa-circle"></i>
            <p>{this.props.status}</p>
          </OpenWrapper>
          <PaidWrapper nPaid={this.props.nPaid} onClick={()=>this.props.changePaidStatus()}>
            <i class="fas fa-credit-card"></i>
            <p>{paid}</p>
          </PaidWrapper>
          <span className="fa-stack fa-md">
            <i class="fa fa-circle-thin fa-stack-2x"></i>
            <i class="far fa-bookmark fa-stack-1x"></i>
          </span>
          <span className="fa-stack fa-md" onClick={()=>this.props.saveQuote()}>
            <i class="fa fa-circle-thin fa-stack-2x"></i>
            <i class="fas fa-save fa-stack-1x"></i>
          </span>
          <span className="fa-stack fa-md">
            <i class="fa fa-circle-thin fa-stack-2x"></i>
            <i class="fas fa-trash-alt fa-stack-1x"></i>
          </span>
        </div>
        <AgentContainer>
          <div>
            <select 
            id="tta" 
            name="tta" 
            defaultValue={this.props.tta} 
            onChange={(event)=>this.props.update("nRep", parseInt(event.target.value))}
            >
              <option value="Select Rep" hidden>
                Select Rep
              </option>
              {activeReps().map((rep, index)=>(
                <option value={rep.id}>{rep.name}</option>
              ))}
            </select>
          </div>
          <div>
            <select 
            id="tta2" 
            name="tta2" 
            defaultValue={this.props.tta2}
            onChange={(event)=>this.props.update("nRep2", parseInt(event.target.value))}
            >
              <option value="Select Rep" hidden>
                Select Rep
              </option>
              {activeReps().map((rep, index)=>(
                <option value={rep.id}>{rep.name}</option>
              ))}
            </select>
          </div>
          <div>
            <select 
            id="tta3" 
            name="tta3" 
            defaultValue={this.props.tta3}
            onChange={(event)=>this.props.update("nRep3", parseInt(event.target.value))}
            >
              <option value="Select Rep" hidden>
                Select Rep
              </option>
              {activeReps().map((rep, index)=>(
                <option value={rep.id}>{rep.name}</option>
              ))}
            </select>
          </div>
        </AgentContainer>
      </Wrapper>
    );
  }
}
