import React, { Component } from 'react';
import Axios from 'axios';
import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import Moment from 'moment';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin: 1em auto 0.5em auto;
  width: 99%;
  max-width: 800px;
  height: fit-content;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.1);
  text-align: center;
  border-radius: 15px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4aabed;
  color: #fff;
  height: 35px;
  font-weight: bold;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  padding: 1em;

  h4 {
    margin: 0.5em auto 1em auto;
  }

  p {
    margin-bottom: 0.4em;
  }

  span {
    font-weight: bold;
  }
`;

export default class TermsSummary extends Component {
  state = {
    data: [],
    loaded: false,
    showAll: false,
  };

  // Async Axios
  fetchData = async (startDate, endDate) => {
    try {
      const response = await Axios.get('/admin-dashboard/terms-summary', {
        params: {
          startDate: startDate,
          endDate: endDate,
        },
        headers: { 'Content-Type': 'application/json' },
      });
      // // Sets the state using the response data
      this.setState({
        data: response.data.result[0].recordset[0],
        loaded: true,
      });
      console.log(this.state);
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    this.fetchData(this.props.startDate, this.props.endDate);
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.fetchData(this.props.startDate, this.props.endDate);
    }
  }
  render() {
    let body = (
      <Container>
        <div>
          <h4>Credit Card</h4>
          <p>
            <span>Sales:</span> ${this.state.data.creditCardSales}
          </p>
          <p>
            <span>Closed Orders:</span> {this.state.data.creditCardAmount}
          </p>
        </div>
        <div>
          <h4>Net</h4>
          <p>
            <span>Sales:</span> ${this.state.data.creditCardSales}
          </p>
          <p>
            <span>Closed Orders:</span> {this.state.data.netSales}
          </p>
        </div>
        <div>
          <h4>Cash Up Front</h4>
          <p>
            <span>Sales:</span> ${this.state.data.netAmount}
          </p>
          <p>
            <span>Closed Orders:</span> {this.state.data.cashUpFrontAmount}
          </p>
        </div>
      </Container>
    );
    let loader = (
      <Loader
        style={{
          textAlign: 'center',
        }}
        type="ThreeDots"
        color="#00BFFF"
        height={50}
        width={60}
      />
    );
    return (
      <Wrapper>
        <Title>Terms Summary</Title>
        {this.state.loaded ? body : loader}
      </Wrapper>
    );
  }
}
