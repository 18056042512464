import React, { Component } from 'react';
import Axios from 'axios';
import styled from 'styled-components';
import { Bar } from 'react-chartjs-2';
import Loader from 'react-loader-spinner';
import Moment from 'moment-business-days';

const Wrapper = styled.section`
  padding: 5em 0em;
  text-align: center;
  width: 70%;
  margin: auto;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const SubTitle = styled.h2`
  padding: 1em 0;
  text-align: center;
`;

export default class BrandSalesBarChart extends Component {
  state = {
    loaded: false,
    racTarget: 0,
    marginRacTarget: 0,
    racSales: 0,
    racMargin: 0,
    camTarget: 0,
    marginCamTarget: 0,
    camSales: 0,
    camMargin: 0,
    csnTarget: 0,
    marginCsnTarget: 0,
    csnSales: 0,
    csnMargin: 0,
    totalTarget: 0,
    totalMargin: 0,
    marginTotalTarget: 0,
    nRacBeforeTaxSales: 0,
    nRacTotalCost: 0,
    nRacTotalMargin: 0,
    showSalesChart: true,
  };

  fetchData = async (startDate, endDate) => {
    try {
      const response = await Axios.get('/brand-sales-charts', {
        params: {
          startDate: startDate,
          endDate: endDate,
        },
        headers: { 'Content-Type': 'application/json' },
      });
      // Sets the state using the response data
      this.setState({
        racSales: response.data.result[0].recordset[0].racSales,
        camSales: response.data.result[0].recordset[0].camSales,
        csnSales: response.data.result[0].recordset[0].csnSales,
        nRacBeforeTaxSales:
          response.data.result[0].recordset[0].nRacBeforeTaxSales,
        nRacTotalCost: response.data.result[0].recordset[0].nRacTotalCost,
        racMargin:
          response.data.result[0].recordset[0].nRacBeforeTaxSales -
          response.data.result[0].recordset[0].nRacTotalCost,
        camMargin:
          response.data.result[0].recordset[0].nCamBeforeTaxSales -
          response.data.result[0].recordset[0].nCamTotalCost,
        csnMargin:
          response.data.result[0].recordset[0].nCsnBeforeTaxSales -
          response.data.result[0].recordset[0].nCsnTotalCost,
        loaded: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    this.fetchData(this.props.startDate, this.props.endDate);
    let businessDays = Moment(this.props.startDate).businessDiff(
      Moment(this.props.endDate)
    );
    this.setState({
      racTarget: businessDays * 16000,
      camTarget: businessDays * 4000,
      totalTarget: businessDays * 20000,
      marginRacTarget: businessDays * 6000,
      marginCamTarget: businessDays * 1500,
      marginTotalTarget: businessDays * 7500,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState({
        loaded: false,
      });
      this.fetchData(this.props.startDate, this.props.endDate);
      let businessDays = 0;
      if (
        Moment(this.props.startDate).format('l') ===
        Moment(this.props.endDate).format('l')
      ) {
        businessDays = 1;
      } else {
        businessDays = Moment(this.props.startDate).businessDiff(
          Moment(this.props.endDate)
        );
      }
      this.setState({
        racTarget: businessDays * 16000,
        camTarget: businessDays * 4000,
        totalTarget: businessDays * 19000,
        marginRacTarget: businessDays * 6000,
        marginCamTarget: businessDays * 1500,
        marginTotalTarget: businessDays * 7500,
      });
    }
  }

  handleClick() {
    this.setState({ showSalesChart: !this.state.showSalesChart });
  }

  render() {
    const salesData = {
      labels: ['RAC', 'CAM', 'Total'],
      datasets: [
        {
          label: 'Sales $',
          data: [
            this.state.racSales,
            this.state.camSales,
            this.state.racSales + this.state.camSales,
            ,
          ],
          backgroundColor: 'rgba(251,98,98, 0.8)',
        },
        {
          label: 'Targets $',
          data: [
            this.state.racTarget,
            this.state.camTarget,
            this.state.totalTarget,
          ],
          backgroundColor: 'rgb(54, 162, 235, 0.8)',
        },
      ],
    };

    const marginData = {
      labels: ['RAC', 'CAM', 'Total'],
      datasets: [
        {
          label: 'Margin $',
          data: [
            this.state.racMargin,
            this.state.camMargin,
            this.state.racMargin + this.state.camMargin,
            ,
          ],
          backgroundColor: 'rgba(251,98,98, 0.8)',
        },
        {
          label: 'Targets $',
          data: [
            this.state.marginRacTarget,
            this.state.marginCamTarget,
            this.state.marginTotalTarget,
          ],
          backgroundColor: 'rgb(54, 162, 235, 0.8)',
        },
      ],
    };

    const options = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        mode: 'label',
        label: 'mylabel',
        callbacks: {
          label: function (tooltipItem, data) {
            if (
              tooltipItem.yLabel
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,') === NaN
            ) {
              '0'.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            } else {
              return (
                ' $' +
                tooltipItem.yLabel
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')
              );
            }
          },
        },
      },
    };

    return (
      <Wrapper>
        {this.state.loaded || (
          <Loader
            style={{
              textAlign: 'center',
            }}
            type="ThreeDots"
            color="#00BFFF"
            height={80}
            width={80}
          />
        )}
        {this.state.loaded && (
          <>
            {this.state.showSalesChart ? (
              <>
                <SubTitle>
                  Brand Sales
                  <span
                    onClick={() => this.handleClick()}
                    style={{ color: 'lightgray', cursor: 'pointer' }}
                  >
                    {' '}
                    - Brand Margins
                  </span>
                </SubTitle>
                <Bar
                  data={salesData}
                  options={options}
                  width={100}
                  height={50}
                />
              </>
            ) : (
              <>
                <SubTitle>
                  <span
                    onClick={() => this.handleClick()}
                    style={{ color: 'lightgray', cursor: 'pointer' }}
                  >
                    Brand Sales -
                  </span>{' '}
                  Brand Margins
                </SubTitle>
                <Bar
                  data={marginData}
                  options={options}
                  width={100}
                  height={50}
                />
              </>
            )}
          </>
        )}
      </Wrapper>
    );
  }
}
