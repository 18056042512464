import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { cGreen, cRed, nQuoteTotalNoDollar, whichRep } from '../../../../assets/functions/calculations';
import moment from 'moment';
import { CustomDateWrapper, Input, SubmitText, GreyTextDiv } from '../../../styled/ui';
import { daysLate, percentTwoDecimal } from '../../../../assets/functions/display';



export default class MonthSummaryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <table className="dashboard">
          <thead>
            <tr>
              <th>Type</th>
              <th>Value</th>
              {this.props.month.yearNums.map((year,index)=>(
                <th key={index}>{year}</th>
              ))}
              <th>Value</th>
              {this.props.month.yearNums.map((year,index)=>(
                <th key={index}>{year}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <br />
            <tr>
              <td rowspan={7}>All</td>
              <td>Completed Quotes</td>
              {this.props.month.years.map((year,index)=>(<td key={index}>{year.completed.count.all}</td>))}
              <td>Closed Quotes</td>
              {this.props.month.years.map((year,index)=>(<td key={index}>{year.closed.count.all}</td>))}
            </tr>
            <tr>
              <td>Completed Revenue</td>
              {this.props.month.years.map((year,index)=>(<td key={index}>{nQuoteTotalNoDollar(year.completed.sales.all)}</td>))}
              <td>Closed Sales</td>
              {this.props.month.years.map((year,index)=>(<td key={index}>{nQuoteTotalNoDollar(year.closed.sales.all)}</td>))}
            </tr>
            <tr>
              <td>Target Revenue</td>
              {this.props.month.yearNums.map((year,index)=>(<td key={index}>{nQuoteTotalNoDollar(this.props.month.targetSales[index])}</td>))}
              <td>Target Sales</td>
              {this.props.month.years.map((year,index)=>(<td key={index}>{nQuoteTotalNoDollar(this.props.month.targetSales[index])}</td>))}
            </tr>
            <tr>
              <td>Completed Margin</td>
              {this.props.month.years.map((year,index)=>(<td key={index}>{nQuoteTotalNoDollar(year.completed.margin.all)}</td>))}
              <td>Closed Margin</td>
              {this.props.month.years.map((year,index)=>(<td key={index}>{nQuoteTotalNoDollar(year.closed.margin.all)}</td>))}
            </tr>
            <tr>
              <td>Completed CSN Labor</td>
              {this.props.month.years.map((year,index)=>(<td key={index}>{nQuoteTotalNoDollar(year.completed.labor)}</td>))}
              <td>Closed CSN Labor</td>
              {this.props.month.years.map((year,index)=>(<td key={index}>{nQuoteTotalNoDollar(year.closed.labor)}</td>))}
            </tr>
            <tr>
              <td>Completed Margin + Labor</td>
              {this.props.month.yearNums.map((year,index)=>(<td key={index}>{nQuoteTotalNoDollar(this.props.month.years[index].completed.labor+this.props.month.years[index].completed.margin.all)}</td>))}
              <td>Closed Margin + Labor</td>
              {this.props.month.years.map((year,index)=>(<td key={index}>{nQuoteTotalNoDollar(this.props.month.years[index].closed.labor+this.props.month.years[index].closed.margin.all)}</td>))}
            </tr>
            <tr>
              <td>Target Margin + Labor</td>
              {this.props.month.yearNums.map((year,index)=>(<td key={index}>{nQuoteTotalNoDollar(this.props.month.targetMargin[index])}</td>))}
              <td>Target Margin + Labor</td>
              {this.props.month.years.map((year,index)=>(<td key={index}>{nQuoteTotalNoDollar(this.props.month.targetMargin[index])}</td>))}
            </tr>
            <br />
            <tr>
              <td rowspan={5}>CSN</td>
              <td>Completed Quotes</td>
              {this.props.month.years.map((year,index)=>(<td key={index}>{year.completed.count.csn}</td>))}
              <td>Closed Quotes</td>
              {this.props.month.years.map((year,index)=>(<td key={index}>{year.closed.count.csn}</td>))}
            </tr>
            <tr>
              <td>Completed Revenue</td>
              {this.props.month.years.map((year,index)=>(<td key={index}>{nQuoteTotalNoDollar(year.completed.sales.csn)}</td>))}
              <td>Closed Sales</td>
              {this.props.month.years.map((year,index)=>(<td key={index}>{nQuoteTotalNoDollar(year.closed.sales.csn)}</td>))}
            </tr>
            <tr>
              <td>Completed Margin</td>
              {this.props.month.years.map((year,index)=>(<td key={index}>{nQuoteTotalNoDollar(year.completed.margin.csn)}</td>))}
              <td>Closed Margin</td>
              {this.props.month.years.map((year,index)=>(<td key={index}>{nQuoteTotalNoDollar(year.closed.margin.csn)}</td>))}
            </tr>
            <tr>
              <td>Completed CSN Labor</td>
              {this.props.month.years.map((year,index)=>(<td key={index}>{nQuoteTotalNoDollar(year.completed.labor)}</td>))}
              <td>Closed CSN Labor</td>
              {this.props.month.years.map((year,index)=>(<td key={index}>{nQuoteTotalNoDollar(year.closed.labor)}</td>))}
            </tr>
            <tr>
              <td>Completed Margin + Labor</td>
              {this.props.month.yearNums.map((year,index)=>(<td key={index}>{nQuoteTotalNoDollar(this.props.month.years[index].completed.labor+this.props.month.years[index].completed.margin.csn)}</td>))}
              <td>Closed Margin + Labor</td>
              {this.props.month.years.map((year,index)=>(<td key={index}>{nQuoteTotalNoDollar(this.props.month.years[index].closed.labor+this.props.month.years[index].closed.margin.csn)}</td>))}
            </tr>
            <br />
            <tr>
              <td rowspan={3}>Contract</td>
              <td>Completed Quotes</td>
              {this.props.month.years.map((year,index)=>(<td key={index}>{year.completed.count.contract}</td>))}
              <td>Closed Quotes</td>
              {this.props.month.years.map((year,index)=>(<td key={index}>{year.closed.count.contract}</td>))}
            </tr>
            <tr>
              <td>Completed Revenue</td>
              {this.props.month.years.map((year,index)=>(<td key={index}>{nQuoteTotalNoDollar(year.completed.sales.contract)}</td>))}
              <td>Closed Sales</td>
              {this.props.month.years.map((year,index)=>(<td key={index}>{nQuoteTotalNoDollar(year.closed.sales.contract)}</td>))}
            </tr>
            <tr>
              <td>Completed Margin</td>
              {this.props.month.years.map((year,index)=>(<td key={index}>{nQuoteTotalNoDollar(year.completed.margin.contract)}</td>))}
              <td>Closed Margin</td>
              {this.props.month.years.map((year,index)=>(<td key={index}>{nQuoteTotalNoDollar(year.closed.margin.contract)}</td>))}
            </tr>
          </tbody>
        </table>
    );
  }
}
