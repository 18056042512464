import React, { Component } from 'react';
import { Wrapper } from '../../styled/ui';
import { getSQLData, setSQLData } from '../../../assets/functions/fetch';
import { nullDateCheck } from '../../../assets/functions/system';
import { matchQuotesAndNotes, processSiteVisitData } from '../../../assets/functions/process/cam';
import SiteVisitHasPlannedDateBJK from './SiteVisits/SiteVisitHasPlannedDateBJK';
import SiteVisitNeedScheduledTableBJK from './SiteVisits/SiteVisitNeedScheduledTable';
import QuoteNeedsSentBJK from './SiteVisits/QuoteNeedsSentBJK';
import SiteVisitQuoteOpenBJK from './SiteVisits/SiteVisitQuoteOpen';
import SiteVisitQuoteDiscontinuedBJK from './SiteVisits/SiteVisitQuoteDiscontinuedBJK';


export default class SiteVisitsBJK extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loaded: false,
          changeSiteVisitMode: false,
          viewing: "Site Visit Needs Scheduled",
        };
        this.handleDateChange = this.handleDateChange.bind(this)
        this.handleSiteVisitDateChange = this.handleSiteVisitDateChange.bind(this)
        this.handleQuoteSentDateChange = this.handleQuoteSentDateChange.bind(this)
        this.handleUpdateTag = this.handleUpdateTag.bind(this)
        this.handleSaveTag = this.handleSaveTag.bind(this)
        this.handleNeedSiteVisit = this.handleNeedSiteVisit.bind(this)
        this.handleSiteVisitPlannedDateChange = this.handleSiteVisitPlannedDateChange.bind(this)
      }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {

    try {
      const fetch = await getSQLData("cam/siteVisits", {})
      const fetchInstallNotes = await getSQLData("cam/siteVisitNotes", {})
      
      if(fetch && fetchInstallNotes){

        let data = fetch.data.data[0].recordset    
        let quoteNotes = fetchInstallNotes.data.data[0].recordset
        
        
        this.setState({
          data: data,
          quoteNotes: quoteNotes,
        }, () => this.processData())
      }
         
    } catch (error) {
      console.error(error);
    }
  }

  processData() {
    let data = this.state.data;
    let quoteNotes = this.state.quoteNotes;

    data = processSiteVisitData(data);
    data = matchQuotesAndNotes(data, quoteNotes);

    let needSiteVisitNoDate = [];
    let needSiteVisitHasDate = [];
    let siteVisitCompleteNeedsSent = [];
    let quoteSentOpen = [];
    let quoteSentDiscontinued = [];

    for (let i = 0; i < data.length; i++) {
      data[i].repNotes = [];
      data[i].nonRepNotes = [];
      for (let j = 0; j < data[i].notes.length; j++) {
        if (data[i].notes[j].nRep === data[i].nRep) {
          data[i].repNotes.push(data[i].notes[j]);
        } else {
          data[i].nonRepNotes.push(data[i].notes[j]);
        }
      }
    }

    this.setState({
      data: data,
      needSiteVisitNoDate: needSiteVisitNoDate,
      needSiteVisitHasDate: needSiteVisitHasDate,
      siteVisitCompleteNeedsSent: siteVisitCompleteNeedsSent,
      quoteSentOpen: quoteSentOpen,
      quoteSentDiscontinued: quoteSentDiscontinued,
      loaded: true,
    });
}
  handleDateChange = (event, id, paramName) => {
    console.log(event.target.value, id, paramName)
    let data = this.state.data
    data[id][paramName] = event.target.value


    this.setState({data: data})
  };

  handleSiteVisitDateChange = async (date, id) => {
    try {
      const post = await setSQLData("cam/setSiteVisitDate", {
        quoteNum: id,
        siteVisit: nullDateCheck(date),
      })
      const postSiteVisit = await setSQLData("cam/setNeedSiteVisit", {
        quoteNum: id,
        needSiteVisit: 0,
      })
      
      if(post && postSiteVisit){
        
      }
      
    } catch (error) {
      console.error(error);
    }
  };
  handleQuoteSentDateChange = async (date, id) => {
    try {
      const fetch = await setSQLData("cam/setQuoteSentDate", {
        quoteNum: id,
        quoteSent: nullDateCheck(date),
      })
      
      if(fetch){
        
      }
      
    } catch (error) {
      console.error(error);
    }
  };
  handleUpdateTag(getTag, getIndex) {
    let data = this.state.data

    data[getIndex].sQuoteTags = getTag
    data[getIndex].changed = true

    console.log(data,getTag, getIndex)

    this.setState({data: data})
  }

  handleSubmitDate = async (date, id) => {
    try {
      const fetch = await setSQLData("cam/setQuoteCompleteDate", {
        quoteNum: id,
        date: nullDateCheck(date),
      })
      
      if(fetch){
        this.fetchData();
      }
      
    } catch (error) {
      console.error(error);
    }
  };

  handleSaveTag = async (tag, id) => {
    try {
      const fetch = await setSQLData("cam/setQuoteTag", {
        quoteNum: id,
        quoteTag: tag,
      })
      
      if(fetch){
        this.fetchData();
      }
      
    } catch (error) {
      console.error(error);
    }
  };
  handleNeedSiteVisit = async (needSiteVisit, id) => {
    if(this.state.changeSiteVisitMode){
      try {
        const fetch = await setSQLData("cam/setNeedSiteVisit", {
          quoteNum: id,
          needSiteVisit: needSiteVisit ? 1 : 0,
        })
        
        if(fetch){
          
        }
        
      } catch (error) {
        console.error(error);
      }
    }
    
  };
  handleSiteVisitPlannedDateChange = async (date, id) => {
    try {
      const post = await setSQLData("cam/setSiteVisitPlannedDate", {
        quoteNum: id,
        siteVisit: nullDateCheck(date),
      })
      
      if(post){
        
      }
      
    } catch (error) {
      console.error(error);
    }
  };


  render() {
    let needSiteVisitNoDate = this.state.needSiteVisitNoDate
    let showAll = this.state.showAll
    return (
      <>
      {this.state.loaded && (
        <>
        
      <Wrapper amount={this.state.data.length}>
        {!this.state.hideAll && (
          <>
            <div>
              {(this.state.data.needSiteVisitNoDate.length > 0 || this.state.showAll) && (<button onClick={()=>this.setState({viewing: "Site Visit Needs Scheduled"})}>Site Visit Needs Scheduled ({this.state.data.needSiteVisitNoDate.length})</button>)}
              {(this.state.data.needSiteVisitHasDate.length > 0 || this.state.showAll) && (<button onClick={()=>this.setState({viewing: "Site Visit Has Planned Date"})}>Site Visit Has Planned Date ({this.state.data.needSiteVisitHasDate.length})</button>)}
              {(this.state.data.siteVisitCompleteNeedsSent.length > 0 || this.state.showAll) && (<button onClick={()=>this.setState({viewing: "Quote Needs Sent"})}>Quote Needs Sent ({this.state.data.siteVisitCompleteNeedsSent.length})</button>)}
              {(this.state.data.quoteSentOpen.length > 0 || this.state.showAll) && (<button onClick={()=>this.setState({viewing: "Site Visit Quote Open"})}>Site Visit Quote Open ({this.state.data.quoteSentOpen.length})</button>)}
              {(this.state.data.quoteSentDiscontinued.length > 0 || this.state.showAll) && (<button onClick={()=>this.setState({viewing: "Site Visit Quote Discontinued"})}>Site Visit Quote Discontinued ({this.state.data.quoteSentDiscontinued.length})</button>)}
            </div>
            <div>
              <button onClick={()=>this.setState({showAll: !showAll})}>Show All</button>
            </div>

            {this.state.viewing === "Site Visit Needs Scheduled" && (
              <SiteVisitNeedScheduledTableBJK
              data={this.state.data.needSiteVisitNoDate} 
              handleDateChange={this.handleDateChange}
              handleSiteVisitDateChange={this.handleSiteVisitDateChange}
              handleQuoteSentDateChange={this.handleQuoteSentDateChange}
              handleUpdateTag={this.handleUpdateTag}
              handleSaveTag={this.handleSaveTag}
              handleNeedSiteVisit={this.handleNeedSiteVisit}
              handleSiteVisitPlannedDateChange={this.handleSiteVisitPlannedDateChange}
              changeSiteVisitMode={this.state.changeSiteVisitMode}
              />
            )}
            {this.state.viewing === "Site Visit Has Planned Date" && (
              <SiteVisitHasPlannedDateBJK
                data={this.state.data.needSiteVisitHasDate}
                handleDateChange={this.handleDateChange}
                handleSiteVisitDateChange={this.handleSiteVisitDateChange}
                handleQuoteSentDateChange={this.handleQuoteSentDateChange}
                handleUpdateTag={this.handleUpdateTag}
                handleSaveTag={this.handleSaveTag}
                handleNeedSiteVisit={this.handleNeedSiteVisit}
                handleSiteVisitPlannedDateChange={this.handleSiteVisitPlannedDateChange}
                changeSiteVisitMode={this.state.changeSiteVisitMode}
              />
            )}
            {this.state.viewing === "Quote Needs Sent" && (
              <QuoteNeedsSentBJK
                data={this.state.data.siteVisitCompleteNeedsSent}
                handleDateChange={this.handleDateChange}
                handleSiteVisitDateChange={this.handleSiteVisitDateChange}
                handleQuoteSentDateChange={this.handleQuoteSentDateChange}
                handleUpdateTag={this.handleUpdateTag}
                handleSaveTag={this.handleSaveTag}
                handleNeedSiteVisit={this.handleNeedSiteVisit}
                handleSiteVisitPlannedDateChange={this.handleSiteVisitPlannedDateChange}
                changeSiteVisitMode={this.state.changeSiteVisitMode}
              />
            )}
            {this.state.viewing === "Site Visit Quote Open" && (
              <SiteVisitQuoteOpenBJK
                data={this.state.data.quoteSentOpen}
                handleDateChange={this.handleDateChange}
                handleSiteVisitDateChange={this.handleSiteVisitDateChange}
                handleQuoteSentDateChange={this.handleQuoteSentDateChange}
                handleUpdateTag={this.handleUpdateTag}
                handleSaveTag={this.handleSaveTag}
                handleNeedSiteVisit={this.handleNeedSiteVisit}
                handleSiteVisitPlannedDateChange={this.handleSiteVisitPlannedDateChange}
                changeSiteVisitMode={this.state.changeSiteVisitMode}
              />
            )}
            {this.state.viewing === "Site Visit Quote Discontinued" && (
              <SiteVisitQuoteDiscontinuedBJK
                data={this.state.data.quoteSentDiscontinued}
                handleDateChange={this.handleDateChange}
                handleSiteVisitDateChange={this.handleSiteVisitDateChange}
                handleQuoteSentDateChange={this.handleQuoteSentDateChange}
                handleUpdateTag={this.handleUpdateTag}
                handleSaveTag={this.handleSaveTag}
                handleNeedSiteVisit={this.handleNeedSiteVisit}
                handleSiteVisitPlannedDateChange={this.handleSiteVisitPlannedDateChange}
                changeSiteVisitMode={this.state.changeSiteVisitMode}
              />
            )}
           
          </>
        )}  

      </Wrapper>
      
      </>
      )}
      </>
    );
  }
}
