import React, { Component } from 'react';
import styled from 'styled-components';
import EmailItem from './EmailItem';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  height: fit-content;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.1);
  text-align: center;
  border-radius: 15px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4aabed;
  color: #fff;
  height: 35px;
  font-weight: bold;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
`;

const EmailContainer = styled.div`
  height: fit-content;

  div:first-child {
    margin: 0;
  }

  div:last-child {
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    margin: 0;
  }
`;

export default class Emails extends Component {
  state = {};

  render() {
    return (
      <Wrapper>
        <Title>Emails</Title>
        <EmailContainer>
          {this.props.emails.map((sql) => (
            <EmailItem
              data={sql}
              quote={this.props.data}
            />
          ))}
        </EmailContainer>
      </Wrapper>
    );
  }
}
