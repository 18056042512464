import React, { Component } from 'react';
import styled from 'styled-components';
import Moment from 'moment';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin: auto;
  padding: 1em 3em;
  width: 100%;
  height: fit-content;
  border: 1px solid #E8EDF1;
  text-align: center;
  text-align: left;
`;

const NoteInfo = styled.div`
  margin: 0.3em auto;
  padding: 0.3em 0 0.6em 0;
  width: 100%;

  p:first-child {
    font-size: 1.2rem;
    font-weight: bold;
  }

  p:last-child {
    font-weight: bold;
  }

  p {
    margin: 0.2em auto;
  }
`;

export default class NotesItem extends Component {
  state = { expanded: false, sent: false, confirmed: false };

  handleClick = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  render() {
    return (
      <Wrapper>
        <NoteInfo>
          <p>{this.props.name}</p>
          <p>
            {Moment(this.props.date).utc().format('L')}
            {' - '}
            {Moment(this.props.date).utc().format('LT')}
          </p>
          <p>General</p>
        </NoteInfo>
        <p>{this.props.note}</p>
      </Wrapper>
    );
  }
}
