module.exports = {
    underwriting_queries: {
        template: {
            query: "",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        getTask: {
            query: "SELECT nAddedBy, dtAdded, sTask FROM TASK_LIST WHERE id=[taskID]",
            req: ["taskID"],
            fields: [
                {key: "nAddedBy", label: "Sales Rep Requested"},
                {key: "dtAdded", label: "Date added"},
                {key: "sTask", label: "Task description"},
            ],
            desc: "Get task list data.",
            tables: ["TASK_LIST"],
            type: "SELECT",
            whereUsed: [],
        },
        getQuoteInfo: {
            query: "SELECT nCompany, sCompany, sName, sAddress, sAddress2, sCity, sState, sZip, sPhone, sEmail, "
                +   "sDCompany, sDName, sDAddress, sDAddress2, sDCity, sDState, sDZip, sDPhone, "
                +   "dtDeliveryDate, dtDeliveryTime, dtPickupDate, dtPickupTime, nDeliveryType, bNet, bCC, nRep, sCCType, sCCName, sCCAddress, sCCExDate, nTaxRate, bTaxDel "
                +   "FROM RAC_CUSTOMER_QUOTES WHERE id=[quoteID]",
            req: ["quoteID"],
            fields: [

            ],
            desc: "Get quote data for underwriting.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: [],
        },
        getQuoteLines: {
            query: "SELECT nItem, sDescription, nPrice, nQuantity, nSort, bTax "
                +   "FROM RAC_QUOTE_LINES WHERE nQuote=[quoteID]",
            req: ["quoteID"],
            fields: [

            ],
            desc: "Get quote data for underwriting.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: [],
        },
        getQuotePOs: {
            query: "SELECT rp.id, rp.sCompany, rp.sDisplayId, rp.dtDeliveryDate, rp.dtPickupDate, rp.nDelivery, rp.nTaxRate, rp.bTaxDel, rp.dtSent, rp.dtConfirmed "
                +   "FROM RAC_POS rp WHERE nAttachedQuote=[quoteID] AND NOT bDeleted=1",
            req: ["quoteID"],
            fields: [

            ],
            desc: "Get PO data for underwriting.",
            tables: ["RAC_POS"],
            type: "SELECT",
            whereUsed: [],
        },
        getQuotePOLines: {
            query: "SELECT rpl.nQuote, rpl.nQuantity, rpl.sDescription, rpl.nPrice, rpl.bTax, rpl.nSort, rpl.nItem "
                +   "FROM RAC_PO_LINES rpl "
                +   "WHERE rpl.nQuote IN (SELECT rp.id FROM RAC_POS rp WHERE rp.nAttachedQuote=[quoteID] AND NOT rp.bDeleted=1) ",
            req: ["quoteID"],
            fields: [

            ],
            desc: "Get PO line data for underwriting.",
            tables: ["RAC_PO_LINES", "RAC_POS"],
            type: "SELECT",
            whereUsed: [],
        },
        

    }

} 