import React, { Component } from 'react';
import styled from 'styled-components';
import Axios from 'axios';
import { whichRep } from '../../assets/functions/calculations';
import RecentActivity from '../tables/misc/RecentActivity';
import PinnedQuotes from '../tables/misc/PinnedQuotes';
import MyOpenQuotes from '../tables/misc/MyOpenQuotes';
import MyOpenQuotesNoNextFollowup from '../tables/misc/MyOpenQuotesNoNextFollowup';
import ExpiredQuotes from '../tables/misc/ExpiredQuotes';
import MostRecentNotes from '../tables/misc/MyMostRecentNotes';
import MyRecentEmails from '../tables/misc/MyRecentEmails';
import MyProspects from '../tables/misc/MyProspects';
import MyClosedQuotes from '../tables/misc/MyClosedQuotes';
import RecentlyReturned from '../tables/misc/RecentlyReturned';
import Cookies from 'js-cookie';

const Wrapper = styled.section`
  padding: 2em 0 1em 0;
  text-align: center;
  @media (max-width: 900px) {
    padding-bottom: 5em;
  }
`;

const Title = styled.h1`
  font-size: 2rem;
`;

const SmallSubTitle = styled.p`
  padding: 0.5em 0 2em 0em;
  font-size: 1rem;
`;

const DashboardControls = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin: 1em auto;
  padding: 2em 4em;
  width: fit-content;
  height: fit-content;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.1);
  text-align: center;
  border-radius: 15px;

  button {
    display: inline-block;
     padding: 0.5em 1.2em;
     margin: 0 0.3em 0.3em 0;
     text-decoration: none;
     color: #333;
     text-align: center;
     transition: all 0.2s;
    background-color: #f2f5f7;
    border: 1px solid rgba(255, 255, 255, 0);
    cursor: pointer;

    &:hover {
      background-color: #36a2eb;
      border: 1px solid #36a2eb;
      color: #fff;
    }
  }

  select {
    display: inline-block;
    color: #333;
     text-align: center;
     padding: 0.3em 1.2em;
     margin: 0 0.3em 0.3em 0;
    background-color: #f2f5f7;
    border: 1px solid #f2f5f7;
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
      background-color: #36a2eb;
      border: 1px solid #36a2eb;
      color: #fff;
    }
  }
`;

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: '',
      loaded: false,
    };
  }
  // Async Axios
  fetchUser = async () => {
    try {
      const passport = await Axios.get('/userSession', {
        credentials: 'include',
      });
      if (passport.data.passport) {
        this.setState({
          user: passport.data.passport.user,
          showReport: "Open Quotes"
        });

      }
      const response = await Axios.get('/user', {
        credentials: 'include',
      });
      if(response){
        this.setState({
          TAUser: response.data.result[0].recordset[0].nTechArmyId,
          loaded: true
        })
      }

      if (
        response.data.result[0].recordset[0].sTTATitle === 'Developer' ||
        response.data.result[0].recordset[0].sTTATitle === 'CEO'
      ) {
        this.setState({
          bAdmin: !this.state.bAdmin,
        });
        Cookies.set('sTTATitle', 'admin');
      } else {
        if (response.data.result[0].recordset[0].bAdmin === true) {
          this.setState({
            bAdmin: !this.state.bAdmin,
          });
          Cookies.set('sTTATitle', 'admin');
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount = async () => {
    this.fetchUser();
    this.props.updateTitle("Home")
    
    try {
      
      if(fetch){

        let data = fetch.data.data[0].recordset[0]

        this.setState({

        })
      }
      
      
    } catch (error) {
      console.error(error);
    }
    
    
  }


  renderSwitch() {
    {
      switch(this.state.showReport){
        case "Open Quotes":
          return <MyOpenQuotes nRep={this.state.user} />
          break;
        case "No Followup":
          return <MyOpenQuotesNoNextFollowup nRep={this.state.user} />
          break;
        case "Closed Quotes":
          return <MyClosedQuotes nRep={this.state.user} />
          break;
        case "Recent Activity":
          return <RecentActivity nRep={this.state.user} />
          break;
        case "Expired Quotes":
          return <ExpiredQuotes nRep={this.state.user} />
          break;
        case "Recent Notes":
          return <MostRecentNotes nRep={this.state.user} />
          break;
        case "Recent Emails":
          return <MyRecentEmails nRep={this.state.user} />
          break;
        case "Prospects":
          return <MyProspects nRep={this.state.user} />
          break;
        case "Recently Returned":
          console.log(this.state.TAUser)
          return <RecentlyReturned nRep={this.state.user} TAUser={this.state.TAUser} />
          break;
        default:
          return ''
      }
    }    
  }

  render() {
    return (
      <Wrapper>
        {this.state.loaded && (
          <>
          {this.props.permissions.Internal || (
            <>
              <Title>Hi {whichRep(this.state.user)}!</Title>
            </>
          )}
          {this.props.permissions.Internal && (
            <>
            
            <DashboardControls>
              <div>
                <Title>Hi {whichRep(this.state.user)}!</Title>
                <SmallSubTitle>Welcome to your home dashboard</SmallSubTitle>
              </div>
              <div>
                <button
                  onClick={() => {
                    if (this.state.showReport !== "Open Quotes") {
                      this.setState({
                        showReport: "Open Quotes"
                      });
                    }
                  }}
                >
                  Open Quotes
                </button>
                <button
                  onClick={() => {
                    if (this.state.showReport !== "No Followup") {
                      this.setState({
                        showReport: "No Followup"
                      });
                    }
                  }}
                >
                  Open Quotes w/o Next Followup
                </button>
                <button
                  onClick={() => {
                    if (this.state.showReport !== "Closed Quotes") {
                      this.setState({
                        showReport: "Closed Quotes"
                      });
                    }
                  }}
                >
                  Closed Quotes
                </button>
                <button
                  onClick={() => {
                    if (this.state.showReport !== "Recent Activity") {
                      this.setState({
                        showReport: "Recent Activity"
                      });
                    }
                  }}
                >
                  Recent Activity
                </button>
                <button
                  onClick={() => {
                    if (this.state.showReport !== "Expired Quotes") {
                      this.setState({
                        showReport: "Expired Quotes"
                      });
                    }
                  }}
                >
                  Expired Quotes
                </button>
                <button
                  onClick={() => {
                    if (this.state.showReport !== "Recent Notes") {
                      this.setState({
                        showReport: "Recent Notes"
                      });
                    }
                  }}
                >
                  Recent Notes
                </button>
                <button
                  onClick={() => {
                    if (this.state.showReport !== "Recent Emails") {
                      this.setState({
                        showReport: "Recent Emails"
                      });
                    }
                  }}
                >
                  Recent Emails
                </button>
                <button
                  onClick={() => {
                    if (this.state.showReport !== "Prospects") {
                      this.setState({
                        showReport: "Prospects"
                      });
                    }
                  }}
                >
                  Prospects
                </button>
                <button onClick={() => (this.setState({showReport: "Recently Returned"}))}>Recently Returned</button>
              </div>
            </DashboardControls>
            <PinnedQuotes nRep={this.state.user} />

            {this.renderSwitch()}

            
          </>
        )}
        </>
        )}
      </Wrapper>
    );
  }
}

export default Home;
