import React, { Component } from 'react'
import { getSQLData, setSQLData } from '../../assets/functions/fetch';
import moment from 'moment';
import RepCommissions from '../tables/accounting-dashboard/RepCommissions';
import LogMismatch from '../tables/accounting-dashboard/LogMismatch';
import { Wrapper } from '../styled/ui';
import ClosedNotOnLog from '../tables/accounting-dashboard/ClosedNotOnLog';
import LoggedNotClosed from '../tables/accounting-dashboard/LoggedNotClosed';
import LogLookup from '../tables/accounting-dashboard/LogLookup';
import UpdateCommissions from '../tables/accounting-dashboard/UpdateCommissions';
import FRPPoints from '../tables/accounting-dashboard/FRPPoints';
import ImportArchive from '../tables/accounting-dashboard/commission/ImportArchive';
import InvoicedNotLogged from '../tables/accounting-dashboard/commission/InvoicedNotLogged';
import PossibleMissedEntries from '../tables/accounting-dashboard/commission/PossibleMissedEntries';
import CommissionLogMismatch from '../tables/accounting-dashboard/commission/CommissionLogMismatch';
import LogExceptions from '../tables/accounting-dashboard/commission/LogExceptions';
import CommissionLogLookup from '../tables/accounting-dashboard/commission/CommissionLogLookup';
import RepCommissionPaid from '../tables/accounting-dashboard/commission/RepCommissionPaid';
import RepLog from '../tables/accounting-dashboard/commission/RepLog';
import InvoiceTool from '../tables/accounting-dashboard/invoicing/InvoiceTool';
import UnpaidButClosed from '../tables/accounting-dashboard/UnpaidButClosed';

export default class AccountingDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      importColumns: [
        {header: "Row", SQL: "nRow", column: -1},
        {header: "Order Date", SQL: "dtOrderDate", column: -1},
        {header: "Delivery Date", SQL: "dtDeliveryDate", column: -1},
        {header: "Customer", SQL: "sCustomer", column: -1},
        {header: "Vendor", SQL: "sVendor", column: -1},
        {header: "Description of Equipment", SQL: "sDescription", column: -1},
        {header: "Rep", SQL: "sRep", column: -1},
        {header: "AssistRep", SQL: "sRep2", column: -1},
        {header: "AssistRep", SQL: "sRep3", column: -1},
        {header: "State", SQL: "sState", column: -1},
        {header: "Quote#", SQL: "nQuote", column: -1},
        {header: "Invoice Total", SQL: "nInvoiceTotal", column: -1},
        {header: "PO Total", SQL: "nPOTotal", column: -1},
        {header: "Commission", SQL: "nCommission", column: -1},
        {header: "Invoice#", SQL: "nInvoice", column: -1},
        {header: "Validation Date", SQL: "dtValidationDate", column: -1},
        {header: "FRP", SQL: "nFRP", column: -1},
        {header: "Rebill", SQL: "bRebill", column: -1},
      ],
      goal: {
        amc: 38909.91,
        rlm: 49239.93,
        kf: 260.33,
        mg: 23603.86,
        bjk: 6408.53,
      }
    };
    this.fetchData = this.fetchData.bind(this)
    this.handleRefresh = this.handleRefresh.bind(this)
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {

    try {
      const calcQuotePrices = await setSQLData("marketing/updateQuoteLineTotal")
      const calcPOCosts = await setSQLData("marketing/updateQuotePOTotal")

      if(calcQuotePrices && calcPOCosts){
        const fetch = await getSQLData("accounting/commission_log_export", {})
        const fetch2 = await getSQLData("accounting/commission_log_prediction", {})
        const fetch3 = await getSQLData("accounting/get_commission_paid_log", {})
        //const fetch4 = await getSQLData("accounting/needs_invoiced", {})
      
        if(fetch && fetch2 && fetch3 && true){

          let commissionLog = fetch.data.data[0].recordset    
          let query = fetch.query

          let predictLog = fetch2.data.data[0].recordset    
          let predictQuery = fetch2.query

          let commissionPaidLog = fetch3.data.data[0].recordset    
          let commissionPaidLogQuery = fetch3.query

          //let needsInvoiced = fetch4.data.data[0].recordset    
          //let needsInvoicedQuery = fetch4.query
          
          this.setState({
            commissionLog: commissionLog,
            query: query,
            predictLog: predictLog,
            predictQuery: predictQuery,
            commissionPaidLog: commissionPaidLog,
            commissionPaidLogQuery: commissionPaidLogQuery,
            //needsInvoiced: needsInvoiced,
            //needsInvoicedQuery: needsInvoicedQuery,
          }, () => this.processData())
        }
      }

      
         
    } catch (error) {
      console.error(error);
    }
  }
  
  processData(){

    let commissionLog = this.state.commissionLog
    let predictLog = this.state.predictLog
    let commissionPaidLog = this.state.commissionPaidLog

    let loggedNotClosed = []
    let closedNotLogged = []
    let logSystemMismatch = []
    let paidLogYears = {
      2023: [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},],
      2024: [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},],
      2025: [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},],
      2026: [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},],
    }

    let reps = {
      amc: -56156.62,
      rlm: -74043.41,
      kf: -227.34,
      mg: -39756.97,
      bjk: -26018.12,
    }
    let count = {}
    let paid = {
      amc: 0,
      rlm: 0,
      kf: 0,
      mg: 0,
      bjk: 0,
    }
    let YTDadj = {
      amc: 378.54,
      rlm: -4312.45,
      kf: 0,
      mg: 0,
      bjk: 0,
    }

    for(let i=0; i<commissionLog.length; i++){

      if(commissionLog[i].dtValidationDate && commissionLog[i].nInvoice){
        commissionLog[i].sRep = commissionLog[i].sRep.toLowerCase()
        if(commissionLog[i].sRep.length == 4){
          commissionLog[i].sRep = commissionLog[i].sRep.substring(0,3)
        }
        
        if(reps[commissionLog[i].sRep]){
          reps[commissionLog[i].sRep] += commissionLog[i].nCommission
        } else {
          reps[commissionLog[i].sRep] = commissionLog[i].nCommission
          
        }

        if(count[commissionLog[i].sRep]){
          count[commissionLog[i].sRep] += 1
        } else {
          count[commissionLog[i].sRep] = 1
        }

        let predictMatch = predictLog.find(x => x.id == commissionLog[i].nQuote)
        if(!predictMatch){
          if(commissionLog[i].nCommission > 0){
            loggedNotClosed.push(commissionLog[i])
          }
          
        } else {
        if(Math.abs((commissionLog[i].nInvoiceTotal - commissionLog[i].nPOTotal)*.1 - commissionLog[i].nCommission) > 0.01
            || Math.abs(commissionLog[i].nInvoiceTotal - (predictMatch.QuoteLineTotal + predictMatch.nDelivery)) > 0.01
            || Math.abs(commissionLog[i].nPOTotal - predictMatch.QuotePOTotal) > 0.01){

              logSystemMismatch.push({log: commissionLog[i], quote: predictMatch})

          }
        }
      }
    }

    for(let i=0; i<predictLog.length; i++){
      let predictMatch = commissionLog.find(x => x.nQuote == predictLog[i].id)
        if(!predictMatch && moment(predictLog[i].dtFirstClosed) > moment().subtract(1,'years')
         && predictLog[i].QuoteLineTotal > 0 && predictLog[i].QuotePOTotal > 0
         && predictLog[i].nRep !== 64 && predictLog[i].nRep !== 63 && predictLog[i].nRep !== 46 && predictLog[i].nRep !== 60
         && predictLog[i].sQuoteType === "Rentacomputer.com" && moment(predictLog[i].dtFirstClosed).format('YYYY') >= 2023
         && predictLog[i].dtPaid){
          closedNotLogged.push(predictLog[i])
        }
    }
    
    for(let i=0; i<commissionPaidLog.length; i++){

      paidLogYears[commissionPaidLog[i].nYear][commissionPaidLog[i].nPayroll][commissionPaidLog[i].sRep] = commissionPaidLog[i].nAmount
      paidLogYears[commissionPaidLog[i].nYear][commissionPaidLog[i].nPayroll].date = commissionPaidLog[i].dtDate

      paid[commissionPaidLog[i].sRep] += commissionPaidLog[i].nAmount

    }

    this.setState({
      reps: reps,
      count: count,
      loggedNotClosed: loggedNotClosed,
      closedNotLogged: closedNotLogged,
      logSystemMismatch: logSystemMismatch,
      paidLogYears: paidLogYears,
      paid: paid,
      YTDadj: YTDadj,
      loaded: true,
    })


  }

  handleOnChange = (e) => {
    this.setState({importFile: e.target.files[0]})
  }

  handleOnSubmit = (e) => {
    const fileReader = new FileReader();
    let importColumns = this.state.importColumns
    let error = false
    let importError = "None"
    let importData = {}
    let importFile = this.state.importFile

    fileReader.onload = function (file) {
      setSQLData("accounting/delete_commission_log", {})

      const text = file.target.result
      const rows = text.split('\n')
      const headers = rows[0].split(',')
      const importData = []

      for (let i=0; i<headers.length; i++){
        headers[i] = headers[i].substring(0,headers[i].length)
      }

      const data = rows.slice(1).map((row) => {
        const values = row.split(',')
        return headers.reduce((object,header,index) => {
          const SQLHeader = this.state.importColumns.find(x => x.header === header)

          if(SQLHeader){
            object[SQLHeader.SQL] = values[index]
          }
          
          return object
        }, {})
      })
      
      for(let i=0; i<data.length; i++){
        let error = false

        data[i].nRow = i

        data[i].nCommission = parseFloat(data[i].nCommission)
        data[i].nInvoice = parseInt(data[i].nInvoice)
        data[i].nInvoiceTotal = parseFloat(data[i].nInvoiceTotal)
        data[i].nPOTotal = parseFloat(data[i].nPOTotal)
        data[i].nQuote = parseInt(data[i].nQuote)
        data[i].nFRP = parseInt(data[i].nFRP)

        if(data[i].dtOrderDate === "rebill"){
          data[i].dtOrderDate = null
          data[i].bRebill = 1
        } else { 
          data[i].bRebill = 0
        }

        if(data[i].dtOrderDate){data[i].dtOrderDate = "'" + data[i].dtOrderDate + "'"}
        if(data[i].dtDeliveryDate){data[i].dtDeliveryDate = "'" + data[i].dtDeliveryDate + "'"} else {data[i].dtDeliveryDate = null}
        if(data[i].dtValidationDate){data[i].dtValidationDate = "'" + data[i].dtValidationDate + "'"} else {data[i].dtValidationDate = null}

        if(!data[i].nFRP){data[i].nFRP = null}
        if(!data[i].nInvoice){data[i].nInvoice = null}

        if(!data[i].sCustomer){data[i].sCustomer = ""}
        if(!data[i].sVendor){data[i].sVendor = ""}
        if(!data[i].nQuote){data[i].nQuote = null}
        if(!data[i].nInvoiceTotal){data[i].nInvoiceTotal = 0}
        if(!data[i].nPOTotal){data[i].sCustomer = 0}
        if(!data[i].nCommission){data[i].nCommission = 0}

        if(!error){
          importData.push(data[i])
        } else {
          console.log(error)
          data[i].error = error
        }
      }

      function processImportData(index) {
        if (index < importData.length) {
          setSQLData("accounting/commission_log_import", importData[index]);
          
          index++;
          setTimeout(() => {
            processImportData(index);
          }, 100);
        }
      }
      
      processImportData(0);

        
    }.bind(this)
    console.log(importFile)

    fileReader.readAsText(importFile)
  }

  handleRefresh(){
    this.setState({needRefresh: false,})
  }

  render() {
    return (
      <Wrapper>

      {this.props.user === 45 && (<div>
        <button onClick={()=>this.setState({viewing: "Paid But Not Logged", needRefresh: true,})}>Paid But Not Logged</button>
        <button onClick={()=>this.setState({viewing: "Commission Log Mismatch", needRefresh: true,})}>Commission Log Mismatch</button>
        <button onClick={()=>this.setState({viewing: "Log Exceptions", needRefresh: true,})}>Log Exceptions</button>
        <button onClick={()=>this.setState({viewing: "Commission Log Lookup", needRefresh: true,})}>Commission Log Lookup</button>
        <button onClick={()=>this.setState({viewing: "Rep Commissions", needRefresh: true,})}>Rep Commissions Summary</button>
        <button onClick={()=>this.setState({viewing: "Commissions Report", needRefresh: true,})}>Commissions Report</button>
    </div>)}
      <div>
        <button onClick={()=>this.setState({viewing: "FRP Points", needRefresh: true,})}>FRP Points</button>
      </div>
      <div>
      <button onClick={()=>this.setState({viewing: "Invoicing Tool", needRefresh: true,})}>Invoicing Tool</button>
      </div>
      <div>
      <button onClick={()=>this.setState({viewing: "Mark As Paid", needRefresh: true,})}>Mark As Paid</button>
      </div>

        {this.state.viewing === "Import Commission Log" && (
          <ImportArchive 
            needRefresh={this.state.needRefresh}
            handleRefresh={this.handleRefresh}
          />
        )}
        {this.state.viewing === "Paid But Not Logged" && (
          <InvoicedNotLogged 
          needRefresh={this.state.needRefresh}
          handleRefresh={this.handleRefresh}
        />
        )}
        {this.state.viewing === "2023 Missed Entries" && (
          <PossibleMissedEntries 
          needRefresh={this.state.needRefresh}
          handleRefresh={this.handleRefresh}
        />
        )}
        {this.state.viewing === "Commission Log Mismatch" && (
          <CommissionLogMismatch 
          needRefresh={this.state.needRefresh}
          handleRefresh={this.handleRefresh}
        />
        )}
        {this.state.viewing === "Log Exceptions" && (
          <LogExceptions 
          needRefresh={this.state.needRefresh}
          handleRefresh={this.handleRefresh}
        />
        )}
        {this.state.viewing === "Commission Log Lookup" && (
          <CommissionLogLookup 
          needRefresh={this.state.needRefresh}
          handleRefresh={this.handleRefresh}
        />
        )}
        {this.state.viewing === "Rep Commissions" && (
          <RepCommissionPaid 
          needRefresh={this.state.needRefresh}
          handleRefresh={this.handleRefresh}
        />
        )}
        {this.state.viewing === "Commissions Report" && (
          <RepLog 
          needRefresh={this.state.needRefresh}
          handleRefresh={this.handleRefresh}
        />
        )}
        
        {this.state.loaded && (
          <>
            {this.state.viewing === "Rep Commissions" && (
              <RepCommissions
                reps={this.state.reps}
                goal={this.state.goal}
                paid={this.state.paid}
                YTDadj={this.state.YTDadj}
              />
            )}
            {this.state.viewing === "Log Mismatch" && (
              <LogMismatch
                logSystemMismatch={this.state.logSystemMismatch}
              />
            )}
            {/*this.state.viewing === "Needs Invoiced" && (
              <NeedsInvoiced
                needsInvoiced={this.state.needsInvoiced}
              />
            )*/}
            {this.state.viewing === "Closed Not On Log" && (
              <ClosedNotOnLog
                closedNotLogged={this.state.closedNotLogged}
              />
            )}
            {this.state.viewing === "Logged Not Closed" && (
              <LoggedNotClosed
                loggedNotClosed={this.state.loggedNotClosed}
              />
            )}
            {this.state.viewing === "Log Lookup" && (
              <LogLookup
              commissionLog={this.state.commissionLog}
              predictLog={this.state.predictLog}
              />
            )}
            {this.state.viewing === "Update Rep Commissions" && (
              <UpdateCommissions
                paidLogYears={this.state.paidLogYears}
                reps={Object.keys(this.state.reps)}
                fetchData={this.fetchData}
              />
            )}
            
          </>
        )}
        {this.state.viewing === "FRP Points" && (
          <FRPPoints 
          needRefresh={this.state.needRefresh}
          handleRefresh={this.handleRefresh}
        />
        )}
        {this.state.viewing === "Invoicing Tool" && (
          <InvoiceTool 
          needRefresh={this.state.needRefresh}
          handleRefresh={this.handleRefresh}
        />
        )}
        {this.state.viewing === "Mark As Paid" && (
          <UnpaidButClosed 
            user={this.props.user}
            needRefresh={this.state.needRefresh}
            handleRefresh={this.handleRefresh}
          />
        )}

      </Wrapper>
    )
  }
}