module.exports = {
    cam_queries: {
        template: {
            query: "",
            req: [],
            fields: [{key: "", label: ""},],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },       
        allFollowUps: {
            query: "SELECT rs.nQuote AS Quote, rs.sNote, rcq.sCompany, rcq.sQuoteTitle, "
                + "(SELECT rr.sName FROM RAC_REPS rr WHERE rr.id=rs.nRep) AS Rep, "
                + "CONVERT(varchar, dtDate, 101) as FollowUp, "
                + "CONVERT(varchar, dtCompleted, 101) as Completed, "
                + "(SELECT TOP 1 sNote FROM RAC_QUOTE_NOTES rqn WHERE rqn.nQuote=rs.nQuote AND rqn.nRep=rs.nRep ORDER BY rqn.id desc) AS LastNote, "
                + "(SELECT TOP 1 dtTimeStamp FROM RAC_QUOTE_NOTES rqn WHERE rqn.nQuote=rs.nQuote AND rqn.nRep=rs.nRep ORDER BY rqn.id desc) AS LastNoteDate "
                + "FROM RAC_SCHEDULE rs "
                + "LEFT JOIN RAC_CUSTOMER_QUOTES rcq ON rcq.id=rs.nQuote "
                + "WHERE rs.nRep IN (63,64,44,68) "
                + "AND (CONVERT(date, dtCompleted) = CONVERT(date, GETDATE()) "
                + "OR (dtCompleted IS NULL AND CONVERT(date, dtDate) <= GETDATE())) "
                + "ORDER BY Completed desc, FollowUp asc",
            req: [""],
            fields: [{key: "", label: ""},],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },       
        followUps: {
            query: "SELECT rs.nQuote AS Quote, rs.sNote, rcq.sCompany, rcq.sQuoteTitle, "
                + "(SELECT rr.sName FROM RAC_REPS rr WHERE rr.id=rs.nRep) AS Rep, "
                + "CONVERT(varchar, dtDate, 101) as FollowUp, "
                + "CONVERT(varchar, dtCompleted, 101) as Completed "
                + "FROM RAC_SCHEDULE rs "
                + "LEFT JOIN RAC_CUSTOMER_QUOTES rcq ON rcq.id=rs.nQuote "
                + "WHERE rs.nRep = [nRep] "
                + "AND (CONVERT(date, dtCompleted) = CONVERT(date, GETDATE()) "
                + "OR dtCompleted IS NULL) "
                + "ORDER BY Completed desc, FollowUp asc",
            req: ["nRep"],
            fields: [{key: "", label: ""},],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },       
        setLaborOnly: {
            query: "UPDATE RAC_CUSTOMER_QUOTES SET bLaborOnly=[laborOnly] WHERE id=[quoteNum]",
            req: ["laborOnly", "quoteNum"],
            fields: [{key: "", label: ""},],
            desc: "Updates Labor Only status of a quote.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "UPDATE",
            whereUsed: [],
        },       
        setnRep2: {
            query: "UPDATE RAC_CUSTOMER_QUOTES SET nRep2 = [nRep2] WHERE id = [quoteNum]",
            req: ["nRep2", "id"],
            fields: [{key: "", label: ""},],
            desc: "Updates the nRep2 field for a given quote",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: ["/components/tables/OpenInstalls.js",],
        },       
        
        recentlyCompletedPOs: {
            query: "SELECT rp.nAttachedQuote, rp.sDisplayId, rp.nTaxRate, rp.bTaxDel, rp.nDelivery, rpl.nItem, rpl.nQuantity, rpl.nPrice, rpl.sDescription, rpl.id AS LineID, rp.sCompany  " +
                "FROM RAC_CUSTOMER_QUOTES rcq " +
                "LEFT JOIN RAC_POS rp on rcq.id=rp.nAttachedQuote " +
                "LEFT JOIN RAC_PO_LINES rpl on rp.id=rpl.nQuote " +
                "WHERE rcq.sStatus='Closed' AND (rcq.dtComplete > DATEADD(month, -[numMonths], GETDATE()) OR rcq.dtFirstClosed > DATEADD(month, -[numMonths], GETDATE())) AND rcq.sQuoteType='CameraSecurityNow.com' ",
            req: ["numMonths"],
            fields: [
                {key: "nAttachedQuote", label: "Quote Number"},
                {key: "nTaxRate", label: "Tax Rate"},
                {key: "bTaxDel", label: "Tax Delivery"},
                {key: "nDelivery", label: "Delivery Cost"},
                {key: "nItem", label: "Item Number"},
                {key: "nQuantity", label: "Quantity"},
                {key: "nPrice", label: "Unit Price"},
                {key: "sDescription", label: "Line Description"},
                {key: "LineID", label: "Quote Line ID"},
                {key: "sCompany", label: "Company Name"},
            ],
            desc: "Gets data about CameraSecurityNow PO lines from quotes that were completed or first closed in the last [numMonths] months.",
            tables: ["RAC_CUSTOMER_QUOTES", "RAC_POS", "RAC_PO_LINES"],
            type: "SELECT",
            whereUsed: ["/components/tables/RecentlyCompleted.js"],
        },     
        recentlyCompletedLines: {
            query: "SELECT rql.nQuote, rql.nItem, rql.sDescription, rql.nPrice, rql.nQuantity "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "LEFT JOIN RAC_QUOTE_LINES rql on rql.nQuote=rcq.id "
                + "WHERE rcq.sStatus='Closed' AND "
                + "(rcq.dtComplete > DATEADD(month, -[numMonths], GETDATE()) OR rcq.dtFirstClosed > DATEADD(month, -[numMonths], GETDATE())) AND rcq.sQuoteType='CameraSecurityNow.com' ",
            req: ["numMonths"],
            fields: [{key: "", label: ""},],
            desc: "Gets data about CameraSecurityNow Quote lines from quotes that were completed or first closed in the last [numMonths] months.",
            tables: ["RAC_CUSTOMER_QUOTES","RAC_QUOTE_LINES"],
            type: "SELECT",
            whereUsed: [],
        },      
        recentlyCompleted: {
            query: "SELECT rcq.id, rcq.nRep, rcq.nRep2, rcq.nRep3, rcq.sQuoteTags, rcq.bTaxDel, rcq.nTaxRate, rcq.nDelivery, rcq.sCompany, " +
                "rcq.sName, rcq.sQuoteTitle, rcq.sAddress, rcq.sAddress2, rcq.sCity, rcq.sState, rcq.sZip, " +
                "rcq.dtComplete, rcq.dtTargetComplete, rcq.dtDateStamp, rcq.dtFirstClosed, rcq.dtSiteVisit, " +
                "(SELECT SUM(rql.nQuantity*rql.nPrice) FROM RAC_QUOTE_LINES rql WHERE rcq.id=rql.nQuote) AS nLinesTotal " +
                "FROM RAC_CUSTOMER_QUOTES rcq " +
                "WHERE rcq.sStatus='Closed' AND (rcq.dtComplete > DATEADD(month, -[numMonths], GETDATE()) OR rcq.dtFirstClosed > DATEADD(month, -[numMonths], GETDATE())) AND rcq.sQuoteType='CameraSecurityNow.com' " +
                "ORDER BY dtComplete desc",
            req: ["numMonths"],
            fields: [
                {key: "id", label: "Quote Number"},
                {key: "nRep", label: "Sales Rep"},
                {key: "nRep2", label: "Sales Rep 2"},
                {key: "nRep3", label: "Sales Rep 3"},
                {key: "nQuoteTotal", label: "Total Price"},
                {key: "bTaxDel", label: "Tax Delivery?"},
                {key: "nTaxRate", label: "Tax Rate"},
                {key: "nDelivery", label: "Delivery Price"},
                {key: "sQuoteTags", label: "Quote Tags"},
                {key: "sCompany", label: "Company Name"},
                {key: "sQuoteTitle", label: "Quote Title"},
                {key: "sStatus", label: "Quote Status"},
                {key: "sName", label: "Contact Name"},
                {key: "sAddress", label: "Address"},
                {key: "sAddress2", label: "Address Line 2"},
                {key: "sCity", label: "City"},
                {key: "sState", label: "State"},
                {key: "sZip", label: "Zip"},
                {key: "dtComplete", label: "Install Complete Date"},
                {key: "dtTargetComplete", label: "Target Complete Date"},
                {key: "dtDateStamp", label: "Quote Date"},
                {key: "dtFirstClosed", label: "Quote First Closed Date"},
                {key: "dtSiteVisit", label: "Site Visit Date"},
                
            ],
            desc: "Gets data about CameraSecurityNow quotes that were completed or first closed in the last [numMonths] months.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: ["/components/tables/RecentlyCompleted.js"],
        },       
        openIssues: {
            query: "SELECT rcq.id, rcq.nRep, rcq.nRep2, rcq.nRep3, rcq.sQuoteTags, rcq.nQuoteTotal, rcq.sCompany, rcq.sName, rcq.sQuoteTitle, rcq.sStatus, " +
                "rcq.sAddress, rcq.sAddress2, rcq.sCity, rcq.sState, rcq.sZip, rcq.dtIssueReported, rcq.dtComplete " +
                "FROM RAC_CUSTOMER_QUOTES rcq " +
                "WHERE rcq.bOpenIssue=1 AND rcq.sQuoteType='CameraSecurityNow.com' " +
                "ORDER BY rcq.dtIssueReported asc ",
            req: [],
            fields: [
                {key: "id", label: "Quote Number"},
                {key: "nRep", label: "Sales Rep"},
                {key: "nRep2", label: "Sales Rep 2"},
                {key: "nRep3", label: "Sales Rep 3"},
                {key: "nQuoteTotal", label: "Total Price"},
                {key: "sQuoteTags", label: "Quote Tags"},
                {key: "sCompany", label: "Company Name"},
                {key: "sQuoteTitle", label: "Quote Title"},
                {key: "sStatus", label: "Quote Status"},
                {key: "sName", label: "Contact Name"},
                {key: "sAddress", label: "Address"},
                {key: "sAddress2", label: "Address Line 2"},
                {key: "sCity", label: "City"},
                {key: "sState", label: "State"},
                {key: "sZip", label: "Zip"},
                {key: "dtIssueReported", label: "Issue Reported Date"},
                {key: "dtComplete", label: "Install Complete Date"},
            ],
            desc: "Returns information about Open Issues",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: ["/components/tables/SiteVisits.js"],
        },       
        openQuotes: {
            query: "SELECT rcq.id, rcq.nRep, rcq.nRep2, rcq.nRep3, rcq.sQuoteTags, rcq.nQuoteTotal, rcq.bTaxDel, rcq.nTaxRate, rcq.nDelivery, rcq.sCompany," +
                "rcq.sName, rcq.sQuoteTitle, rcq.sAddress, rcq.sAddress2, rcq.sCity, rcq.sState, rcq.sZip, rpl.nQuantity, rpl.nPrice, rpl.sDescription, rpl.nItem, rpl.bTax " +
                "rcq.bNeedSiteVisit, rcq.dtSiteVisitPlanned, rcq.dtSiteVisit " +
                "FROM RAC_CUSTOMER_QUOTES rcq " +
                "LEFT JOIN RAC_QUOTE_LINES rql ON rcq.id = rql.nQuote " +
                "WHERE rcq.sQuoteType='CameraSecurityNow.com' AND rcq.sStatus='Open'",
            req: [],
            fields: [
                {key: "id", label: "Quote Number"},
                {key: "nRep", label: "Sales Rep"},
                {key: "nRep2", label: "Sales Rep 2"},
                {key: "nRep3", label: "Sales Rep 3"},
                {key: "sQuoteTags", label: "Quote Tags"},
                {key: "nQuoteTotal", label: "Total Price"},
                {key: "bTaxDel", label: "Tax Delivery?"},
                {key: "nTaxRate", label: "Tax Rate"},
                {key: "nDelivery", label: "Delivery Price"},
                {key: "sQuoteTags", label: "Quote Tags"},
                {key: "sCompany", label: "Company Name"},
                {key: "sQuoteTitle", label: "Quote Title"},
                {key: "sName", label: "Contact Name"},
                {key: "sAddress", label: "Address"},
                {key: "sAddress2", label: "Address Line 2"},
                {key: "sCity", label: "City"},
                {key: "sState", label: "State"},
                {key: "nQuantity", label: "Item Quantity"},
                {key: "nPrice", label: "Unit Price"},
                {key: "sDescription", label: "Item Description"},
                {key: "nItem", label: "Item Number"},
                {key: "bTax", label: "Taxable?"},
                {key: "bNeedSiteVisit", label: "Need Site Visit?"},
                {key: "dtSiteVisitPlanned", label: "Site Visit Planned Date"},
                {key: "dtSiteVisit", label: "Site Visit Date"},
            ],
            desc: "Gets info about all open Camera Security Now quotes.",
            tables: ["AC_CUSTOMER_QUOTES","RAC_QUOTE_LINES"],
            type: "SELECT",
            whereUsed: ["/components/tables/CamOpenQuotes.js"],
        },
        siteVisitsv2: {
            query: "SELECT rcq.* "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE bDeleted <> 1 AND rcq.sQuoteType='CameraSecurityNow.com' "
                + "AND (rcq.sStatus='Open' "
                + "OR (rcq.sStatus='Discontinued' AND rcq.dtLastUpdated >= DATEADD(week,-1,GETDATE())))"
                + "ORDER BY rcq.dtDateStamp desc",
            req: [],
            fields: [{key: "", label: ""},],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },       
        siteVisitNotesv2: {
            query: "SELECT rqn.* "
                + "FROM RAC_QUOTE_NOTES rqn "
                + "LEFT JOIN RAC_CUSTOMER_QUOTES rcq ON rcq.id = rqn.nQuote "
                + "WHERE bDeleted <> 1 AND rcq.sQuoteType='CameraSecurityNow.com' "
                + "AND (rcq.sStatus='Open' "
                + "OR (rcq.sStatus='Discontinued' AND rcq.dtLastUpdated >= DATEADD(week,-1,GETDATE()))) "
                + "ORDER BY rqn.dtTimeStamp desc",
            req: [],
            fields: [{key: "", label: ""},],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },       
        siteVisitEmailsv2: {
            query: "SELECT rqe.* "
                + "FROM RAC_QUOTE_EMAILS rqe "
                + "LEFT JOIN RAC_CUSTOMER_QUOTES rcq ON rcq.id = rqe.nQuote "
                + "WHERE rcq.bNeedSiteVisit=1 AND rcq.sStatus='Open'",
            req: [],
            fields: [{key: "", label: ""},],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },       
        siteVisits: {
            query: "SELECT rcq.id, rcq.nRep, rcq.nRep2, rcq.nRep3, rcq.nQuoteTotal, rcq.bTaxDel, rcq.nTaxRate, rcq.nDelivery, " +
                "rcq.dtDateStamp, rcq.sQuoteTags, rcq.sCompany, rcq.sQuoteTitle, rcq.sStatus, rcq.dtSiteVisit, rcq.dtSentDate, rcq.sName, " +
                "rcq.sAddress, rcq.sAddress2, rcq.sCity, rcq.sState, rcq.sZip, rcq.bNeedSiteVisit, rcq.dtSiteVisitPlanned, rcq.dtLastMove " +
                "FROM RAC_CUSTOMER_QUOTES rcq " +
                "WHERE " +
                "((rcq.bNeedSiteVisit=1 AND NOT rcq.sStatus='Discontinued') OR (rcq.dtSiteVisit IS NOT NULL AND rcq.dtSentDate IS NULL AND NOT rcq.sStatus='Discontinued') " +
                "OR (rcq.dtSiteVisit IS NOT NULL AND rcq.sStatus='Open') " + 
                "OR (rcq.sStatus='Discontinued' AND rcq.dtLastMove >= DATEADD(day,-15,GETDATE()))) " +
                "AND rcq.sQuoteType='CameraSecurityNow.com' " +
                "ORDER BY rcq.dtSentDate desc, rcq.bNeedSiteVisit desc, rcq.dtSiteVisit desc, rcq.dtSiteVisitPlanned desc, rcq.dtDateStamp desc, id",
            req: [],
            fields: [
                {key: "id", label: "Quote Number"},
                {key: "nRep", label: "Sales Rep"},
                {key: "nRep2", label: "Sales Rep 2"},
                {key: "nRep3", label: "Sales Rep 3"},
                {key: "nQuoteTotal", label: "Total Price"},
                {key: "bTaxDel", label: "Tax Delivery?"},
                {key: "nTaxRate", label: "Tax Rate"},
                {key: "nDelivery", label: "Delivery Price"},
                {key: "dtDateStamp", label: "Quote Creation Date"},
                {key: "sQuoteTags", label: "Quote Tags"},
                {key: "sCompany", label: "Company Name"},
                {key: "sQuoteTitle", label: "Quote Title"},
                {key: "sStatus", label: "Quote Status"},
                {key: "dtSiteVisit", label: "Site Visit Date"},
                {key: "dtSentDate", label: "Quote Sent Date"},
                {key: "sName", label: "Contact Name"},
                {key: "sAddress", label: "Address"},
                {key: "sAddress2", label: "Address Line 2"},
                {key: "sCity", label: "City"},
                {key: "sState", label: "State"},
                {key: "sZip", label: "Zip"},
                {key: "bNeedSiteVisit", label: "Need Site Visit?"},
                {key: "dtSiteVisitPlanned", label: "Site Visit Planned Date"},
                {key: "dtLastMove", label: "Quote Last Changed Date"},
            ],
            desc: "Returns information about our Open Site Visits. ",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: ["/components/tables/SiteVisits.js"],
        },       
        openInstallPOLines: {
            query: "SELECT rp.sCompany, rp.nDelivery, rp.nTaxRate, rp.bTaxDel, rp.nAttachedQuote, rpl.nQuantity, rpl.sDescription, rpl.nPrice, rpl.nItem, rpl.id AS LineID " +
                "FROM RAC_POS rp LEFT JOIN RAC_PO_LINES rpl ON rp.id=rpl.nQuote " +
                "WHERE rp.nAttachedQuote IN (SELECT id FROM RAC_CUSTOMER_QUOTES rcq " +
                "WHERE rcq.dtFirstClosed >= '05/01/2021 00:00' AND rcq.dtComplete IS NULL " +
                "AND rcq.sStatus='Closed' AND rcq.sQuoteType='CameraSecurityNow.com') " +
                "ORDER BY rp.id, rpl.nSort ",
            req: [],
            fields: [
                {key: "sCompany", label: "Vendor Name"},
                {key: "nDelivery", label: "Delivery Cost"},
                {key: "nTaxRate", label: "Tax Rate"},
                {key: "bTaxDel", label: "Tax Delivery?"},
                {key: "nAttachedQuote", label: "Quote Number"},
                {key: "nQuantity", label: "Item Quantity"},
                {key: "sDescription", label: "Item Description"},
                {key: "nPrice", label: "Item Price"},
                {key: "nItem", label: "Item Number"},
                {key: "LineID", label: "Line ID"},
            ],
            desc: "Lists PO Lines for all closed CameraSecurityNow.com quotes with no Complete Date.",
            tables: ["RAC_POS", "RAC_PO_LINES", "RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: ["/components/tables/OpenInstalls.js"],
        },       
        setPOLineItem: {
            query: "UPDATE RAC_PO_LINES SET nItem=[itemNum] WHERE id=[lineID]",
            req: ["itemNum", "lineID"],
            fields: [],
            desc: "Updates the item number of a PO Line.",
            tables: ["RAC_PO_LINES"],
            type: "UPDATE",
            whereUsed: [
                "/components/tables/OpenInstalls.js",
                "/components/tables/RecentlyCompleted.js",
            ],
        },     
        setIssueReportedDate: {
            query: "UPDATE RAC_CUSTOMER_QUOTES SET dtIssueReported=[issueReportedDate] WHERE id=[quoteNum]",
            req: ["issueReportedDate", "quoteNum"],
            fields: [],
            desc: "Updates the Issue Reported Date of a quote.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "UPDATE",
            whereUsed: ["/components/tables/OpenIssues.js"],
        },     
        closeIssue: {
            query: "UPDATE RAC_CUSTOMER_QUOTES SET bOpenIssue=0 WHERE id=[quoteNum]",
            req: ["quoteNum"],
            fields: [],
            desc: "Closes an Open Issue.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "UPDATE",
            whereUsed: ["/components/tables/OpenIssues.js"],
        },     
        setNeedSiteVisit: {
            query: "UPDATE RAC_CUSTOMER_QUOTES SET bNeedSiteVisit=[needSiteVisit] WHERE id=[quoteNum]",
            req: ["needSiteVisit", "quoteNum"],
            fields: [],
            desc: "Updates the Need Site Visit? property of a quote.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "UPDATE",
            whereUsed: ["/components/tables/SiteVisits.js"],
        },     
        setSiteVisitDate: {
            query: "UPDATE RAC_CUSTOMER_QUOTES SET dtSiteVisit=[siteVisit] WHERE id=[quoteNum]",
            req: ["siteVisit", "quoteNum"],
            fields: [],
            desc: "Updates the Site Visit date of a quote.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "UPDATE",
            whereUsed: ["/components/tables/SiteVisits.js"],
        },     
        setSiteVisitPlannedDate: {
            query: "UPDATE RAC_CUSTOMER_QUOTES SET dtSiteVisitPlanned=[siteVisit] WHERE id=[quoteNum]",
            req: ["siteVisit", "quoteNum"],
            fields: [],
            desc: "Updates the Site Visit Planned date of a quote.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "UPDATE",
            whereUsed: ["/components/tables/SiteVisits.js"],
        },     
        setQuoteSentDate: {
            query: "UPDATE RAC_CUSTOMER_QUOTES SET dtSentDate=[quoteSent] WHERE id=[quoteNum]",
            req: ["quoteSent", "quoteNum"],
            fields: [],
            desc: "Updates the Quote Sent date of a quote.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "UPDATE",
            whereUsed: ["/components/tables/SiteVisits.js"],
        },     
        setQuoteCompleteDate: {
            query: "UPDATE RAC_CUSTOMER_QUOTES SET dtComplete=[date] WHERE id=[quoteNum]",
            req: ["date", "quoteNum"],
            fields: [],
            desc: "Updates the Completion date of a quote.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "UPDATE",
            whereUsed: ["/components/tables/OpenInstalls.js"],
        },     
        setQuoteTargetComplete: {
            query: "UPDATE RAC_CUSTOMER_QUOTES SET dtTargetComplete=[date] WHERE id=[quoteNum]",
            req: ["date", "quoteNum"],
            fields: [],
            desc: "Updates the Target Completion date of a quote.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "UPDATE",
            whereUsed: ["/components/tables/OpenInstalls.js"],
        },     
        setQuoteTargetStart: {
            query: "UPDATE RAC_CUSTOMER_QUOTES SET dtTargetStart=[date] WHERE id=[quoteNum]",
            req: ["date", "quoteNum"],
            fields: [],
            desc: "Updates the Target Start date of a quote.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "UPDATE",
            whereUsed: ["/components/tables/OpenInstalls.js"],
        },     
        setShipDate: {
            query: "UPDATE RAC_CUSTOMER_QUOTES SET dtShipDate=[date] WHERE id=[quoteNum]",
            req: ["date", "quoteNum"],
            fields: [],
            desc: "Updates the Ship date of a quote.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "UPDATE",
            whereUsed: ["/components/tables/OpenInstalls.js"],
        },     
        setHardwareExpected: {
            query: "UPDATE RAC_CUSTOMER_QUOTES SET dtHardwareExpected=[date] WHERE id=[quoteNum]",
            req: ["date", "quoteNum"],
            fields: [],
            desc: "Updates the Hardware Expected date of a quote.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "UPDATE",
            whereUsed: ["/components/tables/OpenInstalls.js"],
        },     
        setHardwareSetup: {
            query: "UPDATE RAC_CUSTOMER_QUOTES SET dtHardwareSetup=[date] WHERE id=[quoteNum]",
            req: ["date", "quoteNum"],
            fields: [],
            desc: "Updates the Hardware Setup date of a quote.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "UPDATE",
            whereUsed: ["/components/tables/OpenInstalls.js"],
        },     
        setAsSoldMargin: {
            query: "UPDATE RAC_CUSTOMER_QUOTES SET nAsSoldMargin=[margin] WHERE id=[quoteNum]",
            req: ["margin", "quoteNum"],
            fields: [],
            desc: "Updates the As Sold Margin of a quote.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "UPDATE",
            whereUsed: ["/components/tables/OpenInstalls.js"],
        },     
        setQuoteTag: {
            query: "UPDATE RAC_CUSTOMER_QUOTES SET sQuoteTags='[quoteTag]' WHERE id=[quoteNum]",
            req: ["quoteTag", "quoteNum"],
            fields: [],
            desc: "Updates the Quote Tag property of a quote.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "UPDATE",
            whereUsed: [
                "/components/tables/OpenInstalls.js",
                "/components/tables/SiteVisits.js",
                "/components/tables/OpenIssues.js",
            ],
        },     
        openInstalls: {
            query: "SELECT rcq.id, rcq.nRep, rcq.nRep2, rcq.nRep3, rcq.nQuoteTotal, rcq.bTaxDel, rcq.nTaxRate, rcq.nDelivery, rcq.dtFirstClosed, "
                + "rcq.sQuoteTags, rcq.sDCompany, rcq.sDName, rcq.sDPhone, rcq.sEmail, rcq.sQuoteTitle, rcq.dtComplete, rcq.dtTargetComplete, "
                + "("
                    + "SELECT SUM(r2.nPrice * r2.nQuantity) "
                    + "FROM RAC_PO_LINES r2 "
                    + "LEFT JOIN RAC_POS r3 ON r2.nQuote = r3.id "
                    + "WHERE r2.nItem=832 AND rcq.id=r3.nAttachedQuote"
                + ") AS nLaborTotals, "
                + "("
                    + "SELECT COUNT(r2.id) "
                    + "FROM RAC_PO_LINES r2 "
                    + "LEFT JOIN RAC_POS r3 ON r2.nQuote = r3.id "
                    + "WHERE r2.nItem=832 AND rcq.id=r3.nAttachedQuote"
                + ") AS nLaborLines, "
                + "(SELECT Sum(nPOTotal) FROM RAC_POS r4 WHERE r4.nAttachedQuote=rcq.id) AS nPoTotals, "
                + "rcq.sDAddress, rcq.sDAddress2, rcq.sDCity, rcq.sDState, rcq.sDZip, rcq.sInstallStatus, rcq.dtTargetStart, "
                + "rcq.dtShipDate, rcq.dtHardwareExpected, rcq.dtHardwareSetup, rcq.nAsSoldMargin, rcq.bLaborOnly "
                + "FROM rac_customer_quotes rcq "
                + "WHERE rcq.dtFirstClosed >= '05/01/2021 00:00' AND rcq.dtComplete IS NULL "
                + "AND rcq.sStatus='Closed' AND rcq.sQuoteType='CameraSecurityNow.com' "
                + "ORDER BY rcq.dtTargetComplete asc",
            req: [],
            fields: [
                {key: "id", label: "Quote Number"},
                {key: "nRep", label: "Rep Number"},
                {key: "nRep2", label: "Rep 2"},
                {key: "nRep3", label: "Rep 3"},
                {key: "nQuoteTotal", label: "Quote Total Price"},
                {key: "bTaxDel", label: "Delivery Taxable?"},
                {key: "nTaxRate", label: "Tax Rate"},
                {key: "nDelivery", label: "Delivery Price"},
                {key: "dtFirstClosed", label: "First Closed Date"},
                {key: "sQuoteTags", label: "Quote Tags"},
                {key: "sDCompany", label: "Company Name"},
                {key: "sDName", label: "On Site Contact Name"},
                {key: "sEmail", label: "Contact Email"},
                {key: "sQuoteTitle", label: "Quote Title"},
                {key: "dtComplete", label: "Complete Date"},
                {key: "dtTargetComplete", label: "Target Complete Date"},
                {key: "nLaborTotals", label: "Labor Total"},
                {key: "sDAddress", label: "Delivery Address"},
                {key: "sDAddress2", label: "Delivery Address Line 2"},
                {key: "sDCity", label: "Delivery City"},
                {key: "sDState", label: "Delivery State"},
                {key: "sDZip", label: "Delivery Zip"},
                {key: "sDPhone", label: "Delivery Phone"},
                {key: "sInstallStatus", label: "Install Status"},
                {key: "dtTargetStart", label: "Install Target Start Date"},
            ],
            desc: "Lists closed CameraSecurityNow.com quotes with no dtCompleteDate (Open Installs).",
            tables: ["RAC_CUSTOMER_QUOTES","RAC_POS"],
            type: "SELECT",
            whereUsed: [
                "/components/tables/OpenInstalls.js",
            ],
        },
        siteVisitNotes: {
            query: "SELECT rqn.nQuote, rqn.nRep, rqn.dtTimeStamp, rqn.nAttention, rqn.sNote "
                + "FROM RAC_QUOTE_NOTES rqn "
                + "WHERE rqn.nQuote IN "
                + "(SELECT rcq.id FROM rac_customer_quotes rcq WHERE rcq.sQuoteType='CameraSecurityNow.com') "
                + "ORDER BY rqn.dtTimeStamp desc",
            req: [],
            fields: [
                {key: "nQuote", label: "Quote Number"},
                {key: "nRep", label: "Note Author Rep ID"},
                {key: "dtTimeStamp", label: "Note Date/Time"},
                {key: "nAttention", label: "Note Target Rep ID"},
                {key: "dtAttentionDismissed", label: "Note Attention Dismissed Date/Time"},
                {key: "sNote", label: "Note Text"},
            ],
            desc: "Lists all quote notes from CAM Open Installs",
            tables: ["RAC_QUOTE_NOTES", "RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: [
                "/components/tables/SiteVisits.js",
            ],

        },
        openInstallNotes: {
            query: "SELECT rqn.nQuote, rqn.nRep, rqn.dtTimeStamp, rqn.nAttention, rqn.nAttentionPriority, rqn.dtAttentionDismissed, rqn.sNote "
                + "FROM RAC_QUOTE_NOTES rqn "
                + "WHERE rqn.nQuote IN "
                + "(SELECT rcq.id FROM rac_customer_quotes rcq WHERE rcq.dtFirstClosed >= '05/01/2021 00:00' AND rcq.dtComplete IS NULL "
                + "AND rcq.sStatus='Closed' AND rcq.sQuoteType='CameraSecurityNow.com') "
                + "ORDER BY rqn.dtTimeStamp desc",
            req: [],
            fields: [
                {key: "nQuote", label: "Quote Number"},
                {key: "nRep", label: "Note Author Rep ID"},
                {key: "dtTimeStamp", label: "Note Date/Time"},
                {key: "nAttention", label: "Note Target Rep ID"},
                {key: "nAttentionPriority", label: "Attention Priority"},
                {key: "dtAttentionDismissed", label: "Note Attention Dismissed Date/Time"},
                {key: "sNote", label: "Note Text"},
            ],
            desc: "Lists all quote notes from CAM Open Installs",
            tables: ["RAC_QUOTE_NOTES", "RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: [
                "/components/tables/OpenInstalls.js",
            ],
        },
        
        openInstallPackages: {
            query: "SELECT p.* "
                + "FROM PACKAGES p "
                + "WHERE p.nQuote IN "
                + "(SELECT rcq.id FROM rac_customer_quotes rcq WHERE rcq.dtFirstClosed >= '05/01/2021 00:00' AND rcq.dtComplete IS NULL "
                + "AND rcq.sStatus='Closed' AND rcq.sQuoteType='CameraSecurityNow.com') ",
            req: [],
            fields: [

            ],
            desc: "Lists all packages from CAM Open Installs",
            tables: ["PACKAGES", "RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: [
                "/components/tables/OpenInstalls.js",
            ],

        },
    }

} 