import React, { Component } from 'react';
import styled from 'styled-components';
import CustomerInformation from './CustomerInformation';
import DeliveryInformation from './DeliveryInformation';
import QuoteActions from './QuoteActions';
import QuoteTags from './QuoteTags';
import EventDescription from './EventDescription';
import QuoteLevel from './QuoteLevel';
import CreditCardInfo from './CreditCardInfo';
import PoInfo from './PoInfo';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 1em 0;
`;

const Body = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 1em 0;
`;

const LeftPane = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1em;
  width: 100%;
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-right: 0.5em;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 0.5em;
`;
export default class CompanyInfo extends Component {
  state = {};

  render() {
    return (
      <Wrapper>
        <QuoteLevel
          nFirstContact={this.props.data.nFirstContact}
          nContactMade={this.props.data.nContactMade}
          nQuoteReceived={this.props.data.nQuoteReceived}
          nRemindedOfReturn={this.props.data.nRemindedOfReturn}
          nRentalClosed={this.props.data.nRentalClosed}
          nManagerReviewed={this.props.data.nManagerReviewed}
        />
        <Body>
          <LeftPane>
            <LeftColumn>
              <CustomerInformation
                sCompany={this.props.data.sCompany}
                sAddress={this.props.data.sAddress}
                sAddress2={this.props.data.sAddress2}
                sCity={this.props.data.sCity}
                sState={this.props.data.sState}
                sZip={this.props.data.sZip}
                sName={this.props.data.sName}
                sPhone={this.props.data.sPhone}
                sFax={this.props.data.sFax}
                sEmail={this.props.data.sEmail}
                update={this.props.update}
              />
              <QuoteTags sQuoteTags={this.props.data.sQuoteTags} update={(getField, getData) => this.props.update(getField, getData)} />
              <PoInfo
                sPONumber={this.props.data.sPONumber}
                sPOContact={this.props.data.sPOContact}
                sPOPhone={this.props.data.sPOPhone}
                update={this.props.update}
              />
            </LeftColumn>
            <RightColumn>
              <DeliveryInformation
                sDCompany={this.props.data.sDCompany}
                sDAddress={this.props.data.sDAddress}
                sDAddress2={this.props.data.sDAddress2}
                sDCity={this.props.data.sDCity}
                sDState={this.props.data.sDState}
                sDZip={this.props.data.sDZip}
                sDName={this.props.data.sDName}
                sDPhone={this.props.data.sDPhone}
                sDFax={this.props.data.sDFax}
                sDEmail={this.props.data.sDEmail}
                sAltNamePhone={this.props.data.sAltNamePhone}
                sDNotes={this.props.data.sDNotes}
                update={this.props.update}
              />
              <EventDescription
                sRentalDetails={this.props.data.sRentalDetails}
                update={this.props.update}
              />
              <CreditCardInfo />
            </RightColumn>
          </LeftPane>
          <QuoteActions
            onClick={this.props.onClick}
            sStatus={this.props.data.sStatus}
            sQuoteType={this.props.data.sQuoteType}
            sType={this.props.data.sType}
            nPotential={this.props.data.nPotential}
            bCC={this.props.data.bCC}
            bNet={this.props.data.bNet}
            bCOD={this.props.data.bCOD}
            nContractType={this.props.data.nContractType}
            nDeliveryType={this.props.data.nDeliveryType}
            socialMediaCount={this.props.socialMediaCount}
            followupsCount={this.props.followupsCount}
            attachmentsCount={this.props.attachmentsCount}
            nPaid={this.props.data.nPaid}
            update={this.props.update}
          />
        </Body>
      </Wrapper>
    );
  }
}
