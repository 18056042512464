import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Axios from 'axios';
import Moment from 'moment';
import Loader from 'react-loader-spinner';
import { whichRep, abbreviateBrand, calcMilesFromLatLong, nQuoteTotalNoDollar, dateFormatter, dateFormat } from '../../../assets/functions/calculations';
import MediaQuery from 'react-responsive';

const Wrapper = styled.section`
  padding: 1em 0em;
  text-align: center;

  @media (max-width: 900px) {
    padding-bottom: 5em;
  }
`;

const DashboardControls = styled.section`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin: 3em auto 0 auto;
  padding: 2em 4em;
  width: fit-content;
  height: fit-content;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.1);
  text-align: center;
  border-radius: 15px;

  button {
    display: inline-block;
     padding: 0.5em 1.2em;
     margin: 0 0.3em 0.3em 0;
     text-decoration: none;
     color: #333;
     text-align: center;
     transition: all 0.2s;
    background-color: #f2f5f7;
    border: 1px solid rgba(255, 255, 255, 0);
    cursor: pointer;

    &:hover {
      background-color: #36a2eb;
      border: 1px solid #36a2eb;
      color: #fff;
    }
  }

  select {
    display: inline-block;
    color: #333;
     text-align: center;
     padding: 0.3em 1.2em;
     margin: 0 0.3em 0.3em 0;
    background-color: #f2f5f7;
    border: 1px solid #f2f5f7;
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
      background-color: #36a2eb;
      border: 1px solid #36a2eb;
      color: #fff;
    }
  }
`;

const LoaderWrapper = styled.div`
  margin-top: 25vh;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 1em;
`;

const SubTitle = styled.h2`
  padding: 2em 0 0 0;
  text-align: center;
`;

class DiscQuotes extends Component {
  state = {
    data: [],
    loaded: false,
    quotes: []
  };

  // Async Axios
  fetchData = async () => {
    try {
      const response = await Axios.get(this.props.report, {
        params: {
        },
        headers: { 'Content-Type': 'application/json' },
      });
      // Sets the state using the response data
      console.log(this.props.report)
      this.setState({
        quotes: response.data.result[0].recordset,
        loaded: true
      });
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {

    this.fetchData();

  }

  componentWillUnmount() {
    // Stop fetching data when the user leaves the page and component unmounts
    clearInterval(this.interval);
  }


  render() {

    return (
      <Wrapper>
        {this.state.loaded || (
          <LoaderWrapper>
            <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} />
          </LoaderWrapper>
        )}
        {this.state.loaded && (
          <div>
            {console.log(this.props.report)}
          {(this.props.report === "/disc-quotes-summary" && (
            <table className='dashboard'>
              <thead>
                <tr>
                  <th>Reason</th>
                  <th>Quotes</th>
                </tr>
              </thead>
              {this.state.quotes.map((reason,index)=>(
                <tr>
                  <td>{reason.sWhyCancel}</td>
                  <td>{reason.count}</td>
                </tr>
              ))}
            </table>
          )) || (
          <table className='dashboard'>
            <thead>
              <tr>
                <th>Discontinue Date</th>
                <th>Pickup Date</th>
                <th>Quote</th>
                <th>Customer</th>
                <th>Delivery Location</th>                
                <th>Closed Quotes</th>
                <th>Open Quotes</th>
                <th>Price</th>
                <th width={400}>Request</th>
              </tr>
            </thead>
            {this.state.quotes.map((quote,index) => (
              <tr>
                <td>{dateFormat(quote.dtLastUpdated)}</td>
                <td>{dateFormat(quote.dtPickupDate)}</td>
                <td><Link target="_blank" to={`/quote/${quote.id}`}>{quote.id}</Link></td>
                <td>{quote.sCompany}</td>
                <td>{quote.sDCity}, {quote.sDState} {quote.sDZip}</td>
                <td>{quote.nClosed}</td>
                <td>{quote.nOpen}</td>
                <td>{nQuoteTotalNoDollar(quote.nQuoteTotal)}</td>
                <td>{quote.sQuote}</td>
              </tr>
            ))}
          </table>
          )}
          </div>
        )}
      </Wrapper>
    );
  }
}

export default DiscQuotes;
