import React, { Component } from 'react';
import { getSQLData } from '../../../assets/functions/fetch';
import { InputField, Button } from '../../tables/marketing-wizard/StyledComponents';
import EditContactModal from './EditContactModal'; 
import ContactLog from './ContactLog'; 

class MarketingSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: '',
      searchResults: [],
      selectedContact: null, 
      isEditModalOpen: false,
      isContactLogOpen: false,
      typingTimeout: null, // For debouncing the search
    };
  }

  handleInputChange = (e) => {
    const { value } = e.target;
    
    // Clear the previous typing timeout
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    // Set a new timeout to trigger the search after a delay (debouncing)
    this.setState({
      searchString: value,
      typingTimeout: setTimeout(() => {
        this.handleSearch();
      }, 300), // 300 milliseconds delay
    });
  };

  handleSearch = async () => {
    const { searchString } = this.state;
    console.log("Search initiated with: ", searchString);
  
    try {
      const response = await getSQLData('marketing/searchContacts', { searchString });
      console.log("Search Response: ", response); 
  
      if (response && response.data && response.data.data && Array.isArray(response.data.data)) {
        const recordsets = response.data.data; 
  
        if (recordsets.length > 0 && recordsets[0].recordset) {
          const data = recordsets[0].recordset; 
          console.log("Search Results (Raw): ", data);
  
          const filteredResults = data.filter(contact => 
            contact.sName.toLowerCase().includes(searchString.toLowerCase()) || 
            contact.Email1.toLowerCase().includes(searchString.toLowerCase())
          );
  
          console.log("Search Results (Filtered): ", filteredResults);
          this.setState({ searchResults: filteredResults });
        } else {
          console.error("Recordset is missing or empty: ", recordsets);
        }
      } else {
        console.error("Unexpected response structure: ", response);
      }
    } catch (error) {
      console.error("Error during search: ", error);
    }
  };

  // Handle deletion of a contact
  handleDeleteContact = (contactId) => {
    this.setState((prevState) => ({
      searchResults: prevState.searchResults.filter(contact => contact.id !== contactId),
      selectedContact: null, // Clear the selected contact
      isEditModalOpen: false, // Close the modal after deletion
    }));
  };

  // Open Edit Modal
  openEditModal = (contact) => {
    this.setState({ selectedContact: contact, isEditModalOpen: true });
  };

  // Open Contact Log
  openContactLog = (contact) => {
    this.setState({ selectedContact: contact, isContactLogOpen: true });
  };

  // Close Modals
  closeModals = () => {
    this.setState({ isEditModalOpen: false, isContactLogOpen: false, selectedContact: null });
  };

  renderSearchResults = () => {
    const { searchResults } = this.state;

    if (searchResults.length === 0) {
      return <p>No results found.</p>;
    }

    return (
      <ul>
        {searchResults.map((result) => (
          <li key={result.id} style={{ marginBottom: '15px' }}>
            {result.sName} - {result.Email1}
           
            <Button onClick={() => this.openEditModal(result)} style={{ marginRight: '10px', marginLeft: '10px' }}>
              Edit
            </Button>
            <Button onClick={() => this.openContactLog(result)} style={{ marginLeft: '10px' }}>
              Log Contact
            </Button>
          </li>
        ))}
      </ul>
    );
  };

  render() {
    const { selectedContact, isEditModalOpen, isContactLogOpen } = this.state;

    return (
      <div>
        <InputField 
          type="text" 
          placeholder="Search contacts..." 
          value={this.state.searchString} 
          onChange={this.handleInputChange} 
          style={{ marginTop: '10px' }}
        />

        {/* Adding a line space between the search bar and the results */}
        <div style={{ margin: '20px 0' }} />

        <div>
          {this.renderSearchResults()}
        </div>

        {/* Edit Contact Modal */}
        {isEditModalOpen && 
          <EditContactModal 
            contact={selectedContact} 
            onClose={this.closeModals} 
            onDelete={this.handleDeleteContact} // Pass delete handler to modal
          />
        }

        {/* Contact Log Modal */}
        {isContactLogOpen && 
          <ContactLog 
            contact={selectedContact} 
            onClose={this.closeModals} 
          />
        }
      </div>
    );
  }
}

export default MarketingSearch;