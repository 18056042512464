import React, { Component } from 'react';
import styled from 'styled-components';
import SendEmail from './SendEmail';
import Attachments from './Attachments';
import Followups from './Followups';
import SocialLink from './SocialLink';

const Wrapper = styled.section`
  position: fixed;
  right: 0;
  top: 0;
  background-color: #fff;
  width: 23%;
  height: 100%;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.1);
  text-align: center;
  overflow-y: scroll;
  z-index: 22;
`;

const BodyWrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin: 1.5em 0 3em 0;
  position: relative;
`;

const Close = styled.div`
  border-bottom: 1px solid #efefef;
  padding: 1.1em;
  margin-bottom: 1em;

  i {
    position: absolute;
    top: -10px;
    left: 15px;
    font-size: 2.1rem;
    color: #bfbfbf;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      color: #333;
    }
  }
`;

export default class SideBar extends Component {
  componentDidMount() {
    // hides the left navigation menu, reduces width of the top navbar and adjust the body
    document.body.style.paddingLeft = '0px';
    document.getElementById('sideNavBar').style.width = '0px';
    document.getElementById('topNavBar').style.width = '77%';
    document.getElementById('topNavBar').style.paddingLeft = '0px';
    document.getElementById('topNavBar').style.left = '0';
    document.body.style.overflowY = 'hidden';
  }

  componentWillUnmount() {
    // shows the left navigation menu, increases the width of the top navbar and adjusts the body
    document.body.style.paddingLeft = '250px';
    document.getElementById('sideNavBar').style.width = '250px';
    document.getElementById('topNavBar').style.paddingLeft = '230px';
    document.getElementById('topNavBar').style.width = '100%';
    document.getElementById('topNavBar').style.right = '0';
    document.body.style.overflowY = 'overlay';
  }
  // Runs callback function from props to close the sidebar
  handleOnClick = () => {
    this.props.onClick();
  };

  // Disables body scroll when mouse is hovered over sidebar
  disableBodyScroll = () => {
    document.body.style.position = 'fixed';
    document.body.style.overflowY = 'hidden';
  };

  // Enables body scroll when mouse is not hovered over sidebar
  enableBodyScroll = () => {
    document.body.style.position = 'static';
    document.body.style.overflowY = 'overlay';
  };

  render() {
    // Conditionally renders the sidebar based on which button was pressed in QuoteControlsPane.js
    let component;

    if (this.props.action === 'SendEmail') {
      component = <SendEmail />;
    } else if (this.props.action === 'Attachments') {
      component = <Attachments attachments={this.props.attachments} />;
    } else if (this.props.action === 'Followups') {
      component = <Followups followups={this.props.followups} />;
    } else if (this.props.action === 'SocialLinks') {
      component = <SocialLink socialMedia={this.props.socialMedia} />;
    }

    return (
      <Wrapper
        onMouseEnter={this.disableBodyScroll}
        onMouseLeave={this.enableBodyScroll}
      >
        <BodyWrapper>
          <Close>
            <i onClick={this.handleOnClick} class="fas fa-bars"></i>
          </Close>
          {component}
        </BodyWrapper>
      </Wrapper>
    );
  }
}
