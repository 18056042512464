import React, { Component } from 'react';
import styled from 'styled-components';
import Moment from 'moment';
import { parse } from 'node-html-parser';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 1em 3em;
  width: 100%;
  height: fit-content;
  border: 1px solid #e8edf1;
  text-align: center;
  cursor: pointer;

  p {
    text-align: left;
    margin: 0.2em 1em;
  }

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    p {
      margin: 0.2em 1em 0.5em 1em;
      font-weight: bold;
    }
  }
`;

export default class EmailItem extends Component {
  state = { expanded: false, sent: false, confirmed: false };

  handleClick = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  getDomain(email){
    let fromDomainIndex = email.search("@")
    let fromDomain = email.substring(fromDomainIndex+1).replace(">","")

    return fromDomain
  }

  render() {
    let data = this.props.data
    let body = parse(data.sBody).text
    let commentStartIndex = body.search("<!--")
    let commentEndIndex = body.search("-->")
    if(commentEndIndex){
      body = body.substring(commentEndIndex+3)
    }

    let fromDomain = this.getDomain(data.sFrom)
    let toDomain = this.getDomain(data.sTo)
    let customerDomain = this.getDomain(this.props.quote.sEmail)
    
    let outgoing = false
    if(fromDomain === "camerasecuritynow.com" || fromDomain === "rentacomputer.com" || fromDomain === "cfrinc.us"){
      outgoing = true
    }

    let withCustomer = false    
    if(customerDomain === toDomain || customerDomain === fromDomain){
      withCustomer = true
    }

    return (
      <Wrapper style={{backgroundColor: outgoing ? "#ccc" : "#fff"}}>
        {withCustomer && (

        
        <div>
          <i
            style={{ paddingRight: '5%', fontSize: '1.2rem' }}
            className="fa fa-star"
          ></i>
        </div>
        )}
        <div>
          <p>From: {data.sFrom}</p>
          
          <p>
            {Moment(data.dtDate).utc().format('L')}
            {' - '}
            {Moment(data.dtDate).utc().format('LT')}
          </p>
        </div>
        <div>
          <p>To: {data.sTo}</p>
        </div>
        <p>{data.sSubject}</p>
        <p>{body}</p>
      </Wrapper>
    );
  }
}
