import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  background-color: #fff;
  width: 40%;
  height: fit-content;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.1);
  text-align: center;
  border-radius: 15px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4aabed;
  color: #fff;
  height: 35px;
  font-weight: bold;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
`;

const BodyWrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin: 1em 0;

  button {
    cursor: pointer;
    width: 70%;
    margin: 0.7em auto;
    height: 50px;
    border: none;
    transition: all 0.2s;
    border-radius: 4px;
    background-color: #e8edf1;

    &:hover {
      background-color: #4aabed;
      color: #fff;
    }
  }

  select {
    width: 70%;
    height: 37px;
    padding: 0 0.7em;
    margin: 0.5em auto;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  select:nth-child(14) {
    margin-bottom: 1em;
  }

  input {
    width: 70%;
    height: 37px;
    padding: 0.7em;
    margin: 0.5em auto;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  p {
    font-weight: bold;
    text-align: left;
    margin: 0.5em 0 0 15%;
  }
`;

export default class QuoteActions extends Component {
  state = {};

  handleOnClick = (event) => {
    let tab = event.target.textContent
      .replace(/\s/g, '')
      .replace(/ *\([^)]*\) */g, '');
    this.props.onClick(tab);
  };

  render() {
    let paymentMethod;
    let paid;

    if (this.props.bCC === true) {
      paymentMethod = '1';
    } else if (this.props.bNet === true) {
      paymentMethod = '2';
    } else if (this.props.bCOD === true) {
      paymentMethod = '3';
    }

    if (this.props.nPaid) {
      paid = 'Paid';
    } else {
      paid = 'Unpaid';
    }

    return (
      <Wrapper>
        <Title>Quote Actions</Title>
        <BodyWrapper>
          <p>Brand:</p>
          <select
            id="brand"
            name="brand"
            defaultValue={this.props.sQuoteType}
            onChange={(event)=>this.props.update("sQuoteType", event.target.value)}
          >
            <option value="Rentacomputer.com">Rentacomputer.com</option>
            <option value="CameraSecurityNow.com">CameraSecurityNow.com</option>
            <option value="ComputerServiceNow.com">ComputerServiceNow.com</option>
          </select>

          <p>Type:</p>
          <select
            id="type"
            name="type"
            defaultValue={this.props.sType}
            onChange={(event)=>this.props.update("sType", event.target.value)}
          >
            <option value="Select Type" hidden>Select Type</option>
            <option value="Phone">Phone</option>
            <option value="Text">Text</option>
            <option value="Web">Web</option>
            <option value="Email">Email</option>
            <option value="Live Person">Live Person</option>
            <option value="Repeat">Repeat</option>
            <option value="Used Sales">Used Sales</option>
            <option value="CSN Sales">CSN Sales</option>
            <option value="Duplicate">Duplicate</option>
            <option value="Prospect">Prospect</option>
            <option value="Walk-In">Walk-In</option>
            <option value="Cold Call">Cold Call</option>
          </select>

          <p>Potential:</p>
          <select
            id="potential"
            name="potential"
            defaultValue={this.props.nPotential}
            onChange={(event)=>this.props.update("nPotential", parseInt(event.target.value))}
          >
            <option value="Select Potential" hidden>Select Potential</option>
            <option value="1">{'1 - < $250'}</option>
            <option value="2">2 - $250 - $999</option>
            <option value="3">3 - $1000 - $4,999</option>
            <option value="4">{'4 - < $5000 - $9,999'}</option>
            <option value="5">{'5 - > $10,000'}</option>
          </select>

          <p>Payment Method:</p>
          <select
            id="paymentMethod"
            name="paymentMethod"
            defaultValue={paymentMethod}
            onChange={(event)=>this.props.update("[paymentMethod]", event.target.value)}
          >
            <option value="Select Payment Method" hidden>
              Select Payment Method
            </option>
            <option value="Credit Card">Credit Card</option>
            <option value="Net Terms">Net Terms</option>
            <option value="Prepay Check">Prepay Check</option>
          </select>

          <p>Contract Type:</p>
          <select
            id="contractType"
            name="contractType"
            defaultValue={this.props.nContractType}
            onChange={(event)=>this.props.update("nContractType", parseInt(event.target.value))}
          >
            <option value="0" hidden>Select Contract Type</option>
            <option value="1">Fixed Rental</option>
            <option value="2">Open Rental</option>
            <option value="3">Non-Rental</option>
          </select>

          <p>Delivery Type:</p>
          <select
            id="deliveryType"
            name="deliveryType"
            defaultValue={this.props.nDeliveryType}
            onChange={(event)=>this.props.update("nDeliveryType", parseInt(event.target.value))}
          >
            <option value="0" hidden>Select Delivery Type</option>
            <option value="1">Delivery</option>
            <option value="2">Shipping</option>
          </select>

          <button onClick={this.handleOnClick}>Send Email</button>
          <button onClick={this.handleOnClick}>
            Attachments ({this.props.attachmentsCount})
          </button>
          <button onClick={this.handleOnClick}>
            Followups ({this.props.followupsCount})
          </button>
          <button onClick={this.handleOnClick}>
            Social Links ({this.props.socialMediaCount})
          </button>
          <button>Save PDF</button>
          <button>Create PDF</button>
        </BodyWrapper>
      </Wrapper>
    );
  }
}
