import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { cGreen, cRed, nQuoteTotalNoDollar, whichRep } from '../../../../assets/functions/calculations';
import moment from 'moment';
import { CustomDateWrapper, Input, SubmitText, GreyTextDiv } from '../../../styled/ui';



export default class OpenIssuesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    
    return (
      <table className="dashboard">
          <thead>
            <tr>
              <th>Quote Number</th>
              <th>Company</th>
              <th>Contact Name</th>
              <th>Location</th>
              <th>Title</th>
              <th>Reps</th>
              <th>Install Complete Date</th>
              <th>Issue Reported Date</th>
              <th>Quote Amount</th>
              <th>Quote Status</th>
              <th>Quote Tags</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.props.data.map((issue, index) => (
              <tr key={index}>
                <td><Link target="_blank" to={`/quote/${issue.id}`}>{issue.id}</Link></td>
                <td>{issue.sCompany}</td>
                <td>{issue.sName}</td>
                <td>{issue.sAddress}{issue.sAddress2 ? (<><br />{issue.sAddress2}</>) : ""}<br />{issue.sCity}, {issue.sState} {issue.sZip}</td>
                <td>{issue.sTitle}</td>
                <td>{whichRep(issue.nRep)}{issue.nRep2 ? (<><br />{whichRep(issue.nRep2)}</>): ""}{issue.nRep3 ? (<><br />{whichRep(issue.nRep3)}</>) : ""}</td>
                <td>{issue.dtComplete ? moment(issue.dtComplete).utc().format('MM/DD/YYYY') : ""}</td>
                <td>
                  <CustomDateWrapper><Input onChange={(e) => {this.props.handleDateChange(e, index, 'dtIssueReported')}} type="date" id="date" name="date" 
                    value={moment(issue.dtIssueReported).utc().format('YYYY-MM-DD')} /></CustomDateWrapper>
                  <SubmitText onClick={() => this.props.handleIssueReportedDateChange(issue.dtIssueReported,issue.id)}>Submit</SubmitText>
                </td>
                <td>{nQuoteTotalNoDollar(issue.nQuoteTotal)}</td>
                <td>{issue.sStatus}</td>
                <td>
                  <textarea style={{height: '80px', width: '100%'}} onInput={(event) => this.props.handleUpdateTag(event.target.value, index)} value={issue.sQuoteTags}></textarea>
                  {issue.changed && (<div onClick={() => this.props.handleSaveTag(issue.sQuoteTags, issue.id)}>Save Tag</div>)}
                </td>
                <td>
                  <div onClick={()=>this.props.handleCloseIssue(issue.id)}>
                    <i className={'fa fa-ban fa-4x fa-fw'} /><br />Close Issue
                  </div>                  
                </td>
              </tr>
            ))}
          </tbody>
        </table>
    );
  }
}
