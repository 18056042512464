import React, { Component } from 'react';
import styled from 'styled-components';
import Axios from 'axios';
import ReactTooltip from 'react-tooltip';
import ReportIssue from '../misc/ReportIssue';
import UserCard from '../misc/UserCard';
import Cookies from 'js-cookie';

const Wrapper = styled.section`
  padding: 1em 0em;
  text-align: center;

  @media (max-width: 900px) {
    padding-bottom: 5em;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  max-width: 500px;
  width: 90vw;
  border-radius: 10px;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.1);
  margin: 3em auto;
`;

const Title = styled.h1`
  padding: 2em 0 1em 0;
  text-align: center;
`;

const Input = styled.input`
  width: 90%;
  padding: 12px;
  padding: 0em 3em 0em 1em;
  margin: 0.5em auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  line-height: 40px;
  font-family: Noto Sans JP, sans-serif, FontAwesome;
`;

const Button = styled.button`
  display: inline-block;
   padding: 0.8em 2.5em;
   margin: 1em 0.3em 1em 0;
   text-decoration: none;
   color: #fafafa;
   text-align: center;
   transition: all 0.2s;
  background-color: rgba(54, 162, 235, 0.9);
  border: 1px solid #fafafa;
  cursor: pointer;
  border-radius: 50px;

  &:hover {
    border: 1px solid #d7d7d7;
  }
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

const AlertMessage = styled.p`
  position: absolute;
  color: red;
  top: 77px;
`;

const UserCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  padding: 1rem 0;
  margin: auto;
  justify-content: center;
`;

const Filter = styled.div`
  display: flex;
  justify-content: center;
  margin: 1em 0;
`;

const FilterOption = styled.p`
  margin: 0 1em;
  font-weight: 600;
  cursor: pointer;
`;

class Settings extends Component {
  state = {
    user: '',
    loaded: false,
    bAdmin: false,
    flashError: '',
    users: [],
  };

  // Async Axios
  fetchUser = async () => {
    try {
      const passport = await Axios.get('/userSession', {
        credentials: 'include',
      });
      if (passport.data.passport) {
        this.setState({
          user: passport.data.passport.user,
        });
      }
      const response = await Axios.get('/user', {
        credentials: 'include',
      });
      if (
        response.data.result[0].recordset[0].sTTATitle === 'Developer' ||
        response.data.result[0].recordset[0].sTTATitle === 'CEO'
      ) {
        this.setState({
          bAdmin: !this.state.bAdmin,
        });
        Cookies.set('sTTATitle', 'admin');
      } else {
        if (response.data.result[0].recordset[0].bAdmin === true) {
          this.setState({
            bAdmin: !this.state.bAdmin,
          });
          Cookies.set('sTTATitle', 'admin');
        }
      }
      this.fetchAllUsers('active');
    } catch (error) {
      console.error(error);
    }
  };

  // Async Axios
  fetchAllUsers = async (type) => {
    try {
      const users = await Axios.get('/all-users', {
        params: {
          type: type,
        },
        headers: { 'Content-Type': 'application/json' },
      });
      this.setState({
        users: users.data.result[0].recordset,
      });
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    this.fetchUser();
  }

  handleClickUsers(type) {
    this.fetchAllUsers(type);
  }

  render() {
    return (
      <Wrapper>
        <h1>User Account Settings</h1>
        {this.state.user && (
          <>
            <FormWrapper>
              <Title>Change Your Password</Title>
              <AlertMessage>{this.state.flashError}</AlertMessage>
              <form action="/change-password" method="POST" autoComplete="off">
                <label for="newPassword">
                  <Input
                    pattern="^(?=[^A-Z\s]*[A-Z])(?=[^a-z\s]*[a-z])(?=[^\d\s]*\d)(?=\w*[\W_])\S{8,}$"
                    type="password"
                    id="newPassword"
                    name="newPassword"
                    placeholder="New Password"
                    data-lpignore="true"
                    data-tip={
                      'Minimum 8 characters, one uppercase letter, one lowercase letter, one number and one special character'
                    }
                    required
                  />
                  <ReactTooltip />
                </label>
                <ButtonWrapper>
                  <Button type="submit">Change</Button>
                </ButtonWrapper>
              </form>
            </FormWrapper>
            <ReportIssue />
          </>
        )}
        {Cookies.get('sTTATitle') === 'admin' && <h2>Admin Controls</h2>}
        {Cookies.get('sTTATitle') === 'admin' && (
          <FormWrapper>
            <Title>Reset A Users Password</Title>
            <form action="/reset" method="POST" autoComplete="off">
              <label for="name">
                <Input
                  type="text"
                  id="name"
                  name="name"
                  title="Please provide a valid user id number"
                  placeholder="User ID #"
                  pattern="\b(0?[1-9]|[1-9][0-9]|100)\b"
                  required
                />
              </label>
              <ButtonWrapper>
                <Button type="submit">Reset</Button>
              </ButtonWrapper>
            </form>
          </FormWrapper>
        )}
        {this.state.changeToFinished && (
          <FormWrapper>
            <Title>Register A New User</Title>
            <form action="/register" method="POST">
              <label for="name">
                <Input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Username"
                  required
                />
              </label>
              <label for="email">
                <Input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  required
                />
              </label>
              <label for="password">
                <Input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Password"
                  required
                />
              </label>
              <ButtonWrapper>
                <Button type="submit">Register</Button>
              </ButtonWrapper>
            </form>
          </FormWrapper>
        )}
        {Cookies.get('sTTATitle') === 'admin' && (
          <>
            <Title>User Management</Title>
            <Filter>
              <FilterOption onClick={() => this.handleClickUsers('all')}>
                All Users
              </FilterOption>
              <FilterOption onClick={() => this.handleClickUsers('active')}>
                Active Users
              </FilterOption>
              <FilterOption onClick={() => this.handleClickUsers('inactive')}>
                Inactive Users
              </FilterOption>
            </Filter>
            <UserCardContainer>
              {this.state.users.map((sql) => (
                <UserCard
                  key={sql.sUserName}
                  sEmail={sql.sEmail}
                  sHashPassword={sql.sHashPassword}
                  id={sql.id}
                  sTTATitle={sql.sTTATitle}
                  bActive={sql.bActive}
                  bSoundNewQuote={sql.bSoundNewQuote}
                  bAutoGrabLeads={sql.bAutoGrabLeads}
                  bAutoAssignRepeats={sql.bAutoAssignRepeats}
                  nSitOutLeads={sql.nSitOutLeads}
                />
              ))}
            </UserCardContainer>
          </>
        )}
      </Wrapper>
    );
  }
}

export default Settings;

/*

<label for="password">
                <Input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Current Password"
                  required
                />
              </label>
              <label for="newPassword">
                <Input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="New Password"
                  required
                />
              </label>
              <label for="repeatNewPassword">
                <Input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Confirm New Password"
                  required
                />
              </label>

              */
