import { days_between } from "./time";

export function onTimeAmount(state) {
    let white = 0;
    for (let i = 0; i < state.length; i++) {
      let days = days_between(new Date(), state[i].dtDate);
      if (days < 1) {
        white += 1;
      }
    }
    return white;
}
  
export function lateAmount(state) {
    let red = 0;
    for (let i = 0; i < state.length; i++) {
      let days = days_between(new Date(), state[i].dtDate);
      if (days > 1) {
        red += 1;
      }
    }
    return red;
}

export function displayFollowupCount(followups){
  let red = lateAmount(followups)
  let white = onTimeAmount(followups)

  let redHtml = (<span style={{ color: '#ff6666' }}>{red}</span>)
  let dashHtml = (<span>{' - '}</span>)
  let whiteHtml = (<span>{white}</span>)

  let html = []

  if(red > 0){
      html.push(redHtml)
  }
  if(red > 0 && white > 0){
      html.push(dashHtml)
  }
  if(white > 0){
      html.push(whiteHtml)
  }
  
  return (<>{html}</>)
}