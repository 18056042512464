import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Axios from 'axios';
import Moment from 'moment';
import MediaQuery from 'react-responsive';
import Loader from 'react-loader-spinner';
import { calcPreTaxMargin, nQuoteTotalFormatter, nQuoteTotalNoDollar, whichRep } from '../../../assets/functions/calculations';
import { weekStart } from '../../../assets/functions/time';
import { Bar } from 'react-chartjs-2';
import { getSQLData, setSQLData } from '../../../assets/functions/fetch';
import { Title, Wrapper } from '../../styled/ui';
import { matchClosedAndCompleteToMonths, matchClosedAndCompleteToWeeks, matchQuotesAndLines, matchQuotesAndPOLines, organizeChartData, summarizeMonthsAndYears } from '../../../assets/functions/process/cam';
import moment from 'moment';
import RecentlyCompletedMonthTable from './RecentlyCompleted/RecentlyCompletedMonthTable';
import HideShowSubTitle from '../../misc/HideShowSubTitle';
import MonthSummaryTable from './RecentlyCompleted/MonthSummaryTable';
import YearSummaryTable from './RecentlyCompleted/YearSummaryTable';
import RecentlyCompletedChart from './RecentlyCompleted/RecentlyCompletedChart';
import CamMarginChart from './RecentlyCompleted/CamMarginChart';
import RecentlyCompletedWeekTable from './RecentlyCompleted/RecentlyCompletedWeekTable';
import { percentTwoDecimal } from '../../../assets/functions/display';

export default class RecentlyCompleted extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      numMonths: 25,
      selectedWeek: 1,
      showChart: "Margin",
      summaryData: {},
    };
    this.handleShowPO = this.handleShowPO.bind(this)
    this.handleSetLabor = this.handleSetLabor.bind(this)
    this.handleHideShow = this.handleHideShow.bind(this)
    this.changeSelectedWeek = this.changeSelectedWeek.bind(this)
  }

  fetchData = async () => {

    try {
      const fetch = await getSQLData("cam/recentlyCompleted", {numMonths: this.state.numMonths})
      const fetchPOs = await getSQLData("cam/recentlyCompletedPOs", {numMonths: this.state.numMonths})
      const fetchQuoteLines = await getSQLData("cam/recentlyCompletedLines", {numMonths: this.state.numMonths})
      
      if(fetch && fetchPOs && fetchQuoteLines){

        let data = fetch.data.data[0].recordset    
        let query = fetch.query

        let POData = fetchPOs.data.data[0].recordset
        let POQuery = fetchPOs.query

        let quoteLines = fetchQuoteLines.data.data[0].recordset
        
        
        this.setState({
          data: data,
          query: query,
          POData: POData,
          POQuery: POQuery,
          quoteLines: quoteLines,
        }, () => this.processData())
      }
         
    } catch (error) {
      console.error(error);
    }
  }

  processData() {

    let data = matchQuotesAndPOLines(this.state.data, this.state.POData)
    data = matchQuotesAndLines(data, this.state.quoteLines)
    let months = matchClosedAndCompleteToMonths(data, this.state.numMonths)    
    months = summarizeMonthsAndYears(months)
    let summaryData = matchClosedAndCompleteToWeeks(data)

    let hideMonth = []
    let showYear = []
    for(let i=0; i<months.length; i++){
      hideMonth[i]=true
      showYear[i]=0
    }
    hideMonth[0]=false

    let chartData = organizeChartData(months)
    
    this.setState({
      data: data,
      months: months,
      hideMonth: hideMonth,
      showYear: showYear,
      chartData: chartData,
      summaryData: summaryData,
      loaded: true,
  })
  }

  componentDidMount() {
    this.fetchData();
  }

  handleShowPO(index){
    let summaryData = this.state.summaryData
    let weekQuotes = summaryData.weekQuotes[this.state.selectedWeek]
    
    weekQuotes[index].showPOs = !weekQuotes[index].showPOs
    this.setState({summaryData: summaryData})
  }

  handleSetLabor = async (lineID, itemNum) => {
    console.log(lineID, itemNum)
    let newValue = (itemNum === 832 ? null : 832)

    try {
      const fetch = await setSQLData("cam/setPOLineItem", {
        lineID: lineID,
        itemNum: newValue,
      })
      
      if(fetch){
        this.fetchData();
      }
      
    } catch (error) {
      console.error(error);
    }
  }
  handleHideShow(index){
    let hideMonth = this.state.hideMonth
    hideMonth[index] = !hideMonth[index]
    this.setState({hideMonth: hideMonth})
  }
  handleChangeYear(month, year){
    let showYear = this.state.showYear
    showYear[month] = year
    this.setState({showYear: showYear})
  }
  changeSelectedWeek(week){
    this.setState({selectedWeek: week})
  }

  render() {
      let summaryData = this.state.summaryData
      let selectedWeek = this.state.selectedWeek
      console.log(summaryData)
      let selectedWeekSales = summaryData.sales ? summaryData.sales[selectedWeek] : 0
      let selectedWeekTargetSales = summaryData.targetSales ? summaryData.targetSales[selectedWeek] : 0
      let selectedWeekAverageSales = summaryData.movingAverageSales ? summaryData.movingAverageSales[selectedWeek] : 0
      let selectedWeekMargin = summaryData.margin ? summaryData.margin[selectedWeek] : 0
      let selectedWeekTargetMargin = summaryData.targetMargin ? summaryData.targetMargin[selectedWeek] : 0
      let selectedWeekAverageMargin = summaryData.movingAverageMargin ? summaryData.movingAverageMargin[selectedWeek] : 0
      let selectedWeekLabor = summaryData.labor ? summaryData.labor[selectedWeek] : 0
      let selectedWeekTargetLabor = summaryData.targetLabor ? summaryData.targetLabor[selectedWeek] : 0
      let selectedWeekAverageLabor = summaryData.movingAverageLabor ? summaryData.movingAverageLabor[selectedWeek] : 0
    
    
    return (
      <>
      {this.state.loaded && (
        <Wrapper>
          <div>
            <div style={{padding: '10px', display: 'inline-block', fontSize: 18, fontWeight: this.state.showChart === "Margin" ? "bold" : ""}} onClick={()=>this.setState({showChart: "Margin"})}>Margin</div>
            <div style={{padding: '10px', display: 'inline-block', fontSize: 18, fontWeight: this.state.showChart === "Sales" ? "bold" : ""}} onClick={()=>this.setState({showChart: "Sales"})}>Sales</div>
            <div style={{padding: '10px', display: 'inline-block', fontSize: 18, fontWeight: this.state.showChart === "Labor" ? "bold" : ""}} onClick={()=>this.setState({showChart: "Labor"})}>Labor</div>
          </div>
          {this.state.showChart === "Margin" && (
            <CamMarginChart 
              type={"Margin"}
              weeks={this.state.summaryData.weeks.slice(0,52).reverse()}
              margin={this.state.summaryData.margin.slice(0,52).reverse()}
              movingAverage={this.state.summaryData.movingAverageMargin.slice(0,52).reverse()}
              target={this.state.summaryData.targetMargin.slice(0,52).reverse()}
              upperBound={this.state.summaryData.upperBoundMargin}
              lastYearMargin={this.state.summaryData.movingAverageMargin.slice(52,104).reverse()}
              changeSelectedWeek={this.changeSelectedWeek}
            />
          )}
          {this.state.showChart === "Sales" && (
            <CamMarginChart 
              type={"Sales"}
              weeks={this.state.summaryData.weeks.slice(0,52).reverse()}
              margin={this.state.summaryData.sales.slice(0,52).reverse()}
              movingAverage={this.state.summaryData.movingAverageSales.slice(0,52).reverse()}
              target={this.state.summaryData.targetSales.slice(0,52).reverse()}
              upperBound={this.state.summaryData.upperBoundSales}
              lastYearMargin={this.state.summaryData.movingAverageSales.slice(52,104).reverse()}
              changeSelectedWeek={this.changeSelectedWeek}
            />
          )}
          {this.state.showChart === "Labor" && (
            <CamMarginChart 
              type={"Labor"}
              weeks={this.state.summaryData.weeks.slice(0,52).reverse()}
              margin={this.state.summaryData.labor.slice(0,52).reverse()}
              movingAverage={this.state.summaryData.movingAverageLabor.slice(0,52).reverse()}
              target={this.state.summaryData.targetLabor.slice(0,52).reverse()}
              upperBound={this.state.summaryData.upperBoundLabor}
              lastYearMargin={this.state.summaryData.movingAverageLabor.slice(52,104).reverse()}
              changeSelectedWeek={this.changeSelectedWeek}
            />
          )}
          <div style={{width:'500px'}}>
          <Title>Week: {this.state.summaryData.weeks[this.state.selectedWeek]}</Title>
          
          <table className="dashboard">
            <thead>
              <tr>
                <th>Category</th>
                <th>Week</th>
                <th>Moving Average</th>
              </tr>
            </thead>
            <tr>
              <td><b>Margin</b></td>
              <td>
                {nQuoteTotalNoDollar(selectedWeekMargin)}&nbsp;
                (
                  {selectedWeekMargin>selectedWeekTargetMargin ? "+" : ""}
                  {percentTwoDecimal((selectedWeekMargin-selectedWeekTargetMargin)/selectedWeekTargetMargin)}
                )
              </td>
              <td>
                {nQuoteTotalNoDollar(selectedWeekAverageMargin)}&nbsp;
                (
                  {selectedWeekAverageMargin>selectedWeekTargetMargin ? "+" : ""}
                  {percentTwoDecimal((selectedWeekAverageMargin-selectedWeekTargetMargin)/selectedWeekTargetMargin)}
                )
              </td>
            </tr>
            <tr>
              <td><b>Sales</b></td>
              <td>
                {nQuoteTotalNoDollar(selectedWeekSales)}&nbsp;
                (
                  {selectedWeekSales>selectedWeekTargetSales ? "+" : ""}
                  {percentTwoDecimal((selectedWeekSales-selectedWeekTargetSales)/selectedWeekTargetSales)}
                )
              </td>
              <td>
                {nQuoteTotalNoDollar(selectedWeekAverageSales)}&nbsp;
                (
                  {selectedWeekAverageSales>selectedWeekTargetSales ? "+" : ""}
                  {percentTwoDecimal((selectedWeekAverageSales-selectedWeekTargetSales)/selectedWeekTargetSales)}
                )
              </td>
            </tr>
            <tr>
              <td><b>Labor</b></td>
              <td>
                {nQuoteTotalNoDollar(selectedWeekLabor)}&nbsp;
                (
                  {selectedWeekLabor>selectedWeekTargetLabor ? "+" : ""}
                  {percentTwoDecimal((selectedWeekLabor-selectedWeekTargetLabor)/selectedWeekTargetLabor)}
                )
              </td>
              <td>
                {nQuoteTotalNoDollar(selectedWeekAverageLabor)}&nbsp;
                (
                  {selectedWeekAverageLabor>selectedWeekTargetLabor ? "+" : ""}
                  {percentTwoDecimal((selectedWeekAverageLabor-selectedWeekTargetLabor)/selectedWeekTargetLabor)}
                )
              </td>
            </tr>
            
          </table>
          </div>
          <RecentlyCompletedWeekTable
            quotes={this.state.summaryData.weekQuotes[this.state.selectedWeek]}
            weekIndex={this.state.selectedWeek}
            handleShowPO={this.handleShowPO}
            handleSetLabor={this.handleSetLabor}
          />





          {false && (
            <>
            
          {this.state.months.map((month,index)=>(
            <>
              <HideShowSubTitle name={moment(month.years[0].month).format('MMMM')} hideAll={this.state.hideMonth[index]} handleHideShow={this.handleHideShow} index={index} />
              {this.state.hideMonth[index] || (
                <>
                  
                  <MonthSummaryTable
                    month={month}
                  />
                  {month.yearNums.map((year,index2)=>(
                    <button key={index2} onClick={()=>this.handleChangeYear(index, index2)}>{year}</button>
                  ))}
                  <RecentlyCompletedMonthTable 
                    quotes={month.years[this.state.showYear[index]].completed.all}
                    monthIndex={index}
                    handleShowPO={this.handleShowPO}
                    handleSetLabor={this.handleSetLabor}
                  />
                  
                </>
              )}
            </>
          ))}
          <RecentlyCompletedChart
                    chartData={this.state.chartData}
                    />
          <YearSummaryTable 
            month={this.state.months}
            />
            </>
          )}
        </Wrapper>
      )}
      </>
      
    );
  }
}
