import React, { Component } from 'react';
import styled from 'styled-components';
import CostSheetBreakdown from './CostSheetBreakdown';
import QuoteLine from '../cost-sheet/QuoteLine';
import PoLines from './PoLines';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

const Row = styled.section`
  display: flex;
  flex-direction: row;
`;

export default class CostSheet extends Component {
  state = {};

  render() {
    return (
      <Wrapper>
        {/* <Row>
          <CostSheetBreakdown
            labor=""
            insurance=""
            shipping=""
            hardware=""
            cost=""
            margin=""
            totalPrice=""
            side={'left'}
          />
          <CostSheetBreakdown
            labor=""
            insurance=""
            shipping=""
            hardware=""
            cost=""
            margin=""
            totalPrice=""
            side={'right'}
          />
        </Row> */}
        <Row>
          <QuoteLine
            quoteLines={this.props.quoteLines}
            nDelivery={this.props.data.nDelivery}
            nTaxRate={this.props.data.nTaxRate}
          />
          <PoLines POs={this.props.POs} />
        </Row>
      </Wrapper>
    );
  }
}
