import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Axios from 'axios';
import Moment from 'moment';
import MediaQuery from 'react-responsive';
import Loader from 'react-loader-spinner';
import { Bar } from 'react-chartjs-2';

const Wrapper = styled.div`
  width: 99%;
  margin: 0 auto;
  max-width: 1600px;

  table {
    width: 100%;
    position: relative;
    ${(props) => {
      if (props.amount > 10)
        return `
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;

    thead + tbody tr:last-child td:last-child,
    tfoot tr:last-child td:last-child {
      border-bottom-right-radius: 0px !important;
    }
    thead + tbody tr:last-child td:first-child,
    tfoot tr:last-child td:first-child {
      border-bottom-left-radius: 0px !important;
    }
    margin-bottom: 7em;
        `;
    }}
  }

  button {
    position: absolute;
    width: 100%;
    bottom: -27px;
     padding: 0.35em 1.2em;
     text-decoration: none;
     color: #fafafa;
     text-align: center;
     transition: all 0.2s;
    background-color: #4aabed;
    border: 1px solid #4aabed;
    cursor: pointer;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    &:hover {
      color: #222;
      background-color: #fafafa;
      border: 1px solid #d7d7d7;
    }
  }
`;

const SubTitle = styled.h2`
  padding: 2em 0 0 0;
  text-align: center;
`;

const CustomDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    margin: 1em 0;
  }

  input {
    width: 140px;
     padding: 0.2em 0em 0.2em 0.2em;
     margin: 0 0 0.5em 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;

    @media (max-width: 900px) {
      width: auto;
      margin: auto;
    }
  }
`;

const AlertMessage = styled.p`
  font-weight: bold;
`;

export default class RacNewMarginChart extends Component {
  state = {
    loaded: false,
    showChart: true,
    weeks: [],
    margin: [],
    movingAverage: [],
    target: [],
    upperBound: 10000,
    lastYearMargin: [],
  };

  componentDidMount() {
    this.setState({
      weeks: this.props.weeks,
      margin: this.props.margin,
      movingAverage: this.props.movingAverage,
      lastYearMargin: this.props.lastYearMargin,
      upperBound: this.props.upperBound,
      target: this.props.target,
    }, () => this.initData());
  }

  removeLastElement(array){
    let newArray = []
    for(let i=0; i<array.length-1; i++){
      newArray[i] = array[i]
    }
    return newArray
  }

  initData(){
    let weeksPastOnly = this.removeLastElement(this.state.weeks)
    let marginPastOnly = this.removeLastElement(this.state.margin)
    let movingAveragePastOnly = this.removeLastElement(this.state.movingAverage)
    let lastYearMarginPastOnly = this.removeLastElement(this.state.lastYearMargin)
    let targetPastOnly = this.removeLastElement(this.state.target)

    this.setState({
      loaded: true,
      showPastOnly: false,
      weeksPastOnly: weeksPastOnly,
      marginPastOnly: marginPastOnly,
      movingAveragePastOnly: movingAveragePastOnly,
      lastYearMarginPastOnly: lastYearMarginPastOnly,
      targetPastOnly: targetPastOnly,
    })
  }

  render() {
    return (
      <Wrapper>
        <SubTitle>{this.props.type} By Week

        </SubTitle>        
        <div>Hide This Week <input type="checkbox" value={this.state.showPastOnly} onClick={()=>this.setState({showPastOnly: !this.state.showPastOnly})}></input></div>
        {this.state.showChart && (
            <Bar
              
              data={{
                labels: this.state.showPastOnly ? this.state.weeksPastOnly : this.state.weeks,
                datasets: [
                  {
                    type: "bar",
                    lineTension: 0,
                    data: this.state.showPastOnly ? this.state.marginPastOnly : this.state.margin,
                    label: 'Margin',
                    backgroundColor: '#bbb',
                    fill: false,
                    yAxisID: 'y-axis-1',
                    order: 4,
                  },
                  {
                    type: "line",
                    lineTension: 0.3,
                    data: this.state.showPastOnly ? this.state.movingAveragePastOnly : this.state.movingAverage,
                    label: '4 Week Moving Average',
                    borderColor: '#000',
                    fill: false,
                    yAxisID: 'y-axis-1',
                    order: 1,
                  },
                  {
                    type: "line",
                    lineTension: 0,
                    data: this.state.showPastOnly ? this.state.targetPastOnly : this.state.target,
                    label: 'Target',
                    borderColor: '#f00',
                    fill: false,
                    yAxisID: 'y-axis-1',
                    order: 2,
                  },
                  {
                    type: "line",
                    lineTension: 0.3,
                    data: this.state.showPastOnly ? this.state.lastYearMarginPastOnly : this.state.lastYearMargin,
                    label: 'Last Year',
                    borderColor: '#090',
                    borderDash: [10, 10],
                    fill: false,
                    yAxisID: 'y-axis-1',
                    order: 3
                  },
                ],
              }}
                width={100}
                height={35}
                options={{
                  onClick: (event, element)=>{this.props.changeSelectedWeek(51-element[0]._index)},
                  scales: {
                    yAxes: [
                      {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-1',
                        ticks: {
                          max: this.state.upperBound,
                          min: 0,
                        },
                      },
                    ], 
                  },
                                 
                responsive: true,
                maintainAspectRatio: true,
                //bezierCurve: false,
                title: {
                  display: false,
                  fontSize: 16,
                  text: "Total Rental Margin"
                },
                legend: {
                  display: true,
                },
                }}
            />
            )}
      </Wrapper>
    );
  }
}
