import React, { Component } from 'react';
import styled from 'styled-components';
import TrackingItem from './TrackingItem'

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin: 0.5em 0;
  width: 100%;
  height: fit-content;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.1);
  text-align: center;
  border-radius: 15px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4aabed;
  color: #fff;
  height: 35px;
  font-weight: bold;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 2em auto;
  width: 90%;
  max-width: 600px;
  align-items: top;

  p {
    text-align: left;
    margin: 0.4em 0;
  }

  button {
    cursor: pointer;
    width: 80%;
    max-width: 400px;
    margin: ${(props) =>
      props.returnPackages.length > 0 ? '2em auto 0 auto' : '0 auto'};
    height: 40px;
    border: none;
    transition: all 0.2s;
    border-radius: 4px;

    &:hover {
      background-color: #4aabed;
      color: #fff;
    }
  }
`;

export default class ReturnTracking extends Component {
  state = { sent: false, confirmed: false };

  render() {
    return (
      <Wrapper>
        <Title>Return Tracking</Title>
        <BodyWrapper returnPackages={this.props.returnPackages}>
          {this.props.returnPackages.map((sql) => (
            <TrackingItem
              key={sql.id}
              sTrackingNumber={sql.sTrackingNumber}
              sCarrierCode={sql.sCarrierCode}
              sStatusCode={sql.sStatusCode}
              dtEstimatedDelivery={sql.dtEstimatedDelivery}
              dtRealDelivery={sql.dtRealDelivery}
            />
          ))}
          <button>New Return Tracking</button>
        </BodyWrapper>
      </Wrapper>
    );
  }
}
