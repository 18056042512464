import React, { Component } from 'react';
import styled from 'styled-components';
import Axios from 'axios';
import 'font-awesome/css/font-awesome.min.css';


const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  max-width: 500px;
  width: 90vw;
  border-radius: 10px;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.1);
  margin: 3em auto;
`;

const Title = styled.h1`
  text-align: center;
  margin: 1em 0;
  color: #333;

  @media (max-width: 500px) {
    font-size: 1.5rem;
    margin: 2em 0;
  }
`;

const LoginContainer = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-image: radial-gradient(
      circle at 17% 77%,
      rgba(17, 17, 17, 0.04) 0%,
      rgba(17, 17, 17, 0.04) 50%,
      rgba(197, 197, 197, 0.04) 50%,
      rgba(197, 197, 197, 0.04) 100%
    ),
    radial-gradient(
      circle at 26% 17%,
      rgba(64, 64, 64, 0.04) 0%,
      rgba(64, 64, 64, 0.04) 50%,
      rgba(244, 244, 244, 0.04) 50%,
      rgba(244, 244, 244, 0.04) 100%
    ),
    radial-gradient(
      circle at 44% 60%,
      rgba(177, 177, 177, 0.04) 0%,
      rgba(177, 177, 177, 0.04) 50%,
      rgba(187, 187, 187, 0.04) 50%,
      rgba(187, 187, 187, 0.04) 100%
    ),
    linear-gradient(19deg, rgb(28, 117, 250), rgb(54, 162, 235));
`;

const LoginWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  width: 90vw;
  height: 70%;
  min-height: 350px;
  max-height: 500px;
  background-color: #fafafa;
  color: rgba(54, 162, 235, 0.9);
  border-radius: 10px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);

  form div i {
    height: 45px;
    font-size: 1rem;
  }
`;

const Input = styled.input`
  width: 80%;
  max-width: 300px;
  height: 45px;
  border: 1.5px solid #ececec;
  padding: 0em 3em 0em 1em;
  margin: 0.5em 0em;
  background-color: #ececec;
  line-height: 40px;
  font-family: Noto Sans JP, sans-serif, FontAwesome;
`;

const Button = styled.button`
  display: inline-block;
   padding: 0.8em 2.5em;
   margin: 2em 0.3em 0.3em 0;
   text-decoration: none;
   color: #fafafa;
   text-align: center;
   transition: all 0.2s;
  background-color: rgba(54, 162, 235, 0.9);
  border: 1px solid #fafafa;
  cursor: pointer;
  border-radius: 50px;

  &:hover {
    border: 1px solid #d7d7d7;
  }

  @media (max-width: 500px) {
    margin: 4em 0.3em 0.3em 0;
  }
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

const AlertMessage = styled.p`
  position: absolute;
  color: red;
  top: 77px;
`;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flashError: '',
      lockedOut: false
    };
  }
  // Async Axios
  fetchData = async () => {
    try {
      const response = await Axios.get('/userSession', {
        credentials: 'include',
      });
      if (response.data.flash) {
        this.setState({
          flashError:
            response.data.flash.error[response.data.flash.error.length - 1],
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {

    window.addEventListener('keydown',(event) => {

      if(event.key === '`'){

        //this.setState({lockedOut: !this.state.lockedOut})
        
      }
    })

    document.body.style.paddingTop = '0';
    document.body.style.paddingLeft = '0';
    this.fetchData();
    this.interval = setInterval(
      () =>
        this.setState({
          flashError: '',
        }),
      5000
    );
  }

  componentWillUnmount() {
    document.body.style.paddingTop = '55px';
    document.body.style.paddingLeft = '250px';
    // Stop fetching data when the user leaves the page and component unmounts
    clearInterval(this.interval);
  }

  render() {
    return (
      <LoginContainer>
        <LoginWrapper>
          <Title>Sign in to cfrinc.app</Title>
          <AlertMessage>{this.state.flashError}</AlertMessage>
          <form
            style={{ width: '90%', textAlign: 'center' }}
            action="/login"
            method="POST"
            autoComplete="nope"
          >
            <label htmlFor="name">
              <div>
                <i className="fas fa-user-alt"></i>
                <Input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  required
                />
              </div>
            </label>
            <label htmlFor="password">
              <div>
                <i className="fas fa-lock"></i>
                <Input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Password"
                  required
                />
              </div>
            </label>
            <ButtonWrapper>
              <Button className="hvr-grow" type="submit">
                Sign In
              </Button>
            </ButtonWrapper>
          </form>
          {this.state.lockedOut && (
            <FormWrapper>
            <Title>Reset A Users Password</Title>
            <form action="/reset" method="POST" autoComplete="off">
              <label for="name">
                <Input
                  type="text"
                  id="name"
                  name="name"
                  title="Please provide a valid user id number"
                  placeholder="User ID #"
                  pattern="\b(0?[1-9]|[1-9][0-9]|100)\b"
                  required
                />
              </label>
              <ButtonWrapper>
                <Button type="submit">Reset</Button>
              </ButtonWrapper>
            </form>
          </FormWrapper>
          )}
        </LoginWrapper>
      </LoginContainer>
    );
  }
}

export default Login;
