import React, { Component } from 'react';
import styled from 'styled-components';
import RacNewMarginChart from './RacNewMarginChart';
import { matchQuotesAndLines, matchQuotesAndPOLines } from '../../../assets/functions/process/cam';
import { getSQLData } from '../../../assets/functions/fetch';
import { matchClosedToWeeks, matchNewQuotesToWeeks } from '../../../assets/functions/process/rac';
import ClosedWeekTable from './ClosedWeekTable';
import RepSummaryTable from './RepSummaryTable';
import { ReportWrapper } from '../../styled/ui';
import NewQuotesTable from './NewQuotesTable';
import RepLeadTable from './RepLeadTable';





export default class FirstClosedRac extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loaded: false,
      showAll: false,
      showAllLowMargin: false,
      showTop: true,
      showLowMargin: true,
      weeks: [],
      margin: [],
      movingAverage: [],
      upperBound: 10000,
      lastWeek: [],
      topMargin: [],
      lowMargin: [],
      selectedWeek: 1,
      items: [],
      openRentals: [],
      openContracts: [],
      lastYear: [],
      numMonths: 25,
      showChart: "Margin",
    };
    this.changeSelectedWeek = this.changeSelectedWeek.bind(this)
  }


  // Async Axios
  fetchData = async () => {
    try {
      const fetch = await getSQLData("rac/recentlyClosed", {numMonths: this.state.numMonths})
      const fetchNewQuotes = await getSQLData("rac/newQuotes", {numMonths: this.state.numMonths})

      if(fetch && fetchNewQuotes){

        let data = fetch.data.data[0].recordset    
        let newQuotes = fetchNewQuotes.data.data[0].recordset    
        
        
        this.setState({
          data: data,
          newQuotes: newQuotes,
        }, () => this.processData())
      }
    } catch (error) {
      console.error(error);
    }
  };

  processData() {
    let data = this.state.data
    let summaryData = matchClosedToWeeks(data)

    let newQuotes = this.state.newQuotes
    let newQuoteSummary = matchNewQuotesToWeeks(newQuotes)

    console.log(newQuotes)

    this.setState({
      data: data,
      summaryData: summaryData,
      newQuotes: newQuotes,
      newQuoteSummary: newQuoteSummary,
      loaded: true,
  })
  }


  componentDidMount() {
    this.fetchData();
  }

  changeSelectedWeek(week){
    this.setState({selectedWeek: week})
  }

  render() {
    return (
      <ReportWrapper>
                
        {this.state.loaded && (
          <>
            <div>
              <div>
              <div 
                style={{padding: '10px', display: 'inline-block', fontSize: 18, fontWeight: this.state.showChart === "Margin" ? "bold" : ""}} 
                onClick={()=>this.setState({showChart: "Margin"})}>
                Margin
              </div>
              <div 
                style={{padding: '10px', display: 'inline-block', fontSize: 18, fontWeight: this.state.showChart === "Sales" ? "bold" : ""}} 
                onClick={()=>this.setState({showChart: "Sales"})}>
                Sales
              </div>
              <div 
                style={{padding: '10px', display: 'inline-block', fontSize: 18, fontWeight: this.state.showChart === "New Customers" ? "bold" : ""}} 
                onClick={()=>this.setState({showChart: "New Customers"})}>
                New Customers
              </div>
              <div 
                style={{padding: '10px', display: 'inline-block', fontSize: 18, fontWeight: this.state.showChart === "New Customer Sales" ? "bold" : ""}} 
                onClick={()=>this.setState({showChart: "New Customer Sales"})}>
                New Customer Sales
              </div>
              </div>
              <div>
              <div 
                style={{padding: '10px', display: 'inline-block', fontSize: 18, fontWeight: this.state.showChart === "New Customer Quotes" ? "bold" : ""}} 
                onClick={()=>this.setState({showChart: "New Customer Quotes"})}>
                New Customer Quotes
              </div>
              <div 
                style={{padding: '10px', display: 'inline-block', fontSize: 18, fontWeight: this.state.showChart === "Repeat Customer Quotes" ? "bold" : ""}} 
                onClick={()=>this.setState({showChart: "Repeat Customer Quotes"})}>
                Repeat Customer Quotes
              </div>
              <div 
                style={{padding: '10px', display: 'inline-block', fontSize: 18, fontWeight: this.state.showChart === "All New Quotes" ? "bold" : ""}} 
                onClick={()=>this.setState({showChart: "All New Quotes"})}>
                All New Quotes
              </div>
              <div 
                style={{padding: '10px', display: 'inline-block', fontSize: 18, fontWeight: this.state.showChart === "New Quote Potential" ? "bold" : ""}} 
                onClick={()=>this.setState({showChart: "New Quote Potential"})}>
                New Quote Potential
              </div>
              </div>
            </div>

            

            {this.state.showChart === "Margin" && (
              <RacNewMarginChart
                type={"Margin"}
                weeks={this.state.summaryData.weeks.slice(0,52).reverse()}
                margin={this.state.summaryData.margin.slice(0,52).reverse()}
                movingAverage={this.state.summaryData.movingAverageMargin.slice(0,52).reverse()}
                target={this.state.summaryData.targetMargin.slice(0,52).reverse()}
                upperBound={this.state.summaryData.upperBoundMargin}
                lastYearMargin={this.state.summaryData.movingAverageMargin.slice(52,104).reverse()}
                changeSelectedWeek={this.changeSelectedWeek}
              />
            )}

            {this.state.showChart === "Sales" && (
              <RacNewMarginChart
                type={"Sales"}
                weeks={this.state.summaryData.weeks.slice(0,52).reverse()}
                margin={this.state.summaryData.sales.slice(0,52).reverse()}
                movingAverage={this.state.summaryData.movingAverageSales.slice(0,52).reverse()}
                target={this.state.summaryData.targetSales.slice(0,52).reverse()}
                upperBound={this.state.summaryData.upperBoundSales}
                lastYearMargin={this.state.summaryData.movingAverageSales.slice(52,104).reverse()}
                changeSelectedWeek={this.changeSelectedWeek}
              />
            )}

            {this.state.showChart === "New Customers" && (
              <RacNewMarginChart
                type={"New Customers"}
                weeks={this.state.summaryData.weeks.slice(0,52).reverse()}
                margin={this.state.summaryData.newCustomers.slice(0,52).reverse()}
                movingAverage={this.state.summaryData.movingAverageNewCustomers.slice(0,52).reverse()}
                target={this.state.summaryData.targetNewCustomers.slice(0,52).reverse()}
                upperBound={this.state.summaryData.upperBoundNewCustomers}
                lastYearMargin={this.state.summaryData.movingAverageNewCustomers.slice(52,104).reverse()}
                changeSelectedWeek={this.changeSelectedWeek}
              />
            )}

            {this.state.showChart === "New Customer Sales" && (
              <RacNewMarginChart
                type={"New Customer Sales"}
                weeks={this.state.summaryData.weeks.slice(0,52).reverse()}
                margin={this.state.summaryData.newCustomerSales.slice(0,52).reverse()}
                movingAverage={this.state.summaryData.movingAverageNewCustomerSales.slice(0,52).reverse()}
                target={this.state.summaryData.targetNewCustomerSales.slice(0,52).reverse()}
                upperBound={this.state.summaryData.upperBoundNewCustomerSales}
                lastYearMargin={this.state.summaryData.movingAverageNewCustomerSales.slice(52,104).reverse()}
                changeSelectedWeek={this.changeSelectedWeek}
              />
            )}

            {this.state.showChart === "New Customer Quotes" && (
              <RacNewMarginChart
                type={"New Customer Quotes"}
                weeks={this.state.newQuoteSummary.weeks.slice(0,52).reverse()}
                margin={this.state.newQuoteSummary.NewCustomer.data.slice(0,52).reverse()}
                movingAverage={this.state.newQuoteSummary.NewCustomer.movingAverage.slice(0,52).reverse()}
                target={this.state.newQuoteSummary.NewCustomer.targetCount.slice(0,52).reverse()}
                upperBound={this.state.newQuoteSummary.NewCustomer.upperBound}
                lastYearMargin={this.state.newQuoteSummary.NewCustomer.movingAverage.slice(52,104).reverse()}
                changeSelectedWeek={this.changeSelectedWeek}
              />
            )}

            {this.state.showChart === "Repeat Customer Quotes" && (
              <RacNewMarginChart
                type={"Repeat Customer Quotes"}
                weeks={this.state.newQuoteSummary.weeks.slice(0,52).reverse()}
                margin={this.state.newQuoteSummary.Repeat.data.slice(0,52).reverse()}
                movingAverage={this.state.newQuoteSummary.Repeat.movingAverage.slice(0,52).reverse()}
                target={this.state.newQuoteSummary.Repeat.targetCount.slice(0,52).reverse()}
                upperBound={this.state.newQuoteSummary.Repeat.upperBound}
                lastYearMargin={this.state.newQuoteSummary.Repeat.movingAverage.slice(52,104).reverse()}
                changeSelectedWeek={this.changeSelectedWeek}
              />
            )}
            
            {this.state.showChart === "All New Quotes" && (
              <RacNewMarginChart
                type={"All New Quotes"}
                weeks={this.state.newQuoteSummary.weeks.slice(0,52).reverse()}
                margin={this.state.newQuoteSummary.Total.data.slice(0,52).reverse()}
                movingAverage={this.state.newQuoteSummary.Total.movingAverage.slice(0,52).reverse()}
                target={this.state.newQuoteSummary.Total.targetCount.slice(0,52).reverse()}
                upperBound={this.state.newQuoteSummary.Total.upperBound}
                lastYearMargin={this.state.newQuoteSummary.Total.movingAverage.slice(52,104).reverse()}
                changeSelectedWeek={this.changeSelectedWeek}
              />
            )}
            {this.state.showChart === "New Quote Potential" && (
              <RacNewMarginChart
                type={"New Quote Potential"}
                weeks={this.state.newQuoteSummary.weeks.slice(0,52).reverse()}
                margin={this.state.newQuoteSummary.Potential.data.slice(0,52).reverse()}
                movingAverage={this.state.newQuoteSummary.Potential.movingAverage.slice(0,52).reverse()}
                target={this.state.newQuoteSummary.Potential.targetCount.slice(0,52).reverse()}
                upperBound={this.state.newQuoteSummary.Potential.upperBound}
                lastYearMargin={this.state.newQuoteSummary.Potential.movingAverage.slice(52,104).reverse()}
                changeSelectedWeek={this.changeSelectedWeek}
              />
            )}
            {(this.state.showChart === "Sales" || this.state.showChart === "Margin" || this.state.showChart === "New Customers" || this.state.showChart === "New Customer Sales") && (
              <div>
                {this.state.summaryData.weeks[this.state.selectedWeek]}
              <RepSummaryTable
                reps={this.state.summaryData.reps[this.state.selectedWeek]}
              />
              <ClosedWeekTable
                quotes={this.state.summaryData.weekQuotes[this.state.selectedWeek]}
                weekIndex={this.state.selectedWeek}
              />
              </div>
            )}
            {(this.state.showChart === "New Customer Quotes" || this.state.showChart === "Repeat Customer Quotes" || this.state.showChart === "All New Quotes" || this.state.showChart === "New Quote Potential") && (
              <div>
                {this.state.summaryData.weeks[this.state.selectedWeek]}
                <RepLeadTable
                  reps={this.state.newQuoteSummary.reps[this.state.selectedWeek]}
                  metrics={this.state.newQuoteSummary.metrics[this.state.selectedWeek]}
                />
                <NewQuotesTable
                  quotes={this.state.newQuoteSummary.weekQuotes[this.state.selectedWeek]}
                  weekIndex={this.state.selectedWeek}
                />
              </div>
            )}
            
          </>
        )}
      </ReportWrapper>
    );
  }
}
