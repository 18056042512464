module.exports = {
    call_log_queries: {
        template: {
            query: "",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        findCallsByPhoneNumberString: {
            query: "SELECT * FROM CALL_LOG WHERE nFrom IN([phoneString]) OR nTo IN ([phoneString])",
            req: ["phoneString"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Returns all calls for a list of phone numbers",
            tables: ["CALL_LOG"],
            type: "SELECT",
            whereUsed: [],
        },
        getMostRecentCallLog: {
            query: "SELECT MAX(dtStartTime) AS lastEntry FROM CALL_LOG",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets the date/time of the most recent call log entry",
            tables: ["CALL_LOG"],
            type: "SELECT",
            whereUsed: [],
        },
        insertCallLogEntry: {
            query: "INSERT INTO CALL_LOG (sFromName, nFrom, sToName, nTo, sResult, nCallLength, dtStartTime, sDirection) "
                + "VALUES ('[sFromName]', [nFrom], '[sToName]', [nTo], '[sResult]', [nCallLength], '[dtStartTime]', '[sDirection]')",
            req: ["sFromName", "nFrom", "sToName", "nTo", "sResult", "nCallLength", "dtStartTime", "sDirection"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Insert a new call log entry into the database",
            tables: ["CALL_LOG"],
            type: "INSERT INTO",
            whereUsed: [],
        },


    }

} 