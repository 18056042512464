import React, { Component } from 'react';
import { calcOpenInstallChartData, matchQuotesAndNotes, matchQuotesAndPOLines } from '../../../assets/functions/process/cam';
import { getSQLData, setSQLData } from '../../../assets/functions/fetch';
import { nullDateCheck } from '../../../assets/functions/system';
import { Wrapper } from '../../styled/ui';
import HideShowSubTitle from '../../misc/HideShowSubTitle';
import TechDashboardHeader from './TechDashboard/TechDashboardHeader';
import TechDashboardTable from './TechDashboard/TechDashboardTable';

export default class TechDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      hideAll: false,
    };
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleSubmitDate = this.handleSubmitDate.bind(this)
    this.handleTargetSubmitDate = this.handleTargetSubmitDate.bind(this)
    this.handleUpdateTag = this.handleUpdateTag.bind(this)
    this.handleSaveTag = this.handleSaveTag.bind(this)
    this.handleHideShow = this.handleHideShow.bind(this)
    this.handleShowPO = this.handleShowPO.bind(this)
    this.handleSetLabor = this.handleSetLabor.bind(this)
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {

    try {
      const fetch = await getSQLData("cam/openInstalls", {})
      const fetchPOs = await getSQLData("cam/openInstallPOLines", {})
      const fetchInstallNotes = await getSQLData("cam/openInstallNotes", {})
      
      if(fetch && fetchPOs && fetchInstallNotes){

        let data = fetch.data.data[0].recordset    
        let query = fetch.query
        console.log(data)

        let POData = fetchPOs.data.data[0].recordset
        let POQuery = fetchPOs.query

        let quoteNotes = fetchInstallNotes.data.data[0].recordset
        let quoteQuery = fetchInstallNotes.query
        
        
        this.setState({
          data: data,
          query: query,
          POData: POData,
          POQuery: POQuery,
          quoteNotes: quoteNotes,
          quoteQuery: quoteQuery,
        }, () => this.processData())
      }
         
    } catch (error) {
      console.error(error);
    }
  }

  processData() {
    let data = this.state.data
    let quoteNotes = this.state.quoteNotes
    let POData = this.state.POData

    let chartData = calcOpenInstallChartData(data, 20)
    
    data = matchQuotesAndPOLines(data, POData)
    data = matchQuotesAndNotes(data, quoteNotes)

    console.log(data)

    this.setState({
      chartData: chartData,
      data: data,
      loaded: true,
    })
  }

  handleDateChange = (event, id, paramName) => {
    console.log(event.target.value, id, paramName)
    let data = this.state.data
    data[id][paramName] = event.target.value

    this.setState({data: data})
  };

  handleUpdateTag(getTag, getIndex) {
    let data = this.state.data

    data[getIndex].sQuoteTags = getTag
    data[getIndex].changed = true

    this.setState({data: data})
  }

  handleSubmitDate = async (date, id) => {
    try {
      const fetch = await setSQLData("cam/setQuoteCompleteDate", {
        quoteNum: id,
        completeDate: nullDateCheck(date),
      })
      
      if(fetch){
        this.fetchData();
      }
      
    } catch (error) {
      console.error(error);
    }
  };
  handleTargetSubmitDate = async (date, id) => {
    try {
      const fetch = await setSQLData("cam/setQuoteTargetDate", {
        quoteNum: id,
        targetComplete: nullDateCheck(date),
      })
      
      if(fetch){
        this.fetchData();
      }
      
    } catch (error) {
      console.error(error);
    }
  };
  handleSaveTag = async (tag, id) => {
    try {
      const fetch = await setSQLData("cam/setQuoteTag", {
        quoteNum: id,
        quoteTag: tag,
      })
      
      if(fetch){
        this.fetchData();
      }
      
    } catch (error) {
      console.error(error);
    }
  };

  handleHideShow(){
    this.setState({hideAll: !this.state.hideAll})
  }

  handleShowPO(index){
    let data = this.state.data
    data[index].showPOs = !data[index].showPOs
    this.setState({data: data})
  }

  handleSetLabor = async (quoteIndex, lineIndex) => {
    let lineID = this.state.data[quoteIndex].POLines[lineIndex].LineID
    let newValue = (this.state.data[quoteIndex].POLines[lineIndex].nItem === 832 ? null : 832)

    try {
      const fetch = await setSQLData("cam/setPOLineItem", {
        lineID: lineID,
        itemNum: newValue,
      })
      
      if(fetch){
        this.fetchData();
      }
      
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
      <>
      {this.state.loaded && (
        <>
        
      <Wrapper amount={this.state.data.length}>
        <HideShowSubTitle name={"Tech Dashboard"} hideAll={this.state.hideAll} handleHideShow={this.handleHideShow} />
        {!this.state.hideAll && (
          <>
            <TechDashboardHeader 
              data={this.state.data} 
              chartData={this.state.chartData} 
            />
            <TechDashboardTable 
              data={this.state.data}
              handleDateChange={this.handleDateChange}
              handleSubmitDate={this.handleSubmitDate}
              handleTargetSubmitDate={this.handleTargetSubmitDate}
              handleUpdateTag={this.handleUpdateTag}
              handleSaveTag={this.handleSaveTag}
              handleShowPO={this.handleShowPO}
              handleSetLabor={this.handleSetLabor}
            />
          </>
        )}  

      </Wrapper>
      
      </>
      )}
      </>
    );
  }
}
