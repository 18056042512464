export function whiteNoteColorCount(state) {
    let count = 0;
    for (let i = 0; i < state.length; i++) {
      if (state[i].nAttentionPriority === 0) {
        count += 1;
      }
    }
    return count;
  }
  
export function redNoteColorCount(state) {
    let count = 0;
    for (let i = 0; i < state.length; i++) {
      if (state[i].nAttentionPriority === 1) {
        count += 1;
      }
    }
    return count;
  }
  
export function attentionNoteColor(nAttentionPriority) {
    if (nAttentionPriority === 1) {
      return '#ff5959';
    }
  }

  export function displayNotesCount(leads){
    let red = redNoteColorCount(leads)
    let white = whiteNoteColorCount(leads)

    let redHtml = (<span style={{ color: '#ff6666' }}>{red}</span>)
    let dashHtml = (<span>{' - '}</span>)
    let whiteHtml = (<span>{white}</span>)

    let html = []

    if(red > 0){
        html.push(redHtml)
    }
    if(red > 0 && white > 0){
        html.push(dashHtml)
    }
    if(white > 0){
        html.push(whiteHtml)
    }
    
    return (<>{html}</>)
}