import React, { Component } from "react";
import Axios from "axios";
import Loader from "react-loader-spinner";
import styled from "styled-components";
import Moment from "moment";
import Cookies from "js-cookie";
import { Link } from 'react-router-dom';
import ReactTooltip from "react-tooltip";
import { GoogleApiWrapper, Map } from "google-maps-react";
import Summary from "./Summary";
import { nQuoteTotalFormatter } from "../../../assets/functions/calculations";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  height: 1100px;
  padding: 2em;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.1);
  border-radius: 15px;

  i {
    font-size: 8rem;
    color: #adb5bd;
  }
`;

const Wrapper = styled.div`
  display: flex;
`;

const LoaderWrapper = styled.div`
  margin: 25vh auto 0 auto;
`;

const LeftPane = styled.div`
  display: flex;
  justify-content: center;
  width: 20%;
  margin: 1em 0.5em 1em 2em;
`;

const RightPane = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 1em 2em 1em 0.5em;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export default class SupplierViewer extends Component {
  state = {
    loaded: false,
    viewing: "Quotes",
    quoteView: "Closed",
    showSuggestedContacts: false,
  };

  fetchUser = async () => {
    try {
      const passport = await Axios.get('/userSession', {
        credentials: 'include',
      });
      if (passport.data.passport) {
        this.setState({
          user: passport.data.passport.user,
        });

      }
      const response = await Axios.get('/user', {
        credentials: 'include',
      });
      if(response){
        this.setState({TAUser: response.data.result[0].recordset[0].nTechArmyId})
      }

      if (
        response.data.result[0].recordset[0].sTTATitle === 'Developer' ||
        response.data.result[0].recordset[0].sTTATitle === 'CEO'
      ) {
        this.setState({
          bAdmin: !this.state.bAdmin,
        });
        Cookies.set('sTTATitle', 'admin');
      } else {
        if (response.data.result[0].recordset[0].bAdmin === true) {
          this.setState({
            bAdmin: !this.state.bAdmin,
          });
          Cookies.set('sTTATitle', 'admin');
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Async Axios
  fetchData = async (id) => {

    if(parseInt(id) == id){
      try {
        const response = await Axios.get("/crm/customer-data", {
          params: {
            id: parseInt(id),
          },
          headers: { "Content-Type": "application/json" },
        });
        if (response){
          this.setState({
            address: response.data.result[0].recordset, 
            contact: response.data.result[1].recordset, 
            info: response.data.result[2].recordset, 
            closedQuote: response.data.result[3].recordset, 
            openQuote: response.data.result[4].recordset, 
            discQuote: response.data.result[5].recordset, 
            sales: response.data.result[6].recordset,             
            potContact: response.data.result[7].recordset,             
          },  () => this.prepareData())
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  prepareData(){

    //Summarize sales data to past 5 years sales
    let thisYear = Moment().year()
    let years = [thisYear-4, thisYear-3, thisYear-2, thisYear-1, thisYear]
    let sales = [0,0,0,0,0]

    for(let i=0; i<this.state.sales.length; i++){
      let difference = 4-(thisYear-this.state.sales[i].Year)
      if(difference < 5){
        sales[difference] = this.state.sales[i].Sales
      }
    }

    let maxSales = Math.max(...sales)
    let zeroes = Math.floor(Math.log10(maxSales))
    let upperBound = Math.ceil(maxSales/Math.pow(10,zeroes))*Math.pow(10,zeroes)

    //Process potential contacts
    let potContact = this.state.potContact
    let newPotContact = []
    let contact = this.state.contact

    console.log(contact[0], potContact[0])

    for(let i=0; i<potContact.length; i++){
      let match = false
      let matchD = false
      for(let j=0; j<contact.length; j++){
        if(contact[j].sFirstName === potContact[i].sName || (contact[j].sFirstName + ' ' + contact[j].sLastName) === (potContact[i].sLastName)){match=true}
        console.log(contact[j].sFirstName, potContact[i].sName, match ? 1 : 0)
      }
      
      if(!match){
        let newContact = {
          sFirstName: potContact[i].sName.split(' ')[0],
          sLastName: potContact[i].sName.split(' ')[1],
          sPhone: potContact[i].sPhone,
          sEmail: potContact[i].sEmail,
        }
        newPotContact.push(newContact)
        
      }
      if(!matchD){
        let newContact = {
          sFirstName: potContact[i].sDName.split(' ')[0],
          sLastName: potContact[i].sDName.split(' ')[1],
          sPhone: potContact[i].sDPhone,
          sEmail: '',
        }
        newPotContact.push(newContact)
      }
    }

    let noDupePotContact = []
    if(newPotContact.length > 0){
      noDupePotContact[0] = newPotContact[0]
      for(let i=0; i<newPotContact.length; i++){
        let match = false
        for(let j=0; j<noDupePotContact.length; j++){
          if(newPotContact[i].sFirstName === noDupePotContact[j].sFirstName && newPotContact[i].sLastName === noDupePotContact[j].sLastName){match=true}
        }
        
        if(!match){
          noDupePotContact.push(newPotContact[i])
        }
      }
    }
    
    noDupePotContact.sort((a,b) => (a.sFirstName > b.sFirstName ? 1 : a.sFirstName < b.sFirstName ? -1 : a.sLastName > b.sLastName ? 1 : a.sLastName < b.sLastName ? -1 : 0))

    this.setState({
      loaded: true,
      years: years,
      sales: sales,
      maxSales: maxSales,
      upperBound: upperBound,
      potContact: noDupePotContact,
    })
  }

  componentDidMount() {
    this.fetchUser();
    if (this.props.match.params.customer) {
      this.setState(
        {
          supplier: this.props.match.params.customer,
        },
        () => {
          this.fetchData(this.props.match.params.customer);
        }
      );
  
    }

  }

  updateContact(value, index, column){
    let contact = this.state.contact

    contact[index][column] = value
    contact[index].changed = true

    this.setState({contact: contact})
  }

  newContact(){
    let contact = this.state.contact
    contact[contact.length] = {
      sFirstName: "",
      sLastName: "",
      sPhone: "",
      sEmail: "",
    }
    this.setState({contact: contact})
  }

  render() {   

    return (
      <>
      {this.state.loaded && (
        <Wrapper>
          <LeftPane>
            <Summary
                companyName={this.state.info[0].sName}
                dateCreated={this.state.info[0].dtCreated}
                primaryAddress={this.state.info[0].nPrimaryAddress}
                billingAddress={this.state.info[0].nBillingAddress}
                primaryContact={this.state.info[0].nPrimaryContact}
                rep={this.state.info[0].nDefaultRep}
                id={this.state.info[0].id}
                addresses={this.state.address}
                contacts={this.state.contact}
                guessRep={this.state.closedQuote[0].nRep ? this.state.closedQuote[0].nRep : null}
                sales={this.state.sales}
                years={this.state.years}
                upperBound={this.state.upperBound}
              />
            </LeftPane>
            <RightPane>
              <Container>
                <div>
                  <div 
                    style={{padding: '10px', display: 'inline-block', fontWeight: (this.state.viewing==="Quotes" ? 'bold' : '')}} 
                    onClick={()=>this.setState({viewing: "Quotes"})}>
                      Quotes
                  </div>
                  <div 
                    style={{padding: '10px', display: 'inline-block', fontWeight: (this.state.viewing==="Contacts" ? 'bold' : '')}} 
                    onClick={()=>this.setState({viewing: "Contacts"})}>
                      Contacts
                  </div>
                  <div
                    style={{padding: '10px', display: 'inline-block', fontWeight: (this.state.viewing==="Locations" ? 'bold' : '')}} 
                    onClick={()=>this.setState({viewing: "Locations"})}>
                      Locations
                  </div>
                  <div 
                    style={{padding: '10px', display: 'inline-block', fontWeight: (this.state.viewing==="Communications" ? 'bold' : '')}} 
                    onClick={()=>this.setState({viewing: "Communications"})}>
                      Communications
                  </div>
                  <div 
                    style={{padding: '10px', display: 'inline-block', fontWeight: (this.state.viewing==="Marketing" ? 'bold' : '')}} 
                    onClick={()=>this.setState({viewing: "Marketing"})}>
                      Marketing
                  </div>
                </div>

              {/*Start Quote View*/}
              {this.state.viewing === "Quotes" && (
                <div>
                  <div>
                    <div style={{padding: '10px', display: 'inline-block', fontWeight: (this.state.quoteView==="Closed" ? 'bold' : '')}} onClick={()=>this.setState({quoteView: "Closed"})}>Closed Quotes</div>
                    <div style={{padding: '10px', display: 'inline-block', fontWeight: (this.state.quoteView==="Open" ? 'bold' : '')}} onClick={()=>this.setState({quoteView: "Open"})}>Open Quotes</div>
                    <div style={{padding: '10px', display: 'inline-block', fontWeight: (this.state.quoteView==="Discontinued" ? 'bold' : '')}} onClick={()=>this.setState({quoteView: "Discontinued"})}>Discontinued Quotes</div>
                  </div>
                  {this.state.quoteView === "Closed" && this.state.closedQuote.map((quote, index) => (
                    <div style={{padding: "10px", margin: "10px", border: "1px solid black"}}>
                      <div><Link target="_blank" to={`/quote/${quote.id}`}>{quote.id}</Link> - {nQuoteTotalFormatter(quote.nQuoteTotal)} {quote.sTitle} {quote.nParentQuote > 0 && " (Rebill)"} 
                        {quote.nDeliveryType === 0 && (<> - Add-on Bill</>)}
                        {quote.nDeliveryType === 1 && (<> - Delivery {Moment(quote.dtDeliveryDate).utc().format('MM-DD-YYYY')} - {Moment(quote.dtPickupDate).utc().format('MM-DD-YYYY')}</>)}
                        {quote.nDeliveryType === 2 && (<> - Shipping {Moment(quote.dtDeliveryDate).utc().format('MM-DD-YYYY')} - {Moment(quote.dtPickupDate).utc().format('MM-DD-YYYY')}</>)}
                        
                      </div>
                      <div>{quote.sDAddress1} {quote.sDAddress2}, {quote.sDCity}, {quote.sDState} {quote.sDZip}</div>
                      <div>{quote.sRentalDetails}</div>
                      <div style={{display:'inline-block'}}>
                        <div>({quote.Quantity}) {quote.Item}</div>
                      </div>
                    </div>
                  ))}
                  {this.state.quoteView === "Open" && this.state.openQuote.map((quote, index) => (
                    <div style={{padding: "10px", margin: "10px", border: "1px solid black"}}>
                      <div>{quote.id} - {nQuoteTotalFormatter(quote.nQuoteTotal)} {quote.sTitle} {quote.nParentQuote > 0 && " (Rebill)"} 
                        {quote.nDeliveryType === 0 && (<> - Add-on Bill</>)}
                        {quote.nDeliveryType === 1 && (<> - Delivery {Moment(quote.dtDeliveryDate).utc().format('MM-DD-YYYY')} - {Moment(quote.dtPickupDate).utc().format('MM-DD-YYYY')}</>)}
                        {quote.nDeliveryType === 2 && (<> - Shipping {Moment(quote.dtDeliveryDate).utc().format('MM-DD-YYYY')} - {Moment(quote.dtPickupDate).utc().format('MM-DD-YYYY')}</>)}
                        
                      </div>
                      <div>{quote.sDAddress1} {quote.sDAddress2}, {quote.sDCity}, {quote.sDState} {quote.sDZip}</div>
                      <div>{quote.sRentalDetails}</div>
                      <div style={{display:'inline-block'}}>
                        <div>({quote.Quantity}) {quote.Item}</div>
                      </div>
                    </div>
                  ))}
                  {this.state.quoteView === "Discontinued" && this.state.discQuote.map((quote, index) => (
                    <div style={{padding: "10px", margin: "10px", border: "1px solid black"}}>
                      <div>{quote.id} - {nQuoteTotalFormatter(quote.nQuoteTotal)} {quote.sTitle} {quote.nParentQuote > 0 && " (Rebill)"} 
                        {quote.nDeliveryType === 0 && (<> - Add-on Bill</>)}
                        {quote.nDeliveryType === 1 && (<> - Delivery {Moment(quote.dtDeliveryDate).utc().format('MM-DD-YYYY')} - {Moment(quote.dtPickupDate).utc().format('MM-DD-YYYY')}</>)}
                        {quote.nDeliveryType === 2 && (<> - Shipping {Moment(quote.dtDeliveryDate).utc().format('MM-DD-YYYY')} - {Moment(quote.dtPickupDate).utc().format('MM-DD-YYYY')}</>)}
                        
                      </div>
                      <div>{quote.sDAddress1} {quote.sDAddress2}, {quote.sDCity}, {quote.sDState} {quote.sDZip}</div>
                      <div>{quote.sRentalDetails}</div>
                      <div style={{display:'inline-block'}}>
                        <div>({quote.Quantity}) {quote.Item}</div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {/*End Quote View*/}

              {/*Start Contacts*/}
              {this.state.viewing === "Contacts" && (
                <div>
                  <table>
                    <thead>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                    </thead>
                    {this.state.contact.map((contact, index) => (
                      <tr>
                        <td><input value={contact.sFirstName} onChange={(event)=>this.updateContact(event.target.value, index, "sFirstName")}></input></td>
                        <td><input value={contact.sLastName} onChange={(event)=>this.updateContact(event.target.value, index, "sLastName")}></input></td>
                        <td><input value={contact.sEmail} onChange={(event)=>this.updateContact(event.target.value, index, "sEmail")}></input></td>
                        <td><input value={contact.sPhone} onChange={(event)=>this.updateContact(event.target.value, index, "sPhone")}></input></td>
                        <td>{contact.changed && (<div onClick={()=>this.saveContact(index)}>Save</div>)}</td>
                      </tr>
                    ))}
                  <div onClick={()=>this.newContact()}>New Contact</div>
                  <div onClick={()=>this.setState({showSuggestedContacts: !this.state.showSuggestedContacts})}>{this.state.showSuggestedContacts ? "Hide " : "Show "}Suggested Contacts</div>
                      {this.state.showSuggestedContacts && this.state.potContact.map((contact, index) => (
                        <tr>
                          <td>{contact.sFirstName}</td>
                          <td>{contact.sLastName}</td>
                          <td>{contact.sEmail}</td>
                          <td>{contact.sPhone}</td>
                          <td onClick={()=>this.quickAddContact(index)}>Quick Add</td>
                        </tr>
                      ))}
                  </table>
                </div>
              )}
              {/*End Contacts*/}

              </Container>
            </RightPane>
        </Wrapper>
      )}
      </>
      
    )
  }
}