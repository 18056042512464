import React, { Component } from 'react';
import styled from 'styled-components';
import Moment from 'moment';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin: 1em 0.5em 0 0;
  width: 50%;
  height: 220px;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.1);
  text-align: center;
  border-radius: 15px;

  input {
    width: 70%;
    padding: 0.7em;
    margin: 0.5em auto;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4aabed;
  color: #fff;
  height: 35px;
  font-weight: bold;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  div {
    width: 100%;
    margin: 0.5em;
  }

  p {
    font-weight: bold;
    text-align: left;
    margin: 0.5em 0 0 15%;
  }
`;

export default class BillingCycle extends Component {
  render() {
    return (
      <Wrapper>
        <Title>
          <p>Billing</p>
        </Title>
        <InputContainer>
          <div>
            <div>
              <p>Billing Cycle Start:</p>
              <input
                type="date"
                id="billingStart"
                name="billingStart"
                value={Moment(this.props.dtBillingCycleStart)
                  .utc()
                  .format('YYYY-MM-DD')}
                min="2021-01-01"
              />
            </div>
            <div>
              <p>Billing Cycle End:</p>
              <input
                type="date"
                id="billingEnd"
                name="billingEnd"
                value={Moment(this.props.dtBillingCycleEnd)
                  .utc()
                  .format('YYYY-MM-DD')}
                min="2021-06-16"
              />
            </div>
          </div>
          <div>
            <div>
              <p>Rental Period:</p>
              <input
                type="text"
                id="startDate"
                name="startDate"
                value={this.props.sRentalPeriod}
              />
            </div>
            <div>
              <p>Insurance:</p>
              <input
                type="text"
                id="insurance"
                name="insurance"
                value={this.props.nInsurance}
              />
            </div>
          </div>
        </InputContainer>
      </Wrapper>
    );
  }
}
