import React from 'react';
import styled from 'styled-components';

// Styled-components for modal
const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 800px;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;

const EmailSubject = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
`;

const EmailInfo = styled.p`
  margin: 5px 0;
  color: #555;
`;

const EmailBody = styled.div`
  margin-top: 20px;
  color: #333;
  line-height: 1.5;
`;

// EmailDetailsModal component
const EmailDetailsModal = ({ email, onClose }) => {
  if (!email) {
    console.error('Email data is undefined or null:', email);
    return null;
  }

  return (
    <ModalBackground>
      <ModalContent>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <EmailSubject>{email.sSubject}</EmailSubject>
        <EmailInfo><strong>From:</strong> {email.sFrom}</EmailInfo>
        <EmailInfo><strong>Date:</strong> {new Date(email.dtDate).toLocaleString()}</EmailInfo>
        <EmailBody dangerouslySetInnerHTML={{ __html: email.sBody }} />
      </ModalContent>
    </ModalBackground>
  );
};

export default EmailDetailsModal;