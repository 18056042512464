import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 1em 2em;
  margin: 0 auto 1em auto;
  width: 100%;
  height: 71px;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.1);
  text-align: center;
  border-radius: 15px;
`;

const Button = styled.button`
   text-decoration: none;
   text-align: center;
   transition: all 0.2s;
  cursor: pointer;
  padding: 0.5em 4em;
  border: 3px #e8edf1 solid;
  border-radius: 50px;
  background-color: #e8edf1;
  font-weight: 500;
  margin: 0 4em;

  &:hover {
    background-color: #4aabed;
    color: #fff;
    border: 3px #4aabed solid;
  }
`;

export default class CommunicationControls extends Component {
  state = {
    activeNotes: true,
    activeLog: false,
    activeEmails: false,
  };

  // Sends the text of the clicked button up to Communications.js component, sets all the button states to false, and then sets the selected button to true/active, which then applies styling
  handleOnClick = (event) => {
    let tab = event.target.textContent
      .replace(/\s/g, '')
      .replace(/ *\([^)]*\) */g, '');;
    this.props.onClick(tab);
    this.setState({
      activeNotes: false,
      activeLog: false,
      activeEmails: false,
    });
    this.setState({ [`active${tab}`]: true });
  };
  render() {
    return (
      <Wrapper>
        <Button
          onClick={this.handleOnClick}
          style={{
            color: this.state.activeNotes && '#fff',
            backgroundColor: this.state.activeNotes && '#4aabed',
            border: this.state.activeNotes && '3px #4aabed solid',
          }}
        >
          Notes ({this.props.noteCount})
        </Button>
        <Button
          onClick={this.handleOnClick}
          style={{
            color: this.state.activeEmails && '#fff',
            backgroundColor: this.state.activeEmails && '#4aabed',
            border: this.state.activeEmails && '3px #4aabed solid',
          }}
        >
          Emails ({this.props.emailCount})
        </Button>
        <Button
          onClick={this.handleOnClick}
          style={{
            color: this.state.activeLog && '#fff',
            backgroundColor: this.state.activeLog && '#4aabed',
            border: this.state.activeLog && '3px #4aabed solid',
          }}
        >
          Log ({this.props.logCount})
        </Button>
        
      </Wrapper>
    );
  }
}
