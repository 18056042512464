import React, { Component } from 'react';
import { Wrapper } from '../../styled/ui';
import { getSQLData, setSQLData } from '../../../assets/functions/fetch';
import { nullDateCheck } from '../../../assets/functions/system';
import { matchQuotesAndEmails, matchQuotesAndNotes, processSiteVisitDatav2, returnSiteVisitArrayName } from '../../../assets/functions/process/cam';
import SiteVisitTablev2 from './SiteVisits/SiteVisitTablev2';
import CamSalesTable from './SiteVisits/CamSalesTable';


export default class SiteVisitsv2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      changeSiteVisitMode: false,
      viewing: "Site Visit Needs Scheduled",
    };
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleDateSubmit = this.handleDateSubmit.bind(this)

  }

  fetchData = async () => {

    try {
      const fetch = await getSQLData("cam/siteVisitsv2", {})
      const fetchNotes = await getSQLData("cam/siteVisitNotesv2", {})
      const fetchEmails = await getSQLData("cam/siteVisitEmailsv2", {})
      if(fetch){
        let data = fetch.data.data[0].recordset    
        let notes = fetchNotes.data.data[0].recordset    
        let emails = fetchEmails.data.data[0].recordset    

        this.setState({
          data: data,
          notes: notes,
          emails: emails,
        }, () => this.processData())
      }
         
    } catch (error) {
      console.error(error);
    }
  }

  processData = () => {
    let data = this.state.data
    let notes = this.state.notes
    let emails = this.state.emails
    console.log(data)
    data = matchQuotesAndNotes(data, notes)
    data = matchQuotesAndEmails(data, emails)
    data = processSiteVisitDatav2(data)

    console.log(data);

    this.setState({
      data: data,
      loaded: true,
    })
  }

  componentDidMount() {
    window.addEventListener('keydown',(event) => {
      if(event.key === '`'){
        this.setState({changeSiteVisitMode: !this.state.changeSiteVisitMode})
      }
    })
    this.fetchData();
  }

  handleDateChange = (event, id, paramName) => {
    let data = this.state.data
    let viewing = this.state.viewing
    let arrayName = returnSiteVisitArrayName(viewing)   

    data[arrayName][id][paramName] = event.target.value

    this.setState({data: data})
  };

  handleDateSubmit = async(date, id, type) => {
    const queryLookup = {
      dtSiteVisitPlanned: "cam/setSiteVisitPlannedDate",
      dtSiteVisit: "cam/setSiteVisitDate",
      dtSentDate: "cam/setQuoteSentDate",
    }

    try {
      const fetch = await setSQLData(queryLookup[type], {
        quoteNum: id,
        date: nullDateCheck(date),
      })
      
      if(fetch){
        this.fetchData();
      }
      
    } catch (error) {
      console.error(error);
    }
  }

  changeView(view){
    this.setState({viewing: view})
  }

  render() {
    let viewing = this.state.viewing
    return (
      <>
      {this.state.loaded && (

      
      <Wrapper>
        <div>
          <button onClick={()=>this.changeView("Site Visit Needs Scheduled")}>Site Visit Needs Scheduled ({this.state.data.needScheduled.length})</button>
          <button onClick={()=>this.changeView("Site Visit Has Planned Date")}>Site Visit Has Planned Date ({this.state.data.needSiteVisit.length})</button>
          <button onClick={()=>this.changeView("Quote Needs Sent")}>Quote Needs Sent ({this.state.data.needQuoteSent.length})</button>
          <button onClick={()=>this.changeView("New CAM Quotes")}>New Quotes ({this.state.data.newQuotes.length})</button>
          <button onClick={()=>this.changeView("Recent CAM Quotes")}>Recent Quotes ({this.state.data.lastMonthQuotes.length})</button>
          <button onClick={()=>this.changeView("Old CAM Quotes")}>Old Quotes ({this.state.data.oldQuotes.length})</button>
          <button onClick={()=>this.changeView("Discontinued CAM Quotes")}>Discontinued  Quotes ({this.state.data.discontinuedQuotes.length})</button>
        </div>
        <div>
          <button onClick={()=>this.setState({loaded:false},()=>this.fetchData())}>Refresh Data</button>
        </div>
        {!this.state.hideAll && (
          <>
          {(viewing === "Site Visit Needs Scheduled" || viewing === "Site Visit Has Planned Date" || viewing === "Quote Needs Sent") && (
            <SiteVisitTablev2
              data={this.state.data}
              viewing={this.state.viewing}
              handleDateChange={this.handleDateChange}
              handleDateSubmit={this.handleDateSubmit}
            />
          )}
          {(viewing === "New CAM Quotes" || viewing === "Recent CAM Quotes" || viewing === "Old CAM Quotes" || viewing === "Discontinued CAM Quotes") && (
            <CamSalesTable
              data={this.state.data}
              viewing={this.state.viewing}
              handleDateChange={this.handleDateChange}
              handleDateSubmit={this.handleDateSubmit}
            />
            
          )}
            
          </>
        )}  
      </Wrapper>
      )}
      </>
    );
  }
}
