import React, { Component } from 'react';
import styled from 'styled-components';
import { getSQLData, setSQLData } from '../../../assets/functions/fetch';

const repNames = {
  5: 'Andy Wendt',
  54: 'Brian Kubala',
  63: 'Hunter Stewart',
  64: 'Jeff Bruck',
  71: 'Jill Staley',
  1: 'John Beagle',
  45: 'Johnny Beagle',
  44: 'Kevin Boles',
  68: 'Kevin Thompson',
  40: 'Kristen Fisher',
  51: 'Mark Gillaugh',
  22: 'Randy Moore',
  27: 'Robert Beagle',
  14: 'Susan Beagle',
  8: 'Tony Cipollone',
};

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const ModalTitle = styled.h2`
  margin: 0;
  font-size: 1.5rem;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;

const InputGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`;

const Select = styled.select`
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #ccc;
  resize: vertical;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #4aabed;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: #2a8acb;
  }
  &:not(:last-child) {
    margin-right: 10px;
  }
`;

class LogMarketingContactModal extends Component {
  state = {
    selectedContact: '',
    contactType: '',
    contactOutcome: '',
    contactDetails: '',
    contacts: [],
    selectedRep: '',
    selectedContactInfo: {
      email: '',
      phone: '',
      sTier: '3', // Default to Tier 3
    },
  };

  componentDidMount() {
    this.fetchCompanyContacts();
  }

  fetchCompanyContacts = async () => {
    const { nCompany } = this.props;
    try {
      const contactsFetch = await getSQLData('logistics/getContactsByCompany', { nCompany });
      if (contactsFetch && contactsFetch.data && contactsFetch.data.data[0]) {
        this.setState({ contacts: contactsFetch.data.data[0].recordset });
      }
    } catch (error) {
      console.error('Error fetching contacts:', error);
    }
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleContactChange = (e) => {
    const selectedContactId = e.target.value;
    const selectedContact = this.state.contacts.find(contact => contact.id === parseInt(selectedContactId, 10));

    this.setState({
      selectedContact: selectedContactId,
      selectedContactInfo: {
        email: selectedContact ? selectedContact.Email1 : '',
        phone: selectedContact ? selectedContact.sPhone : '',
        sTier: selectedContact ? selectedContact.sTier : '3',
      }
    });
  };

  handleSubmit = async () => {
    const { selectedContact, contactType, contactOutcome, contactDetails, selectedContactInfo, selectedRep } = this.state;
    const { onClose, nCompany, quoteId } = this.props;

    if (!selectedRep) {
      console.error('Rep ID is not selected');
      return;
    }

    try {
      console.log('Attempting to log marketing contact with details:', {
        nRep: selectedRep,
        nContact: selectedContact,
        sType: contactType,
        sDisposition: contactOutcome,
        sNote: contactDetails,
      });

      // Log the marketing contact
      const logResponse = await setSQLData('logistics/logMarketingContact', {
        nRep: selectedRep,
        nContact: selectedContact,
        sType: contactType,
        sDisposition: contactOutcome,
        sNote: contactDetails,
      });

      if (logResponse.status === 200 && logResponse.data) {
        console.log('Log contact successful');

        console.log('Attempting to update contact tier with details:', {
          nContact: selectedContact,
          sTier: selectedContactInfo.sTier,
        });

        // Update the sTier in CONTACTS
        const tierUpdateResponse = await setSQLData('logistics/updateContactTier', {
          nContact: selectedContact,
          sTier: selectedContactInfo.sTier,
        });

        if (tierUpdateResponse.status === 200 && tierUpdateResponse.data) {
          console.log('Tier update successful');
          onClose(); 
        } else {
          console.error('Error updating tier:', tierUpdateResponse.error);
        }
      } else {
        console.error('Error logging marketing contact:', logResponse.error);
      }
    } catch (error) {
      console.error('Error logging marketing contact:', error);
    }
  };

  render() {
    const { onClose } = this.props;
    const { selectedContact, contactType, contactOutcome, contactDetails, contacts, selectedContactInfo, selectedRep } = this.state;

    return (
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>
            <ModalTitle>Log Marketing Contact</ModalTitle>
            <CloseButton onClick={onClose}>&times;</CloseButton>
          </ModalHeader>

          <InputGroup>
            <Label>Select Rep</Label>
            <Select
              name="selectedRep"
              value={selectedRep}
              onChange={this.handleInputChange}
            >
              <option value="">Select a Rep</option>
              {Object.entries(repNames).map(([repId, repName]) => (
                <option key={repId} value={repId}>
                  {repName}
                </option>
              ))}
            </Select>
          </InputGroup>

          <InputGroup>
            <Label>Select Contact</Label>
            <Select
              name="selectedContact"
              value={selectedContact}
              onChange={this.handleContactChange}
            >
              <option value="">Select a contact</option>
              {contacts.map((contact) => (
                <option key={contact.id} value={contact.id}>
                  {contact.sName} - {contact.Email1}
                </option>
              ))}
            </Select>
          </InputGroup>

          {selectedContact && (
            <>
              <InputGroup>
                <Label>Email</Label>
                <Input
                  type="text"
                  value={selectedContactInfo.email}
                  readOnly
                />
              </InputGroup>
              <InputGroup>
                <Label>Phone</Label>
                <Input
                  type="text"
                  value={selectedContactInfo.phone}
                  readOnly
                />
              </InputGroup>
            </>
          )}

          <InputGroup>
            <Label>Contact Type</Label>
            <Select
              name="contactType"
              value={contactType}
              onChange={this.handleInputChange}
            >
              <option value="">Select contact type</option>
              <option value="call">Call</option>
              <option value="email">Email</option>
            </Select>
          </InputGroup>

          <InputGroup>
            <Label>Outcome</Label>
            <Select
              name="contactOutcome"
              value={contactOutcome}
              onChange={this.handleInputChange}
            >
              <option value="">Select outcome</option>
              <option value="connected">Connected</option>
              <option value="voicemail">Left Voicemail</option>
              <option value="no answer">No Answer</option>
              <option value="disconnected">Number Disconnected</option>
              <option value="wrong number">Wrong Number</option>
            </Select>
          </InputGroup>

          <InputGroup>
            <Label>Contact Details</Label>
            <TextArea
              name="contactDetails"
              value={contactDetails}
              onChange={this.handleInputChange}
              rows="5"
              placeholder="Enter details about the contact attempt"
            />
          </InputGroup>

          {selectedContact && (
            <InputGroup>
              <Label>Tier</Label>
              <Select
                name="sTier"
                value={selectedContactInfo.sTier}
                onChange={(e) => this.setState({ selectedContactInfo: { ...selectedContactInfo, sTier: e.target.value } })}
              >
                <option value="3">Tier 3 - Good Conversation</option>
                <option value="4">Tier 4 - No Needs / Will Keep Us in Mind</option>
                <option value="5">Tier 5 - Unresponsive</option>
                <option value="6">Tier 6 - Don't call again</option>
              </Select>
            </InputGroup>
          )}

          <ButtonContainer>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={this.handleSubmit}>Log Contact</Button>
          </ButtonContainer>
        </ModalContent>
      </ModalOverlay>
    );
  }
}

export default LogMarketingContactModal;