import React, { Component } from 'react';
import Axios from 'axios';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Link,
} from 'react-router-dom';
import UnassignedLead from '../misc/UnassignedLead';
import { days_between, currentHourStart } from '../../assets/functions/time';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet';
import { BottomNavbar, BottomUl, MobilePopUpMenuWrapper, SideBarItem, SideBarLinkMobile, SubTitle, TopNavbar, TopUl } from '../styled/navMobile';
import { lateAmount, onTimeAmount } from '../../assets/functions/followups';
import { attentionNoteColor, redNoteColorCount, whiteNoteColorCount } from '../../assets/functions/notes';
import { SideBarLink } from '../styled/nav';

function dateColor(dtDate) {
  let days = days_between(new Date(), dtDate);

  if (days > 1) {
    return '#ff5959';
  }
}

function openOrClosed(state) {
  if (state === true) {
    return '80vh';
  } else {
    return '0vh';
  }
}

function openOrClosedSideMenu(state) {
  if (state === true) {
    return '0px';
  } else {
    return '-800px';
  }
}

class NavBars extends Component {
  state = {
    flashError: '',
    bAdmin: false,
    bAccounting: false,
    bRAC: false,
    bCSN: false,
    itTools: false,
    racLeads: [],
    camLeads: [],
    followUps: [],
    notes: [],
    accountingList: [],
    underwritingList: [],
    ticketList: [],
    showPopUpMenu: false,
    showSideMenu: false,
    showSearch: false,
    search: '',
    results: [],
  };

  

  // Async Axios
  fetchUser = async () => {
    const passport = await Axios.get('/userSession', {
      credentials: 'include',
    });
    if (passport.data.passport) {
      this.setState({
        user: passport.data.passport.user,
      });
    }
    const response = await Axios.get('/user', {
      credentials: 'include',
    });
    if (
      response.data.result[0].recordset[0].sTTATitle === 'Developer' ||
      response.data.result[0].recordset[0].sTTATitle === 'CEO'
    ) {
      this.setState({
        bAdmin: !this.state.bAdmin,
        bAccounting: !this.state.bAccounting,
        bRAC: !this.state.bRAC,
        bCSN: !this.state.bCSN,
        itTools: !this.state.itTools,
      });
    } else {
      if (response.data.result[0].recordset[0].bAdmin === true) {
        this.setState({
          bAdmin: !this.state.bAdmin,
        });
      }
      if (response.data.result[0].recordset[0].bAccounting === true) {
        this.setState({
          bAccounting: !this.state.bAccounting,
        });
      }
      if (response.data.result[0].recordset[0].bRAC === true) {
        this.setState({
          bRAC: !this.state.bRAC,
        });
      }
      if (response.data.result[0].recordset[0].bCSN === true) {
        this.setState({
          bCSN: !this.state.bCSN,
        });
      }
    }
    this.fetchData();
  };
  catch(error) {
    console.error(error);
  }

  // Async Axios
  fetchData = async () => {
    const leads = await Axios.get('/unassigned-leads');
    const notes = await Axios.get('/follow-notes', {
      params: {
        nRep: this.state.user,
        date: currentHourStart(),
      },
    });
    this.setState({
      racLeads: leads.data.result[0].recordset,
      camLeads: leads.data.result[1].recordset,
      followUps: notes.data.result[0].recordset,
      notes: notes.data.result[1].recordset,
    });
    this.fetchTaskLists();
  };
  catch(error) {
    console.error(error);
  }

  // Async Axios
  fetchTaskLists = async () => {
    const accountingList = await Axios.get('/uncompleted-task-list', {
      params: {
        nCategory: 1,
      },
      headers: { 'Content-Type': 'application/json' },
    });
    const underwritingList = await Axios.get('/uncompleted-task-list', {
      params: {
        nCategory: 2,
      },
      headers: { 'Content-Type': 'application/json' },
    });
    const ticketList = await Axios.get('/uncompleted-ticket-list', {
      headers: { 'Content-Type': 'application/json' },
    });
    this.setState({
      underwritingList: underwritingList.data.result[0].recordset[0].nCount,
      accountingList: accountingList.data.result[0].recordset[0].nCount,
      ticketList: ticketList.data.result[0].recordset[0].nCount,
    });
  };
  catch(error) {
    console.error(error);
  }

  componentDidMount() {
    this.fetchUser();
    // Fetches new data every 5 seconds
    this.interval = setInterval(() => this.fetchData(), 5000);
  }

  componentWillUnmount() {
    // Stop fetching data when the user leaves the page and component unmounts
    clearInterval(this.interval);
  }

  handleClick = () => {
    Cookies.remove('connect.sid');
    Cookies.remove('sTTATitle');
  };

  showPopUpMenu = (event) => {
    event.preventDefault();

    this.setState({
      showPopUpMenu: !this.state.showPopUpMenu,
    });
    if (this.state.showPopUpMenu === false) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  };

  showSideMenu = (event) => {
    event.preventDefault();

    this.setState({
      showSideMenu: !this.state.showSideMenu,
    });
    if (this.state.showSideMenu === false) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  };

  closeMenus = (event) => {
    event.preventDefault();

    this.setState({
      showSideMenu: false,
      showPopUpMenu: false,
    });
    document.body.style.overflow = 'visible';
  };

  showSearch = (event) => {
    event.preventDefault();

    this.setState({
      showSideMenu: false,
      showPopUpMenu: false,
      showSearch: true,
    });
    document.body.style.overflow = 'visible';
  };

  // When the value of the input changes it sets it to the search state, and then does the fetchData() function for axios
  handleOnChange = (event) => {
    // Clears running timer and starts a new one each time the user types
    clearTimeout(this.timer);
    this.setState({ search: event.target.value });
    this.timer = setTimeout(() => {
      this.fetchData();
    }, 400);
  };

  // When enter is clicked, search state is set to empty string, and loaded to false, hiding the drop down menu
  handleClick = () => {
    this.setState({ loaded: false, search: '', showSearch: false });
  };

  // When enter is clicked nothing will happen, prevent page from refreshing
  handleClickFalse = (e) => {
    e.preventDefault();
  };

  // When input is unfocused it sets search state to an empty string, and loaded to false, hiding the drop down menu
  handleBlur = () => {
    this.setState({ loaded: false, search: '', showSearch: false });
  };

  render() {
    return (
      <>
        <Helmet defer={false}>
          {this.state.racLeads.length + this.state.camLeads.length > 0 && (
            <title>
              (
              {(
                this.state.racLeads.length + this.state.camLeads.length
              ).toString()}
              ) New Leads - Command Center
            </title>
          )}
          {this.state.racLeads.length + this.state.camLeads.length <= 0 && (
            <title>Command Center</title>
          )}
        </Helmet>
        <TopNavbar>
          <div
            style={{ right: openOrClosedSideMenu(this.state.showSideMenu) }}
            className="mobile-side-menu"
          >
            {this.state.showSideMenu ? (
              <>
                <ul>
                  <li onClick={this.showSideMenu}>
                    {this.state.bAdmin && (
                      <NavLink to="/admin-dashboard">Admin Dashboard</NavLink>
                    )}
                  </li>
                  <li onClick={this.showSideMenu}>
                    {this.state.bAccounting && (
                      <NavLink to="/accounting-dashboard">
                        Accounting Dashboard
                      </NavLink>
                    )}
                  </li>
                  <li onClick={this.showSideMenu}>
                    {this.state.bCSN && (
                      <NavLink to="/cam-csn-dashboard">
                        CAM/CSN Dashboard
                      </NavLink>
                    )}
                  </li>
                  <li onClick={this.showSideMenu}>
                    {this.state.bCSN && (
                      <NavLink to="/cam-operations">CAM Operations</NavLink>
                    )}
                  </li>
                  <li onClick={this.showSideMenu}>
                    {this.state.bRAC && (
                      <NavLink to="/marketing-dashboard">
                        Marketing Dashboard
                      </NavLink>
                    )}
                  </li>
                  <li onClick={this.showSideMenu}>
                    {this.state.bRAC && (
                      <NavLink to="/rac-dashboard">RAC Dashboard</NavLink>
                    )}
                  </li>
                </ul>
              </>
            ) : null}
          </div>
          <TopUl>
            <li onClick={this.closeMenus} style={{ marginRight: 'auto' }}>
              <NavLink to="/settings">
                <i style={{ color: '#fff' }} className="fas fa-user-circle"></i>
              </NavLink>
            </li>
            <li>
              <i className="far fa-bell"></i>
            </li>
            <li>
              <i
                style={{ color: '#fff' }}
                onClick={this.showPopUpMenu}
                className="fas fa-ellipsis-v"
              ></i>
            </li>
          </TopUl>
        </TopNavbar>
        <BottomNavbar>
          <div
            style={{ height: openOrClosed(this.state.showPopUpMenu) }}
            className="mobile-pop-up-menu"
          >
            {this.state.showPopUpMenu ? (
              <>
                <MobilePopUpMenuWrapper>
                  {this.state.racLeads.length > 0 && (
                    <>
                      <SubTitle>RAC Unassigned Leads</SubTitle>
                      {this.state.racLeads.slice(0, 1).map((sql) => (
                        <UnassignedLead
                          key={sql.id}
                          id={sql.id}
                          dtDateStamp={sql.dtDateStamp}
                        />
                      ))}
                    </>
                  )}
                  {this.state.camLeads.length > 0 && (
                    <>
                      <SubTitle>CAM Unassigned Leads</SubTitle>
                      {this.state.camLeads.slice(0, 1).map((sql) => (
                        <UnassignedLead
                          key={sql.id}
                          id={sql.id}
                          dtDateStamp={sql.dtDateStamp}
                        />
                      ))}
                    </>
                  )}
                  {this.state.followUps.length > 0 && (
                    <>
                      <SubTitle>
                        Follow Ups (
                        {lateAmount(this.state.followUps) > 0 && (
                          <span style={{ color: '#ff6666' }}>
                            {lateAmount(this.state.followUps)}
                          </span>
                        )}
                        {lateAmount(this.state.followUps) > 0 &&
                          onTimeAmount(this.state.followUps) > 0 && (
                            <span>{' - '}</span>
                          )}
                        {onTimeAmount(this.state.followUps) > 0 && (
                          <span>{onTimeAmount(this.state.followUps)}</span>
                        )}
                        )
                      </SubTitle>

                      {this.state.followUps.map((sql) => (
                        <SideBarLinkMobile
                          href={
                            'https://cc.cfrinc.us/quote.asp?nfollowup=' +
                            sql.sFollowupId +
                            '&id=' +
                            sql.nQuote
                          }
                          target="_blank"
                          style={{
                            backgroundColor: dateColor(sql.dtDate),
                            textAlign: 'left',
                          }}
                        >
                          <SideBarItem>
                            <i className="fas fa-briefcase"></i> {sql.sCompany}
                          </SideBarItem>
                          {sql.sQuoteTitle && (
                            <SideBarItem>
                              <i className="fas fa-quote-left"></i>{' '}
                              {sql.sQuoteTitle}
                            </SideBarItem>
                          )}
                          <SideBarItem>
                            <i className="fas fa-user"></i> {sql.sName}
                          </SideBarItem>
                          <SideBarItem>
                            <i className="fas fa-phone"></i> {sql.sPhone}
                          </SideBarItem>
                          <SideBarItem>
                            <i className="fas fa-sticky-note"></i>{' '}
                            {sql.sNote.slice(0, 200)}
                          </SideBarItem>
                        </SideBarLinkMobile>
                      ))}
                    </>
                  )}
                  {this.state.notes.length > 0 && (
                    <>
                      <SubTitle>
                        Notes (
                        {redNoteColorCount(this.state.notes) > 0 && (
                          <span style={{ color: '#ff6666' }}>
                            {redNoteColorCount(this.state.notes)}
                          </span>
                        )}
                        {whiteNoteColorCount(this.state.notes) > 0 &&
                          redNoteColorCount(this.state.notes) > 0 && (
                            <span>{' - '}</span>
                          )}
                        {whiteNoteColorCount(this.state.notes) > 0 && (
                          <span>{whiteNoteColorCount(this.state.notes)}</span>
                        )}
                        )
                      </SubTitle>
                      {this.state.notes.map((sql) => (
                        <SideBarLink
                          href={
                            'https://cc.cfrinc.us/quote.asp?id=' + sql.nQuote
                          }
                          target="_blank"
                          style={{
                            backgroundColor: attentionNoteColor(
                              sql.nAttentionPriority
                            ),
                            textAlign: 'left',
                          }}
                        >
                          <SideBarItem>
                            <i className="fas fa-calendar-day"></i>{' '}
                            {sql.dtTimeStamp.slice(0, -14)}
                          </SideBarItem>
                          <SideBarItem>
                            <i className="fas fa-briefcase"></i> {sql.sCompany}
                          </SideBarItem>
                          <SideBarItem>
                            <i className="fas fa-user"></i> {sql.sName}
                          </SideBarItem>
                          <SideBarItem>
                            <i className="fas fa-sticky-note"></i>{' '}
                            {sql.sNote.slice(0, 200)}
                          </SideBarItem>
                        </SideBarLink>
                      ))}
                    </>
                  )}
                </MobilePopUpMenuWrapper>
              </>
            ) : null}
          </div>
          <BottomUl>
            {this.state.showSearch ? (
              <li>
                <div className="mobile-search-container">
                  <form className="search-label" action="">
                    <input
                      type="text"
                      id="search-input"
                      value={this.state.search}
                      placeholder="Type to search"
                      onChange={this.handleOnChange}
                      autoComplete="off"
                      onBlur={this.handleBlur}
                      autoFocus
                    />
                    <Link to={`/results/${this.state.search}`}>
                      <button
                        className="search-icon"
                        onClick={this.handleClick}
                      >
                        <i
                          onClick={this.handleClick}
                          className="fa fa-search"
                        />
                      </button>
                    </Link>
                  </form>
                </div>
              </li>
            ) : (
              <>
                <li onClick={this.showSearch}>
                  <i style={{ color: '#fff' }} className="fa fa-search"></i>
                </li>
                <li onClick={this.closeMenus}>
                  <NavLink to="/home">
                    <i style={{ color: '#fff' }} className="fas fa-home"></i>
                  </NavLink>
                </li>
                <li onClick={this.showSideMenu} style={{ size: '20' }}>
                  <i className="fas fa-bars"></i>
                </li>
              </>
            )}
          </BottomUl>
        </BottomNavbar>
      </>
    );
  }
}

export default NavBars;

