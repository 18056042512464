import React, { Component } from 'react';
import { nQuoteTotalNoDollar } from '../../../assets/functions/calculations';

class RepCommissions extends Component {
    render() {
        return (
            <div>
            <table>
            <thead>
              <tr>
                <th>Earned</th>
                <th>Paid</th>
                <th>Rep</th>
                <th>YTD Commissions</th>
                <th>Balance</th>
              </tr>
            </thead>
            {Object.keys(this.props.reps).map((rep, index)=>(
              <tr>
                <td>{nQuoteTotalNoDollar(this.props.reps[rep])}</td>
                <td>{nQuoteTotalNoDollar(this.props.paid[rep]+this.props.YTDadj[rep])}</td>
                <td>{rep}</td>
                <td>{nQuoteTotalNoDollar(this.props.paid[rep])}</td>
                <td>{nQuoteTotalNoDollar(this.props.reps[rep]-(this.props.paid[rep]+this.props.YTDadj[rep]))}</td>
              </tr>              
            ))}
            </table>
          </div>
        );
    }
}

export default RepCommissions;