import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: #fff;
  padding: 1em 2em;
  margin: 0 auto;
  width: 100%;
  height: 71px;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.1);
  text-align: center;
  border-radius: 15px;

  i {
    color: #858c90;
  }
`;

const Button = styled.button`
   padding: 0.5em 1.2em;
   text-decoration: none;
   text-align: center;
   transition: all 0.2s;
  cursor: pointer;
  padding: 0.5em 1em;
  border: 3px #e8edf1 solid;
  border-radius: 50px;
  background-color: #fff;
  background-color: #e8edf1;

  &:hover {
    background-color: #4aabed;
    color: #fff;
    border: 3px #4aabed solid;
  }

  &:nth-child(1) {
    ${(props) => {
      if (props.nFirstContact != null)
        return `
          cursor: auto;
          background-color: #4aabed;
          color: #fff;
          border: 3px #4aabed solid;
        `;
    }}
  }

  &:nth-child(3) {
    ${(props) => {
      if (props.nContactMade != null)
        return `
          cursor: auto;
          background-color: #4aabed;
          color: #fff;
          border: 3px #4aabed solid;
        `;
    }}
  }

  &:nth-child(5) {
    ${(props) => {
      if (props.nQuoteReceived != null)
        return `
          cursor: auto;
          background-color: #4aabed;
          color: #fff;
          border: 3px #4aabed solid;
        `;
    }}
  }

  &:nth-child(7) {
    ${(props) => {
      if (props.nRemindedOfReturn != null)
        return `
          cursor: auto;
          background-color: #4aabed;
          color: #fff;
          border: 3px #4aabed solid;
        `;
    }}
  }

  &:nth-child(9) {
    ${(props) => {
      if (props.nRentalClosed != null)
        return `
          cursor: auto;
          background-color: #4aabed;
          color: #fff;
          border: 3px #4aabed solid;
        `;
    }}
  }

  &:nth-child(11) {
    ${(props) => {
      if (props.nManagerReviewed != null)
        return `
          cursor: auto;
          background-color: #4aabed;
          color: #fff;
          border: 3px #4aabed solid;
        `;
    }}
  }
`;

export default class QuoteLevel extends Component {
  render() {
    return (
      <Wrapper>
        <Button nFirstContact={this.props.nFirstContact}>First Contact</Button>
        <i class="fas fa-angle-right"></i>
        <Button nContactMade={this.props.nContactMade}>
          Contact Made with Customer
        </Button>
        <i class="fas fa-angle-right"></i>
        <Button nQuoteReceived={this.props.nQuoteReceived}>
          Customer Received Quote
        </Button>
        <i class="fas fa-angle-right"></i>
        <Button nRemindedOfReturn={this.props.nRemindedOfReturn}>
          Customer Reminded of Return
        </Button>
        <i class="fas fa-angle-right"></i>
        <Button nRentalClosed={this.props.nRentalClosed}>Rental Closed</Button>
        <i class="fas fa-angle-right"></i>
        <Button nManagerReviewed={this.props.nManagerReviewed}>
          Manager Reviewed
        </Button>
      </Wrapper>
    );
  }
}
