import React, { Component } from 'react';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import QuoteTitle from './QuoteTitle';
import QuoteHeaderControl from './QuoteHeaderControl';
import racLogo from '../../assets/images/brands/rac.png';
import camLogo from '../../assets/images/brands/cam.png';
import csnLogo from '../../assets/images/brands/csn.png';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 200px;
  background-color: #fff;
  padding: 1.8em auto 1.1em auto;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.1);
  border-radius: 15px;
  position: relative;
`;

const QuoteInfo = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 95%;
  margin: 0 auto 1em auto;
`;

const QuoteBrand = styled.div`
  width: 100px;
  justify-self: start;
  margin-right: 5%;

  img {
    width: 100%;
    height: auto;
  }
`;

const QuoteNav = styled.div`
  display: flex;
  flex-wrap: nowrap;
  max-width: 1300px;
  width: 99%;
  margin: 0 auto;

  div {
    flex: 1;
    text-align: center;
    color: #9ca7ae;
    font-weight: 700;
    text-transform: uppercase;
    transition: 0.2s;

    &:hover {
      cursor: pointer;
      color: #222;
    }
  }
`;

export default class QuoteHeader extends Component {
  constructor(props){
    super(props)
    this.state={

    }
  }

  render() {
    let brand = this.props.data.sQuoteType;
    let brandLogo;

    if (brand === 'Rentacomputer.com') {
      brandLogo = <img src={racLogo}></img>;
    } else if (brand === 'CameraSecurityNow.com') {
      brandLogo = <img src={camLogo}></img>;
    } else if (brand === 'ComputerSecurityNow.com') {
      brandLogo = <img src={csnLogo}></img>;
    }

    return (
      <Wrapper>
        <QuoteInfo>
          <MediaQuery minDeviceWidth={1130}>
            <QuoteBrand>
              <a target="_blank" href={`http://www.${this.props.data.sQuoteType}`}>
                {brandLogo}
              </a>
            </QuoteBrand>
          </MediaQuery>
          <QuoteTitle
            company={this.props.data.sCompany}
            title={this.props.data.sQuoteTitle}
            id={this.props.data.id}
            sDAddress={this.props.data.sDAddress}
            sDCity={this.props.data.sDCity}
            sDState={this.props.data.sDState}
            sDZip={this.props.data.sDZip}
            date={this.props.data.date}
            sAddress={this.props.data.sAddress}
            sCity={this.props.data.sCity}
            sState={this.props.data.sState}
            sZip={this.props.data.sZip}
            sQuoteType={this.props.data.sQuoteType}
            nContractType={this.props.data.nContractType}
          />
          <QuoteHeaderControl
            update={this.props.update}
            changePaidStatus={this.props.changePaidStatus}
            saveQuote={this.props.saveQuote}
            status={this.props.data.sStatus}
            tta={this.props.data.nRep}
            tta2={this.props.data.nRep2}
            tta3={this.props.data.nRep3}
            nPaid={this.props.data.nPaid}
            
          />
        </QuoteInfo>
        <QuoteNav>
          <div 
            onClick={() => this.props.handleOnClick("Quote Overview")} 
            style={{color: this.props.viewing=="Quote Overview" && 'black',}}
          >
            Quote Overview
          </div>
          <div 
            onClick={() => this.props.handleOnClick("Communications")} 
            style={{color: this.props.viewing=="Communications" && 'black',}}
          >
            Communications
          </div>
          <div 
            onClick={() => this.props.handleOnClick("Quote Lines")} 
            style={{color: this.props.viewing=="Quote Lines" && 'black',}}
          >
            Quote Lines
          </div>
          <div 
            onClick={() => this.props.handleOnClick("PO Lines")} 
            style={{color: this.props.viewing=="PO Lines" && 'black',}}
          >
            PO Lines
          </div>
          <div 
            onClick={() => this.props.handleOnClick("RFQs")} 
            style={{color: this.props.viewing=="RFQs" && 'black',}}
          >
            RFQs
          </div>
          <div 
            onClick={() => this.props.handleOnClick("Logistics")} 
            style={{color: this.props.viewing=="Logistics" && 'black',}}
          >
            Logistics
          </div>
          <div 
            onClick={() => this.props.handleOnClick("Cost Sheet")} 
            style={{color: this.props.viewing=="Cost Sheet" && 'black',}}
          >
            Cost Sheet
          </div>
        </QuoteNav>
      </Wrapper>
    );
  }
}
