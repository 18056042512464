import React, { Component } from 'react';
import Axios from 'axios';
import styled from 'styled-components';
import { whichRep } from '../../assets/functions/calculations';
import Switch from 'react-switch';

const Wrapper = styled.section`
  background-color: #fff;
  padding: 1em;
  margin: 1.2em 0.6em;
  border-radius: 10px;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.1);
  width: 100%;
  min-width: 250px;
  flex-basis: 15%;
  position: relative;
`;

const Name = styled.h4`
  margin: 0.7em 0 0.4em 0;
`;

const Job = styled.p`
  margin: 0.4em 0;
`;

const OptionsContainer = styled.div`
  margin: 2em 0 3em 0;
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: justify;

  margin-bottom: 1em;
`;

const Button = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: auto;
  text-align: center;
  padding: 0.7em;
  margin-top: 0.5em;
  color: #fff;
  background-color: #4aabed;
  transition: 0.3s;
  cursor: pointer;
  position: absolute;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  &:hover {
    background-color: #5eb4ef;
  }
`;

class UserCard extends Component {
  state = {
    checked: false,
    bSoundNewQuote: false,
    bAutoGrabLeads: false,
    bAutoAssignRepeats: false,
    nSitOutLeads: this.props.nSitOutLeads,
  };

  handleClick(sHashPassword, sEmail) {
    Axios({
      method: 'post',
      url: '/login',
      data: {
        password: sHashPassword,
        email: sEmail,
      },
    });
    window.location.reload();
  }

  componentDidMount() {
    if (this.props.bActive === true) {
      this.setState({
        checked: true,
      });
    }
    if (this.props.bSoundNewQuote === true) {
      this.setState({
        bSoundNewQuote: true,
      });
    }
    if (this.props.bAutoGrabLeads === true) {
      this.setState({
        bAutoGrabLeads: true,
      });
    }
    if (this.props.bAutoAssignRepeats === true) {
      this.setState({
        bAutoAssignRepeats: true,
      });
    }
  }

  handleChangeActive = (checked) => {
    this.setState({ checked });
    Axios({
      method: 'post',
      url: '/change-active-status',
      data: {
        checked: checked,
        id: this.props.id,
      },
    });
  };

  handleChangeSoundNewQuote = (bSoundNewQuote) => {
    this.setState({ bSoundNewQuote });
    Axios({
      method: 'post',
      url: '/change-bSoundNewQuote',
      data: {
        checked: bSoundNewQuote,
        id: this.props.id,
      },
    });
  };

  handleChangeAutoGrabLeads = (bAutoGrabLeads) => {
    this.setState({ bAutoGrabLeads });
    Axios({
      method: 'post',
      url: '/change-bAutoGrabLeads',
      data: {
        checked: bAutoGrabLeads,
        id: this.props.id,
      },
    });
  };

  handleChangeAutoAssignRepeats = (bAutoAssignRepeats) => {
    this.setState({ bAutoAssignRepeats });
    Axios({
      method: 'post',
      url: '/change-bAutoAssignRepeats',
      data: {
        checked: bAutoAssignRepeats,
        id: this.props.id,
      },
    });
  };

  handleChangeSitOutLeads = (e) => {
    this.setState({ nSitOutLeads: e.target.value });
    Axios({
      method: 'post',
      url: '/change-nSitOutLeads',
      data: {
        checked: e.target.value,
        id: this.props.id,
      },
    });
  };

  render() {
    return (
      <Wrapper>
        <i
          style={{ fontSize: "1.5rem", color: "#4AABED" }}
          class="fas fa-user"
        ></i>
        <Name>
          #{this.props.id} {whichRep(this.props.id)}
        </Name>
        <Job>{this.props.sTTATitle ? this.props.sTTATitle : "No Title"}</Job>
        <Job>{this.props.sEmail ? this.props.sEmail : "No Email"}</Job>
        <OptionsContainer>
          <OptionsWrapper>
            <p>Active: </p>
            <label>
              <Switch
                checked={this.state.checked}
                onChange={this.handleChangeActive}
                onColor="#4AABED"
              />
            </label>
          </OptionsWrapper>
          <OptionsWrapper>
            <p>Lead Sound: </p>
            <label>
              <Switch
                checked={this.state.bSoundNewQuote}
                onChange={this.handleChangeSoundNewQuote}
                onColor="#4AABED"
              />
            </label>
          </OptionsWrapper>
          <OptionsWrapper>
            <p>Auto-Grab Leads: </p>
            <label>
              <Switch
                checked={this.state.bAutoGrabLeads}
                onChange={this.handleChangeAutoGrabLeads}
                onColor="#4AABED"
              />
            </label>
          </OptionsWrapper>
          <OptionsWrapper>
            <p>Auto-Assign Repeats: </p>
            <label>
              <Switch
                checked={this.state.bAutoAssignRepeats}
                onChange={this.handleChangeAutoAssignRepeats}
                onColor="#4AABED"
              />
            </label>
          </OptionsWrapper>
          <OptionsWrapper>
            <p>Sit Out Leads: </p>
            <label>
              <select
                onChange={(e) => {
                  this.handleChangeSitOutLeads(e);
                }}
                name="sitOutLeads"
                id="sitOutLeads"
                value={this.state.nSitOutLeads}
              >
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </select>
            </label>
          </OptionsWrapper>
          <Button
            onClick={() =>
              this.handleClick(this.props.sHashPassword, this.props.sEmail)
            }
          >
            <p>Switch to User</p>
          </Button>
        </OptionsContainer>
      </Wrapper>
    );
  }
}

export default UserCard;
