import moment from "moment"

export function underwritingLinks(task, quote, lines, data){
    let links = []
        links[0] = []
        links[1] = []
        links[2] = []
        links[3] = []
        links[4] = []
        links[5] = []
        links[6] = []

        links[0].push({
            link: "https://www.google.com/search?q=" + quote.sCompany.replaceAll(" ","+"),
            text: quote.sCompany.replaceAll(" ","+"),
        })

        links[0].push({
            link: "https://www.google.com/search?q=" + quote.sName.replaceAll(" ","+"),
            text: quote.sName.replaceAll(" ","+"),
        })

        if(quote.sName.replaceAll(" ","+") != quote.sDName.replaceAll(" ","+")){
            links[0].push({
                link: "https://www.google.com/search?q=" + quote.sDName.replaceAll(" ","+"),
                text: quote.sDName.replaceAll(" ","+"),
            })  
        }

        links[0].push({
            link: "https://www.google.com/search?q=" + quote.sName.replaceAll(" ","+") + "+" + quote.sCompany.replaceAll(" ","+") + "+linkedin",
            text: quote.sName.replaceAll(" ","+") + quote.sCompany.replaceAll(" ","+") + "+linkedin",
        })

        if(quote.sCompany.replaceAll(" ","+") != quote.sDCompany.replaceAll(" ","+")){
            links[0].push({
                link: "https://www.google.com/search?q=" + quote.sName.replaceAll(" ","+") + "+" + quote.sDCompany.replaceAll(" ","+") + "+linkedin",
                text: quote.sName.replaceAll(" ","+") + quote.sDCompany.replaceAll(" ","+") + "+linkedin",
            })
        }

        if(quote.sName.replaceAll(" ","+") != quote.sDName.replaceAll(" ","+")){
            links[0].push({
                link: "https://www.google.com/search?q=" + quote.sDName.replaceAll(" ","+") + "+" + quote.sCompany.replaceAll(" ","+") + "+linkedin",
                text: quote.sDName.replaceAll(" ","+") + quote.sCompany.replaceAll(" ","+") + "+linkedin",
            })  

            if(quote.sCompany.replaceAll(" ","+") != quote.sDCompany.replaceAll(" ","+")){
                links[0].push({
                    link: "https://www.google.com/search?q=" + quote.sDName.replaceAll(" ","+") + "+" + quote.sDCompany.replaceAll(" ","+") + "+linkedin",
                    text: quote.sDName.replaceAll(" ","+") + quote.sDCompany.replaceAll(" ","+") + "+linkedin",
                })
            }
        }

        links[0].push({
            link: "https://www.google.com/search?q=" + quote.sAddress.replaceAll(" ","+")
                + (quote.sAddress2 ? "+" + quote.sAddress2.replaceAll(" ","+") : "")
                + "+" + quote.sCity.replaceAll(" ", "+") + "+" + quote.sState.replaceAll(" ", "+"),
            text: quote.sAddress.replaceAll(" ","+")
            + (quote.sAddress2 ? "+" + quote.sAddress2.replaceAll(" ","+") : "")
            + "+" + quote.sCity.replaceAll(" ", "+") + "+" + quote.sState.replaceAll(" ", "+"),
        }) 

        links[0].push({
            link: "https://www.google.com/search?q=" + quote.sAddress.replaceAll(" ","+")
                + (quote.sAddress2 ? "+" + quote.sAddress2.replaceAll(" ","+") : "")
                + "+" + quote.sCity.replaceAll(" ", "+") + "+" + quote.sState.replaceAll(" ", "+")
                + "+virtual+office",
            text: quote.sAddress.replaceAll(" ","+")
            + (quote.sAddress2 ? "+" + quote.sAddress2.replaceAll(" ","+") : "")
            + "+" + quote.sCity.replaceAll(" ", "+") + "+" + quote.sState.replaceAll(" ", "+")
            + "+virtual+office",
        }) 

        links[0].push({
            link: "https://www.google.com/maps?q=" + quote.sAddress.replaceAll(" ","+")
                + (quote.sAddress2 ? "+" + quote.sAddress2.replaceAll(" ","+") : "")
                + "+" + quote.sCity.replaceAll(" ", "+") + "+" + quote.sState.replaceAll(" ", "+"),
            text: "Google Maps: " + quote.sAddress.replaceAll(" ","+")
            + (quote.sAddress2 ? "+" + quote.sAddress2.replaceAll(" ","+") : "")
            + "+" + quote.sCity.replaceAll(" ", "+") + "+" + quote.sState.replaceAll(" ", "+"),
        }) 

        links[0].push({
            link: "https://www.google.com/search?q=" + quote.sAddress.replaceAll(" ","+")
                + (quote.sAddress2 ? "+" + quote.sAddress2.replaceAll(" ","+") : "")
                + "+" + quote.sCity.replaceAll(" ", "+") + "+" + quote.sState.replaceAll(" ", "+")
                + "+zillow",
            text: quote.sAddress.replaceAll(" ","+")
            + (quote.sAddress2 ? "+" + quote.sAddress2.replaceAll(" ","+") : "")
            + "+" + quote.sCity.replaceAll(" ", "+") + "+" + quote.sState.replaceAll(" ", "+")
            + "+zillow",
        }) 
        links[0].push({
            link: "https://www.google.com/search?q=" + quote.sCity.replaceAll(" ", "+") + "+" + quote.sState.replaceAll(" ", "+")
                + "+property+search",
            text: quote.sCity.replaceAll(" ", "+") + "+" + quote.sState.replaceAll(" ", "+")
            + "+property+search",
        }) 

        let emails = []

        if(quote.sEmail){
            let temp = quote.sEmail.split(",")
            for(let i=0; i<temp.length; i++){
                let temp2 = temp[i].split(";")
                for(let j=0; j<temp2.length; j++){
                    emails.push(temp2[j])
                }
                
            }
            for(let i=0; i<emails.length; i++){
                console.log(emails[i])
                links[1].push({
                    link: "https://www.google.com/search?q=" + emails[i].replaceAll(" ", "") + "+" + quote.sCompany.replaceAll(" ", "+"),
                    text: emails[i].replaceAll(" ", "") + "+" + quote.sCompany.replaceAll(" ", "+"),
                })
            }
        }

        let domains = []

        for(let i=0; i<emails.length; i++){
            let domain = emails[i].split("@")[1]
            let isGeneric = checkGenericDomain(domain)
            domains.push({
                domain: domain,
                generic: isGeneric,
            })
            if(!isGeneric){
                links[1].push({
                    link: "https://www.godaddy.com/whois/results.aspx?domain=" + domain,
                    text: "Domain Lookup: " + domain,
                })
                links[1].push({
                    link: "https://" + domain,
                    text: domain,
                })
            }
        }

        links[2].push({
            link: "https://www.google.com/search?q=" + quote.sCompany.replaceAll(" ", "+") + "+facebook",
            text: quote.sCompany.replaceAll(" ", "+") + "+facebook",
        })

        links[2].push({
            link: "https://www.google.com/search?q=" + quote.sCompany.replaceAll(" ", "+") + "+linkedin",
            text: quote.sCompany.replaceAll(" ", "+") + "+linkedin",
        })

        links[2].push({
            link: "https://www.google.com/search?q=" + quote.sCompany.replaceAll(" ", "+") + "+linkedin",
            text: quote.sCompany.replaceAll(" ", "+") + "+twitter",
        })

        if(quote.sCompany.replaceAll(" ","+") != quote.sDCompany.replaceAll(" ","+")){
            links[2].push({
                link: "https://www.google.com/search?q=" + quote.sDCompany.replaceAll(" ", "+") + "+facebook",
                text: quote.sCompany.replaceAll(" ", "+") + "+facebook",
            })

            links[2].push({
                link: "https://www.google.com/search?q=" + quote.sDCompany.replaceAll(" ", "+") + "+linkedin",
                text: quote.sCompany.replaceAll(" ", "+") + "+linkedin",
            })

            links[2].push({
                link: "https://www.google.com/search?q=" + quote.sDCompany.replaceAll(" ", "+") + "+twitter",
                text: quote.sCompany.replaceAll(" ", "+") + "+twitter",
            }) 
        }

        links[2].push({
            link: "https://www.google.com/search?q=" + quote.sCompany.replaceAll(" ", "+") + "+scam",
            text: quote.sCompany.replaceAll(" ", "+") + "+scam",
        }) 

        links[2].push({
            link: "https://www.google.com/search?q=" + quote.sCompany.replaceAll(" ", "+") + "+judgement",
            text: quote.sCompany.replaceAll(" ", "+") + "+judgement",
        })

        if(quote.sCompany.replaceAll(" ","+") != quote.sDCompany.replaceAll(" ","+")){
            links[2].push({
                link: "https://www.google.com/search?q=" + quote.sDCompany.replaceAll(" ", "+") + "+scam",
                text: quote.sDCompany.replaceAll(" ", "+") + "+scam",
            }) 
    
            links[2].push({
                link: "https://www.google.com/search?q=" + quote.sDCompany.replaceAll(" ", "+") + "+judgement",
                text: quote.sDCompany.replaceAll(" ", "+") + "+judgement",
            })
        }

        links[2].push({
            link: "https://www.google.com/search?q=" + quote.sName.replaceAll(" ", "+") + "+scam",
            text: quote.sName.replaceAll(" ", "+") + "+scam",
        }) 

        links[2].push({
            link: "https://www.google.com/search?q=" + quote.sName.replaceAll(" ", "+") + "+judgement",
            text: quote.sName.replaceAll(" ", "+") + "+judgement",
        })

        for(let i=0; i<domains.length; i++){
            links[2].push({
                link: "https://www.google.com/search?q=" + domains[i].domain + "+scam",
                text: domains[i].domain + "+scam",
            }) 
    
            links[2].push({
                link: "https://www.google.com/search?q=" + domains[i].domain + "+judgement",
                text: domains[i].domain + "+judgement",
            })
        }

        links[2].push({
            link: "https://www.google.com/search?q=" + quote.sCompany.replaceAll(" ", "+") + "+owner",
            text: quote.sCompany.replaceAll(" ", "+") + "+owner",
        }) 

        if(quote.sCompany.replaceAll(" ","+") != quote.sDCompany.replaceAll(" ","+")){
            links[2].push({
                link: "https://www.google.com/search?q=" + quote.sDCompany.replaceAll(" ", "+") + "+owner",
                text: quote.sDCompany.replaceAll(" ", "+") + "+owner",
            }) 
        }

        if(quote.sAddress.replaceAll(" ","+")
        + (quote.sAddress2 ? "+" + quote.sAddress2.replaceAll(" ","+") : "")
        + "+" + quote.sCity.replaceAll(" ", "+") + "+" + quote.sState.replaceAll(" ", "+") !=
        quote.sDAddress.replaceAll(" ","+")
        + (quote.sDAddress2 ? "+" + quote.sDAddress2.replaceAll(" ","+") : "")
        + "+" + quote.sDCity.replaceAll(" ", "+") + "+" + quote.sDState.replaceAll(" ", "+")){
            links[3].push({
                link: "https://www.google.com/search?q=" + quote.sDAddress.replaceAll(" ","+")
                    + (quote.sDAddress2 ? "+" + quote.sDAddress2.replaceAll(" ","+") : "")
                    + "+" + quote.sDCity.replaceAll(" ", "+") + "+" + quote.sDState.replaceAll(" ", "+"),
                text: quote.sDAddress.replaceAll(" ","+")
                + (quote.sDAddress2 ? "+" + quote.sDAddress2.replaceAll(" ","+") : "")
                + "+" + quote.sDCity.replaceAll(" ", "+") + "+" + quote.sDState.replaceAll(" ", "+"),
            }) 


            links[3].push({
                link: "https://www.google.com/search?q=" + quote.sDAddress.replaceAll(" ","+")
                    + (quote.sDAddress2 ? "+" + quote.sDAddress2.replaceAll(" ","+") : "")
                    + "+" + quote.sDCity.replaceAll(" ", "+") + "+" + quote.sDState.replaceAll(" ", "+")
                    + "+virtual+office",
                text: quote.sDAddress.replaceAll(" ","+")
                + (quote.sDAddress2 ? "+" + quote.sDAddress2.replaceAll(" ","+") : "")
                + "+" + quote.sDCity.replaceAll(" ", "+") + "+" + quote.sDState.replaceAll(" ", "+")
                + "+virtual+office",
            }) 


            links[3].push({
                link: "https://www.google.com/search?q=" + quote.sDCompany.replaceAll(" ","+"),
                text: quote.sDCompany.replaceAll(" ","+"),
            })  
            

            links[3].push({
                link: "https://www.google.com/maps?q=" + quote.sDAddress.replaceAll(" ","+")
                    + (quote.sDAddress2 ? "+" + quote.sDAddress2.replaceAll(" ","+") : "")
                    + "+" + quote.sDCity.replaceAll(" ", "+") + "+" + quote.sDState.replaceAll(" ", "+"),
                text: "Google Maps: " + quote.sDAddress.replaceAll(" ","+")
                + (quote.sDAddress2 ? "+" + quote.sDAddress2.replaceAll(" ","+") : "")
                + "+" + quote.sDCity.replaceAll(" ", "+") + "+" + quote.sDState.replaceAll(" ", "+"),
            }) 

            links[3].push({
                link: "https://www.google.com/search?q=" + quote.sDAddress.replaceAll(" ","+")
                    + (quote.sDAddress2 ? "+" + quote.sDAddress2.replaceAll(" ","+") : "")
                    + "+" + quote.sDCity.replaceAll(" ", "+") + "+" + quote.sDState.replaceAll(" ", "+"),
                text: quote.sDAddress.replaceAll(" ","+")
                + (quote.sDAddress2 ? "+" + quote.sDAddress2.replaceAll(" ","+") : "")
                + "+" + quote.sDCity.replaceAll(" ", "+") + "+" + quote.sDState.replaceAll(" ", "+")
                + "+zillow",
            }) 

            links[3].push({
                link: "https://www.google.com/search?q=" + quote.sDCompany.replaceAll(" ", "+") + "+scam",
                text: quote.sDCompany.replaceAll(" ", "+") + "+scam",
            }) 
    
            links[3].push({
                link: "https://www.google.com/search?q=" + quote.sDCompany.replaceAll(" ", "+") + "+judgement",
                text: quote.sDCompany.replaceAll(" ", "+") + "+judgement",
            })

            links[3].push({
                link: "https://www.google.com/search?q=" + quote.sDName.replaceAll(" ", "+") + "+scam",
                text: quote.sDName.replaceAll(" ", "+") + "+scam",
            }) 
    
            links[3].push({
                link: "https://www.google.com/search?q=" + quote.sDName.replaceAll(" ", "+") + "+judgement",
                text: quote.sDName.replaceAll(" ", "+") + "+judgement",
            })

            links[3].push({
                link: "https://www.google.com/search?q=" + quote.sDAddress.replaceAll(" ","+")
                    + (quote.sDAddress2 ? "+" + quote.sDAddress2.replaceAll(" ","+") : "")
                    + "+" + quote.sDCity.replaceAll(" ", "+") + "+" + quote.sDState.replaceAll(" ", "+")
                    + "+scam",
                text: quote.sDAddress.replaceAll(" ","+")
                + (quote.sDAddress2 ? "+" + quote.sDAddress2.replaceAll(" ","+") : "")
                + "+" + quote.sDCity.replaceAll(" ", "+") + "+" + quote.sDState.replaceAll(" ", "+")
                + "+scam",
            }) 

            links[3].push({
                link: "https://www.google.com/search?q=" + quote.sDAddress.replaceAll(" ","+")
                    + (quote.sDAddress2 ? "+" + quote.sDAddress2.replaceAll(" ","+") : "")
                    + "+" + quote.sDCity.replaceAll(" ", "+") + "+" + quote.sDState.replaceAll(" ", "+")
                    + "+judgement",
                text: quote.sDAddress.replaceAll(" ","+")
                + (quote.sDAddress2 ? "+" + quote.sDAddress2.replaceAll(" ","+") : "")
                + "+" + quote.sDCity.replaceAll(" ", "+") + "+" + quote.sDState.replaceAll(" ", "+")
                + "+judgement",
            }) 

            links[3].push({
                link: "https://www.google.com/search?q=" + quote.sDAddress.replaceAll(" ","+")
                    + (quote.sDAddress2 ? "+" + quote.sDAddress2.replaceAll(" ","+") : "")
                    + "+" + moment(quote.dtDeliveryDate).format('MMMM') + "+" + moment(quote.dtDeliveryDate).format('YYYY'),
                text: quote.sDAddress.replaceAll(" ","+")
                + (quote.sDAddress2 ? "+" + quote.sDAddress2.replaceAll(" ","+") : "")
                + "+" + moment(quote.dtDeliveryDate).format('MMMM') + "+" + moment(quote.dtDeliveryDate).format('YYYY')
            }) 
            
            links[3].push({
                link: "https://www.google.com/search?q=" + quote.sDCompany.replaceAll(" ","+")
                    + "+" + moment(quote.dtDeliveryDate).format('MMMM') + "+" + moment(quote.dtDeliveryDate).format('YYYY'),
                text: quote.sDCompany.replaceAll(" ","+")
                + "+" + moment(quote.dtDeliveryDate).format('MMMM') + "+" + moment(quote.dtDeliveryDate).format('YYYY')
            }) 
        }

        
        return links


}

export function showQuestions(questions){
    for(let i=0; i<questions.length; i++){
        questions[i].show = showQuestion(questions[i], questions)
        if(i===0){
            questions[i].offset = 0
        } else {
            questions[i].offset = questions[i-1].offset + (questions[i-1].show ? 0 : 1)
        }
    }

    return questions
}

export function checkQuestions(questions, page){
    questions = showQuestions(questions)
    let success = true
    for(let i=0; i<questions.length; i++){
        if(questions[i].show && !questions[i].answer && questions[i].page===page){success=false}
    }

    return success
}

export function summarizeFlags(questions){
    let flags = {}
    flags.list = []
    flags.Top = []
    flags.High = []
    flags.Neutral = []
    flags.Low = []
    flags.correction = []

    for(let i=0; i<questions.length; i++){
        if(questions[i].show && questions[i].answer && ( questions[i].flag === questions[i].answer || questions[i].correction === questions[i].answer)){
            if(questions[i].priority){
                flags[questions[i].priority].push(questions[i])
            } else {
                flags.correction.push(questions[i])
            }
        }
    }

    console.log(flags)

    return flags
}

export function showQuestion(question, questions){
    let showQuestion = true

    if(question.dependency){
        if(question.dependency.param === "bCCRequired"){
            let requestTerms = questions.find(x => x.param === "bTerms")
            let termsPlusCC = questions.find(x => x.param === "bTermsRequireCreditCard")

            if(requestTerms === "Yes" && termsPlusCC === "No"){
                showQuestion = false
            }
        } else {
            let checkQuestion = questions.find(x => x.param === question.dependency.param)
            if(checkQuestion.answer !== question.dependency.condition){
                showQuestion = false
            }
        }
        
    }

    return showQuestion
  }

export function checkGenericDomain(domain){
    let genericDomains = [
        "gmail.com",
        "yahoo.com",
        "hotmail.com",
        "outlook.com",
        "aol.com",
        "icloud.com",
        "protonmail.com",
        "mail.com",
        "zoho.com",
        "yandex.com",
        "inbox.com",
        "gmx.com",
        "fastmail.com",
        "mailinator.com",
        "tutanota.com",
        "me.com",
        "mac.com"
      ];

    let isGeneric = genericDomains.includes(domain)

    return isGeneric
}

export function underwritingQuestions() {
    let questions = [
        {
            question: "Can you find the company name mentioned on the internet?",
            param: "bCompanyNameInternet",
            page: 0,
            type: "Yes/No",
            flag: "No",
            priority: "High",
        },
        {
            question: "Can you link the contact's name to the company on the internet?",
            param: "bContactCompanyMatch",
            page: 0,
            type: "Yes/No",
            flag: "No",
            priority: "High",
        },
        {
            question: "Can you find the contact's name on LinkedIn?",
            param: "bContactLinkedIn",
            page: 0,
            type: "Yes/No",
            flag: "No",
            priority: "High",
        },
        {
            question: "Can you match the bill to address to the company on the internet?",
            param: "bCompanyBillingAddressMatch",
            page: 0,
            type: "Yes/No",
            flag: "No",
            priority: "High",
        },
        {
            question: "Can you match the bill to address to the phrase 'virtual office'?",
            param: "bBillingAddressVirtual",
            page: 0,
            type: "Yes/No",
            flag: "Yes",
            priority: "High",
        },
        {
            question: "Does the bill to address look residential or commercial on Google Maps?",
            param: "sBillingAddressType",
            page: 0,
            type: "Residential/Commercial",
            flag: "Residential",
            priority: "High",
        },
        {
            question: "If the bill to address is residential, is it a home or apartment?",
            param: "sBillingResidentialType",
            page: 0,
            type: "Home/Apartment",
            flag: "Apartment",
            priority: "Top",
            dependency: {
                param: "sBillingAddressType",
                condition: "Residential",
            },
        },
        {
            question: "If the bill to address is a home, what is its value (Zillow)?",
            param: "nResidentialValue",
            page: 0,
            type: "int",
            flag: 200000,
            priority: "Neutral",
            dependency: {
                param: "sBillingResidentialType",
                condition: "Home",
            },
        },
        {
            question: "If the bill to address is a home, can you match the homeownership to the contact via a property search?",
            param: "bContactProptertyRecordsMatch",
            page: 0,
            type: "Yes/No",
            flag: "No",
            priority: "High",
            dependency: {
                param: "sBillingResidentialType",
                condition: "Home",
            },
        },
        {
            question: "Can you match the email address to the company on the internet?",
            param: "bEmailCompanyMatch",
            page: 1,
            type: "Yes/No",
            flag: "No",
            priority: "High",
        },
        {
            question: "Is the email address from a generic domain?",
            param: "bEmailGenericDomain",
            page: 1,
            type: "Yes/No",
        },
        {
            question: "If the company has a website, when was the domain registered?",
            param: "dtDomainRegistration",
            page: 1,
            type: "date",
            flag: "Year",
            priority: "High",
            dependency: {
                param: "bEmailGenericDomain",
                condition: "No",
            },
        },
        {
            question: "Does the URL in the email address redirect to another website?",
            param: "bDomainRedirect",
            page: 1,
            type: "Yes/No",
            flag: "Yes",
            priority: "High",
            dependency: {
                param: "bEmailGenericDomain",
                condition: "No",
            },
        },
        {
            question: "If there is a redirect, are they using the same registrar?",
            param: "bRedirectRegistrar Match",
            page: 1,
            type: "Yes/No",
            flag: "No",
            priority: "Neutral",
            dependency: {
                param: "bDomainRedirect",
                condition: "Yes",
            },
        },
        {
            question: "If there is a redirect, can you put the domain name in quotes and find it mentioned in a positive context in a search?",
            param: "bRedirectDomainPositiveReputation",
            page: 1,
            type: "Yes/No",
            flag: "No",
            priority: "High",
            dependency: {
                param: "bDomainRedirect",
                condition: "Yes",
            },
        },
        {
            question: "Can you find recent social media activity?",
            param: "bSocialMediaActive",
            page: 2,
            type: "Yes/No",
            flag: "No",
            priority: "Neutral",
        },
        {
            question: "Can you associate the company name, the domain name, or the contact's name to the word 'scam' or 'judgement'?",
            param: "bScamMatch",
            page: 2,
            type: "Yes/No",
            flag: "Yes",
            priority: "Top",
        },
        {
            question: "Is the company a solopreneur?",
            param: "bSolopreneur",
            page: 2,
            type: "Yes/No",
        },
        {
            question: "If the company is a solopreneur, does a background check show a bankruptcy or large lien against the contact?",
            param: "bContactCreditIssues",
            page: 2,
            type: "Yes/No",
            flag: "Yes",
            priority: "Top",
            dependency: {
                param: "bSolopreneur",
                condition: "Yes",
            },
        },
        {
            question: "Is the delivery address complete with contact name, 'care of', or suite number, if appropriate?",
            param: "bDeliveryContactNameCheck",
            page: 3,
            type: "Yes/No",
            correction: "No",
        },
        {
            question: "Can you find the delivery company name mentioned on the internet?",
            param: "bDeliveryCompanyNameInternet",
            page: 3,
            type: "Yes/No",
            flag: "No",
            priority: "High",
        },
        {
            question: "Can you match the delivery address to the company on the internet?",
            param: "bDeliveryCompanyAddressMatch",
            page: 3,
            type: "Yes/No",
            flag: "No",
            priority: "High",
        },
        {
            question: "Can you match the delivery address to the phrase 'virtual office'?",
            param: "bDeliveryAddressVirtual",
            page: 3,
            type: "Yes/No",
            flag: "Yes",
            priority: "High",
        },
        {
            question: "Does the delivery address look safe on Google Maps?",
            param: "bDeliveryAddressSafe",
            page: 3,
            type: "Yes/No",
            flag: "No",
            priority: "High",
        },
        {
            question: "Does the bill to address look residential or commercial on Google Maps?",
            param: "sDeliveryAddressType",
            page: 3,
            type: "Residential/Commercial",
            flag: "Residential",
            priority: "High",
        },
        {
            question: "If the bill to address is residential, is it a home or apartment?",
            param: "sDeliveryResidentialType",
            page: 3,
            type: "Home/Apartment",
            flag: "Apartment",
            priority: "Top",
            dependency: {
                param: "sDeliveryAddressType",
                condition: "Residential",
            },
        },
        {
            question: "Can you associate the delivery location, address, contact name, or company name to the word 'scam' or 'judgement'?",
            param: "bDeliveryScamMatch",
            page: 3,
            type: "Yes/No",
            flag: "Yes",
            priority: "Top",
        },
        {
            question: "Can you match an event to the delivery address during the dates the equipment is rented?",
            param: "bDeliveryAddressEventDateMatch",
            page: 3,
            type: "Yes/No",
            flag: "No",
            priority: "Neutral",
        },
        {
            question: "Are delivery and pickup dates complete?",
            param: "bDatesComplete",
            page: 3,
            type: "Yes/No",
            correction: "No",
        },
        {
            question: "Are delivery and pickup times complete?",
            param: "bTimesComplete",
            page: 3,
            type: "Yes/No",
            correction: "No",
        },
        {
            question: "Do the delivery dates require any expedited shipping?",
            param: "bExpeditedShipping",
            page: 3,
            type: "Yes/No",
        },
        {
            question: "Are the delivery dates obtainable?",
            param: "bRealisticDelivery",
            page: 3,
            type: "Yes/No",
            correction: "No",
        },
        {
            question: "Is the customer applying for terms?",
            param: "bTerms",
            page: 4,
            type: "Yes/No",
        },
        {
            question: "If Terms are selected, do we have supporting documentation to approve, such as a completed business application or PO from a large corporation or government entity?",
            param: "bTermsSupportingDocumentation",
            page: 4,
            type: "Yes/No",
            flag: "No",
            priority: "Top",
            dependency: {
                param: "bTerms",
                condition: "Yes",
            },
        },
        {
            question: "If Terms are selected, should we require a Credit Card on file for damages, renewals, or copier overages, etc?",
            param: "bTermsRequireCreditCard",
            page: 4,
            type: "Yes/No",
        },
        {
            question: "If terms are selected, is payment in advance required?",
            param: "bCreditCardInfoComplete",
            page: 4,
            type: "Yes/No",
        },
        {
            question: "If a credit card is required, is the card information complete and listed on the signed proposal??",
            param: "bCreditCardCharged",
            page: 4,
            type: "Yes/No",
            correction: "No",
            dependency: {
                param: "bCCRequired",
                condition: "Yes",
            },
        },
        {
            question: "If a credit card is required, has it been charged?",
            param: "bCreditCardCharged",
            page: 4,
            type: "Yes/No",
            correction: "No",
            dependency: {
                param: "bCCRequired",
                condition: "Yes",
            },
        },
        {
            question: "If a credit card is required, does the name on the card match any of the contact names?",
            param: "bCreditCardNameMatch",
            page: 4,
            type: "Yes/No",
            flag: "No",
            priority: "High",
            dependency: {
                param: "bCCRequired",
                condition: "Yes",
            },
        },
        {
            question: "If a credit card is required, can you match the name on the card to the company?",
            param: "bCreditCardCompanyMatch",
            page: 4,
            type: "Yes/No",
            flag: "No",
            priority: "High",
            dependency: {
                param: "bCCRequired",
                condition: "Yes",
            },
        },
        {
            question: "If a credit card is required, does the address on the card match the company or delivery location?",
            param: "bCreditCardAddressMatch",
            page: 4,
            type: "Yes/No",
            flag: "No",
            priority: "High",
            dependency: {
                param: "bCCRequired",
                condition: "Yes",
            },
        },
        {
            question: "If a credit card is required, is the expiration date on the card after the pickup date?",
            param: "dtCreditCardExpiration",
            page: 4,
            type: "Yes/No",
            flag: "No",
            priority: "High",
        },
        {
            question: "Are the item descriptions concise and up to date?",
            param: "bCreditCardCharged",
            page: 5,
            type: "Yes/No",
            correction: "No",
        },
        {
            question: "Are the quantities clearly defined?",
            param: "bItemQuantitiesClear",
            page: 5,
            type: "Yes/No",
            correction: "No",
        },
        {
            question: "Are all appropriate line items being billed?",
            param: "bAllLineItemsBilled",
            page: 5,
            type: "Yes/No",
            correction: "No",
        },
        {
            question: "Did the rep apply the correct sales tax to the order?",
            param: "bSalesTaxCorrect",
            page: 5,
            type: "Yes/No",
            correction: "No",
        },
        {
            question: "Does the email appear well written?",
            param: "bCustomerProfessionalism",
            page: 5,
            type: "Yes/No",
            correction: "No",
        },
        {
            question: "Is the client concerned with pricing?",
            param: "bClientPricingPressure",
            page: 5,
            type: "Yes/No",
            flag: "No",
            priority: "Low",
        },
        {
            question: "Does the client increase the order size?",
            param: "bOrderSizeIncrease",
            page: 5,
            type: "Yes/No",
            flag: "Yes",
            priority: "Low",
        },
        {
            question: "Is the client looking for high-end systems or Apple equipment?",
            param: "bRiskItemsInvolved",
            page: 5,
            type: "Yes/No",
            flag: "Yes",
            priority: "High",
        },
    ]

    return questions
}

export function matchPOandLines(POs, lines){
    for(let i=0;i<POs.length;i++){
        POs[i].lines = []
        for(let j=0;j<lines.length;j++){
            if(lines[j].nQuote===POs[i].id){
                POs[i].lines.push(lines[j])
            }
        }
    }

    return POs
}