import React, { Component } from 'react'
import { getSQLData, setSQLData } from '../../../../assets/functions/fetch';
import { calcPreSalesTaxCommissionLog, nQuoteTotalNoDollar, whichRep } from '../../../../assets/functions/calculations';
import moment from 'moment';

export default class CommissionLogMismatch extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loaded: false,

        };

      }

      componentDidMount(){
        this.fetchData()
      }

      fetchData = async() => {
        try {
            const fetch = await getSQLData("accounting/commissionLogMismatch", {id: this.state.id})
            
            if(fetch){
                let data = fetch.data.data[0].recordset

                this.setState({
                    data: data,
                }, ()=>this.processData())

            }

        } catch (error) {

        }
      }

      processData() {
        let data = this.state.data

        for(let i=0; i<data.length; i++){
            data[i].margin = data[i].linesTotal + data[i].nDelivery - data[i].POTotal


        }

        this.setState({
            data: data,
            loaded: true,
        })
      }

      insertException = async(index, exception) => {
        let entry = this.state.data[index]
        try {
            const fetch = await setSQLData("accounting/addCommissionLogException",{
                nQuote: entry.id,
                nRep: entry.nRep,
                nMargin: entry.margin-entry.commissionLog, 
                dtDate: moment().format("MM/DD/YYYY"),
                sException: exception,
            })

            if(fetch){this.fetchData()}
        } catch (error) {

        }
        
      }

      deleteLog = async(logID) => {
        try {
          const fetch = await setSQLData("accounting/deleteCommissionLog",{
            logID: logID,
          })
          
          if(fetch){this.fetchData()}
        } catch (error) {

        }
      }


  render() {
    return (
      <div>
        {this.state.loaded && (

        
        <table>
            <thead>
                <tr>
                    <th>Quote</th>
                    <th>Margin</th>
                    <th>Logged</th>
                    <th>Insert Exception</th>
                    <th>Rep</th>
                    <th>First Closed</th>
                    <th>Invoiced</th>
                    <th>Paid</th>
                    <th>Delivery</th>
                </tr>
            </thead>
        
        {this.state.data.map((quote,index)=>(
            <tr>
                <td>{quote.id}</td>
                <td>{nQuoteTotalNoDollar(quote.margin)}</td>
                <td>{quote.commissionLog}</td>
                <td>
                  <button onClick={()=>this.insertException(index, "Credit Card Fee")}>CC</button>
                  <button onClick={()=>this.insertException(index, "Flag")}>Flag</button>
                  <button onClick={()=>this.insertException(index, "Damage Insurance")}>Damage</button>
                  <button onClick={()=>this.insertException(index, "Cancelled Order")}>Cancelled Order</button>
                  <button onClick={()=>this.insertException(index, "Early Return")}>Early Return</button>
                  <button onClick={()=>this.insertException(index, "Late Shipment")}>Late Shipment</button>
                  <button onClick={()=>this.insertException(index, "Vendor Cost Revision")}>Vendor Cost Revision</button>
                  <button onClick={()=>this.insertException(index, "Customer Quantity Change")}>Customer Quantity Change</button>
                  <button onClick={()=>this.deleteLog(quote.logID)}>Delete</button>
                </td>
                <td>{whichRep(quote.nRep)}</td>
                <td>{quote.dtFirstClosed}</td>
                <td>{quote.dtInvoiced}</td>
                <td>{quote.dtPaid}</td>
                <td>{quote.dtDeliveryDate}</td>
                
            </tr>
        ))}
        </table>

        )}
      </div>
    )
  }
}
