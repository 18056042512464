import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin: 0;
  width: 100%;
  height: 706px;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.1);
  text-align: center;
  border-radius: 15px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4aabed;
  color: #fff;
  height: 35px;
  font-weight: bold;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
`;

const Form = styled.form`
  margin: 1em;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 98%;
  margin: 1em 0.5em;

  input {
    height: 37px;
    width: 80%;
    padding: 0 0.7em;
    border: 1px solid #fff;
    border-radius: 5px;
    transition: box-shadow 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    background-color: #f2f5f7;
  }

  p {
    width: 20%;
    text-align: left;
    margin: 0;
    font-weight: 500;
  }

  div {
    width: 80%;
    margin: 0;
    padding: 0;

    input {
      width: 100%;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto 1.4em 1.1em 1.4em;

  button {
    cursor: pointer;
    width: 50%;
    margin: 0.7em 0.2em;
    height: 50px;
    border: none;
    transition: all 0.2s;
    border-radius: 4px;
    background-color: #e8edf1;

    &:hover {
      background-color: #4aabed;
      color: #fff;
    }
  }
`;

export default class CustomerInformation extends Component {
  render() {
    return (
      <Wrapper>
        <Title>
          <p>Customer Information</p>
        </Title>
        <Form autoComplete="off">
          <InputWrapper>
            <p>Company:</p>
            <input
              id="company"
              name="company"
              type="text"
              value={this.props.sCompany}
              onChange={(event) => this.props.update("sCompany", event.target.value)}
            ></input>
          </InputWrapper>
          <InputWrapper>
            <p>Name:</p>
            <input
              id="name"
              name="name"
              type="text"
              value={this.props.sName}
              onChange={(event) => this.props.update("sName", event.target.value)}
            ></input>
          </InputWrapper>
          <InputWrapper>
            <p>Address:</p>
            <div>
              <input
                id="address"
                name="address"
                type="text"
                value={this.props.sAddress}
                onChange={(event) => this.props.update("sAddress", event.target.value)}
              ></input>
              <input
                id="address2"
                name="address2"
                type="text"
                value={this.props.sAddress2}
                onChange={(event) => this.props.update("sAddress2", event.target.value)}
              ></input>
            </div>
          </InputWrapper>
          <InputWrapper>
            <p>City:</p>
            <input
              id="city"
              name="city"
              type="text"
              value={this.props.sCity}
              onChange={(event) => this.props.update("sCity", event.target.value)}
            ></input>
          </InputWrapper>
          <InputWrapper>
            <p>State:</p>
            <input
              id="state"
              name="state"
              type="text"
              value={this.props.sState}
              onChange={(event) => this.props.update("sState", event.target.value)}
            ></input>
          </InputWrapper>
          <InputWrapper>
            <p>Zip:</p>
            <input
              id="zip"
              name="zip"
              type="number"
              value={this.props.sZip}
              onChange={(event) => this.props.update("sZip", event.target.value)}
            ></input>
          </InputWrapper>
          <InputWrapper>
            <p>Phone:</p>
            <input
              id="phone"
              name="phone"
              type="text"
              value={this.props.sPhone}
              onChange={(event) => this.props.update("sPhone", event.target.value)}
            ></input>
          </InputWrapper>
          <InputWrapper>
            <p>Fax:</p>
            <input
              id="fax"
              name="fax"
              type="text"
              value={this.props.sFax}
              onChange={(event) => this.props.update("sFax", event.target.value)}
            ></input>
          </InputWrapper>
          <InputWrapper>
            <p>Email:</p>
            <input
              id="email"
              name="email"
              type="text"
              value={this.props.sEmail}
              onChange={(event) => this.props.update("sEmail", event.target.value)}
            ></input>
          </InputWrapper>
        </Form>
        <ButtonWrapper>
          <button onClick={this.handleOnClick}>Copy Billing</button>
          <button onClick={this.handleOnClick}>Copy Delivery</button>
        </ButtonWrapper>
      </Wrapper>
    );
  }
}
