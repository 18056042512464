import moment from 'moment'
import React, { Component } from 'react'
import { showQuestion } from '../../../assets/functions/underwriting'

export default class UnderwritingFlag extends Component {
  render() {
    return (
      <>
      
      {this.props.question.flag && 
        ((this.props.question.answer === this.props.question.flag 
            || (this.props.question.param === "dtDomainRegistration" && moment().diff(this.props.question.answer, "days") < 365) 
            || (this.props.question.param === "nResidentialValue" && this.props.question.answer < this.props.question.flag)
        )
        && (showQuestion(this.props.question, this.props.questions))
        ) &&
        (
            <div style={{
                fontWeight: (this.props.question.priority === "Top" || this.props.question.priority === "High") ? "bold" : "",
                fontSize: this.props.question.priority === "Top" ? '18px' : (this.props.question.priority === "Low" ? '12px' : ''),
                }}>
                {this.props.displayQuestion ? this.props.question.question : ""} {this.props.question.answer} ({this.props.question.priority})
            </div>
        ) }

        {this.props.question.correction && this.props.question.answer === this.props.question.correction && (
          <div>
            {this.props.displayQuestion ? this.props.question.question : ""} {this.props.question.answer} (Correction)
          </div>
        )}
        
        </>
    )
  }
}
