import React, { Component } from 'react';
import { nQuoteTotalNoDollar } from '../../../assets/functions/calculations';
import moment from 'moment';
import { setSQLData } from '../../../assets/functions/fetch';
import { Title } from '../../styled/ui';

class UpdateCommissions extends Component {

    state = {
      seedDate: moment("20230113"),
      initials: '',
      displayYear: 2023,
      paidLogChanges: [],
    }

    initializePayrolls(){
      let payrollsForward = 100
      let initials = this.state.initials
      let seedDate = this.state.seedDate

      let currentYear = moment(seedDate).format('YYYY')
      let currentPayroll = 0

      for(let i=0; i<payrollsForward; i++){
        let payrollYear = moment(seedDate).add(14*i, 'days').format('YYYY')
        let payrollDate = moment(seedDate).add(14*i, 'days').format('MM/DD/YYYY')

        if(payrollYear > currentYear){
          currentYear++
          currentPayroll=0
        } else {
          currentPayroll++
        }

        setSQLData("accounting/add_commission_paid_log", {
          nYear: payrollYear,
          nPayroll: currentPayroll,
          dtDate: payrollDate,
          nAmount: 0,
          sRep: initials,
        })

      }
    }

    componentDidMount(){
      this.setState({modifiedPaidLog: this.props.paidLogYears, loaded: true})
    }

    handleUpdatePayroll(event,rep,index){
      let modifiedPaidLog = this.state.modifiedPaidLog
      let paidLogChanges = this.state.paidLogChanges

      modifiedPaidLog[this.state.displayYear][index][rep] = event.target.value

      if(!paidLogChanges.find(x => (x.nYear == this.state.displayYear && x.nPayroll == index && x.sRep == rep))){
        paidLogChanges.push({
          nYear: this.state.displayYear,
          nPayroll: index,
          sRep: rep,
        })
      }
      

      this.setState({
        modifiedPaidLog: modifiedPaidLog,
        paidLogChanges: paidLogChanges,
      })
    }

    saveCommissionLogChanges() {
      let paidLogChanges = this.state.paidLogChanges
      let modifiedPaidLog = this.state.modifiedPaidLog

      for(let i=0; i<paidLogChanges.length; i++){
        console.log(modifiedPaidLog, paidLogChanges[i].nYear, paidLogChanges[i].nPayroll, paidLogChanges[i].sRep)
        let amount = parseFloat(modifiedPaidLog[paidLogChanges[i].nYear][paidLogChanges[i].nPayroll][paidLogChanges[i].sRep])
        setSQLData("accounting/update_commission_paid_log", {
          nYear: paidLogChanges[i].nYear,
          nPayroll: paidLogChanges[i].nPayroll,
          nAmount: amount,
          sRep: paidLogChanges[i].sRep,
        })
        
      }

      paidLogChanges = []

      this.props.fetchData()

      this.setState({
        paidLogChanges: paidLogChanges
      })

      
    }


    render() {
        return (
          <div>
            {/*
            Create payrolls for a new rep
            
            <input onChange={(event)=>this.setState({initials: event.target.value})} value={this.state.initials}></input>
            <button onClick={()=>this.initializePayrolls()}>Create Payrolls</button>*/}
            {this.state.loaded && (
              <>
              
            <Title>Payroll Year: {this.state.displayYear}</Title>
            <button onClick={()=>this.saveCommissionLogChanges()}>Save Commision Log Changes</button>
            <table>
              <thead>
                <tr>
                  <th>Payroll</th>
                  <th>Date</th>
                  {this.props.reps.map((rep,index)=>(
                    <th>{rep}</th>
                  ))}
                </tr>
              </thead>
                {this.state.modifiedPaidLog[this.state.displayYear].map((payroll,index)=>(
                  <>
                  {index > 0 && (
                    <tr>
                      <td>{index}</td>
                      <td>{moment(payroll.date).format("MM/DD/YYYY")}</td>
                      {this.props.reps.map((rep,index2)=>(
                        <td><input onChange={(event)=>this.handleUpdatePayroll(event,rep,index)} value={payroll[rep]} /></td>
                      ))}
                    </tr>
                  )}
                  
                  </>
                ))}
              
            </table>
            </>
            )}

          </div>
            
        );
    }
}

export default UpdateCommissions;