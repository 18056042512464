import React, { Component } from 'react';
import { whichRep } from '../../../assets/functions/calculations';
import moment from 'moment';
import parse from 'node-html-parser';

class DiscontinuedQuoteViewer extends Component {
    constructor(props) {
        super(props);
        this.state = {
          viewing: "Quote",
        };
      }

    componentDidMount() {
        
      }
    
     

     

    render() {
        let quote = this.props.quote
        let lines = this.props.lines
        let notes = this.props.notes
        let emails = this.props.emails

        return (
            <div>
              <button onClick={()=>this.setState({viewing:"Quote"})}>Quote</button>
              <button onClick={()=>this.setState({viewing:"Notes"})}>Notes</button>
              <button onClick={()=>this.setState({viewing:"Emails"})}>Emails</button>
                {this.state.viewing === "Quote" && (
                  <>
                  <table>
                    <tr>
                      <td>Discontinue Reason:</td>
                      <td>{quote.sWhyCancel ? quote.sWhyCancel : ""}</td>
                    </tr>
                    <tr>
                      <td>Discontinue Date:</td>
                      <td>{moment(quote.dtLastUpdated).format('MM/DD/YYYY hh:mm A')}</td>
                    </tr>
                    <tr>
                      <td>Quote Total:</td>
                      <td>{quote.nQuoteTotal}</td>
                    </tr>
                    <tr>
                      <td># of Closed Quotes in the last 3 years:</td>
                      <td>{quote.Closed_Quotes}</td>
                    </tr>
                    <tr>
                      <td># of Open Quotes:</td>
                      <td>{quote.Open_Quotes}</td>
                    </tr>
                    <tr>
                      <td>Email:</td>
                      <td>{quote.sEmail}</td>
                    </tr>
                    <tr>
                      <td>Phone:</td>
                      <td>{quote.sPhone}</td>
                    </tr>
                    <tr>
                      <td>Original Request:</td>
                      <td>{quote.sQuote}</td>
                    </tr>
                    <tr>
                      <td>Delivery Date:</td>
                      <td>{moment(quote.dtDeliveryDate).format('MM/DD/YYYY hh:mm A')}</td>
                    </tr>
                    <tr>
                      <td>Pickup Date:</td>
                      <td>{moment(quote.dtPickupDate).format('MM/DD/YYYY hh:mm A')}</td>
                    </tr>
                    <tr>
                      <td>
                        <div><b>Company info</b></div>
                        <div>{quote.Company_Name}</div>
                        <div>{quote.sName}</div>
                        <div>{quote.sAddress}</div>
                        <div>{quote.sAddress2}</div>
                        <div>{quote.sCity}, {quote.sState} {quote.sZip}</div>
                      </td>
                      <td>
                        <div><b>Delivery info</b></div>
                        <div>{quote.sDCompany}</div>
                        <div>{quote.sDName}</div>
                        <div>{quote.sDAddress}</div>
                        <div>{quote.sDAddress2}</div>
                        <div>{quote.sDCity}, {quote.sDState} {quote.sDZip}</div>
                      </td>
                    </tr>
                  </table>
                    
                      <div>
                        
                      </div>
                      <div>
                        
                      </div>
                    <div>
                      <table>
                        <thead>
                          <tr>
                            <th>Quantity</th>
                            <th>Description</th>
                            <th>Price</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        {lines.map((line,index)=>(
                          <tr>
                            <td>{line.nQuantity}</td>
                            <td>{line.sDescription}</td>
                            <td>{line.nPrice}</td>
                            <td>{line.nQuantity * line.nPrice}</td>
                          </tr>
                        ))}
                      </table>
                    </div>
                  </>
                )}
                {this.state.viewing === "Notes" && (
                  <div>
                    {notes.map((note, index)=>(
                        <div style={{
                          border: '1px solid black',
                          margin: '10px',
                          padding: '10px',
                        }}>
                          <div><b>{whichRep(note.nRep)} {moment(note.dtTimeStamp).utc().format('MM/DD/YYYY hh:mm A')}</b></div>
                          <div>{parse(note.sNote).text}</div>
                        </div>                          
                    ))}
                  </div>
                )}
                {this.state.viewing === "Emails" && (
                  <div>
                    {emails.map((email, index)=>(
                      <div style={{
                        border: '1px solid black',
                        margin: '10px',
                        padding: '10px',
                      }}>
                        <div><b>Date: </b>{moment(email.dtDate).format("MM/DD/YYYY hh:mm A")}</div>
                        <div><b>From: </b>{email.sFrom}</div>
                        <div><b>To: </b>{email.sTo}</div>
                        <div><b>Subject: </b>{email.sSubject}</div>
                        <div>{parse(email.sBody).text}</div>
                      </div>
                    ))}
                  </div>
                )}
            </div>
        );
    }
}

export default DiscontinuedQuoteViewer;