import React, { Component } from 'react';
import Axios from 'axios';
import styled from 'styled-components';
import QuoteHeader from '../quote/QuoteHeader';
import QuoteOverview from '../quote/quote-overview/QuoteOverview';
import QuoteLines from '../quote/quote-lines/QuoteLines';
import PoLines from '../quote/po-lines/PoLines';
import Communications from '../quote/communications/Communications';
import Rfqs from '../quote/rfqs/Rfqs';
import Logistics from '../quote/logistics/Logistics';
import CostSheet from '../quote/cost-sheet/CostSheet';
import SideBar from '../quote/sidebar/SideBar';
import Loader from 'react-loader-spinner';
import { getSQLData, setSQLData } from '../../assets/functions/fetch';
import { buildAddress, formatQuoteChangeLog, generateCustomerCSV, generateInvoiceCSV, prepareStringForSQL, processQuoteChanges, splitPackages } from '../../assets/functions/system';
import { fetchUser } from '../../assets/functions/user';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { whichRep } from '../../assets/functions/calculations';
//import { PDFDownloadLink, Document, Page } from '@react-pdf/renderer'
import DocuSignPDF from '../quote/pdf/DocuSignPDF';

const Wrapper = styled.div`
  display: flex;
`;

const BodyWrapper = styled.div`
  width: 100%;
  padding: 1em 2em;
`;

const LoaderWrapper = styled.div`
  margin: 25vh auto 0 auto;
`;

export default class Quote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      viewing: "Quote Overview",
      changes: [],
    };

    this.updateData = this.updateData.bind(this)
    this.handleOnClick = this.handleOnClick.bind(this)
    this.saveQuote = this.saveQuote.bind(this)
    this.changePaidStatus = this.changePaidStatus.bind(this)
  }

  handleOnClick = (viewing) => {

    this.setState({ viewing: viewing });
  };

  handleSideBarAction = (event) => {
    this.setState({
      showSideBar: true,
      action: event,
    });
    if (this.state.showSideBar === true && event === this.state.action) {
      this.setState({
        showSideBar: false,
      });
    }
  };

  handleSideBarClose = () => {
    this.setState({
      showSideBar: false,
    });
  };


  fetchData = async (id) => {

    try {
      const fetchQuoteData = await getSQLData("quote/quoteData", {quoteID: id})
      const fetchQuoteLines = await getSQLData("quote/quoteLines", {quoteID: id})
      const fetchQuotePOs = await getSQLData("quote/quotePOs", {quoteID: id})
      const fetchQuotePOLines = await getSQLData("quote/quotePOLines", {quoteID: id})
      const fetchQuoteAttachments = await getSQLData("quote/quoteAttachments", {quoteID: id})
      const fetchQuoteFollowups = await getSQLData("quote/quoteFollowups", {quoteID: id})
      const fetchQuoteNotes = await getSQLData("quote/quoteNotes", {quoteID: id})
      const fetchQuoteLog = await getSQLData("quote/quoteLog", {quoteID: id})
      const fetchQuoteEmails = await getSQLData("quote/quoteEmails", {quoteID: id})
      const fetchQuotePackages = await getSQLData("quote/quotePackages", {quoteID: id})
      const user = await fetchUser()
      
      if(fetchQuoteData && fetchQuoteLines && fetchQuotePOs && fetchQuotePOLines && fetchQuoteAttachments && fetchQuoteFollowups && 
        fetchQuoteNotes && fetchQuoteLog && fetchQuoteEmails && fetchQuotePackages && user){

        let data = fetchQuoteData.data.data[0].recordset[0]    
        let attachments = fetchQuoteAttachments.data.data[0].recordset    
        let followups = fetchQuoteFollowups.data.data[0].recordset    
        let notes = fetchQuoteNotes.data.data[0].recordset    
        let log = fetchQuoteLog.data.data[0].recordset    
        let emails = fetchQuoteEmails.data.data[0].recordset    
        let packages = fetchQuotePackages.data.data[0].recordset    
        let quoteLines = fetchQuoteLines.data.data[0].recordset    
        let POs = fetchQuotePOs.data.data[0].recordset    
        let quotePOLines = fetchQuotePOLines.data.data[0].recordset    


        let query = {
          data: fetchQuoteData.query,
          attachments: fetchQuoteAttachments.query,
          followups: fetchQuoteFollowups.query,
          notes: fetchQuoteNotes.query,
          log: fetchQuoteLog.query,
          emails: fetchQuoteEmails.query,
          packages: fetchQuotePackages.query,
          quoteLines: fetchQuoteLines.query,
          POs: fetchQuotePOs.query,
          quotePOLines: fetchQuotePOLines.query,
        }       
        
        this.setState({
          data: data,
          followups: followups,
          attachments: attachments,
          notes: notes,
          log: log,
          emails: emails,
          packages: packages,
          quoteLines: quoteLines,
          POs: POs,
          quotePOLines: quotePOLines,
          query: query,
          user: user.user,
        }, () => this.processData())
      }
         
    } catch (error) {
      console.error(error);
    }
  }

  processData() {
    let logText = "'Quote viewed by " + whichRep(this.state.user) + " on cfrinc.app'"
    setSQLData("quote/insertQuoteLog", {logText: logText, quoteID: this.state.data.id, repID: this.state.user})

    let packages = this.state.packages
    let packageData = splitPackages(packages)

    let data = this.state.data
    let quoteLines = this.state.quoteLines

    let invoice = generateInvoiceCSV(data, quoteLines)
    let customer = generateCustomerCSV(data)

    this.setState({
      deliveryPackages: packageData.deliveryPackages,
      returnPackages: packageData.returnPackages,
      InvoiceData: invoice.Data,
      InvoiceHeaders: invoice.Headers,
      CustomerData: customer.Data,
      CustomerHeaders: customer.Headers,
      loaded: true,
    })
  }

  componentDidMount() {

    this.fetchData(this.props.history.location.pathname.slice(-7))
    
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState({ loaded: false });
      this.fetchData(this.props.history.location.pathname.slice(-7));
    }
  }

  updateData(getField, getData) {

    console.log(getField,getData)
    let data = this.state.data
    let changes = this.state.changes

    data[getField] = getData
    
    if(getField[0]=="n"){
      changes[getField] = getData
    }
    if(getField[0]=="s"){
      changes[getField] = prepareStringForSQL(getData)
    }    
    if(getField=="[paymentMethod]"){

    }
    

    this.setState({
      data: data,
      changes: changes,
    })
  }

  saveQuote = async () => {
    try {
      let changesText = processQuoteChanges(this.state.changes)
      let logText = formatQuoteChangeLog(changesText, this.state.data.id)
      const changes = await setSQLData("quote/updateQuote", {changes: changesText, quoteID: this.state.data.id})
      if(changes){
        const log = await setSQLData("quote/insertQuoteLog", {logText: logText, quoteID: this.state.data.id, repID: this.state.user})
      }
    } catch(error){
      console.error(error)
    }
  }

  changePaidStatus(){
    let data = this.state.data

    data.nPaid = !data.nPaid
    
    setSQLData("quote/setPaidStatus", {paidStatus: data.nPaid ? 1 : 0, quoteNum: data.id})
    let logText = "'Paid status updated to " + (data.nPaid ? "Paid" : "Unpaid") + " by " + whichRep(this.state.user) + " on cfrinc.app'"
    setSQLData("quote/insertQuoteLog", {logText: logText, quoteID: this.state.data.id, repID: this.state.user})

    this.setState({data: data})

  }

  render() {
    return (
      <Wrapper>
        {this.state.loaded || (
          <LoaderWrapper>
            <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} />
          </LoaderWrapper>
        )}
        {this.state.loaded && (
          <>
            <BodyWrapper
              style={{
                // Sets the width of the quote page when the sidebar is open
                width: this.state.showSideBar && '77.1%',
              }}
            >
              <button>
                <CSVLink data={this.state.InvoiceData} headers={this.state.InvoiceHeaders} filename={"invoice"}>Download Invoice</CSVLink>
              </button>
              <button>
                <CSVLink data={this.state.CustomerData} headers={this.state.CustomerHeaders} filename={"customer"}>Download Customer</CSVLink>
              </button>
              <div>
                {console.log(this.state.data)}
                {//<PDFDownloadLink document={<DocuSignPDF data={this.state.data} />} fileName={this.state.data.id + ".pdf"}>
                //  {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
                //</PDFDownloadLink>
                }
              </div>
              <QuoteHeader
                handleOnClick={this.handleOnClick}
                update={this.updateData}
                saveQuote={this.saveQuote}
                changePaidStatus={this.changePaidStatus}
                viewing={this.state.viewing}
                data={this.state.data}
              />
              
              {this.state.viewing == "Quote Overview" && (
                <QuoteOverview
                  onClick={this.handleSideBarAction}
                  data={this.state.data}
                  followupsCount={this.state.followups.length}
                  attachmentsCount={this.state.attachments.length}
                  socialMediaCount={
                    this.state.data.sSocialMedia
                      ? this.state.data.sSocialMedia.split(',').length
                      : 0
                  }
                  update={this.updateData}
                />
              )}

              {this.state.viewing == "Quote Lines" && (
                <QuoteLines
                  data={this.state.data}
                  quoteLines={this.state.quoteLines}
                  POs={this.state.POs}
                />
              )}
              {this.state.viewing == "PO Lines" && (
                <PoLines POs={this.state.POs} />
              )}
              {this.state.viewing == "Communications" && (
                <Communications
                  notes={this.state.notes}
                  log={this.state.log}
                  emails={this.state.emails}
                  data={this.state.data}
                />
              )}
              {this.state.viewing == "RFQs" && (
                <Rfqs 
                  quote={this.state.data.id} 
                  quoteLines={this.state.quoteLines}
                /> 
              )}
              {this.state.viewing == "Logistics" && (
                <Logistics
                  data={this.state.data}
                  deliveryPackages={this.state.deliveryPackages}
                  returnPackages={this.state.returnPackages}
                />
              )}
              {this.state.viewing == "Cost Sheet" && (
                <CostSheet
                  data={this.state.data}
                  quoteLines={this.state.quoteLines}
                  POs={this.state.POs}
                />
              )}
            </BodyWrapper>
            {this.state.showSideBar ? (
              <SideBar
                action={this.state.action}
                onClick={this.handleSideBarClose}
                attachments={this.state.attachments}
                followups={this.state.followups}
                socialMedia={this.state.data.sSocialMedia}
              />
            ) : null}
          </>
        )}
      </Wrapper>
    );
  }
}
