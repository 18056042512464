import moment from 'moment'
import React, { Component } from 'react'
import { CustomDateWrapper, Input } from '../../styled/ui'
import UnderwritingFlag from './UnderwritingFlag'
import { summarizeFlags } from '../../../assets/functions/underwriting'

export default class UnderwritingQuestions extends Component {
  render() {
    let flags = summarizeFlags(this.props.questions)
    return (
        <div>
        <table style={{width:'900px'}}>

            {this.props.viewing === 6 && (
                <>
                {flags.Top && flags.Top.map((question, index) => 
                    <tr>
                        <td>{question.question}</td>
                        <td>{question.answer}</td>
                        <td>{question.flag === question.answer ? question.priority : ""}</td>
                    </tr>
                )}
                {flags.High && flags.High.map((question, index) => 
                    <tr>
                        <td>{question.question}</td>
                        <td>{question.answer}</td>
                        <td>{question.flag === question.answer ? question.priority : ""}</td>
                    </tr>
                )}
                {flags.Neutral && flags.Neutral.map((question, index) => 
                    <tr>
                        <td>{question.question}</td>
                        <td>{question.answer}</td>
                        <td>{question.flag === question.answer ? question.priority : ""}</td>
                    </tr>
                )}
                {flags.Low && flags.Low.map((question, index) => 
                    <tr>
                        <td>{question.question}</td>
                        <td>{question.answer}</td>
                        <td>{question.flag === question.answer ? question.priority : ""}</td>
                    </tr>
                )}
                {flags.correction && flags.correction.map((question, index) => 
                    <tr>
                        <td>{question.question}</td>
                        <td>{question.answer}</td>
                        <td>{question.correction === question.answer ? "Correction" : ""}</td>
                    </tr>
                )}
                
                </>
            )}

            {this.props.questions.map((question, index) => (
                <>
                

                {this.props.viewing === question.page && question.show && (
                    
                
                <tr style={{backgroundColor: (index-question.offset)%2===0 ? "#dddddd" : "#aaaaaa"}}>
                    <td style={{width: '500px'}}>{index-question.offset+1}. {question.question}</td>
                    <td style={{width: '150px'}}>
                        {question.type === "Yes/No" && (
                            <select onChange={(event)=>this.props.changeAnswer(index, event.target.value)}>
                                <option value=""></option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                        )}
                        {question.type === "Residential/Commercial" && (
                            <select onChange={(event)=>this.props.changeAnswer(index, event.target.value)}>
                                <option value=""></option>
                                <option value="Residential">Residential</option>
                                <option value="Commercial">Commercial</option>
                            </select>
                        )}
                        {question.type === "Home/Apartment" && (
                            <select onChange={(event)=>this.props.changeAnswer(index, event.target.value)}>
                                <option value=""></option>
                                <option value="Home">Home</option>
                                <option value="Apartment">Apartment</option>
                            </select>
                        )}
                        {question.type === "int" && (
                            <input onChange={(event)=>this.props.changeAnswer(index, event.target.value)} value={question.answer}></input>
                        )}
                        {question.type === "date" && (
                            <>
                            <CustomDateWrapper><Input onChange={(event) => {this.props.changeAnswer(index, event.target.value)}} type="date" id="date" name="date" 
                              value={question.answer ? moment(question.answer).utc().format('YYYY-MM-DD') : null} /></CustomDateWrapper>
                            </>
                        )}
                        
                    </td>
                    <td style={{width: '250px'}}>
                        <UnderwritingFlag 
                            displayQuestion={false} 
                            question={question}
                            questions={this.props.questions}
                        />
                    </td>
                </tr>
                
                )}
                </>
            ))}
        </table>
    </div>
    )
  }
}
