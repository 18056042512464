import styled from "styled-components";

export const SearchWrapper = styled.section`
padding: 2em 0 1em 0;
text-align: center;
@media (max-width: 1100px) {
  padding-bottom: 5em;
}

`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  max-width: 1200px;
  width: 90vw;
  border-radius: 10px;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.1);
  margin: 3em auto;
`;

export const ReportWrapper = styled.div`
  width: 99%;
  margin: 0 auto;
  max-width: 1600px;

  table {
    width: 100%;
    position: relative;
    ${(props) => {
      if (props.amount > 10)
        return `
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;

    thead + tbody tr:last-child td:last-child,
    tfoot tr:last-child td:last-child {
      border-bottom-right-radius: 0px !important;
    }
    thead + tbody tr:last-child td:first-child,
    tfoot tr:last-child td:first-child {
      border-bottom-left-radius: 0px !important;
    }
    margin-bottom: 7em;
        `;
    }}
  }

  button {
    position: absolute;
    width: 100%;
    bottom: -27px;
     padding: 0.35em 1.2em;
     text-decoration: none;
     color: #fafafa;
     text-align: center;
     transition: all 0.2s;
    background-color: #4aabed;
    border: 1px solid #4aabed;
    cursor: pointer;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    &:hover {
      color: #222;
      background-color: #fafafa;
      border: 1px solid #d7d7d7;
    }
  }
`;

export const Wrapper = styled.div`
width: 99%;
margin: 4em auto;
max-width: 1600px;

table {
  width: 100%;
  position: relative;
  ${(props) => {
    if (props.amount > 10)
      return `
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;

  thead + tbody tr:last-child td:last-child,
  tfoot tr:last-child td:last-child {
    border-bottom-right-radius: 0px !important;
  }
  thead + tbody tr:last-child td:first-child,
  tfoot tr:last-child td:first-child {
    border-bottom-left-radius: 0px !important;
  }
  margin-bottom: 7em;
      `;
  }}
}

button {
   padding: 0.35em 1.2em;
   text-decoration: none;
   color: #fafafa;
   text-align: center;
   transition: all 0.2s;
  background-color: #4aabed;
  border: 1px solid #4aabed;
  cursor: pointer;
  border-radius: 15px;
  border-radius: 15px;
  margin: 5px;

  &:hover {
    color: #222;
    background-color: #fafafa;
    border: 1px solid #d7d7d7;
  }
}
`;

export const Input = styled.input`
width: 140px;
 padding: 0.2em 0em 0.2em 0.2em;
 margin: 0 0 0.5em 0;
border: 1px solid #ccc;
border-radius: 4px;
box-sizing: border-box;

@media (max-width: 900px) {
  width: auto;
  margin: auto;
}
`;

export const CustomDateWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

@media (max-width: 900px) {
  margin: 1em 0;
}
`;

export const SubTitle = styled.h2`
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1em;
`;

export const SubmitText = styled.p`
text-align: center;
cursor: pointer;
`

export const GreyTextDiv = styled.div`
  color: #888888;
`

export const Title = styled.h1`
padding: 2em 0 1em 0;
text-align: center;
`;

export const Button = styled.button`
display: inline-block;
 padding: 0.8em 2.5em;
 margin: 1em 0.3em 1em 0;
 text-decoration: none;
 color: #fafafa;
 text-align: center;
 transition: all 0.2s;
background-color: rgba(54, 162, 235, 0.9);
border: 1px solid #fafafa;
cursor: pointer;
border-radius: 50px;

&:hover {
  border: 1px solid #d7d7d7;
}
`;

export const Time = styled.h1`
  padding: 0.5em 0 2em 0em;
  font-size: 1rem;

  i {
    margin: 0 1em;
    color: #c3d1da;
    cursor: pointer;
    transition: 0.1s;

    &:hover {
      color: #36a2eb;
    }
  }
`;

export const CustomDateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1em 0 0 0;

  @media (max-width: 900px) {
    flex-direction: column;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 900px) {
      margin: 1em 0;
    }

    label {
      margin-right: 0.2em;
      font-weight: bold;
    }

    input {
      width: 200px;
       padding: 0.2em 1.2em;
       margin: 0 0.3em 0.3em 0;
      border: 1px solid #ccc;
      box-sizing: border-box;
      margin-top: 6px;
      margin-bottom: 16px;

      @media (max-width: 900px) {
        width: auto;
        margin: auto;
      }
    }
  }
`;

export const DashboardWrapper = styled.section`
  text-align: center;
  padding: 0 0.4em 1em 0.4em;

  table {
    position: relative;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

    thead + tbody tr:last-child td:last-child,
    tfoot tr:last-child td:last-child {
      border-bottom-right-radius: 0px;
    }
    thead + tbody tr:last-child td:first-child,
    tfoot tr:last-child td:first-child {
      border-bottom-left-radius: 0px;
    }
  }
`;

export const DashboardControlsDiv = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin: 3em auto 2em auto;
  padding: 2em 4em;
  width: fit-content;
  height: fit-content;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.1);
  text-align: center;
  border-radius: 15px;

  button {
    display: inline-block;
     padding: 0.5em 1.2em;
     margin: 0 0.3em 0.3em 0;
     text-decoration: none;
     color: #333;
     text-align: center;
     transition: all 0.2s;
    background-color: #f2f5f7;
    border: 1px solid rgba(255, 255, 255, 0);
    cursor: pointer;

    &:hover {
      background-color: #36a2eb;
      border: 1px solid #36a2eb;
      color: #fff;
    }
  }

  select {
    display: inline-block;
    color: #333;
     text-align: center;
     padding: 0.3em 1.2em;
     margin: 0 0.3em 0.3em 0;
    background-color: #f2f5f7;
    border: 1px solid #f2f5f7;
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
      background-color: #36a2eb;
      border: 1px solid #36a2eb;
      color: #fff;
    }
  }
`;

export const PageWrapper = styled.div`
  display: block;
  position: fixed;
  z-index: 50;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: #222;
  opacity: 0.5;
`;

export const Modal = styled.div`
  display: block;
  position: fixed;
  z-index: 55;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 90%;
  overflow: hidden;
  background-color: #fff;
  opacity: 1;
  border-radius: 10px;

`;

export const ModalContent = styled.div`
display: flex;
flex-direction: column;
align-content: center;
justify-content: center;
text-align: left;
background-color: #fefefe;
margin: auto;
padding: 0.5em;
width: 90%;
overflow: hidden;

h2 {
  text-align: center;
}

p {
  text-align: left;
  font-size: 1rem;
  margin-top: 1em;
}

span {
  font-weight: bold;
}

input {
  width: 100%;
  max-width: 400px;
  padding: 0.7em;
  margin: 0.5em auto 0 auto;
  border: 1px solid #ccc;
  border-radius: 4px;
}

select {
  width: 100%;
  max-width: 400px;
  padding: 0.7em;
  margin: 0.5em auto 0 auto;
  border: 1px solid #ccc;
  border-radius: 4px;
}
`;

export const Select = styled.select`
  width: 100%;
  height: 60px;
  white-space:pre-wrap;
  margin: 0.5em auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.4em;
`

export const Option = styled.option`
max-width: 100%;
overflow: hidden;
word-wrap: normal !important;
white-space: normal;
`