import React, { Component } from 'react'
import { getSQLData, setSQLData } from '../../../../assets/functions/fetch';
import moment from 'moment';

export default class ImportCallLog extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loaded: false,
          importFile: {},
        };
      }

      componentDidMount() {
        this.fetchData()
      }

      fetchData = async() => {
        try {
            const fetch = await getSQLData("call_log/getMostRecentCallLog", {})

            if(fetch){
                let lastEntry = fetch.data.data[0].recordset[0].lastEntry

                this.setState({lastEntry:lastEntry})
            }


        } catch (error) {

        }
      }




    createMetaDataTemplate(){return {
        total: {
            count: 0,
            length: 0,
        },
    Inbound: {
        Answered: {
            count: 0,
            length: 0,
            },
        Connected: {
            count: 0,
            length: 0,
            },
        Missed: {
            count: 0,
            length: 0,
            },
        'Not Connected': {
            count: 0,
            length: 0,
            },
        'VM/Missed': {
            count: 0,
            length: 0,
            },
        'IVR/Answered': {
            count: 0,
            length: 0,
            },
        total: {
            count: 0,
            length: 0,
            },
    },
    Outbound: {
        Answered: {
            count: 0,
            length: 0,
            },
        Connected: {
            count: 0,
            length: 0,
            },
        Missed: {
            count: 0,
            length: 0,
            },
        'Not Connected': {
            count: 0,
            length: 0,
            },
        'VM/Missed': {
            count: 0,
            length: 0,
            },
        'IVR/Answered': {
            count: 0,
            length: 0,
            },
        total: {
            count: 0,
            length: 0,
            },
    },
    'Ring out': {
        Answered: {
            count: 0,
            length: 0,
            },
        Connected: {
            count: 0,
            length: 0,
            },
        Missed: {
            count: 0,
            length: 0,
            },
        'Not Connected': {
            count: 0,
            length: 0,
            },
        'VM/Missed': {
            count: 0,
            length: 0,
            },
        'IVR/Answered': {
            count: 0,
            length: 0,
            },
        total: {
            count: 0,
            length: 0,
            },
    },
    Internal: {
        Answered: {
            count: 0,
            length: 0,
            },
        Connected: {
            count: 0,
            length: 0,
            },
        Missed: {
            count: 0,
            length: 0,
            },
        'Not Connected': {
            count: 0,
            length: 0,
            },
        'VM/Missed': {
            count: 0,
            length: 0,
            },
        'IVR/Answered': {
            count: 0,
            length: 0,
            },
        total: {
            count: 0,
            length: 0,
            },
    },
    }}

      handleOnChange = (e) => {
        this.setState({importFile: e.target.files[0]})
      }

      handleOnSubmit = (e) => {
        const fileReader = new FileReader();
        let importFile = this.state.importFile
        console.log(importFile)

        fileReader.onload = function (file) {
            const text = file.target.result
            const rows = text.split('\n')
            const headers = rows[0].split(',')
            const data = []

            for(let i=1; i<rows.length; i++){
                let call = {}
                let row = rows[i].split(',')
                for(let j=0; j<headers.length; j++){
                    call[headers[j]] = row[j]
                }
                data.push(call)
            }

            this.setState({
                data: data,
            }, ()=>this.processCallLog())
        }.bind(this)

        fileReader.readAsText(importFile)
      }

      processCallLog(){
        let data = this.state.data
        let lastEntry = this.state.lastEntry


        let log = []
        let metaData = this.createMetaDataTemplate()
        metaData.numbers = {}

        let phoneRegEx = /\([0-9]+\)\s[0-9]+-[0-9]+/i

        for(let i=0; i<data.length; i++){
            let fromPhone = data[i]["From Name"] ? data[i]["From Name"].match(phoneRegEx) : null
            let fromName = fromPhone ? data[i]["From Name"].substring(0, fromPhone.index-1) : null
            fromPhone = fromPhone ? fromPhone[0].replace("(","").replace(")","").replace(" ","").replace("-","") : null

            let toPhone = data[i]["To Name"] ? data[i]["To Name"].match(phoneRegEx) : null
            let toName = toPhone ? data[i]["To Name"].substring(0, toPhone.index-1) : null
            toPhone = toPhone ? toPhone[0].replace("(","").replace(")","").replace(" ","").replace("-","") : null

            data[i].fromPhone = fromPhone
            data[i].fromName = fromName
            data[i].toPhone = toPhone
            data[i].toName = toName

            let startTime = moment(data[i]["Call Start Time"])
            let lastEntryMoment = moment(lastEntry).add('hour', moment().isDST() ? 4 : 5)

            if(startTime.diff(lastEntryMoment) > 0){
                log.push(data[i])
            }
            
            let result = data[i].Result
            let direction = data[i]["Call Direction"]
            let length = data[i]["Call Length"]

            if(result && direction && length){
                metaData.total.count++
                metaData.total.length += parseInt(length)
                metaData[direction][result].count++
                metaData[direction].total.count++
                metaData[direction][result].length += parseInt(length)
                metaData[direction].total.length += parseInt(length)

                if(!metaData.numbers[fromPhone]){
                    metaData.numbers[fromPhone] = this.createMetaDataTemplate()
                }
                if(!metaData.numbers[toPhone]){
                    metaData.numbers[toPhone] = this.createMetaDataTemplate()
                }

                metaData.numbers[fromPhone].total.count++
                metaData.numbers[fromPhone].total.length += parseInt(length)
                metaData.numbers[fromPhone][direction][result].count++
                metaData.numbers[fromPhone][direction].total.count++
                metaData.numbers[fromPhone][direction][result].length += parseInt(length)
                metaData.numbers[fromPhone][direction].total.length += parseInt(length)

                metaData.numbers[toPhone].total.count++
                metaData.numbers[toPhone].total.length += parseInt(length)
                metaData.numbers[toPhone][direction][result].count++
                metaData.numbers[toPhone][direction].total.count++
                metaData.numbers[toPhone][direction][result].length += parseInt(length)
                metaData.numbers[toPhone][direction].total.length += parseInt(length)
            }
            

        }

        this.setState({
            data: data,
            metaData: metaData,
            log: log,
            loaded: true,
        })
      }

      saveToSQL(i) {

        let data = this.state.data

        if (i < data.length) {
            let logEntry = {
                sFromName: data[i].fromName,
                nFrom: parseInt(data[i].fromPhone),
                sToName: data[i].toName,
                nTo: parseInt(data[i].toPhone),
                sResult: data[i].Result,
                nCallLength: parseInt(data[i]["Call Length"]),
                dtStartTime: data[i]["Call Start Time"],
                sDirection: data[i]["Call Direction"],
            }

            console.log(logEntry)
            setSQLData("call_log/insertCallLogEntry", logEntry);
            
            i++;
            setTimeout(() => {
                this.saveToSQL(i)
            }, 100);
        }

      } 

  render() {
    return (
        <>
        <form>
            <input type={"file"} id={"csvFileInput"} accept={".csv"} onChange={this.handleOnChange} />
            <button onClick={(e) => {this.handleOnSubmit(e)}}>Import CSV</button>
        </form>
        <div>
            <button onClick={()=>this.saveToSQL(0)}>Save to SQL</button>
        </div>
            {this.state.loaded && (
                <table>
                    <thead>
                        <tr>
                            <th>From Name</th>
                            <th>From #</th>
                            <th>To Name</th>
                            <th>To #</th>
                            <th>Result</th>
                            <th>Call Length</th>
                            <th>Start Time</th>
                            <th>Direction</th>
                            <th></th>
                        </tr>
                    </thead>
                    {this.state.log.map((call, index)=>(
                        <tr>
                            <td>{call.fromName}</td>
                            <td>{call.fromPhone}</td>
                            <td>{call.toName}</td>
                            <td>{call.toPhone}</td>
                            <td>{call.Result}</td>
                            <td>{call["Call Length"]}</td>
                            <td>{call["Call Start Time"]}</td>
                            <td>{call["Call Direction"]}</td>
                            <td>{call.newEntry}</td>
                        </tr>
                    ))}
                </table>
            )}
        </>
    )
  }
}
