import React, { Component } from 'react';
import { setSQLData } from '../../assets/functions/fetch';
import { activeReps } from '../../assets/functions/calculations';

class CreateNote extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            text: "",
            rep: 0,
        };
      }

      componentDidMount(){
        let text = this.props.text
        let attention = this.props.attention

        this.setState({
            text: text,
            attention: attention,
        })
      }

      changeNote(text){
        this.setState({
            text: text,
        })
      }
      changeRep(attention){
    

        this.setState({
            attention: attention,
        })
      }
      createNote = async() => {
        try {
            const note = await setSQLData("quote/createNote", {
                nQuote: this.props.nQuote,
                nRep: this.props.nRep,
                nAttention: this.state.attention,
                sNote: this.state.text,
            })
            if(note){
                this.props.handleCreateNote()
            }
        } catch(error){
            console.error(error)
        }
      }

    render() {
        let text = this.state.text
        let attention = this.state.attention
        let reps = this.props.reps
        return (
            <div style={{
                position: 'fixed',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 1,
            }}>
            <div style={{
                position: 'fixed',
                left: '30%',
                top: '30%',
                width: '40%',
                height: '40%',
                backgroundColor: 'white',
                padding: '10px',
                zIndex: 2,
            }}>
                <div><textarea 
                    value={text}
                    onChange={(e)=>this.changeNote(e.target.value)}
                    style= {{
                        width: '500px',
                        height: '200px',
                    }}
                /></div>
                <select value={attention} onChange={(e)=>this.changeRep(e.target.value)}>
                    <option value={0}>None</option>
                    {reps.map((rep,index)=>(
                        <option value={rep.id}>{rep.sDisplay}</option>
                    ))}
                </select>
                <button>Add Note</button>
                <button onClick={()=>this.props.handleCreateNote()}>Don't Add Note</button>
            </div>
            </div>
        );
    }
}

export default CreateNote;