import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { nQuoteTotalNoDollar, whichRep } from '../../../assets/functions/calculations';
import { percentTwoDecimal } from '../../../assets/functions/display';



export default class ClosedWeekTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    
    return (
      <table className="dashboard">
          <thead>
            <tr>
              <th>Quote</th>
              <th>Company</th>
              <th>Rep</th>
              <th>First Closed</th>
              <th>Quote Total</th>
              <th>PO Total</th>
              <th>Margin</th>
              <th>New Customer</th>
              <th>Type</th>
              <th>Campaign</th>
              <th>Lead Source</th>
              <th>Referring Site</th>
            </tr>
          </thead>
          <tbody>
            {this.props.quotes.map((quote, index)=>(
              <>
              <tr key={index}>
                <td><Link target="_blank" to={`/quote/${quote.id}`}>{quote.id}</Link></td>
                <td>{quote.sCompany}</td>
                <td>{whichRep(quote.nRep)}</td>
                <td>{moment(quote.dtFirstClosed).utc().format('MM/DD/YYYY')}</td>
                <td>{nQuoteTotalNoDollar(quote.TotalPrice)}</td>
                <td>{nQuoteTotalNoDollar(quote.POTotal)}</td>
                <td>{nQuoteTotalNoDollar(quote.margin)}<br />{percentTwoDecimal((quote.margin)/quote.TotalPrice)}</td>
                <td>{quote.NewCustomer ? "Yes" : ""}</td>
                <td>{quote.sType}</td>
                <td>{quote.sCampaign}</td>
                <td>{quote.sLeadSource}</td>
                <td>{quote.sReferringSite}</td>
              </tr>
              </>
            ))}
          </tbody>
        </table>
    );
  }
}
