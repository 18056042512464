import React, { Component } from 'react';

class OpenEmail extends Component {
    render() {
        const emailData = {
            to: this.props.to,
            from: this.props.from,
            cc: this.props.cc,
            bcc: this.props.bcc,
            subject: this.props.subject,
            body: this.props.body,
            quote: this.props.quote,
        }
        const queryString = new URLSearchParams(emailData).toString()
        const url = `http://localhost:5000/#/send-email?${queryString}`
        return (
            <div onClick={()=>{
                window.open(url, "_blank", "resizable=no,scrollbars=no,width=1200,height=800")
              }}>
                {this.props.text}
            </div>
        );
    }
}

export default OpenEmail;