import React, { Component } from "react";
import Axios from "axios";
import styled from "styled-components";
import Loader from "react-loader-spinner";

const Select = styled.select`
  width: 100%;
  height: 60px;
  white-space:pre-wrap;
  margin: 0.5em auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.4em;
`
const Option = styled.option`
max-width: 100%;
overflow: hidden;
word-wrap: normal !important;
white-space: normal;
`

const LoaderWrapper = styled.div``;

const PageWrapper = styled.div`
  display: block;
  position: fixed;
  z-index: 50;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: #222;
  opacity: 0.5;
`;

const Modal = styled.div`
  display: block;
  position: fixed;
  z-index: 55;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  height: fit-content;
  overflow: auto;
  background-color: #fff;
  opacity: 1;
  border-radius: 10px;

  @media (max-width: 900px) {
    width: 90%;
    height: auto;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: left;
  background-color: #fefefe;
  margin: auto;
  padding: 3em;
  width: 100%;
  overflow: hidden;

  h2 {
    text-align: center;
  }

  p {
    text-align: left;
    font-size: 1rem;
    margin-top: 1em;
  }

  span {
    font-weight: bold;
  }

  input {
    width: 100%;
    max-width: 400px;
    padding: 0.7em;
    margin: 0.5em auto 0 auto;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  text-align: center;
  margin: 3em 0 1em 0;
  overflow: hidden;

  button {
    width: 100%;
    max-width: 200px;
     text-decoration: none;
     color: #222;
     text-align: center;
     transition: all 0.2s;
    padding: 0.5em 1em;
    margin: 0 0.5em;
    border: 3px #e8edf1 solid;
    border-radius: 4px;
    font-weight: 500;
    align-self: center;
    background-color: #e8edf1;
    cursor: pointer;

    &:hover {
      background-color: #4aabed;
      color: #fff;
      border: 3px #4aabed solid;
    }
  }
`;

const InputContainer = styled.div`
  width: 80%;
  margin: auto;
`;

const Warning = styled.h2`
  font-weight: bold;
  color: red;
  text-align: center !important;
`;

export default class AddReview extends Component {
  state = {
    logistics: false,
    equipment: false,
    logisticsResponse: true,
    logisticsImpact: "No Impact",
    equipmentResponse: true,
    equipmentImpact: "No Impact",
    comment: false,
    commentText: "",
    commentQuote: 0,
    commentRating: 0,
    submitted: false,
  };

  handleConfirmation = () => {
    Axios({
      method: "post",
      url: "/customers/add-new-contact",
      data: {
        nCompany: this.props.nCompany,
        sFirstName: this.state.firstName,
        sLastName: this.state.lastName,
        sPhone: this.state.phone,
        sEmail: this.state.email,
      },
    });
    this.props.handleShowAddContactModal();
  };

  handleCancel = () => {
    this.setState({
      showConfirmation: false,
    });
  };

  handleSubmit = () => {
    if (!this.state.submitted){
      this.setState({submitted: true})
      this.leaveReview()
      if(this.state.comment){
        this.leaveComment()
      }
    }
  };

  exit = () => {
    this.props.closeWindow()
  }

  leaveReview = async () => {
    try {
      const response = await Axios.post(
        "/leave-review",
        {
          quote: this.props.reviewQuote,
          supplier: this.props.reviewSupplier,
          rep: this.props.user,
          logistics: this.state.logistics,
          logisticsImpact: this.state.logisticsImpact,
          logisticsResponse: (this.state.logistics ? this.state.logisticsResponse : "No Impact"),
          equipment: this.state.equipment,
          equipmentImpact: this.state.equipmentImpact,
          equipmentResponse: (this.state.equipment ? this.state.equipmentResponse : "No Impact")
        },
      )
      this.props.closeWindow()
      
    } catch (error) {
      console.error(error)
    }

  };

  leaveComment = async () => {
    try {
      const response = await Axios.post(
        "/leave-comment",
        {
          comment: this.state.commentText,
          commentRating: this.state.commentRating,
          commentQuote: this.state.commentQuote,
          supplier: this.props.reviewSupplier,
          user: this.props.user
        },
      )

      
    } catch (error) {
      console.error(error)
    }
  };

  handleChange = (event) => {
    if(event.target.type === "checkbox"){
      this.setState({
        [event.target.name]: event.target.checked,
      });
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
    
  };

  render() {


    let modal = (
      <>
        <PageWrapper></PageWrapper>
        <Modal>

            <ModalContent>
            <div><button style={{width: 30, textAlign: 'center', float: 'right'}} onClick={() => this.exit()}>X</button></div>
              <div>
                Review for {this.props.reviewName}, quote {this.props.reviewQuote} for {this.props.reviewCustomer}
              </div>
            <InputContainer>
                <p>Logistics Issues?</p>
                <input
                  name="logistics"
                  onChange={this.handleChange}
                  type="checkbox"
                  value={this.state.logistics}
                  checked={this.state.logistics}
                />
              </InputContainer>
              {this.state.logistics && (
                <>
                  <InputContainer>
                    <p>Vendor Response Acceptable?</p>
                    <input
                      name="logisticsResponse"
                      onChange={this.handleChange}
                      type="checkbox"
                      value={this.state.logisticsResponse}
                      checked={this.state.logisticsResponse}
                    />
                  </InputContainer>
                  <InputContainer>
                    <p>Incident Impact</p>
                    <Select name="logisticsImpact"
                      onChange={this.handleChange}
                      value={this.state.logisticsImpact}> 
                      <Option value="No Impact">No Impact</Option>
                      <Option value="Minor Impact">Minor Impact</Option>
                      <Option value="Major Impact">Major Impact</Option>
                      <Option value="Rental Refunded">Rental Refunded</Option>
                    </Select>
                  </InputContainer>
                </>
              )}
              
              <InputContainer>
                <p>Equipment Issues?</p>
                <input
                  name="equipment"
                  onChange={this.handleChange}
                  type="checkbox"
                  value={this.state.equipment}
                  checked={this.state.equipment}
                />
              </InputContainer>
              {this.state.equipment && (
                <>
                  <InputContainer>
                    <p>Vendor Response Acceptable?</p>
                    <input
                      name="equipmentResponse"
                      onChange={this.handleChange}
                      type="checkbox"
                      value={this.state.equipmentResponse}
                      checked={this.state.equipmentResponse}
                    />
                  </InputContainer>
                  <InputContainer>
                    <p>Incident Impact</p>
                    <Select name="equipmentImpact"
                      onChange={this.handleChange}
                      value={this.state.equipmentImpact}>
                      <Option value="No Impact">No Impact</Option>
                      <Option value="Minor Impact">Minor Impact</Option>
                      <Option value="Major Impact">Major Impact</Option>
                      <Option value="Rental Refunded">Rental Refunded</Option>
                    </Select>
                  </InputContainer>
                </>
              )}
                  <InputContainer>
                    <p>Do you want to leave a comment?</p>
                    <input
                      name="comment"
                      onChange={this.handleChange}
                      type="checkbox"
                      value={this.state.comment}
                      checked={this.state.comment}
                    />
                  </InputContainer>
              {this.state.comment && (
                <>
                  <InputContainer>
                    <p>Comment</p>
                    <textarea name="commentText" onChange={this.handleChange} value={this.state.commentText}></textarea>
                  </InputContainer>
                  <InputContainer>
                    <p>Rating</p>
                    <Select name="commentRating"
                      onChange={this.handleChange}
                      value={this.state.commentRating}>
                      <Option value={0}>No Rating</Option>
                      <Option value={5}>5</Option>
                      <Option value={4}>4</Option>
                      <Option value={3}>3</Option>
                      <Option value={2}>2</Option>
                      <Option value={1}>1</Option>
                    </Select>
                  </InputContainer>
                </>
              )}
                <br />
                  <button onClick={() => this.handleSubmit()}>Submit</button>
                  
                
              
            </ModalContent>

        </Modal>
      </>
    );

    return <>{modal}</>;
  }
}
