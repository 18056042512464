import React, { Component } from 'react';
import Axios from 'axios';
import styled from 'styled-components';
import { Pie } from 'react-chartjs-2';
import Loader from 'react-loader-spinner';

const Wrapper = styled.section`
  padding: 5em 0em;
  text-align: center;
  width: 70%;
  margin: auto;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const SubTitle = styled.h2`
  padding: 1em 0;
  text-align: center;
`;

export default class BrandSalesPieChart extends Component {
  state = {
    loaded: false,
    racSales: 0,
    camSales: 0,
    csnSales: 0,
    racMargin: 0,
    camMargin: 0,
    csnMargin: 0,
    showSalesChart: true,
  };

  fetchData = async (startDate, endDate) => {
    try {
      const response = await Axios.get('/brand-sales-charts', {
        params: {
          startDate: startDate,
          endDate: endDate,
        },
        headers: { 'Content-Type': 'application/json' },
      });
      // Sets the state using the response data
      this.setState({
        racSales: response.data.result[0].recordset[0].racSales,
        camSales: response.data.result[0].recordset[0].camSales,
        csnSales: response.data.result[0].recordset[0].csnSales,
        racMargin:
          response.data.result[0].recordset[0].nRacBeforeTaxSales -
          response.data.result[0].recordset[0].nRacTotalCost,
        camMargin:
          response.data.result[0].recordset[0].nCamBeforeTaxSales -
          response.data.result[0].recordset[0].nCamTotalCost,
        csnMargin:
          response.data.result[0].recordset[0].nCsnBeforeTaxSales -
          response.data.result[0].recordset[0].nCsnTotalCost,
        loaded: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    this.fetchData(this.props.startDate, this.props.endDate);
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState({
        loaded: false,
      });
      this.fetchData(this.props.startDate, this.props.endDate);
    }
  }

  handleClick() {
    this.setState({ showSalesChart: !this.state.showSalesChart });
  }

  render() {
    const salesData = {
      labels: ['RAC', 'CAM', 'CSN'],
      datasets: [
        {
          label: 'Sales $',
          data: [
            this.state.racSales,
            this.state.camSales,
            this.state.csnSales,
            ,
          ],
          backgroundColor: [
            'rgba(54, 162, 235, 0.8)',
            'rgba(251,98,98, 0.8)',
            'rgba(255, 206, 86, 0.8)',
          ],
        },
      ],
    };

    const marginData = {
      labels: ['RAC', 'CAM', 'CSN'],
      datasets: [
        {
          label: 'Margin $',
          data: [
            this.state.racMargin,
            this.state.camMargin,
            this.state.csnMargin,
            ,
          ],
          backgroundColor: [
            'rgba(54, 162, 235, 0.8)',
            'rgba(251,98,98, 0.8)',
            'rgba(255, 206, 86, 0.8)',
          ],
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        mode: 'label',
        label: 'mylabel',
        callbacks: {
          label: function (tooltipItem, data) {
            var value = Number(
              data.datasets[0].data[tooltipItem.index]
            ).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            return ' $' + value;
          },
        },
      },
    };

    return (
      <Wrapper>
        {this.state.loaded || (
          <Loader
            style={{
              textAlign: 'center',
            }}
            type="ThreeDots"
            color="#00BFFF"
            height={80}
            width={80}
          />
        )}
        {this.state.loaded && (
          <>
            {this.state.showSalesChart ? (
              <>
                <SubTitle>
                  Brand Sales
                  <span
                    onClick={() => this.handleClick()}
                    style={{ color: 'lightgray', cursor: 'pointer' }}
                  >
                    {' '}
                    - Brand Margins
                  </span>
                </SubTitle>
                <Pie
                  data={salesData}
                  options={options}
                  width={100}
                  height={50}
                />
              </>
            ) : (
              <>
                <SubTitle>
                  <span
                    onClick={() => this.handleClick()}
                    style={{ color: 'lightgray', cursor: 'pointer' }}
                  >
                    Brand Sales -
                  </span>{' '}
                  Brand Margins
                </SubTitle>
                <Pie
                  data={marginData}
                  options={options}
                  width={100}
                  height={50}
                />
              </>
            )}
          </>
        )}
      </Wrapper>
    );
  }
}
