import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { cGreenText, nQuoteTotalNoDollar, whichRep } from '../../../../assets/functions/calculations';
import moment from 'moment';
import { CustomDateWrapper, Input, SubmitText, GreyTextDiv, Title } from '../../../styled/ui';



export default class NeedHardwareBJK extends Component {
    constructor(props) {
      super(props);
      this.state = {
      };
    }
  
    render() {
      
      return (
        <>
        <Title>Need Hardware</Title>
        <div style={{paddingBottom: '10px'}}>After hardware has been setup, received, and documented, set the Hardware Setup date to remove from this report.  Mark as Hardware Only if no labor is needed.</div>

            {this.props.data.length > 0 && (
                <div>
                  {this.props.data.map((sql, index) => (
                   <table key={sql.id} style={{border: '1px solid black', borderRadius: '5px 5px 5px 5px'}}>
                      <tr>
                        <td style={{verticalAlign: 'top', textAlign:'left', width: '300px'}}>
                          <div style={{fontWeight: 'bold', fontSize: 16}}><Link target="_blank" to={`/quote/${sql.id}`}>Quote {sql.id} <br /> {sql.sQuoteTitle}</Link></div>
                          <div>{sql.sDCompany}</div>
                          <div>{sql.sDCity}, {sql.sDState} {sql.sDZip}</div>
                          <div>{sql.sDName}</div>
                          <div>{sql.sDPhone}</div>
                        </td>
                        <td style={{verticalAlign: 'top', textAlign:'left', width: '300px'}}>
                        <div><b>Price</b>: {nQuoteTotalNoDollar(sql.nQuoteTotal)}</div>
                        <div><b>Margin</b>: {nQuoteTotalNoDollar(sql.nMargin)}</div>
                        <div onClick={()=>this.props.handleShowPO(sql.index)}><b>CSN Labor</b>: <a style={{color: 'blue'}}>{nQuoteTotalNoDollar(sql.nLaborTotals)}</a></div>
                        <div><b>First Closed</b>: {moment(sql.dtFirstClosed).utc().format('MM/DD/YYYY')}</div>
                        <div><b>Rep</b>: {sql.nRep ? whichRep(sql.nRep) : null}</div>      
                        <input type="checkbox" checked={sql.bLaborOnly} onClick={()=>this.props.setLaborOnly(sql)} /> Hardware Only     
                      </td>         
                      
                      <td style={{verticalAlign: 'top', textAlign:'left', width: '300px'}}>
                        <div>
                          <b>Hardware Expected</b>:
                          <CustomDateWrapper><Input onChange={(e) => {this.props.handleDateChange(e, sql.index, 'dtHardwareExpected')}} type="date" id="date" name="date"
                            value={moment(sql.dtHardwareExpected).utc().format('YYYY-MM-DD')} />
                          </CustomDateWrapper>
                          <SubmitText onClick={() => this.props.handleDateSubmit(sql.dtHardwareExpected,sql.id,"dtHardwareExpected")}>Submit</SubmitText>
                          <br />
                          <b>Target Start</b>: 
                          <CustomDateWrapper><Input onChange={(e) => {this.props.handleDateChange(e, sql.index, 'dtTargetStart')}} type="date" id="date" name="date" 
                            value={moment(sql.dtTargetStart).utc().format('YYYY-MM-DD')} /></CustomDateWrapper>
                          <SubmitText onClick={() => this.props.handleDateSubmit(sql.dtTargetStart,sql.id,"dtTargetStart")}>Submit</SubmitText>
                        </div>
                      </td>
                      <td style={{verticalAlign: 'top', textAlign:'left', width: '300px'}}>
                        <div>
                          <b>Hardware Setup</b>:
                          <CustomDateWrapper><Input onChange={(e) => {this.props.handleDateChange(e, sql.index, 'dtHardwareSetup')}} type="date" id="date" name="date"
                            value={moment(sql.dtHardwareSetup).utc().format('YYYY-MM-DD')} />
                          </CustomDateWrapper>
                          <SubmitText onClick={() => this.props.handleDateSubmit(sql.dtHardwareSetup,sql.id,"dtHardwareSetup")}>Submit</SubmitText>
                          <br />
                          <b>Target Complete</b>: 
                          <CustomDateWrapper><Input onChange={(e) => {this.props.handleDateChange(e, sql.index, 'dtTargetComplete')}} type="date" id="target" name="target"
                            value={moment(sql.dtTargetComplete).utc().format('YYYY-MM-DD')} />
                          </CustomDateWrapper>
                          <SubmitText onClick={() => this.props.handleDateSubmit(sql.dtTargetComplete,sql.id,"dtTargetComplete")}>Submit</SubmitText>
                        </div>
                      </td>
                      <td style={{verticalAlign: 'top', textAlign:'left', width: '300px'}}>
                        <div>
                        <b>Ship Date</b>:
                        <CustomDateWrapper><Input onChange={(e) => {this.props.handleDateChange(e, sql.index, 'dtShipDate')}} type="date" id="date" name="date"
                          value={moment(sql.dtShipDate).utc().format('YYYY-MM-DD')} />
                        </CustomDateWrapper>
                        <SubmitText onClick={() => this.props.handleDateSubmit(sql.dtShipDate,sql.id,"dtShipDate")}>Submit</SubmitText>
                        <br />
                        <b>Complete Date</b>: 
                        <CustomDateWrapper><Input onChange={(e) => {this.props.handleDateChange(e, sql.index, 'dtComplete')}} type="date" id="target" name="target"
                          value={moment(sql.dtComplete).utc().format('YYYY-MM-DD')} />
                        </CustomDateWrapper>
                        <SubmitText onClick={() => this.props.handleDateSubmit(sql.dtComplete,sql.id,"dtComplete")}>Submit</SubmitText>
                        </div>
                      </td>
                      </tr>
                      <tr>
                      <td  colSpan={2} style={{verticalAlign: 'top', textAlign:'left', width: '300px'}}>
                          <div><b>Notes</b></div>
                          {sql.notes.map((note, index)=>(
                            <>
                            {index < 10 && (
                              <div style={{margin: '10px'}}>
                                <div><b>{whichRep(note.nRep)} {moment(note.dtTimeStamp).utc().format('hh:mm A MM/DD/YYYY')}</b></div>
                                <div>{note.sNote}</div>
                              </div>
                            )}
                            
                            </>
                          ))}
                      </td>
                                  
                      <td  colSpan={3} style={{verticalAlign: 'top', textAlign:'left', width: '300px'}}>
                        <div><b>Meeting Notes</b></div>
                        <textarea style={{height: '80px', width: '100%'}} onInput={(event) => this.props.handleUpdateTag(event.target.value, index)} value={sql.sQuoteTags}></textarea>
                        {sql.changed && (<div onClick={() => this.props.handleSaveTag(sql.sQuoteTags, sql.id)}>Save Tag</div>)}
                      </td>
                      <td  colSpan={2} style={{verticalAlign: 'top', textAlign:'left', width: '20%'}}>
                          <div><b>Inbound Tracking</b></div>
                          {sql.inbound.map((tracking, index2) => (
                              <div style={{color: tracking.sStatusCode === "DE" ? cGreenText : ''}}>
                                <input type="checkbox" checked={tracking.bCompleted} onClick={()=>this.props.handleConfirmShipment(tracking)}></input>
                                <a href={"https://www.google.com/search?q="+tracking.sTrackingNumber} target="_blank">
                                  {tracking.sTrackingNumber} {tracking.sStatusCode} {tracking.sStatusCode === "DE" ? moment(tracking.dtRealDelivery).format("MM-DD") : moment(tracking.dtEstimatedDelivery).format("MM-DD")}
                                </a>
                              </div>
                            ))}
                            <br />
                            <div><b>Outbound Tracking</b></div>
                            {sql.outbound.map((tracking, index2) => (
                            <div style={{color: tracking.sStatusCode === "DE" ? cGreenText : ''}}>
                              <input type="checkbox" checked={tracking.bCompleted} onClick={()=>this.props.handleConfirmShipment(tracking)}></input>
                              <a href={"https://www.google.com/search?q="+tracking.sTrackingNumber} target="_blank">
                                {tracking.sTrackingNumber} {tracking.sStatusCode} {tracking.sStatusCode === "DE" ? moment(tracking.dtRealDelivery).format("MM-DD") : moment(tracking.dtEstimatedDelivery).format("MM-DD")}
                              </a>
                              <br />
                            </div>
                          ))}
                      </td>
                      </tr>
                    {sql.showPOs && (
                      <tr>
                      <td colSpan={"100%"}>
                      <div>
                        <table class="dashboard">
                          <thead>
                            <tr>
                              <th>Vendor</th>
                              <th>Description</th>
                              <th>Quantity</th>
                              <th>Price</th>
                              <th>Total Price</th>
                              <th>Item</th>
                            </tr>
                          </thead>
                          {sql.POLines && sql.POLines.map((line, lineNumber) => (
                            <tr key={lineNumber}>
                              <td>{line.sCompany}</td>
                              <td>{line.sDescription}</td>
                              <td>{line.nQuantity}</td>
                              <td>{nQuoteTotalNoDollar(line.nPrice)}</td>
                              <td>{nQuoteTotalNoDollar(line.nQuantity*line.nPrice)}</td>
                              <td onClick={()=>this.props.handleSetLabor(sql.index, lineNumber)}>{line.nItem === 832 && (
                                <>CSN Labor</>
                              ) || (
                                <GreyTextDiv>Set as CSN Labor</GreyTextDiv>
                              )}</td>
                            </tr>
                          ))}
                        </table>
                      </div>
                      </td>
                      </tr>
                    )}
                    
                    </table>
                  ))}
                  
                </div>
            )}
  
          </>
      );
    }
  }