import React, { Component } from 'react';
import { nQuoteTotalNoDollar } from '../../../assets/functions/calculations';

class ClosedNotOnLog extends Component {
    render() {
        return (
            <div>
            <table>
            <thead>
              <tr>
                <th>Quote</th>
                <th>Date First Closed</th>
              </tr>
            </thead>
            {this.props.closedNotLogged.map((quote, index)=>(
              <tr>
                <th>{quote.id}</th>
                <th>{quote.dtFirstClosed}</th>
              </tr>              
            ))}
            </table>
          </div>
        );
    }
}

export default ClosedNotOnLog;