import React, { Component } from 'react';
import styled from 'styled-components';
import { getSQLData, setSQLData } from '../../../assets/functions/fetch';
import { calcOpenInstallChartData, matchQuotesAndNotes, matchQuotesAndPOLines, matchQuotesAndPackages } from '../../../assets/functions/process/cam';
import { nullDateCheck } from '../../../assets/functions/system';
import LateDeliveries from '../../tables/rac-logistics/LateDeliveries';
import ReturnReminders from '../../tables/rac-logistics/ReturnReminders';
import VendorPreDelivery from '../../tables/rac-logistics/VendorPreDelivery';
import CustomerPreDelivery from '../../tables/rac-logistics/CustomerPreDelivery';
import OrdersNotYetReturned from '../../tables/rac-logistics/OrdersNotYetReturned';
import PackagesMissingPO from '../../tables/rac-logistics/PackagesMissingPO';
import MissingDeliveryTracking from '../../tables/rac-logistics/MissingDeliveryTracking';
import MissingReturnTracking from '../../tables/rac-logistics/MissingReturnTracking';
import PackagesDeliveredNotCheckedIn from '../../tables/rac-logistics/PackagesDeliveredNotCheckedIn';
import ReturnsDeliveredNotCheckedIn from '../../tables/rac-logistics/ReturnsDeliveredNotCheckedIn';
import NeedsPackingSlip from '../../tables/rac-logistics/NeedsPackingSlip';
import OrdersMissingStar from '../../tables/rac-logistics/OrdersMissingStar';
import OrdersNotYetReturnedv2 from '../../tables/rac-logistics/OrdersNotYetReturnedv2';


const Wrapper = styled.section`
  padding: 2em 0 1em 0;
  text-align: center;
  @media (max-width: 900px) {
    padding-bottom: 5em;
  }
`;

const Title = styled.h1`
  font-size: 2rem;
`;

const SmallSubTitle = styled.p`
  padding: 0.5em 0 2em 0em;
  font-size: 1rem;
`;

const DashboardControls = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin: 1em auto;
  padding: 2em 4em;
  width: fit-content;
  height: fit-content;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.1);
  text-align: center;
  border-radius: 15px;

  button {
    display: inline-block;
     padding: 0.5em 1.2em;
     margin: 0 0.3em 0.3em 0;
     text-decoration: none;
     color: #333;
     text-align: center;
     transition: all 0.2s;
    background-color: #f2f5f7;
    border: 1px solid rgba(255, 255, 255, 0);
    cursor: pointer;

    &:hover {
      background-color: #36a2eb;
      border: 1px solid #36a2eb;
      color: #fff;
    }
  }

  select {
    display: inline-block;
    color: #333;
     text-align: center;
     padding: 0.3em 1.2em;
     margin: 0 0.3em 0.3em 0;
    background-color: #f2f5f7;
    border: 1px solid #f2f5f7;
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
      background-color: #36a2eb;
      border: 1px solid #36a2eb;
      color: #fff;
    }
  }
`;

export default class RacLogisticsHomev2 extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loaded: false,
        hideAll: false,
        showAll: true,
        viewing: "Late Deliveries",
      };
      this.handleDateChange = this.handleDateChange.bind(this)
      this.handleDateSubmit = this.handleDateSubmit.bind(this)
      this.updateAsSoldMargin = this.updateAsSoldMargin.bind(this)
      this.handleLateCallNote = this.handleLateCallNote.bind(this)
      this.handleSaveTag = this.handleSaveTag.bind(this)
      this.handleHideShow = this.handleHideShow.bind(this)
      this.handleShowPO = this.handleShowPO.bind(this)
      this.handleSetLabor = this.handleSetLabor.bind(this)
      this.handleConfirmShipment = this.handleConfirmShipment.bind(this)
      this.setLaborOnly = this.setLaborOnly.bind(this)
    }
  
    componentDidMount() {
      this.fetchData();
    }
  
    fetchData = async () => {
  
      try {
        const fetch = await getSQLData("cam/openInstalls", {})
        const fetchPOs = await getSQLData("cam/openInstallPOLines", {})
        const fetchInstallNotes = await getSQLData("cam/openInstallNotes", {})
        const fetchPackages = await getSQLData("cam/openInstallPackages", {})
        
        if(fetch && fetchPOs && fetchInstallNotes){
  
          let data = fetch.data.data[0].recordset  
          let POData = fetchPOs.data.data[0].recordset
          let quoteNotes = fetchInstallNotes.data.data[0].recordset
          let packages = fetchPackages.data.data[0].recordset
          
          
          this.setState({
            data: data,
            POData: POData,
            quoteNotes: quoteNotes,
            packages: packages,
          }, () => this.processData())
        }
           
      } catch (error) {
        console.error(error);
      }
    }
  
    processData() {
      let data = this.state.data
      let quoteNotes = this.state.quoteNotes
      let POData = this.state.POData
      let packages = this.state.packages
  
      let chartData = calcOpenInstallChartData(data, 20)
      
      data = matchQuotesAndPOLines(data, POData)
      data = matchQuotesAndNotes(data, quoteNotes)
      data = matchQuotesAndPackages(data, packages)
  
      console.log(data)
  
      let LateDeliveries = []
      let ReturnReminders = []
      let VendorPreDelivery = []
      let CustomerPreDelivery = []
      let OrdersNotYetReturned = []
      let PackagesMissingPO = []
      let MissingDeliveryTracking = []
      let MissingReturnTracking = []
      let PackagesDeliveredNotCheckedIn = []
      let ReturnsDeliveredNotCheckedIn = []
      let NeedsPackingSlip = []
      let OrdersMissingStar = []
      let OrdersNotYetReturnedv2 = []
  
  
      this.setState({
        chartData: chartData,
        data: data,
        LateDeliveries: LateDeliveries,
        ReturnReminders: ReturnReminders,
        VendorPreDelivery: VendorPreDelivery,
        CustomerPreDelivery: CustomerPreDelivery,
        OrdersNotYetReturned: OrdersNotYetReturned,
        PackagesMissingPO: PackagesMissingPO,
        MissingDeliveryTracking: MissingDeliveryTracking,
        MissingReturnTracking: MissingReturnTracking,
        PackagesDeliveredNotCheckedIn: PackagesDeliveredNotCheckedIn,
        ReturnsDeliveredNotCheckedIn: ReturnsDeliveredNotCheckedIn,
        NeedsPackingSlip: NeedsPackingSlip,
        OrdersMissingStar: OrdersMissingStar,
        OrdersNotYetReturnedv2: OrdersNotYetReturnedv2,
        loaded: true,
      })
    }
  
    handleDateChange = (event, id, paramName) => {
      console.log(event.target.value, id, paramName)
      let data = this.state.data
      data[id][paramName] = event.target.value
  
  
      this.setState({data: data})
    };
  
    handleDateSubmit = async(date, id, type) => {
      const queryLookup = {
        dtComplete: "cam/setQuoteCompleteDate",
        dtTargetComplete: "cam/setQuoteTargetComplete",
        dtTargetStart: "cam/setQuoteTargetStart",
        dtShipDate: "cam/setShipDate",
        dtHardwareExpected: "cam/setHardwareExpected",
        dtHardwareSetup: "cam/setHardwareSetup",
      }
  
      try {
        const fetch = await setSQLData(queryLookup[type], {
          quoteNum: id,
          date: nullDateCheck(date),
        })
        
        if(fetch){
          this.fetchData();
        }
        
      } catch (error) {
        console.error(error);
      }
    }
  
    updateAsSoldMargin = async(value, id) => {
      try {
        const fetch = await setSQLData("cam/setAsSoldMargin", {
          quoteNum: id,
          margin: value,
        })
  
        if(fetch){
          this.fetchData();
        }
  
      } catch (error){
  
      }
    }
  
    handleLateCallNote(getText, getIndex) {
      let data = this.state.data
  
      data[getIndex].sNote = getText
      data[getIndex].changed = true
  
      console.log(data,getText, getIndex)
  
      this.setState({data: data})
    }
  

    handleSaveTag = async (text, id, rep) => {
      try {
        const fetch = await setSQLData("logistics/addLateCallNote", {
          quoteNum: id,
          noteText: text,
          repID: rep,
        })
        
        if(fetch){
          this.fetchData();
        }
        
      } catch (error) {
        console.error(error);
      }
    };

    handleSubmitDate = async (date, id) => {
      try {
        const fetch = await setSQLData("cam/setQuoteCompleteDate", {
          quoteNum: id,
          date: nullDateCheck(date),
        })
        
        if(fetch){
          this.fetchData();
        }
        
      } catch (error) {
        console.error(error);
      }
    };
    handleTargetSubmitDate = async (date, id) => {
      try {
        const fetch = await setSQLData("cam/setQuoteTargetComplete", {
          quoteNum: id,
          date: nullDateCheck(date),
        })
        
        if(fetch){
          this.fetchData();
        }
        
      } catch (error) {
        console.error(error);
      }
    };
   
  
    handleHideShow(){
      this.setState({hideAll: !this.state.hideAll})
    }
  
    handleShowPO(index){
      let data = this.state.data
      data[index].showPOs = !data[index].showPOs
      this.setState({data: data})
    }
  
    handleSetLabor = async (quoteIndex, lineIndex) => {
      let lineID = this.state.data[quoteIndex].POLines[lineIndex].LineID
      let newValue = (this.state.data[quoteIndex].POLines[lineIndex].nItem === 832 ? null : 832)
  
      console.log(quoteIndex)
  
      try {
        const fetch = await setSQLData("cam/setPOLineItem", {
          lineID: lineID,
          itemNum: newValue,
        })
        
        if(fetch){
          this.fetchData();
        }
        
      } catch (error) {
        console.error(error);
      }
    }
  
    handleConfirmShipment = async (tracking) => {
      try {
        const fetch = await setSQLData("logistics/trackingCompleteStatus", {
          trackingID: tracking.Id,
          trackingStatus: tracking.bCompleted ? 0 : 1,
        })
  
        if(fetch){
          this.fetchData()
        }
      } catch (error){
        console.log(error)
      }
    }
    setLaborOnly = async (quote) => {
      try {
        const fetch = await setSQLData("cam/setLaborOnly", {
          quoteNum: quote.id,
          laborOnly: quote.bLaborOnly ? 0 : 1,
        })
  
        if(fetch){
          this.fetchData()
        }
      } catch (error){
        console.log(error)
      }
    }
  
    render() {
      return (
        <>
        {this.state.loaded && (
          <>
          
          <Wrapper amount={this.state.data ? this.state.data.length : 0}>
          {!this.state.hideAll && (
            <>
             
              <div>
                {(this.state.LateDeliveries.length > 0 || this.state.showAll) && (<button onClick={()=>this.setState({viewing: "Late Deliveries"})}>Late Deliveries</button>)}
                {(this.state.ReturnReminders.length > 0 || this.state.showAll) && (<button onClick={()=>this.setState({viewing: "Return Reminders"})}>Return Reminders</button>)}
                {(this.state.VendorPreDelivery.length > 0 || this.state.showAll) && (<button onClick={()=>this.setState({viewing: "Vendor Pre-Delivery"})}>Vendor Pre-Delivery</button>)}
                {(this.state.CustomerPreDelivery.length > 0 || this.state.showAll) && (<button onClick={()=>this.setState({viewing: "Customer Pre-Delivery"})}>Customer Pre-Delivery</button>)}
                {(this.state.OrdersNotYetReturned.length > 0 || this.state.showAll) && (<button onClick={()=>this.setState({viewing: "Orders Not Returned Or Rebilled"})}>Orders Not Returned Or Rebilled</button>)}
                {(this.state.PackagesMissingPO.length > 0 || this.state.showAll) && (<button onClick={()=>this.setState({viewing: "Packages Missing POs"})}>Packages Missing POs</button>)}
                {(this.state.MissingDeliveryTracking.length > 0 || this.state.showAll) && (<button onClick={()=>this.setState({viewing: "Missing Delivery Tracking"})}>Missing Delivery Tracking</button>)}
                {(this.state.MissingReturnTrackinglength > 0 || this.state.showAll) && (<button onClick={()=>this.setState({viewing: "Missing Return Tracking"})}>Missing Return Tracking</button>)}
                {(this.state.PackagesDeliveredNotCheckedIn.length > 0 || this.state.showAll) && (<button onClick={()=>this.setState({viewing: "Customer Delivered But Not Checked In"})}>Customer Delivered But Not Checked In</button>)}
                {(this.state.ReturnsDeliveredNotCheckedIn.length > 0 || this.state.showAll) && (<button onClick={()=>this.setState({viewing: "Return Delivered But Not Checked In"})}>Return Delivered But Not Checked In</button>)}
                {(this.state.NeedsPackingSlip.length > 0 || this.state.showAll) && (<button onClick={()=>this.setState({viewing: "Need Packing Slip"})}>Need Packing Slip</button>)}
                {(this.state.OrdersMissingStar.length > 0 || this.state.showAll) && (<button onClick={()=>this.setState({viewing: "Needs Likelihood To Close"})}>Needs Likelihood To Close</button>)}
                {(this.state.OrdersNotYetReturnedv2.length > 0 || this.state.showAll) && (<button onClick={()=>this.setState({viewing: "Orders Not Returned Updated"})}>Orders Not Returned Updated</button>)}
              </div>
              <div>
                <button onClick={()=>this.setState({showAll: !this.state.showAll})}>Show All</button>
              </div>
  
              {this.state.viewing === "Late Deliveries" && (
                <LateDeliveries
                  data={this.state.LateDeliveries}
                  handleDateChange={this.handleDateChange}
                  handleDateSubmit={this.handleDateSubmit}
                  handleUpdateTag={this.handleUpdateTag}
                  handleSaveTag={this.handleSaveTag}
                  handleShowPO={this.handleShowPO}
                  handleSetLabor={this.handleSetLabor}
                  updateAsSoldMargin={this.updateAsSoldMargin}
                  setLaborOnly={this.setLaborOnly}
                />
              )}
              {this.state.viewing === "Return Reminders" && (
                <ReturnReminders
                  data={this.state.ReturnReminders}
                  handleDateChange={this.handleDateChange}
                  handleDateSubmit={this.handleDateSubmit}
                  handleUpdateTag={this.handleUpdateTag}
                  handleSaveTag={this.handleSaveTag}
                  handleShowPO={this.handleShowPO}
                  handleSetLabor={this.handleSetLabor}
                  setLaborOnly={this.setLaborOnly}
                />
              )}
              {this.state.viewing === "Vendor Pre-Delivery" && (
                <VendorPreDelivery
                  data={this.state.VendorPreDelivery}
                  handleDateChange={this.handleDateChange}
                  handleDateSubmit={this.handleDateSubmit}
                  handleUpdateTag={this.handleUpdateTag}
                  handleSaveTag={this.handleSaveTag}
                  handleShowPO={this.handleShowPO}
                  handleSetLabor={this.handleSetLabor}
                  setLaborOnly={this.setLaborOnly}
                />
              )}
              {this.state.viewing === "Customer Pre-Delivery" && (
                <CustomerPreDelivery
                  data={this.state.CustomerPreDelivery}
                  handleDateChange={this.handleDateChange}
                  handleDateSubmit={this.handleDateSubmit}
                  handleUpdateTag={this.handleUpdateTag}
                  handleSaveTag={this.handleSaveTag}
                  handleShowPO={this.handleShowPO}
                  handleSetLabor={this.handleSetLabor}
                  setLaborOnly={this.setLaborOnly}
                />
              )}
              {this.state.viewing === "Orders Not Returned Or Rebilled" && (
                <OrdersNotYetReturned
                  data={this.state.OrdersNotYetReturned}
                  handleDateChange={this.handleDateChange}
                  handleDateSubmit={this.handleDateSubmit}
                  handleUpdateTag={this.handleUpdateTag}
                  handleSaveTag={this.handleSaveTag}
                  handleShowPO={this.handleShowPO}
                  handleSetLabor={this.handleSetLabor}
                  setLaborOnly={this.setLaborOnly}
                />
              )}
              {this.state.viewing === "Packages Missing POs" && (
                <PackagesMissingPO
                  data={this.state.PackagesMissingPO}
                  handleDateChange={this.handleDateChange}
                  handleDateSubmit={this.handleDateSubmit}
                  handleUpdateTag={this.handleUpdateTag}
                  handleSaveTag={this.handleSaveTag}
                  handleShowPO={this.handleShowPO}
                  handleSetLabor={this.handleSetLabor}
                />
              )}
              {this.state.viewing === "Missing Delivery Tracking" && (
                <MissingDeliveryTracking
                  data={this.state.MissingDeliveryTracking}
                  handleDateChange={this.handleDateChange}
                  handleDateSubmit={this.handleDateSubmit}
                  handleUpdateTag={this.handleUpdateTag}
                  handleSaveTag={this.handleSaveTag}
                  handleShowPO={this.handleShowPO}
                  handleSetLabor={this.handleSetLabor}
                  handleConfirmShipment={this.handleConfirmShipment}
                />
              )}
              {this.state.viewing === "Missing Return Tracking" && (
                <MissingReturnTracking
                  data={this.state.MissingReturnTracking}
                  handleDateChange={this.handleDateChange}
                  handleDateSubmit={this.handleDateSubmit}
                  handleUpdateTag={this.handleUpdateTag}
                  handleSaveTag={this.handleSaveTag}
                  handleShowPO={this.handleShowPO}
                  handleSetLabor={this.handleSetLabor}
                />
              )}
              {this.state.viewing === "Customer Delivered But Not Checked In" && (
                <PackagesDeliveredNotCheckedIn
                  data={this.state.PackagesDeliveredNotCheckedIn}
                  handleDateChange={this.handleDateChange}
                  handleDateSubmit={this.handleDateSubmit}
                  handleUpdateTag={this.handleUpdateTag}
                  handleSaveTag={this.handleSaveTag}
                  handleShowPO={this.handleShowPO}
                  handleSetLabor={this.handleSetLabor}
                  handleConfirmShipment={this.handleConfirmShipment}
                />
              )}
              {this.state.viewing === "Return Delivered But Not Checked In" && (
                <ReturnsDeliveredNotCheckedIn
                  data={this.state.ReturnsDeliveredNotCheckedIn}
                  handleDateChange={this.handleDateChange}
                  handleDateSubmit={this.handleDateSubmit}
                  handleUpdateTag={this.handleUpdateTag}
                  handleSaveTag={this.handleSaveTag}
                  handleShowPO={this.handleShowPO}
                  handleSetLabor={this.handleSetLabor}
                />
              )}
                 {this.state.viewing === "Need Packing Slip" && (
                <NeedsPackingSlip
                  data={this.state.NeedsPackingSlip}
                  handleDateChange={this.handleDateChange}
                  handleDateSubmit={this.handleDateSubmit}
                  handleUpdateTag={this.handleUpdateTag}
                  handleSaveTag={this.handleSaveTag}
                  handleShowPO={this.handleShowPO}
                  handleSetLabor={this.handleSetLabor}
                />
              )}
                 {this.state.viewing === "Needs Likelihood To Close" && (
                <OrdersMissingStar
                  data={this.state.OrdersMissingStar}
                  handleDateChange={this.handleDateChange}
                  handleDateSubmit={this.handleDateSubmit}
                  handleUpdateTag={this.handleUpdateTag}
                  handleSaveTag={this.handleSaveTag}
                  handleShowPO={this.handleShowPO}
                  handleSetLabor={this.handleSetLabor}
                />
              )}
                 {this.state.viewing === "Orders Not Returned Updated" && (
               <OrdersNotYetReturnedv2
               data={this.state.OrdersNotYetReturnedv2}
               handleDateChange={this.handleDateChange}
               handleDateSubmit={this.handleDateSubmit}
               handleLateCallNote={this.handleLateCallNote}
               handleSaveTag={(text, id) => this.handleSaveTag(text, id, this.state.rep)}
               handleShowPO={this.handleShowPO}
               handleSetLabor={this.handleSetLabor}
             />
              )}
            </>
          )}  
  
        </Wrapper>
        
        </>
        )}
        </>
      );
    }
  }
  