import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import { cGreen, cRed } from '../../../../assets/functions/calculations';

export default class RecentlyCompletedChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    
    return (
      <Bar
        data={{
          labels: this.props.chartData.labels,
          datasets: [
            {
              type: "line",
              lineTension: 0,
              data: this.props.chartData.data[1],
              label: 'Last Year',
              borderColor: "#090",
              fill: false,
              yAxisID: 'y-axis-1',
            },
            {
              type: "line",
              lineTension: 0,
              data: this.props.chartData.target,
              label: 'Target',
              borderColor: "#900",
              fill: false,
              yAxisID: 'y-axis-1',
            },
            {
              type: "bar",
              lineTension: 0,
              data: this.props.chartData.data[0],
              label: 'This Year',
              backgroundColor: "#999",
              fill: false,
              yAxisID: 'y-axis-1',
            },
          ],
        }}
          width={100}
          height={35}
          options={{
            scales: {
              yAxes: [
                {
                  type: 'linear',
                  display: true,
                  position: 'left',
                  id: 'y-axis-1',
                  ticks: {
                    max: this.props.chartData.upperBound,
                    min: this.props.chartData.lowerBound,
                  },
                },
              ], 
            },
                            
          responsive: true,
          maintainAspectRatio: true,
          title: {
            display: false,
            fontSize: 16,
            text: "Total Install Margin"
          },
          legend: {
            display: true,
          },
          }}
      />
    );
  }
}
