import React, { Component } from 'react'
import { getSQLData, setSQLData } from '../../../assets/functions/fetch';
import { nQuoteTotalNoDollar } from '../../../assets/functions/calculations';
import moment from 'moment';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { formatAbbreviatedDollar } from '../../../assets/functions/display';
import FuzzySet from 'fuzzyset'


export default class MarketingProfileWizard extends Component {
  constructor(props) {
    super(props);
      this.state = {
          loaded: false,
          selectedCustomer: 0,
          customerDataLoaded: false,
          sIndustry: null,
          sSubIndustry: null,
          nEmployees: 0,
          nCompanyAnnualSales: 0,
          customer: {},
          suggestIndustries: [],
      }
  }

  componentDidMount() {
    this.fetchData()
  }



  fetchData = async() => {
    try {

      const fetchNoMarketingProfile = await getSQLData("marketing/noMarketingProfile", {})
      const fetchMarketingProfile = await getSQLData("marketing/marketingProfile", {})
      const getIndustries = await getSQLData("marketing/getIndustries", {})

      if(fetchNoMarketingProfile && getIndustries && fetchMarketingProfile){
        let noMarketingProfile = fetchNoMarketingProfile.data.data[0].recordset
        let marketingProfile = fetchMarketingProfile.data.data[0].recordset
        let industries = getIndustries.data.data[0].recordset
        

        this.setState({
          noMarketingProfile: noMarketingProfile,
          marketingProfile: marketingProfile,
          industries: industries,
          suggestIndustries: industries,
          loaded: true,
          
        })
      }

    } catch (error) {

    }
  }

  selectCustomer = async(id) => {
    let selectedCustomer = id

    try {
      
      let fetchCustomerData = await getSQLData("marketing/getCustomerData", {customerID: id})
      let fetchCustomerQuotes = await getSQLData("marketing/quotesByCustomer", {customerID: id})
      let fetchCustomerPhones = await getSQLData("marketing/phoneNumbersByCompany", {customerID: id})
      let fetchCustomerEmails = await getSQLData("marketing/emailsByCompany", {customerID: id})

      if(fetchCustomerQuotes && fetchCustomerPhones && fetchCustomerEmails && fetchCustomerData){
        let customer = fetchCustomerData.data.data[0].recordset[0]
        let customerQuotes = fetchCustomerQuotes.data.data[0].recordset
        let customerPhones = fetchCustomerPhones.data.data[0].recordset
        let customerEmails = fetchCustomerEmails.data.data[0].recordset

        this.setState({
          selectedCustomer: selectedCustomer,
          customerSQL: {...customer},
          customer: customer,
          customerQuotes: customerQuotes,
          customerPhones: customerPhones,
          customerEmails: customerEmails,
        }, ()=>this.processCustomerData())
      }

    } catch (error) {

    }

    
  }

  normalizePhone(number){
    return parseInt(number.replaceAll("-","").replaceAll("(","").replaceAll(")",""))
  }

  processCustomerData = async() => {
    let customerQuotes = this.state.customerQuotes
    let normalizedPhoneNumbers = []
    let normalizedPhoneString = ""
    let customer = this.state.customer
    let sIndustry = customer.sIndustry
    
    for(let i=0; i<customerQuotes.length; i++){
      customerQuotes[i].normPhone = customerQuotes[i].sPhone ? this.normalizePhone(customerQuotes[i].sPhone) : null
      customerQuotes[i].normDPhone = customerQuotes[i].sDPhone ? this.normalizePhone(customerQuotes[i].sDPhone) : null

      if(!normalizedPhoneNumbers.includes(customerQuotes[i].normPhone) && !isNaN(customerQuotes[i].normPhone)){normalizedPhoneNumbers.push(customerQuotes[i].normPhone)}
      if(!normalizedPhoneNumbers.includes(customerQuotes[i].normDPhone) && !isNaN(customerQuotes[i].normDPhone)){normalizedPhoneNumbers.push(customerQuotes[i].normDPhone)}
    }

    for(let i=0; i<normalizedPhoneNumbers.length; i++){
      normalizedPhoneString = (i === 0 ? normalizedPhoneNumbers[i] : normalizedPhoneString + "," + normalizedPhoneNumbers[i]) 
    }

    try {

      const fetchCalls = await getSQLData("call_log/findCallsByPhoneNumberString", {phoneString: normalizedPhoneString})
      const fetchSubIndustries = await getSQLData("marketing/getSubIndustries", {sIndustry: sIndustry})

      if(fetchCalls && fetchSubIndustries){
        let customerCalls = fetchCalls.data.data[0].recordset
        let subIndustries = fetchSubIndustries.data.data[0].recordset

        console.log(subIndustries)

        this.setState({
          customerDataLoaded: true,
          customerCalls: customerCalls,
          subIndustries: subIndustries,
        })
      }

    } catch (error) {

    }

    
  }

  changeData = (newData) => {
    let customer = this.state.customer
    let field = Object.keys(newData)[0]
    let data = newData[field]
    let industries = this.state.industries
    let suggestIndustries = []
    customer[field] = data
    
    if(field === "sIndustry"){
      let industryArray = []
      let searchString = customer[field].toLowerCase()

      for (let i=0; i<industries.length; i++){
        industryArray.push(industries[i].sIndustry)
      }
      
      if(searchString.length > 3){
        let fs = FuzzySet(industryArray)
        suggestIndustries = fs.get(searchString)
      } else if(searchString.length > 0) {
        for(let i=0; i<industryArray.length; i++){
          if(industryArray[i].toLowerCase().includes(searchString)){
            suggestIndustries.push([1,industryArray[i]])
          }
        }
      }
      
    }

    this.setState({
      customer: customer,
      suggestIndustries: suggestIndustries,
    })
  }

  setData = async(field) => {
    try {
      let customer = this.state.customer
      let data = customer[field]
      

      if(field.substring(0,1) === "s"){
        data = "'" + data + "'"
      }
      if(field.substring(0,2) === "dt"){
        data = "'" + moment(data).format("MM/DD/YYYY") + "'"
      }

      setSQLData("marketing/setMarketingProfileData",{
        fieldName: field, 
        fieldValue: data, 
        companyID: this.state.selectedCustomer,
      })

      this.setState({
        customer: customer,
        customerSQL: {...customer},
      }, ()=>this.processCustomerData())

    } catch (error) {}
  }

  render() {
    let suggestIndustries = this.state.suggestIndustries ? (
                              this.state.suggestIndustries.length > 0 ? this.state.suggestIndustries : this.state.industries
                            ) : this.state.industries
    return (
      <div>
        {this.state.loaded && (
          <>

          {this.state.selectedCustomer === 0 && (
            <>
            <div>Companies with no marketing profile</div>
            <table>
              <thead>
                <tr>
                  <td>Company ID</td>
                  <td>Name</td>
                  <td>Most Recent Order</td>
                </tr>
              </thead>
              {this.state.noMarketingProfile.map((customer, index)=>(
                <tr onClick={()=>this.selectCustomer(customer.id)}>
                  <td>{customer.id}</td>
                  <td>{customer.sName}</td>
                  <td>{moment(customer.dtFirstClosed).utc().format("MM/DD/YYYY")}</td>
                </tr>
              ))}
            </table>
            <div>Most recently updated marketing profile</div>
            <table>
              <thead>
                <tr>
                  <td>Company ID</td>
                  <td>Name</td>
                  <td>Industry</td>
                  <td>Sub-Industry</td>
                  <td>Employees</td>
                  <td>Company Annual Sales</td>
                </tr>
              </thead>
              {this.state.marketingProfile.map((customer, index) => (
                <tr onClick={()=>this.selectCustomer(customer.id)}>
                  <td>{customer.id}</td>
                  <td>{customer.sName}</td>
                  <td>{customer.sIndustry}</td>
                  <td>{customer.sSubIndustry}</td>
                  <td>{customer.nEmployees ? customer.nEmployees.toLocaleString() : null}</td>
                  <td>{formatAbbreviatedDollar(customer.nCompanyAnnualSales)}</td>
                </tr>
              ))}
            </table>
            </>
          )}

          {this.state.selectedCustomer > 0 && this.state.customerDataLoaded && (
            <>
            <table>
              <thead>
                <tr>
                  <th>Quote</th>
                  <th>Company Name</th>
                  <th>Contact Name</th>
                  <th>Contact Phone</th>
                  <th>Contact Email</th>
                  <th>Status</th>
                  <th>Quote Total</th>
                  <th>Delivery Date</th>
                  <th>Pickup Date</th>
                </tr>
              </thead>
              {this.state.customerQuotes.map((quote, index)=> (
                <tr>
                  <td><Link target="_blank" to={`/quote/${quote.id}`}>{quote.id}</Link></td>
                  <td>{quote.sCompany}</td>
                  <td>{quote.sName}</td>
                  <td>{quote.sPhone}</td>
                  <td>{quote.sEmail}</td>
                  <td>{quote.sStatus}</td>
                  <td>{nQuoteTotalNoDollar(quote.nQuoteTotal)}</td>
                  <td>{moment(quote.dtDeliveryDate).format("MM-DD-YYYY")}</td>
                  <td>{moment(quote.dtPickupDate).format("MM-DD-YYYY")}</td>
                </tr>
              ))}
            </table>

            <table>
              <tr>
                <td>Industry</td>
                <td>
                  <input 
                    onChange={(event)=>this.changeData({sIndustry: event.target.value})}
                    value={this.state.customer.sIndustry}
                  />
                </td>
                <td>
                  {console.log(this.state.customer.sIndustry, this.state.customerSQL.sIndustry)}
                  {this.state.customer.sIndustry !== this.state.customerSQL.sIndustry && <button onClick={()=>this.setData("sIndustry")}>Save</button>}
                </td>
                <td>
                  {suggestIndustries.map((industry, index)=>
                  <>
                    {index < 10 && (
                      <div onClick={()=>this.changeData({sIndustry: industry[1] ? industry[1] : industry.sIndustry})}>
                        {industry[1] ? industry[1] : industry.sIndustry}
                      </div>
                    )}
                  </>
                  )}
                </td>
              </tr>
              <tr>
                <td>Sub-Industry</td>
                <td>
                  <input 
                    onChange={(event)=>this.changeData({sSubIndustry: event.target.value})}
                    value={this.state.customer.sSubIndustry}
                  />
                </td>
                <td>
                {this.state.customer.sSubIndustry !== this.state.customerSQL.sSubIndustry && <button onClick={()=>this.setData("sSubIndustry")}>Save</button>}
                </td>
                <td>
                  {this.state.subIndustries.map((subIndustry, index)=>(
                    <div onClick={()=>this.changeData({sSubIndustry: subIndustry.sSubIndustry})}>
                      {subIndustry.sSubIndustry}
                    </div>
                  ))}
                </td>
              </tr>
              <tr>
                <td>Employees</td>
                <td>
                  <input 
                    onChange={(event)=>this.changeData({nEmployees: parseInt(event.target.value)})}
                    value={this.state.customer.nEmployees}
                  />
                  {this.state.customer.nEmployees ? this.state.customer.nEmployees.toLocaleString() : null}
                </td>
                <td>
                {this.state.customer.nEmployees !== this.state.customerSQL.nEmployees && <button onClick={()=>this.setData("nEmployees")}>Save</button>}
                </td>
              </tr>
              <tr>
                <td>Company Annual Sales</td>
                <td>
                  <input 
                    onChange={(event)=>this.changeData({nCompanyAnnualSales: parseInt(event.target.value)})}
                    value={this.state.customer.nCompanyAnnualSales}
                  />
                  {formatAbbreviatedDollar(this.state.customer.nCompanyAnnualSales)}
                </td>
                <td>
                {this.state.customer.nCompanyAnnualSales !== this.state.customerSQL.nCompanyAnnualSales && <button onClick={()=>this.setData("nCompanyAnnualSales")}>Save</button>}
                </td>
              </tr>
              {/* 
              <tr>
                <td>Primary Contact Name</td>
                <td>
                  <input onChange={(event)=>{}}></input>
                </td>
                <td>
                  <button onClick={()=>{}}>Save</button>
                </td>
              </tr>
              <tr>
                <td>Primary Contact Phone</td>
                <td>
                  <input onChange={(event)=>{}}></input>
                </td>
                <td>
                  <button onClick={()=>{}}>Save</button>
                </td>
              </tr>
              <tr>
                <td>Primary Contact Email</td>
                <td>
                  <input onChange={(event)=>{}}></input>
                </td>
                <td>
                  <button onClick={()=>{}}>Save</button>
                </td>
              </tr>
              <tr>
                <td>Primary Contact Title</td>
                <td>
                  <input onChange={(event)=>{}}></input>
                </td>
                <td>
                  <button onClick={()=>{}}>Save</button>
                </td>
              </tr>
              <tr>
                <td>Primary Contact Department</td>
                <td>
                  <input onChange={(event)=>{}}></input>
                </td>
                <td>
                  <button onClick={()=>{}}>Save</button>
                </td>
              </tr>
              */}
            </table>
            
            </>
          )}

          </>
        )}
        <br />
        {this.state.selectedCustomer !== 0 && <button onClick={()=>this.setState({selectedCustomer: 0}, ()=>this.fetchData())}>Back</button>}
      </div>
    )
  }
}
