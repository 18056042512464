import React, { Component } from 'react'
import { CSVLink } from 'react-csv'
import { getSQLData } from '../../assets/functions/fetch'
import { generateInvoiceCSV } from '../../assets/functions/system'
export default class InvoiceCSVButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loaded: false,
        }
    }
    componentDidMount(){
        this.fetchData()
    }
    fetchData = async() => {
        try {
            const fetchQuoteData = await getSQLData("quote/quoteData", {quoteID: this.props.id})
            const fetchQuoteLines = await getSQLData("quote/quoteLines", {quoteID: this.props.id})

            if(fetchQuoteData && fetchQuoteLines){
                let quoteLines = fetchQuoteLines.data.data[0].recordset
                let data = fetchQuoteData.data.data[0].recordset[0]

                

                this.setState({
                    quoteLines: quoteLines,
                    data: data,
                }, () => this.processData())
            }

        } catch (error) {
            console.error(error)
        }
    }
    processData() {
        let data = this.state.data
        let quoteLines = this.state.quoteLines

        console.log(data, data.sEmail)

        let invoice = generateInvoiceCSV(data, quoteLines)

        this.setState({
            InvoiceData: invoice.Data,
            InvoiceHeaders: invoice.Headers,
            loaded: true,
        })
    }
  render() {
    return (
        <>
        {this.state.loaded && (
            <button>
                <CSVLink data={this.state.InvoiceData} headers={this.state.InvoiceHeaders} filename={"invoice"}>Download Invoice</CSVLink>
            </button>
        )}
        
        </>
    )
  }
}
