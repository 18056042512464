import React, { Component } from 'react';
import { getSQLData, setSQLData } from '../../../assets/functions/fetch';
import { CustomDateWrapper, Input, SubmitText } from '../../styled/ui';
import moment from 'moment';
import { stringToSQL } from '../../../assets/functions/system';
import { prettyDOM } from '@testing-library/react';

class FRPPoints extends Component {
    state = {
      lookup: '',
      showCompanyResults: false,
      companyResults: [],
      selectedCompany: {},
      showFRPList: false,
      dtDateEarned: moment().utc().format('YYYY-MM-DD'),
      showDeleteContacts: false,
      currentAction: "Add",
    }

    componentDidMount(){
      this.fetchData()
    }

    fetchData = async() => {
      try {
        const fetch = await getSQLData("accounting/predictFRP", {})
        if(fetch){
          let predictFRP = fetch.data.data[0].recordset

          this.setState({
            predictFRP: predictFRP,
            
          }, () => this.processData())
        }
      } catch (error) {

      }
    }

    processData(){
      let predictFRP = this.state.predictFRP
      console.log(predictFRP)

      this.setState({
        loaded: true,
        predictFRP: predictFRP,
      })
    }

    searchCustomer = async() => {

      if(!this.state.showCompanyFRPMatch || this.state.showFRPList){
        try {

          const fetch = await getSQLData("accounting/findFRPid", {sCompanyName: this.state.lookup})
          if(fetch){
            const companyResults = fetch.data.data[0].recordset
  
            this.setState({
              companyResults: companyResults,
              showFRPList: false,
            }, () => this.processSearchData())
          }
  
        } catch (error) {
  
        }
      } else {
        this.searchCustomerFRPMatch()
      }
      
    }

    processSearchData() {
      let companyResults = this.state.companyResults

      for(let i=0; i<companyResults.length; i++){
        companyResults[i].confirm = false
      }

      this.setState({
        companyResults: companyResults,
        showCompanyResults: true,
      })
    }

    selectCompany = async(index) => {
      try {
        let selectedCompany = this.state.companyResults[index]
        selectedCompany.index = index
        const fetch = await getSQLData("accounting/listFRP", {nContactId: selectedCompany.id})

        if(fetch){
          let frpList = fetch.data.data[0].recordset

          this.setState({
            selectedCompany: selectedCompany,
            frpList: frpList,
            showFRPList: true,
          }, () => this.searchCustomerFRPMatch())
        }
      } catch (error) {

      }
    }

    searchCustomerFRPMatch = async () => {
      if(this.state.selectedCompany.nFRPid){

      } else {
        try {
          const fetch = await getSQLData("accounting/findCompanyFRPMatch", {sName: this.state.lookup})
          if(fetch){
            let companyFRPMatch = fetch.data.data[0].recordset

            this.setState({
              companyFRPMatch: companyFRPMatch,
            }, ()=>this.processFRPMatchData())
          }


        } catch (error){
  
        }
      }
      
    }

    processFRPMatchData() {
      let companyFRPMatch = this.state.companyFRPMatch

      for(let i=0; i<companyFRPMatch.length; i++){
        companyFRPMatch[i].confirm = false
      }

      this.setState({
        companyFRPMatch: companyFRPMatch,
        showCompanyFRPMatch: true,
      })
    }

    submitFRP() {
      setSQLData("accounting/addFRP",{
        dtDateEarned: this.state.dtDateEarned, 
        nPoints: this.state.addPoints, 
        sUsedFor: this.state.usedFor,
        nContactId: this.state.selectedCompany.id
      })

      this.setState({
        addPoints: 0,
        dtDateEarned: moment().utc().format('YYYY-MM-DD'),
        usedFor: ''
      })
      this.selectCompany(this.state.selectedCompany.index)
    }

    handleClickActivate = async(index) => {
      let companyResults = this.state.companyResults

      if(companyResults[index].confirm){
        try {
          const fetch = await setSQLData("accounting/setFRPContactDelete", {
            bDelete: companyResults[index].bDelete ? 0 : 1, 
            nFRPid: companyResults[index].id,
          })
          companyResults[index].bDelete = !companyResults[index].bDelete
        } catch (error) {

        }
      } else {
        companyResults[index].confirm = true
      }
    
      this.setState({
        companyResults: companyResults,
      })
    }

    handleSelectCompany = async(index) => {
      let companyFRPMatch = this.state.companyFRPMatch

      if(!companyFRPMatch[index].confirm){
        companyFRPMatch[index].confirm = true
      } else {
        try {
          let selectedCompany = this.state.selectedCompany
          selectedCompany.nFRPid = this.state.companyFRPMatch[index].id

          const fetch = await setSQLData("accounting/setCompanyFRPid", {
            nFRPid: selectedCompany.id,
            nCompanyid: selectedCompany.nFRPid,
          })

          this.setState({
            selectedCompany: selectedCompany,
          })
        } catch (error) {

        }
      }
      

      this.setState({
        companyFRPMatch: companyFRPMatch,
      })
    }

    handleAddCustomer = async () => {
      if(this.state.confirmAddCustomer){
        const fetch = await setSQLData("accounting/createNewCustomer", {
          sContactName: stringToSQL(this.state.lookup)
        })
        this.setState({
          confirmAddCustomer: false
        })
      } else {
        this.setState({
          confirmAddCustomer: true
        })
      }
    }

    editPoints(index) {     
      let frpList = this.state.frpList
      this.setState({
        editingId: frpList[index].id,
        editingIndex: index,
        addPoints: frpList[index].nPoints,
        usedFor: frpList[index].sUsedFor,
        dtDateEarned: frpList[index].dtDateEarned,
        currentAction: "Edit",
      })
    }

    updateFRP = async() => {
      try {
        const fetch = await setSQLData("accounting/editFRP", {
          id: this.state.editingId,
          nPoints: this.state.addPoints,
          sUsedFor: this.state.usedFor,
          dtDateEarned: this.state.dtDateEarned,
        })

        let frpList = this.state.frpList
        frpList[this.state.editingIndex].nPoints = this.state.addPoints
        frpList[this.state.editingIndex].sUsedFor = this.state.usedFor
        frpList[this.state.editingIndex].dtDateEarned = this.state.dtDateEarned

        this.setState({
          frpList: frpList,
          addPoints: 0,
          usedFor: '',
          dtDateEarned: moment().utc().format('YYYY-MM-DD'),
          currentAction: "Add",
        })
      } catch (error) {

      }
    }

    deletePoints = async(index) => {
      let frpList = this.state.frpList
      if(frpList[index].confirmDelete){
        try {
          const fetch = await setSQLData("accounting/deleteFRP", {
            id: frpList[index].id,
          })
          frpList[index].confirmDelete=false
          frpList[index].bDelete=true
          this.setState({frpList: frpList})

        } catch (error) {

        }
      } else {
        frpList[index].confirmDelete=true
        this.setState({frpList: frpList})
      }
    }

    cancelUpdateFRP() {
      this.setState({
        addPoints: "",
        usedFor: "",
        dtDateEarned: moment().utc().format('YYYY-MM-DD'),
        currentAction: "Add"
      })
    }

    automateFRP = async(index) => {
      let predictFRP = this.state.predictFRP
      if(predictFRP[index].confirmAutomate){
        let quote = this.state.predictFRP[index]
        try {
          const fetch = await setSQLData("accounting/addFRP", {
            nPoints: quote.Earned,
            dtDateEarned: quote.dtPaid,
            sUsedFor: quote.id,
            nContactId: quote.nFRPid,
          })
          
          predictFRP[index].added = true
          this.setState({predictFRP: predictFRP})
        } catch (error) {

        }
      } else {
        predictFRP[index].confirmAutomate = true
        this.setState({predictFRP: predictFRP})
      }
      
    }

    render() {
      if(this.props.needRefresh){
        this.props.handleRefresh()
        this.fetchData()
      }

        return (
          <div>
            <input onChange={(event)=>this.setState({lookup: event.target.value})} value={this.state.lookup} />
            <button onClick={()=>this.searchCustomer()}>Search</button>
            <button onClick={()=>this.handleAddCustomer()}>{this.state.confirmAddCustomer ? "Confirm New Customer" : "Create New Customer"}</button>
            {this.state.loaded && !this.state.showFRPList && !this.state.showCompanyFRPMatch && !this.state.showCompanyResults && (
              <table width={1100}>
                <thead>
                  <th>Quote</th>
                  <th>Customer</th>
                  <th>FRP Earned</th>
                  <th>FRP Logged</th>
                  <th>Date Earned</th>
                  <th></th>
                </thead>
                {this.state.predictFRP.map((quote, index) => (
                  <>
                  {quote.added || (<tr>
                      <td width={100}>{quote.id}</td>
                      <td width={400}>{quote.sName}</td>
                      <td width={100}>{quote.Earned}</td>
                      <td width={100}>{quote.Logged}</td>
                      <td width={100}>{moment(quote.dtPaid).format("MM/DD/YY")}</td>
                      <td>
                        {quote.Logged > 0 
                        ? "You must enter manually"
                        : (quote.nFRPid 
                          ? <button onClick={()=>this.automateFRP(index)}>{quote.confirmAutomate ? "Confirm Add" : "Add FRP"}</button> 
                          : "You must enter manually")}
                        </td>
                    </tr>)}
                  </>
                ))}
              </table>
            )}
            {this.state.showCompanyResults && !this.state.showCompanyFRPMatch && !this.state.showFRPList && (
              <>
            Show Deleted <input type="checkbox" value={this.state.showDeleteContacts} onClick={()=>this.setState({showDeleteContacts: !this.state.showDeleteContacts})} />
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Balance</th>
                    <th>Change Status</th>
                  </tr>
                  {this.state.companyResults && this.state.companyResults.map((company,index)=>(
                    <>
                    
                      {(!company.bDelete || this.state.showDeleteContacts) && (
                        <tr>
                          <td>{company.sCompanyName} <button onClick={()=>this.selectCompany(index)}>Select</button></td>
                          <td>{company.Balance}</td>
                          <td>
                            <button onClick={()=>this.handleClickActivate(index)}>
                              {company.confirm 
                                ? (company.bDelete ? "Confirm Re-Activate" : "Confirm De-Activate") 
                                : (company.bDelete ? "Re-Activate" : "Make Inactive")}
                            </button>
                          </td>
                        </tr>
                      )}
                    </>
                  ))}
                </thead>
              </table>
              </>
            )}
            <br /><br />
            {!this.state.selectedCompany.nFRPid > 0 && this.state.showCompanyFRPMatch && (
              <table>
                <thead>
                  This FRP Contact does not have an associated Command Center Company record.
                  <th>Company</th>
                  <th>Closed Quotes</th>
                  <th>Sales $</th>
                  <th>Most Recent Order</th>
                </thead>
                {this.state.companyFRPMatch.map((company, index) => (
                  <tr onClick={()=>this.handleSelectCompany(index)}>
                    <td>
                      {company.sName}
                      <button>
                        {company.confirm ? "Confirm Selection" : "Select"}
                      </button>
                    </td>
                    <td>{company.nTotalDeals}</td>
                    <td>{company.nLifetimeSales}</td>
                    <td>{moment(company.dtLastDeal).format("MM/DD/YY")}</td>
                  </tr>
                ))}
              </table>
            )}

            {this.state.showFRPList && this.state.selectedCompany.nFRPid > 0 && (
              <div>
                <div>
                    {this.state.selectedCompany.sCompanyName} (Balance {Math.floor(this.state.selectedCompany.Balance)})
                </div>
                <div>
                  Date: <Input onChange={(event) => this.setState({dtDateEarned: event.target.value})} type="date" id="target" name="target"
                    value={moment(this.state.dtDateEarned).utc().format('YYYY-MM-DD')} />
                  
                </div>
                <div>
                  Points: <input onChange={(event)=>{
                    let newValue = event.target.value == '-' 
                      ? '-' 
                      : event.target.value 
                        ? parseInt(event.target.value) 
                        : null
                    this.setState({addPoints: newValue})
                    }} value={this.state.addPoints}></input>
                  
                </div>
                <div>
                  Quote: <input onChange={(event)=>this.setState({usedFor: event.target.value})} value={this.state.usedFor}></input>
                </div>
                <div>
                  {this.state.currentAction == "Add" && (
                    <button onClick={()=>this.submitFRP()}>Submit</button>
                  )}
                  {this.state.currentAction == "Edit" && (
                    <>
                    <button onClick={()=>this.updateFRP()}>Update</button>
                    <button onClick={()=>this.cancelUpdateFRP()}>Cancel</button>
                    </>
                  )}
                  
                </div>
                <div>
                    <table>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Points</th>
                          <th>Quote</th>
                          <th>Update</th>
                        </tr>
                      </thead>
                      {this.state.frpList.map((list, index)=>(
                        <>
                        {list.bDelete || (
                          <tr>
                            <td>{moment(list.dtDateEarned).format("MM/DD/YY")}</td>
                            <td>{list.nPoints}</td>
                            <td>{list.sUsedFor}</td>
                            <td>
                              <button onClick={()=>this.editPoints(index)}>Edit</button>
                              <button onClick={()=>this.deletePoints(index)}>{list.confirmDelete ? "Confirm Delete" : "Delete"}</button>
                            </td>
                          </tr>
                        )}
                        
                        </>
                      ))}
                    </table>
                </div>
              </div>
            )}
          </div>
        );
    }
}

export default FRPPoints;