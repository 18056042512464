import React, { Component } from 'react';
import styled from 'styled-components';
import CommunicationControls from './CommunicationControls';
import Log from './Log';
import Emails from './Emails';
import Notes from './Notes';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 1em 0;
`;

export default class Communications extends Component {
  state = { showNotes: true, showLog: false, showEmails: false };

  // Is sent as a prop to CommunicationControls.js, the event will the text of the button click in the CommunicationsControl.js component. It then sets all the state to false, then setting the event state to true, showing the selected component
  handleOnClick = (event) => {
    this.setState({
      showNotes: false,
      showLog: false,
      showEmails: false,
    });
    this.setState({ [`show${event}`]: true });
  };

  render() {
    return (
      <Wrapper>
        <CommunicationControls
          onClick={this.handleOnClick}
          noteCount={this.props.notes.length}
          emailCount={this.props.emails.length}
          logCount={this.props.log.length}
        />
        {this.state.showNotes ? <Notes notes={this.props.notes} /> : null}
        {this.state.showLog ? <Log log={this.props.log} /> : null}
        {this.state.showEmails ? <Emails emails={this.props.emails} data={this.props.data} /> : null}
      </Wrapper>
    );
  }
}
