import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  height: fit-content;
  text-align: center;

  button {
    cursor: pointer;
    width: 85%;
    margin: 0.5em auto;
    height: 40px;
    border: none;
    transition: all 0.2s;
    border-radius: 4px;

    &:hover {
      background-color: #4aabed;
      color: #fff;
    }
  }

  input {
    height: 40px;
    width: 85%;
    margin: 0 auto;
    padding: 0 0.7em;
    border: 1px solid #fff;
    border-radius: 5px;
    background-color: #f2f5f7;
  }
`;

export default class SocialLink extends Component {
  state = {};

  handleOnClick = (event) => {
    let tab = event.target.textContent.replace(/\s/g, '');
    this.props.onClick(tab);
  };

  render() {
    let socialLinks;

    // Splits string into array for social links, if null do nothing.
    // Reason for this is sSocialLinks is a string in the DB instead of an array, it will return null if it is blank
    if (this.props.socialMedia != null) {
      socialLinks = (
        <>
          {this.props.socialMedia.split(',').map((sql) => (
            <input type="text" value={sql}></input>
          ))}
        </>
      );
    } else {
      socialLinks = null;
    }

    return (
      <Wrapper>
        {socialLinks}
        <button>New Social Link</button>
      </Wrapper>
    );
  }
}
