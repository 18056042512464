import React, { Component } from 'react';
import styled from 'styled-components';
import Axios from 'axios';
import ReactTooltip from 'react-tooltip';
import ReportIssue from '../misc/ReportIssue';
import UserCard from '../misc/UserCard';
import Cookies from 'js-cookie';
import SearchCustomer from '../misc/SearchCustomer';
import { whichRep, activeReps } from '../../assets/functions/calculations';
import { NavLink } from 'react-router-dom';
import { thisMonthEnd } from '../../assets/functions/time';
import AddAddressModal from '../crm/master-viewer/AddAddressModal';
import AddContactModal from '../crm/master-viewer/AddContactModal';
import { Moment } from 'moment';
import { nQuoteTotalFormatter } from '../../assets/functions/calculations';

const Select = styled.select`
  width: 60%;
  height: 60px;
  white-space:pre-wrap;
  margin: 0.5em auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.4em;
    display:inline-block;
`
const Option = styled.option`
max-width: 100%;
overflow: visible;
word-wrap: normal !important;
white-space: pre-wrap;

`

const Wrapper = styled.section`
  padding: 2em 0 1em 0;
  text-align: left;
  align-content: left;
  @media (max-width: 1100px) {
    padding-bottom: 5em;
  };
  
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  background-color: #fff;
  max-width: 1100px;
  width: 90vw;
  border-radius: 10px;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.1);
  margin: 3em auto;
  padding: 1em;
`;

const ColumnHeader = styled.div`
  padding: 10px;
  display: inline-block;
  width: 200px;

`

const Title = styled.h1`
  padding: 2em 0 1em 0;
  text-align: center;
`;

const Input = styled.input`
  width: 90%;
  padding: 12px;
  padding: 0em 3em 0em 1em;
  margin: 0.5em auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  line-height: 40px;
  font-family: Noto Sans JP, sans-serif, FontAwesome;
`;

const Button = styled.button`
  display: inline-block;
   padding: 0.8em 2.5em;
   margin: 1em 0 1em 0.5em;
   text-decoration: none;
   color: #fafafa;
   text-align: center;
   transition: all 0.2s;
  background-color: rgba(54, 162, 235, 0.9);
  border: 1px solid #fafafa;
  cursor: pointer;
  border-radius: 50px;

  &:hover {
    border: 1px solid #d7d7d7;
  }
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

const AlertMessage = styled.p`
  position: absolute;
  color: red;
  top: 77px;
`;

const UserCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  padding: 1rem 0;
  margin: auto;
  justify-content: center;
`;

const Filter = styled.div`
  display: flex;
  justify-content: center;
  margin: 1em 0;
`;

const FilterOption = styled.p`
  margin: 0 1em;
  font-weight: 600;
  cursor: pointer;
`;

const TableCell = styled.div`
  margin: 1em;

`

class NewQuote extends Component {

  state = {
    user: '',
    loaded: false,
    customerDataLoaded: false,
    bAdmin: false,
    flashError: '',
    users: [],
    customerID: 0,
    customer: "",
    rep: 0,
    repName: "",
    brand: "",
    leadSource: "",
    allFilled: false,
    newQuote: 0,
    billingID: 0,
    billingAddress1: "",
    billingAddress2: "",
    billingCity: "",
    billingState: "",
    billingZip: 0,
    deliveryID: 0,
    deliveryAddress1: "",
    deliveryAddress2: "",
    deliveryCity: "",
    deliveryState: "",
    deliveryZip: 0,
    paymentTerms: "",
    bCC: 0,
    bNet: 0,
    bCOD: 0,
    contactID: 0,
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    deliveryContactID: 0,
    dCompany: "",
    dFirstName: "",
    dLastName: "",
    dPhone: "",
    dEmail: "",
    showWizard: false,
    addresses: [],
    contacts: [],
    showAddContactModal: false,
    showAddAddressModal: false,
    creating: "",
    pickup: "",
    delivery: "",
    rentalPeriod: "",
    rentalUnits: "Day",
    rentalPeriodDisplay: 0,
    deliveryType: "Shipping",
    templates: [],
    templateDataLoaded: false,
    templateSelected: 0,
    templateLines: [],
    templateLoaded: false,
    item: []
  };

  // Async Axios
  fetchUser = async () => {
    try {
      const passport = await Axios.get('/userSession', {
        credentials: 'include',
      });
      if (passport.data.passport) {
        this.setState({
          user: passport.data.passport.user,
        });

      }
      const response = await Axios.get('/user', {
        credentials: 'include',
      });
      if (
        response.data.result[0].recordset[0].sTTATitle === 'Developer' ||
        response.data.result[0].recordset[0].sTTATitle === 'CEO'
      ) {
        this.setState({
          bAdmin: !this.state.bAdmin,
        });
        Cookies.set('sTTATitle', 'admin');
      } else {
        if (response.data.result[0].recordset[0].bAdmin === true) {
          this.setState({
            bAdmin: !this.state.bAdmin,
          });
          Cookies.set('sTTATitle', 'admin');
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Async Axios
  fetchData = async () => {
    const response = await Axios.get('/new-quote-search', {
      params: {
        customerID: this.state.customerID
      },
      headers: { 'Content-Type': 'application/json' },
    });
    this.setState({
      addresses: response.data.result[0].recordset,
      contacts: response.data.result[1].recordset
    });
    // If the result is not empty, then it sets loaded to true, showing the drop down menu
    if (response.data.result.length > 0) {
      this.setState({
        customerDataLoaded: true,
      });

      
    } else {
      this.setState({
        customerDataLoaded: false,
      });
    }
  };
  catch(error) {
    console.error(error);
  }

  fetchTemplates = async () => {
    const response = await Axios.get('/get-templates', {
      params: {
        customerID: this.state.customerID
      },
      headers: { 'Content-Type': 'application/json' },
    });
    this.setState({
      templates: response.data.result[0].recordset
    });
    // If the result is not empty, then it sets loaded to true, showing the drop down menu
    if (response.data.result.length > 0) {
      this.setState({
        templateDataLoaded: true,
      });

      
    } else {
      console.log("test")
      this.setState({
        templateDataLoaded: false,
      });
    }
  };
  catch(error) {
    console.error(error);
  }

  fetchTemplateLines = async () => {
    const response = await Axios.get('/quote-lines', {
      params: {
        id: this.state.templateSelected
      },
      headers: { 'Content-Type': 'application/json' },
    });
    this.setState({
      templateLines: response.data.result[0].recordset
    });
    // If the result is not empty, then it sets loaded to true, showing the drop down menu
    if (response.data.result.length > 0) {
      console.log(response.data.result[0].recordset)
      this.setState({
        templateLoaded: true,
      });

      
    } else {
      this.setState({
        templateLoaded: false,
      });
    }
  };
  catch(error) {
    console.error(error);
  }

  fetchItemInfo = async (getItem) => {
    const response = await Axios.get('/item-info', {
      params: {
        item: getItem
      },
      headers: { 'Content-Type': 'application/json' },
    });
    this.setState({
      item: response.data.result[0].recordset
    });
  };
  catch(error) {
    console.error(error);
  }

  createNewQuote = async () => {

    try {
      const response = await Axios.post(
        "/create-new-quote-default-address",
        {
          getCompanyID: this.state.customerID,
          getCompany: this.state.customer,
          getRep: this.state.rep,
          getType: this.state.leadSource,
          getQuoteType: this.state.brand,
          getCC: this.state.bCC,
          getNet: this.state.bNet,
          getAddress1: "",
          getAddress2: "",
          getCity: "",
          getState: "",
          getZip: "",
          getDAddress1: "",
          getDAddress2: "",
          getDCity: "",
          getDState: "",
          getDZip: "",
          firstName: "",
          lastName: "",
          phone: "",
          email: ""
        },
      );
      if(response){
        let newQuote = Object.values(response.data.result[0].recordset[0])[0]
        this.setState({newQuote: newQuote},
          this.addCancellationWarning(newQuote)
        )
        this.addNewQuoteLog(newQuote)
        this.addQuoteLineFromItem(0, 0, 663, 0)
      }
    } catch (error) {
      console.error(error)
    }

  };

  addNewQuoteLog = () => {
    Axios({
      method: "post",
      url: "/add-new-quote-log",
      data: {
        getQuoteID: this.state.newQuote,
        getRep: whichRep(this.state.user),
        getRepID: this.state.user
      },
    });
  }

  addQuoteLineFromText = (getQuote, getText, getSort) => {
    Axios({
      method: "post",
      url: "/add-quote-line",
      data: {
        quote: getQuote,
        quantity: 0,
        description: getText,
        price: 0,
        tax: 0,
        sort: getSort,
        item: 0
      }
    })
  }

  addQuoteLineFromTemplateLine = (getLine, getQuote) => {
    let bTax = 0
    if(getLine.bTax){
      bTax = 1
    }

    Axios({
      method: "post",
      url: "/add-quote-line",
      data: {
        quote: getQuote,
        quantity: getLine.nQuantity,
        description: getLine.sDescription,
        price: getLine.nPrice,
        tax: bTax,
        sort: getLine.nSort,
        item: getLine.nItem
      }
    })
  }

  addCancellationWarning = (getID) => {
    this.addQuoteLineFromItem(getID, 0, 663, 1)
  }

  addOvernightWarning = (getID) => {
    this.addQuoteLineFromItem(getID, 0, 304, 2)
  }

  addQuoteLineFromItem = (getQuote, getQty, getItem, getSort) => {
    this.fetchItemInfo(getItem).then(() => {
      Axios({
        method: "post",
        url: "/add-quote-line",
        data: {
          quote: getQuote,
          quantity: getQty,
          description: this.state.item[0].sDescription,
          price: this.state.item[0].nPrice,
          tax: 1,
          sort: getSort,
          item: getItem
        }
      })
    })
  }

  

  createNewQuoteBlankLines = async () =>{
    try {
      console.log(this.state.customer)
      const response = await Axios.post(
        "/create-new-quote-blank-lines",
        {
          getCompanyID: this.state.customerID,
          getCompany: this.state.customer,
          getRep: this.state.rep,
          getType: this.state.leadSource,
          getQuoteType: this.state.brand,
          getCC: this.state.bCC,
          getNet: this.state.bNet,
          getAddress1: this.state.billingAddress1,
          getAddress2: this.state.billingAddress2,
          getCity: this.state.billingCity,
          getState: this.state.billingState,
          getZip: this.state.billingZip,
          getDAddress1: this.state.deliveryAddress1,
          getDAddress2: this.state.deliveryAddress2,
          getDCity: this.state.deliveryCity,
          getDState: this.state.deliveryState,
          getDZip: this.state.deliveryZip,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          phone: this.state.phone,
          email: this.state.email,
          dCompany: this.state.dCompany,
          dFirstName: this.state.dFirstName,
          dLastName: this.state.dLastName,
          dPhone: this.state.dPhone,
          dEmail: this.state.dEmail,
          delivery: this.state.delivery,
          pickup: this.state.pickup,
          rentalPeriod: this.state.rentalPeriod,
          rentalPeriodDisplay: this.state.rentalPeriodDisplay,
          rentalUnits: this.state.rentalUnits,
          deliveryType: this.state.deliveryType
        },
      );
      
      if(response){
        let newQuote = Object.values(response.data.result[0].recordset[0])[0]
        this.setState({newQuote: newQuote},
          this.addCancellationWarning(newQuote)
        )
        this.addNewQuoteLog(newQuote)
        
        let today = new Date()
        let delivery = new Date(Date.parse(this.state.delivery))

        let leadTime = (delivery - today)/1000/3600/24

        if (leadTime<=1){
          this.addOvernightWarning(newQuote)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  createNewQuoteFromTemplate = async () =>{
    try {
      console.log(this.state.customer)
      const response = await Axios.post(
        "/create-new-quote-blank-lines",
        {
          getCompanyID: this.state.customerID,
          getCompany: this.state.customer,
          getRep: this.state.rep,
          getType: this.state.leadSource,
          getQuoteType: this.state.brand,
          getCC: this.state.bCC,
          getNet: this.state.bNet,
          getAddress1: this.state.billingAddress1,
          getAddress2: this.state.billingAddress2,
          getCity: this.state.billingCity,
          getState: this.state.billingState,
          getZip: this.state.billingZip,
          getDAddress1: this.state.deliveryAddress1,
          getDAddress2: this.state.deliveryAddress2,
          getDCity: this.state.deliveryCity,
          getDState: this.state.deliveryState,
          getDZip: this.state.deliveryZip,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          phone: this.state.phone,
          email: this.state.email,
          dCompany: this.state.dCompany,
          dFirstName: this.state.dFirstName,
          dLastName: this.state.dLastName,
          dPhone: this.state.dPhone,
          dEmail: this.state.dEmail,
          delivery: this.state.delivery,
          pickup: this.state.pickup,
          rentalPeriod: this.state.rentalPeriod,
          rentalPeriodDisplay: this.state.rentalPeriodDisplay,
          rentalUnits: this.state.rentalUnits,
          deliveryType: this.state.deliveryType
        },
      );
      
      if(response){
        let newQuote = Object.values(response.data.result[0].recordset[0])[0]
        this.setState({newQuote: newQuote})
        this.addNewQuoteLog(newQuote)

        for(let i=0; i<this.state.templateLines.length; i++){
          this.addQuoteLineFromTemplateLine(this.state.templateLines[i], newQuote)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  createNewQuoteDefaultAddresses = async () => {

    try {
      const response = await Axios.post(
        "/create-new-quote-default-address",
        {
          getCompanyID: this.state.customerID,
          getCompany: this.state.customer,
          getRep: this.state.rep,
          getType: this.state.leadSource,
          getQuoteType: this.state.brand,
          getCC: this.state.bCC,
          getNet: this.state.bNet,
          getAddress1: this.state.billingAddress1,
          getAddress2: this.state.billingAddress2,
          getCity: this.state.billingCity,
          getState: this.state.billingState,
          getZip: this.state.billingZip,
          getDAddress1: this.state.deliveryAddress1,
          getDAddress2: this.state.deliveryAddress2,
          getDCity: this.state.deliveryCity,
          getDState: this.state.deliveryState,
          getDZip: this.state.deliveryZip,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          phone: this.state.phone,
          email: this.state.email,
          
        },
      );
      console.log(this.state.firstName)
      if(response){
        let newQuote = Object.values(response.data.result[0].recordset[0])[0]
        this.setState({newQuote: newQuote},
          this.addCancellationWarning(newQuote)
        )
      }
    } catch (error) {
      console.error(error)
    }

  };

  componentDidMount() {
    this.fetchUser();
  }

  getBrandByRep = (getRep) => {
    if(getRep === 44 || getRep === 45 || getRep === 46 || getRep === 58 || getRep === 59 || getRep === 60 || getRep === 62){
      return "CameraSecurityNow.com"

    } else {
      return "Rentacomputer.com"

    }
  }

  updateCustomer = (getData) => {
    let getBrand = this.getBrandByRep(getData.nDefaultRep)
    

    let bCC = 0
    let bNet = 0
    let bCOD = 0

    if(getData.sPaymentTerms ==="Credit Card" || getData.sPaymentTerms === "No Terms"){
      bCC = 1
    } else if(getData.sPaymentTerms === "Net 30" || getData.sPaymentTerms === "Net 60" || getData.sPaymentTerms === "Net 90" || getData.sPaymentTerms === "Due Upon Completion" || getData.sPaymentTerms === "50% Down 50% On Completion"){
      bNet = 1
    }

    this.setState({
      customerID: getData.id,
      customer: getData.sName,
      rep: getData.nDefaultRep,
      repName: whichRep(getData.nDefaultRep), 
      brand: getBrand,
      billingID: getData.nBillingAddress,
      billingAddress1: getData.sAddress,
      billingAddress2: getData.sAddress2,
      billingCity: getData.sCity,
      billingState: getData.sState,
      billingZip: getData.sZip,
      deliveryID: getData.nPrimaryAddress,
      deliveryAddress1: getData.sDAddress,
      deliveryAddress2: getData.sDAddress2,
      deliveryCity: getData.sDCity,
      deliveryState: getData.sDState,
      deliveryZip: getData.sDZip,
      paymentTerms: getData.sPaymentTerms,
      bCC: bCC,
      bNet: bNet,
      bCOD: bCOD,
      contactID: getData.nPrimaryContact,
      deliveryContactID: getData.nPrimaryContact,
      firstName: getData.sFirstName,
      lastName: getData.sLastName,
      phone: getData.sPhone,
      email: getData.sEmail,
      dFirstName: getData.sFirstName,
      dLastName: getData.sLastName,
      dPhone: getData.sPhone,
      dEmail: getData.sEmail,
      dCompany: getData.sName,
      deliveryAddress1: getData.sAddress,
      deliveryAddress2: getData.sAddress2,
      deliveryCity: getData.sCity,
      deliveryState: getData.sState,
      deliveryZip: getData.sZip
    })
  }

  quoteWizard = () => {
    this.setState({showWizard: true})

    this.fetchData()
    this.fetchTemplates()
  }

  updatePrimaryContact = (event) => {
    let newContact = event.target.value
    let newFirst = ""
    let newLast = ""
    let newPhone = ""
    let newEmail = ""

    for(let i=0; i<this.state.contacts.length; i++){
      if(this.state.contacts[i].id == newContact){
        newFirst = this.state.contacts[i].sFirstName
        newLast = this.state.contacts[i].sLastName
        newPhone = this.state.contacts[i].sPhone
        newEmail = this.state.contacts[i].sEmail
        
        i=this.state.contacts.length
      }
    }

    this.setState({
      contactID: newContact,
      firstName: newFirst,
      lastName: newLast,
      phone: newPhone,
      email: newEmail
    })

    
  }

  updateDeliveryContact = (event) => {
    let newContact = event.target.value
    let newFirst = ""
    let newLast = ""
    let newPhone = ""
    let newEmail = ""

    for(let i=0; i<this.state.contacts.length; i++){
      if(this.state.contacts[i].id == newContact){
        newFirst = this.state.contacts[i].sFirstName
        newLast = this.state.contacts[i].sLastName
        newPhone = this.state.contacts[i].sPhone
        newEmail = this.state.contacts[i].sEmail
        
        i=this.state.contacts.length
      }
    }

    console.log(newFirst)

    this.setState({
      deliveryContactID: newContact,
      dFirstName: newFirst,
      dLastName: newLast,
      dPhone: newPhone,
      dEmail: newEmail
    })
  }

  updateBillingAddress = (event) => {
    let newID = event.target.value
    let newAddress = ""
    let newAddress2 = ""
    let newCity = ""
    let newState = ""
    let newZip = ""

    for(let i=0; i<this.state.contacts.length; i++){
      if(this.state.contacts[i].id == newID){
        newAddress = this.state.addresses[i].sAddress
        newAddress2 = this.state.addresses[i].sAddress2
        newCity = this.state.addresses[i].sCity
        newState = this.state.addresses[i].sState
        newZip = this.state.addresses[i].sZip
        
        i=this.state.contacts.length
      }
    }

    this.setState({
      billingID: newID,
      billingAddress1: newAddress,
      billingAddress2: newAddress2,
      billingCity: newCity,
      billingState: newState,
      billingZip: newZip
    })
  }

  updateDeliveryAddress = (event) => {
    let newID = event.target.value
    let newAddress = ""
    let newAddress2 = ""
    let newCity = ""
    let newState = ""
    let newZip = ""
    let newCompany = ""

    for(let i=0; i<this.state.addresses.length; i++){
      if(this.state.addresses[i].id == newID){
        newAddress = this.state.addresses[i].sAddress
        newAddress2 = this.state.addresses[i].sAddress2
        newCity = this.state.addresses[i].sCity
        newState = this.state.addresses[i].sState
        newZip = this.state.addresses[i].sZip
        newCompany = this.state.addresses[i].sName
        
        i=this.state.contacts.length
      }
    }

    console.log(this.state.addresses)

    this.setState({
      deliveryID: newID,
      deliveryAddress1: newAddress,
      deliveryAddress2: newAddress2,
      deliveryCity: newCity,
      deliveryState: newState,
      deliveryZip: newZip,
      dCompany: newCompany
    })
  }

  createNewPrimaryContact = () => {
    this.setState({
      showAddContactModal: true,
      creating: "primary contact"
    })
  }

  createNewDeliveryContact = () => {
    this.setState({
      showAddContactModal: true,
      creating: "delivery contact"
    })
  }

  createNewBillingAddress = () => {
    this.setState({
      showAddAddressModal: true,
      creating: "billing address"
    })
  }

  createNewDeliveryAddress = () => {
    this.setState({
      showAddAddressModal: true,
      creating: "delivery address"
    })
  }

  handleShowAddAddressModal = () => {
    this.fetchData().then(
      () => {
        if(this.state.creating === "billing address"){
          this.setState({
            billingID: this.state.addresses[0].id,
            billingAddress1: this.state.addresses[0].sAddress,
            billingAddress2: this.state.addresses[0].sAddress2,
            billingCity: this.state.addresses[0].sCity,
            billingState: this.state.addresses[0].sState,
            billingZip: this.state.addresses[0].sZip
          })
        } else if(this.state.creating === "delivery address"){
          this.setState({
            deliveryID: this.state.addresses[0].id,
            deliveryAddress1: this.state.addresses[0].sAddress,
            deliveryAddress2: this.state.addresses[0].sAddress2,
            deliveryCity: this.state.addresses[0].sCity,
            deliveryState: this.state.addresses[0].sState,
            deliveryZip: this.state.addresses[0].sZip
          })
        }
  
  
        this.setState({
          showAddAddressModal: false
        })
      }
    )
  }

    handleShowAddContactModal = () => {
      this.fetchData().then(
        () => {
          if(this.state.creating === "primary contact"){
            this.setState({
              contactID: this.state.contacts[0].id,
              firstName: this.state.contacts[0].sFirstName,
              lastName: this.state.contacts[0].sLastName,
              phone: this.state.contacts[0].sPhone,
              email: this.state.contacts[0].sEmail,
            })
          } else if(this.state.creating === "delivery contact"){
            this.setState({
              deliveryContactID: this.state.contacts[0].id,
              dFirstName: this.state.contacts[0].sFirstName,
              dLastName: this.state.contacts[0].sLastName,
              dPhone: this.state.contacts[0].sPhone,
              dEmail: this.state.contacts[0].sEmail,
            })
          }
    
    
          this.setState({
            showAddContactModal: false
          })
        }
      )
  }

  updateDelivery = (event) => {
    this.setState({delivery: event.target.value}, this.calcRentalPeriod)    
  }

  updatePickup = (event) => {
    this.setState({pickup: event.target.value}, this.calcRentalPeriod)
  }

  calcRentalPeriod = () => {
    if(this.state.delivery !== "" && this.state.pickup !== "" && Date.parse(this.state.pickup) >= Date.parse(this.state.delivery)){
      let pickup = new Date(Date.parse(this.state.pickup)+1000*3600*24)
      let delivery = new Date(Date.parse(this.state.delivery)+1000*3600*24)
      let rentalPeriod = (pickup - delivery)/1000/3600/24+1
      let rentalUnits = ""
      let rentalPeriodDisplay = 0

      if(pickup.getDate() === delivery.getDate()-1 || (delivery.getDate() === 1 && pickup.getDate() >= 28)){
        rentalUnits = "Month"
        rentalPeriodDisplay = Math.round(rentalPeriod/30.5)
      } else if(rentalPeriod%7 === 0){
        rentalUnits = "Week"
        rentalPeriodDisplay = rentalPeriod/7
      } else {
        rentalUnits = "Day"
        rentalPeriodDisplay = rentalPeriod
      }
      
      this.setState({
        rentalPeriod: rentalPeriod,
        rentalUnits: rentalUnits,
        rentalPeriodDisplay: rentalPeriodDisplay
      })
    }
    
  }

  calcPickupDate = () => {
    if(this.state.delivery !== "" && this.state.rentalPeriodDisplay >=0){
      let pickup = {}
      let delivery = Date.parse(this.state.delivery)
      let period = parseInt(this.state.rentalPeriodDisplay)

      if(this.state.rentalUnits === "Day"){
        //Moment(this.props.dateCreated).utc().format('YYYY-MM-DD')
        pickup = new Date(delivery+(period-1)*3600*24*1000)
        
      } else if(this.state.rentalUnits === "Week"){
        pickup = new Date(delivery+(period*7-1)*3600*24*1000)
      } else if(this.state.rentalUnits === "Month"){
        delivery = new Date(delivery)
        console.log(delivery.getMonth())
        pickup = new Date(delivery.setMonth(delivery.getMonth()+period)-3600*24*1000)
      }

      pickup = pickup.toISOString().split('T')[0]
      
      this.setState({pickup: pickup})
    }
  }

  updateUnits = (event) => {
    this.setState({rentalUnits: event.target.value}, this.calcPickupDate)
  }

  updateRentalPeriod = (event) => {
    this.setState({rentalPeriodDisplay: event.target.value}, this.calcPickupDate)
  }

  updateDeliveryType = (event) => {
    this.setState({deliveryType: event.target.value})
  }

  selectTemplate = (getID) => {
    this.setState({templateSelected: this.state.templates[getID].id},
      this.fetchTemplateLines)
  }

  createNewCustomer = async (getName) => {

    try {
      
      const response = await Axios.post(
        "/customers/update-tool/add-company-name",
        {
          name: getName,
        },
      );

      console.log("Test")

      if(response){
        let newCustomer = Object.values(response.data.result[0].recordset[0])[0]

        console.log(newCustomer + " " + getName + " " + this.state.user)

        this.setState({
          customerID: newCustomer,
          customer: getName,
          rep: this.state.user,
          repName: whichRep(this.state.user), 
          brand: this.getBrandByRep(this.state.user),
          paymentTerms: "Credit Card",
          bCC: 1,
          bNet: 0,
          bCOD: 0,
        })
      }
      
    } catch (error) {
      console.error(error)
    }

  };

  render() {
    let repDropdown
    let billingDropdown
    let deliveryDropdown
    let contactDropdown
    let deliveryContactDropdown

    repDropdown = (
      <>
        { activeReps().map((rep) => (
          <Option value={rep.id} >
            {rep.name}
          </Option>
        ))}
      </>
    )

    billingDropdown = (
      <>
        { (!this.state.billingID > 0 && (<Option value={null}>No Address Selected</Option>))}
        { this.state.addresses.map((sql) => (
          <Option value={sql.id}>
            {sql.sName}, {sql.sAddress}, {sql.sCity}, {sql.sState}
          </Option>
        ))}
      </>
    )

    deliveryDropdown = (
      <>
        { (!this.state.deliveryID > 0 && (<Option value={null}>No Address Selected</Option>))}
        { this.state.addresses.map((sql, index) => (
          <Option value={sql.id}>
            {sql.sName}, {sql.sAddress}, {sql.sCity}, {sql.sState}
          </Option>
        ))}
      </>
    )

    contactDropdown = (
      <>
        { (!this.state.contactID > 0 && (<Option value={null}>No Contact Selected</Option>))}
        { this.state.contacts.map((sql, index) => (
          <Option value={sql.id}>
            {sql.sFirstName} {sql.sLastName},
            {sql.sPhone},
            {sql.sEmail}
          </Option>
        ))}
      </>
    )

    deliveryContactDropdown = (
      <>
        { (!this.state.deliveryContactID > 0 && (<Option value={null}>No Contact Selected</Option>))}
        { this.state.contacts.map((sql, index) => (
          <Option value={sql.id}>
            {sql.sFirstName} {sql.sLastName}, {sql.sPhone}, {sql.sEmail}
          </Option>
        ))}
      </>
    )



    return (
      <Wrapper>
        <FormWrapper>
            {this.state.showAddContactModal && (
              <AddContactModal
                handleShowAddContactModal={this.handleShowAddContactModal}
                nCompany={this.state.customerID}
              />
            )}
            {this.state.showAddAddressModal && (
              <AddAddressModal
                handleShowAddAddressModal={this.handleShowAddAddressModal}
                nCompany={this.state.customerID}
              />
            )}
          <Title>
            Create a New Quote
          </Title>
          <div>
            
            
            {((this.state.customerID > 0 && (
              <>
              <div>
                <ColumnHeader>
                  Customer 
                </ColumnHeader>
                <div style={{display: 'inline-block', padding: 10}}>
                  {this.state.customer}
                </div>
                <Button onClick={() => {this.setState({customer: "", customerID: 0})}}>Change</Button>
                <Button>
                <NavLink to={"/crm/master-viewer/" + this.state.customerID} style={{ color: '#fafafa'}}>
                  Go to Customer Profile
                </NavLink>
                </Button>
              </div>
                <div>
                <ColumnHeader>
                  Rep 
                </ColumnHeader> 
                    <Select onChange={(event) => {
                      this.setState({rep: parseInt(event.target.value), repName: whichRep(parseInt(event.target.value))})
                      console.log(whichRep(event.target.value))
                    }} value={this.state.rep}>
                    {repDropdown}
                  </Select>
                </div>
                <div>
                <ColumnHeader>
                  Brand 
                </ColumnHeader>
                 <Select onChange={(event) => { this.setState({brand: event.target.value})}} value={this.state.brand}>
                    <Option value="Rentacomputer.com">Rentacomputer.com</Option>
                    <Option value="CameraSecurityNow.com">CameraSecurityNow.com</Option>
                    <Option value="ComputerServiceNow.com">ComputerServiceNow.com</Option>
                </Select>
                </div>
                <div>
                <ColumnHeader>
              Source
            </ColumnHeader>
            
            <Select onChange={(event) => { this.setState({leadSource: event.target.value})}} value={this.state.leadSource}>
                    <Option value="Phone">Phone</Option>
                    <Option value="Email">Email</Option>
                    <Option value="Repeat">Repeat</Option>
                    <Option value="Text">Text/SMS</Option>
                    <Option value="LivePerson">Tawk.to Chat</Option>
                    <Option value="Walk-In">Walk-In</Option>
                    <Option value="Referral">Referral</Option>
                    <Option value="Prospect">Prospect</Option>
                </Select>
          </div>
          {(!this.state.showWizard && (
          
            <div>
              <Button onClick={() => (this.createNewQuote())}>
                Quick Quote Creation - Blank Address
              </Button>
              <Button onClick={() => (this.createNewQuoteDefaultAddresses())}>
                Quick Quote Creation - Use Default Address & Primary Contact
              </Button>
              <Button onClick={() => (this.quoteWizard())}>
                Quote Wizard
              </Button>
            </div>

          )) || (
            <>
            <div>
              <ColumnHeader>Billing Contact</ColumnHeader>
              <Select onChange={this.updatePrimaryContact} value={this.state.contactID}>
                {contactDropdown}
              </Select>
              <Button onClick={this.createNewPrimaryContact}>
                New Contact
              </Button>
            </div>
            <div>
              <ColumnHeader>Delivery Contact</ColumnHeader>
              <Select onChange={this.updateDeliveryContact} value={this.state.deliveryContactID}>
                {deliveryContactDropdown}

              </Select>
              
              <Button onClick={this.createNewDeliveryContact}>
                New Contact
              </Button>
            </div>
            <div>
              <ColumnHeader>Billing Address</ColumnHeader>
              <Select onChange={this.updateBillingAddress} value={this.state.billingID}>
                {billingDropdown}

              </Select>
              <Button onClick={this.createNewBillingAddress}>
                New Address
              </Button>
            </div>
            <div>
              <ColumnHeader>Delivery Address</ColumnHeader>
              <Select onChange={this.updateDeliveryAddress} value={this.state.deliveryID}>
                {deliveryDropdown}

              </Select>
              <Button onClick={this.createNewDeliveryAddress}>
                New Address
              </Button>
            </div>
            <div>
              <ColumnHeader>
                Payment Terms 
              </ColumnHeader>
              <div style={{display: 'inline-block'}}>
                {this.state.paymentTerms}
              </div>
            </div>
            <div>
              <ColumnHeader>
                Delivery Date 
              </ColumnHeader>
              <div style={{display: 'inline-block'}}>
                <input type="date" value={this.state.delivery} onChange={this.updateDelivery} style={{width:200}}/>
              </div>
            </div>
            <div>
              <ColumnHeader>
              Pickup Date 
              </ColumnHeader>
              <div style={{display: 'inline-block'}}>
                <input type="date" value={this.state.pickup} onChange={this.updatePickup} style={{width:200}}/>
              </div>
            </div>
            <div>
              <ColumnHeader>
                Rental Period 
              </ColumnHeader>
              <div style={{display: 'inline-block'}}>
                <input type="text" onChange={this.updateRentalPeriod} value={this.state.rentalPeriodDisplay} style={{width:200}}/>
              </div>
              <Select onChange={this.updateUnits} value={this.state.rentalUnits} style={{marginLeft: '1em'}}>
                <Option value="Day">Day</Option>
                <Option value="Week">Week</Option>
                <Option value="Month">Month</Option>
              </Select>
            </div>
            <div>
              <ColumnHeader>
                Delivery Type
              </ColumnHeader>
              <Select onChange={this.updateDeliveryType} value={this.state.deliveryType}>
                <Option value="Shipping">Shipping</Option>
                <Option value="Delivery">Delivery</Option>
              </Select>
            </div>
              <Button onClick={() => (this.createNewQuoteBlankLines())}>
                Create New Quote - Blank Lines
              </Button>
            </>
          )}
          <div>
            
            <div>
            {this.state.templateDataLoaded && (
              <>
              <ColumnHeader>
                Select a Template
              </ColumnHeader>
              {this.state.templates.map((template, index) => (
                
                <Button onClick={() => this.selectTemplate(index)}>
                  {template.sQuoteTitle}
                </Button>
              ))}
              </>
            )}
            </div>
          </div>

            
            
            {this.state.templateLoaded && (
              <div>
                <TableCell style={{display: 'inline-block', width: '10%', margin: '1em'}}>
                  Quantity
                </TableCell>
                <TableCell style={{display: 'inline-block', width: '60%'}}>
                  Description
                </TableCell>
                <TableCell style={{display: 'inline-block', width: '20%'}}>
                  Price
                </TableCell>
              {this.state.templateLines.map((line, index) => (
                <div>
                  <TableCell style={{display: 'inline-block', width: '10%'}}>
                    {line.nQuantity}
                  </TableCell>
                  <TableCell style={{display: 'inline-block', width: '60%'}}>
                    {line.sDescription}
                  </TableCell>
                  <TableCell style={{display: 'inline-block', width: '20%'}}>
                    {nQuoteTotalFormatter(line.nPrice)}
                  </TableCell>
                </div>
              ))}
              <Button onClick={() => (this.createNewQuoteFromTemplate())}>
                Create Quote From Template
              </Button>

              </div>
            )}


          {this.state.newQuote > 0 && (
          
            <div>
              <Button>
                <NavLink to={"/quote/" + this.state.newQuote} style={{ color: '#fafafa'}}>
                Go to quote - new CC
                </NavLink>
              </Button>
              <Button>
                <a href={"https://cc.cfrinc.us/quote.asp?id=" + this.state.newQuote} style={{ color: '#fafafa'}}>
                Go to quote - old CC
                </a>
              </Button>
              
              
            </div>

          )}
              </>
            )) || (
            <div>
            <ColumnHeader>
              Customer 
            </ColumnHeader>
            <SearchCustomer callback={this.updateCustomer} createNewCustomer={this.createNewCustomer}></SearchCustomer>
            
            </div>
            )
            )}
          </div>
          
        </FormWrapper>
      </Wrapper>
    );
  }
}

export default NewQuote;