import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Axios from 'axios';
import Moment from 'moment';
import Loader from 'react-loader-spinner';
import MediaQuery from 'react-responsive';
import { calcMilesFromLatLong, whichRep } from '../../../assets/functions/calculations';
import { CustomDateWrapper, SubTitle, Wrapper } from '../../styled/ui';
import { getSQLData, setSQLData } from '../../../assets/functions/fetch';
import HideShowSubTitle from '../../misc/HideShowSubTitle';
import { nullDateCheck } from '../../../assets/functions/system';
import SiteVisitsTable from './SiteVisits/SiteVisitsTable';
import moment from 'moment';
import { processSiteVisitData } from '../../../assets/functions/process/cam';
import SiteVisitNeedScheduledTable from './SiteVisits/SiteVisitNeedScheduledTable';
import SiteVisitHasPlannedDate from './SiteVisits/SiteVisitHasPlannedDate';
import QuoteNeedsSent from './SiteVisits/QuoteNeedsSent';
import SiteVisitQuoteOpen from './SiteVisits/SiteVisitQuoteOpen';
import SiteVisitQuoteDiscontinued from './SiteVisits/SiteVisitQuoteDiscontinued';

export default class SiteVisits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      changeSiteVisitMode: false,
      viewing: "Site Visit Needs Scheduled",
    };
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleSiteVisitDateChange = this.handleSiteVisitDateChange.bind(this)
    this.handleQuoteSentDateChange = this.handleQuoteSentDateChange.bind(this)
    this.handleUpdateTag = this.handleUpdateTag.bind(this)
    this.handleSaveTag = this.handleSaveTag.bind(this)
    this.handleNeedSiteVisit = this.handleNeedSiteVisit.bind(this)
    this.handleSiteVisitPlannedDateChange = this.handleSiteVisitPlannedDateChange.bind(this)
  }

  fetchData = async () => {

    try {
      const fetch = await getSQLData("cam/siteVisits", {})
      
      if(fetch){

        let data = fetch.data.data[0].recordset    
        let query = fetch.query
        
        
        this.setState({
          data: data,
          query: query,
        }, () => this.processData())
      }
         
    } catch (error) {
      console.error(error);
    }
  }

  processData = () => {
    let data = this.state.data

    data = processSiteVisitData(data)

    console.log(data);

    this.setState({
      data: data,
      loaded: true,
    })
  }

  componentDidMount() {
    window.addEventListener('keydown',(event) => {
      if(event.key === '`'){
        this.setState({changeSiteVisitMode: !this.state.changeSiteVisitMode})
      }
    })
    this.fetchData();
  }

  handleDateChange = (event, id, paramName, arrayName) => {
    let data = this.state.data

    data[arrayName][id][paramName] = event.target.value

    this.setState({data: data})
  };

  handleUpdateTag(getTag, getIndex, arrayName) {
    let data = this.state.data

    data[arrayName][getIndex].sQuoteTags = getTag
    data[arrayName][getIndex].changed = true

    this.setState({data: data})
  }

  handleSiteVisitDateChange = async (date, id) => {
    try {
      const post = await setSQLData("cam/setSiteVisitDate", {
        quoteNum: id,
        siteVisit: nullDateCheck(date),
      })
      const postSiteVisit = await setSQLData("cam/setNeedSiteVisit", {
        quoteNum: id,
        needSiteVisit: 0,
      })
      
      if(post && postSiteVisit){
        
      }
      
    } catch (error) {
      console.error(error);
    }
  };
  handleSiteVisitPlannedDateChange = async (date, id) => {
    try {
      const post = await setSQLData("cam/setSiteVisitPlannedDate", {
        quoteNum: id,
        siteVisit: nullDateCheck(date),
      })
      
      if(post){
        
      }
      
    } catch (error) {
      console.error(error);
    }
  };
  handleQuoteSentDateChange = async (date, id) => {
    try {
      const fetch = await setSQLData("cam/setQuoteSentDate", {
        quoteNum: id,
        quoteSent: nullDateCheck(date),
      })
      
      if(fetch){
        
      }
      
    } catch (error) {
      console.error(error);
    }
  };
  handleSaveTag = async (tag, id) => {
    try {
      const fetch = await setSQLData("cam/setQuoteTag", {
        quoteNum: id,
        quoteTag: tag,
      })
      
      if(fetch){
        
      }
      
    } catch (error) {
      console.error(error);
    }
  };
  handleNeedSiteVisit = async (needSiteVisit, id) => {
    if(this.state.changeSiteVisitMode){
      try {
        const fetch = await setSQLData("cam/setNeedSiteVisit", {
          quoteNum: id,
          needSiteVisit: needSiteVisit ? 1 : 0,
        })
        
        if(fetch){
          
        }
        
      } catch (error) {
        console.error(error);
      }
    }
    
  };


  changeView(view){
    this.setState({viewing: view})
  }

  render() {

    return (
      <>
      {this.state.loaded && (

      
      <Wrapper amount={this.state.data.length}>
        <div>
          <button onClick={()=>this.changeView("Site Visit Needs Scheduled")}>Site Visit Needs Scheduled ({this.state.data.needSiteVisitNoDate.length})</button>
          <button onClick={()=>this.changeView("Site Visit Has Planned Date")}>Site Visit Has Planned Date ({this.state.data.needSiteVisitHasDate.length})</button>
          <button onClick={()=>this.changeView("Quote Needs Sent")}>Quote Needs Sent ({this.state.data.siteVisitCompleteNeedsSent.length})</button>
          <button onClick={()=>this.changeView("Site Visit Quote Open")}>Site Visit Quote Open ({this.state.data.quoteSentOpen.length})</button>
          <button onClick={()=>this.changeView("Site Visit Quote Discontinued")}>Site Visit Quote Discontinued ({this.state.data.quoteSentDiscontinued.length})</button>
          {/*<button onClick={()=>this.changeView("Quotes Open No Site Visit")}>Quotes Open No Site Visit</button>
          <button onClick={()=>this.changeView("Quotes Discontinued No Site Visit")}>Quotes Discontinued No Site Visit</button>*/}
        </div>
        <div>
          <button onClick={()=>this.setState({loaded:false},()=>this.fetchData())}>Refresh Data</button>
        </div>
        {!this.state.hideAll && (
          <>
            {this.state.viewing == "Site Visit Needs Scheduled" && (
              <SiteVisitNeedScheduledTable 
                data={this.state.data.needSiteVisitNoDate} 
                handleDateChange={this.handleDateChange}
                handleSiteVisitDateChange={this.handleSiteVisitDateChange}
                handleQuoteSentDateChange={this.handleQuoteSentDateChange}
                handleUpdateTag={this.handleUpdateTag}
                handleSaveTag={this.handleSaveTag}
                handleNeedSiteVisit={this.handleNeedSiteVisit}
                handleSiteVisitPlannedDateChange={this.handleSiteVisitPlannedDateChange}
                changeSiteVisitMode={this.state.changeSiteVisitMode}
              />
            )}
            {this.state.viewing == "Site Visit Has Planned Date" && (
              <SiteVisitHasPlannedDate 
                data={this.state.data.needSiteVisitHasDate} 
                handleDateChange={this.handleDateChange}
                handleSiteVisitDateChange={this.handleSiteVisitDateChange}
                handleQuoteSentDateChange={this.handleQuoteSentDateChange}
                handleUpdateTag={this.handleUpdateTag}
                handleSaveTag={this.handleSaveTag}
                handleNeedSiteVisit={this.handleNeedSiteVisit}
                handleSiteVisitPlannedDateChange={this.handleSiteVisitPlannedDateChange}
                changeSiteVisitMode={this.state.changeSiteVisitMode}
              />
            )}
            {this.state.viewing == "Quote Needs Sent" && (
              <QuoteNeedsSent 
                data={this.state.data.siteVisitCompleteNeedsSent} 
                handleDateChange={this.handleDateChange}
                handleSiteVisitDateChange={this.handleSiteVisitDateChange}
                handleQuoteSentDateChange={this.handleQuoteSentDateChange}
                handleUpdateTag={this.handleUpdateTag}
                handleSaveTag={this.handleSaveTag}
                handleNeedSiteVisit={this.handleNeedSiteVisit}
                handleSiteVisitPlannedDateChange={this.handleSiteVisitPlannedDateChange}
                changeSiteVisitMode={this.state.changeSiteVisitMode}
              />
            )}
            {this.state.viewing == "Site Visit Quote Open" && (
              <SiteVisitQuoteOpen 
                data={this.state.data.quoteSentOpen} 
                handleDateChange={this.handleDateChange}
                handleSiteVisitDateChange={this.handleSiteVisitDateChange}
                handleQuoteSentDateChange={this.handleQuoteSentDateChange}
                handleUpdateTag={this.handleUpdateTag}
                handleSaveTag={this.handleSaveTag}
                handleNeedSiteVisit={this.handleNeedSiteVisit}
                handleSiteVisitPlannedDateChange={this.handleSiteVisitPlannedDateChange}
                changeSiteVisitMode={this.state.changeSiteVisitMode}
              />
            )}
            {this.state.viewing == "Site Visit Quote Discontinued" && (
              <SiteVisitQuoteDiscontinued 
                data={this.state.data.quoteSentDiscontinued} 
                handleDateChange={this.handleDateChange}
                handleSiteVisitDateChange={this.handleSiteVisitDateChange}
                handleQuoteSentDateChange={this.handleQuoteSentDateChange}
                handleUpdateTag={this.handleUpdateTag}
                handleSaveTag={this.handleSaveTag}
                handleNeedSiteVisit={this.handleNeedSiteVisit}
                handleSiteVisitPlannedDateChange={this.handleSiteVisitPlannedDateChange}
                changeSiteVisitMode={this.state.changeSiteVisitMode}
              />
            )}
          </>
        )}  
      </Wrapper>
      )}
      </>
    );
  }
}
