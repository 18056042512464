import React, { Component } from 'react';
import styled from 'styled-components';
import Axios from 'axios';
import ReactTooltip from 'react-tooltip';
import Cookies from 'js-cookie';
import { NavLink } from 'react-router-dom';
import SearchVendor from '../misc/SearchVendor';
import axios from 'axios';
import HeatMapNew from '../misc/HeatMapNew';
import { Button, FormWrapper, Modal, ModalContent, Option, SearchWrapper, Select, Title } from '../styled/ui';
import { getSQLData } from '../../assets/functions/fetch';
import { calcMilesFromLatLong } from '../../assets/functions/calculations';

class TASearch extends Component {

  state = {
    user: '',
    loaded: false,
    bAdmin: false,
    flashError: '',
    redirectTo: "",
    redirect: false,
    isLoaded: false,
    zipcodes: [],
    skillsets: [],
    selectedSkills: [],
    suppliers: [],
    zip: 0,
    latitude: 0,
    longitude: 0,
    range: 0,
    searchString: "",
    viewing: "skill",
    results: 0,
    noRFQ: 0,
    noPO: 0,
    maxScore: 1,
    usOnly: true,
    nonusOnly: false,
    categories: [],
    items: [],
    selectedCategories: [],
    showHeatMap: false,
    allSuppliers: [],
    sort: 'dtDate',
    sortOrder: 'desc',
    newName: '',
    newID: -1,
    heatMapReady: false,
    locations: [],
    oldSearch: false,
    heatMapLocations: [],
  };

  // Async Axios
  fetchUser = async () => {
    try {
      const passport = await Axios.get('/userSession', {
        credentials: 'include',
      });
      if (passport.data.passport) {
        this.setState({
          user: passport.data.passport.user,
          repView: passport.data.passport.user
        });

      }
      const response = await Axios.get('/user', {
        credentials: 'include',
      });
      if (
        response.data.result[0].recordset[0].sTTATitle === 'Developer' ||
        response.data.result[0].recordset[0].sTTATitle === 'CEO'
      ) {
        this.setState({
          bAdmin: !this.state.bAdmin,
        });
        Cookies.set('sTTATitle', 'admin');
      } else {
        if (response.data.result[0].recordset[0].bAdmin === true) {
          this.setState({
            bAdmin: !this.state.bAdmin,
          });
          Cookies.set('sTTATitle', 'admin');
        }
      }
      if(response){
        this.fetchData();
      }
    } catch (error) {
      console.error(error);
    }
  };

  fetchData = async () => {

      try {
        const fetchZip = await getSQLData("supplier/getZipCodes")
        const fetchSkills = await getSQLData("supplier/getSkillsets")
        const fetchItemCategories = await getSQLData("inventory/getItemCategories")
        const fetchItems = await getSQLData("inventory/getItemList")

        if (fetchZip && fetchSkills && fetchItemCategories && fetchItems){
          let zipcodes = fetchZip.data.data[0].recordset
          let skillsets = fetchSkills.data.data[0].recordset
          let categories = fetchItemCategories.data.data[0].recordset
          let items = fetchItems.data.data[0].recordset

          this.setState({
            zipcodes: zipcodes, 
            skillsets: skillsets, 
            categories: categories, 
            items: items,
          },  () => this.prepareData())
        }
      } catch (error) {
        console.error(error);
      }
  }

  findVendorBySkills = async (getSearch) => {

    try {
      const response = await Axios.get("/find-vendor-by-skills", {
        params: {
          search: getSearch
        },
        headers: { "Content-Type": "application/json" },
      });
      if (response){
        console.log(response.data.result)
        this.setState({
          suppliers: response.data.result[0].recordsets[0],
          locations: response.data.result[1].recordsets[0],
        }, () => this.calcSupplierDistances())
      }
    } catch (error) {
      console.error(error);
    }
}
fetchItemVendors = async (getItem) => {

  try {
    const response = await Axios.get("/find-vendor-by-item", {
      params: {
        item: getItem
      },
      headers: { "Content-Type": "application/json" },
    });
    if (response){
      console.log(response.data.result[0].recordset)
      this.setState({suppliers: response.data.result[0].recordset}, () => this.calcSupplierDistances())
    }
  } catch (error) {
    console.error(error);
  }
}

  prepareData(){
    let skillsets = []
    let skills = this.state.skillsets.length
    let maxID = this.state.skillsets[skills-1].id
    let skillSummary = []

    for(let i=0; i<=maxID; i++){
      skillsets[i] = {title: "", active: false, supplier: false}
      skillSummary[i] = {title: "", active: false, supplier: false, supplierCount: 0, supplierPO: 0, supplierResponse: 0, supplierCleanup: 0}
    }

    for(let i=0; i<this.state.skillsets.length; i++){
      skillsets[this.state.skillsets[i].id] = {title: "", active: false, supplier: false, supplierCount: 0, supplierPO: 0, supplierResponse: 0, supplierCleanup: 0}
      skillsets[this.state.skillsets[i].id].title = this.state.skillsets[i].sTitle
      skillsets[this.state.skillsets[i].id].active = this.state.skillsets[i].bActive
      skillsets[this.state.skillsets[i].id].id = this.state.skillsets[i].id
      skillSummary[this.state.skillsets[i].id] = skillsets[this.state.skillsets[i].id]
    }
    
    console.log(skillSummary)
    skillsets.sort((a,b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0))

    this.setState({skillsets: skillsets})

    let items = []
    let categories = []

    for(let i=0; i<this.state.items.length; i++){
      items[this.state.items[i].id] = this.state.items[i]
    }

    for(let i=0; i<this.state.categories.length; i++){
      categories[this.state.categories[i].id] = this.state.categories[i]
    }

    

    this.setState({items: items, categories: categories, skillSummary: skillSummary, loaded: true})
  }

  prepareSupplierSummary() {
    let allSuppliers = this.state.allSuppliers
    let skillSummary = this.state.skillSummary
    allSuppliers.cleanup = 0
    allSuppliers.cleanupRAC = 0
    allSuppliers.noSkills = 0
    allSuppliers.POs = 0
    allSuppliers.responses = 0
    allSuppliers.POsRAC = 0
    allSuppliers.responsesRAC = 0
    allSuppliers.active = 0
    allSuppliers.activeRAC = 0

    let rentalSkills = [1,5,24,33,34,35,36,157]

    console.log(allSuppliers)

    for(let i=0; i<allSuppliers.length; i++){
      let rentalSupplier = false
      let activeSupplier = false

      allSuppliers[i].skills = allSuppliers[i].sSkillset.split(",")
      allSuppliers[i].rentalSkills = 0
      allSuppliers[i].missingSkills = []
      allSuppliers[i].missingSkillsText = ""

      if(allSuppliers[i].skills[0] === '' || !allSuppliers[i].skills || allSuppliers[i].skills.length === 0) {
        allSuppliers[i].skills = []
        allSuppliers[i].noSkills += 1
      } else {
        for(let j=0; j<allSuppliers[i].skills.length; j++){
          allSuppliers[i].skills[j] = parseInt(allSuppliers[i].skills[j])
          let skillID = allSuppliers[i].skills[j]
          

          skillSummary[skillID].supplierCount = skillSummary[skillID].supplierCount + 1

          if(allSuppliers[i].nPOs > 0){
            skillSummary[skillID].supplierPO += 1
          }

          if(allSuppliers[i].nRFQs - allSuppliers[i].nIgnored > 0){
            skillSummary[skillID].supplierResponse += 1
          }

          if(allSuppliers[i].nPOs === 0 && allSuppliers[i].nRFQs === 0){
            skillSummary[skillID].supplierCleanup += 1        
          }

          if(skillSummary[skillID].active && skillID != 8 && skillID != 68){
            rentalSupplier=true
          }
          if(skillSummary[skillID].active){
            activeSupplier = true
          }
        }
        if(rentalSupplier){allSuppliers.activeRAC += 1}
        if(activeSupplier){allSuppliers.active += 1}
        if(allSuppliers[i].nPOs > 0 && activeSupplier){
          allSuppliers.POs += 1
          if(rentalSupplier){allSuppliers.POsRAC += 1}
        }
        if(allSuppliers[i].nRFQs - allSuppliers[i].nIgnored > 0 && activeSupplier){
          allSuppliers.responses += 1
          if(rentalSupplier){allSuppliers.responsesRAC += 1}
        }
        if(allSuppliers[i].nPOs === 0 && allSuppliers[i].nRFQs === 0 && activeSupplier){
          allSuppliers.cleanup += 1
          if(rentalSupplier){allSuppliers.cleanupRAC += 1}
        }
      }

      for(let j=0; j<rentalSkills.length; j++){
        if(allSuppliers[i].skills.includes(rentalSkills[j])){
          allSuppliers[i].rentalSkills++
        }else{
          allSuppliers[i].missingSkills.push(this.state.skillSummary[rentalSkills[j]].title)
          allSuppliers[i].missingSkillsText = allSuppliers[i].missingSkillsText + this.state.skillSummary[rentalSkills[j]].title + ", "
        }
      }
    }
    console.log(skillSummary, this.state.skillsets, this.state.skillSummary)

    this.setState({allSuppliers: allSuppliers, skillSummary: skillSummary}, () => this.calcAllSupplierDistances())
  }

  componentDidMount() {
    this.fetchUser();
    
  }

  navigateTo = (getData) => {
    let redirectTo = "/srm/master-viewer/" + getData.id
    this.setState({redirect: true, redirectTo: redirectTo, vendorName: getData.sCompany})
    console.log(this.state.redirect)
  }

  selectSkills = (options) => {
    this.setState({suppliers: []})
    let selectedSkills = []
    let searchString = ""

    

    for (let i=0; i<options.length; i++){
      if (options[i].selected){
        selectedSkills.push(options[i].value)
        let length = options[i].value.length
        let subString = "(r1.sSkillset LIKE '" + options[i].value + "' OR "
        subString = subString + "LEFT(r1.sSkillset," + length+1 + ") LIKE '" + options[i].value + ",%' OR "
        subString = subString + "RIGHT(r1.sSkillset," + length+1 + ") LIKE '%," + options[i].value + "' OR "
        subString = subString + "r1.sSkillset LIKE '%," + options[i].value + ",%')"

        if(searchString === ""){
          searchString = subString + " "
        } else {
          searchString = searchString + "AND " + subString + " "
        }
      }
    }     

    if(searchString !== ""){
      console.log(searchString)
      this.findVendorBySkills(searchString)
    }

    this.setState({selectedSkills: selectedSkills, searchString: searchString})
  }

  updateZip = (getZip) => {
    this.setState({zip: getZip, suppliers: []}, () => {this.calcDistances()})

    if(this.state.searchString !== ""){
      this.findVendorBySkills(this.state.searchString)
    }
  }

  calcDistances = () => {
      let coords = this.getLatLong(this.state.zip)

      this.setState({latitude: coords.latitude, longitude: coords.longitude})
  }

  getLatLong = (getZip) => {
    let latitude = 0
    let longitude = 0

    if(getZip){    
      if(getZip.length === 5 && parseInt(getZip)>0 && parseInt(getZip)<=99999){
        
        for(let i=0; i< this.state.zipcodes.length; i++){
          if(parseInt(this.state.zipcodes[i].sZipCode) === parseInt(getZip)){
            latitude = this.state.zipcodes[i].sLatitude
            longitude = this.state.zipcodes[i].sLongitude
            i=this.state.zipcodes.length
          }
        }
      }
    }
    return {latitude: latitude, longitude: longitude}
  }

  calcSupplierDistances = () => {
    let suppliers = this.state.suppliers
    let locations = this.state.locations
    let numLocations = locations.length
    let coords = this.getLatLong(this.state.zip)

    let tempLocations = []

    console.log(locations)
    for(let i=0; i<numLocations; i++){

      if(locations[i].sZip !== null){
        locations[i].coords = this.getLatLong(locations[i].sZip)
        console.log(coords, locations[i].coords)
        locations[i].distance = calcMilesFromLatLong(coords, locations[i].coords)
        let newLocation = {}
        for(let key in locations[i]){
          newLocation[key] = locations[i][key]
        }
        tempLocations.push(newLocation)
      }
    }
    locations = tempLocations
    console.log(locations)

    let results = 0
    let noPO = 0
    let noRFQ = 0
    let inactive = 0
    let major = 0

    let maxScore = 0.01

    for(let i=0; i<suppliers.length; i++){
      locations.push(suppliers[i].id)

      suppliers[i].locations = []
      for(let j=0; j<locations.length; j++){
        if(locations[j].nCompany === suppliers[i].id){
          suppliers[i].locations.push({
            zip: locations[j].sZip,
            distance: locations[j].distance,
            lat: locations[j].coords.latitude, 
            lng: locations[j].coords.longitude, 
            serviceArea: locations[j].nServiceArea,
          })
        }
      }
      suppliers[i].closestLocation = {}
      for(let j=0; j<suppliers[i].locations.length; j++){
        if(suppliers[i].locations[j].distance > 0 && (Object.keys(suppliers[i].closestLocation).length === 0 || suppliers[i].locations[j].distance < suppliers[i].closestLocation.distance)){
          suppliers[i].closestLocation = suppliers[i].locations[j]
        }
      }
      
      suppliers[i].inRange = (this.state.zip === 0 || suppliers[i].closestLocation.distance <= suppliers[i].closestLocation.serviceArea + this.state.range)
      console.log(suppliers[i])

      let coords = this.getLatLong(suppliers[i].sZip1)

      suppliers[i].latitude = coords.latitude
      suppliers[i].lat = coords.latitude
      suppliers[i].longitude = coords.longitude
      suppliers[i].lng = coords.longitude

      suppliers[i].distance = calcMilesFromLatLong({latitude: this.state.latitude, longitude: this.state.longitude}, coords)

      if(
        suppliers[i].distance <= this.state.range || this.state.zip <= 0 &&
        ((this.state.usOnly && suppliers[i].sState1) || (this.state.nonusOnly && !suppliers[i].sState1) || (!this.state.usOnly && !this.state.nonusOnly))
        ){
        results = results + 1
        
        if(suppliers[i].nPOs === 0){
          noPO = noPO + 1
        }
        if(suppliers[i].nRFQs === 0){
          noRFQ = noRFQ + 1
        }

        if(suppliers[i].nPOs === 0 && (suppliers[i].nRFQs === 0 || suppliers[i].nRFQs === suppliers[i].nIgnored)){
          inactive = inactive + 1
        }

        if(suppliers[i].nPOs >= 4 && suppliers[i].nRFQs - suppliers[i].nIgnored >= 12){
          major = major + 1
          suppliers[i].major = true
        }
      }

      suppliers[i].ratingScore = .5

      if (suppliers[i].nRating>0){
        suppliers[i].ratingScore = Math.min(1,suppliers[i].nRating/5)
      }
      
      if(suppliers[i].nRFQs === 0){
        suppliers[i].ignoredScore = .5
      } else {
        suppliers[i].ignoredScore = Math.sqrt(1-suppliers[i].nIgnored/suppliers[i].nRFQs)
      }
      
      suppliers[i].responseScore = 1-Math.min(Math.log(suppliers[i].nAvgResponse/60+2)-1,6)/6
      suppliers[i].poScore = Math.log10(suppliers[i].nPOs+1)+1
      suppliers[i].issueAdj = suppliers[i].nIssues/100
      suppliers[i].majorAdj = suppliers[i].nIssuesMajor/10
      
      if(parseInt(this.state.selectedSkills[0]) === 68){
        suppliers[i].score = suppliers[i].ratingScore * suppliers[i].poScore
      }else{
        suppliers[i].score = suppliers[i].ratingScore * suppliers[i].ignoredScore * suppliers[i].responseScore * suppliers[i].poScore - suppliers[i].issueAdj - suppliers[i].majorAdj
      }
   
      maxScore = Math.max(maxScore, suppliers[i].score)
    }

    suppliers.sort((a,b) => (a.score < b.score ? 1 : (a.score > b.score ? -1 : 0)))

    let heatMapLocations = []
    for(let i=0; i<suppliers.length; i++){
      suppliers[i].adjScore = suppliers[i].score / maxScore
      for(let j=0; j<suppliers[i].locations.length; j++){
        suppliers[i].locations[j].score = suppliers[i].adjScore
        if(suppliers[i].inRange){heatMapLocations.push(suppliers[i].locations[j])}
      }
    }

    console.log(heatMapLocations)

    this.setState({
      suppliers: suppliers, 
      results: results,
      noPO: noPO,
      noRFQ: noRFQ,
      inactive: inactive,
      maxScore: maxScore,
      major: major,
      locations: locations,
      heatMapLocations: heatMapLocations,
    })
  }

  calcSuppliersInRange = () => {
    let suppliers = this.state.suppliers

    for (let i=0; i<suppliers.length; i++){
      suppliers[i].inRange = (this.state.zip === 0 || (suppliers[i].closestLocation.serviceArea > 0 && suppliers[i].closestLocation.distance <= suppliers[i].closestLocation.serviceArea + this.state.range))
      console.log(suppliers[i].closestLocation.serviceArea + this.state.range)
    }
    
    this.setState({suppliers: suppliers})
  }

  calcAllSupplierDistances = () => {
    let suppliers = this.state.allSuppliers
    console.log(suppliers)

    let results = 0
    let noPO = 0
    let noRFQ = 0
    let inactive = 0
    let major = 0

    let maxScore = 0.01

    for(let i=0; i<suppliers.length; i++){
      let coords = this.getLatLong(suppliers[i].sZip1)

      suppliers[i].latitude = coords.latitude
      suppliers[i].lat = coords.latitude
      suppliers[i].longitude = coords.longitude
      suppliers[i].lng = coords.longitude

      suppliers[i].distance = calcMilesFromLatLong({latitude: this.state.latitude, longitude: this.state.longitude}, coords)

      if(
        suppliers[i].distance <= this.state.range || this.state.zip <= 0 &&
        ((this.state.usOnly && suppliers[i].sState1) || (this.state.nonusOnly && !suppliers[i].sState1) || (!this.state.usOnly && !this.state.nonusOnly))
        ){
        results = results + 1
        
        if(suppliers[i].nPOs === 0){
          noPO = noPO + 1
        }
        if(suppliers[i].nRFQs === 0){
          noRFQ = noRFQ + 1
        }

        if(suppliers[i].nPOs === 0 && (suppliers[i].nRFQs === 0 || suppliers[i].nRFQs === suppliers[i].nIgnored)){
          inactive = inactive + 1
        }

        if(suppliers[i].nPOs >= 4 && suppliers[i].nRFQs - suppliers[i].nIgnored >= 12){
          major = major + 1
          suppliers[i].major = true
        }
      }

      suppliers[i].ratingScore = .5

      if (suppliers[i].nRating>0){
        suppliers[i].ratingScore = Math.min(1,suppliers[i].nRating/5)
      }
      
      if(suppliers[i].nRFQs === 0){
        suppliers[i].ignoredScore = .5
      } else {
        suppliers[i].ignoredScore = Math.sqrt(1-suppliers[i].nIgnored/suppliers[i].nRFQs)
      }
      
      suppliers[i].responseScore = 1-Math.min(Math.log(suppliers[i].nAvgResponse/60+2)-1,6)/6
      suppliers[i].poScore = Math.log10(suppliers[i].nPOs+1)+1
      suppliers[i].issueAdj = suppliers[i].nIssues/100
      suppliers[i].majorAdj = suppliers[i].nIssuesMajor/10
    
      suppliers[i].score = suppliers[i].ratingScore * suppliers[i].ignoredScore * suppliers[i].responseScore * suppliers[i].poScore - suppliers[i].issueAdj - suppliers[i].majorAdj
      suppliers[i].score = suppliers[i].score + suppliers[i].rentalSkills 
    
      maxScore = Math.max(maxScore, suppliers[i].score)
    }

    suppliers.sort((a,b) => (a.score < b.score ? 1 : (a.score > b.score ? -1 : 0)))

    for(let i=0; i<suppliers.length; i++){
      suppliers[i].adjScore = suppliers[i].score / maxScore
    }

    this.setState({
      allSuppliers: suppliers, 
      results: results,
      noPO: noPO,
      noRFQ: noRFQ,
      inactive: inactive,
      maxScore: maxScore,
      major: major,
      allSuppliersReady: true,
    })
  }

  sortData(getSort) {
    let data = this.state.data
    let sortOrder = "desc"

    if (getSort === this.state.sort){
      if(this.state.sortOrder === "asc"){
        sortOrder = "desc"
        data.sort((a,b) => (a[getSort] < b[getSort]) ? 1 : ((b[getSort] < a[getSort]) ? -1 : 0))
        this.setState({sortOrder: sortOrder, data: data, sort: getSort})
      } else {
        sortOrder = "asc"
        data.sort((a,b) => (a[getSort] > b[getSort]) ? 1 : ((b[getSort] > a[getSort]) ? -1 : 0))
        this.setState({sortOrder: sortOrder, data: data, sort: getSort})
      }
    } else {
      sortOrder = "desc"
      data.sort((a,b) => (a[getSort] < b[getSort]) ? 1 : ((b[getSort] < a[getSort]) ? -1 : 0))
      this.setState({sortOrder: sortOrder, data: data, sort: getSort})
    }
  }

  selectCategory = (options) => {
    let selectedCategories = []
    let selectedCategory = {}

    for (let i=0; i<options.length; i++){
      if (options[i].selected){
        selectedCategories.push(parseInt(options[i].value))
        
      }
    }

    if(selectedCategories.length === 1){
      selectedCategory = this.state.categories[selectedCategories[0]]
    }

    this.setState({selectedCategories: selectedCategories, selectedCategory: selectedCategory})
  }

  selectItem = (event) => {
    this.setState({ selectedItemID: event.target.value, selectedItem: this.state.items[event.target.value], itemChanged: false}, () => this.fetchItemVendors(event.target.value))
  }

  sortBy(getSort){
    let sortOrder = this.state.sortOrder
    let sort = this.state.sort
    let skillSummary = this.state.skillSummary
    if(sort === getSort){
      if(sortOrder === "asc"){sortOrder = "desc"}else{sortOrder = "asc"}
    } else {
      sortOrder = "desc" 
      sort = getSort
    }

    if(sortOrder === 'desc'){
      skillSummary = skillSummary.sort(function (a, b) {
        return b[sort] > a[sort] ? 1 : b[sort] < a[sort] ? -1 : 0;
      });
    } else {
      skillSummary = skillSummary.sort(function (a, b) {
        return b[sort] > a[sort] ? -1 : b[sort] < a[sort] ? 1 : 0;
      });
    }
    
    console.log(sort, sortOrder, skillSummary)

    this.setState({sortOrder: sortOrder, sort: sort, skillSummary: skillSummary})
  }

  createNewVendor(){
    axios.post('/srm/new-vendor', {
        name: this.state.newName
      })
    .then((response) => {
      this.setState({
        newID: response.data.id,
        newName: ''
      })
    });

  }

  render() {

    return (
      
      <SearchWrapper>
        
        <FormWrapper>
          <Title>
            Search the Tech Army
          </Title>
          
          {this.state.loaded && (
            <>
          <div>
            <Button onClick={() => this.setState({viewing: "name"})}>Search by Name or ID</Button>
            <Button onClick={() => this.setState({viewing: "skill"})}>Search by Skillset and Zip Code</Button>
            <Button onClick={() => this.setState({viewing: "item"})}>Search by Item and Zip Code</Button>
          </div>
          {/*<div>
            <Button onClick={() => this.setState({viewing: "summary"}, () => this.prepareSupplierSummary())}>Vendor Summary</Button>
          </div>*/}
          <div>
            <Button onClick={() => this.setState({viewing: "new"})}>New Vendor</Button>
          </div>
          {this.state.viewing === "summary" && (
            <>
            <table>
              <thead>
                <td>Type</td>
                <td>All</td>
                <td>RAC</td>
              </thead>
              <tr>
                <td>Active</td>
                <td>{this.state.allSuppliers.active}</td>
                <td>{this.state.allSuppliers.activeRAC}</td>
              </tr>
              <tr>
                <td>Cleanup</td>
                <td>{this.state.allSuppliers.cleanup}</td>
                <td>{this.state.allSuppliers.cleanupRAC}</td>
              </tr>
              <tr>
                <td>With POs</td>
                <td>{this.state.allSuppliers.POs}</td>
                <td>{this.state.allSuppliers.POsRAC}</td>
              </tr>
              <tr>
                <td>With Responses</td>
                <td>{this.state.allSuppliers.responses}</td>
                <td>{this.state.allSuppliers.responsesRAC}</td>
              </tr>
            </table>
            <div>
              
            </div>

            <table>
              <thead>
                  <td onClick={() => this.sortBy("title")}>Skill</td>
                  <td onClick={() => this.sortBy("supplierCount")} data-tip="# of Active Suppliers">Suppliers<ReactTooltip /></td>
                  <td onClick={() => this.sortBy("supplierPO")} data-tip="# of Suppliers With Closed POs in the Last Year">POs<ReactTooltip /></td>
                  <td onClick={() => this.sortBy("supplierResponse")} data-tip="# of Suppliers With RFQ Responses in the Last Year">RFQ Responses<ReactTooltip /></td>
                  <td onClick={() => this.sortBy("supplierCleanup")} data-tip="Active Vendors with no Closed POs and no RFQ Responses in the Last Year">Cleanup<ReactTooltip /></td>
              </thead>
            {this.state.skillSummary.map((skill,index) => (
              <>
                {(skill.active) && (
                  <tr>
                    <td>{skill.title}</td>
                    <td>{skill.supplierCount}</td>
                    <td>{skill.supplierPO}</td>
                    <td>{skill.supplierResponse}</td>
                    <td>{skill.supplierCleanup}</td>
                  </tr>
                )}
              </>
            ))}
            </table>
            </>
          )}
          {this.state.viewing === "best" && (
            <div>            
              <div>
                Zip: 
                <input 
                  onChange={(event) => this.updateZip(event.target.value)} 
                  value={this.state.zip}>
                </input>
                  &nbsp;
                <input 
                  type='checkbox' 
                  checked={this.state.usOnly} 
                  onChange={() => this.setState({usOnly: !this.state.usOnly, nonusOnly: (this.state.usOnly ? this.state.nonusOnly : false)})}>
                </input> US Only
                &nbsp;
                <input 
                  type='checkbox' 
                  checked={this.state.nonusOnly} 
                  onChange={() => this.setState({nonusOnly: !this.state.nonusOnly, usOnly: (this.state.nonusOnly ? this.state.usOnly : false)})}>
                </input> Non-US Only
              </div>
              <div>Range: <input onChange={(event) => this.setState({range: parseInt(event.target.value)})} value={this.state.range}></input> miles</div>
              {this.state.heatMapReady && (<div><button onClick={() => this.setState({showHeatMap: !this.state.showHeatMap})}>Show Heat Map</button></div>)}

            {this.state.allSuppliers.length >0 && (
            
            <div>
            <table>
            <thead>
                <td width={40}>Score</td>
                <td width={40}>Company</td>
                <td width={100}>Location</td>
                {this.state.zip > 0 && (<td width={40} data-tip="Miles from vendor to search zip code.">Distance<ReactTooltip /></td>)}
                <td data-tip="# of Closed POs with this vendor over the last year.">POs<ReactTooltip /></td>
                <td data-tip="# of RFQs sent to the vendor over the last year.">RFQs<ReactTooltip /></td>
                <td data-tip="% of RFQs ignored over the last year.">Ignored<ReactTooltip /></td>
                <td data-tip="Average response time (in hours).">Avg RFQ Response<ReactTooltip /></td>
                <td data-tip="Average rating of all comments, out of 5.">Rating<ReactTooltip /></td>
                <td data-tip="# of issues reported over the last year (major issues).">Issues (Major)<ReactTooltip /></td>
                <td data-tip="% of issues with an acceptable response from the vendor.">Issue Response<ReactTooltip /></td>
                <td data-tip="(H)ardware only, (P)referred Vendor, (B)eware of Vendor, (M)ajor Vendor">Code<ReactTooltip /></td>
                <td data-tip="# of Rental Skillsets">Rental Skills<ReactTooltip /></td>
                <td data-tip="Rental Skillsets Missing">Missing Skills<ReactTooltip /></td>
            </thead>
            {this.state.allSuppliersReady && this.state.allSuppliers.map((supplier, index) => (
              <>
                {(supplier.distance <= this.state.range || this.state.zip === 0) && 
                  supplier.bActive &&  
                  ((this.state.usOnly && supplier.sState1) || (this.state.nonusOnly && !supplier.sState1) || (!this.state.usOnly && !this.state.nonusOnly)) && 
                  (
                <tr>
                  <td>{Math.round(supplier.score/this.state.maxScore*100)}%</td>
                  <td><NavLink target="_blank" to={"/srm/master-viewer/" + supplier.id}>{supplier.sCompany1}</NavLink></td>
                  <td>{supplier.sCity1}, {supplier.sState1 ? supplier.sState1 : (<>{supplier.sProvince1}, {supplier.sCountry1}</>)}</td>
                  {this.state.zip > 0 && (<td>{Math.round(supplier.distance)}</td>)}                  
                  <td>{supplier.nPOs}</td>
                  <td>{supplier.nRFQs}</td>
                  <td>{Math.round(supplier.nIgnored/supplier.nRFQs*1000)/10}%</td>
                  <td>{Math.round(supplier.nAvgResponse/6)/10}</td>
                  <td>{Math.round(supplier.nRating*100)/100}</td>
                  {/*<td>{Math.round(supplier.nIssues/supplier.nReviews*1000)/10 ? Math.round(supplier.nIssues/supplier.nReviews*1000)/10 + "%" : "N/A"}</td>*/}
                  <td>{supplier.nIssues} ({supplier.nIssuesMajor})</td>
                  <td>{Math.round(supplier.nIssuesResponse/supplier.nIssues*1000)/10 ? Math.round(supplier.nIssuesResponse/supplier.nIssues*1000)/10 + "%" : "N/A"}</td>
                  <td>{supplier.bHardwareOnly ? "H" : ""} {supplier.bPreferredVendor ? "P" : ""} {supplier.bBeware ? "B" : ""} {supplier.major ? "M" : ""}</td>
                  <td>{supplier.rentalSkills}</td>
                  <td>
                    <textarea rows={4}>{supplier.missingSkillsText}
                    </textarea>
                  </td>
                </tr>
                )}
              </>
                ))}
  
            </table>
            </div>
            )}
            {this.state.showHeatMap && this.state.heatMapReady && (
              <>

                <Modal>
                  <ModalContent>
                    <button onClick={() => this.setState({showHeatMap: !this.state.showHeatMap})}>Hide Heat Map</button>
                  </ModalContent>
                  <ModalContent>
                  <HeatMapNew 
                    points={this.state.locations}
                  />
                  </ModalContent>
                </Modal>
              </>
            )}
            </div>
  
            )}
          
          {this.state.viewing === "new" && (
            <div>
              {this.state.newID < 0 && <div>Name: <input onChange={(event) => this.setState({newName: event.target.value})}></input></div>}
              {this.state.newName && (<Button onClick={() => this.createNewVendor()}>Create</Button>)}
              {this.state.newID >= 0 && <Button><NavLink to={"/srm/master-viewer/" + this.state.newID}>
                          Go to New Vendor Profile
                        </NavLink></Button>}
              {console.log(this.state.newID)}
            </div>
          )}
          {this.state.viewing === "skill" && (          
          <div>            
            <div>
              Zip: 
              <input 
                onChange={(event) => this.updateZip(event.target.value)} 
                value={this.state.zip}>
              </input>
                &nbsp;
              <input 
                type='checkbox' 
                checked={this.state.usOnly} 
                onChange={() => this.setState({usOnly: !this.state.usOnly, nonusOnly: (this.state.usOnly ? this.state.nonusOnly : false)})}>
              </input> US Only
              &nbsp;
              <input 
                type='checkbox' 
                checked={this.state.nonusOnly} 
                onChange={() => this.setState({nonusOnly: !this.state.nonusOnly, usOnly: (this.state.nonusOnly ? this.state.usOnly : false)})}>
              </input> Non-US Only
            </div>
            <div>Add Range: <input onChange={(event) => this.setState({range: parseInt(event.target.value)}, () => this.calcSuppliersInRange())} value={this.state.range}></input> miles</div>
            <div>Old Search: <input type='checkbox' checked={this.state.oldSearch} onChange={() => this.setState({oldSearch: !this.state.oldSearch})}></input></div>

            <div><button onClick={() => this.setState({showHeatMap: !this.state.showHeatMap})}>Show Heat Map</button></div>

            {this.state.suppliers.length >0 && (
            
            <div>
            {parseInt(this.state.selectedSkills[0])===68 && (<>
              <table>
            <thead>
                <td>Score</td>
                <td>Company</td>
                <td>Location</td>
                {this.state.zip > 0 && (<td data-tip="Miles from vendor to search zip code.">Distance<ReactTooltip /></td>)}
                <td data-tip="# of Closed POs with this vendor over the last year.">POs<ReactTooltip /></td>
                <td data-tip="Average rating of all comments, out of 5.">Rating<ReactTooltip /></td>
            </thead>
            {this.state.suppliers.map((supplier, index) => (
              <>
                {(supplier.inRange || (this.state.oldSearch && supplier.distance <= this.state.range)) && 
                  supplier.bActive &&  
                  ((this.state.usOnly && supplier.sState1) || (this.state.nonusOnly && !supplier.sState1) || (!this.state.usOnly && !this.state.nonusOnly)) && 
                  (
                <tr>
                  <td>{Math.round(supplier.score/this.state.maxScore*100)}%</td>
                  <td><NavLink target="_blank" to={"/srm/master-viewer/" + supplier.id}>{supplier.sCompany1}</NavLink></td>
                  <td>{supplier.sCity1}, {supplier.sState1 ? supplier.sState1 : (<>{supplier.sProvince1}, {supplier.sCountry1}</>)}</td>
                  {this.state.zip > 0 && 
                  ((this.state.oldSearch && (<td>{Math.round(supplier.distance)}</td>)) || (<td>{Math.round(supplier.closestLocation.distance)}</td>))}                  
                  <td>{supplier.nPOs}</td>
                  <td>{Math.round(supplier.nRating*100)/100}</td>
                </tr>
                )}
              </>
                ))}
  
            </table>
            </>) || (<>
            
            {/*<div>Results: {this.state.results}</div>
            <div data-tip="Vendors with no POs and no RFQ responses in the last year">Inactive Vendors: {this.state.inactive}</div>
            <div data-tip="Vendors with 4 POs and 12 RFQ responses in the last year">Major Vendors: {this.state.major}</div>
            <div data-tip="Vendors with no RFQs sent in the last year">No RFQ Vendors: {this.state.noRFQ}</div>*/}
            <table>
            <thead>
                <td>Score</td>
                <td>Company</td>
                <td>Location</td>
                {this.state.zip > 0 && (<td data-tip="Miles from vendor to search zip code.">Distance<ReactTooltip /></td>)}
                <td data-tip="# of Closed POs with this vendor over the last year.">POs<ReactTooltip /></td>
                <td data-tip="# of RFQs sent to the vendor over the last year.">RFQs<ReactTooltip /></td>
                <td data-tip="% of RFQs ignored over the last year.">Ignored<ReactTooltip /></td>
                <td data-tip="Average response time (in hours).">Avg RFQ Response<ReactTooltip /></td>
                <td data-tip="Average rating of all comments, out of 5.">Rating<ReactTooltip /></td>
                <td data-tip="# of issues reported over the last year (major issues).">Issues (Major)<ReactTooltip /></td>
                <td data-tip="% of issues with an acceptable response from the vendor.">Issue Response<ReactTooltip /></td>
                <td data-tip="(H)ardware only, (P)referred Vendor, (B)eware of Vendor, (M)ajor Vendor">Code<ReactTooltip /></td>
            </thead>

            {this.state.suppliers.map((supplier, index) => (
              <>
                {(supplier.inRange || (this.state.oldSearch && supplier.distance <= this.state.range)) && 
                  supplier.bActive &&  
                  ((this.state.usOnly && supplier.sState1) || (this.state.nonusOnly && !supplier.sState1) || (!this.state.usOnly && !this.state.nonusOnly)) && 
                  (
                <tr>
                  <td>{Math.round(supplier.score/this.state.maxScore*100)}%</td>
                  <td><NavLink target="_blank" to={"/srm/master-viewer/" + supplier.id}>{supplier.sCompany1}</NavLink></td>
                  <td>{supplier.sCity1}, {supplier.sState1 ? supplier.sState1 : (<>{supplier.sProvince1}, {supplier.sCountry1}</>)}</td>
                  {this.state.zip > 0 && 
                  ((this.state.oldSearch && (<td>{Math.round(supplier.distance)}</td>)) || (<td>{Math.round(supplier.closestLocation.distance)}</td>))}                  
                  <td>{supplier.nPOs}</td>
                  <td>{supplier.nRFQs}</td>
                  <td>{Math.round(supplier.nIgnored/supplier.nRFQs*1000)/10}%</td>
                  <td>{Math.round(supplier.nAvgResponse/6)/10}</td>
                  <td>{Math.round(supplier.nRating*100)/100}</td>
                  {/*<td>{Math.round(supplier.nIssues/supplier.nReviews*1000)/10 ? Math.round(supplier.nIssues/supplier.nReviews*1000)/10 + "%" : "N/A"}</td>*/}
                  <td>{supplier.nIssues} ({supplier.nIssuesMajor})</td>
                  <td>{Math.round(supplier.nIssuesResponse/supplier.nIssues*1000)/10 ? Math.round(supplier.nIssuesResponse/supplier.nIssues*1000)/10 + "%" : "N/A"}</td>
                  <td>{supplier.bHardwareOnly ? "H" : ""} {supplier.bPreferredVendor ? "P" : ""} {supplier.bBeware ? "B" : ""} {supplier.major ? "M" : ""}</td>
                </tr>
                )}
              </>
                ))}
  
            </table>
            </>)}
            {this.state.showHeatMap && (
              <>

                <Modal>
                  <ModalContent>
                    <button onClick={() => this.setState({showHeatMap: !this.state.showHeatMap})}>Hide Heat Map</button>
                  </ModalContent>
                  <ModalContent>
                  <HeatMapNew 
                    points={this.state.heatMapLocations}
                  />
                  </ModalContent>
                </Modal>
              </>
            )}
            </div>
  
            )}

            <Select onChange={(event) => this.selectSkills(event.target.options)} style={{width: "300px", height: "500px"}} multiple>
              {this.state.skillsets.map((skill, index) => (
              <>
                {(skill.active) ? (<Option value={skill.id}>{skill.title}</Option>) : ""} 
              </>
              ))}
            </Select>
          </div>
          )}
          {this.state.viewing === "item" && (          
          <div>            
            <div>
              Zip: 
              <input 
                onChange={(event) => this.updateZip(event.target.value)} 
                value={this.state.zip}>
              </input>
                &nbsp;
              <input 
                type='checkbox' 
                checked={this.state.usOnly} 
                onChange={() => this.setState({usOnly: !this.state.usOnly, nonusOnly: (this.state.usOnly ? this.state.nonusOnly : false)})}>
              </input> US Only
              &nbsp;
              <input 
                type='checkbox' 
                checked={this.state.nonusOnly} 
                onChange={() => this.setState({nonusOnly: !this.state.nonusOnly, usOnly: (this.state.nonusOnly ? this.state.usOnly : false)})}>
              </input> Non-US Only
            </div>
            <div>Range: <input onChange={(event) => this.setState({range: event.target.value})} value={this.state.range}></input> miles</div>
            <div><button onClick={() => this.setState({showHeatMap: !this.state.showHeatMap})}>Show Heat Map</button></div>

            {this.state.suppliers.length >0 && (
            
            <div>
            <div>Results: {this.state.results}</div>
            <div data-tip="Vendors with no POs and no RFQ responses in the last year">Inactive Vendors: {this.state.inactive}</div>
            <div data-tip="Vendors with 4 POs and 12 RFQ responses in the last year">Major Vendors???: {this.state.major}</div>
            
            
            <table>
            <thead>
                <td>Score</td>
                <td>Company</td>
                <td>Location</td>
                {this.state.zip > 0 && (<td data-tip="Miles from vendor to search zip code.">Distance<ReactTooltip /></td>)}
                <td data-tip="# of Closed POs with this vendor over the last year.">POs<ReactTooltip /></td>
                <td data-tip="# of RFQs sent to the vendor over the last year.">RFQs<ReactTooltip /></td>
                <td data-tip="% of RFQs ignored over the last year.">Ignored<ReactTooltip /></td>
                <td data-tip="Average response time (in hours).">Avg RFQ Response<ReactTooltip /></td>
                <td data-tip="Average rating of all comments, out of 5.">Rating<ReactTooltip /></td>
                <td data-tip="# of issues reported over the last year (major issues).">Issues (Major)<ReactTooltip /></td>
                <td data-tip="% of issues with an acceptable response from the vendor.">Issue Response<ReactTooltip /></td>
                <td data-tip="(H)ardware only, (P)referred Vendor, (B)eware of Vendor, (M)ajor Vendor">Code<ReactTooltip /></td>
            </thead>
            {console.log(this.state.suppliers)}
            {this.state.suppliers.map((supplier, index) => (
              <>
                {(supplier.distance <= this.state.range || this.state.zip === 0) && 
                  supplier.bActive &&  
                  ((this.state.usOnly && supplier.sState1) || (this.state.nonusOnly && !supplier.sState1) || (!this.state.usOnly && !this.state.nonusOnly)) && 
                  (
                <tr>
                  <td>{Math.round(supplier.score/this.state.maxScore*100)}%</td>
                  <td><NavLink target="_blank" to={"/srm/master-viewer/" + supplier.id}>{supplier.sCompany1}</NavLink></td>
                  <td>{supplier.sCity1}, {supplier.sState1 ? supplier.sState1 : (<>{supplier.sProvince1}, {supplier.sCountry1}</>)}</td>
                  {this.state.zip > 0 && (<td>{Math.round(supplier.distance)}</td>)}                  
                  <td>{supplier.nPOs}</td>
                  <td>{supplier.nRFQs}</td>
                  <td>{Math.round(supplier.nIgnored/supplier.nRFQs*1000)/10}%</td>
                  <td>{Math.round(supplier.nAvgResponse/6)/10}</td>
                  <td>{Math.round(supplier.nRating*100)/100}</td>
                  {/*<td>{Math.round(supplier.nIssues/supplier.nReviews*1000)/10 ? Math.round(supplier.nIssues/supplier.nReviews*1000)/10 + "%" : "N/A"}</td>*/}
                  <td>{supplier.nIssues} ({supplier.nIssuesMajor})</td>
                  <td>{Math.round(supplier.nIssuesResponse/supplier.nIssues*1000)/10 ? Math.round(supplier.nIssuesResponse/supplier.nIssues*1000)/10 + "%" : "N/A"}</td>
                  <td>{supplier.bHardwareOnly ? "H" : ""} {supplier.bPreferredVendor ? "P" : ""} {supplier.bBeware ? "B" : ""} {supplier.major ? "M" : ""}</td>
                </tr>
                )}
              </>
                ))}
  
            </table>
            {this.state.showHeatMap && (
              <>

                <Modal>
                  <ModalContent>
                    <button onClick={() => this.setState({showHeatMap: !this.state.showHeatMap})}>Hide Heat Map</button>
                  </ModalContent>
                  <ModalContent>
                  <HeatMapNew 
                    points={this.state.heatMapLocations}
                  />
                  </ModalContent>
                </Modal>
              </>
            )}
            </div>
  
            )}

            <div style={{display: 'inline-block', width: "400px"}}>
                Categories
                  <div>
                    <Select onChange={(event) => this.selectCategory(event.target.options)} style={{width: "300px", height: "500px"}} multiple>
                      {this.state.categories.map((category, index) => (
                      <>
                        {<Option value={category.id}>{category.sName}</Option>} 
                      </>
                      ))}
                    </Select>
                  </div>
              </div>
              <div style={{display: 'inline-block', width: "400px"}}>
                Items
                  <div>
                    <Select onChange={(event) => this.selectItem(event)} size={10} style={{width: "500px", height: "500px"}}>
                      {this.state.items.map((item, index) => (
                      <>
                        {this.state.selectedCategories.includes(item.nCat) && <Option value={item.id}>{item.sName} ({item.nUsage})</Option>} 
                      </>
                      ))}
                    </Select>
                  </div>
              </div>
          </div>
          )}
          {this.state.viewing === "name" && (
            <>
            <div>
              
              <SearchVendor callback={this.navigateTo}></SearchVendor>
              
            </div>
            <div>
            {(this.state.redirect && (
            <>
            <Button>
            <NavLink target="_blank" to={this.state.redirectTo} style={{ color: '#fafafa'}}>
              Go to vendor {this.state.vendorName}
            </NavLink>
            </Button>
  
            </>
            ))}
            </div>
            </>
          )}
          
          </>
          )}

        </FormWrapper>
      </SearchWrapper>
    );
}
}

export default TASearch;