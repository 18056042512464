import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto 0.5em auto;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 3em 2em;
    margin: 0.5em auto;
    width: fit-content;
    height: fit-content;
    box-shadow: 0 0 5px rgba(33, 33, 33, 0.1);
    text-align: center;
    border-radius: 15px;
  }

  div:first-child {
    width:100%;
    max-width: 750px;
    height: 71px;
    margin: 4em auto 0.5em auto;
    padding: 0 0;
    color: #ff7f7f;
  }
`;

const Button = styled.button`
   padding: 3em 8em;
  margin: 0 1em;
   text-decoration: none;
   text-align: center;
   transition: all 0.2s;
  background-color: #e8edf1;
  border-radius: 15px;
  border: none;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    background-color: #4aabed;
    color: #fff;
  }
`;

export default class RfqControl extends Component {
  render() {
    return (
      <Wrapper>
        <div>
          <h1>
            This is a temporary feature, RFQ functionality will be redeveloped
            in the future
          </h1>
        </div>
        <div>
          <a
            href={`https://cc.cfrinc.us/request-for-quote.asp?nQuote=${this.props.id}`}
            target="_blank"
          >
            <Button>Send as Lead</Button>
          </a>
          <a
            href={`https://cc.cfrinc.us/request-for-quote.asp?nQuote=${this.props.id}`}
            target="_blank"
          >
            <Button>Create new RFQ</Button>
          </a>
        </div>
      </Wrapper>
    );
  }
}
