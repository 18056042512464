import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Loader from 'react-loader-spinner';
import styled from 'styled-components';
import Moment from 'moment';
import MediaQuery from 'react-responsive';
import {
  nQuoteTotalFormatter,
  abbreviateBrand,
} from '../../../assets/functions/calculations';
import { CSVLink } from 'react-csv';

const Wrapper = styled.div`
  width: 99%;
  max-width: 1300px;
  margin: 4em auto;

  table {
    width: 100%;
    position: relative;
    ${(props) => {
      if (props.amount > 10)
        return `
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;

    thead + tbody tr:last-child td:last-child,
    tfoot tr:last-child td:last-child {
      border-bottom-right-radius: 0px !important;
    }
    thead + tbody tr:last-child td:first-child,
    tfoot tr:last-child td:first-child {
      border-bottom-left-radius: 0px !important;
    }
    margin-bottom: 7em;
        `;
    }}
  }

  button {
    position: absolute;
    width: 100%;
    bottom: -27px;
     padding: 0.35em 1.2em;
     text-decoration: none;
     color: #fafafa;
     text-align: center;
     transition: all 0.2s;
    background-color: #4aabed;
    border: 1px solid #4aabed;
    cursor: pointer;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    &:hover {
      color: #222;
      background-color: #fafafa;
      border: 1px solid #d7d7d7;
    }
  }
`;

const SubTitle = styled.h2`
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1em;
`;

function bReviewed(bReviewed) {
  if (bReviewed === false) {
    return 'bold';
  } else {
    return 'normal';
  }
}

export default class MyOpenQuotes extends Component {
  state = {
    data: [],
    loaded: false,
    showAll: false,
    nRep: this.props.nRep,
    CSVHeaders: [
      {label: "Quote", key: "id"},  
      {label: "Customer", key: "sCompany"},  
      {label: "City", key: "sCity"},
      {label: "State", key: "sState"},
      {label: "Quote Total", key: "nQuoteTotal"},
      {label: "Brand", key: "sQuoteType"},
      {label: "RFQ Date", key: "dtDateStamp"},
      {label: "Next FU", key: "dtFollowup"},
      {label: "Last Activity", key: "dtDate"},
    ]  
  };

  fetchData = async (nRep) => {
    try {
      const response = await Axios.get('/all-my-quotes', {
        params: {
          nRep: nRep,
        },
        headers: { 'Content-Type': 'application/json' },
      });
      // Sets the state using the response data
      this.setState({
        data: response.data.result[0].recordset,
        loaded: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    this.fetchData(this.state.nRep);
    this.interval = setInterval(() => this.fetchData(this.state.nRep), 10000);
  }

  componentWillUnmount() {
    // Stop fetching data when the user leaves the page and component unmounts
    clearInterval(this.interval);
  }

  render() {
    let table;

    // If the state showAll is false then table with return with only 10 rows, else show all 100
    if (this.state.showAll === false) {
      table = (
        <>
          {this.state.data.slice(0, 10).map((sql) => (
            <tr
              style={{
                fontWeight: bReviewed(sql.bReviewed),
              }}
              key={sql.id}
            >
              <td style={{ textAlign: 'center' }}>
                <Link target="_blank" to={`/quote/${sql.id}`}>
                  {sql.id}
                </Link>
              </td>
              <td>
                <Link
                  target="_blank"
                  to={`/crm/master-viewer/${sql.sCompany.replace(
                    /\//g,
                    '%2F'
                  )}`}
                >
                  {sql.sCompany}
                </Link>
              </td>
              <MediaQuery minDeviceWidth={900}>
                <td>
                  {sql.sCity} {sql.sState}
                </td>
                <td>{sql.sQuoteTitle}</td>
              </MediaQuery>
              <td style={{ textAlign: 'center' }}>
                {nQuoteTotalFormatter(sql.nQuoteTotal)}
              </td>
              <MediaQuery minDeviceWidth={900}>
                <td style={{ textAlign: 'center' }}>
                  {abbreviateBrand(sql.sQuoteType)}
                </td>
                <td style={{ textAlign: 'center' }}>
                  {sql.dtDateStamp ? (
                    <>
                      {Moment(sql.dtDateStamp).utc().format('MM/DD/YYYY')}
                      <br />
                      {Moment(sql.dtDateStamp).utc().format('hh:mm A')}
                    </>
                  ) : null}
                </td>
              </MediaQuery>
              <td style={{ textAlign: 'center' }}>
                {sql.dtFollowup ? (
                  <>
                    {Moment(sql.dtFollowup).utc().format('MM/DD/YYYY')}
                    <br />
                    {Moment(sql.dtFollowup).utc().format('hh:mm A')}
                  </>
                ) : null}
              </td>
              <MediaQuery minDeviceWidth={900}>
                <td style={{ textAlign: 'center' }}>
                  {sql.dtDate ? (
                    <>
                      {Moment(sql.dtDate).utc().format('MM/DD/YYYY')}
                      <br />
                      {Moment(sql.dtDate).utc().format('hh:mm A')}
                    </>
                  ) : null}
                </td>
              </MediaQuery>
            </tr>
          ))}
        </>
      );
    } else {
      table = (
        <>
          {this.state.data.map((sql) => (
            <tr
              style={{
                fontWeight: bReviewed(sql.bReviewed),
              }}
              key={sql.id}
            >
              <td style={{ textAlign: 'center' }}>
                <Link target="_blank" to={`/quote/${sql.id}`}>
                  {sql.id}
                </Link>
              </td>
              <td>
                <Link
                  target="_blank"
                  to={`/crm/master-viewer/${sql.sCompany.replace(
                    /\//g,
                    '%2F'
                  )}`}
                >
                  {sql.sCompany}
                </Link>
              </td>
              <MediaQuery minDeviceWidth={900}>
                <td>
                  {sql.sCity} {sql.sState}
                </td>
                <td>{sql.sQuoteTitle}</td>
              </MediaQuery>
              <td style={{ textAlign: 'center' }}>
                {nQuoteTotalFormatter(sql.nQuoteTotal)}
              </td>
              <MediaQuery minDeviceWidth={900}>
                <td style={{ textAlign: 'center' }}>
                  {abbreviateBrand(sql.sQuoteType)}
                </td>
                <td style={{ textAlign: 'center' }}>
                  {sql.dtDateStamp ? (
                    <>
                      {Moment(sql.dtDateStamp).utc().format('MM/DD/YYYY')}
                      <br />
                      {Moment(sql.dtDateStamp).utc().format('hh:mm A')}
                    </>
                  ) : null}
                </td>
              </MediaQuery>
              <td style={{ textAlign: 'center' }}>
                {sql.dtFollowup ? (
                  <>
                    {Moment(sql.dtFollowup).utc().format('MM/DD/YYYY')}
                    <br />
                    {Moment(sql.dtFollowup).utc().format('hh:mm A')}
                  </>
                ) : null}
              </td>
              <MediaQuery minDeviceWidth={900}>
                <td style={{ textAlign: 'center' }}>
                  {sql.dtDate ? (
                    <>
                      {Moment(sql.dtDate).utc().format('MM/DD/YYYY')}
                      <br />
                      {Moment(sql.dtDate).utc().format('hh:mm A')}
                    </>
                  ) : null}
                </td>
              </MediaQuery>
            </tr>
          ))}
        </>
      );
    }

    return (
      <Wrapper amount={this.state.data.length}>
        <SubTitle>My Open Quotes ({this.state.data.length})</SubTitle>
        <CSVLink data={this.state.data} headers={this.state.CSVHeaders} onClick={()=>(console.log(this.state.data))}>
          Download
        </CSVLink>
        <table className="dashboard">
          <thead>
            <tr>
              <th>Quote #</th>
              <th>Company</th>
              <MediaQuery minDeviceWidth={900}>
                <th>Location</th>
                <th>Title</th>
              </MediaQuery>
              <th>Quote $</th>
              <MediaQuery minDeviceWidth={900}>
                <th>Brand</th>
                <th>RFQ Date</th>
              </MediaQuery>
              <th>Next FU</th>
              <MediaQuery minDeviceWidth={900}>
                <th>Last Activity</th>
              </MediaQuery>
            </tr>
          </thead>
          <tbody>
            {this.state.loaded || (
              <tr>
                <td colSpan="9">
                  <Loader
                    style={{
                      textAlign: 'center',
                    }}
                    type="ThreeDots"
                    color="#00BFFF"
                    height={20}
                    width={120}
                  />
                </td>
              </tr>
            )}
            {this.state.loaded && (
              <>
                {this.state.data.length > 0 ? (
                  <>{table}</>
                ) : (
                  <tr>
                    <td
                      colSpan="9"
                      style={{ textAlign: 'center', padding: '1em' }}
                    >
                      No quotes
                    </td>
                  </tr>
                )}
                {this.state.data.length > 10 ? (
                  <>
                    {this.state.showAll ? (
                      <button
                        onClick={() => {
                          this.setState({
                            showAll: false,
                          });
                        }}
                      >
                        Hide
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          this.setState({
                            showAll: true,
                          });
                        }}
                      >
                        Show All
                      </button>
                    )}
                  </>
                ) : null}
              </>
            )}
          </tbody>
        </table>
      </Wrapper>
    );
  }
}
