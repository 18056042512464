import React, { Component } from 'react';
import styled from 'styled-components';
import Moment from 'moment';

const Wrapper = styled.div`
  width: 47%;
  h1 {
    font-size: 2rem;
    font-weight: bold;
  }

  h3 {
    margin-top: 0.2em;
    font-size: 14px;
  }

  h5 {
    font-size: 14px;
  }

  a {
    &:nth-child(4) {
      display: block;
      margin-top: 0.5em;
      color: #36a2eb;
      font-weight: 500;

      &:hover {
        color: #9ad0f5;
      }
    }

    &:hover {
      color: #4aabed;
    }
  }

  span {
    font-size: 1rem;
  }
`;

export default class QuoteTitle extends Component {
  state = {};

  render() {
    let title;
    let company;

    // title comes in as a empty string, and sometimes null. First checks to make sure its not null, and then checks to make sure its not an empty string.
    if (this.props.title != null) {
      if (this.props.title.length > 0) {
        title = ` - ${this.props.title}`;
      } else {
        title = null;
      }
    } else {
      title = null;
    }

    // If the company prop is null then it will return a placeholder
    if (this.props.company != null) {
      company = this.props.company.slice(0, 35);
    } else {
      company = 'No Company';
    }

    return (
      <Wrapper>
        <h5>{Moment(this.props.date).utc().format('L')}</h5>
        <h1>{company}</h1>
        <h3>
          <span>
            <a
              target="_blank"
              href={`https://cc.cfrinc.us/quote.asp?id=${this.props.id}`}
            >
              #{this.props.id}{' '}
            </a>
          </span>
          <span>{title}</span>
        </h3>
        {this.props.sDAddress ? (
          <a
            target="_blank"
            href={`http://maps.google.com/?q=${this.props.sDAddress} ${this.props.sDCity}, ${this.props.sDState} ${this.props.sDZip}`}
          >
            <i class="fas fa-map-marker-alt"></i> {this.props.sDAddress}{' '}
            {this.props.sDCity} {this.props.sDState} {this.props.sDZip}
          </a>
        ) : (
          <a
            target="_blank"
            href={`http://maps.google.com/?q=${this.props.sAddress} ${this.props.sCity}, ${this.props.sState} ${this.props.sZip}`}
          >
            <i class="fas fa-map-marker-alt"></i> {this.props.sAddress}{' '}
            {this.props.sCity} {this.props.sState} {this.props.sZip}
          </a>
        )}
      </Wrapper>
    );
  }
}
