import React, { Component } from 'react'
//import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import racLogo from '../../../assets/images/rac-rental-contract.jpg';

{/*const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4'
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    },
    logo: {
      left: "0.1in",
      top: "0.1in",
      width: "60%",
    },
    contact: {
      left: "0.1in",
      top: "0.1in",
      width: "30%",
    },
    table: {
      fontSize: 10,
      width: 550,
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignContent: "stretch",
      flexWrap: "nowrap",
      alignItems: "stretch"
    },
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignContent: "stretch",
      flexWrap: "nowrap",
      alignItems: "stretch",
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 35
    },
    cell: {
      borderColor: "#cc0000",
      borderStyle: "solid",
      borderWidth: 2,
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: "auto",
      alignSelf: "stretch"
    },
  });
*/} 

export default class DocuSignPDF extends Component {
  render() {
    return (
      <>
      {/*
      <Document>
        <Page size="A4" style={styles.page}>
            <View>
              <View style={styles.table}>
                <View style={styles.row}>
                  <Image src={racLogo} style={styles.cell}></Image>
                  <Text style={styles.cell}>Contact Info</Text>
                </View>
              </View>                
            </View>
        </Page>
    </Document>
    */}
    </>
    )
  }
}
