export const Allegheny = [
    "Allison Park",
    "Bairdford",
    "Bakerstown",
    "Bethel Park",
    "Brackenridge",
    "Braddock",
    "Bradfordwoods",
    "Bridgeville",
    "Buena Vista",
    "Bunola",
    "Carnegie",
    "Cheswick",
    "Clairton",
    "Coraopolis",
    "Coulters",
    "Creighton",
    "Crescent",
    "Cuddy",
    "Curtisville",
    "Dravosburg",
    "Duquesne",
    "East Mc Keesport",
    "East Pittsburgh",
    "Elizabeth",
    "Gibsonia",
    "Glassport",
    "Glenshaw",
    "Greenock",
    "Harwick",
    "Homestead",
    "Imperial",
    "Indianola",
    "Ingomar",
    "Leetsdale",
    "Mc Kees Rocks",
    "Mckeesport",
    "Monroeville",
    "Morgan",
    "Natrona Heights",
    "North Versailles",
    "Oakdale",
    "Oakmont",
    "Pitcairn",
    "Pittsburgh",
    "Presto",
    "Rural Ridge",
    "Russellton",
    "Sewickley",
    "South Park",
    "Springdale",
    "Sturgeon",
    "Tarentum",
    "Turtle Creek",
    "Verona",
    "Warrendale",
    "West Elizabeth",
    "West Mifflin",
    "Wexford",
    "Wildwood",
    "Wilmerding"
]
