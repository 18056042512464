import React, { Component } from 'react';
import { getSQLData } from '../../assets/functions/fetch';
import { Wrapper } from '../styled/nav';
import Tutorial1 from '../tutorials/Tutorial1';
import Tutorial1Larry from '../tutorials/Tutorial1Larry';
import Tutorial1Brian from '../tutorials/Tutorial1Brian';
import Tutorial1Hunter from '../tutorials/Tutorial1Hunter';
import Tutorial2 from '../tutorials/Tutorial2';
import Tutorial2JR from '../tutorials/Tutorial2JR';
import Tutorial2Brian from '../tutorials/Tutorial2Brian';
import RacTempHome from '../pages/home/RacTempHome';

class Tutorial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      viewing: "Home",
    };
  }


  componentDidMount = async () => {
    this.props.updateTitle("Dev Tutorial")

    if(this.props.user === 45){this.setState({viewing: "Home"})}
    if(this.props.user === 54){this.setState({viewing: "Home"})}
    if(this.props.user === 63){this.setState({viewing: "Tutorial1Hunter"})}
    if(this.props.user === 66){this.setState({viewing: "Tutorial1Larry"})}
    
    try {
      
      if(fetch){

        let data = fetch.data.data[0].recordset[0]
        let path = fetch.config.params.path

        console.log(data, path)
        this.setState({

        })
      }
      
      
    } catch (error) {
      console.error(error);
    }
    
    
  }

  render() {
    return (
      <Wrapper>
        {this.state.viewing === "Home" && (
          <>
            Cfrinc.app Development Tutorials
            <button onClick={() => this.setState({viewing: "Tutorial1"})}>Tutorial1</button>
            <button onClick={() => this.setState({viewing: "Tutorial1Brian"})}>Tutorial1 - Brian</button>
            <button onClick={() => this.setState({viewing: "Tutorial1Hunter"})}>Tutorial1 - Hunter</button>
            <button onClick={() => this.setState({viewing: "Tutorial1Larry"})}>Tutorial1 - Larry</button>
            <br />
            <button onClick={() => this.setState({viewing: "Tutorial2"})}>Tutorial2</button>
            <button onClick={() => this.setState({viewing: "Tutorial2JR"})}>Tutorial2JR</button>
            <button onClick={() => this.setState({viewing: "RacTempHome"})}>Tutorial2 - Brian</button>

          </>
        )}
        {this.state.viewing === "Tutorial1" && (
          <Tutorial1 />
        )}
        {this.state.viewing === "Tutorial1Brian" && (
          <Tutorial1Brian user={this.props.user} updateTitle={this.props.updateTitle} permissions={this.props.permissions} />
        )}
        {this.state.viewing === "Tutorial1Hunter" && (
          <Tutorial1Hunter />
        )}
        {this.state.viewing === "Tutorial1Larry" && (
          <Tutorial1Larry />
        )}
         {this.state.viewing === "Tutorial2" && (
          <Tutorial2 />
        )}
         {this.state.viewing === "Tutorial2JR" && (
          <Tutorial2JR />
        )}
             {this.state.viewing === "Tutorial2Brian" && (
          <RacTempHome />
        )}
    

      </Wrapper>
    );
  }
};

export default Tutorial;
