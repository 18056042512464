import React, { Component } from 'react';
import Axios from 'axios';
import styled from 'styled-components';

const Wrapper = styled.section`
  padding: 1em 0em;
  text-align: center;
  transition: 0.3s;
`;

const SubTitle = styled.h2`
  padding: 1em 0;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #fff;
  max-width: 500px;
  width: 90vw;
  border-radius: 10px;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.1);
  margin: 3em auto;
`;

const Select = styled.select`
  width: 90%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
`;

const TextArea = styled.textarea`
  width: 90%;
  padding: 1em;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  transition: box-shadow 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  background-color: #fff;
`;

const Button = styled.button`
  display: inline-block;
   padding: 0.8em 2.5em;
   margin: 1em 0.3em 1em 0;
   text-decoration: none;
   color: #fafafa;
   text-align: center;
   transition: all 0.2s;
  background-color: rgba(54, 162, 235, 0.9);
  border: 1px solid #fafafa;
  cursor: pointer;
  border-radius: 50px;

  &:hover {
    border: 1px solid #d7d7d7;
  }
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

const Label = styled.label`
  font-weight: bold;
  text-align: left;
  float: left;
  margin: 0 0 0.5em 2em;
`;

const AlertMessage = styled.p`
  position: absolute;
  color: #4aabed;
  font-weight: bold;
  top: 65px;
`;

class ReportIssue extends Component {
  state = {
    sCategory: 'General Feedback',
    nPriority: 2,
    sDescription: '',
    flashMessage: '',
  };

  // When the form is submitted it was pass the value of both select fields and the text box to the axios post, it will then reset the textBox state and fetch new data
  handleSubmit = (e) => {
    e.preventDefault();
    Axios({
      method: 'post',
      url: '/create-new-ticket',
      data: {
        nAddedBy: '',
        sCategory: this.state.sCategory,
        sDescription: this.state.sDescription,
        nPriority: this.state.nPriority,
      },
    });
    this.setState({
      sCategory: 'General Feedback',
      nPriority: 2,
      sDescription: '',
      flashMessage: 'Your ticket has been submitted!',
    });
    this.interval = setInterval(
      () =>
        this.setState({
          flashMessage: '',
        }),
      8000
    );
  };

  handleChange(e) {
    this.setState({
      sDescription: e.target.value,
    });
  }

  handleChangeCategory = (e) => {
    this.setState({ sCategory: e.target.value });
  };

  handleChangePriority = (e) => {
    this.setState({ nPriority: e.target.value });
  };

  render() {
    return (
      <Wrapper>
        <>
          <FormWrapper>
            <SubTitle>Report an issue</SubTitle>
            <AlertMessage>{this.state.flashMessage}</AlertMessage>
            <form onSubmit={this.handleSubmit} autoComplete="off">
              <Label htmlFor="category">Category</Label>
              <Select
                id="category"
                name="category"
                value={this.state.sCategory}
                onChange={(e) => {
                  this.handleChangeCategory(e);
                }}
              >
                <option value="General Feedback">General Feedback</option>
                <option value="UI">UI</option>
                <option value="Server Error">Server Error</option>
                <option value="Feature Request">Feature Request</option>
              </Select>
              <Label htmlFor="priority">Priority</Label>
              <Select
                id="priority"
                name="priority"
                value={this.state.nPriority}
                onChange={(e) => {
                  this.handleChangePriority(e);
                }}
              >
                <option value="1">Low</option>
                <option value="2">Normal</option>
                <option value="3">High</option>
              </Select>
              <TextArea
                id="description"
                name="description"
                placeholder="Please provide a detailed message.."
                style={{ height: '200px' }}
                value={this.state.sDescription}
                onChange={(e) => {
                  this.handleChange(e);
                }}
              ></TextArea>
              <ButtonWrapper>
                <Button type="submit">Submit</Button>
              </ButtonWrapper>
            </form>
          </FormWrapper>
        </>
      </Wrapper>
    );
  }
}

export default ReportIssue;
