import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { cGreen, cRed, nQuoteTotalNoDollar, whichRep } from '../../../../assets/functions/calculations';
import moment from 'moment';
import { CustomDateWrapper, Input, SubmitText, GreyTextDiv, SubTitle } from '../../../styled/ui';
import { numberOfDays } from '../../../../assets/functions/display';



export default class SiteVisitQuoteDiscontinued extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    
    return (
      <>
      <SubTitle>Site Visit Complete, Quote Sent, Discontinued</SubTitle>
      <table class="dashboard">
          <thead>
            <tr>
              <th>ID</th>
              <th>Company</th>
              <th>Contact</th>
              <th>Location</th>
              <th>Title</th>
              <th>Sales Rep</th>
              <th>Quote Date</th>
              <th>Quote Price</th>
              <th style={{width: '300px'}}>Quote Tag</th>
              <th>Site Visit Needed?</th>
              <th>Site Visit Planned</th>
              <th>Site Visit Date</th>
              <th>Quote Sent Date</th>
            </tr>
          </thead>
          <tbody>
                {this.props.data.length > 0 && (
                    <>
                      {this.props.data.map((sql, index) => (
                        <>
                        <tr key={sql.id}>
                          <td><Link target="_blank" to={`/quote/${sql.id}`}>{sql.id}</Link></td>
                          <td>{sql.sCompany}</td>
                          <td>{sql.sName}</td>
                          <td>{sql.sAddress}{sql.sAddress2 ? (<><br />{sql.sAddress2}</>) : ""}<br />{sql.sCity}, {sql.sState} {sql.sZip}</td>
                          <td>{sql.sQuoteTitle}</td>
                          <td>{whichRep(sql.nRep)}{sql.nRep2 ? (<><br />{whichRep(sql.nRep2)}</>) : ""}{sql.nRep3 ? (<><br />{whichRep(sql.nRep3)}</>) : ""}</td>
                          <td>{moment(sql.dtDateStamp).utc().format('MM/DD/YY')}<br />{numberOfDays(sql.Age)} old</td>
                          <td>{nQuoteTotalNoDollar(sql.nQuoteTotal)}</td>
                          <td>
                            <textarea style={{height: '80px', width: '100%'}} onInput={(event) => this.props.handleUpdateTag(event.target.value, index, 'quoteSentDiscontinued')} value={sql.sQuoteTags}></textarea>
                            {sql.changed && (<div onClick={() => this.props.handleSaveTag(sql.sQuoteTags, sql.id)}>Save Tag</div>)}
                          </td>
                          <td onClick={()=>this.props.handleNeedSiteVisit(!sql.bNeedSiteVisit, sql.id)} style={{fontWeight: this.props.changeSiteVisitMode ? "bold" : ""}}>
                            {sql.bNeedSiteVisit ? "Yes" : "No"}
                          </td> 
                          <td>
                            <CustomDateWrapper><Input onChange={(e) => {this.props.handleDateChange(e, index, 'dtSiteVisitPlanned', 'quoteSentDiscontinued')}} type="date" id="date" name="date" 
                              value={moment(sql.dtSiteVisitPlanned).utc().format('YYYY-MM-DD')} /></CustomDateWrapper>
                            <SubmitText onClick={() => this.props.handleSiteVisitPlannedDateChange(sql.dtSiteVisitPlanned,sql.id)}>Submit</SubmitText>
                          </td>
                          <td>
                            <CustomDateWrapper><Input onChange={(e) => {this.props.handleDateChange(e, index, 'dtSiteVisit', 'quoteSentDiscontinued')}} type="date" id="date" name="date" 
                              value={moment(sql.dtSiteVisit).utc().format('YYYY-MM-DD')} /></CustomDateWrapper>
                            <SubmitText onClick={() => this.props.handleSiteVisitDateChange(sql.dtSiteVisit,sql.id)}>Submit</SubmitText>
                          </td>
                          <td>
                            <CustomDateWrapper><Input onChange={(e) => {this.props.handleDateChange(e, index, 'dtSentDate', 'quoteSentDiscontinued')}} type="date" id="target" name="target"
                              value={moment(sql.dtSentDate).utc().format('YYYY-MM-DD')} />
                            </CustomDateWrapper>
                            <SubmitText onClick={() => this.props.handleQuoteSentDateChange(sql.dtSentDate,sql.id)}>Submit</SubmitText>
                          </td>
                        </tr>
                        </>
                      ))}
                    </>
                )}

          </tbody>
        </table>
        </>
    );
  }
}
