import React, { Component } from 'react';
import styled from 'styled-components';
import Search from '../misc/Search';
import { NavLink } from 'react-router-dom';
import Cookies from 'js-cookie';
import { white } from 'chalk';
import { topNavLinks } from '../../assets/data/nav';
import { checkPermissions } from '../../assets/functions/user';
import OpenEmail from '../email/OpenEmail';

const Wrapper = styled.a``;

const NavUlLeft = styled.ul`
  flex: 1 200px;

  li {
    list-style-type: none;
    font-weight: 500;
    align-self: flex-end;
    display: inline-block;
    padding: 0em 0.5em;
  }

  i {
    color: #ff5959;
    position: absolute;
    font-size: 1.3rem;
    margin-right: 5px;
    left: -13px;
    top: -9px;
  }
`;

const NavUlRight = styled.ul`
  justify-self: flex-start;
  margin-left: auto;
  margin-right: auto;

  li {
    list-style-type: none;
    font-weight: 500;
    align-self: flex-end;
    display: inline-block;
    padding-right: 1.5em;
  }
`;

const Button = styled.button`
  display: inline-block;
   padding: 0.35em 1.2em;
   margin: 1em 0.3em 0.3em 0;
   text-decoration: none;
   color: #fafafa;
   text-align: center;
   transition: all 0.2s;
  background: none;
  border: 1px solid rgba(255, 255, 255, 0);
  cursor: pointer;
  border-radius: 15px;
`;

const Number = styled.p`
  color: #fff;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 4px;
  left: 0.5px;
  right: 0;
  text-align: center;
  font-size: 0.8rem;
`;

class TopNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
    };
  }

  handleClick = () => {
    Cookies.remove('connect.sid');
    Cookies.remove('sTTATitle');
  };

  render() {
    return (
      <Wrapper className="topNavBar" id="topNavBar">
        <NavUlLeft>
        {topNavLinks.map((link, id) => (
          <>
              {checkPermissions(this.props.permissions, link.permissions) && (
                <li>
                  <NavLink id={id} to={link.link} style={{ color: '#fff', position: 'relative' }}>
                    {link.text}
                  </NavLink>
                </li>
              )}
              
          </>
        ))}
        </NavUlLeft>
        
        <NavUlRight>
          <li>
            <Search />
          </li>
          <li>
            <div>
              <i className="far fa-bell"></i>
            </div>
          </li>
          <li>
            <div>
              <NavLink to="/settings">
                <i style={{ color: '#fff' }} className="fas fa-cog"></i>
              </NavLink>
            </div>
          </li>
          <li>
            <form action="/logout" method="POST">
              <Button onClick={this.handleClick} type="submit">
                <a style={{ color: '#fff' }}>Sign Out</a>
              </Button>
            </form>
          </li>
          <li>
            <OpenEmail
              to={"test@test.com"}
              from={"jr@cfrinc.us"}
              text={"Send Email"}
              >
            </OpenEmail>
          </li>
        </NavUlRight>
      </Wrapper>
    );
  }
}

export default TopNavbar;
