import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Moment from 'moment';
import Axios from 'axios';
import Loader from 'react-loader-spinner';

const Wrapper = styled.div`
  width: 99%;
  max-width: 1600px;
  margin: 5em auto;

  table {
    width: 100%;
    position: relative;
    ${(props) => {
      if (props.amount > 10)
        return `
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;

    thead + tbody tr:last-child td:last-child,
    tfoot tr:last-child td:last-child {
      border-bottom-right-radius: 0px !important;
    }
    thead + tbody tr:last-child td:first-child,
    tfoot tr:last-child td:first-child {
      border-bottom-left-radius: 0px !important;
    }
    margin-bottom: 7em;
        `;
    }}
  }

  button {
    position: absolute;
    width: 100%;
    bottom: -27px;
     padding: 0.35em 1.2em;
     text-decoration: none;
     color: #fafafa;
     text-align: center;
     transition: all 0.2s;
    background-color: #4aabed;
    border: 1px solid #4aabed;
    cursor: pointer;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    &:hover {
      color: #222;
      background-color: #fafafa;
      border: 1px solid #d7d7d7;
    }
  }

  i {
    color: #999;
    transition: 0.2s;

    &:hover {
      color: black;
    }
  }
`;

const SubTitle = styled.h2`
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1em;
`;

export default class DiscontinuedQuotes extends Component {
  state = {
    data: [],
    loaded: false,
    showAll: false,
  };

  // Async Axios
  fetchData = async (sQuoteType) => {
    try {
      const response = await Axios.get('/discontinued-quotes', {
        params: {
          sQuoteType: sQuoteType,
        },
        headers: { 'Content-Type': 'application/json' },
      });
      // // Sets the state using the response data
      this.setState({
        data: response.data.result[0].recordset,
        loaded: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    this.fetchData(this.props.sQuoteType);
  }

  // Handles marking a quote as a valid discontinue
  handleClick(id) {
    console.log('test');
    Axios({
      method: 'post',
      url: '/mark-discontinued-quote',
      data: {
        id: id,
      },
    });
    this.fetchData(this.props.sQuoteType);
  }

  render() {
    let table;

    // If the state showAll is false then table with return with only 10 rows, else show all 100
    if (this.state.showAll === false) {
      table = (
        <>
          {this.state.data.slice(0, 10).map((sql) => (
            <tr key={sql.id}>
              <td style={{ textAlign: 'center' }}>
                <Link target="_blank" to={`/quote/${sql.id}`}>
                  {sql.id}
                </Link>
              </td>
              <td style={{ textAlign: 'center' }}>
                {Moment(sql.dtLastUpdated).utc().format('MM/DD/YYYY')}
              </td>
              <td>
                <Link
                  target="_blank"
                  to={`/crm/master-viewer/${sql.nCompany}`}
                >
                  {sql.sCompany}
                </Link>
              </td>
              <td style={{ textAlign: 'center' }}>{sql.sPhone}</td>
              <td>{`${sql.sAddress} ${sql.sCity} ${sql.sState}`}</td>
              <td>{sql.sEmail}</td>
              <td>{sql.sQuote}</td>
              <td style={{ textAlign: 'center' }}>
                $
                {sql.nQuoteTotal.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </td>
              <td>{sql.sWhyCancel}</td>
              <td>{sql.nLikelyToClose}</td>
              <td>{sql.nRFQ}</td>
              <td style={{ textAlign: 'center' }}>
                <i
                  onClick={() => this.handleClick(sql.id)}
                  style={{ cursor: 'pointer' }}
                  className="fas fa-check"
                ></i>
              </td>
            </tr>
          ))}
        </>
      );
    } else {
      table = (
        <>
          {this.state.data.map((sql) => (
            <tr key={sql.id}>
              <td style={{ textAlign: 'center' }}>
                <Link target="_blank" to={`/quote/${sql.id}`}>
                  {sql.id}
                </Link>
              </td>
              <td style={{ textAlign: 'center' }}>
                {Moment(sql.dtLastUpdated).utc().format('MM/DD/YYYY')}
              </td>
              <td>
                <Link
                  target="_blank"
                  to={`/crm/master-viewer/${sql.nCompany}`}
                >
                  {sql.sCompany}
                </Link>
              </td>
              <td style={{ textAlign: 'center' }}>{sql.sPhone}</td>
              <td>{`${sql.sAddress} ${sql.sCity} ${sql.sState}`}</td>
              <td>{sql.sEmail}</td>
              <td>{sql.sQuote}</td>
              <td style={{ textAlign: 'center' }}>
                $
                {sql.nQuoteTotal.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </td>
              <td>{sql.sWhyCancel}</td>
              <td>{sql.nLikelyToClose}</td>4
              <td>{sql.nRFQ}</td>
              <td style={{ textAlign: 'center' }}>
                <i
                  onClick={() => this.handleClick(sql.id)}
                  style={{ cursor: 'pointer' }}
                  className="fas fa-check"
                ></i>
              </td>
            </tr>
          ))}
        </>
      );
    }

    return (
      <Wrapper amount={this.state.data.length}>
        <SubTitle>Discontinued Quotes</SubTitle>
        <table className="dashboard">
          <thead>
            <tr>
              <th style={{ width: '8%' }}>Quote</th>
              <th style={{ width: '8%' }}>Date Discontinued</th>
              <th style={{ width: '8%' }}>Company</th>
              <th style={{ width: '8%' }}>Phone</th>
              <th style={{ width: '8%' }}>Location</th>
              <th style={{ width: '8%' }}>Email</th>
              <th style={{ width: '20%' }}>Request</th>
              <th style={{ width: '8%' }}>Quote $</th>
              <th style={{ width: '8%' }}>Why Discontinued</th>
              <th style={{ width: '8%' }}>Rating</th>
              <th style={{ width: '8%' }}>RFQs</th>
              <th style={{ width: '8%' }}>Mark Valid Discontinue</th>
            </tr>
          </thead>
          <tbody>
            {this.state.loaded || (
              <tr>
                <td colSpan="10">
                  <Loader
                    style={{
                      textAlign: 'center',
                    }}
                    type="ThreeDots"
                    color="#00BFFF"
                    height={20}
                    width={120}
                  />
                </td>
              </tr>
            )}
            {this.state.loaded && (
              <>
                {this.state.data.length > 0 ? (
                  <>{table}</>
                ) : (
                  <tr>
                    <td
                      colSpan="10"
                      style={{ textAlign: 'center', padding: '1em' }}
                    >
                      No quotes
                    </td>
                  </tr>
                )}
                {this.state.data.length > 10 ? (
                  <>
                    {this.state.showAll ? (
                      <button
                        onClick={() => {
                          this.setState({
                            showAll: false,
                          });
                        }}
                      >
                        Hide
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          this.setState({
                            showAll: true,
                          });
                        }}
                      >
                        Show All
                      </button>
                    )}
                  </>
                ) : null}
              </>
            )}
          </tbody>
        </table>
      </Wrapper>
    );
  }
}
