import React, { Component } from 'react';
import {
    NavLink,
  } from 'react-router-dom';
import { DashboardLinksWrapper } from '../styled/nav';
import { checkPermissions } from '../../assets/functions/user';
import { leftNavLinks } from '../../assets/data/nav';

class DesktopLeftNav extends Component {

    render(){
        return(
            <DashboardLinksWrapper>
              <h2 style={{ textAlign: 'center' }}>Your Dashboards</h2>

            {leftNavLinks.map((link, id) => (
                <>
                    {checkPermissions(this.props.permissions, link.permissions) && (
                        <NavLink id={id} to={link.link}>
                            <li>
                                <i style={{ paddingRight: '5%', fontSize: '1.2rem' }} className={link.icon} />
                                {link.text}
                            </li>
                        </NavLink>
                    )}
                    
                </>
            ))}
            </DashboardLinksWrapper>
        )
    }
}

export default DesktopLeftNav;