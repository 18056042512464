import React, { Component } from 'react';
import styled from 'styled-components';
import Items from '../../../assets/json/items.json';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
`;

const NewLineButton = styled.td`
  text-align: center;
  background-color: #eaf5fd;
  cursor: pointer;
  transition: 0.3s;
  font-weight: bold;

  &:hover {
    background-color: #5eb4ef;
    color: #fff;
  }
`;

export default class QuoteLinesPane extends Component {
  render() {
    let nSubTotal = 0;

    // Loops through quoteLines and calculates the subtotal
    for (let i = 0; i < this.props.quoteLines.length; i++) {
      let nItemTotal =
        this.props.quoteLines[i].nPrice * this.props.quoteLines[i].nQuantity;
      nSubTotal += nItemTotal;
    }

    // Calculates Total amount of Tax
    let nTax = (nSubTotal + this.props.nDelivery) * (this.props.nTaxRate / 100);

    return (
      <Wrapper>
        <table style={{ width: '100%' }} className="dashboard">
          <thead>
            <tr>
              <th style={{ width: '5%' }}>Quantity</th>
              <th style={{ width: '20%' }}>Item</th>
              <th style={{ width: '65%' }}>Equipment Description</th>
              <th style={{ width: '5%' }}>Unit Price</th>
              <th style={{ width: '5%' }}>Total</th>
            </tr>
          </thead>
          <tbody>
            {this.props.quoteLines.map((sql) => (
              <tr key={sql.id}>
                <td contentEditable="true" style={{ textAlign: 'center' }}>
                  {sql.nQuantity}
                </td>
                <td contentEditable="true" style={{ textAlign: 'left' }}>
                  {Items[sql.nItem] != null && (
                    <>
                      {sql.nItem < 759 && (
                        <>{sql.nItem ? Items[sql.nItem].Name : null}</>
                      )}
                      {sql.nItem > 759 && <>Shipping - Other</>}
                    </>
                  )}
                </td>
                <td contentEditable="true" style={{ whiteSpace: 'pre-wrap' }}>
                  {sql.sDescription}
                </td>
                <td contentEditable="true" style={{ textAlign: 'center' }}>
                  $
                  {sql.nPrice
                    ? sql.nPrice.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : null}
                </td>
                <td contentEditable="true" style={{ textAlign: 'center' }}>
                  $
                  {(sql.nPrice * sql.nQuantity).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
              </tr>
            ))}
            <tr>
              <NewLineButton colSpan="5">New Line</NewLineButton>
            </tr>
            <tr>
              <td
                colSpan="4"
                style={{ textAlign: 'right', fontWeight: 'bold' }}
              >
                Sub Total:
              </td>
              <td colSpan="1" style={{ textAlign: 'center' }}>
                $
                {nSubTotal.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </td>
            </tr>
            <tr>
              <td
                colSpan="4"
                style={{ textAlign: 'right', fontWeight: 'bold' }}
              >
                Delivery & Pickup / Shipping:
              </td>
              <td colSpan="1" style={{ textAlign: 'center' }}>
                $
                {this.props.nDelivery
                  ? this.props.nDelivery.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : null}
              </td>
            </tr>
            <tr>
              <td colSpan="4" style={{ textAlign: 'right' }}>
                <input type="checkbox" id="" name="" value="" /> Tax Delivery &
                Pickup?{' '}
                <span style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  Tax ( {this.props.nTaxRate} %):
                </span>
              </td>
              <td colSpan="1" style={{ textAlign: 'center' }}>
                $
                {nTax.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </td>
            </tr>
            <tr>
              <td
                colSpan="4"
                style={{ textAlign: 'right', fontWeight: 'bold' }}
              >
                Total:
              </td>
              <td colSpan="1" style={{ textAlign: 'center' }}>
                $
                {(nSubTotal + this.props.nDelivery + nTax).toLocaleString(
                  undefined,
                  { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </Wrapper>
    );
  }
}
