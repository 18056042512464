import React, { Component } from 'react';
import { getSQLData, setSQLData } from '../../../assets/functions/fetch';

class BlastEmailEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loaded: false,
          selected: {},

        };
    
      }
      fetchData = async () => {

        try {

            const fetch = await getSQLData("marketingWizard/getBlastEmails", {})
            if(fetch){
                let data = fetch.data.data[0].recordset    
        
                this.setState({
                    data: data,
                }, () => this.processData())
            }
        }
        catch {

        }
      }
      componentDidMount(){
        this.fetchData()
      }

      processData(){
        
        this.setState({
            loaded: true,
        })
      }

      handleChangeSubject(subject){
        let selected = this.state.selected
        selected.sName = subject
        this.setState({
            selected: selected
        })
      }
      handleChangeBody(body){
        let selected = this.state.selected
        selected.sEmail = body
        this.setState({
            selected: selected
        })
      }
      handleSaveChanges = async() => {
        let selected = this.state.selected

        if(selected?.id > 0){
            try {
                const setter = await setSQLData("marketingWizard/updateBlast",{
                    id: selected.id,
                    name: selected.sName,
                    body: selected.sEmail,
                })
                if(setter){
                    this.fetchData()
                }
            } catch {

            }
            
            
        } else {
            try {
                const setter = await setSQLData("marketingWizard/createNewBlast",{
                    name: selected.sName,
                    body: selected.sEmail,
                })
                if(setter){
                    this.fetchData()
                }
            } catch {

            }
            
        }

      }
      selectBlast(index){
        let selected = {...this.state.data[index]}
        this.setState({
            selected: selected
        })
      }
    render() {
        let data = this.state.data
        let selected = this.state.selected
        return (
            <>
            <div>
                {data?.length > 0 && data.map((email,index)=>(
                    <button onClick={()=>this.selectBlast(index)}>Edit {email.sName}</button>
                ))}
            </div>
            <div>
                <table>
                    <tr>
                        <td><b>Subject:</b></td>
                        <td><input 
                            value={selected.sName} 
                            onChange={(e)=>this.handleChangeSubject(e.target.value)}
                            style={{width: '600px'}}
                        ></input></td>
                    </tr>
                    <tr>
                        <td><b>Body:</b></td>
                        <td><textarea 
                            value={selected.sEmail} 
                            onChange={(e)=>this.handleChangeBody(e.target.value)}
                            style={{width: '600px', height: '200px'}}
                        ></textarea></td>
                    </tr>
                </table>
                <div><button onClick={()=>this.handleSaveChanges()}>{selected.id > 0 ? "Save Changes" : "Create New Blast Email"}</button></div>
                
            </div>
            </>
        );
    }
}

export default BlastEmailEditor;