import moment from 'moment';
import React, { Component } from 'react'
import { getSQLData } from '../../../assets/functions/fetch';
import { matchQuotesAndPOLines } from '../../../assets/functions/process/cam';
import { matchQuotesAndFollowups, matchQuotesAndNotesv2, matchQuotesAndPOs, matchQuotesAndQuoteLines, matchQuotesAndRFQs } from '../../../assets/functions/process/quotes';
import { nQuoteTotalFormatter, whichRep } from '../../../assets/functions/calculations';



export default class DiscontinuedQuotes extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loaded: false,

        };
    

      }
      componentDidMount() {
        this.fetchData()
      }

      fetchData = async () => {

        try {

          
            const fetchQuotes = await getSQLData("rac/getDiscontinuedQuotes", {})
            const fetchFollowups = await getSQLData("rac/getDiscontinuedQuoteFollowups", {})
            const fetchQuoteLines = await getSQLData("rac/getDiscontinuedQuoteLines", {})
            const fetchQuotePOs = await getSQLData("rac/getDiscontinuedQuotePOs", {})
            const fetchNotes = await getSQLData("rac/getDiscontinuedQuoteNotes", {})
            const fetchRFQs = await getSQLData("rac/getDiscontinuedQuoteRFQs", {})

            
            if(fetchQuotes && fetchFollowups && fetchQuoteLines && fetchQuotePOs && fetchNotes && fetchRFQs){

                let quotes = fetchQuotes.data.data[0].recordset
                let followups = fetchFollowups.data.data[0].recordset
                let quoteLines = fetchQuoteLines.data.data[0].recordset
                let POs = fetchQuotePOs.data.data[0].recordset
                let notes = fetchNotes.data.data[0].recordset
                let RFQs = fetchRFQs.data.data[0].recordset

                this.setState({
                  quotes: quotes,
                  followups: followups,
                  quoteLines: quoteLines,
                  POs: POs,
                  notes: notes,
                  RFQs: RFQs,
                }, () => this.processData())
            }
          
        } catch (error) {
          console.error(error);
        }
      }
      
      processData(){
        let quotes = this.state.quotes
        let followups = this.state.followups
        let quoteLines = this.state.quoteLines
        let POs = this.state.POs
        let notes = this.state.notes
        let RFQs = this.state.RFQs

        let data = {
          quotes: quotes,
          followups: followups,
          quoteLines: quoteLines,
          POs: POs,
          notes: notes,
          RFQs: RFQs,
        }

        console.log(data)

        data = matchQuotesAndFollowups(data)
        data = matchQuotesAndQuoteLines(data)
        data = matchQuotesAndPOs(data)
        data = matchQuotesAndNotesv2(data)
        data = matchQuotesAndRFQs(data)

        console.log(data)

        this.setState({
            quotes: data.quotes,
            loaded: true,
        })
      }




  render() {
    let loaded = this.state.loaded
    let quotes = this.state.quotes
    return (
      <div>
        {loaded && (
            <>
                <table>
                  {quotes.map((quote,index)=>(
                    <div style={{border: '1px solid black', padding: '5px', margin: '5px'}}>
                      <tr>
                        <td>
                          <div>Quote: {quote.id}</div>
                          <div>Quote Company: {quote.sCompany}</div>
                          <div>Company Profile Name: {quote.sName}</div>
                          <div>Rep: {whichRep(quote.nRep)}</div>
                        </td>
                        <td>
                          <div>Request Date: {moment(quote.dtDateStamp).utc().format("MM/DD/YYYY")}</div>
                          <div>Discontinue Date: {moment(quote.dtLastUpdate).utc().format("MM/DD/YYYY")}</div>
                          <div>Delivery Date: {moment(quote.dtDelveryDate).utc().format("MM/DD/YYYY")}</div>
                          <div>Pickup Date: {moment(quote.dtPickupDate).utc().format("MM/DD/YYYY")}</div>
                        </td>
                        <td>
                          <div>Industry: {quote.sIndustry}</div>
                          <div>Sub-Industry: {quote.sSubIndustry}</div>
                          <div>{quote.nEmployees} employees</div>
                          <div>{nQuoteTotalFormatter(quote.nCompanyAnnualSales)} sales</div>
                        </td>
                      </tr>
                      {quote.quoteLines.length > 0 &&
                      <tr>
                         <div>Quote Lines</div>
                        <table>
                        {quote.quoteLines.map((line,index2) => (
                          <tr>
                            <td>{line.nQuantity}</td>
                            <td>{line.nItem}</td>
                            <td>{line.sDescription}</td>
                            <td>{line.nPrice}</td>
                            <td>{line.nQuantity * line.nPrice}</td>
                          </tr>
                        ))}
                        </table>
                      </tr>}
                      {quote.notes.length > 0 && 
                      <tr>
                        <div>Notes</div>
                        <table>
                          {quote.notes.map((note,index2) => (
                            <tr>
                              <td>{moment(note.dtTimeStamp).utc().format("MM/DD/YYYY")}</td>
                              <td>{whichRep(note.nRep)}</td>
                              <td>{note.sNote}</td>
                            </tr>
                          ))}
                        </table>
                      </tr>}
                      {quote.RFQs.length > 0 && 
                      <tr>
                        <div>RFQs</div>
                        <table>
                          {quote.RFQs.map((RFQ,index2) => (
                            <tr>
                              <td>{RFQ.nMember}</td>
                              <td>{moment(RFQ.dtSent).utc().format("MM/DD/YYYY")}</td>
                              <td>{moment(RFQ.dtSubmitted).utc().format("MM/DD/YYYY")}</td>
                              <td>{RFQ.totalPrice+RFQ.nDelivery}</td>
                            </tr>
                          ))}
                        </table>
                      </tr>}
                      {quote.followups.length > 0 && 
                      <tr>
                        <div>Followups</div>
                        <table>
                          {quote.followups.map((followup,index2) => (
                            <tr>
                              <td>{whichRep(followup.nRep)}</td>
                              <td>{moment(followup.dtDate).utc().format("MM/DD/YYYY")}</td>
                              <td>{followup.sNote}</td>
                              <td>{followup.dtCompleted}</td>
                            </tr>
                          ))}
                        </table>
                      </tr>
                      }
                    </div>
                  ))}
                </table>
            </>
        )}
      </div>
    )
  }
}
