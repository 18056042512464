import React, { Component } from 'react'
import { getSQLData, setSQLData } from '../../../../assets/functions/fetch';
import { calcPreSalesTaxCommissionLog, nQuoteTotalNoDollar, whichRep } from '../../../../assets/functions/calculations';
import moment from 'moment';
import { CustomDateWrapper, Input, SubmitText } from '../../../styled/ui';

export default class RepLog extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loaded: false,
          nRep: 8,
        };

      }

      componentDidMount(){
        let now = moment()
        let referenceStart = moment('1/8/24 00:00')
        let referenceEnd = moment('1/21/24 23:59')

        let dayOfPayroll = moment().diff(referenceStart, "days") % 14
        let payrollStart = moment().subtract(dayOfPayroll, "days")
        let payrollEnd = moment().add(13-dayOfPayroll, "days")

        console.log(referenceStart, referenceEnd, dayOfPayroll, payrollStart, payrollEnd)

        this.setState({
          minDate: payrollStart,
          maxDate: payrollEnd,
        }, ()=>this.fetchData())
      }
      

      fetchData = async() => {
        try {
            const fetch = await getSQLData("accounting/getRepLog", {
              nRep: this.state.nRep,
              minDate: this.state.minDate.format("MM/DD/YYYY"),
              maxDate: this.state.maxDate.format("MM/DD/YYYY"),
            })
            
            if(fetch){
                let data = fetch.data.data[0].recordset

                this.setState({
                    data: data,
                }, ()=>this.processData())

            }

        } catch (error) {

        }
      }

      processData() {
        let data = this.state.data

        console.log(data)

        this.setState({
            data: data,
            loaded: true,
        })
      }

      changeRep(options){
        let nRep = 0
        for(let i=0; i<options.length; i++){
          if(options[i].selected){nRep=options[i].value}
        }
        this.setState({
          nRep: nRep,
        })
      }

  render() {
    return (
      <div>
        {this.state.loaded && (
          <>
          <div style={{maxWidth: '600px'}}>
            <table>
              <tr>
                <td>Start Date</td>
                <td>
                  <CustomDateWrapper><Input onChange={(e) => {this.setState({minDate: moment(e.target.value)})}} type="date" id="date" name="date"
                    value={moment(this.state.minDate).utc().format('YYYY-MM-DD')} />
                  </CustomDateWrapper>
                </td>
              </tr>
              <tr>
                <td>End Date</td>
                <td>
                <CustomDateWrapper><Input onChange={(e) => {this.setState({maxDate: moment(e.target.value)})}} type="date" id="date" name="date"
                  value={moment(this.state.maxDate).utc().format('YYYY-MM-DD')} />
                </CustomDateWrapper>
                </td>
              </tr>
              <tr>
                <td>Rep</td>
                <td>
                <select onChange={(event)=>this.changeRep(event.target.options)}>
                  <option value={8}>{whichRep(8)}</option>
                  <option value={22}>{whichRep(22)}</option>
                  <option value={40}>{whichRep(40)}</option>
                  <option value={51}>{whichRep(51)}</option>
                  <option value={54}>{whichRep(54)}</option>
                </select>
                </td>
              </tr>
            </table>
            <button onClick={() => this.fetchData()}>Get Commission Report</button>
          </div> 
            {this.state.data.length > 0 && (
              <div style={{maxWidth: '800px'}}>
              <table>
                <thead>
                  <th width={200}>Quote</th>
                  <th width={200}>Date Logged</th>
                  <th width={200}>Commission</th>
                  <th width={200}></th>
                </thead>
                {this.state.data.map((line, index)=>(
                  <tr>
                    <td>{line.nQuote}</td>
                    <td>{moment(line.dtDateEarned).format("MM/DD/YYYY")}</td>
                    <td>{nQuoteTotalNoDollar(line.nMargin*.1)}</td>
                    <td>{line.sException}</td>
                  </tr>
                ))}
              </table>
              </div>
            )}
        
          </>
        )}
      </div>
    )
  }
}
