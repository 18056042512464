import React, { Component } from 'react';
import { getSQLData } from '../../assets/functions/fetch';
import { Wrapper } from '../styled/nav';
import styled from 'styled-components';
import moment from 'moment';
import { getQueryInfo } from '../../assets/functions/system';
import { CSVLink } from 'react-csv';

class Tutorial2JR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      data: {},
      quoteNum: 0,
    };
  }

  componentDidMount = async () => {
    
    
  }

  fetchData = async () => {
    try {
      const fetch = await getSQLData("accounting/qbi_newInvoices", {
        QuoteNum: this.state.quoteNum
      })
      
      
      if(fetch){

        let data = fetch.data.data[0].recordset    
        let query = fetch.query
        
        this.setState({
          data: data,
          query: query,
        }, () => this.processData())
      }
      
      
    } catch (error) {
      console.error(error);
    }
  }

  processData() {

    let data = this.state.data

    for(let i=0; i<data.length; i++){
      if(data[i].nQuantity < 0){data[i].nQuantity = -data[i].nQuantity}
      if(data[i].nPrice < 0){data[i].nPrice = -data[i].nPrice}
      if(data[i].TotalPrice < 0){data[i].TotalPrice = -data[i].TotalPrice}
    }

    this.setState({
      data: data,
      loaded: true,
    })

  }

  render() {
    return (
      <>

      <Wrapper>
        <input value={this.state.quoteNum} onChange={(event)=>this.setState({quoteNum: parseInt(event.target.value)})}></input>
        <button onClick={()=>this.fetchData()}>Get Invoice</button>
        {this.state.loaded && (
        <button><CSVLink data={this.state.data} headers={this.state.query.fields}>Download {this.state.data[0].id}</CSVLink></button>
        )}
      </Wrapper>
      
      </>
    );
  }
};

export default Tutorial2JR;
