import React, { Component } from 'react'
import { getSQLData, setSQLData } from '../../../../assets/functions/fetch';
import { calcPreSalesTaxCommissionLog, nQuoteTotalNoDollar, whichRep } from '../../../../assets/functions/calculations';
import moment from 'moment';

export default class InvoicedNotLogged extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loaded: false,

        };

      }

      componentDidMount(){
        this.fetchData()
      }

      fetchData = async() => {
        try {
            const fetch = await getSQLData("accounting/paidButNotLogged", {})
            
            if(fetch){
                let data = fetch.data.data[0].recordset

                this.setState({
                    data: data,
                }, ()=>this.processData())

            }

        } catch (error) {

        }
      }

      processData() {
        let data = this.state.data

        for(let i=0; i<data.length; i++){
            data[i].margin = data[i].linesTotal + data[i].nDelivery - data[i].POTotal


        }

        this.setState({
            data: data,
            loaded: true,
        })
      }

      addLog = async(index) => {
        let entry = this.state.data[index]
        try {
            const fetch = await setSQLData("accounting/commission_log_import",{
                nQuote: entry.id,
                nRep: entry.nRep,
                nMargin: entry.margin, 
                dtDate: moment().format("MM/DD/YYYY")
            })

            if(fetch){this.fetchData()}
        } catch (error) {

        }
        
      }

  render() {
    return (
      <div>
        {this.state.loaded && (

        
        <table>
            <thead>
                <tr>
                    <th>Quote</th>
                    <th>Margin</th>
                    <th>Quote Total</th>
                    <th></th>
                    <th>Rep</th>
                    <th>First Closed</th>
                    <th>Invoiced</th>
                    <th>Paid</th>
                    <th>Delivery</th>
                </tr>
            </thead>
        
        {this.state.data.map((quote,index)=>(
            <tr>
                <td>{quote.id}</td>
                <td>{nQuoteTotalNoDollar(quote.margin)}</td>
                <td>{nQuoteTotalNoDollar(quote.nQuoteTotal)}</td>
                <td><button onClick={()=>this.addLog(index)}>Add to Log</button></td>
                <td>{whichRep(quote.nRep)}</td>
                <td>{quote.dtFirstClosed}</td>
                <td>{quote.dtInvoiced}</td>
                <td>{quote.dtPaid}</td>
                <td>{quote.dtDeliveryDate}</td>
                
            </tr>
        ))}
        </table>

        )}
      </div>
    )
  }
}
