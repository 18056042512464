import React, { Component } from 'react';
import Axios from 'axios';
import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import Moment from 'moment';
import { whichRep } from '../../../assets/functions/calculations';

const Wrapper = styled.div`
  width: 99%;
  max-width: 700px;
  margin: 4em auto;

  table {
    width: 100%;
  }
`;

const SubTitle = styled.h2`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1em;

  i {
    font-size: 16px;
    margin-left: 7px;
  }
`;

class RepItemUsage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      reps: [],
      maxReps: 100,
      loaded: false,
    };
  }

  // Async Axios
  fetchData = async () => {
    try {
      const response = await Axios.get('/rep-item-usage', {
        params: {

        },
        headers: { 'Content-Type': 'application/json' },
      });
 
      if(response){
        this.setState({
          data: response.data.result[0].recordset,  
          loaded: true,
        }, () => this.summarizeData());
      }
      
    } catch (error) {
      console.error(error);
    }
  };

  summarizeData() {
    let repData = []
    for(let i=0; i<this.state.maxReps; i++){
      repData[i] = {quotes: [], lines: 0, items: 0, other: 0}
    }

    for(let i=0; i<this.state.data.length; i++){
      let rep = this.state.data[i].nRep

      if (rep > 0){
        repData[rep].lines = repData[rep].lines + 1

        if(this.state.data[i].nItem > 0 && this.state.data[i].nItem < 900000){
          repData[rep].items = repData[rep].items + 1
        }

        if(this.state.data[i].nItem >= 900000){
          repData[rep].other = repData[rep].other + 1
        }

        if(!repData[rep].quotes.includes(this.state.data[i].id)){
          repData[rep].quotes.push(this.state.data[i].id)
        }        
      }      
    }

    this.setState({reps: repData})

  }

  componentDidMount() {
    this.fetchData();
  }

  render() {
    return (
      <Wrapper>
        <SubTitle>
          Rep Item Usage <i class="far fa-clock"></i>
        </SubTitle>
        <table className="dashboard">
          <thead>
            <tr>
            <th>Rep</th>
            <th>Quotes</th>
            <th>Lines</th>
            <th>Items</th>
            <th>Other</th>
            <th>Non-Item</th>
            </tr>
          </thead>
            {this.state.loaded || (
              <tr>
                <td colSpan="8">
                  <Loader
                    style={{
                      textAlign: 'center',
                    }}
                    type="ThreeDots"
                    color="#00BFFF"
                    height={80}
                    width={80}
                  />
                </td>
              </tr>
            )}
            {this.state.loaded && (
              <>
                {this.state.reps.map((rep,index) => (
                  <>
                    {rep.lines > 0 && index !== 44 && index !== 45 && index !== 52 && index !== 60 && (
                      <tr>
                        <td>{whichRep(index)}</td>
                        <td>{rep.quotes.length}</td>
                        <td>{rep.lines}</td>
                        <td>{rep.items} ({Math.round(rep.items/rep.lines*1000)/10}%)</td>
                        <td>{rep.other} ({Math.round(rep.other/rep.lines*1000)/10}%)</td>
                        <td>{rep.lines-rep.items-rep.other}  ({Math.round((rep.lines-rep.items-rep.other)/rep.lines*1000)/10}%)</td>
                      </tr>
                    )}
                  </>
                ))}
              </>
            )}
        </table>
      </Wrapper>
    );
  }
}

export default RepItemUsage;
