import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Axios from 'axios';
import Moment from 'moment';
import Loader from 'react-loader-spinner';
import MediaQuery from 'react-responsive';
import { nQuoteTotalNoDollar, quoteLink, whichRep } from '../../../assets/functions/calculations';

const Wrapper = styled.div`
  width: 99%;
  margin: 0 auto;
  max-width: 1600px;

  table {
    width: 100%;
    position: relative;
    ${(props) => {
      if (props.amount > 10)
        return `
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;

    thead + tbody tr:last-child td:last-child,
    tfoot tr:last-child td:last-child {
      border-bottom-right-radius: 0px !important;
    }
    thead + tbody tr:last-child td:first-child,
    tfoot tr:last-child td:first-child {
      border-bottom-left-radius: 0px !important;
    }
    margin-bottom: 7em;
        `;
    }}
  }

  button {
    position: absolute;
    width: 100%;
    bottom: -27px;
     padding: 0.35em 1.2em;
     text-decoration: none;
     color: #fafafa;
     text-align: center;
     transition: all 0.2s;
    background-color: #4aabed;
    border: 1px solid #4aabed;
    cursor: pointer;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    &:hover {
      color: #222;
      background-color: #fafafa;
      border: 1px solid #d7d7d7;
    }
  }
`;

const SubTitle = styled.h2`
  padding: 2em 0 0 0;
  text-align: center;
`;

const CustomDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    margin: 1em 0;
  }

  input {
    width: 140px;
     padding: 0.2em 0em 0.2em 0.2em;
     margin: 0 0 0.5em 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;

    @media (max-width: 900px) {
      width: auto;
      margin: auto;
    }
  }
`;

const AlertMessage = styled.p`
  font-weight: bold;
`;

export default class LikelyToClose extends Component {
  state = {
    data: [],
    loaded: false,
    showAll: false,
    hideAll: false,
  };

  // Async Axios
  fetchData = async () => {
    try {
      const response = await Axios.get('/cam-operations/high-likelihood', {
        params: {
        },
        headers: { 'Content-Type': 'application/json' },
      });
      // Sets the state using the response data
      this.setState({
        data: response.data.result[0].recordset,
        loaded: true,
      }, () => console.log(response.data.result));
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    this.fetchData();
  }

  componentWillUnmount() {

  }

  updateTag(getTag, getIndex) {
    let data = this.state.data

    data[getIndex].sQuoteTags = getTag
    data[getIndex].changed = true

    this.setState({data: data})
  }

  saveTag(getRecord, getIndex){
    Axios({
      method: 'post',
      url: '/save-tag',
      data: {
        data: getRecord
      },
    });
    let data = this.state.data
    data[getIndex].changed = false
    this.setState({data: data})

  }

  render() {
    let table;

    return (
      <Wrapper>
        <SubTitle onClick={() => this.setState({hideAll: !this.state.hideAll})}>
          High Likelihood to Close
          {this.state.hideAll ? (
            <i
            style={{ padding: '5px', fontSize: '1.2rem' }}
            className="fas fa-arrow-alt-circle-down"
          ></i>
          ) : (
            <i
            style={{ padding: '5px', fontSize: '1.2rem' }}
            className="fas fa-arrow-alt-circle-up"
          ></i>
          )}
          </SubTitle>
          {!this.state.hideAll && (
            <>
            
        <table className="dashboard">
          <thead>
            <tr>
              <th>ID</th>
              <th>Company</th>
              <th>Quote Total</th>
              <th>Quote Date</th>
              <th>Rep</th>
              <th>Title</th>
              <th>Quote Tag</th>
              <th>Site Visit Date</th>
            </tr>
          </thead>
          <tbody>
            {this.state.loaded || (
              <tr>
                <td colSpan="11">
                  <Loader
                    style={{
                      textAlign: 'center',
                    }}
                    type="ThreeDots"
                    color="#00BFFF"
                    height={20}
                  />
                </td>
              </tr>
            )}

            {this.state.loaded && this.state.data && this.state.data.map((data,index) => (
              <tr>
                <td><Link target="_blank" to={`/quote/${data.id}`}>
                  {data.id}
                </Link></td>
                <td>{data.sCompany}</td>
                <td>{nQuoteTotalNoDollar(data.nQuoteTotal)}</td>
                <td>{Moment(data.dtDateStamp).utc().format('YYYY-MM-DD')}</td>
                <td>{whichRep(data.nRep)}</td>                    
                <td>{data.sQuoteTitle}</td>                    
                <td>
                  <textarea style={{height: '80px', width: '100%'}} onInput={(event) => this.updateTag(event.target.value, index)}>{data.sQuoteTags}</textarea>
                  {data.changed && (<div onClick={() => this.saveTag(data, index)}>Save Tag</div>)}
                </td>
                <td>{data.dtSiteVisit && Moment(data.dtSiteVisit).utc().format('YYYY-MM-DD')}</td>
              </tr>
            ))}
          </tbody>
        </table>
        </>
          )}
      </Wrapper>
    );
  }
}
