import React, { Component } from 'react';
import styled from 'styled-components';
import { setSQLData } from '../../../assets/functions/fetch';

// Rep names for the dropdown
const repNames = {
  5: 'Andy Wendt',
  54: 'Brian Kubala',
  63: 'Hunter Stewart',
  64: 'Jeff Bruck',
  71: 'Jill Staley',
  1: 'John Beagle',
  45: 'Johnny Beagle',
  44: 'Kevin Boles',
  68: 'Kevin Thompson',
  40: 'Kristen Fisher',
  51: 'Mark Gillaugh',
  22: 'Randy Moore',
  27: 'Robert Beagle',
  14: 'Susan Beagle',
  8: 'Tony Cipollone',
};

// Styled Components for Modal
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const ModalTitle = styled.h2`
  margin: 0;
  font-size: 1.5rem;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;

const InputGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`;

const Select = styled.select`
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #ccc;
  resize: vertical;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #4aabed;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: #2a8acb;
  }
  &:not(:last-child) {
    margin-right: 10px;
  }
`;

class ContactLog extends Component {
  state = {
    contactType: '',
    contactOutcome: '',
    contactDetails: '',
    selectedRep: '',
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = async () => {
    const { contactId, repId, onClose, onLogSuccess } = this.props; // Add onLogSuccess callback
    const { contactType, contactOutcome, contactDetails, selectedRep } = this.state;

    try {
      console.log('Logging contact:', {
        nContact: contactId,
        nRep: selectedRep || repId,
        sType: contactType,
        sDisposition: contactOutcome,
        sNote: contactDetails,
      });

      const logResponse = await setSQLData('marketing/insertContactLog', {
        nContact: contactId,
        nRep: selectedRep || repId,
        sType: contactType,
        sDisposition: contactOutcome,
        sNote: contactDetails,
      });

      if (logResponse.status === 200) {
        console.log('Contact logged successfully');
        if (onLogSuccess) {
          onLogSuccess(contactId); // Call the success callback and pass the contact ID
        }
        onClose();
      } else {
        console.error('Error logging contact:', logResponse.error);
      }
    } catch (error) {
      console.error('Error logging contact:', error);
    }
  };

  render() {
    const { onClose } = this.props;
    const { contactType, contactOutcome, contactDetails, selectedRep } = this.state;

    return (
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>
            <ModalTitle>Log Contact Interaction</ModalTitle>
            <CloseButton onClick={onClose}>&times;</CloseButton>
          </ModalHeader>

          <InputGroup>
            <Label>Select Rep Making the Contact</Label>
            <Select
              name="selectedRep"
              value={selectedRep}
              onChange={this.handleInputChange}
            >
              <option value="">Select a Rep</option>
              {Object.entries(repNames).map(([repId, repName]) => (
                <option key={repId} value={repId}>
                  {repName}
                </option>
              ))}
            </Select>
          </InputGroup>

          <InputGroup>
            <Label>Contact Type</Label>
            <Select
              name="contactType"
              value={contactType}
              onChange={this.handleInputChange}
            >
              <option value="">Select contact type</option>
              <option value="Call">Call</option>
              <option value="Email">Email</option>
            </Select>
          </InputGroup>

          <InputGroup>
            <Label>Outcome</Label>
            <Select
              name="contactOutcome"
              value={contactOutcome}
              onChange={this.handleInputChange}
            >
              <option value="">Select outcome</option>
              <option value="Connected">Connected</option>
              <option value="Voicemail">Voicemail</option>
              <option value="Email Bounceback">Email Bounceback</option>
            </Select>
          </InputGroup>

          <InputGroup>
            <Label>Contact Details</Label>
            <TextArea
              name="contactDetails"
              value={contactDetails}
              onChange={this.handleInputChange}
              rows="5"
              placeholder="Enter details about the contact attempt"
            />
          </InputGroup>

          <ButtonContainer>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={this.handleSubmit}>Log Contact</Button>
          </ButtonContainer>
        </ModalContent>
      </ModalOverlay>
    );
  }
}


export default ContactLog;