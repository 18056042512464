import React, { Component } from 'react';
import { getSQLData, setSQLData } from '../../assets/functions/fetch';
import { Wrapper } from '../styled/nav';
import styled from 'styled-components';
import moment from 'moment';
import { getQueryInfo } from '../../assets/functions/system';
import { CSVLink } from 'react-csv';
import Axios from 'axios';

class Tutorial2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      data: {},
      startDate: moment().subtract(1, "months").startOf("months").format("MM/DD/YYYY"),
      endDate: moment().subtract(1, "months").endOf("months").format("MM/DD/YYYY"),
    };
  }

  componentDidMount = async () => {
    this.fetchData()
    
    
  }

  fetchData = async () => {
    let startDate = this.state.startDate
    let endDate = this.state.endDate

    if(this.state.customStartDate && this.state.customEndDate){
      startDate = this.state.customStartDate
      endDate = this.state.customEndDate
    }
    try {
      const fetch = await getSQLData("marketing/closedQuotesDateRange", {
        startDate: startDate,
        endDate: endDate,
      })
      
      const response = await Axios.post(
        "/send-email",
        {
        email: {
          from: 'tickets@cfrinc.us',
          to: 'jr@cfrinc.us',
          subject: "Test",
          text: "I just sent this email from cfrinc.app.",
        }
      })
      
      
      if(fetch){

        let data = fetch.data.data[0].recordset        
        let query = fetch.query
        
        this.setState({
          data: data,
          query: query,
        }, () => this.processData())
      }
      
      
    } catch (error) {
      console.error(error);
    }
  }

  processData() {

    this.setState({

      loaded: true,
    })

  }


  render() {
    return (
      <>
      {this.state.loaded && (
      <Wrapper>
        <div>
          In this exercise, we're going to create a new component for Kirsten to export a CSV file, and also display the data on a graph.
        </div>
        <h2>Create a new Component</h2>
        <div>
          Create a new component in components/tutorials named Tutorial2YourName.js.  Copy in the constructor, componentDidMount, processData, and render methods.
        </div>
        <h2>react-csv</h2>
        <div>
          Fortunately, there is an easy to use npm package called react-csv that contains a component called CSVLink.  
          You need to provide two properties to it: data and headers.
          headers is an array of objects containing two properties: key and label.
          data is an array of objects (the data you want displayed).
          The data objects must contain the properties in the "key" of the headers.
          If the query is documented properly, all of this is already ready to use in CSVLink.
        </div>
        <div>
          console.log(this.state.data, this.state.query) and look at it.
          Notice how data contains the properties: dtDateStamp, id, nQuoteTotal, sCompany, sEmail, and sName.
          Notice the array in query called fields that contains the properties key and label.
          The keys match the data: dtDateStamp, id, nQuoteTotal, sCompany, sEmail, and sName
          The labels are more human friendly: Quote Creation Date, Quote Number, Quote Total Price, Company Name, Email, Contact Name.
          The labels are what will be the column headers of the CSV file.
        </div>
        <h3>Exercise 1: Create a button, add a CSVLink to it, pass the properties data (this.state.data) and headers (this.state.query.fields) to it.</h3>
        <h2>Modify the date range</h2>
        <div>
          By default, the data listed is last month.
          However, we can change the date range by updating the startDate and endDate state, then running fetchData again.
          startDate and endDate must be formatted like MM-DD-YYYY.
          Whenever we take input from a user, we need to pass data to a function to update state.
          I have created an input to change Start Date and a function to update state as an example.
          Start Date: <input type="date" onChange={(event) => this.setState({customStartDate: event.target.value})} />
          End Date: <input type="date" onChange={(event) => this.setState({customEndDate: event.target.value})} />
        </div>
        <h3>Exercise: Create an input to change End Date, then a button that calls this.fetchData onClick.</h3>
        <h2>Move date change components to another file and setup a callback to update state.</h2>
        <div>
          <CSVLink data={this.getDataFromState} headers={this.state.query.fields} asyncOnClick={true} onClick={this.fetchData}>Download</CSVLink>
        </div>
        <h2>Format for Kirsten</h2>
        <div>
          Create a new component called WebFX Dashboard in components/tables/home.
          Move the date change, CSV export, and graph to this component.
          Format this component for the end user, adding text explaining what they are downloading.
        </div>
      </Wrapper>
      )}
      </>
    );
  }
};

export default Tutorial2;
