import React, { Component } from 'react';
import styled from 'styled-components';
import { Bar, Scatter } from 'react-chartjs-2';
import Axios from 'axios';
import PercentageLegend from '../../../misc/PercentageLegend';
import { weekStart, weekEnd } from '../../../../assets/functions/time.js';
import {
  round,
  calcTotalMargin,
  calcTotalSale,
  dateFormat,
  dateFormatter,
} from '../../../../assets/functions/calculations.js';
import Moment from 'moment';

const Wrapper = styled.section`
  width: 99%;
  max-width: 1600px;
  margin: 7em auto 5em auto;
`;

const SubTitle = styled.h2`
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  border-bottom: none !important;
  margin-bottom: 1em;
`;

class RacHistoryCharts extends Component {
  state = {
    showChart: "",
    labels: [],
    openQuotes: [],
    openQuotesSales: [],
    openQuotesPotential: [],
    siteVisits: [],
    siteVisitsPlanned: [],
    quotesSent: [],
    openIssues: [],
  };

  // Async Axios - Gets 6 weeks of data
  fetchData = async (startDate, endDate, i) => {
    const response = await Axios.get('/rac-history', {
      params: {
        startDate: startDate,
        endDate: endDate,
      },
      headers: { 'Content-Type': 'application/json' },
    });
    // Sets the state using the passed counter from plotData
    this.setState({
      data: response.data.result[0].recordset,
    }, () => this.plotData());
  };
  catch(error) {
    console.error(error);
  }

  // Runs plotData function when the component mounts
  componentDidMount() {
    this.fetchData()
  }

  plotData() {
    let data = this.state.data
    let labels = []
    let dates = []
    let openQuotes = []
    let openQuotesSales = []
    let openQuotesPotential = []
    let openQuotesPlot = []
    let openQuotesSalesPlot = []
    let openQuotesPotentialPlot = []

    for(let i=0; i<data.length; i++){
      labels[i] = Moment(data[i].dtDate).valueOf()
      dates[i] = data[i].dtDate
      if(data[i].nOpenQuotesRAC){openQuotes[i]=data[i].nOpenQuotesRAC}else{openQuotes[i]=0}
      if(data[i].nOpenQuotesSalesRAC){openQuotesSales[i]=data[i].nOpenQuotesSalesRAC}else{openQuotesSales[i]=0}
      if(data[i].nOpenQuotesPotentialRAC){openQuotesPotential[i]=data[i].nOpenQuotesPotentialRAC}else{openQuotesPotential[i]=0}
      openQuotesPlot[i] = {x: labels[i], y: openQuotes[i]}
      openQuotesSalesPlot[i] = {x: labels[i], y: openQuotesSales[i]}
      openQuotesPotentialPlot[i] = {x: labels[i], y: openQuotesPotential[i]}
    }

    let openQuotesUpper = Math.max.apply(Math, openQuotes)
    let openQuotesLower = Math.min.apply(Math, openQuotes)
    let spread = openQuotesUpper - openQuotesLower
    openQuotesUpper = Math.ceil(openQuotesUpper + Math.max(1, spread*.1))
    openQuotesLower = Math.floor(openQuotesLower - Math.max(1, spread*.1))

    let openQuotesSalesUpper = Math.max.apply(Math, openQuotesSales)
    let openQuotesSalesLower = Math.min.apply(Math, openQuotesSales)
    spread = openQuotesSalesUpper - openQuotesSalesLower
    openQuotesSalesUpper = Math.ceil((openQuotesSalesUpper + Math.max(1, spread*.1))/10000)*10000
    openQuotesSalesLower = Math.floor((openQuotesSalesLower - Math.max(1, spread*.1))/10000)*10000

    let openQuotesPotentialUpper = Math.max.apply(Math, openQuotesPotential)
    let openQuotesPotentialLower = Math.min.apply(Math, openQuotesPotential)
    spread = openQuotesPotentialUpper - openQuotesPotentialLower
    openQuotesPotentialUpper = Math.ceil((openQuotesPotentialUpper + Math.max(1, spread*.1))/1000)*1000
    openQuotesPotentialLower = Math.floor((openQuotesPotentialLower - Math.max(1, spread*.1))/1000)*1000

    let labelsUpper = Math.ceil(Math.max.apply(Math, labels)/1000/3600/24)*1000*3600*24
    let labelsLower = Math.floor(Math.min.apply(Math, labels)/1000/3600/24)*1000*3600*24
    

    this.setState({
      showChart: "Open Quotes",
      labels: labels,
      openQuotes: openQuotes,
      openQuotesSales: openQuotesSales,
      openQuotesPotential: openQuotesPotential,
      openQuotesUpper: openQuotesUpper,
      openQuotesLower: openQuotesLower,
      openQuotesSalesUpper: openQuotesSalesUpper,
      openQuotesSalesLower: openQuotesSalesLower,
      openQuotesPotentialUpper: openQuotesPotentialUpper,
      openQuotesPotentialLower: openQuotesPotentialLower,
      openQuotesPlot: openQuotesPlot,
      openQuotesSalesPlot: openQuotesSalesPlot,
      openQuotesPotentialPlot: openQuotesPotentialPlot,
      labelsUpper: labelsUpper,
      labelsLower: labelsLower,
      dates: dates,
    })
  }

  render() {
    return (
      <Wrapper>
        <SubTitle>{this.state.showChart ? this.state.showChart : "No Chart"}</SubTitle>
        <div>
        <div style={{display: 'inline-block', border: '1px solid black', padding: 5, margin: 5}} onClick={()=>this.setState({showChart: "Open Quotes"})}>Open Quotes</div>
        <div style={{display: 'inline-block', border: '1px solid black', padding: 5, margin: 5}} onClick={()=>this.setState({showChart: "Open Quotes Sales $"})}>Open Quotes Sales $</div>
        <div style={{display: 'inline-block', border: '1px solid black', padding: 5, margin: 5}} onClick={()=>this.setState({showChart: "Open Quotes Potential $"})}>Open Quotes Potential $</div>
        </div>
        
        {this.state.showChart && (
          <>

          {/* Start Open Quotes Chart */}
          {this.state.showChart === "Open Quotes" && (
            <Scatter
            data={{
              datasets: [
                {
                  type: "line",
                  lineTension: 0,
                  data: this.state.openQuotesPlot,
                  label: '# Open Quotes',
                  borderColor: '#666',
                  fill: false,
                  yAxisID: 'y-axis-1',
                },
              ],
            }}
              width={100}
              height={35}
              options={{
                tooltips: {
                  callbacks: {
                    label: function(item) {                        
                      return dateFormatter(parseInt(item.label)) + ": " + parseInt(item.value).toLocaleString("en-US", {
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                      })
                    }
                  }
                },
                scales: {
                  xAxes: [
                    {
                      type: 'linear',
                      display: true,
                      position: 'bottom',
                      id: 'x-axis-1',
                      ticks: {
                        max: this.state.labelsUpper,
                        min: this.state.labelsLower,
                        stepSize: 1000*3600*24,
                        callback: function(label, index, labels) {
                          return dateFormat(label)
                        }
                      }
                    }
                    
                  ],
                  yAxes: [
                    {
                      type: 'linear',
                      display: true,
                      position: 'left',
                      id: 'y-axis-1',
                      ticks: {
                        max: this.state.openQuotesUpper,
                        min: this.state.openQuotesLower,
                        callback: function(label, index, labels) {
                          return label.toLocaleString("en-US", {
                            maximumFractionDigits: 0,
                            minimumFractionDigits: 0,
                          })
                        }
                      },
                    },
                  ], 
                },
                               
              responsive: true,
              maintainAspectRatio: true,
              //bezierCurve: false,
              title: {
                display: false,
                fontSize: 16,
                text: "# Open Quotes"
              },
              legend: {
                display: true,
              },
              }}
          />
          )}          
          {/* End Open Quotes Chart */}

          {/* Start Open Quotes Sales Chart */}
          {this.state.showChart === "Open Quotes Sales $" && (
            <Scatter
            data={{
              datasets: [
                {
                  type: "line",
                  lineTension: 0,
                  data: this.state.openQuotesSalesPlot,
                  label: 'Open Quotes Total $',
                  borderColor: '#666',
                  fill: false,
                  yAxisID: 'y-axis-1',
                },
              ],
            }}
              width={100}
              height={35}
              options={{
                tooltips: {
                  callbacks: {
                    label: function(item) {                        
                      return dateFormatter(parseInt(item.label)) + ": " + parseInt(item.value).toLocaleString("en-US", {
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                      })
                    }
                  }
                },
                scales: {
                  xAxes: [
                    {
                      type: 'linear',
                      display: true,
                      position: 'bottom',
                      id: 'x-axis-1',
                      ticks: {
                        max: this.state.labelsUpper,
                        min: this.state.labelsLower,
                        stepSize: 1000*3600*24,
                        callback: function(label, index, labels) {
                          return dateFormat(label)
                        }
                      }
                    }
                    
                  ],
                  yAxes: [
                    {
                      type: 'linear',
                      display: true,
                      position: 'left',
                      id: 'y-axis-1',
                      ticks: {
                        max: this.state.openQuotesSalesUpper,
                        min: this.state.openQuotesSalesLower,
                        callback: function(label, index, labels) {
                          return label.toLocaleString("en-US", {
                            maximumFractionDigits: 0,
                            minimumFractionDigits: 0,
                          })
                        }
                      },
                    },
                  ], 
                },
                               
              responsive: true,
              maintainAspectRatio: true,
              //bezierCurve: false,
              title: {
                display: false,
                fontSize: 16,
                text: "Open Quotes Total $"
              },
              legend: {
                display: true,
              },
              }}
          />
          )}          
          {/* End Open Quotes Sales Chart */}

          {/* Start Open Quotes Potential Chart */}
          {this.state.showChart === "Open Quotes Potential $" && (
            <Scatter
            data={{
              datasets: [
                {
                  type: "line",
                  lineTension: 0,
                  data: this.state.openQuotesPotentialPlot,
                  label: 'Open Quotes Potential $',
                  borderColor: '#666',
                  fill: false,
                  yAxisID: 'y-axis-1',
                },
              ],
            }}
              width={100}
              height={35}
              options={{
                tooltips: {
                  callbacks: {
                    label: function(item) {                        
                      return dateFormatter(parseInt(item.label)) + ": " + parseInt(item.value).toLocaleString("en-US", {
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                      })
                    }
                  }
                },
                scales: {
                  xAxes: [
                    {
                      type: 'linear',
                      display: true,
                      position: 'bottom',
                      id: 'x-axis-1',
                      ticks: {
                        max: this.state.labelsUpper,
                        min: this.state.labelsLower,
                        stepSize: 1000*3600*24,
                        callback: function(label, index, labels) {
                          return dateFormat(label)
                        }
                      }
                    }
                    
                  ],
                  yAxes: [
                    {
                      type: 'linear',
                      display: true,
                      position: 'left',
                      id: 'y-axis-1',
                      ticks: {
                        max: this.state.openQuotesPotentialUpper,
                        min: this.state.openQuotesPotentialLower,
                        callback: function(label, index, labels) {
                          return label.toLocaleString("en-US", {
                            maximumFractionDigits: 0,
                            minimumFractionDigits: 0,
                          })
                        }
                      },
                    },
                  ], 
                },
                               
              responsive: true,
              maintainAspectRatio: true,
              //bezierCurve: false,
              title: {
                display: false,
                fontSize: 16,
                text: "Open Quotes Potential $"
              },
              legend: {
                display: true,
              },
              }}
          />
          )}          
          {/* End Open Quotes Potential Chart */}

        </>
        )}
      </Wrapper>
    );
  }
}

export default RacHistoryCharts;
