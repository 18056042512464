import React, { Component } from 'react'
import { getSQLData, setSQLData } from '../../../assets/functions/fetch';
import { nQuoteTotalNoDollar } from '../../../assets/functions/calculations';
import moment from 'moment';

export default class UnpaidButClosed extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loaded: false,
        };

      }

      componentDidMount() {
        this.fetchData()
      }

      fetchData = async() => {
        try {
            const fetch = await getSQLData("accounting/getUnpaidButClosed", {

            })

            if(fetch){
                let data = fetch.data.data[0].recordset

                this.setState({
                    data: data,
                }, ()=>this.processData())
            }
            
        } catch (error) {

        }
      }

      processData() {
        let data = this.state.data
        

        this.setState({
            data: data,
            loaded: true,
        })

      }

      markAsPaid = async(index) => {

        try {

          const update = await setSQLData("accounting/markAsPaid", {
            id: this.state.data[index].id,
            repID: this.props.user,
          })

          if(update){
            this.fetchData()
          }

        } catch (error) {

        }
      }

  render() {
    return (
      <>
      {this.state.loaded && (

     
        <table>
          <thead>
            <tr>
              <td>Quote</td>
              <td>Customer</td>
              <td>Price</td>
              <td>First Closed Date</td>
              <td></td>
            </tr>
          </thead>
          {this.state.data.map((quote, index)=>(
            <tr>
              <td>{quote.id}</td>
              <td>{quote.sCompany}</td>
              <td>{nQuoteTotalNoDollar(quote.nQuoteTotal)}</td>
              <td>{moment(quote.dtFirstClosed).utc().format('MM/DD/YYYY')}</td>
              <td><button onClick={()=>this.markAsPaid(index)}>Mark as Paid</button></td>
            </tr>
          ))}
        </table>
         )}
      </>
    )
  }
}
