import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin: 0;
  width: 100%;
  height: 706px;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.1);
  text-align: center;
  border-radius: 15px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4aabed;
  color: #fff;
  height: 35px;
  font-weight: bold;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
`;

const Form = styled.form`
  margin: 1em;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 98%;
  margin: 1em 0.5em;

  input {
    height: 37px;
    width: 80%;
    padding: 0 0.7em;
    border: 1px solid #fff;
    border-radius: 5px;
    transition: box-shadow 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    background-color: #f2f5f7;
  }

  textarea {
    height: 100px;
    width: 80%;
    padding: 0 0.7em;
    border: 1px solid #fff;
    border-radius: 5px;
    transition: box-shadow 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    background-color: #f2f5f7;
    resize: none;
  }

  p {
    width: 20%;
    text-align: left;
    margin: 0;
    font-weight: 500;
  }

  div {
    width: 80%;
    margin: 0;
    padding: 0;

    input {
      width: 100%;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  button {
    cursor: pointer;
    width: 50%;
    margin: 0.7em 0.2em;
    height: 50px;
    border: none;
    transition: all 0.2s;
    border-radius: 4px;
    background-color: #e8edf1;

    &:hover {
      background-color: #4aabed;
      color: #fff;
    }
  }
`;

export default class DeliveryInfoPane extends Component {
  state = {};

  render() {
    return (
      <Wrapper>
        <Title>Delivery Information</Title>
        <Form autoComplete="off">
          <div>
            <InputWrapper>
              <p>Company:</p>
              <input
                id="dCompany"
                name="dCompany"
                type="text"
                value={this.props.sDCompany}
                onChange={(event) => this.props.update("sDCompany", event.target.value)}
              ></input>
            </InputWrapper>
            <InputWrapper>
              <p>Name:</p>
              <input
                id="dName"
                name="dName"
                type="text"
                value={this.props.sDName}
                onChange={(event) => this.props.update("sDName", event.target.value)}
              ></input>
            </InputWrapper>
            <InputWrapper>
              <p>Address:</p>
              <div>
                <input
                  id="dAddress"
                  name="dAddress"
                  type="text"
                  value={this.props.sDAddress}
                  onChange={(event) => this.props.update("sDAddress", event.target.value)}
                ></input>
                <input
                  id="dAddress2"
                  name="dAddress2"
                  type="text"
                  value={this.props.sDAddress2}
                  onChange={(event) => this.props.update("sDAddress2", event.target.value)}
                ></input>
              </div>
            </InputWrapper>
            <InputWrapper>
              <p>City:</p>
              <input
                id="dCity"
                name="dCity"
                type="text"
                value={this.props.sDCity}
                onChange={(event) => this.props.update("sDCity", event.target.value)}
              ></input>
            </InputWrapper>
            <InputWrapper>
              <p>State:</p>
              <input
                id="dState"
                name="dState"
                type="text"
                value={this.props.sDState}
                onChange={(event) => this.props.update("sDState", event.target.value)}
              ></input>
            </InputWrapper>
            <InputWrapper>
              <p>Zip:</p>
              <input
                id="dZip"
                name="dZip"
                type="text"
                value={this.props.sDZip}
                onChange={(event) => this.props.update("sDZip", event.target.value)}
              ></input>
            </InputWrapper>
            <InputWrapper>
              <p>Phone:</p>
              <input
                id="dPhone"
                name="dPhone"
                type="text"
                value={this.props.sDPhone}
                onChange={(event) => this.props.update("sDPhone", event.target.value)}
              ></input>
            </InputWrapper>
            <InputWrapper>
              <p>Alt Info:</p>
              <input
                id="dAltPhone"
                name="dAltPhone"
                type="text"
                value={this.props.sAltNamePhone}
                onChange={(event) => this.props.update("sAltNamePhone", event.target.value)}
              ></input>
            </InputWrapper>
            <InputWrapper>
              <p>Notes:</p>
              <textarea
                id="dNotes"
                name="dNotes"
                type="text"
                value={this.props.sDNotes}
                onChange={(event) => this.props.update("sDNotes", event.target.value)}
              ></textarea>
            </InputWrapper>
            <ButtonWrapper>
              <button>Copy Customer</button>
              <button>Copy Delivery</button>
            </ButtonWrapper>
          </div>
        </Form>
      </Wrapper>
    );
  }
}
