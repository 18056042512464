import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Axios from 'axios';
import Moment from 'moment';
import Loader from 'react-loader-spinner';
import { whichRep, abbreviateBrand, calcMilesFromLatLong } from '../../assets/functions/calculations';
import MediaQuery from 'react-responsive';
import { thisWeekEnd } from '../../assets/functions/time';
import HeatMap from '../misc/HeatMap';

const PageWrapper = styled.div`
  display: block;
  position: fixed;
  z-index: 50;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: #222;
  opacity: 0.5;
`;

const Modal = styled.div`
  display: block;
  position: fixed;
  z-index: 55;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 90%;
  overflow: hidden;
  background-color: #fff;
  opacity: 1;
  border-radius: 10px;

`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: left;
  background-color: #fefefe;
  margin: auto;
  padding: 0.5em;
  width: 90%;
  overflow: hidden;

  h2 {
    text-align: center;
  }

  p {
    text-align: left;
    font-size: 1rem;
    margin-top: 1em;
  }

  span {
    font-weight: bold;
  }

  input {
    width: 100%;
    max-width: 400px;
    padding: 0.7em;
    margin: 0.5em auto 0 auto;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  select {
    width: 100%;
    max-width: 400px;
    padding: 0.7em;
    margin: 0.5em auto 0 auto;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
`;

const Wrapper = styled.section`
  padding: 1em 0em;
  text-align: center;

  @media (max-width: 900px) {
    padding-bottom: 5em;
  }
`;

const DashboardControls = styled.section`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin: 3em auto 0 auto;
  padding: 2em 4em;
  width: fit-content;
  height: fit-content;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.1);
  text-align: center;
  border-radius: 15px;

  button {
    display: inline-block;
     padding: 0.5em 1.2em;
     margin: 0 0.3em 0.3em 0;
     text-decoration: none;
     color: #333;
     text-align: center;
     transition: all 0.2s;
    background-color: #f2f5f7;
    border: 1px solid rgba(255, 255, 255, 0);
    cursor: pointer;

    &:hover {
      background-color: #36a2eb;
      border: 1px solid #36a2eb;
      color: #fff;
    }
  }

  select {
    display: inline-block;
    color: #333;
     text-align: center;
     padding: 0.3em 1.2em;
     margin: 0 0.3em 0.3em 0;
    background-color: #f2f5f7;
    border: 1px solid #f2f5f7;
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
      background-color: #36a2eb;
      border: 1px solid #36a2eb;
      color: #fff;
    }
  }
`;

const LoaderWrapper = styled.div`
  margin-top: 25vh;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 1em;
`;

const SubTitle = styled.h2`
  padding: 2em 0 0 0;
  text-align: center;
`;

const CustomDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    margin: 1em 0;
  }

  input {
    width: 140px;
     padding: 0.2em 0em 0.2em 0.2em;
     margin: 0 0 0.5em 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;

    @media (max-width: 900px) {
      width: auto;
      margin: auto;
    }
  }
`;

const AlertMessage = styled.p`
  font-weight: bold;
`;

class AllQuotes extends Component {
  state = {
    data: [],
    loaded: false,
    titleLoaded: true,
    nRep: 0,
    sQuoteType: 'All',
    repDefaultValue: 'Select Rep',
    brandDefaultValue: 'Select Brand',
    sort: 'dtDate',
    sortOrder: 'desc',
    repsWithQuotes: [],
    bRemoveProspects: true,
    probs: [],
    maxDistance: 0,
    homeZip: 45005,
    searchZip: 45005,
    showHeatMap: false,
    quotes: 0,
    sent: 0,
  };

  // Async Axios
  fetchData = async (nRep, sQuoteType, bRemoveProspects) => {
    try {
      const response = await Axios.get('/all-quotes', {
        params: {
          nRep: nRep,
          sQuoteType: sQuoteType,
          bRemoveProspects: bRemoveProspects
        },
        headers: { 'Content-Type': 'application/json' },
      });
      // Sets the state using the response data
      this.setState({
        data: response.data.result[0].recordset,
        zip: response.data.result[1].recordset,
        titleLoaded: true,
      }, () => this.initData());
    } catch (error) {
      console.error(error);
    }
  };

  // Async Axios
  fetchReps = async () => {
    try {
      const response = await Axios.get('/all-quotes/reps-with-quotes', {
        headers: { 'Content-Type': 'application/json' },
      });
      // Sets the state using the response data
      this.setState({
        repsWithQuotes: response.data.result[0].recordset
      });
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    let probs = []
    probs[0] = 0    // Not currently used
    probs[1] = 0.01 // Really long shot - barely worth keeping open
    probs[2] = 0.05 // Half as likely as average quote
    probs[3] = 0.1  // Average quote closure rate
    probs[4] = 0.5  // 50/50 shot
    probs[5] = 0.9  // Sure thing - but we're not always right

    this.setState({probs: probs})

    this.fetchReps();
    this.fetchData(this.state.nRep, this.state.sQuoteType, this.state.bRemoveProspects);
    this.interval = setInterval(
      () => this.fetchData(this.state.nRep, this.state.sQuoteType, this.state.bRemoveProspects),
      10000
    );
  }

  componentWillUnmount() {
    // Stop fetching data when the user leaves the page and component unmounts
    clearInterval(this.interval);
  }

  initData(){
    console.log(this.state.zip)

    let search = {}
    let quotes = 0
    let sent = 0

    if(this.state.searchZip.toString().length === 5){
      search = this.state.zip.find(({sZipCode}) => sZipCode === this.state.searchZip.toString())
    }  else {
      search = this.state.zip.find(({sZipCode}) => sZipCode === this.state.homeZip.toString())
    }
 
    let data = this.state.data


    for(let i=0; i<this.state.data.length; i++){
      let location = {}
      data[i].distance = -1
      if (this.state.data[i].sDZip){
        location = this.state.zip.find(({sZipCode}) => sZipCode === this.state.data[i].sDZip)
      } else {
        location = this.state.zip.find(({sZipCode}) => sZipCode === this.state.data[i].sZip)
      }
      if(location){
        data[i].distance = calcMilesFromLatLong(
          {latitude: location.sLatitude, longitude: location.sLongitude},
          {latitude: search.sLatitude, longitude: search.sLongitude}
          )
        data[i].lat = location.sLatitude
        data[i].lng = location.sLongitude        
      }

      data[i].dtDateStamp = Moment(data[i].dtDateStamp).valueOf()
      data[i].dtFollowup = Moment(data[i].dtFollowup).valueOf()
      if(isNaN(data[i].dtFollowup)){data[i].dtFollowup = 0}
      data[i].dtDate = Moment(data[i].dtDate).valueOf()
      
      quotes++
      if(data[i].dtSentDate){sent++}
      if(data[i].nQuoteTotal > 0){
        
        if(!data[i].nLikelyToClose){
          data[i].nPotential = data[i].nQuoteTotal * this.state.probs[3] / 100
        } else {
          data[i].nPotential = data[i].nQuoteTotal * this.state.probs[data[i].nLikelyToClose] / 100
        }

      } else {
        data[i].nPotential = 0
        
      }
    }
    console.log(data)
    this.setState({
      data: data, 
      loaded: true,
      quotes: quotes,
      sent: sent,
    })
    
  }

  handleRepChange(e) {
    this.setState(
      {
        nRep: parseInt(e.target.value),
        repDefaultValue: whichRep(e.target.value),
        titleLoaded: false,
      },
      () => this.fetchData(this.state.nRep, this.state.sQuoteType, this.state.bRemoveProspects)
    );
  }

  handlesQuoteTypeChange(e) {
    if (e.target.value === 'Rentacomputer.com') {
      this.setState(
        {
          sQuoteType: 'Rentacomputer.com',
          brandDefaultValue: e.target.value,
          titleLoaded: false,
        },
        () => this.fetchData(this.state.nRep, this.state.sQuoteType, this.state.bRemoveProspects)
      );
    } else if (e.target.value === 'CameraSecurityNow.com') {
      this.setState(
        {
          sQuoteType: 'CameraSecurityNow.com',
          brandDefaultValue: e.target.value,
          titleLoaded: false,
        },
        () => this.fetchData(this.state.nRep, this.state.sQuoteType, this.state.bRemoveProspects)
      );
    } else if (e.target.value === 'ComputerServiceNow.com') {
      this.setState(
        {
          sQuoteType: 'ComputerServiceNow.com',
          brandDefaultValue: e.target.value,
          titleLoaded: false,
        },
        () => this.fetchData(this.state.nRep, this.state.sQuoteType, this.state.bRemoveProspects)
      );
    } else if (e.target.value === 'All') {
      this.setState(
        {
          sQuoteType: 'All',
          brandDefaultValue: e.target.value,
          titleLoaded: false,
        },
        () => this.fetchData(this.state.nRep, this.state.sQuoteType, this.state.bRemoveProspects)
      );
    }
  }

  hideShowProspects() {
    this.setState({bRemoveProspects: !this.state.bRemoveProspects},
    () => this.fetchData(this.state.nRep, this.state.sQuoteType, this.state.bRemoveProspects))
  }

  sortBy(getSort){
    if(this.state.sort === getSort){
      if(this.state.sortOrder === "asc"){this.setState({sortOrder: "desc"})}else{this.setState({sortOrder: "asc"})}
    } else {
      this.setState({sort: getSort, sortOrder:"desc"})
    }
  }

  handleDateChange = (event, id) => {
    this.setState({
      [`customDate + ${id}`]: event.target.value,
    });
  };


  handleSentSubmitDate = (date, id) => {
    console.log(date)
    if (this.state[`customDate + ${id}`] === undefined) {
      this.setState({
        [`flashSentMessage2 + ${id}`]: 'Please change the date!',
      });
      this.interval = setInterval(
        () =>
          this.setState({
            [`flashSentMessage2 + ${id}`]: '',
          }),
        3000
      );
    } else {
      Axios({
        method: 'post',
        url: '/cam-operations/quote-sent-date',
        data: {
          dtSentDate: date,
          id: id,
        },
      });
      this.fetchData();
      this.setState({
        [`flashSentMessage + ${id}`]: 'Date changed!',
      });
      this.interval = setInterval(
        () =>
          this.setState({
            [`flashSentMessage + ${id}`]: '',
          }),
        3000
      );
    }
  };

  handleSentRemoveDate = (id) => {
    Axios({
      method: 'post',
      url: '/cam-operations/quote-sent-delete-date',
      data: {
        id: id,
      },
    });
    this.fetchData();
    this.setState({
      [`flashSentMessage + ${id}`]: 'Date removed!',
    });
    this.interval = setInterval(
      () =>
        this.setState({
          [`flashSentMessage + ${id}`]: '',
        }),
      3000
    );
  };

  updateTag(getTag, getIndex) {
    let data = this.state.data

    data[getIndex].sQuoteTags = getTag
    data[getIndex].changed = true

    this.setState({data: data})
  }

  saveTag(getRecord, getIndex){
    Axios({
      method: 'post',
      url: '/save-tag',
      data: {
        data: getRecord
      },
    });
    let data = this.state.data
    data[getIndex].changed = false
    this.setState({data: data})

  }


  render() {
    let sumTotal = 0;
    let sumPotential = 0;
    let unrankedPotential = 0;
    let data;
    let sort = this.state.sort

    if(this.state.loaded){

      switch(this.state.sort){
        case 'distance':

          if(this.state.sortOrder === 'desc'){
            data = this.state.data.sort(function (a, b) {
              return b[sort] > a[sort] ? 1 : b[sort] < a[sort] ? -1 : 0;
            });
          } else {
            data = this.state.data.sort(function (a, b) {
              return a[sort] == -1 ? 1 : (b[sort] < a[sort] ? 1 : (b[sort] > a[sort] ? -1 : 0))
            });
          }

        break;
        case 'nLikelyToClose':

          if(this.state.sortOrder === 'desc'){
            data = this.state.data.sort(function (a, b) {
              return a[sort] == b[sort] ? 0 :( a[sort] < 1 ? (b[sort] > 3 ? 1 : b[sort] < 3 ? -1 : 0) : (b[sort] > a[sort] ? 1 : b[sort] < a[sort] ? -1 : 0))
            });
          } else {
            data = this.state.data.sort(function (a, b) {
              return a[sort] == b[sort] ? 0 : (a[sort] < 1 ? (b[sort] < 3 ? 1 : b[sort] > 3 ? -1 : 0) : (b[sort] < a[sort] ? 1 : b[sort] > a[sort] ? -1 : 0));
            });
          }

        break;
        default:

          if(this.state.sortOrder === 'desc'){
            data = this.state.data.sort(function (a, b) {
              return b[sort] > a[sort] ? 1 : b[sort] < a[sort] ? -1 : 0;
            });
          } else {
            data = this.state.data.sort(function (a, b) {
              return b[sort] > a[sort] ? -1 : b[sort] < a[sort] ? 1 : 0;
            });
          }

      }
    }
    


    for (let i = 0; i < this.state.data.length; i++) {
      sumTotal += this.state.data[i].nQuoteTotal;

      if(this.state.data[i].nLikelyToClose){
        sumPotential += this.state.data[i].nQuoteTotal * this.state.probs[this.state.data[i].nLikelyToClose]
      } else {
        sumPotential += this.state.data[i].nQuoteTotal * this.state.probs[3]
        unrankedPotential += this.state.data[i].nQuoteTotal * this.state.probs[3]
      }
    }


    return (
      <Wrapper>
        {this.state.showHeatMap && (
          <>
          <PageWrapper></PageWrapper>
          <Modal>
            <ModalContent>
              <button onClick={() => this.setState({showHeatMap: !this.state.showHeatMap})}>Hide Heat Map</button>
            </ModalContent>
            <ModalContent>
            <HeatMap 
              points={this.state.data}
            />
            </ModalContent>
          </Modal>
          
          </>
        )}
        {this.state.loaded || (
          <LoaderWrapper>
            <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} />
          </LoaderWrapper>
        )}
        {this.state.loaded && (
          <>
            <DashboardControls>
              <div>
                <Title>All Quotes</Title>
              </div>
              <div>
                <button
                  onClick={() => {
                    this.setState(
                      { nRep: 0, repDefaultValue: 'Select Rep' },
                      () =>
                        this.fetchData(this.state.nRep, this.state.sQuoteType)
                    );
                  }}
                >
                  All Reps
                </button>
                <select
                  id="rep"
                  name="rep"
                  defaultValue={this.state.repDefaultValue}
                  value={this.state.repDefaultValue}
                  onChange={(e) => {
                    this.handleRepChange(e);
                  }}
                >
                  <option value="Select Rep" disabled hidden>
                    Select Rep
                  </option>
                  {this.state.repsWithQuotes.map((sql) => (
                    <option value={sql.nRep}>{whichRep(sql.nRep)}</option>
                  ))}
                </select>
                <button
                  onClick={() => {
                    this.setState(
                      { sQuoteType: 'All', brandDefaultValue: 'Select Brand' },
                      () =>
                        this.fetchData(this.state.nRep, this.state.sQuoteType)
                    );
                  }}
                >
                  All Brands
                </button>
                <select
                  id="sQuoteType"
                  name="sQuoteType"
                  defaultValue={this.state.brandDefaultValue}
                  value={this.state.brandDefaultValue}
                  onChange={(e) => {
                    this.handlesQuoteTypeChange(e);
                  }}
                >
                  <option value="Select Brand" disabled hidden>
                    Select Brand
                  </option>
                  <option value="All">All</option>
                  <option value="Rentacomputer.com">RAC</option>
                  <option value="CameraSecurityNow.com">CAM</option>
                  <option value="ComputerServiceNow.com">CSN</option>
                </select>
              </div>
              <div>
                <button onClick={() => this.setState({showHeatMap: !this.state.showHeatMap})}>Show Heat Map</button>
                  <div>
                    Max Distance: <input value={this.state.maxDistance} onChange={(event) => this.setState({maxDistance: event.target.value})}></input>
                  </div>
                  <div>
                    From Zip: <input value={this.state.searchZip} onChange={(event) => this.setState({searchZip: event.target.value}, () => (this.state.searchZip.toString().length===5 && this.calcDist()))}></input>
                  </div>
              </div>
              <div>
                {((this.state.bRemoveProspects && (
                  <button onClick={() => this.hideShowProspects()}>Show Prospects</button>
                )) || (
                  <button onClick={() => this.hideShowProspects()}>Hide Prospects</button>
                ))}
              </div>
            </DashboardControls>
            <SubTitle>
              {this.state.titleLoaded ? (
                <>
                  {this.state.nRep == 0 ? (
                    <>
                      {this.state.sQuoteType != 'All' ? (
                        <>
                          All {this.state.data.length} open quotes for{' '}
                          {this.state.sQuoteType}  ({this.state.sent} sent = {Math.round(this.state.sent/this.state.quotes*100)}%) - Total: $
                          {sumTotal.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })} - Potential: $
                          {sumPotential.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })} ({Math.round(unrankedPotential / sumPotential*10000)/100}% unranked)
                          
                        </>
                      ) : (
                        <>
                          All {this.state.quotes} open quotes ({this.state.sent} sent = {Math.round(this.state.sent/this.state.quotes*100)}%) - Total: $
                          {sumTotal.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })} - Potential: $
                          {sumPotential.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })} ({Math.round(unrankedPotential / sumPotential*10000)/100}% unranked)
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {this.state.sQuoteType != 'All' ? (
                        <>
                          {whichRep(this.state.nRep)}'s {this.state.quotes}{' '}
                          open quotes for {this.state.sQuoteType}  ({this.state.sent} sent = {Math.round(this.state.sent/this.state.quotes*100)}%) - Total: $
                          {sumTotal.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </>
                      ) : (
                        <>
                          {whichRep(this.state.nRep)}'s {this.state.quotes}{' '}
                          open quotes ({this.state.sent} sent = {Math.round(this.state.sent/this.state.quotes*100)}%) - Total: $
                          {sumTotal.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <Loader
                  type="ThreeDots"
                  color="#00BFFF"
                  height={10}
                  width={80}
                />
              )}
            </SubTitle>
            <table class="dashboard">
              <thead>
                <tr>
                  <th onClick={() => this.sortBy('id')}>Quote #</th>
                  <th onClick={() => this.sortBy('sCompany')}>Company</th>
                  <MediaQuery minDeviceWidth={900}>
                    <th onClick={() => this.sortBy('sState')} width={150}>Location</th>
                    <th onClick={() => this.sortBy('distance')}>Miles</th>
                    <th onClick={() => this.sortBy('sTitle')} width={100}>Title</th>
                  </MediaQuery>
                  <th onClick={() => this.sortBy('nQuoteTotal')}>Quote $</th>
                  <th onClick={() => this.sortBy('nPotential')}>Potential</th>
                  <th onClick={() => this.sortBy('nRep')} style={{ width: 'auto' }}>Rep</th>
                  <th onClick={() => this.sortBy('sQuoteType')} style={{ cursor: 'pointer', width: 'auto' }}>Brand</th>
                  <MediaQuery minDeviceWidth={900}>
                    <th onClick={() => this.sortBy('nLikelyToClose')}><i class='fas fa-star'></i></th>
                    <th onClick={() => this.sortBy('dtDateStamp')}>RFQ Date</th>
                    <th onClick={() => this.sortBy('dtFollowup')}>Next FU</th>
                    <th onClick={() => this.sortBy('dtDate')}>Last Activity</th>
                  </MediaQuery>
                  <th onClick={() => this.sortBy('sQuoteTags')}>Quote Tags</th>
                  <th onClick={() => this.sortBy('dtSentDate')}>Quote Sent</th>
                </tr>
              </thead>
              <tbody>
                {data.map((sql, index) => (
                  <>
                  {(parseInt(this.state.maxDistance) <= 0 ||(parseInt(this.state.maxDistance) > 0 && sql.distance <= parseInt(this.state.maxDistance))) && (
                  <tr key={sql.id}>
                    <td>
                      <Link target="_blank" to={`/quote/${sql.id}`}>
                        {sql.id}
                      </Link>
                    </td>
                    <td>{sql.sCompany}</td>
                    <MediaQuery minDeviceWidth={900}>
                      <td>
                        {this.props.sDAddress ? (
                          <a
                            target="_blank"
                            href={`http://maps.google.com/?q=${sql.sDAddress} ${sql.sDCity}, ${sql.sDState} ${sql.sDZip}`}
                          >
                            {sql.sDAddress} {sql.sDCity} {sql.sDState}{' '}
                            {sql.sDZip}
                          </a>
                        ) : (
                          <a
                            target="_blank"
                            href={`http://maps.google.com/?q=${sql.sAddress} ${sql.sCity}, ${sql.sState} ${sql.sZip}`}
                          >
                            {sql.sAddress} {sql.sCity} {sql.sState} {sql.sZip}
                          </a>
                        )}
                      </td>
                      <td>{Math.round(sql.distance)}</td>
                      <td>{sql.sQuoteTitle}</td>
                    </MediaQuery>
                    <td>
                      {sql.nQuoteTotal ? (
                        <>
                          $
                          {sql.nQuoteTotal.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </>
                      ) : null}
                    </td>
                    <td>
                      {Math.round(sql.nPotential)}
                    </td>
                    <td style={{ width: 'auto' }}>
                      {sql.nRep ? (
                        <>{whichRep(sql.nRep).match(/\b\w/g).join('')}</>
                      ) : null}
                    </td>
                    <td style={{ width: 'auto' }}>
                      {abbreviateBrand(sql.sQuoteType)}
                    </td>
                    <MediaQuery minDeviceWidth={900}>
                      <td>{sql.nLikelyToClose}</td>
                      <td>
                        {sql.dtDateStamp ? (
                          <>
                            {Moment(sql.dtDateStamp).fromNow()}
                          </>
                        ) : null}
                      </td>
                      <td>
                        {sql.dtFollowup ? (
                          <>
                            {Moment(sql.dtFollowup).fromNow()}
                          </>
                        ) : null}
                      </td>
                      <td>
                        {sql.dtDate ? (
                          <>
                            {Moment(sql.dtDate).fromNow()}
                          </>
                        ) : null}
                      </td>
                    </MediaQuery>
                    <td>
                      <textarea style={{height: '80px', width: '100%'}} onInput={(event) => this.updateTag(event.target.value, index)}>{sql.sQuoteTags}</textarea>
                      {sql.changed && (<div onClick={() => this.saveTag(sql, index)}>Save Tag</div>)}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                <CustomDateWrapper>
                  <input
                    onChange={(e) => {
                      this.handleDateChange(e, sql.id);
                    }}
                    type="date"
                    defaultValue={Moment(sql.dtSentDate)
                      .utc()
                      .format('YYYY-MM-DD')}
                  />
                </CustomDateWrapper>
                {this.state[`flashSentMessage + ${sql.id}`] ? (
                  <AlertMessage>
                    {this.state[`flashSentMessage + ${sql.id}`]}
                  </AlertMessage>
                ) : (
                  <>
                    {this.state[`flashSentMessage2 + ${sql.id}`] ? (
                      <AlertMessage>
                        {this.state[`flashSentMessage2 + ${sql.id}`]}
                      </AlertMessage>
                    ) : (
                      <div>
                        <span
                          onClick={() =>
                            this.handleSentSubmitDate(
                              this.state[`customDate + ${sql.id}`],
                              sql.id
                            )
                          }
                          style={{
                            textAlign: 'center',
                            cursor: 'pointer',
                            marginRight: '0.2em',
                          }}
                        >
                          Submit
                        </span>
                        <span
                          onClick={() => this.handleSentRemoveDate(sql.id)}
                          style={{
                            textAlign: 'center',
                            cursor: 'pointer',
                            marginLeft: '0.2em',
                          }}
                        >
                          Delete
                        </span>
                      </div>
                    )}
                  </>
                )}
              </td>
                  </tr>
                  )}
                  </>
                ))}
              </tbody>
            </table>
          </>
        )}
      </Wrapper>
    );
  }
}

export default AllQuotes;
