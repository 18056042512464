import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Axios from 'axios';
import Loader from 'react-loader-spinner';
import Moment from 'moment';
import { whichRep } from '../../../assets/functions/calculations';
import AddReview from '../../srm/AddReview';

const Button = styled.button`
  display: inline-block;
   padding: 0.8em 2.5em;
   margin: 1em 0.3em 1em 0;
   text-decoration: none;
   color: #fafafa;
   text-align: center;
   transition: all 0.2s;
  background-color: rgba(54, 162, 235, 0.9);
  border: 1px solid #fafafa;
  cursor: pointer;
  border-radius: 50px;

  &:hover {
    border: 1px solid #d7d7d7;
  }
`;

const Wrapper = styled.div`
  width: 99%;
  max-width: 1300px;
  margin: 4em auto;

  table {
    width: 100%;
    position: relative;
    ${(props) => {
      if (props.amount > 10)
        return `
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;

    thead + tbody tr:last-child td:last-child,
    tfoot tr:last-child td:last-child {
      border-bottom-right-radius: 0px !important;
    }
    thead + tbody tr:last-child td:first-child,
    tfoot tr:last-child td:first-child {
      border-bottom-left-radius: 0px !important;
    }
    margin-bottom: 7em;
        `;
    }}
  }

  }
`;

const SubTitle = styled.h2`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1em;

  i {
    font-size: 16px;
    margin-left: 7px;
  }
`;

export default class POsToReview extends Component {
  state = {
    data: [],
    loaded: false,
    showAll: false,
    updated: false,
  };

  // Async Axios
  fetchData = async (nRep) => {
    try {
      const response = await Axios.get('/recently-returned', {
        params: {
          nRep: 0,
        },
        headers: { 'Content-Type': 'application/json' },
      });
      // // Sets the state using the response data
      this.setState({
        data: response.data.result[0].recordset,
        loaded: true,
      });
      console.log("test")
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    this.fetchData(this.props.nRep);
  }

  updateSupplier = async (getPO, getSupplier) => {
      try {
        const response = await Axios.post(
          "/update-PO-supplier",
          {
            PO: getPO,
            supplier: getSupplier,
          },
        ).then(this.fetchData(this.props.nRep))

        
      } catch (error) {
        console.error(error)
      }
  }

  closeWindow = () => {
    this.setState({reviewing: false}, () => this.fetchData(this.props.nRep))
  }

  render() {
    let table;
    let today = new Date()

      table = (
        <>
          {this.state.data.map((sql) => (
            <tr style={{fontWeight: (Date.parse(sql.dtPickupDate) < today.getTime()-7*1000*3600*24 ? 'bold' : '')}}>
              {console.log(Date.parse(sql.dtPickupDate))}
              <td style={{ textAlign: 'center' }}>
                <Link target="_blank" to={`/quote/${sql.id}`}>
                  {sql.id}
                </Link>
              </td>
              <td>
                <Link
                  target="_blank"
                  to={`/crm/master-viewer/` + sql.nCompany}
                >
                  {sql.sCompany}
                </Link>
              </td>
              <td style={{ textAlign: 'center' }}>
                {sql.dtPickupDate
                  ? Moment(sql.dtPickupDate)
                      .utc()
                      .format('MM/DD/YYYY')
                  : null}
              </td>
              <td>                
                {whichRep(sql.nRep)}
              </td>
            </tr>
          ))}
        </>
      );


    return (
      <Wrapper amount={this.state.data.length}>
        <SubTitle>POs To Review</SubTitle>

        {this.state.reviewing && (
              <AddReview
                reviewSupplier={this.state.reviewSupplier}
                reviewName={this.state.reviewName}
                reviewQuote={this.state.reviewQuote}
                reviewCustomer={this.state.reviewCustomer}
                user={this.props.TAUser}
                closeWindow={this.closeWindow}
              />
            )}
        <table className="dashboard">
          <thead>
              <th>Quote</th>
              <th>Customer</th>
              <th>Pickup Date</th>
              <th>Rep</th>
          </thead>
          <tbody>
            {this.state.loaded || (
              <tr>
                <td colSpan="4">
                  <Loader
                    style={{
                      textAlign: 'center',
                    }}
                    type="ThreeDots"
                    color="#00BFFF"
                    height={20}
                    width={120}
                  />
                </td>
              </tr>
            )}
            {this.state.loaded && (
              <>
                {this.state.data.length > 0 ? (
                  <>{table}</>
                ) : (
                  <tr>
                    <td
                      colSpan="4"
                      style={{ textAlign: 'center', padding: '1em' }}
                    >
                      All Your Recently Returned POs Have Comments!
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </Wrapper>
    );
  }
}
