import React, { Component } from 'react';
import styled from 'styled-components';
import Moment from 'moment';
import {
  dayChangeStart,
  dayChangeEnd,
  lastWeekStart,
  lastWeekEnd,
  thisWeekStart,
  thisWeekEnd,
  thisMonthEnd,
  thisMonthStart,
  thisYearStart,
  thisYearEnd,
  getFormattedDate,
} from '../../assets/functions/time';
import CamCsnClosedOrders from '../tables/cam-csn-dashboard/CamCsnClosedOrders';
import CamCsnRfq from '../tables/cam-csn-dashboard/CamCsnRFQ';
import DiscontinuedQuotes from '../tables/misc/DiscontinuedQuotes';
import CamCsnLineChart from '../charts/line/camcsn-dashboard/CamCsnLineChart';
import LostCustomers from '../tables/cam-csn-dashboard/LostCustomers';
import CamHistoryCharts from '../charts/line/camcsn-dashboard/CamHistoryCharts';

const Wrapper = styled.section`
  text-align: center;
  padding: 0 0.4em 1em 0.4em;

  table {
    position: relative;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

    thead + tbody tr:last-child td:last-child,
    tfoot tr:last-child td:last-child {
      border-bottom-right-radius: 0px;
    }
    thead + tbody tr:last-child td:first-child,
    tfoot tr:last-child td:first-child {
      border-bottom-left-radius: 0px;
    }
  }
`;

const DashboardControls = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin: 3em auto 0 auto;
  padding: 2em 4em;
  width: fit-content;
  height: fit-content;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.1);
  text-align: center;
  border-radius: 15px;

  button {
    display: inline-block;
     padding: 0.5em 1.2em;
     margin: 0 0.3em 0.3em 0;
     text-decoration: none;
     color: #333;
     text-align: center;
     transition: all 0.2s;
    background-color: #f2f5f7;
    border: 1px solid rgba(255, 255, 255, 0);
    cursor: pointer;

    &:hover {
      background-color: #36a2eb;
      border: 1px solid #36a2eb;
      color: #fff;
    }
  }

  select {
    display: inline-block;
    color: #333;
     text-align: center;
     padding: 0.3em 1.2em;
     margin: 0 0.3em 0.3em 0;
    background-color: #f2f5f7;
    border: 1px solid #f2f5f7;
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
      background-color: #36a2eb;
      border: 1px solid #36a2eb;
      color: #fff;
    }
  }
`;

const Title = styled.h1`
  font-size: 2rem;
`;

const Time = styled.h1`
  padding: 0.5em 0 2em 0em;
  font-size: 1rem;

  i {
    margin: 0 1em;
    color: #c3d1da;
    cursor: pointer;
    transition: 0.1s;

    &:hover {
      color: #36a2eb;
    }
  }
`;

const CustomDateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1em 0 0 0;

  @media (max-width: 900px) {
    flex-direction: column;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 900px) {
      margin: 1em 0;
    }

    label {
      margin-right: 0.2em;
      font-weight: bold;
    }

    input {
      width: 200px;
       padding: 0.2em 1.2em;
       margin: 0 0.3em 0.3em 0;
      border: 1px solid #ccc;
      box-sizing: border-box;
      margin-top: 6px;
      margin-bottom: 16px;

      @media (max-width: 900px) {
        width: auto;
        margin: auto;
      }
    }
  }
`;

class CamCsnDashboard extends Component {
  state = {
    startDate: dayChangeStart(7),
    endDate: dayChangeEnd(0),
    showManualDate: false,
    customDateStart: '',
    customDateEnd: '',
    timeFrame: '',
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleChange(e) {
    if (e.target.value === 'Last Week') {
      this.setState({
        startDate: lastWeekStart(),
        endDate: lastWeekEnd(),
      });
    } else if (e.target.value === 'This Week') {
      this.setState({
        startDate: thisWeekStart(),
        endDate: thisWeekEnd(),
      });
    } else if (e.target.value === 'This Month') {
      this.setState({
        startDate: thisMonthStart(),
        endDate: thisMonthEnd(),
      });
    } else if (e.target.value === 'This Month-to-Date') {
      this.setState({
        startDate: thisMonthStart(),
        endDate: dayChangeEnd(0),
      });
    } else if (e.target.value === 'This Year') {
      this.setState({
        startDate: thisYearStart(),
        endDate: thisYearEnd(),
      });
    } else if (e.target.value === 'This Year-to-Date') {
      this.setState({
        startDate: thisYearStart(),
        endDate: dayChangeEnd(0),
      });
    } else if (e.target.value === 'The Last 365') {
      this.setState({
        startDate: dayChangeStart(365),
        endDate: dayChangeEnd(0),
      });
    } else if (e.target.value === 'The Last 30') {
      this.setState({
        startDate: dayChangeStart(30),
        endDate: dayChangeEnd(0),
      });
    } else {
      this.setState({
        showManualDate: true,
      });
    }
  }

  // Calculates time frame for quick change time buttons
  handleQuickTimeChange = (math) => (e) => {
    let start = Moment(this.state.startDate);
    let end = Moment(this.state.endDate);

    if (math === 'subtract') {
      if (
        this.state.timeFrame === 'Yesterday' ||
        this.state.timeFrame === 'Today'
      ) {
        this.setState({
          startDate: Moment(start)
            .subtract(1, 'days')
            .format('YYYY-MM-DD 00:00'),
          endDate: Moment(end).subtract(1, 'days').format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'Last 7 Days') {
        this.setState({
          startDate: Moment(start)
            .subtract(7, 'days')
            .format('YYYY-MM-DD 00:00'),
          endDate: Moment(end).subtract(7, 'days').format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'Last Week') {
        this.setState({
          startDate: Moment(start)
            .subtract(1, 'weeks')
            .startOf('week')
            .format('YYYY-MM-DD 00:00'),
          endDate: Moment(end)
            .subtract(1, 'weeks')
            .endOf('week')
            .format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'This Week') {
        this.setState({
          startDate: Moment(start)
            .subtract(1, 'weeks')
            .format('YYYY-MM-DD 00:00'),
          endDate: Moment(end).subtract(1, 'weeks').format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'This Month') {
        this.setState({
          startDate: Moment(start)
            .subtract(1, 'months')
            .startOf('month')
            .format('YYYY-MM-DD 00:00'),
          endDate: Moment(end)
            .subtract(1, 'months')
            .endOf('month')
            .format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'This Month-to-Date') {
        this.setState({
          startDate: Moment(start)
            .subtract(1, 'months')
            .startOf('month')
            .format('YYYY-MM-DD 00:00'),
          endDate: Moment(end).subtract(1, 'months').format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'This Year') {
        this.setState({
          startDate: Moment(start)
            .subtract(1, 'years')
            .startOf('year')
            .format('YYYY-MM-DD 00:00'),
          endDate: Moment(end)
            .subtract(1, 'years')
            .endOf('year')
            .format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'This Year-to-Date') {
        this.setState({
          startDate: Moment(start)
            .subtract(1, 'years')
            .startOf('year')
            .format('YYYY-MM-DD 00:00'),
          endDate: Moment(end).subtract(1, 'years').format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'The Last 365') {
        this.setState({
          startDate: Moment(start)
            .subtract(1, 'years')
            .format('YYYY-MM-DD 00:00'),
          endDate: Moment(end).subtract(1, 'years').format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'The Last 30') {
        this.setState({
          startDate: Moment(start)
            .subtract(30, 'days')
            .format('YYYY-MM-DD 00:00'),
          endDate: Moment(end).subtract(30, 'days').format('YYYY-MM-DD 23:59'),
        });
      } else {
        let days = end.diff(start, 'days');
        let startDate = Moment(start)
          .subtract(days, 'days')
          .format('YYYY-MM-DD 00:00');
        let endDate = Moment(end)
          .subtract(days, 'days')
          .format('YYYY-MM-DD 23:59');
        this.setState({
          startDate: startDate,
          endDate: endDate,
        });
      }
    }

    if (math === 'add') {
      let start = Moment(this.state.startDate);
      let end = Moment(this.state.endDate);

      if (
        this.state.timeFrame === 'Yesterday' ||
        this.state.timeFrame === 'Today'
      ) {
        this.setState({
          startDate: Moment(start).add(1, 'days').format('YYYY-MM-DD 00:00'),
          endDate: Moment(end).add(1, 'days').format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'Last 7 Days') {
        this.setState({
          startDate: Moment(start).add(7, 'days').format('YYYY-MM-DD 00:00'),
          endDate: Moment(end).add(7, 'days').format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'Last Week') {
        this.setState({
          startDate: Moment(start)
            .add(1, 'weeks')
            .startOf('week')
            .format('YYYY-MM-DD 00:00'),
          endDate: Moment(end)
            .add(1, 'weeks')
            .endOf('week')
            .format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'This Week') {
        this.setState({
          startDate: Moment(start).add(1, 'weeks').format('YYYY-MM-DD 00:00'),
          endDate: Moment(end).add(1, 'weeks').format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'This Month') {
        this.setState({
          startDate: Moment(start)
            .add(1, 'months')
            .startOf('month')
            .format('YYYY-MM-DD 00:00'),
          endDate: Moment(end)
            .add(1, 'months')
            .endOf('month')
            .format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'This Month-to-Date') {
        this.setState({
          startDate: Moment(start)
            .add(1, 'months')
            .startOf('month')
            .format('YYYY-MM-DD 00:00'),
          endDate: Moment(end).add(1, 'months').format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'This Year') {
        this.setState({
          startDate: Moment(start)
            .add(1, 'years')
            .startOf('year')
            .format('YYYY-MM-DD 00:00'),
          endDate: Moment(end)
            .add(1, 'years')
            .endOf('year')
            .format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'This Year-to-Date') {
        this.setState({
          startDate: Moment(start)
            .add(1, 'years')
            .startOf('year')
            .format('YYYY-MM-DD 00:00'),
          endDate: Moment(end).add(1, 'years').format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'The Last 365') {
        this.setState({
          startDate: Moment(start).add(1, 'years').format('YYYY-MM-DD 00:00'),
          endDate: Moment(end).add(1, 'years').format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'The Last 30') {
        this.setState({
          startDate: Moment(start).add(30, 'days').format('YYYY-MM-DD 00:00'),
          endDate: Moment(end).add(30, 'days').format('YYYY-MM-DD 23:59'),
        });
      } else {
        let days = end.diff(start, 'days');
        let startDate = Moment(start)
          .add(days, 'days')
          .format('YYYY-MM-DD 00:00');
        let endDate = Moment(end).add(days, 'days').format('YYYY-MM-DD 23:59');
        this.setState({
          startDate: startDate,
          endDate: endDate,
        });
      }
    }
  };

  handleStartDateChange = (event) => {
    this.setState({ customDateStart: event.target.value });
  };

  handleEndDateChange = (event) => {
    this.setState({ customDateEnd: event.target.value });
  };

  render() {
    return (
      <Wrapper ref={this.viewRef}>
        <DashboardControls>
          <div>
            <Title>CAM/CSN Dashboard</Title>
            <Time>
              <i
                onClick={this.handleQuickTimeChange('subtract')}
                class="fas fa-chevron-circle-left"
              ></i>
              {Moment(this.state.startDate).format('dddd, MMMM Do YYYY') +
                ' - ' +
                Moment(this.state.endDate).format('dddd, MMMM Do YYYY')}
              <i
                onClick={this.handleQuickTimeChange('add')}
                class="fas fa-chevron-circle-right"
              ></i>
            </Time>
          </div>
          <div>
            <button
              onClick={() => {
                this.setState({
                  startDate: dayChangeStart(0),
                  endDate: dayChangeEnd(0),
                  timeFrame: 'Today',
                  showManualDate: false,
                });
              }}
            >
              Today
            </button>
            <button
              onClick={() => {
                this.setState({
                  startDate: dayChangeStart(1),
                  endDate: dayChangeEnd(1),
                  timeFrame: 'Yesterday',
                  showManualDate: false,
                });
              }}
            >
              Yesterday
            </button>
            <button
              onClick={() => {
                this.setState({
                  startDate: dayChangeStart(7),
                  endDate: dayChangeEnd(0),
                  timeFrame: 'Last 7 Days',
                  showManualDate: false,
                });
              }}
            >
              Last 7 Days
            </button>
            <select
              id="priority"
              name="priority"
              defaultValue={'Select other'}
              onChange={(e) => {
                this.handleChange(e);
              }}
            >
              <option value="Select other" disabled hidden>
                Select additional time
              </option>
              <option value="Last Week">Last Week</option>
              <option value="This Week">This Week</option>
              <option value="This Month">This Month</option>
              <option value="This Month-to-Date">This Month-to-Date</option>
              <option value="This Year">This Year</option>
              <option value="This Year-to-Date">This Year-to-Date</option>
              <option value="The Last 365">The Last 365</option>
              <option value="The Last 30">The Last 30</option>
              <option value="Custom Date">Custom Date</option>
            </select>
          </div>
          {this.state.showManualDate ? (
            <>
              <CustomDateContainer>
                <div>
                  <label for="startDate">Start Date</label>
                  <input
                    type="date"
                    id="startDate"
                    name="startDate"
                    onChange={this.handleStartDateChange}
                  />
                </div>
                <div>
                  <label for="endDate">End Date</label>
                  <input
                    type="date"
                    id="endDate"
                    name="endDate"
                    onChange={this.handleEndDateChange}
                  />
                </div>
              </CustomDateContainer>
              <div>
                <button
                  onClick={() => {
                    this.fetchData(
                      getFormattedDate(this.state.customDateStart) + ' 00:00',
                      getFormattedDate(this.state.customDateEnd) + ' 23:59'
                    );
                  }}
                >
                  Submit
                </button>
              </div>
            </>
          ) : null}
        </DashboardControls>
        <CamCsnClosedOrders
          startDate={this.state.startDate}
          endDate={this.state.endDate}
        />
        <CamCsnRfq
          startDate={this.state.startDate}
          endDate={this.state.endDate}
        />
        <CamHistoryCharts />
        {/*<CamCsnLineChart />*/}

        <DiscontinuedQuotes sQuoteType="cam-csn" />
        <LostCustomers />
      </Wrapper>
    );
  }
}

export default CamCsnDashboard;
