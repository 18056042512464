import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin-top: 1em;
  width: 100%;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.1);
  text-align: center;
  border-radius: 15px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4aabed;
  color: #fff;
  height: 35px;
  font-weight: bold;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
`;

const Form = styled.form`
  margin: 1em;

  div {
    width: 98%;
    margin: auto;
  }

  textarea {
    height: 250px;
    width: 100%;
    padding: 0.6em 0.8em;
    border: 1px solid #fff;
    border-radius: 5px;
    transition: box-shadow 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    background-color: #f2f5f7;
    resize: none;
  }

  p {
    text-align: left;
    margin: 0.4em 0 0.4em 0;
    font-weight: 500;
  }
`;

export default class QuoteTags extends Component {
  render() {
    return (
      <Wrapper>
        <Title>Quote Tags</Title>
        <Form autoComplete="off">
          <div>
            <textarea
              id="quoteTags"
              name="quoteTags"
              type="text"
              value={this.props.sQuoteTags}
              onChange={(event) => this.props.update("sQuoteTags", event.target.value)}
            ></textarea>
          </div>
        </Form>
      </Wrapper>
    );
  }
}
