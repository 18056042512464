import React, { Component } from 'react';
import styled from 'styled-components';
import Moment from 'moment';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin: 0.5em 0;
  width: 100%;
  height: fit-content;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.1);
  text-align: center;
  border-radius: 15px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4aabed;
  color: #fff;
  height: 35px;
  font-weight: bold;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 1em auto;
  width: 90%;
  align-items: top;

  p {
    text-align: left;
    margin: 0.4em 0;
    text-align: center;
  }

  span {
    font-weight: bold;
    a {
      color: #4aabed;
      cursor: pointer;
    }
  }
`;

export default class Addons extends Component {
  render() {
    let addon;

    if (this.props.nParentQuote != null) {
      if (this.props.nContractType != 2) {
        addon = (
          <div>
            <p>
              This quote is a Rebill of quote:{' '}
              <span>
                <a
                  target="_blank"
                  href={`https://cc.cfrinc.us/quote.asp?id=${this.props.nParentQuote}`}
                >
                  {this.props.nParentQuote}
                </a>
              </span>
            </p>
          </div>
        );
      }
    } else if (this.props.nContractType === 2) {
      addon = (
        <div>
          <p>
            This quote's next rebill date:{' '}
            <span>{Moment(this.props.dtRebillDate).utc().format('LLLL')}</span>
          </p>
        </div>
      );
    } else {
      addon = (
        <div>
          <p>This quote currently has no rebills or add-ons</p>
        </div>
      );
    }

    return (
      <Wrapper>
        <Title>Rebills & Add-ons</Title>
        <BodyWrapper>{addon}</BodyWrapper>
      </Wrapper>
    );
  }
}
