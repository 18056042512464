import React, { Component } from 'react';
import { nQuoteTotalNoDollar } from '../../../assets/functions/calculations';

class LogLookup extends Component {
    state = {
      lookup: 0,
      lookupQuote: {},
    }

    handleChangeQuote(event) {
      if(parseInt(event.target.value)){
        let lookup = parseInt(event.target.value)
        if(lookup > 1500000){
          let logMatch = this.props.commissionLog.find(x => x.nQuote == lookup)
          let systemMatch = this.props.predictLog.find(x => x.id == lookup)
          let lookupQuote = {log: logMatch, quote: systemMatch}

          this.setState({lookupQuote: lookupQuote})
        }

        this.setState({lookup: lookup})

      }
    }


    render() {
        return (
          <div>
            <input onChange={(event)=>this.handleChangeQuote(event)} value={this.state.lookup} />
            {this.state.lookupQuote.log && (
              <table>

              </table>
            )}
            {this.state.lookupQuote.quote && (
              <table>
                
              </table>
            )}
          </div>
        );
    }
}

export default LogLookup;