import React, { Component } from 'react';
import styled from 'styled-components';
import { getSQLData } from '../../../assets/functions/fetch';
import moment from 'moment';
import { Link } from 'react-router-dom';


const Wrapper = styled.div`
  width: 99%;
  max-width: 1300px;
  margin: 4em auto;

  table {
    width: 100%;
    position: relative;
    ${(props) => {
      if (props.amount > 10)
        return `
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;

    thead + tbody tr:last-child td:last-child,
    tfoot tr:last-child td:last-child {
      border-bottom-right-radius: 0px !important;
    }
    thead + tbody tr:last-child td:first-child,
    tfoot tr:last-child td:first-child {
      border-bottom-left-radius: 0px !important;
    }
    margin-bottom: 7em;
        `;
    }}
  }

  button {
    position: absolute;
    width: 100%;
    bottom: -27px;
     padding: 0.35em 1.2em;
     text-decoration: none;
     color: #fafafa;
     text-align: center;
     transition: all 0.2s;
    background-color: #4aabed;
    border: 1px solid #4aabed;
    cursor: pointer;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    &:hover {
      color: #222;
      background-color: #fafafa;
      border: 1px solid #d7d7d7;
    }
  }
`;

const SubTitle = styled.h2`
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1em;
`;

const StyledTable = styled.table`
  width: 100%;
  position: relative;
  border-collapse: collapse; /* Add this to collapse the borders */

  /* Additional styles for table headers */
  thead th {
    background-color: #000397;
    color: white;
  }

  /* Styles for alternating row colors */
  tbody tr:nth-child(odd) {
    background-color: #f2f2f2; /* Grey background for odd rows */
  }

  tbody tr:nth-child(even) {
    background-color: #ffffff; /* Light grey background for even rows */
  }

`;

function bReviewed(bReviewed) {
  if (bReviewed === false) {
    return 'bold';
  } else {
    return 'normal';
  }
}

export default class LateDeliveries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      data: {},
      quotes: [], // Initialize quotes as an empty array
    };
  }



  componentDidMount = async () => {
   
    try {

      const fetch = await getSQLData("logistics/lateDeliveries", {numQuotes: 10})
      
      if(fetch){

        let data = fetch.data.data[0].recordset
        //let query = getQueryInfo("tutorial/tutorial1")
        let query = fetch.query 
        console.log(query)
        
        this.setState({
          data: data,
          //query: query,
        }, () => this.processData())
      }
      
      
    } catch (error) {
      console.error(error);
    }
    
  }

  processData() {
    let quotes = this.state.data;
    let reps = {};
  
    this.setState({
      quotes: quotes,
      reps: reps,
      loaded: true,
    });
  }

  componentWillUnmount() {
    // Stop fetching data when the user leaves the page and component unmounts
    clearInterval(this.interval);
  }

  render() {
   
      return (
      <Wrapper amount={this.state.data.length}>
        <SubTitle>Late Deliveries ({this.state.data.length})</SubTitle>
       <div><p>Note the rep as soon as a late delivery is discovered. For customer-caused delivery exceptions, put "EX" in tracking to remove red status. For non-customer caused delayes, red note the rep, and put "REP" in tracking to remove red status.
        Remove quotes from this list put "REM" in tracking. If blank, there are no late deliveries.</p><br></br></div>

        <StyledTable>
            <thead>
              <tr>
              <th>id</th>
              <th>Company</th>
              <th>Exceptions</th>
              <th>Delivery Date</th>
              <th>Tracking Number</th>
              </tr>
            </thead>
            <tbody>
            {this.state.quotes.map((data, row) => (
              <tr key={row}>
                 <td style={{ textAlign: 'center' }}>
                <Link target="_blank" to={`/quote/${data.id}`}>
                  {data.id}
                </Link>
              </td>
                <td>{data.sCompany}</td>
                <td>{data.nExceptions}</td>
                <td>{moment(data.dtDeliveryDate).format('MM/DD/YYYY')}</td>
                <td>{data.sDeliveryTracking}</td>
              </tr>
            ))}
            </tbody>
          </StyledTable>

      </Wrapper>
    );
  }
}
