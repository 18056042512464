import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { nQuoteTotalNoDollar, whichRep } from '../../../assets/functions/calculations';
import { percentTwoDecimal } from '../../../assets/functions/display';



export default class RepSummaryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    
    return (
      <table className="dashboard">
          <thead>
            <tr>
              <th>Rep</th>
              <th>Closed Quotes</th>
              <th>Sales</th>
              <th>Margin</th>
              <th>Margin %</th>
              <th>New Customers</th>
              <th>New Customer Sales</th>
            </tr>
          </thead>
          <tbody>
            {this.props.reps.map((rep, index)=>(
              <>
              {rep.totalSales > 0 && (
                <tr key={index}>
                  <td>{whichRep(rep.nRep)}</td>
                  <td>{rep.numQuotes}</td>
                  <td>{nQuoteTotalNoDollar(rep.totalSales)}</td>
                  <td>{nQuoteTotalNoDollar(rep.totalMargin)}</td>
                  <td>{percentTwoDecimal(rep.averageMargin)}</td>
                  <td>{rep.newCustomers}</td>
                  <td>{nQuoteTotalNoDollar(rep.newCustomerSales)}</td>
                </tr>
              )}
              
              </>
            ))}
          </tbody>
        </table>
    );
  }
}
