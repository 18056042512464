import React, { Component } from 'react';
import Axios from 'axios';
import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import Moment from 'moment';

const Wrapper = styled.div`
  width: 99%;
  max-width: 700px;
  margin: 4em auto;

  table {
    width: 100%;
  }
`;

const SubTitle = styled.h2`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1em;

  i {
    font-size: 16px;
    margin-left: 7px;
  }
`;

class RepOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brian: [],
      mark: [],
      randy: [],
      tonyC: [],
      jeff: [],
      andy: [],
      reagan: [],
      loaded: false,
    };
  }

  // Async Axios
  fetchData = async (startDate, endDate) => {
    try {
      const brian = await Axios.get('/rep-overview', {
        params: {
          nRep: 54,
          todaysDate: Moment().format('YYYY-MM-DD 23:59'),
          startDate: startDate,
          endDate: endDate,
        },
        headers: { 'Content-Type': 'application/json' },
      });
      const mark = await Axios.get('/rep-overview', {
        params: {
          nRep: 51,
          todaysDate: Moment().format('YYYY-MM-DD 23:59'),
          startDate: startDate,
          endDate: endDate,
        },
        headers: { 'Content-Type': 'application/json' },
      });
      const randy = await Axios.get('/rep-overview', {
        params: {
          nRep: 22,
          todaysDate: Moment().format('YYYY-MM-DD 23:59'),
          startDate: startDate,
          endDate: endDate,
        },
        headers: { 'Content-Type': 'application/json' },
      });
      const tonyC = await Axios.get('/rep-overview', {
        params: {
          nRep: 8,
          todaysDate: Moment().format('YYYY-MM-DD 23:59'),
          startDate: startDate,
          endDate: endDate,
        },
        headers: { 'Content-Type': 'application/json' },
      });
      const jeff = await Axios.get('/rep-overview', {
        params: {
          nRep: 64,
          todaysDate: Moment().format('YYYY-MM-DD 23:59'),
          startDate: startDate,
          endDate: endDate,
        },
        headers: { 'Content-Type': 'application/json' },
      });
      const andy = await Axios.get('/rep-overview', {
        params: {
          nRep: 5,
          todaysDate: Moment().format('YYYY-MM-DD 23:59'),
          startDate: startDate,
          endDate: endDate,
        },
        headers: { 'Content-Type': 'application/json' },
      });
      const reagan = await Axios.get('/rep-overview', {
        params: {
          nRep: 62,
          todaysDate: Moment().format('YYYY-MM-DD 23:59'),
          startDate: startDate,
          endDate: endDate,
        },
        headers: { 'Content-Type': 'application/json' },
      });
      // Sets the state using the response data
      this.setState({
        brian: brian.data.result[0].recordset[0],
        mark: mark.data.result[0].recordset[0],
        randy: randy.data.result[0].recordset[0],
        tonyC: tonyC.data.result[0].recordset[0],
        jeff: jeff.data.result[0].recordset[0],
        andy: andy.data.result[0].recordset[0],
        reagan: reagan.data.result[0].recordset[0],
        loaded: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    this.fetchData(this.props.startDate, this.props.endDate);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.startDate !== prevProps.startDate ||
      this.props.endDate !== prevProps.endDate
    ) {
      this.setState({ loaded: false });
      this.fetchData(this.props.startDate, this.props.endDate);
    }
  }

  render() {
    return (
      <Wrapper>
        <SubTitle>
          Rep Overview <i class="far fa-clock"></i>
        </SubTitle>
        <table className="dashboard">
          <thead>
            <tr>
              <th>Type</th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Andy
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Brian
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Mark
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Randy
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Reagan
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Tony C.
              </th>
              <th
                style={{
                  textAlign: 'center',
                }}
              >
                Jeff
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.loaded || (
              <tr>
                <td colSpan="8">
                  <Loader
                    style={{
                      textAlign: 'center',
                    }}
                    type="ThreeDots"
                    color="#00BFFF"
                    height={80}
                    width={80}
                  />
                </td>
              </tr>
            )}
            {this.state.loaded && (
              <>
                <tr>
                  <td style={{ fontWeight: 'bold' }}>Completed Followups</td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.andy.nCompletedFollowup}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.brian.nCompletedFollowup}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.mark.nCompletedFollowup}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.randy.nCompletedFollowup}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.reagan.nCompletedFollowup}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.tonyC.nCompletedFollowup}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.jeff.nCompletedFollowup}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 'bold' }}>Uncompleted Followups</td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.andy.nUncompletedFollowup}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.brian.nUncompletedFollowup}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.mark.nUncompletedFollowup}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.randy.nUncompletedFollowup}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.reagan.nUncompletedFollowup}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.tonyC.nUncompletedFollowup}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.jeff.nUncompletedFollowup}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 'bold' }}>Late Followups</td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.andy.nLateFollowup}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.brian.nLateFollowup}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.mark.nLateFollowup}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.randy.nLateFollowup}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.reagan.nLateFollowup}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.tonyC.nLateFollowup}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.jeff.nLateFollowup}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 'bold' }}>Open Quotes</td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.andy.nOpenQuote}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.brian.nOpenQuote}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.mark.nOpenQuote}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.randy.nOpenQuote}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.reagan.nOpenQuote}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.tonyC.nOpenQuote}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.jeff.nOpenQuote}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 'bold' }}>Quotes w/o Followups</td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.andy.nNoFollowup}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.brian.nNoFollowup}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.mark.nNoFollowup}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.randy.nNoFollowup}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.reagan.nNoFollowup}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.tonyC.nNoFollowup}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.jeff.nNoFollowup}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 'bold' }}>New Leads</td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.andy.nNewLeads}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.brian.nNewLeads}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.mark.nNewLeads}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.randy.nNewLeads}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.reagan.nNewLeads}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.tonyC.nNewLeads}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.jeff.nNewLeads}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 'bold' }}>Closed Quotes</td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.andy.nClosedQuotes}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.brian.nClosedQuotes}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.mark.nClosedQuotes}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.randy.nClosedQuotes}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.reagan.nClosedQuotes}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.tonyC.nClosedQuotes}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {this.state.jeff.nClosedQuotes}
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </Wrapper>
    );
  }
}

export default RepOverview;
