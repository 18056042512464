import React, { Component } from 'react';
import RepActivityReport from '../tables/admin-dashboardv2/RepActivityReport';

class AdminDashboardv2 extends Component {
    render() {
        return (
            <div>
                <RepActivityReport />
            </div>
        );
    }
}

export default AdminDashboardv2;