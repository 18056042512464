import React, { Component } from 'react';
import Axios from 'axios';

export default class PoLinesPane extends Component {
  state = { data: [], expanded: false, sent: false, confirmed: false };

  // Async Axios
  fetchData = async () => {
    const response = await Axios.get('/quote/po-line', {
      params: {
        id: this.props.id,
      },
      headers: { 'Content-Type': 'application/json' },
    });
    // Sets the state using the response data
    this.setState({
      data: response.data.result[0].recordset,
      loaded: true,
    });
    console.log(this.props);
  };
  catch(error) {
    console.error(error);
  }

  componentDidMount() {
    this.fetchData();
  }

  handleClick = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  render() {
    return (
      <>
        {this.state.data.map((sql) => (
          <tr key={sql.id}>
            <td style={{ textAlign: 'center', fontWeight: 'bold' }}>
              {this.props.poLetter}
            </td>
            <td style={{ textAlign: 'center' }}></td>
            <td style={{ textAlign: 'center' }}>{sql.nQuantity}</td>
            <td style={{ whiteSpace: 'pre-wrap' }}>{sql.sDescription}</td>
            <td style={{ textAlign: 'center' }}>
              $
              {sql.nPrice.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
            <td style={{ textAlign: 'center' }}>
              $
              {(sql.nPrice * sql.nQuantity).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
          </tr>
        ))}
      </>
    );
  }
}
