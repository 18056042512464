import React, { Component } from 'react';
import styled from 'styled-components';
import LogisticOverview from './LogisticsOverview';
import DeliveryTracking from './DeliveryTracking';
import ReturnTracking from './ReturnTracking';
import Addons from './Addons';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-right: 0.5em;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 0.5em;
`;

export default class Logistics extends Component {
  state = {};

  render() {
    return (
      <Wrapper>
        <LogisticOverview
          nContractType={this.props.data.nContractType}
          nDeliveryType={this.props.data.nDeliveryType}
          dtDeliveryDate={this.props.data.dtDeliveryDate}
          dtPickupDate={this.props.data.dtPickupDate}
          sStatus={this.props.data.sStatus}
        />
        <BodyWrapper>
          <LeftColumn>
            <DeliveryTracking deliveryPackages={this.props.deliveryPackages} />
            <Addons
              nParentQuote={this.props.data.nParentQuote}
              nContractType={this.props.data.nContractType}
              dtRebillDate={this.props.data.dtRebillDate}
            />
          </LeftColumn>
          <RightColumn>
            <ReturnTracking returnPackages={this.props.returnPackages} />
          </RightColumn>
        </BodyWrapper>
      </Wrapper>
    );
  }
}
