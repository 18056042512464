import React, { Component } from 'react';
import moment from 'moment';
import CreateNote from '../../popups/CreateNote';
import { getSQLData } from '../../../assets/functions/fetch';
import CreateFollowup from '../../popups/CreateFollowup';

const DiscontinuedQuoteSurvey = {
    test: {
        text: "Was this a test, duplicate, accidental, or similar?",
        value: false,
    },
    unqualified: {
        text: "Is this a valid unqualified lead?",
        value: false,
    },
    repeat: {
        text: "Is this a repeat customer?",
        value: false,
    },
    openQuotes: {
        text: "Does this customer have open quotes?",
        value: false,
    },
    customerNo: {
        text: "Did the customer tell the rep 'no'?",
        value: false,
    },
    unableToFill: {
        text: "Was the rep unable to fill the request?",
        value: false,
    },
    stopResponding: {
        text: "Did the rep discontinue because the customer stopped responding?",
        value: false,
    },
    outOfTime: {
        text: "Did the rep discontinue because there is not enough time to fill the request?",
        value: false,
    },
    lowValue: {
        text: "Is this a paper shredder or other low value request?",
        value: false,
    },
    
}

class DiscontinuedQuoteQuestions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            processed: false,
            createNote: false,
            createFollowup: false,
        };

        this.handleCreateNote = this.handleCreateNote.bind(this)
        this.handleCreateFollowup = this.handleCreateFollowup.bind(this)
      }

    componentDidMount() {
        let survey = {...DiscontinuedQuoteSurvey}

        this.setState({
            survey: survey,
        }, () => this.predictQuestions())
      }
    
    predictQuestions() {
        let survey = this.state.survey
        let quote = this.props.quote
        let lines = this.props.lines
        let notes = this.props.notes
        let emails = this.props.emails

        if(quote.Closed_Quotes > 0){
            survey.repeat.value = true
        }
        if(quote.Open_Quotes > 0){
            survey.openQuotes.value = true
        }
        if(quote.sWhyCancel === "Customer is not qualified" || 
            quote.sWhyCancel === "SPAM request"
        ){
            survey.unqualified.value = true
        }
        if(quote.sWhyCancel === "Customer is non-responsive"){
            survey.stopResponding.value = true
        }
        if(quote.sWhyCancel === "Time prohibits order"){
            survey.outOfTime.value = true
        }
        if(quote.sWhyCancel === "Customer closed a different quote" ||
            quote.sWhyCancel === "Equipment has been returned" ||
            quote.sWhyCancel === "Duplicate quote"
        ){
            survey.test.value = true
        }
        if(quote.sWhyCancel === "Cannot render service requested" ||
            quote.sWhyCancel === "We do not provide this service" ||
            quote.sWhyCancel === "Sent Lead" ||
            quote.sWhyCancel === "Location Not Supported"
        ){
            survey.unableToFill.value = true
        }
        if(quote.sWhyCancel === "Customer said Project Canceled or No Longer Needed" ||
            quote.sWhyCancel === "Customer went with another vendor" ||
            quote.sWhyCancel === "Customer purchased equipment instead" ||
            quote.sWhyCancel === "Customer said Quote Was Too Expensive"
        ){
            survey.customerNo.value = true
        }

        survey.order = [
            survey.test, 
            survey.unqualified, 
            survey.repeat, 
            survey.openQuotes,
            survey.customerNo,
            survey.unableToFill,
            survey.stopResponding,
            survey.outOfTime,
            survey.lowValue,
        ]

        console.log(survey)

        this.setState({
            survey: survey,
        }, () => this.processSurvey())
    }

    processSurvey(){
        let survey = this.state.survey
        let quote = this.props.quote

        const pickupDate = quote.dtPickupDate ? moment(quote.dtPickupDate) : null;
        const lastUpdatedDate = moment(quote.dtLastUpdated);
    
        const referenceDate = pickupDate || lastUpdatedDate;
        referenceDate.add(6, 'weeks').format('YYYY-MM-DD');

        if(survey.unqualified.value || survey.test.value || survey.repeat.value || survey.openQuotes.value){
            survey.result = {
                text: "Mark as valid discontinued and no action.",
            }
        } else if(survey.unableToFill.value){
            survey.result = {
                text: "Create a note directed at the current supplier management rep telling them we were unable to fill this request.",
                noteText: "We were unable to fill this request.",
                noteTo: 5,
            }
        } else if(survey.customerNo.value) {
            const pickupDate = quote.dtPickupDate ? moment(quote.dtPickupDate) : null;
            const lastUpdatedDate = moment(quote.dtLastUpdated);
        
            const followupDate = pickupDate || lastUpdatedDate;
            followupDate.add(6, 'weeks').format('YYYY-MM-DD');

            survey.result = {
                text: "If the contact is angry, hostile, unreasonable, or meets other criteria not to pursue, mark as valid discontinued and no action. "
                    + "Otherwise, schedule a followup for 6 weeks after their event ended, or quote discontinued if that value is not available. ",
                followupText: "Review Customer 'no' Script.",
                followupDate: followupDate,
            }
        } else if(survey.stopResponding.value) {
            survey.result = {
                text: "Search RingCentral call log, find the last time the rep tried to call them.  "
                    + "Schedule a follow up to call them 1 week after the last time a sales rep tried to contact them. ",
                followupText: "Review Non-responsive Contact Script.",
                followupDate: moment().add(1,'weeks').format('YYYY-MM-DD'),
            }
        } else if(survey.outOfTime.value) {
            const pickupDate = quote.dtPickupDate ? moment(quote.dtPickupDate) : null;
            const lastUpdatedDate = moment(quote.dtLastUpdated);
        
            const followupDate = pickupDate || lastUpdatedDate;
            followupDate.add(6, 'weeks').format('YYYY-MM-DD');

            survey.result = {
                text: "Schedule a follow up to call them 6 weeks after their event ended. ",
                followupText: "Review Out of Time Script.",
                followupDate: moment().add(6,'weeks').format('YYYY-MM-DD'),
            }
        } else if (survey.lowValue.value) {
            const pickupDate = quote.dtPickupDate ? moment(quote.dtPickupDate) : null;
            const lastUpdatedDate = moment(quote.dtLastUpdated);
        
            const followupDate = pickupDate || lastUpdatedDate;
            followupDate.add(6, 'weeks').format('YYYY-MM-DD');

            survey.result = {
                text: "Schedule a follow up to call them 6 weeks after their event ended. ",
                followupText: "Review Low Value Request Script.",
                followupDate: moment().add(6,'weeks').format('YYYY-MM-DD'),
            }
        } else {
            survey.result = {
                text: "Create a note directed at the sales manager.  This may be an invalid discontinue. ",
                noteText: "Review possible invalid discontinue.",
                noteTo: 5,
            }
        }

        this.setState({
            survey: survey,
            processed: true,
        })
    }
    updateSurvey(index){
        let survey = this.state.survey

        survey.order[index].value = !survey.order[index].value

        this.setState({
            survey: survey
        }, () => this.processSurvey())
    }

    handleCreateNote(){
        let createNote = !this.state.createNote
        this.setState({
            createNote: createNote,
        })
    }

    handleCreateFollowup(){
        let createFollowup = !this.state.createFollowup
        this.setState({
            createFollowup: createFollowup,
        })
    }
     

    render() {
        let survey = this.state.survey
        let processed = this.state.processed
        let createNote = this.state.createNote
        let createFollowup = this.state.createFollowup
        let quote = this.props.quote
        let reps = this.props.reps
        let user = this.props.user

        return (
            <>
            {processed && (
                <>
            <div>
                <table>
                {survey.order.map((question,index)=>(
                    <tr>
                        <td>{question.text}</td>
                        <td><input 
                            type={'checkbox'} 
                            checked={question.value} 
                            onChange={()=>this.updateSurvey(index)} />
                        </td>
                    </tr>
                ))}
                </table>
            </div>
            
                <div>
                    <div style={{margin: '10px', fontWeight: 'bold'}}>{survey.result.text}</div>
                    <div>
                        <div><button 
                        style={{
                            fontWeight: survey.result.followupText ? "bold" : "",
                            margin: '10px',
                        }}
                        onClick={()=>this.handleCreateFollowup()}>
                            Create Follow Up
                        </button><button 
                        style={{
                            fontWeight: survey.result.noteText ? "bold" : "",
                            margin: '10px',
                        }} 
                        onClick={()=>this.handleCreateNote()}>
                            Create Note
                        </button></div>
                    </div>
                    <div><button
                    style={{
                        margin: '10px',
                    }}>
                        Mark Valid Discontinue
                    </button></div>
                </div>
                </>
            )}

            {createNote && (
                <CreateNote 
                    nQuote = {quote.id}
                    nRep = {user}
                    attention = {survey.result.noteTo ? survey.result.noteTo : 0}
                    text = {survey.result.noteText ? survey.result.noteText : ""}
                    reps = {reps}
                    handleCreateNote = {this.handleCreateNote}
                />

            )}
            {createFollowup && (
                <CreateFollowup 
                    nQuote = {quote.id}
                    nRep = {user}
                    followupFor = {user}
                    text = {survey.result.followupText ? survey.result.followupText : ""}
                    reps = {reps}
                    date = {survey.result.followupDate ? survey.result.followupDate : moment().format("YYYY-MM-DD hh:mm A")}
                    handleCreateFollowup = {this.handleCreateFollowup}
                />
            )}
            
            </>
        );
    }
}

export default DiscontinuedQuoteQuestions;