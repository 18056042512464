module.exports = {
    admin_queries: {
        template: {
            query: "",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        activeReps: {
            query: "SELECT * FROM RAC_REPS WHERE bActive=1",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        discontinuedQuoteReviewEmails: {
            query: "SELECT * "
                + "FROM RAC_QUOTE_EMAILS "
                + "WHERE nQuote = (SELECT TOP 1 id FROM RAC_CUSTOMER_QUOTES WHERE sStatus='Discontinued' AND NOT bDiscontinuedValid=1 ORDER BY dtLastUpdated desc) "
                + "ORDER BY dtDate desc",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        discontinuedQuoteReviewNotes: {
            query: "SELECT * "
                + "FROM RAC_QUOTE_NOTES "
                + "WHERE nQuote = (SELECT TOP 1 id FROM RAC_CUSTOMER_QUOTES WHERE sStatus='Discontinued' AND NOT bDiscontinuedValid=1 ORDER BY dtLastUpdated desc) "
                + "ORDER BY dtTimeStamp desc",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        discontinuedQuoteReviewLines: {
            query: "SELECT * "
                + "FROM RAC_QUOTE_LINES "
                + "WHERE nQuote = (SELECT TOP 1 id FROM RAC_CUSTOMER_QUOTES WHERE sStatus='Discontinued' AND NOT bDiscontinuedValid=1 ORDER BY dtLastUpdated desc) "
                + "ORDER BY nSort asc",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        discontinuedQuoteReview: {
            query: "SELECT TOP 1 rcq.*, "
                + "(SELECT sName FROM COMPANIES c WHERE c.id=rcq.nCompany) AS Company_Name, "
                + "(SELECT COUNT(rp.id) FROM RAC_POS rp WHERE rp.nAttachedQuote=rcq.id) AS Number_POs, "
                + "(SELECT COUNT(rr.id) FROM RAC_RFQ rr WHERE rr.nQuote=rcq.id) AS Number_RFQs, "
                + "(SELECT COUNT(rs.id) FROM RAC_SCHEDULE rs WHERE rs.nQuote=rcq.id) AS Number_Followups, "
                + "(SELECT COUNT(rs.id) FROM RAC_SCHEDULE rs WHERE rs.nQuote=rcq.id AND rs.dtCompleted IS NULL) AS Number_Open_Followups, "
                + "("
                    + "SELECT COUNT(rcq2.id) "
                    + "FROM RAC_CUSTOMER_QUOTES rcq2 "
                    + "WHERE rcq2.nCompany=rcq.nCompany "
                    + "AND rcq.sStatus='Closed' "
                    + "AND rcq.dtFirstClosed >= DATEADD(year,-3,GETDATE())"
                +") AS Closed_Quotes, "
                + "("
                    + "SELECT COUNT(rcq2.id) "
                    + "FROM RAC_CUSTOMER_QUOTES rcq2 "
                    + "WHERE rcq2.nCompany=rcq.nCompany "
                    + "AND rcq.sStatus='Open' "
                    + "AND rcq.dtFirstClosed >= DATEADD(year,-3,GETDATE()) "
                    + "AND NOT bDeleted=1 "
                +") AS Open_Quotes "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE sStatus='Discontinued' AND NOT bDiscontinuedValid=1 "
                + "ORDER BY dtLastUpdated desc",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        setPotential: {
            query: "UPDATE RAC_CUSTOMER_QUOTES "
                + "SET nPotential=[nPotential] "
                + "WHERE id=[id]",
            req: ["id","nPotential"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Discontinues a quote as spam",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "UPDATE",
            whereUsed: [],
        },
        discontinueAsSpam: {
            query: "UPDATE RAC_CUSTOMER_QUOTES "
                + "SET sStatus='Discontinued', "
                + "sWhyCancel='SPAM request', "
                + "bDeleted=1 "
                + "WHERE id=[id]",
            req: ["id"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Discontinues a quote as spam",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "UPDATE",
            whereUsed: [],
        },
        newLeads: {
            query: "SELECT id, sName, sCompany, sAddress, sCity, sState, sZip, sPhone, sEmail, "
                + "sQuote, sType, dtDateStamp, sQuoteType, dtDeliveryDate, dtPickupDate, "
                + "sReferringSite, sTimeZone, sLeadSource, nPotential "
                + "FROM RAC_CUSTOMER_QUOTES "
                + "WHERE nRep IS NULL AND sStatus='Open' AND NOT bDeleted = 1 ORDER BY id asc",
            req: [],
            fields: [
                {key: "id", label: "Quote"},
                {key: "sName", label: "Name"},
                {key: "sCompany", label: "Company"},
                {key: "sAddress", label: "Address"},
                {key: "sCity", label: "City"},
                {key: "sState", label: "State"},
                {key: "sZip", label: "Zip"},
                {key: "sPhone", label: "Phone"},
                {key: "sEmail", label: "Email"},
                {key: "sQuote", label: "Quote Request"},
                {key: "sType", label: "Type"},
                {key: "dtDateStamp", label: "Date/Time"},
                {key: "sQuoteType", label: "Brand"},
                {key: "dtDeliveryDate", label: "Delivery"},
                {key: "dtPickupDate", label: "Pickup"},
                {key: "sReferringSite", label: "Referring Site"},
                {key: "sTimeZone", label: "Time Zone"},
                {key: "sLeadSource", label: "Lead Source"},
            ],
            desc: "Gets data about new leads that haven't been assigned to a rep",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: [],
        },
        openQuotesByRep: {
            query: "SELECT rcq.* "
            + "FROM RAC_CUSTOMER_QUOTES rcq WHERE rcq.sStatus='Open' AND rcq.bDeleted != 1 AND rcq.nRep=[nRep]",
            req: ["nRep"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        notesByRep: {
            query: "SELECT rqn.* "
                + "FROM RAC_QUOTE_NOTES rqn "
                + "WHERE rqn.nRep=[nRep] AND "
                + "rqn.nQuote IN (SELECT rcq.id FROM RAC_CUSTOMER_QUOTES rcq WHERE rcq.sStatus='Open' AND rcq.bDeleted != 1 AND rcq.nRep=[nRep]) "
                + "ORDER BY rqn.id desc",
            req: ["nRep"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        followUpsByRep: {
            query: "SELECT rs.nQuote AS Quote, rs.sNote, rcq.sCompany, rcq.sQuoteTitle, "
                + "(SELECT rr.sName FROM RAC_REPS rr WHERE rr.id=rs.nRep) AS Rep, "
                + "CONVERT(varchar, dtDate, 101) as FollowUp, "
                + "CONVERT(varchar, dtCompleted, 101) as Completed "
                + "FROM RAC_SCHEDULE rs "
                + "LEFT JOIN RAC_CUSTOMER_QUOTES rcq ON rcq.id=rs.nQuote "
                + "WHERE rs.nRep = [nRep] "
                + "ORDER BY CASE WHEN rs.dtCompleted IS NULL THEN 1 ELSE 0 END, dtCompleted desc, FollowUp asc",
            req: ["nRep"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        repActivityReport: {
            query: "SELECT rr.sDisplay, rr.id, "
                + "(SELECT COUNT(rs.id) "
                    + "FROM RAC_SCHEDULE rs "
                    + "WHERE rs.nRep=rr.id AND (CONVERT(date, rs.dtCompleted) = CONVERT(date, GETDATE()))"
                + ") AS FollowupsCompleted, "
                + "(SELECT COUNT(rcq.id) "
                    + "FROM RAC_CUSTOMER_QUOTES rcq "
                    + "WHERE (rcq.nRep=rr.id OR rcq.nRep2=rr.id OR rcq.nRep3=rr.id) AND rcq.bDeleted != 1 AND rcq.sStatus='Open'"
                + ") AS OpenQuotes, "
                + "(SELECT COUNT(rqn.id) "
                    + "FROM RAC_QUOTE_NOTES rqn "
                    + "WHERE rqn.nRep=rr.id AND (CONVERT(date, rqn.dtTimeStamp) = CONVERT(date, GETDATE()))"
                + ") AS Notes, "
                + "(SELECT COUNT(rs.id) "
                    + "FROM RAC_SCHEDULE rs "
                    + "WHERE rs.nRep=rr.id AND rs.dtCompleted IS NULL AND (CONVERT(date, rs.dtDate) = CONVERT(date, GETDATE()))"
                + ") AS FollowupsDue, "
                + "(SELECT COUNT(rs.id) "
                    + "FROM RAC_SCHEDULE rs "
                    + "WHERE rs.nRep=rr.id AND rs.dtCompleted IS NULL AND (CONVERT(date, rs.dtDate) < CONVERT(date, GETDATE()))"
                + ") AS FollowupsPastDue "
                + "FROM RAC_REPS rr "
                + "WHERE rr.bActive=1 AND NOT (rr.id IN (1,14,27,45))",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
    }
} 