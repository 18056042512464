import React, { Component } from 'react';
import styled from 'styled-components';
import Moment from 'moment';
import RacClosedOrders from '../tables/rac-dashboard/RacClosedOrders';
import RacRFQ from '../tables/rac-dashboard/RacRFQ';
import RacLineChart from '../charts/line/rac-dashboard/RacLineChart';
import OpenContracts from '../tables/rac-dashboard/OpenContracts';
import DiscontinuedQuotes from '../tables/misc/DiscontinuedQuotes';
import SalesRepContest from '../tables/rac-dashboard/SalesRepContest';
import RacHistoryCharts from '../charts/line/rac-dashboard/RacHistoryCharts';
import { displayDateRange, lastBusinessDayStartEnd, quickTimeChange } from '../../assets/functions/time';
import { DashboardWrapper } from '../styled/ui';
import DashboardControls from '../tables/rac-dashboard/DashboardControls';


class RacDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: lastBusinessDayStartEnd().startDate,
      endDate: lastBusinessDayStartEnd().endDate,
      loaded: false,
      showManualDate: false,
      customDateStart: '',
      customDateEnd: '',
      timeFrame: '',
    };
    this.handleChange = this.handleChange.bind(this)
    this.handleStartDateChange = this.handleStartDateChange.bind(this)
    this.handleEndDateChange = this.handleEndDateChange.bind(this)
    this.handleQuickTimeChange = this.handleQuickTimeChange.bind(this)
    this.handleSetState = this.handleSetState.bind(this)
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    console.log(this.state)
  }

  handleChange(e) {
    let dates = displayDateRange(e)
    if(dates.showManualDate){
      this.setState({
        showManualDate: true
      })
    } else {
      this.setState({
        startDate: dates.startDate,
        endDate: dates.endDate,
      })
    }
  }

  // Calculates time frame for quick change time buttons
  handleQuickTimeChange = (math) => (e) => {
    let dates = quickTimeChange(math, e, this.state.startDate, this.state.endDate, this.state.timeFrame)
    this.setState({
      startDate: dates.startDate,
      endDate: dates.endDate,
    })
  };

  handleStartDateChange = (event) => {
    this.setState({ customDateStart: event.target.value });
  };

  handleEndDateChange = (event) => {
    this.setState({ customDateEnd: event.target.value });
  };

  handleSetState(state){
    this.setState(state)
  }

  render() {
    let date = Moment();

    return (
      <DashboardWrapper ref={this.viewRef}>
        <DashboardControls
          handleChange={this.handleChange}
          handleStartDateChange={this.handleStartDateChange}
          handleEndDateChange={this.handleEndDateChange}
          handleQuickTimeChange={this.handleQuickTimeChange}
          handleSetState={this.handleSetState}
          customDateStart={this.state.customDateStart}
          customDateEnd={this.state.customDateEnd}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          showManualDate={this.state.showManualDate}
        />
          
          
        

        <RacClosedOrders
          startDate={this.state.startDate}
          endDate={this.state.endDate}
        />
        <RacRFQ startDate={this.state.startDate} endDate={this.state.endDate} />
        {/*<RacLineChart />*/}
        <RacHistoryCharts />
        <OpenContracts />
        <DiscontinuedQuotes sQuoteType="Rentacomputer.com" />
      </DashboardWrapper>
    );
  }
}

export default RacDashboard;
