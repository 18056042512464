import React, { Component } from 'react'

export default class UnderwritingLinks extends Component {
  render() {
    return (
      <div>
          {this.props.links[this.props.viewing].length > 0 ? "Links:" : ""}
      {this.props.links[this.props.viewing].map((link,index)=>(
          <>
          
          {!link.clicked && (
              <div>
                  <a href={link.link} target="_blank" onClick={()=>this.props.clickLink(index)}>{link.text}</a>
              </div>
          )}
          
          </>
      ))}
      
  </div>
    )
  }
}
