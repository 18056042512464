import React, { Component } from 'react';
import styled from 'styled-components';
import RfqControl from './RfqControl';
import Axios from 'axios';
import { nQuoteTotalNoDollar } from '../../../assets/functions/calculations';
import Moment from 'moment';
import { Link } from 'react-router-dom';

const Wrapper = styled.section``;

export default class Rfqs extends Component {
  state = {
    RFQlines: [],
    quoteLines: this.props.quoteLines,
    ready: false,
    RFQs: [],
    quote: 0,
    mostApples: 0,
  };

  fetchData = async () => {
    try {
      const response = await Axios.get('/quote/rfq', {
        params: {
          quote: this.state.quote,
          
        },
        headers: { 'Content-Type': 'application/json' },
      });
      // Sets the state using the response data
      if(response){
        this.setState({
          RFQlines: response.data.result[0].recordset,
          
        }, () => this.prepareData());
      }
      
    } catch (error) {
      console.error(error);
    }
  };

  prepareData() {
    let RFQs = []
    let RFQlines = this.state.RFQlines
    let quoteLines = this.state.quoteLines
    let ids = []
    let minPrice = 0   
    let bestPrice = 0 

    for(let i=0; i<RFQlines.length; i++){
      RFQlines[i].quoteLine = -1
      let match = false
      for(let j=0; j<quoteLines.length && !match; j++){
        if(quoteLines[j].sDescription === RFQlines[i].sDescription){
          RFQlines[i].quoteLine = j
          console.log(j)
          if(((!quoteLines[j].nQuantity || quoteLines[j].nQuantity === 0) && RFQlines[i].nPrice > 0) || RFQlines[i].nQuantity === quoteLines[j].nQuantity){
            RFQlines[i].match = true
            match = true
          } else {
            RFQlines[i].match = false
          }
        }
      }

      let newLine = {
        sDescription: RFQlines[i].sDescription,
        nPrice: RFQlines[i].nPrice,
        nQuantity: RFQlines[i].nQuantity,
        quoteLine: RFQlines[i].quoteLine,
        match: RFQlines[i].match,
      }

      if(!ids.includes(RFQlines[i].id)){
        ids.push(RFQlines[i].id)
        let RFQ = {}
        RFQ.id = RFQlines[i].id
        RFQ.name = RFQlines[i].name
        RFQ.bTaxDel = RFQlines[i].bTaxDel
        RFQ.nDelivery = RFQlines[i].nDelivery
        RFQ.nTaxRate = RFQlines[i].nTaxRate
        RFQ.dtDateSubmitted = RFQlines[i].dtDateSubmitted
        RFQ.dtSent = RFQlines[i].dtSent
        RFQ.lastOrder = RFQlines[i].lastOrder
        RFQ.nMember = RFQlines[i].nMember
        RFQ.sApples = RFQlines[i].sApples
        RFQ.showLines = false
        RFQ.lines = []
        RFQ.lines.push(newLine)
        RFQs.push(RFQ)
        console.log(RFQs)
      } else {
        RFQs[RFQs.length-1].lines.push(newLine)
      }     

    }

    let requiredLines = 0
    for(let i=0; i<quoteLines.length; i++){
      quoteLines[i].quoted = false
      for(let j=0; j<RFQlines.length && !quoteLines[i].quoted; j++){
        if(RFQlines[j].quoteLine === i){
          quoteLines[i].quoted = true
          requiredLines++
        }
      }

      if(!quoteLines[i].quoted && quoteLines[i].nQuantity > 0){
        requiredLines++
      }
    }

    for(let i=0; i<RFQs.length; i++){
      RFQs[i].totalPrice = 0
      RFQs[i].preTaxPrice = 0
      for(let j=0; j<RFQs[i].lines.length; j++){
        RFQs[i].totalPrice += RFQs[i].lines[j].nPrice * RFQs[i].lines[j].nQuantity
        RFQs[i].preTaxPrice += RFQs[i].lines[j].nPrice * RFQs[i].lines[j].nQuantity
      }
      if(RFQs[i].bTaxDel){
        RFQs[i].totalPrice += RFQs[i].nDelivery
        RFQs[i].preTaxPrice += RFQs[i].nDelivery
        RFQs[i].totalPrice *= (1 + RFQs[i].nTaxRate/100)
      } else {
        RFQs[i].totalPrice *= (1 + RFQs[i].nTaxRate/100)
        RFQs[i].totalPrice += RFQs[i].nDelivery
        RFQs[i].preTaxPrice += RFQs[i].nDelivery
      }

      for(let j=0; j<RFQs[i].lines.length; j++){
        if(RFQs[i].dtDateSubmitted && RFQs[i].totalPrice > 0 && RFQs[i].lines[j].match){
        }
      }

      switch(RFQs[i].sApples){
        case "Full Match":
          RFQs[i].nApples = 1
          break;
        case "Probably":
          RFQs[i].nApples = 2
          break;
        case "Probably Not":
          RFQs[i].nApples = 3
          break;
        case "No":
          RFQs[i].nApples = 4
          break;
        default:
          RFQs[i].nApples = 5
      }

      if(RFQs[i].sApples === "Full Match" && (bestPrice === 0 || RFQs[i].totalPrice < bestPrice)){
        bestPrice = RFQs[i].totalPrice
      }
    }

    if(bestPrice > 0){
      for(let i=0; i<RFQs.length; i++){
        RFQs[i].priceComp = RFQs[i].totalPrice / bestPrice - 1
      }
    }
    
    

    RFQs.sort((a,b) => (
        a.nApples > b.nApples ? 1 : a.nApples !== b.nApples ? -1 :
        (a.totalPrice === 0 && b.totalPrice === 0) ? 0 :
        a.totalPrice > b.totalPrice && b.totalPrice > 0 ? 1 :
        a.totalPrice === 0 ? 1 :
        (a.totalPrice > 0 && a.totalPrice < b.totalPrice) ? -1 : 
        (a.totalPrice > 0 && b.totalPrice === 0) ? -1 : 0
      ))

      console.log(RFQs)

    this.setState({
      ready: true,
      RFQs: RFQs,
      RFQlines: RFQlines,
      minPrice: minPrice,
    })
  }

  componentDidMount() {
    console.log(this.props.quote)

    this.setState({quote: this.props.quote}, () => this.fetchData())
  }

  showLines(index) {
    let RFQs = this.state.RFQs
    RFQs[index].showLines = !RFQs[index].showLines
    this.setState({RFQs: RFQs})
  }

  render() {
    return (
      <Wrapper>
        {this.state.ready && (
          <div>
            <table class="dashboard" style={{width: '1200px'}}>
              <thead>
                <th>Vendor</th>
                <th>Response Time</th>
                <th>Total Price</th>
                <th>Last Order</th>
              </thead>
              {this.state.RFQs.map((RFQ, index) => (
                <>
                <tr onClick={()=>this.showLines(index)}>
                  <td><Link target="_blank" to={`/srm/master-viewer/${RFQ.nMember}`}>{RFQ.name}</Link></td>
                  <td>{Math.round(Moment(RFQ.dtDateSubmitted).diff(Moment(RFQ.dtSent))/1000/6)/10} minutes</td>
                  <td>
                    {nQuoteTotalNoDollar(RFQ.totalPrice)}&nbsp;
                    ({RFQ.sApples ? RFQ.sApples : "NR"}
                    {RFQ.sApples === "Full Match" ? (RFQ.priceComp === 0 ? " Best Price" : " +" + Math.round(RFQ.priceComp * 1000)/10 + "%") : ""})</td>
                  <td>{Moment(RFQ.lastOrder).utc().format('MM/DD/YYYY')}</td>
                </tr>
                {RFQ.showLines && RFQ.lines.length > 0 && (
                  <tr><td colSpan={4}><table style={{width: '1100px'}}>
                    <thead>
                      <th>Quantity</th>
                      <th>Description</th>
                      <th>Price</th>
                      <th>Total Price</th>
                    </thead>
                    {RFQ.lines.map((line,index2) => (
                      <tr style={{fontWeight: line.match ? "bold" : ""}}>
                        <td>{line.nQuantity}</td>
                        <td>{line.sDescription}</td>
                        <td>{nQuoteTotalNoDollar(line.nPrice)}</td>
                        <td>{nQuoteTotalNoDollar(line.nQuantity * line.nPrice)}</td>
                      </tr>
                    ))}
                      <tr>
                        <td></td>
                        <td>Delivery</td>
                        <td></td>
                        <td>{nQuoteTotalNoDollar(RFQ.nDelivery)}</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>Tax</td>
                        <td></td>
                        <td>{nQuoteTotalNoDollar(RFQ.totalPrice - RFQ.preTaxPrice)}</td>
                      </tr>
                  </table></td></tr>
                )}
                </>
              ))}
            </table>
          </div>
        )}
      </Wrapper>
    );
  }
}
