import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import styled from 'styled-components';
import Moment from 'moment';
import Axios from "axios";
import { activeReps, whichRep, getPaymentTerms, userCanChangeTerms } from '../../../assets/functions/calculations';
import SalesChart from './SalesChart';

const SuggestButton = styled.button`
   text-decoration: none;
   text-align: center;
   transition: all 0.2s;
  cursor: pointer;
  padding: 0.5em 1em;
  border: 3px #e8edf1 solid;
  border-radius: 50px;
  background-color: #fff;
  font-weight: 500;
  margin: 0;
  color: #9ca7ae;

  &:hover {
    background-color: #4aabed;
    color: #fff;
    border: 3px #4aabed solid;
  }
`;

const Select = styled.select`
  width: 100%;
  height: 60px;
  white-space:pre-wrap;
  margin: 0.5em auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.4em;
`
const Option = styled.option`
max-width: 100%;
overflow: hidden;
word-wrap: normal !important;
white-space: normal;
`


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #fff;
  width: 100%;
  height: 1100px;
  padding: 2em;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.1);
  border-radius: 15px;

  i {
    font-size: 8rem;
    color: #adb5bd;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1em 0;

  div {
    max-width: 250px;
  }

  input {
    width: 100%;
    margin: 0.5em auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.4em;
  }

  p {
    text-align: left;
    margin-left: 0.2em;
    font-weight: bold;
    margin-top: 1em;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1em 0;
`;

export default class Summary extends Component {
  state = {
    primaryAddress: this.props.primaryAddress,
    billingAddress: this.props.billingAddress,
    guessRep: null,
    user: null,
    rep: this.props.rep,
    companyName: this.props.companyName,
    primaryContact: this.props.primaryContact,
    sales: this.props.sales,
    years: this.props.years,
    upperBound: this.props.upperBound,
  }

  // Async Axios
  fetchData = async () => {
    try {
      const response = await Axios.get('/userSession', {
        credentials: 'include',
      });
      if (response.data.passport) {
        this.setState({
          user: response.data.passport.user,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    this.fetchData();
    console.log(this.props)
  }

  updatePrimaryAddress = (event) => {
    Axios({
      method: "post",
      url: "/customers/update-primary-address",
      data: {
        primaryID: event.target.value,
        customerID: this.props.id
      },
    });

    this.setState({primaryAddress: event.target.value})
  }

  updateBillingAddress = (event) => {
    Axios({
      method: "post",
      url: "/customers/update-billing-address",
      data: {
        billingID: event.target.value,
        customerID: this.props.id
      },
    });

    this.setState({billingAddress: event.target.value})
  }

  updatePrimaryContact = (event) => {
    Axios({
      method: "post",
      url: "/customers/update-primary-contact",
      data: {
        contactID: event.target.value,
        customerID: this.props.id
      },
    });

    this.setState({primaryContact: event.target.value})
  }

      updateDefaultRep = (event) => {
        Axios({
          method: "post",
          url: "/customers/update-rep",
          data: {
            repID: event.target.value,
            customerID: this.props.id
          },
        });

        this.setState({rep: event.target.value})
      }

      updateGuessRep = (guessID) => {
        Axios({
          method: "post",
          url: "/customers/update-rep",
          data: {
            repID: guessID,
            customerID: this.props.id
          },
        });
        this.setState({rep: guessID})
      }

      updatePaymentTerms = (event) => {
        Axios({
          method: "post",
          url: "/customers/update-terms",
          data: {
            sTerms: event.target.value,
            customerID: this.props.id
          },
        });
        this.setState({terms: {sPaymentTerms: event.target.value}})
      }

      updateTempCredit = (event) => {
        this.setState({creditLimit: event.target.value})
      }

      updateTempEquipment = (event) => {
        this.setState({equipmentLimit: event.target.value})
      }

      updateTempCompany = (event) => {
        this.setState({companyName: event.target.value})
      }

      updateCompanyName = () => {
        Axios({
          method: "post",
          url: "/customers/update-company-name",
          data: {
            companyName: this.state.companyName,
            customerID: this.props.id
          },
        });
      }

      updateCreditLimit = () => {

        let getUpdate = 0

        if(isNaN(this.state.creditLimit)){
          console.log("not a number")
        } else {
          if(this.state.creditLimit > 0){
            getUpdate = this.state.creditLimit
          }

          Axios({
            method: "post",
            url: "/customers/update-credit",
            data: {
              creditLimit: getUpdate,
              customerID: this.props.id
            },
          });
          this.setState({terms: {nCreditLimit: getUpdate}})
        }  
      }

      updateEquipmentLimit = () => {

        let getUpdate = 0

        if(isNaN(this.state.equipmentLimit)){
          console.log("not a number")
        } else {
          if(this.state.equipmentLimit > 0){
            getUpdate = this.state.equipmentLimit
          }

          Axios({
            method: "post",
            url: "/customers/update-equipment",
            data: {
              equipmentLimit: getUpdate,
              customerID: this.props.id
            },
          });
          this.setState({terms: {nEquipmentLimit: getUpdate}})
        }  
      }
  

  render() {
    let dateCreated;

    // Checks to see if the date is from 1995 and up
    if (Moment(this.props.dateCreated).utc().format('YYYY') > 1995) {
      dateCreated = Moment(this.props.dateCreated).utc().format('YYYY-MM-DD');
    } else {
      dateCreated = null;
    }

    let billingDropdown
    let repDropdown
    let paymentDropDown
    let primaryDropdown
    let contactDropdown

    billingDropdown = (
      <>
        { (!this.state.billingAddress && (<Option value={null}>No Address Selected</Option>))}
        { this.props.addresses.map((sql) => (
          <Option value={sql.id}>
            {sql.sAddress}, {sql.sCity}, {sql.sState}
          </Option>
        ))}
      </>
    )

    primaryDropdown = (
      <>
        { (!this.state.primaryAddress && (<Option value={null}>No Address Selected</Option>))}
        { this.props.addresses.map((sql) => (
          <Option value={sql.id}>
            {sql.sAddress}, {sql.sCity}, {sql.sState}
          </Option>
        ))}
      </>
    )

    contactDropdown = (
      <>
        { (!this.state.primaryContact && (<Option value={null}>No Contact Selected</Option>))}
        { this.props.contacts.map((sql) => (
          <Option value={sql.id}>
            {sql.sFirstName} {sql.sLastName}, {sql.sPhone}, {sql.sEmail}
          </Option>
        ))}
      </>
    )

    repDropdown = (
      <>
        { activeReps().map((rep) => (
          <Option value={rep.id} >
            {rep.name}
          </Option>
        ))}
      </>
    )

    /*paymentDropDown = (
      <>
        { getPaymentTerms().map((term) => (
          <Option value={term}>
            {term}
          </Option>
        ))}
      </>
    )*/

    return (
      <Wrapper>
        <div>
          <SalesChart 
            sales={this.state.sales}
            years={this.state.years}
            upperBound={this.state.upperBound}
          />
        </div>
        <Container>
          <div>
            <p>Company Name</p>
            <input type="text" defaultValue={this.props.companyName} onChange={this.updateTempCompany} />
            {this.props.companyName !== this.state.companyName && (
              <SuggestButton onClick={this.updateCompanyName}>Update Company Name</SuggestButton>
            )}
          </div>
          <div>
            <p>Company ID</p>
            <input type="text" defaultValue={this.props.id} />
          </div>
          <div>
            <p>Primary Contact</p>
            <Select onChange={this.updatePrimaryContact} value={this.state.primaryContact}>
              {contactDropdown}

            </Select>
          </div>
          {console.log(this.state.primaryAddress)}
          <div>
            <p>Primary Address</p>
            <Select onChange={this.updatePrimaryAddress} value={this.state.primaryAddress}>
              {primaryDropdown}
            </Select>
          </div>
          <div>
            <p>Billing Address</p>
            <Select onChange={this.updateBillingAddress} value={this.state.billingAddress}>
              {billingDropdown}
            </Select>
          </div>
          <div>
            <p>Default Rep</p>
            <Select onChange={this.updateDefaultRep} value={this.state.rep}>
              {repDropdown}
            </Select>
            { (!this.state.rep || this.state.rep == 0) && (
              <SuggestButton onClick={() => this.updateGuessRep(this.props.guessRep)}>Set rep to {whichRep(this.props.guessRep)}</SuggestButton>
            )}
            { ((!this.state.rep || this.state.rep == 0) && this.props.guessRep !== this.state.user && false) && (
               <SuggestButton onClick={() => this.updateGuessRep(this.state.user)}>Set rep to {whichRep(this.state.user)}</SuggestButton>
            ) }
          </div>
          <div>
            <p>Date Created</p>
            <input type="date" value={dateCreated} />
          </div>
          {/*<div>
            <p>Payment Terms</p>
            <Select onChange={this.updatePaymentTerms} value={((!this.state.terms && getPaymentTerms()[0]) || this.state.terms.sPaymentTerms)} disabled={!userCanChangeTerms(this.state.user)}>
              {paymentDropDown}
            </Select>
            </div>
          
          <div>
            <p>Credit Limit</p>
            <input type="text" value={this.state.creditLimit} onChange={this.updateTempCredit} disabled={!userCanChangeTerms(this.state.user)}></input>
            {(this.state.creditLimit !== this.props.terms.nCreditLimit && (
            <SuggestButton onClick={this.updateCreditLimit}>Update Credit Limit</SuggestButton>
            ))}
          </div>
          
          <div>
            <p>Equipment Limit</p>
            <input type="text" value={this.state.equipmentLimit} onChange={this.updateTempEquipment} disabled={!userCanChangeTerms(this.state.user)}></input>
            {(this.state.equipmentLimit !== this.props.terms.nEquipmentLimit && (
            <SuggestButton onClick={this.updateEquipmentLimit}>Update Equipment Limit</SuggestButton>
            ))}
          </div>*/}
        </Container>
      </Wrapper>
    );
  }
}
