import React, { Component } from 'react';

class LogMismatch extends Component {
    render() {
        return (
            <table>
                <thead>
                    <tr>
                        <th>Quote</th>
                        <th>Log Price</th>
                        <th>Log Cost</th>
                        <th>Log Commission</th>
                        <th>System Price</th>
                        <th>System Cost</th>
                    </tr>
                </thead>
                {this.props.logSystemMismatch.map((quote,index) => (
                    <tr>
                        <td>{quote.quote.id}</td>
                        <td 
                            style={{
                                fontWeight: (quote.log.nInvoiceTotal !== quote.quote.QuoteLineTotal+quote.quote.nDelivery ? 'bold' : ''),
                        }}>
                                {quote.log.nInvoiceTotal}
                        </td>
                        <td 
                            style={{
                                fontWeight: (quote.log.nPOTotal !== quote.quote.QuotePOTotal ? 'bold' : ''),
                        }}>
                                {quote.log.nPOTotal}
                        </td>
                        <td 
                            style={{
                                fontWeight: (Math.abs(quote.log.nCommission - (quote.log.nInvoiceTotal-quote.log.nPOTotal)*.1) > 0.01 ? 'bold' : ''),
                        }}>
                                {quote.log.nCommission}
                        </td>
                        <td 
                            style={{
                                fontWeight: (quote.log.nInvoiceTotal !== quote.quote.QuoteLineTotal+quote.quote.nDelivery ? 'bold' : ''),
                        }}>
                                {quote.quote.QuoteLineTotal+quote.quote.nDelivery}
                        </td>
                        <td 
                            style={{
                                fontWeight: (quote.log.nPOTotal !== quote.quote.QuotePOTotal ? 'bold' : ''),
                        }}>
                                {quote.quote.QuotePOTotal}
                        </td>
                    </tr>
                ))}
            </table>
        );
    }
}

export default LogMismatch;