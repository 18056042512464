import React, { Component } from 'react';
import styled from 'styled-components';
import Moment from 'moment';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 4%;

  input {
    height: 30px;
    width: 35%;
    max-width: 400px;
    margin: 0 0.5em 0.2em 0em;
    padding: 0 0.7em;
    border: 1px solid #fff;
    border-radius: 5px;
    background-color: #f2f5f7;
  }

  i {
    margin-right: 0.5em;
    padding: 0;
    color: #c3d1da;
    transition: 0.2s;
    font-size: 1rem;
  }
`;

const CheckBox = styled.div`
  height: 20px;
  width: 20px;
  margin-right: 0.5em;

  input {
    height: 100%;
    width: 100%;
  }
`;

const DeleteIcon = styled.span`
  i {
    &:hover {
      cursor: pointer;
      color: red;
    }
  }
`;

const Carrier = styled.span`
  i {
    font-size: 3rem;
    color: #444;
    margin-right: 0.2em;
  }
`;

export default class TrackingItem extends Component {
  handleClick = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  render() {
    let carrier;
    let status;

    // Returns icon based on sCarrierCode carrier
    if (this.props.sCarrierCode === 'fedex') {
      carrier = <i class="fab fa-fedex"></i>;
    } else if (this.props.sCarrierCode === 'ups') {
      carrier = <i class="fab fa-ups"></i>;
    } else if (this.props.sCarrierCode === 'dhl') {
      carrier = <i class="fab fa-dhl"></i>;
    }

    // Returns icon based on tracking status
    if (this.props.sStatusCode != 'DE') {
      status = (
        <>
          <p>
            {this.props.sStatusCode}{' '}
            {this.props.dtRealDelivery ? (
              <p>{Moment(this.props.dtRealDelivery).utc().format('llll')}</p>
            ) : null}
          </p>
        </>
      );
    } else if (this.props.sStatusCode === 'DE') {
      status = (
        <>
          <p>
            {this.props.sStatusCode}:{' '}
            {Moment(this.props.dtEstimatedDelivery).utc().format('llll')}
          </p>
        </>
      );
    }

    return (
      <Wrapper
        sStatusCode={this.props.sStatusCode}
        sTrackingNumber={this.props.sTrackingNumber}
      >
        <CheckBox>
          <input type="checkbox" id="" name="" value="" />
        </CheckBox>
        <DeleteIcon>
          <i class="fas fa-minus-circle"></i>
        </DeleteIcon>
        <a
          target="_blank"
          href={`http://www.google.com/search?q=${this.props.sTrackingNumber}`}
        >
          <i class="fas fa-external-link-alt"></i>
        </a>
        <input type="text" value={`${this.props.sTrackingNumber}`}></input>
        <Carrier>{carrier}</Carrier>
        {status}
      </Wrapper>
    );
  }
}
