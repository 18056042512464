export const CAMTargets = {
    sales: {
        2021: [
            83333.33,    
            83333.33,    
            83333.33,    
            83333.33,    
            83333.33,    
            83333.33,    
            83333.33,    
            83333.33,    
            83333.33,    
            83333.33,    
            83333.33,    
            83333.33,    
        ],
        2022: [
            83333.33,    
            83333.33,    
            83333.33,    
            83333.33,    
            83333.33,    
            83333.33,    
            83333.33,    
            100000,    
            100000,    
            100000,    
            100000,    
            100000,    
        ],
        2023: [
            110000,      
            110000,      
            110000,      
            110000,      
            110000,      
            110000,      
            110000,      
            120000,      
            120000,      
            120000,      
            120000,      
            120000,      
        ],
        2024: [
            125000,
            125000,
            125000,
            125000,
            125000,
            125000,
            125000,
            125000,
            125000,
            125000,
            125000,
            125000,
        ]
    },
    margin: {
        2021: [
            40000,
            40000,
            40000,
            40000,
            40000,
            40000,
            40000,
            40000,
            40000,
            40000,
            40000,
            40000,
        ],
        2022: [
            40000,
            40000,
            40000,
            40000,
            40000,
            40000,
            40000,
            50000,
            50000,
            50000,
            50000,
            50000,
        ],
        2023: [
            55000,
            55000,
            55000,
            55000,
            55000,
            55000,
            55000,
            60000,
            60000,
            60000,
            60000,
            60000,
        ],
        2024: [
            62500,
            62500,
            62500,
            62500,
            62500,
            62500,
            62500,
            62500,
            62500,
            62500,
            62500,
            62500,
        ],
    }
}