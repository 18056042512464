import moment from "moment";
import { queries } from "../data/queries";
import { setSQLData } from "./fetch";
import { Allegheny } from "../data/geography";
import { getStateFullName } from "./geography";

export function getQueryInfo(query){return queries[query.split('/')[0]][query.split('/')[1]]}

export function nullDateCheck(date){return date ? "'" + date + "'" : null}

export function stringToSQL(inputString){return inputString.replace(/'/g, "''")}

export function camStatsTemplate(){return {
    closed: {
    count: {
      all: 0,
      csn: 0,
      contract: 0,
    },
    sales: {
      all: 0,
      csn: 0,
      contract: 0,
    },
    margin: {
      all: 0,
      csn: 0,
      contract: 0,
    },
    labor: 0,
    },
    completed: {
      all: [],
      csn: [],
      contract: [],
      count: {
        all: 0,
        csn: 0,
        contract: 0,
      },
      sales: {
        all: 0,
        csn: 0,
        contract: 0,
      },
      margin: {
        all: 0,
        csn: 0,
        contract: 0,
      },
      labor: 0,
    },
    target: {
      sales: 0,
      margin: 0,
    }
  }}

  export function processQuoteChanges(changes){
    let updateText = ""
    for(const field in changes){
      if(updateText.length > 0){
        updateText += ", "
      }
      updateText += field + " = " + changes[field]
    }
    
    return updateText
  }

  export function prepareStringForSQL(text){
    text = "'" + text + "'"
    return text
  }

  export function formatQuoteChangeLog(text, id){
    text = "'Quote " + id + " updated in cfrinc.app: " + text.replaceAll("'", "") + "'"

    return text
  }

  export function buildAddress(name, address, address2, city, state, zip){
    return name + "\n" + address + "\n" + (address2 ? (address2 + "\n") : "") + city + ", " + state + " " + zip
  }

  export function splitPackages(packages){
    let deliveryPackages = []
    let returnPackages = []

    for(let i=0; i<packages.length; i++){
      if(packages[i].nType==1){deliveryPackages.push(packages[i])}
      if(packages[i].nType==2){returnPackages.push(packages[i])}
    }

    return {
      deliveryPackages: deliveryPackages,
      returnPackages: returnPackages
    }
  }

  export function generateInvoiceCSV(data, quoteLines){
    let CSVData = []
    let billingAddress = buildAddress(data.sName, data.sAddress, data.sAddress2, data.sCity, data.sState, data.sZip)
    let shippingAddress = buildAddress(data.sDName, data.sDAddress, data.sDAddress2, data.sDCity, data.sDState, data.sDZip)

    for(let i=0; i<quoteLines.length; i++){
      if(i==0){
        CSVData[i] = {   
          Customer: data.sCompany,
          Email: data.sEmail.split(";")[0].split(",")[0],
          billingAddress: billingAddress,
          shippingAddress: shippingAddress,
          invoiceDate: moment(data.DeliveryDate).format("MM/DD/YYYY"),
          dueDate: moment(data.DeliveryDate).format("MM/DD/YYYY"),
          shippingDate: moment(data.DeliveryDate).format("MM/DD/YYYY"),
          PONumber: data.sPONumber,
          SalesRep: data.RepInitials,
          TaxRate: (data.sDState == "FL" 
            ? data.sDCity + ", " + data.sDState 
            : (data.sDState == "PA" 
              ? (data.sDState == "Philadelphia" 
                ? "Philadelphia, PA" 
                : (Allegheny.includes(data.sDCity) ? "Allegheny County, PA" : "Pennsylvania")) 
              : getStateFullName(data.sDState))),
          Shipping: data.bTaxDel > 0 ? 0 : data.nDelivery,
          Terms: data.bCC > 0 ? "Credit Card" : data.bNet ? "Net 30" : "In Advance",
          Item: data.sQBItem,
        }
        } else {
          CSVData[i] = {}
        }

        let arrayDescription = quoteLines[i].sDescription.split("\n").join("`").split("\r").join("`").split("`")
        let newDescription = ""
        for(let j=0; j<arrayDescription.length; j++){
          if(j>0){
            newDescription = newDescription + "\u2028"
          }
          newDescription += arrayDescription[j]
        }
        newDescription = newDescription.replaceAll(",",";")

        CSVData[i].Invoice = data.id
        CSVData[i].Item = quoteLines[i].Item
        CSVData[i].Description = newDescription
        CSVData[i].Quantity = quoteLines[i].nQuantity>=1 ? quoteLines[i].nQuantity : 1
        CSVData[i].Rate = quoteLines[i].nPrice ? quoteLines[i].nPrice : 0
        CSVData[i].Amount = quoteLines[i].nQuantity * quoteLines[i].nPrice ? quoteLines[i].nQuantity * quoteLines[i].nPrice : 0
        CSVData[i].Taxable = "Y"
        CSVData[i].Class = data.sQuoteType
    }
    if(data.bTaxDel > 0 && data.nDelivery > 0){
      CSVData[CSVData.length] = {
        Invoice: CSVData[0].Invoice,
        Description: "Taxable Shipping",
        Quantity: 1,
        Rate: data.bTaxDel > 0 ? data.nDelivery : 0,
        Amount: data.bTaxDel > 0 ? data.nDelivery : 0,
        Taxable: "Y",
        Class: CSVData[0].Class,
      }
    }

    console.log(CSVData)


    let CSVHeaders = [
      {key: "Invoice", label: "*InvoiceNo"},
      {key: "Customer", label: "*Customer"},
      {key: "Email", label: "Email"},
      {key: "billingAddress", label: "billingAddress"},
      {key: "shippingAddress", label: "shippingAddress"},
      {key: "invoiceDate", label: "*InvoiceDate"},
      {key: "dueDate", label: "*DueDate"},
      {key: "PONumber", label: "PONumber"},
      {key: "SalesRep", label: "SalesRep"},
      {key: "TaxRate", label: "TaxRate"},
      {key: "Shipping", label: "Shipping"},
      {key: "Item", label: "Item(Product/Service)"},
      {key: "Description", label: "ItemDescription"},
      {key: "Quantity", label: "ItemQuantity"},
      {key: "Rate", label: "ItemRate"},
      {key: "Amount", label: "ItemAmount"},
      {key: "Taxable", label: "Taxable"},
      {key: "Class", label: "Class"},
      {key: "shippingDate", label: "ShippingDate"},
      {key: "Terms", label: "Terms"},
    ]

    return {
      Headers: CSVHeaders,
      Data: CSVData,
    }
  }

  export function generateCustomerCSV(data){
    let Customer = [{
      Company: data.sCompany,
      Name: data.sName,
      Email: data.sEmail,
      Phone: data.sPhone,
      Street: data.sAddress,
      Street2: data.sAddress2,
      City: data.sCity,
      State: data.sState,
      Zip: data.sZip,
      Terms: "Credit Card",
      Country: "United States",
    }]

    let Headers = [
      {key: "Name", label: "Name"},
      {key: "Company", label: "Company"},
      {key: "Email", label: "Email"},
      {key: "Phone", label: "Phone"},
      {key: "Street", label: "Street"},
      {key: "Street2", label: "Street2"},
      {key: "City", label: "City"},
      {key: "State", label: "State"},
      {key: "Zip", label: "Zip"},
      {key: "Terms", label: "Terms"},
      {key: "Country", label: "Country"},
    ]

    return {
      Headers: Headers,
      Data: Customer,
    }
  }
  export function copyToClipboard(text){
    navigator.clipboard.writeText(text)
  }