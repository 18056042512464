import { Component } from "react";
import { displayFollowupCount } from "../../assets/functions/followups";
import FollowupItem from "./FollowupItem";

class FollowupsTile extends Component {
    state = {
      showFollowups: false,
    }

    render() {


        return(
          <>
          {this.props.followups && this.props.followups.length > 0 && (
              <>
                <h2
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.setState({showFollowups: !this.state.showFollowups})}
                >
                  Followups ({displayFollowupCount(this.props.followups)})
                  <i
                    style={{ float: 'right' }}
                    className="fas fa-chevron-down"
                  ></i>
                </h2>
                {this.state.showFollowups && (
                  <>
                    {this.props.followups.map((sql) => (
                      <FollowupItem
                        sFollowupId={sql.sFollowupId}
                        nQuote={sql.nQuote}
                        dtDate={sql.dtDate}
                        sCompany={sql.sCompany}
                        sQuoteTitle={sql.sQuoteTitle}
                        sName={sql.sName}
                        sPhone={sql.sPhone}
                        sNote={sql.sNote}
                      />
                    ))}
                  </>
                )}
              </>
            )}
           </>
        )
    }
}

export default FollowupsTile;