import Axios from 'axios';
import Cookies from 'js-cookie';
import { currentHourStart } from "./time";

export async function fetchUser () {
    let data = {}
    data.permissions = {}

    try {
      const passport = await Axios.get('/userSession', {credentials: 'include',});
      if (passport.data.passport) {
          data.user = passport.data.passport.user
      }

      const response = await Axios.get('/user', {credentials: 'include',});
      if(response){
          data.TAUser = response.data.result[0].recordset[0].nTechArmyId
          data.loaded = true
        }
      if (
        response.data.result[0].recordset[0].sTTATitle === 'Developer' ||
        response.data.result[0].recordset[0].sTTATitle === 'CEO' || 
        data.user === 45
      ) {
        Cookies.set('sTTATitle', 'admin');
        data.permissions.admin = true
      }

      if (response.data.result[0].recordset[0].bAccounting === true) {
        data.permissions.accounting=true
      }
      if (response.data.result[0].recordset[0].bRAC === true) {
        data.permissions.RAC = true
      }
      if (response.data.result[0].recordset[0].bCSN === true) {
        data.permissions.CSN = true
      }
      if (
        response.data.result[0].recordset[0].bCSN === true ||
        response.data.result[0].recordset[0].bRAC === true ||
        response.data.result[0].recordset[0].bAdmin === true
      ) {
        data.permissions.marketing = true
      }
      if(data.user === 45  || data.user === 54 || data.user === 63 || data.user === 66){
        data.permissions.Dev = true
      }
      data.permissions.Internal = (response.data.result[0].recordset[0].sTTATitle !== "External")


    } catch (error) {
      console.error(error);
    }

    return data
  };

  export function checkPermissions(permissions, required){
    let pass = false;

    if(required.length > 0){
        for(let i=0; i<required.length; i++){
            if(permissions[required[i]]) pass = true
        }
    }

    return pass
  }

  export async function fetchLeadsAndNotes(user) {
    let data = {}

    try {
        const leads = await Axios.get('/unassigned-leads');
        const notes = await Axios.get('/follow-notes', {
        params: {
            nRep: user,
            date: currentHourStart(),
        },
        });

        data.racLeads = leads.data.result[0].recordset
        data.camLeads = leads.data.result[1].recordset
        data.followUps = notes.data.result[0].recordset
        data.notes = notes.data.result[1].recordset
    }
    catch(error) {
        console.error(error);
    }

    return data
}