import React, { Component } from 'react';
import InvoiceCSVButton from '../../misc/InvoiceCSVButton';
import { setSQLData } from '../../../assets/functions/fetch';

class NeedsInvoiced extends Component {
    constructor(props) {
        super(props);
        this.state = {
            invoiced: [],
        }
    }
    markAsInvoiced(index){
        let invoiced = this.state.invoiced
        invoiced[index] = true

        setSQLData("accounting/mark_as_invoiced", {quoteNum: this.props.needsInvoiced[index].id})

        this.setState({
            invoiced: invoiced,
        })
    }
    render() {
        return (
            <table>
                <thead>
                    <tr>
                        <th>Quote</th>
                        <th>Name</th>
                        <th>Export</th>
                    </tr>
                </thead>
                {this.props.needsInvoiced.map((quote,index) => (
                    <tr>
                        <td>{quote.id}</td>
                        <td>{quote.sCompany}</td>
                        <td>
                            <InvoiceCSVButton id={quote.id} />
                        </td>
                        {/*<td>
                            {this.state.invoiced[index] && (
                                <>Invoiced</>
                            ) || (
                                <button onClick={()=>this.markAsInvoiced(index)}>Mark As Invoiced</button>
                            )}
                        </td>*/}
                    </tr>
                ))}
            </table>
        );
    }
}

export default NeedsInvoiced;