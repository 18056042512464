import React, { Component } from 'react';
import Axios from 'axios';
import styled from 'styled-components';
import { Pie } from 'react-chartjs-2';
import Loader from 'react-loader-spinner';

const Wrapper = styled.section`
  padding: 5em 0em;
  text-align: center;
  width: 70%;
  margin: auto;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const SubTitle = styled.h2`
  padding: 1em 0;
  text-align: center;
`;

export default class UserSalesPieChart extends Component {
  state = {
    loaded: false,
    brianSales: 0,
    brianMargin: 0,
    markSales: 0,
    markMargin: 0,
    randySales: 0,
    randyMargin: 0,
    tonyCSales: 0,
    tonyCMargin: 0,
    jeffSales: 0,
    jeffMargin: 0,
    reaganSales: 0,
    reaganMargin: 0,
    showSalesChart: true,
  };

  fetchData = async (startDate, endDate) => {
    try {
      const response = await Axios.get('/user-sales-charts', {
        params: {
          startDate: startDate,
          endDate: endDate,
        },
        headers: { 'Content-Type': 'application/json' },
      });
      // Sets the state using the response data
      this.setState({
        brianSales: response.data.result[0].recordset[0].brianSales,
        markSales: response.data.result[0].recordset[0].markSales,
        randySales: response.data.result[0].recordset[0].randySales,
        tonyCSales: response.data.result[0].recordset[0].tonyCSales,
        jeffSales: response.data.result[0].recordset[0].jeffSales,
        reaganSales: response.data.result[0].recordset[0].reaganSales,
        brianMargin:
          response.data.result[0].recordset[0].brianBeforeTaxSales -
          response.data.result[0].recordset[0].brianTotalCost,
        markMargin:
          response.data.result[0].recordset[0].markBeforeTaxSales -
          response.data.result[0].recordset[0].markTotalCost,
        randyMargin:
          response.data.result[0].recordset[0].randyBeforeTaxSales -
          response.data.result[0].recordset[0].randyTotalCost,
        tonyCMargin:
          response.data.result[0].recordset[0].tonyCBeforeTaxSales -
          response.data.result[0].recordset[0].tonyCTotalCost,
        jeffMargin:
          response.data.result[0].recordset[0].jeffBeforeTaxSales -
          response.data.result[0].recordset[0].jeffTotalCost,
        reaganMargin:
          response.data.result[0].recordset[0].reaganBeforeTaxSales -
          response.data.result[0].recordset[0].reaganTotalCost,
        loaded: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    this.fetchData(this.props.startDate, this.props.endDate);
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState({
        loaded: false,
      });
      this.fetchData(this.props.startDate, this.props.endDate);
    }
  }

  handleClick() {
    this.setState({ showSalesChart: !this.state.showSalesChart });
  }

  render() {
    const salesData = {
      labels: ['Brian', 'Mark', 'Randy', 'Tony C', 'Jeff', 'Reagan'],
      datasets: [
        {
          label: 'Sales $',
          data: [
            this.state.brianSales,
            this.state.markSales,
            this.state.randySales,
            this.state.tonyCSales,
            this.state.jeffSales,
            this.state.reaganSales,
          ],
          backgroundColor: [
            'rgba(54, 162, 235, 0.8)',
            'rgba(251,98,98, 0.8)',
            'rgba(255, 206, 86, 0.8)',
            'rgba(116, 211, 174, 0.8)',
            'rgba(136, 90, 137, 0.8)',
            'rgba(255, 128, 0, 0.8)',
          ],
        },
      ],
    };

    const marginData = {
      labels: ['Brian', 'Mark', 'Randy', 'Tony C', 'Jeff', 'Reagan'],
      datasets: [
        {
          label: 'Margin $',
          data: [
            this.state.brianMargin,
            this.state.markMargin,
            this.state.randyMargin,
            this.state.tonyCMargin,
            this.state.jeffMargin,
            this.state.reaganMargin,
          ],
          backgroundColor: [
            'rgba(54, 162, 235, 0.8)',
            'rgba(251,98,98, 0.8)',
            'rgba(255, 206, 86, 0.8)',
            'rgba(116, 211, 174, 0.8)',
            'rgba(136, 90, 137, 0.8)',
            'rgba(255, 128, 0, 0.8)',
          ],
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        mode: 'label',
        label: 'mylabel',
        callbacks: {
          label: function (tooltipItem, data) {
            var value = Number(
              data.datasets[0].data[tooltipItem.index]
            ).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            return ' $' + value;
          },
        },
      },
    };

    return (
      <Wrapper>
        {this.state.loaded || (
          <Loader
            style={{
              textAlign: 'center',
            }}
            type="ThreeDots"
            color="#00BFFF"
            height={80}
            width={80}
          />
        )}
        {this.state.loaded && (
          <>
            {this.state.showSalesChart ? (
              <>
                <SubTitle>
                  User Sales
                  <span
                    onClick={() => this.handleClick()}
                    style={{ color: 'lightgray', cursor: 'pointer' }}
                  >
                    {' '}
                    - User Margins
                  </span>
                </SubTitle>
                <Pie
                  data={salesData}
                  options={options}
                  width={100}
                  height={50}
                />
              </>
            ) : (
              <>
                <SubTitle>
                  <span
                    onClick={() => this.handleClick()}
                    style={{ color: 'lightgray', cursor: 'pointer' }}
                  >
                    User Sales -
                  </span>{' '}
                  User Margins
                </SubTitle>
                <Pie
                  data={marginData}
                  options={options}
                  width={100}
                  height={50}
                />
              </>
            )}
          </>
        )}
      </Wrapper>
    );
  }
}
