import React, { Component } from 'react'
import { copyToClipboard } from '../../../../assets/functions/system'
import { nQuoteTotalNoDollar } from '../../../../assets/functions/calculations'
import moment from 'moment'

export default class QBInvoice extends Component {
  render() {
    let invoice = this.props.invoice
    return (
        <>
            <div style={{backgroundColor: "#6380a0", padding: "5px"}}>
                <table><tr>
                        <td style={{color: "#fff", width: '100px'}}>CUSTOMER:JOB</td>
                        <td style={{backgroundColor: "#eee", width: '500px', border: '1px double #ccc', borderRadius: '4px',}} onClick={()=>copyToClipboard(invoice.sQBCustomer + ": " + invoice.id)}>{invoice.sQBCustomer}: {invoice.id}</td>
                        <td style={{width: '100px'}}></td>
                        <td style={{color: "#fff", width: '100px'}}>CLASS: </td>
                        <td style={{backgroundColor: "#eee", width: '200px', border: '1px solid #ccc', borderRadius: '4px'}} onClick={()=>copyToClipboard(invoice.sQuoteType)}>{invoice.sQuoteType}</td>
                        <td style={{color: "#fff", width: '100px'}}>TEMPLATE:</td>
                        <td style={{backgroundColor: "#eee", width: '200px', border: '1px solid #ccc', borderRadius: '4px'}} onClick={()=>copyToClipboard(invoice.sQuoteType === "Rentacomputer.com" ? "RAC Invoice" : "CSN & CAM")}>{invoice.sQuoteType === "Rentacomputer.com" ? "RAC Invoice" : "CSN & CAM"}</td>
                        <td style={{width: '300px'}}></td>
                    </tr>
                </table>
            </div>
        <div>
            <table>
                <tr>
                    <td width={800}>
                        <div style={{fontSize: '32px'}}>Invoice</div>
                        {invoice.dtPaid && (
                            <div style={{color: "#80be49"}}><i class="fa fa-check-circle" aria-hidden="true"></i>PAID {moment(invoice.dtPaid).format("MM/DD/YYYY")}</div>
                        )}
                    </td>
                    <td>
                        <table style={{borderSpacing: '4px',}}>
                            <tr>
                                <td width={100}></td>
                                <td width={150}>DATE</td>
                                <td width={200}>BILLING ADDRESS</td>
                                <td width={200}>DELIVERY ADDRESS</td>
                            </tr>
                            <tbody>
                                <tr height={100}>
                                    <td></td>
                                    <td style={{verticalAlign: 'top'}}>
                                        <div style={{backgroundColor: "#eee", border: '1px solid #ccc', borderRadius: '4px', padding: '4px', margin: '4px',}} onClick={()=>copyToClipboard(moment(invoice.date).add(5, "hours").format("MM/DD/YYYY"))}>
                                            {moment(invoice.date).add(5, "hours").format("MM/DD/YYYY")}
                                        </div>
                                        <div>
                                            INVOICE #
                                        </div>
                                        <div style={{backgroundColor: "#eee", border: '1px solid #ccc', borderRadius: '4px', padding: '4px', margin: '4px',}} onClick={()=>copyToClipboard(invoice.id)}>
                                            {invoice.id}
                                        </div>
                                    </td>
                                    <td 
                                        style={{whiteSpace: 'pre-line', backgroundColor: "#eee", border: '1px solid #ccc', borderRadius: '4px', verticalAlign: 'top',}} 
                                        onClick={()=>copyToClipboard(invoice.billingAddress)}
                                    >
                                        {invoice.billingAddress}
                                    </td>
                                    <td 
                                        style={{whiteSpace: 'pre-line', backgroundColor: "#eee", border: '1px solid #ccc', borderRadius: '4px', verticalAlign: 'top', spacing: '4px',}} 
                                        onClick={()=>copyToClipboard(invoice.deliveryAddress)}
                                    >
                                        {invoice.deliveryAddress}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                <td></td> 
                <td>
                        <table>
                            <thead>
                                <tr>
                                    <td>P.O. NUMBER</td>
                                    <td>TERMS</td>
                                    <td>REP</td>
                                    <td>START</td>
                                    <td>DELIVERY METHOD</td>
                                    <td>PICKUP DATE</td>
                                </tr>
                            </thead>
                            <tr>
                                <td style={{backgroundColor: "#eee", border: '1px solid #ccc', borderRadius: '4px', padding: '4px', margin: '4px',}} onClick={()=>copyToClipboard(invoice.PONumber)}>{invoice.PONumber}</td>
                                <td style={{backgroundColor: "#eee", border: '1px solid #ccc', borderRadius: '4px', padding: '4px', margin: '4px',}} onClick={()=>copyToClipboard(invoice.sPaymentTerms)}>{invoice.sPaymentTerms}</td>
                                <td style={{backgroundColor: "#eee", border: '1px solid #ccc', borderRadius: '4px', padding: '4px', margin: '4px',}} onClick={()=>copyToClipboard(invoice.rep)}>{invoice.rep}</td>
                                <td style={{backgroundColor: "#eee", border: '1px solid #ccc', borderRadius: '4px', padding: '4px', margin: '4px',}} onClick={()=>copyToClipboard(moment(invoice.startDate).add(5, "hours").format("MM/DD/YYYY"))}>{moment(invoice.startDate).add(5, "hours").format("MM/DD/YYYY")}</td>
                                <td style={{backgroundColor: "#eee", border: '1px solid #ccc', borderRadius: '4px', padding: '4px', margin: '4px',}} onClick={()=>copyToClipboard(invoice.deliveryMethod)}>{invoice.deliveryMethod}</td>
                                <td style={{backgroundColor: "#eee", border: '1px solid #ccc', borderRadius: '4px', padding: '4px', margin: '4px',}} onClick={()=>copyToClipboard(moment(invoice.pickupDate).add(5, "hours").format("MM/DD/YYYY"))}>{invoice.pickupDate ? moment(invoice.pickupDate).add(5, "hours").format("MM/DD/YYYY") : ""}</td>
                            </tr>
                        </table>
                    </td> 
                </tr>
            </table>
        </div>
        <div>
            <table>
                <tr>
                    <td style={{borderWidth: '0px 1px 0px 0px', borderStyle: 'dotted', borderColor:'#ccc'}}>QUANTITY</td>
                    <td style={{borderWidth: '0px 1px 0px 0px', borderStyle: 'dotted', borderColor:'#ccc'}}>CODE</td>
                    <td style={{borderWidth: '0px 1px 0px 0px', borderStyle: 'dotted', borderColor:'#ccc'}}>DESCRIPTION</td>
                    <td style={{borderWidth: '0px 1px 0px 0px', borderStyle: 'dotted', borderColor:'#ccc'}}>PRICE EACH</td>
                    <td style={{borderWidth: '0px 1px 0px 0px', borderStyle: 'dotted', borderColor:'#ccc'}}>EXTENDED</td>
                    <td>TAX</td>
                </tr>
                {invoice.lines.map((line, index)=>(
                    <tr style={{backgroundColor: index%2===0 ? "#e3effe" : "#fff"}}>
                        <td 
                            onClick={()=>copyToClipboard(line.quantity ? line.quantity : "")} 
                            style={{borderWidth: '0px 1px 0px 1px', borderStyle: 'solid', borderColor:'#ccc'}}
                        >
                            {line.quantity ? line.quantity : ""}
                        </td>
                        <td 
                            onClick={()=>copyToClipboard(line.code ? line.code : "")} 
                            style={{borderWidth: '0px 1px 0px 0px', borderStyle: 'solid', borderColor:'#ccc'}}
                        >
                            {line.code ? line.code : ""}
                        </td>
                        <td 
                            onClick={()=>copyToClipboard(line.description ? line.description : "")} 
                            style={{borderWidth: '0px 1px 0px 0px', borderStyle: 'solid', borderColor:'#ccc'}}
                        >
                            {line.description ? line.description : ""}
                        </td>
                        <td 
                            onClick={()=>copyToClipboard(line.price ? line.price : "")} 
                            style={{borderWidth: '0px 1px 0px 0px', borderStyle: 'solid', borderColor:'#ccc'}}
                        >
                            {line.price ? nQuoteTotalNoDollar(line.price) : ""}
                        </td>
                        <td 
                            onClick={()=>copyToClipboard(line.extended ? line.extended : "")} 
                            style={{borderWidth: '0px 1px 0px 0px', borderStyle: 'solid', borderColor:'#ccc'}}
                        >
                            {line.extended ? nQuoteTotalNoDollar(line.extended) : ""}
                        </td>
                        <td 
                            onClick={()=>copyToClipboard(line.tax ? line.tax : "")} 
                            style={{borderWidth: '0px 1px 0px 0px', borderStyle: 'solid', borderColor:'#ccc'}}
                        >
                            {line.tax ? line.tax : ""}
                        </td>
                    </tr>
                ))}
            </table>
        </div>
        <br />
        <div>
            <table>
                    <tr>
                        <td rowSpan={4} width={700}></td>
                        <td onClick={()=>copyToClipboard(invoice.taxDistrict)}>TAX: {invoice.taxDistrict} ({invoice.taxRate}%)</td>
                        <td>{nQuoteTotalNoDollar(invoice.tax)}</td>
                    </tr>
                    <tr>
                        <td>TOTAL</td>
                        <td>{nQuoteTotalNoDollar(invoice.total+invoice.tax)}</td>
                    </tr>
            </table>
        </div>
        </>
    )
  }
}
