import React, { useState, useEffect } from 'react';
import { ContactList, ContactItem, ActionButton, EditActionButton } from '../../tables/marketing-wizard/StyledComponents'; // Styled components
import { getSQLData } from '../../../assets/functions/fetch';
import EditContactModal from './EditContactModal';
import ContactLog from './ContactLog';
import BlastEmailEditor from '../marketing-wizard/BlastEmailEditor';
import styled from 'styled-components'; // Import styled-components

const SuccessPopup = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #28a745;
  color: white;
  padding: 15px 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 1.1rem;
  z-index: 1000;
  animation: fadeOut 4s forwards;

  @keyframes fadeOut {
    0% { opacity: 1; }
    75% { opacity: 1; }
    100% { opacity: 0; }
  }
`;

const ContactCard = styled.div`
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const ContactHeader = styled.h3`
  margin-bottom: 10px;
  color: #333;
`;

const Section = styled.div`
  margin-bottom: 15px;
`;

const SectionTitle = styled.h4`
  margin-bottom: 8px;
  color: #666;
  font-size: 1.1rem;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
`;

const SectionContent = styled.p`
  margin: 5px 0;
  color: #444;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px; /* Added margin-bottom to create space between buttons and blast email editor */
`;

const NavigationButton = styled.button`
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const BlastEmailButton = styled.button`
  margin-top: 20px;
  padding: 12px 24px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #218838;
  }
`;

const CollapseButton = styled.button`
  margin-top: 20px;
  padding: 12px 24px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #c82333;
  }
`;

const ContactsList = ({ nRep }) => {
    const [contacts, setContacts] = useState([]);
    const [currentContactIndex, setCurrentContactIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showLogModal, setShowLogModal] = useState(false);
    const [showBlastEditor, setShowBlastEditor] = useState(false); // State for Blast Email Editor
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [displayAll, setDisplayAll] = useState(false); // State to toggle displaying all contacts

    const loadContacts = async () => {
        try {
            console.log('Fetching contacts for sRep:', nRep.toString()); // Debugging line
            const response = await getSQLData('marketing/tier3ContactsFiltered', { sRep: nRep.toString() });
            console.log('Response from tier3ContactsFiltered:', response); // Log the entire response

            if (response && response.data && response.data.data[0]) {
                const contactsData = response.data.data[0].recordset;
                console.log('Filtered Contacts data:', contactsData); // Log the contacts data
                setContacts(contactsData);
            } else {
                console.error('No data returned from the query');
            }
        } catch (error) {
            console.error('Error loading filtered contacts:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadContacts();
    }, []);

    const openEditModal = () => {
        setShowEditModal(true);
    };

    const closeEditModal = () => {
        setShowEditModal(false);
    };

    const openLogModal = () => {
        setShowLogModal(true);
    };

    const closeLogModal = () => {
        setShowLogModal(false);
    };

    const openBlastEmailEditor = () => { // Open Blast Email Editor
        setShowBlastEditor(true);
    };

    const closeBlastEmailEditor = () => { // Close Blast Email Editor
        setShowBlastEditor(false);
    };

    const handleNextContact = () => {
        if (currentContactIndex < contacts.length - 1) {
            setCurrentContactIndex(currentContactIndex + 1);
        }
    };

    const handlePreviousContact = () => {
        if (currentContactIndex > 0) {
            setCurrentContactIndex(currentContactIndex - 1);
        }
    };

    const toggleDisplayAll = () => {
        setDisplayAll(!displayAll);
    };

    const renderContactLogs = (contactLogs) => {
        if (!contactLogs || contactLogs.trim() === '') return <SectionContent>No previous interactions logged.</SectionContent>;

        // Split the logs and display each entry
        const logsList = contactLogs.split(', ');

        return (
            <ul>
                {logsList.map((log, index) => (
                    <li key={index}>{log}</li>
                ))}
            </ul>
        );
    };

    const renderContacts = () => {
        if (contacts.length === 0) return <li>No Tier 3 contacts found.</li>;

        if (displayAll) {
            return contacts.map((contact, index) => (
                <ContactCard key={contact.contactId}>
                    <ContactHeader>{contact.contactName} - {contact.companyName}</ContactHeader>

                    <Section>
                        <SectionTitle>Contact Information</SectionTitle>
                        <SectionContent>Email: {contact.contactEmail}</SectionContent>
                        <SectionContent>Phone: {contact.contactPhone}</SectionContent>
                    </Section>

                    <Section>
                        <SectionTitle>Recent Orders</SectionTitle>
                        {contact.recentOrders ? (
                            <ul>
                                {contact.recentOrders.split(', ').map((order, i) => {
                                    const [quoteId, orderDetails] = order.split('|');
                                    return (
                                        <li key={i}>
                                            <a href={`https://cfrinc.app/#/quote/${quoteId.trim()}`} target="_blank" rel="noopener noreferrer">
                                                {orderDetails}
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            <SectionContent>No recent orders</SectionContent>
                        )}
                    </Section>

                    <Section>
                        <SectionTitle>Top Item Category</SectionTitle>
                        <SectionContent>{contact.topItemCategory || 'N/A'}</SectionContent>
                    </Section>

                    <Section>
                        <SectionTitle>Sales in Last 2 Years</SectionTitle>
                        <SectionContent>{contact.salesLast2Years ? `$${contact.salesLast2Years.toLocaleString()}` : '0'}</SectionContent>
                    </Section>

                    <Section>
                        <SectionTitle>Previous Interactions</SectionTitle>
                        {renderContactLogs(contact.contactLogs)}
                    </Section>

                    <ButtonContainer>
                        <EditActionButton onClick={openEditModal}>Edit</EditActionButton>
                        <ActionButton onClick={openLogModal}>Log Interaction</ActionButton>
                    </ButtonContainer>
                </ContactCard>
            ));
        } else {
            const contact = contacts[currentContactIndex];

            return (
                <ContactCard key={contact.contactId}>
                    <ContactHeader>{contact.contactName} - {contact.companyName}</ContactHeader>

                    <Section>
                        <SectionTitle>Contact Information</SectionTitle>
                        <SectionContent>Email: {contact.contactEmail}</SectionContent>
                        <SectionContent>Phone: {contact.contactPhone}</SectionContent>
                    </Section>

                    <Section>
                        <SectionTitle>Recent Orders</SectionTitle>
                        {contact.recentOrders ? (
                            <ul>
                                {contact.recentOrders.split(', ').map((order, i) => {
                                    const [quoteId, orderDetails] = order.split('|');
                                    return (
                                        <li key={i}>
                                            <a href={`https://cfrinc.app/#/quote/${quoteId.trim()}`} target="_blank" rel="noopener noreferrer">
                                                {orderDetails}
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            <SectionContent>No recent orders</SectionContent>
                        )}
                    </Section>

                    <Section>
                        <SectionTitle>Top Item Category</SectionTitle>
                        <SectionContent>{contact.topItemCategory || 'N/A'}</SectionContent>
                    </Section>

                    <Section>
                        <SectionTitle>Sales in Last 2 Years</SectionTitle>
                        <SectionContent>{contact.salesLast2Years ? `$${contact.salesLast2Years.toLocaleString()}` : '0'}</SectionContent>
                    </Section>

                    <Section>
                        <SectionTitle>Previous Interactions</SectionTitle>
                        {renderContactLogs(contact.contactLogs)}
                    </Section>

                    <ButtonContainer>
                        <EditActionButton onClick={openEditModal}>Edit</EditActionButton>
                        <ActionButton onClick={openLogModal}>Log Interaction</ActionButton>
                    </ButtonContainer>
                </ContactCard>
            );
        }
    };

    if (loading) {
        return <div>Loading contacts...</div>;
    }

    return (
        <div>
            <h2>Tier 3 Marketing Contacts</h2>

            {showSuccessMessage && (
                <SuccessPopup>Contact logged successfully!</SuccessPopup>
            )}

            <ContactList>
                {renderContacts()}
            </ContactList>

            <ButtonContainer>
                <NavigationButton onClick={handlePreviousContact} disabled={currentContactIndex === 0}>
                    Previous
                </NavigationButton>
                <NavigationButton onClick={handleNextContact} disabled={currentContactIndex === contacts.length - 1}>
                    Next
                </NavigationButton>
                <NavigationButton onClick={toggleDisplayAll}>
                    {displayAll ? 'Collapse' : 'Display All'}
                </NavigationButton> {/* Button to toggle display mode */}
            </ButtonContainer>

            {!showBlastEditor && ( // Render Send Blast Email Button only when editor is hidden
                <BlastEmailButton onClick={openBlastEmailEditor}>
                    Send Blast Email to Tier 3 Contacts
                </BlastEmailButton>
            )}

            {showBlastEditor && ( // Render Blast Email Editor and Collapse Button
                <>
                    <BlastEmailEditor onClose={closeBlastEmailEditor} />
                    <CollapseButton onClick={closeBlastEmailEditor}>
                        Collapse Blast Email
                    </CollapseButton>
                </>
            )}

            {showEditModal && contacts.length > 0 && (
                <EditContactModal
                    contact={contacts[currentContactIndex]}
                    onClose={closeEditModal}
                    onSave={loadContacts}
                />
            )}

            {showLogModal && contacts.length > 0 && (
                <ContactLog
                    contactId={contacts[currentContactIndex].contactId}
                    repId={contacts[currentContactIndex].sRep}
                    onClose={closeLogModal}
                    onLogSuccess={() => {
                        setShowSuccessMessage(true);
                        setTimeout(() => setShowSuccessMessage(false), 2000);
                    }}
                />
            )}
        </div>
    );
};

export default ContactsList;