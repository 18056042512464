import React, { Component } from 'react';
import styled from 'styled-components';
import QuoteLinesPane from './QuoteLinesPane';
import Billing from './Billing';
import AdditionalInformation from './AdditionalInformation';
import QuoteLinesBreakdown from './QuoteLinesBreakdown';

const Top = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export default class QuoteLines extends Component {
  render() {
    return (
      <div>
        <Top>
          <Billing
            dtBillingCycleStart={this.props.data.dtBillingCycleStart}
            dtBillingCycleEnd={this.props.data.dtBillingCycleEnd}
            sRentalPeriod={this.props.data.sRentalPeriod}
            nInsurance={this.props.data.nInsurance}
          />
          <AdditionalInformation
            nContractType={this.props.data.nContractType}
            nDeliveryType={this.props.data.nDeliveryType}
            bCC={this.props.data.bCC}
            bNet={this.props.data.bNet}
            bCOD={this.props.data.bCOD}
          />
        </Top>
        <QuoteLinesBreakdown
          POs={this.props.POs}
          quoteLines={this.props.quoteLines}
          nDelivery={this.props.data.nDelivery}
        />
        <QuoteLinesPane
          quoteLines={this.props.quoteLines}
          nDelivery={this.props.data.nDelivery}
          nTaxRate={this.props.data.nTaxRate}
        />
      </div>
    );
  }
}
