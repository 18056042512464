import React, { Component } from 'react';
import { nQuoteTotalNoDollar } from '../../../../assets/functions/calculations';
import OpenInstallsChart from './OpenInstallsChart';


export default class OpenInstallsHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
          showChart: false,
        };
      }

  render() {
    
    return (
        <>
            <div>Open Jobs: {this.props.data.length} /  
            Margin: {nQuoteTotalNoDollar(this.props.chartData.marginTotal)} / 
            Labor: {nQuoteTotalNoDollar(this.props.chartData.laborTotal)}</div>
            <div onClick={() => this.setState({showChart: !this.state.showChart})}>Show Chart</div>
            {this.state.showChart && (<OpenInstallsChart chartData={this.props.chartData} />)}
        </>
    );
  }
}
