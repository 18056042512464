import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { nQuoteTotalNoDollar, whichRep } from '../../../../assets/functions/calculations';
import moment from 'moment';
import { CustomDateWrapper, Input, SubmitText, GreyTextDiv, Title } from '../../../styled/ui';
import { numberOfDays } from '../../../../assets/functions/display';
import { returnSiteVisitArrayName } from '../../../../assets/functions/process/cam';


export default class CamSalesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    let viewing = this.props.viewing
    let data = this.props.data
    let arrayName = returnSiteVisitArrayName(viewing)
    return (
      <>
      <Title>{viewing}</Title>
      {data[arrayName].length > 0 && (
        <div><table>
          <thead>
            <tr>
              <th>Quote</th>
              <th>Location</th>
              <th>Customer</th>
              <th>Price</th>
              <th>Age</th>
              <th>Rep</th>
              <th>Type</th>
              <th>Notes</th>
            </tr>
          </thead>
          {data[arrayName].map((quote, index) => (

              <tr style={{border:'1px solid black'}}>
                <td>
                  <div><Link target="_blank" to={`/quote/${quote.id}`}>{quote.id} <br /> {quote.sQuoteTitle}</Link></div>
                </td>
                <td>
                  <div>{quote.sCompany}</div>
                  <div>{quote.sCity}, {quote.sState} {quote.sZip}</div>
                </td>
                <td>
                  <div>{quote.sCompany} - {quote.sName}</div>
                </td>
                <td>
                    <div>{nQuoteTotalNoDollar(quote.nQuoteTotal)}</div>
                </td>
                <td>
                  <div>{numberOfDays(quote.Age)} old</div>
                </td>
                <td>
                  <div>{quote.nRep ? whichRep(quote.nRep) : null}</div>
                </td>
                <td>
                  <div>{quote.sType} {quote.sCampaign ? quote.sCampaign : ""}</div>
                </td>
                <td>
                  <div>{quote.sStatus}</div>           
                </td> 
                    <td style={{verticalAlign: 'top', textAlign:'left'}}>
                            {quote.notes.map((note, index)=>(
                            <>
                            {index < 6 && (
                              <div style={{margin: '10px'}}>
                                <div><b>{whichRep(note.nRep)} {moment(note.dtTimeStamp).utc().format('hh:mm A MM/DD/YYYY')}</b></div>
                                <div>{note.sNote}</div>
                              </div>
                            )}
                            </>
                          ))}

                    </td>        
      
                    
                    </tr>

          ))}
          </table>
        </div>
      )}
          
      </>
    );
  }
}