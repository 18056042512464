import React, { Component } from 'react';
import {
  BrowserRouter as Redirect,
  Switch,
  Route,
  Link,
} from 'react-router-dom';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { days_between } from '../../assets/functions/time';

function dateColor(dtDate) {
  let days = days_between(new Date(), dtDate);

  if (days > 1) {
    return '#ff5959';
  }
}

const Wrapper = styled.div`
  width: 95%;
  padding: 0.2em;
  margin: 0.2em 0.4em 0.2em 0;
  position: relative;
  border-radius: 5px;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  background-color: #fff;

  &:hover {
    color: #222;
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }

  p {
    padding: 0.3em 0.5em;
    text-align: left;
  }
`;

class FollowupItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: '',
    };
  }

  render() {
    return (
      <Wrapper
        data-tip={'Quote: ' + this.props.nQuote}
        style={{
          backgroundColor: dateColor(this.props.dtDate),
        }}
      >
        <ReactTooltip />
        <Link to={`/quote/${this.props.nQuote}`}>
          <p>
            <i className="fas fa-briefcase"></i> {this.props.sCompany}
          </p>
          {this.props.sQuoteTitle && (
            <p>
              <i className="fas fa-quote-left"></i> {this.props.sQuoteTitle}
            </p>
          )}
          <p>
            <i className="fas fa-user"></i> {this.props.sName}
          </p>
          <p>
            <i className="fas fa-phone"></i> {this.props.sPhone}
          </p>
          <p>
            <i className="fas fa-sticky-note"></i>{' '}
            {this.props.sNote.slice(0, 200)}
          </p>
        </Link>
      </Wrapper>
    );
  }
}

export default FollowupItem;
