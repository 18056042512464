import React, { Component } from 'react';
import styled from 'styled-components';
import FollowupItem from './FollowupItem';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  height: fit-content;
  text-align: center;

  button {
    cursor: pointer;
    width: 85%;
    margin: 0.5em auto;
    height: 40px;
    border: none;
    transition: all 0.2s;
    border-radius: 4px;

    &:nth-child(2) {
      margin: 0 auto 0.5em auto;
    }

    &:hover {
      background-color: #4aabed;
      color: #fff;
    }
  }
`;

const FollowupItemContainer = styled.div`
  margin: 1em 0 0 0;
`;

export default class Followups extends Component {
  state = {};

  handleOnClick = (event) => {
    let tab = event.target.textContent.replace(/\s/g, '');
    this.props.onClick(tab);
  };

  render() {
    return (
      <Wrapper>
        <FollowupItemContainer>
          {this.props.followups.map((sql) => (
            <FollowupItem
              key={sql.id}
              name={sql.nRep}
              dateAdded={sql.dtAdded}
              dtCompleted={sql.dtCompleted}
              dtDate={sql.dtDate}
              nStatus={sql.nStatus}
              sNote={sql.sNote}
            />
          ))}
        </FollowupItemContainer>
        <button>New Followup</button>
      </Wrapper>
    );
  }
}
