import React, { Component } from 'react'
import ImportCallLog from './call-log/ImportCallLog'

export default class CallLog extends Component {
  render() {
    return (
      <div>
        <ImportCallLog />
      </div>
    )
  }
}
