import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Loader from 'react-loader-spinner';
import styled from 'styled-components';
import Moment from 'moment';
import {
  round,
  calcTotalMargin,
  whichRep,
  marginColorCam,
} from '../../../assets/functions/calculations';
import {
  calcPreSalesTax,
  calcPreTaxMargin,
  calcActiveSalesTotal,
  calcActiveMarginTotal,
} from '../../../assets/functions/calculations';
import MediaQuery from 'react-responsive';

const Wrapper = styled.div`
  width: 99%;
  max-width: 1200px;
  margin: 5em auto;

  table {
    width: 100%;
    position: relative;
    ${(props) => {
      if (props.amount < 10)
        return `
            
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;

    thead + tbody tr:last-child td:last-child,
    tfoot tr:last-child td:last-child {
      border-bottom-right-radius: 15px !important;
    }
    thead + tbody tr:last-child td:first-child,
    tfoot tr:last-child td:first-child {
      border-bottom-left-radius: 15px !important;
    }
        `;
    }}
  }

  button {
    position: absolute;
    width: 100%;
    bottom: -27px;
     padding: 0.35em 1.2em;
     text-decoration: none;
     color: #fafafa;
     text-align: center;
     transition: all 0.2s;
    background-color: #4aabed;
    border: 1px solid #4aabed;
    cursor: pointer;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    &:hover {
      color: #222;
      background-color: #fafafa;
      border: 1px solid #d7d7d7;
    }
  }

  span {
    margin: 0 1em;
  }
`;

const SubTitle = styled.h2`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  border-bottom: none !important;
  margin-bottom: 1em;

  i {
    font-size: 16px;
    margin-left: 7px;
  }
`;



export default class CamCsnClosedOrders extends Component {
  state = {
    data: [],
    loaded: false,
    showAll: false,
  };

  // Async Axios
  fetchData = async (startDate, endDate) => {
    try {
      const response = await Axios.get('/cam-csn-dashboard/closed-orders', {
        params: {
          startDate: startDate,
          endDate: endDate,
        },
        headers: { 'Content-Type': 'application/json' },
      });
      // Sets the state using the response data
      this.setState({
        data: response.data.result[0].recordset,
        loaded: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    this.fetchData(this.props.startDate, this.props.endDate);
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.fetchData(this.props.startDate, this.props.endDate);
    }
  }

  render() {
    let table;

    // If the state showAll is false then table with return with only 10 rows, else show all 100
    if (this.state.showAll === false) {
      table = (
        <>
          {this.state.data.slice(0, 10).map((sql) => (
            <tr
              
              key={sql.id}
            >
              <MediaQuery minDeviceWidth={900}>
                <td>
                  <Link target="_blank" to={`/quote/${sql.id}`}>
                    {sql.id}
                  </Link>
                </td>
              </MediaQuery>
              <td>
                <Link
                  target="_blank"
                  to={`/crm/master-viewer/${sql.sCompany.replace(
                    /\//g,
                    '%2F'
                  )}`}
                >
                  {sql.sCompany}
                </Link>
              </td>
              <td>{Moment(sql.dtFirstClosed).utc().format('MM/DD/YYYY')}</td>
              <td style={{ textAlign: 'center' }}>
                {'$' +
                  calcPreSalesTax(
                    sql.nQuoteTotal,
                    sql.nTaxRate,
                    sql.bTaxDel,
                    sql.nDelivery
                  ).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
              </td>
              <MediaQuery minDeviceWidth={900}>
                <td style={{ textAlign: 'center' }}>
                  {'$' +
                    calcPreTaxMargin(
                      sql.nQuoteTotal,
                      sql.nTaxRate,
                      sql.nPoTotals,
                      sql.bTaxDel,
                      sql.nDelivery
                    ).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                </td>
                <td style={{
                backgroundColor: marginColorCam(
                  ((calcPreSalesTax(
                    sql.nQuoteTotal,
                    sql.nTaxRate,
                    sql.bTaxDel,
                    sql.nDelivery
                  ) -
                    sql.nPoTotals) /
                    calcPreSalesTax(
                      sql.nQuoteTotal,
                      sql.nTaxRate,
                      sql.bTaxDel,
                      sql.nDelivery
                    )) *
                    100
                ),
               textAlign: 'center' }}>
                  {(
                    ((calcPreSalesTax(
                      sql.nQuoteTotal,
                      sql.nTaxRate,
                      sql.bTaxDel,
                      sql.nDelivery
                    ) -
                      sql.nPoTotals) /
                      calcPreSalesTax(
                        sql.nQuoteTotal,
                        sql.nTaxRate,
                        sql.bTaxDel,
                        sql.nDelivery
                      )) *
                    100
                  ).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }) + '%'}
                </td>
              </MediaQuery>
              <td>{whichRep(sql.nRep)}</td>
            </tr>
          ))}
        </>
      );
    } else {
      table = (
        <>
          {this.state.data.map((sql) => (
            <tr
              style={{
                backgroundColor: marginColorCam(
                  ((calcPreSalesTax(
                    sql.nQuoteTotal,
                    sql.nTaxRate,
                    sql.bTaxDel,
                    sql.nDelivery
                  ) -
                    sql.nPoTotals) /
                    calcPreSalesTax(
                      sql.nQuoteTotal,
                      sql.nTaxRate,
                      sql.bTaxDel,
                      sql.nDelivery
                    )) *
                    100
                ),
              }}
              key={sql.id}
            >
              <MediaQuery minDeviceWidth={900}>
                <td>
                  <Link target="_blank" to={`/quote/${sql.id}`}>
                    {sql.id}
                  </Link>
                </td>
              </MediaQuery>
              <td>
                <Link
                  target="_blank"
                  to={`/crm/master-viewer/${sql.sCompany.replace(
                    /\//g,
                    '%2F'
                  )}`}
                >
                  {sql.sCompany}
                </Link>
              </td>
              <td>{Moment(sql.dtFirstClosed).utc().format('MM/DD/YYYY')}</td>
              <td style={{ textAlign: 'center' }}>
                {'$' +
                  calcPreSalesTax(
                    sql.nQuoteTotal,
                    sql.nTaxRate,
                    sql.bTaxDel,
                    sql.nDelivery
                  ).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
              </td>
              <MediaQuery minDeviceWidth={900}>
                <td style={{ textAlign: 'center' }}>
                  {'$' +
                    calcPreTaxMargin(
                      sql.nQuoteTotal,
                      sql.nTaxRate,
                      sql.nPoTotals,
                      sql.bTaxDel,
                      sql.nDelivery
                    ).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                </td>
                <td style={{ textAlign: 'center' }}>
                  {(
                    ((calcPreSalesTax(
                      sql.nQuoteTotal,
                      sql.nTaxRate,
                      sql.bTaxDel,
                      sql.nDelivery
                    ) -
                      sql.nPoTotals) /
                      calcPreSalesTax(
                        sql.nQuoteTotal,
                        sql.nTaxRate,
                        sql.bTaxDel,
                        sql.nDelivery
                      )) *
                    100
                  ).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }) + '%'}
                </td>
              </MediaQuery>
              <td>{whichRep(sql.nRep)}</td>
            </tr>
          ))}
        </>
      );
    }

    return (
      <Wrapper amount={this.state.data.length}>
        <SubTitle>
          CAM/CSN Closed Orders <i class="far fa-clock"></i>
        </SubTitle>
        <div class="legend">
          <div class="legend-scale">
            <ul class="legend-labels">
              <li>
                <span style={{ background: marginColorCam(20) }}></span>
                0%- 29%
              </li>
              <li>
                <span style={{ background: marginColorCam(35) }}></span>
                30% - 39%
              </li>
              <li>
                <span style={{ background: marginColorCam(50) }}></span>
                40% - 55%
              </li>
              <li>
                <span style={{ background: marginColorCam(60) }}></span>
                56% - 100%
              </li>
            </ul>
          </div>
        </div>
        <h4>
          <span>
            Sales Total: $
            {calcActiveSalesTotal(this.state.data).toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
          <span>
            Gross Profit Total: $
            {calcActiveMarginTotal(this.state.data).toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
          {isNaN(round(calcTotalMargin(this.state.data))) ? (
            <span>Gross Margin: 0%</span>
          ) : (
            <span>
              Gross Margin: {round(calcTotalMargin(this.state.data))}%
            </span>
          )}
        </h4>
        <table style={{ position: 'relative' }} class="dashboard">
          <thead>
            <tr>
              <MediaQuery minDeviceWidth={900}>
                <th>ID</th>
              </MediaQuery>
              <th>Company</th>
              <th>Date</th>
              <th>Sales</th>
              <MediaQuery minDeviceWidth={900}>
                <th>Gross Profit</th>
                <th>Gross Margin</th>
              </MediaQuery>
              <th>Rep</th>
            </tr>
          </thead>
          <tbody>
            {this.state.loaded || (
              <tr>
                <td colSpan="7">
                  <Loader
                    style={{
                      textAlign: 'center',
                    }}
                    type="ThreeDots"
                    color="#00BFFF"
                    height={20}
                    width={120}
                  />
                </td>
              </tr>
            )}
            {this.state.loaded && (
              <>
                {this.state.data.length > 0 ? (
                  <>{table}</>
                ) : (
                  <tr>
                    <td
                      colSpan="7"
                      style={{ textAlign: 'center', padding: '1em' }}
                    >
                      No quotes
                    </td>
                  </tr>
                )}
                {this.state.data.length > 10 ? (
                  <>
                    {this.state.showAll ? (
                      <button
                        onClick={() => {
                          this.setState({
                            showAll: false,
                          });
                        }}
                      >
                        Hide
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          this.setState({
                            showAll: true,
                          });
                        }}
                      >
                        Show All
                      </button>
                    )}
                  </>
                ) : null}
              </>
            )}
          </tbody>
        </table>
      </Wrapper>
    );
  }
}
