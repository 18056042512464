import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin: 1em auto 0 auto;
  width: 100%;
  height: fit-content;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.1);
  text-align: center;
  border-radius: 15px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: center;

  padding: 1em;
  margin: 0 auto;

  div {
    margin: 0 1em;
  }

  p {
    text-align: center;
    margin: 0.5em;
    justify-content: space-between;

    span {
      font-weight: bold;
    }
  }
`;

export default class QuoteLinesBreakdown extends Component {
  render() {
    let nPoTotals = 0;
    let nSubTotal = 0;
    let markup;
    let grossProfit = 0;
    let grossMargin;

    // Calculates nPoTotals
    for (let i = 0; i < this.props.POs.length; i++) {
      nPoTotals += this.props.POs[i].nPOTotal;
    }

    // Calculates nSubTotal
    for (let i = 0; i < this.props.quoteLines.length; i++) {
      let nItemTotal =
        this.props.quoteLines[i].nPrice * this.props.quoteLines[i].nQuantity;
      nSubTotal += nItemTotal;
    }

    // Calculates markup, if markup is infinity, will return a symbol
    if (
      ((nSubTotal + this.props.nDelivery - nPoTotals) / nPoTotals) * 100 !==
      Infinity
    ) {
      markup = `${(
        ((nSubTotal + this.props.nDelivery - nPoTotals) / nPoTotals) *
        100
      ).toFixed(2)}%`;
    } else {
      markup = '∞';
    }

    // Calculates gross profit
    grossProfit = nSubTotal + this.props.nDelivery - nPoTotals;

    // Calculates gross margin
    grossMargin = (
      ((nSubTotal + this.props.nDelivery - nPoTotals) /
        (nSubTotal + this.props.nDelivery)) *
      100
    ).toFixed(2);

    return (
      <Wrapper>
        <Container>
          <div>
            <p>
              <span>PO Totals</span>
            </p>
            <p>
              $
              {nPoTotals.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
          </div>
          <div>
            <p>
              <span>Gross Profit</span>
            </p>
            <p>
              $
              {grossProfit.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
          </div>
          <div>
            <p>
              <span>Markup</span>
            </p>
            <p>{markup === NaN ? markup : '0'}%</p>
          </div>
          <div>
            <p>
              <span>Gross Margin</span>
            </p>
            <p>{grossMargin === NaN ? grossMargin : '0'}%</p>
          </div>
          {/* <div>
            <p>
              <span>Estimated Commission</span>
            </p>
            <p>
              $
              {(
                (nSubTotal + this.props.nDelivery - nPoTotals) *
                0.1
              ).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
          </div> */}
        </Container>
      </Wrapper>
    );
  }
}
