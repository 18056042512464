import React, { Component } from 'react'
import { getSQLData, setSQLData } from '../../../../assets/functions/fetch';
import { calcPreSalesTaxCommissionLog, nQuoteTotalNoDollar, whichRep } from '../../../../assets/functions/calculations';
import moment from 'moment';

export default class LogExceptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loaded: false,
        };

      }

      componentDidMount(){
        this.fetchData()
      }

      fetchData = async() => {
        try {
            const fetch = await getSQLData("accounting/getCommissionLogExceptions", {})
            
            if(fetch){
                let data = fetch.data.data[0].recordset

                this.setState({
                    data: data,
                }, ()=>this.processData())

            }

        } catch (error) {

        }
      }

      processData() {
        let data = this.state.data
        let exceptions = []
        let summary = {}

        for(let i=0; i<data.length; i++){
            if(!exceptions.includes(data[i].sException)){
              exceptions.push(data[i].sException)
              summary[data[i].sException] = {
                count: 0,
                total: 0,
              }
            }
            summary[data[i].sException].count++
            summary[data[i].sException].total += data[i].nMargin
            
        }

        this.setState({
            data: data,
            loaded: true,
            exceptions: exceptions,
            show: [...exceptions],
            summary: summary,
        })
      }

      addLog = async(index) => {
        let entry = this.state.data[index]
        try {
            const fetch = await setSQLData("accounting/commission_log_import",{
                nQuote: entry.id,
                nRep: entry.nRep,
                nMargin: entry.margin, 
                dtDate: moment().format("MM/DD/YYYY")
            })

            if(fetch){this.fetchData()}
        } catch (error) {

        }
        
      }

      hideShow(type){
        let show = this.state.show

        if(show.includes(type)){
          const index = show.indexOf(type)
          show.splice(index, 1)
        } else {
          show.push(type)
        }

        this.setState({
          show: show,
        })
      }

      insertException = async(index, exception) => {
        let entry = this.state.data[index]
        try {
            const fetch = await setSQLData("accounting/editCommissionLogException",{
                nExceptionId: entry.id,
                sException: exception,
            })

            if(fetch){this.fetchData()}
        } catch (error) {

        }
        
      }

      deleteLog = async(logID) => {
        try {
          const fetch = await setSQLData("accounting/deleteCommissionLog",{
            logID: logID,
          })
          
          if(fetch){this.fetchData()}
        } catch (error) {

        }
      }

  render() {
    return (
      <div>
        {this.state.loaded && (
          <>
          {this.state.exceptions.map((type, index) => (
            <div>{type}: {this.state.summary[type].count} = {nQuoteTotalNoDollar(this.state.summary[type].total)} 
              <button onClick={()=>this.hideShow(type)}>{this.state.show.includes(type) ? "Hide" : "Show"}</button>
            </div>
          ))}

          
        <table>
            <thead>
                <tr>
                    <th>Quote</th>
                    <th>Margin</th>
                    <th>Rep</th>
                    <th>Exception</th>
                    <th></th>
                </tr>
            </thead>
        
        {this.state.data.map((quote,index)=>(
          <>
            {this.state.show.includes(quote.sException) && (
            <tr>
                <td>{quote.nQuote}</td>
                <td>{nQuoteTotalNoDollar(quote.nMargin)}</td>
                <td>{whichRep(quote.nRep)}</td>
                <td>{quote.sException}</td>
                <td><button onClick={()=>this.deleteLog(quote.id)}>Delete</button></td>              
            </tr>
            )}
          </>
        ))}
        </table>
        </>
        )}
      </div>
    )
  }
}
