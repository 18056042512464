import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Axios from 'axios';
import Loader from 'react-loader-spinner';
import Moment from 'moment';
import { whichRep } from '../../../assets/functions/calculations';

const Wrapper = styled.div`
  width: 99%;
  max-width: 1300px;
  margin: 4em auto;

  table {
    width: 100%;
    position: relative;
    ${(props) => {
      if (props.amount > 10)
        return `
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;

    thead + tbody tr:last-child td:last-child,
    tfoot tr:last-child td:last-child {
      border-bottom-right-radius: 0px !important;
    }
    thead + tbody tr:last-child td:first-child,
    tfoot tr:last-child td:first-child {
      border-bottom-left-radius: 0px !important;
    }
    margin-bottom: 7em;
        `;
    }}
  }

  button {
    position: absolute;
    width: 100%;
    bottom: -27px;
     padding: 0.35em 1.2em;
     text-decoration: none;
     color: #fafafa;
     text-align: center;
     transition: all 0.2s;
    background-color: #4aabed;
    border: 1px solid #4aabed;
    cursor: pointer;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    &:hover {
      color: #222;
      background-color: #fafafa;
      border: 1px solid #d7d7d7;
    }
  }
`;

const SubTitle = styled.h2`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1em;

  i {
    font-size: 16px;
    margin-left: 7px;
  }
`;

const TableRow = styled.tr`
  ${(props) => {
    if (props.sFrom.includes(props.sEmail))
      return `
        background-color: #85bb65;

        &:hover {
          background-color: #85bb65 !important;
        }
        `;
  }}
  ${(props) => {
    if (
      props.sFrom.includes(whichRep(props.nRep)) &&
      props.sEmail === props.sTo
    )
      return `
        font-weight: bold;
        `;
  }}
`;

export default class MajorIssues extends Component {
  state = {
    reviews: [],
    comments: [],
    feed: [],
    loaded: false,
    showAll: false,
  };

  // Async Axios
  fetchData = async () => {
    try {
      const response = await Axios.get('/srm/major-issues', {
        headers: { 'Content-Type': 'application/json' },
      });
      // // Sets the state using the response data
      this.setState({
        reviews: response.data.result[0].recordsets[0],
        loaded: true,
      }, () => this.arrangeData());
    } catch (error) {
      console.error(error);
    }
  };

  arrangeData = () => {
    let feed = this.state.reviews
    console.log(feed)
    this.setState({feed: feed})
  };

  componentDidMount() {
    this.fetchData();
  };

  render() {
    return (
      <>
          <Wrapper amount={100}>
            <SubTitle>
              Most Recent Major Issues
            </SubTitle>
                {this.state.loaded || (
                  <tr>
                    <td colSpan="7">
                      <Loader
                        style={{
                          textAlign: 'center',
                        }}
                        type="ThreeDots"
                        color="#00BFFF"
                        height={20}
                        width={120}
                      />
                    </td>
                  </tr>
                )}
                {this.state.loaded && (
                  <>
                    {this.state.feed.map((feed,index) => (
                      <div style={{textAlign: 'left'}}>
                        {
                          <>
                            {(feed.bLogistics && (feed.sLogisticsImpact === "Major Impact" || feed.sLogisticsImpact === "Rental Refunded")) || (feed.bEquipment && (feed.sEquipmentImpact === "Rental Refunded" || feed.sEquipmentImpact === "Major Impact")) && (
                              <>
                                <div style={{display:'inline-block', width: '400px', outline: '1px solid black', padding: '5px', height: '100px', verticalAlign: 'top'}}>
                                  <div>Review for <NavLink to={"/srm/master-viewer/" + feed.nSupplier}><b>{feed.supplier}</b></NavLink></div>
                                  <div>By {feed.repFirst} {feed.repLast} on {Moment(feed.dtReview).utc().format('MM-DD-YYYY')}</div>
                                  <div>Quote: {feed.nQuote}</div>
                                </div>
                                <div style={{display: 'inline-block', width: '800px', outline: '1px solid black', padding: '5px', height: '100px', verticalAlign: 'top'}}>
                                  <div>
                                    {!feed.bEquipment && !feed.bLogistics && (
                                      "No issues reported."
                                    )}
                                    {feed.bLogistics && (
                                      <>Logistics issue reported.  Impact: <b>{feed.sLogisticsImpact}</b> Response:  <b>{feed.bLogisticsResponse ? "Acceptable" : "Unacceptable"}</b><br /></>
                                    )}
                                    {feed.bEquipment && (
                                      <>Equipment issue reported.  Impact: <b>{feed.sEquipmentImpact}</b> Response: <b>{feed.bEquipmentResponse ? "Acceptable" : "Unacceptable"}</b></>
                                    )}
                                  </div>
                                </div>

                              </>
                            )}
                          
                          </>
                          }
                      </div>
                    ))}
                  </>
                )}
          </Wrapper>
        
      </>
    );
  }
}
