import { Component } from "react";
import { displayLeadsCount } from "../../assets/functions/leads";
import UnassignedLead from "../misc/UnassignedLead";

class LeadsTile extends Component {
    state = {
        showAllRacLeads: false,
        showAllCamLeads: false,
    }

    render() {
        let racLeads = (this.state.showAllRacLeads ? [...this.props.racLeads] : (this.props.racLeads ? [this.props.racLeads[0]] : []))
        let camLeads = (this.state.showAllRacLeads ? [...this.props.camLeads] : (this.props.camLeads ? [this.props.camLeads[0]] : []))

        return(
            <>
            {this.props.permissions.RAC && (
              <>
                {this.props.racLeads && this.props.racLeads.length > 0 && (
                  <>
                    <h2 style={{ textAlign: 'center' }}>
                      New RAC Leads ({displayLeadsCount(this.props.racLeads)})
                    </h2>
                        {racLeads.map((sql) => (
                          <UnassignedLead
                            key={sql.id}
                            id={sql.id}
                            dtDateStamp={sql.dtDateStamp}
                            leadSound={this.props.leadSound}
                          />
                        ))}
                    {this.props.permissions.admin && (
                      <>
                        {this.props.racLeads && this.props.racLeads.length > 1 && (
                          <p
                            onClick={() => {
                              this.setState({
                                showAllRacLeads: !this.state.showAllRacLeads,
                              });
                            }}
                            style={{ cursor: 'pointer', marginTop: '0.5em' }}
                          >
                            {this.state.showAllRacLeads ? (
                              <>Hide</>
                            ) : (
                              <>Show All</>
                            )}
                          </p>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}


            {this.props.permissions.CSN && (
              <>
                {this.props.camLeads && this.props.camLeads.length > 0 && (
                  <>
                    <h2 style={{ textAlign: 'center' }}>
                      New CAM Leads ({displayLeadsCount(this.props.camLeads)})
                    </h2>

                        {camLeads.map((sql) => (
                          <UnassignedLead
                            key={sql.id}
                            id={sql.id}
                            dtDateStamp={sql.dtDateStamp}
                          />
                        ))}
                      </>
                    )}
                    {this.props.permissions.admin && (
                      <>
                        {this.props.camLeads && this.props.camLeads.length > 1 && (
                          <p
                            onClick={() => {
                              this.setState({
                                showAllCamLeads: !this.state.showAllCamLeads,
                              });
                            }}
                            style={{ cursor: 'pointer', marginTop: '0.5em' }}
                          >
                            {this.state.showAllCamLeads ? (
                              <>Hide</>
                            ) : (
                              <>Show All</>
                            )}
                          </p>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
        )
    }
}

export default LeadsTile;