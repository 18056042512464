import React, { useEffect, useState } from 'react';
import { StyledTable, Spinner } from '../../tables/marketing-wizard/StyledComponents';
import { getSQLData } from '../../../assets/functions/fetch';

const MarketingEffortsSummary = () => {
    const [summary, setSummary] = useState([]);
    const [loading, setLoading] = useState(true);

    const loadMarketingEfforts = async () => {
        try {
            const response = await getSQLData('marketing/marketingEffortsData', { numContacts: 10 });
            if (response && response.data && response.data.data[0]) {
                setSummary(response.data.data[0].recordset);
            }
        } catch (error) {
            console.error('Error fetching marketing efforts:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadMarketingEfforts();
    }, []);

    if (loading) {
        return <Spinner />;
    }

    return (
        <div>
            <h2>Marketing Efforts Summary</h2>
            <StyledTable>
                <thead>
                    <tr>
                        <th>Rep</th>
                        <th># Tier 3 Contacts</th>
                        <th>$ Sales</th>
                        <th>Phone Calls</th>
                    </tr>
                </thead>
                <tbody>
                    {summary.length > 0 ? (
                        summary.map((entry) => (
                            <tr key={entry.repId}>
                                <td>{entry.repName}</td>
                                <td>{entry.tier3Contacts}</td>
                                <td>{entry.sales}</td>
                                <td>{entry.phoneCalls}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="4">No marketing efforts data found.</td>
                        </tr>
                    )}
                </tbody>
            </StyledTable>
        </div>
    );
};

export default MarketingEffortsSummary;