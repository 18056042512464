import React, { Component } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStickyNote, faEnvelope, faBuilding } from '@fortawesome/free-solid-svg-icons';
import { getSQLData } from '../../../assets/functions/fetch';
import EmailDetailsModal from './EmailDetailsModal';
import CompanyDetailsModal from './CompanyDetailsModal';
import { StyledTable, IconButton } from './StyledComponents';

const repNames = {
  5: 'Andy Wendt',
  54: 'Brian Kubala',
  63: 'Hunter Stewart',
  64: 'Jeff Bruck',
  71: 'Jill Staley',
  1: 'John Beagle',
  45: 'Johnny Beagle',
  44: 'Kevin Boles',
  68: 'Kevin Thompson',
  40: 'Kristen Fisher',
  51: 'Mark Gillaugh',
  22: 'Randy Moore',
  27: 'Robert Beagle',
  14: 'Susan Beagle',
  8: 'Tony Cipollone',
};

class DiscontinuedQuotesModal extends Component {
  state = {
    discontinuedQuotes: [],
    notes: {},
    emails: {},
    activeNotes: {},
    activeEmails: {},
    selectedEmail: null,
    selectedCompany: null,
  };

  componentDidMount() {
    this.loadDiscontinuedQuotes();
  }

  loadDiscontinuedQuotes = async () => {
    try {
      const discontinuedQuotesFetch = await getSQLData("logistics/discontinuedQuotes", { numQuotes: 10 });
      if (discontinuedQuotesFetch && discontinuedQuotesFetch.data && discontinuedQuotesFetch.data.data[0]) {
        const discontinuedQuotes = discontinuedQuotesFetch.data.data[0].recordset;
        this.setState({ discontinuedQuotes }, this.fetchAllNotesAndEmails);
      }
    } catch (error) {
      console.error('Error loading discontinued quotes:', error);
    }
  };

  fetchAllNotesAndEmails = async () => {
    const { discontinuedQuotes } = this.state;
    const quoteIds = discontinuedQuotes.map(q => q.id);

    try {
      const notesFetch = await getSQLData("logistics/notesDiscontinued", { quoteIds });
      const emailsFetch = await getSQLData("logistics/emailsDiscontinued", { quoteIds });

      const notesRecordset = notesFetch.data.data[0]?.recordset || [];
      const emailsRecordset = emailsFetch.data.data[0]?.recordset || [];

      let notes = {};
      let emails = {};

      discontinuedQuotes.forEach(quote => {
        notes[quote.id] = notesRecordset.filter(note => note.nQuote === quote.id);
        emails[quote.id] = emailsRecordset.filter(email => email.nQuote === quote.id);
      });

      this.setState({ notes, emails });
    } catch (error) {
      console.error('Error fetching notes or emails:', error);
    }
  };

  toggleNotes = (quoteId) => {
    this.setState((prevState) => ({
      activeNotes: {
        ...prevState.activeNotes,
        [quoteId]: !prevState.activeNotes[quoteId],
      },
    }));
  };

  toggleEmails = (quoteId) => {
    this.setState((prevState) => ({
      activeEmails: {
        ...prevState.activeEmails,
        [quoteId]: !prevState.activeEmails[quoteId],
      },
    }));
  };

  openEmailModal = (email) => {
    this.setState({ selectedEmail: email });
  };

  closeEmailModal = () => {
    this.setState({ selectedEmail: null });
  };

  openCompanyModal = (quoteId, companyId, companyName) => {
    this.setState({
      selectedCompany: { quoteId, companyId, companyName },
    });
  };

  closeCompanyModal = () => {
    this.setState({ selectedCompany: null });
  };

  render() {
    const { discontinuedQuotes, notes, emails, activeNotes, activeEmails, selectedEmail, selectedCompany } = this.state;

    return (
      <>
        <StyledTable>
          <thead>
            <tr>
              <th>ID</th>
              <th>Company</th>
              <th>Status</th>
              <th>Last Updated</th>
              <th>Rep</th>
              <th>Notes</th>
              <th>Emails</th>
              <th>Company Details</th>
            </tr>
          </thead>
          <tbody>
            {discontinuedQuotes.map((quote, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td>{quote.id}</td>
                  <td>{quote.sCompany}</td>
                  <td>{quote.sStatus}</td>
                  <td>{moment(quote.dtLastUpdated).utcOffset(0).format('MM/DD/YYYY hh:mm A')}</td>
                  <td>{repNames[quote.nRep] || `Rep ID: ${quote.nRep}`}</td>
                  <td>
                    <IconButton onClick={() => this.toggleNotes(quote.id)}>
                      <FontAwesomeIcon icon={faStickyNote} />
                    </IconButton>
                  </td>
                  <td>
                    <IconButton onClick={() => this.toggleEmails(quote.id)}>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </IconButton>
                  </td>
                  <td>
                    <IconButton onClick={() => this.openCompanyModal(quote.id, quote.nCompany, quote.sCompany)}>
                      <FontAwesomeIcon icon={faBuilding} />
                    </IconButton>
                  </td>
                </tr>

                {activeNotes[quote.id] && notes[quote.id] && (
                  <tr>
                    <td colSpan="8">
                      <div>
                        <h4>Notes</h4>
                        {notes[quote.id].slice().reverse().map((note, idx) => (
                          <p key={idx}>
                            {note.sNote} 
                            <small>
                              <em> {moment(note.dtTimeStamp).utcOffset(0).format('MM/DD/YYYY hh:mm A')} </em>
                              by <b>{repNames[note.nRep] || `Rep ID: ${note.nRep}`}</b>
                            </small>
                          </p>
                        ))}
                      </div>
                    </td>
                  </tr>
                )}

                {activeEmails[quote.id] && emails[quote.id] && (
                  <tr>
                    <td colSpan="8">
                      <div>
                        <h4>Emails</h4>
                        {emails[quote.id].slice().reverse().map((email, idx) => (
                          <div key={idx} onClick={() => this.openEmailModal(email)}>
                            <p><strong>{email.sSubject}</strong></p>
                            <p>From: {email.sFrom} | To: {email.sTo}</p>
                            <hr />
                          </div>
                        ))}
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </StyledTable>

        {selectedEmail && (
          <EmailDetailsModal
            email={selectedEmail}
            onClose={this.closeEmailModal}
          />
        )}

        {selectedCompany && (
          <CompanyDetailsModal
            quoteId={selectedCompany.quoteId} 
            companyId={selectedCompany.companyId}
            companyName={selectedCompany.companyName}
            onClose={this.closeCompanyModal}
          />
        )}
      </>
    );
  }
}

export default DiscontinuedQuotesModal;