import React, { Component } from 'react';
import styled from 'styled-components';
import PoLineItem from './PoLineItem';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  margin-left: 0.5em;
`;

export default class PoLines extends Component {
  render() {
    return (
      <Wrapper>
        <table style={{ width: '100%' }} className="dashboard">
          <thead>
            <tr>
              <th style={{ width: '5%' }}>PO</th>
              <th style={{ width: '5%' }}>Line</th>
              <th style={{ width: '5%' }}>Quantity</th>
              <th style={{ width: '65%' }}>Equipment Description</th>
              <th style={{ width: '10%' }}>
                Unit
                <br />
                Price
              </th>
              <th style={{ width: '10%' }}>Total</th>
            </tr>
          </thead>
          <tbody>
            {this.props.POs.length > 0 ? (
              <>
                {this.props.POs.map((sql) => (
                  <PoLineItem
                    id={sql.id}
                    sDisplayId={sql.sDisplayId}
                    nPOTotal={sql.nPOTotal}
                    dtQuoteDate={sql.dtQuoteDate}
                    sCompany={sql.sCompany}
                    sName={sql.sName}
                    sAddress={sql.sAddress}
                    sCity={sql.sCity}
                    sState={sql.sState}
                    sZip={sql.sZip}
                    sPhone={sql.sPhone}
                    sFax={sql.sFax}
                    sEmail={sql.sEmail}
                    sEstimate={sql.sEstimate}
                    sDCompany={sql.sDCompany}
                    sDName={sql.sDName}
                    sDAddress={sql.sDAddress}
                    sDCity={sql.sDCity}
                    sDState={sql.sDState}
                    sDZip={sql.sDZip}
                    sDPhone={sql.sDPhone}
                    sDFax={sql.sDFax}
                    sDEmail={sql.sDEmail}
                    sDNotes={sql.sDNotes}
                    sAltNamePhone={sql.sAltNamePhone}
                    nDelivery={sql.nDelivery}
                    nTaxRate={sql.nTaxRate}
                    poLetter={sql.sDisplayId.slice(-1)}
                  />
                ))}
              </>
            ) : (
              <tr>
                <td colSpan="6" style={{ textAlign: 'center' }}>
                  No PO's have been created
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Wrapper>
    );
  }
}
