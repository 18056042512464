import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Loader from 'react-loader-spinner';
import styled from 'styled-components';
import Moment from 'moment';
import {
  calcPreSalesTax,
  calcPreTaxMargin,
  calcActiveSalesTotal,
  calcActiveMarginTotal,
  round,
  calcTotalMargin,
  calcMilesFromLatLong,
  marginColorRac,
} from '../../../assets/functions/calculations';
import { whichRep } from '../../../assets/functions/calculations';
import MediaQuery from 'react-responsive';
import HeatMap from '../../misc/HeatMap';


const PageWrapper = styled.div`
  display: block;
  position: fixed;
  z-index: 50;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: #222;
  opacity: 0.5;
`;

const Modal = styled.div`
  display: block;
  position: fixed;
  z-index: 55;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 90%;
  overflow: hidden;
  background-color: #fff;
  opacity: 1;
  border-radius: 10px;

`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: left;
  background-color: #fefefe;
  margin: auto;
  padding: 0.5em;
  width: 90%;
  overflow: hidden;

  h2 {
    text-align: center;
  }

  p {
    text-align: left;
    font-size: 1rem;
    margin-top: 1em;
  }

  span {
    font-weight: bold;
  }

  input {
    width: 100%;
    max-width: 400px;
    padding: 0.7em;
    margin: 0.5em auto 0 auto;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  select {
    width: 100%;
    max-width: 400px;
    padding: 0.7em;
    margin: 0.5em auto 0 auto;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
`;
const Wrapper = styled.div`
  width: 99%;
  max-width: 1200px;
  margin: 5em auto;

  table {
    width: 100%;
    position: relative;
    ${(props) => {
      if (props.amount < 10)
        return `
            
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;

    thead + tbody tr:last-child td:last-child,
    tfoot tr:last-child td:last-child {
      border-bottom-right-radius: 15px !important;
    }
    thead + tbody tr:last-child td:first-child,
    tfoot tr:last-child td:first-child {
      border-bottom-left-radius: 15px !important;
    }
        `;
    }}
  }

  button {
    position: absolute;
    width: 100%;
    bottom: -30px;
     padding: 0.35em 1.2em;
     text-decoration: none;
     color: #fafafa;
     text-align: center;
     transition: all 0.2s;
    background-color: #4aabed;
    border: 1px solid #4aabed;
    cursor: pointer;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    &:hover {
      color: #222;
      background-color: #fafafa;
      border: 1px solid #d7d7d7;
    }
  }

  span {
    margin: 0 1em;
  }
`;

const SubTitle = styled.h2`
  display: flex;
  justify-content: center;
  text-align: center;
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  border-bottom: none !important;
  margin-bottom: 1em;

  i {
    font-size: 16px;
    margin-left: 7px;
  }
`;

export default class RacClosedOrders extends Component {
  state = {
    data: [],
    loaded: false,
    showAll: false,
  };

  // Async Axios
  fetchData = async (startDate, endDate) => {
    try {
      const response = await Axios.get('/rac-dashboard/closed-orders', {
        params: {
          startDate: startDate,
          endDate: endDate,
        },
        headers: { 'Content-Type': 'application/json' },
      });
      // Sets the state using the response data
      this.setState({
        data: response.data.result[0].recordset,
        zipcodes: response.data.result[1].recordset,
        loaded: true,
      }, () => this.setCoords());
    } catch (error) {
      console.error(error);
    }
  };

  setCoords = () => {
    let data = this.state.data
    let zipcodes = this.state.zipcodes
    let coord = {}

    for(let i=0; i<data.length; i++){
      data[i].type = "Delivery Distance"
      if(data[i].sDZip || data[i].sZip){
        if(data[i].sDZip){coord = this.getLatLng(data[i].sDZip)}else{coord = this.getLatLng(data[i].sZip)}
        
        data[i].lat = coord.lat
        data[i].lng = coord.lng
        
      }
      if(data[i].sSupplierZip){
        coord = this.getLatLng(data[i].sSupplierZip)
        data[i].supplierLat = coord.lat
        data[i].supplierLng = coord.lng
      }
      if(data[i].lat && data[i].lng && data[i].supplierLat && data[i].supplierLng){
        data[i].distance = calcMilesFromLatLong({latitude: data[i].lat, longitude: data[i].lng}, {latitude: data[i].supplierLat, longitude: data[i].supplierLng})
      } else { 
        data[i].distance = -1
      }
    }

    console.log(data)

    this.setState({data: data})
  }

  getLatLng = (getZip) => {
    let latitude = 0
    let longitude = 0

    console.log(parseInt(getZip))

    if(getZip){    
      if(getZip.length >= 5 && parseInt(getZip)>0 && parseInt(getZip)<=99999){
        
        for(let i=0; i< this.state.zipcodes.length; i++){
          if(parseInt(this.state.zipcodes[i].sZipCode) === parseInt(getZip)){
            latitude = this.state.zipcodes[i].sLatitude
            longitude = this.state.zipcodes[i].sLongitude
            i=this.state.zipcodes.length
          }
        }
      }
    }
    return {lat: latitude, lng: longitude}
  }

  componentDidMount() {
    this.fetchData(this.props.startDate, this.props.endDate);
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.fetchData(this.props.startDate, this.props.endDate);
    }
  }

  render() {
    let table;

    // If the state showAll is false then table with return with only 10 rows, else show all 100
    if (this.state.showAll === false) {
      table = (
        <>
          {this.state.data.slice(0, 10).map((sql) => (
            <tr
              style={{
                backgroundColor: marginColorRac(
                  ((calcPreSalesTax(
                    sql.nQuoteTotal,
                    sql.nTaxRate,
                    sql.bTaxDel,
                    sql.nDelivery
                  ) -
                    sql.nPoTotals) /
                    calcPreSalesTax(
                      sql.nQuoteTotal,
                      sql.nTaxRate,
                      sql.bTaxDel,
                      sql.nDelivery
                    )) *
                    100
                ),
              }}
              key={sql.id}
            >
              <MediaQuery minDeviceWidth={900}>
                <td>
                  <Link target="_blank" to={`/quote/${sql.id}`}>
                    {sql.id}
                  </Link>
                </td>
              </MediaQuery>
              <td>
                {' '}
                <div>
                  {sql.sCompany}
                </div>
              </td>
              <td>{Moment(sql.dtFirstClosed).utc().format('MM/DD/YYYY')}</td>
              <td style={{ textAlign: 'center' }}>
                {'$' +
                  calcPreSalesTax(
                    sql.nQuoteTotal,
                    sql.nTaxRate,
                    sql.bTaxDel,
                    sql.nDelivery
                  ).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
              </td>
              <MediaQuery minDeviceWidth={900}>
                <td style={{ textAlign: 'center' }}>
                  {'$' +
                    calcPreTaxMargin(
                      sql.nQuoteTotal,
                      sql.nTaxRate,
                      sql.nPoTotals,
                      sql.bTaxDel,
                      sql.nDelivery
                    ).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                </td>
                <td style={{ textAlign: 'center' }}>
                  {(
                    ((calcPreSalesTax(
                      sql.nQuoteTotal,
                      sql.nTaxRate,
                      sql.bTaxDel,
                      sql.nDelivery
                    ) -
                      sql.nPoTotals) /
                      calcPreSalesTax(
                        sql.nQuoteTotal,
                        sql.nTaxRate,
                        sql.bTaxDel,
                        sql.nDelivery
                      )) *
                    100
                  ).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }) + '%'}
                </td>
              </MediaQuery>
              <td>{whichRep(sql.nRep)}</td>
              <td>{Math.round(sql.nDelivery)}</td>
              <td>{Math.round(sql.distance)}</td>
            </tr>
          ))}
        </>
      );
    } else {
      table = (
        <>
          {this.state.data.map((sql) => (
            <tr
              style={{
                backgroundColor: marginColorRac(
                  ((calcPreSalesTax(
                    sql.nQuoteTotal,
                    sql.nTaxRate,
                    sql.bTaxDel,
                    sql.nDelivery
                  ) -
                    sql.nPoTotals) /
                    calcPreSalesTax(
                      sql.nQuoteTotal,
                      sql.nTaxRate,
                      sql.bTaxDel,
                      sql.nDelivery
                    )) *
                    100
                ),
              }}
              key={sql.id}
            >
              <MediaQuery minDeviceWidth={900}>
                <td>
                  <Link target="_blank" to={`/quote/${sql.id}`}>
                    {sql.id}
                  </Link>
                </td>
              </MediaQuery>
              <td>
                {' '}
                <Link
                  target="_blank"
                  to={`/crm/master-viewer/${sql.sCompany.replace(
                    /\//g,
                    '%2F'
                  )}`}
                >
                  {sql.sCompany}
                </Link>
              </td>
              <td>{Moment(sql.dtFirstClosed).utc().format('MM/DD/YYYY')}</td>
              <td style={{ textAlign: 'center' }}>
                {'$' +
                  calcPreSalesTax(
                    sql.nQuoteTotal,
                    sql.nTaxRate,
                    sql.bTaxDel,
                    sql.nDelivery
                  ).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
              </td>
              <MediaQuery minDeviceWidth={900}>
                <td style={{ textAlign: 'center' }}>
                  {'$' +
                    calcPreTaxMargin(
                      sql.nQuoteTotal,
                      sql.nTaxRate,
                      sql.nPoTotals,
                      sql.bTaxDel,
                      sql.nDelivery
                    ).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                </td>
                <td style={{ textAlign: 'center' }}>
                  {(
                    ((calcPreSalesTax(
                      sql.nQuoteTotal,
                      sql.nTaxRate,
                      sql.bTaxDel,
                      sql.nDelivery
                    ) -
                      sql.nPoTotals) /
                      calcPreSalesTax(
                        sql.nQuoteTotal,
                        sql.nTaxRate,
                        sql.bTaxDel,
                        sql.nDelivery
                      )) *
                    100
                  ).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }) + '%'}
                </td>
              </MediaQuery>
              <td>{whichRep(sql.nRep)}</td>
              <td>{Math.round(sql.nDelivery)}</td>
              <td>{Math.round(sql.distance)}</td>
            </tr>
          ))}
        </>
      );
    }

    return (
      <Wrapper amount={this.state.data.length}>
        <SubTitle>
          RAC Closed Orders <i className="far fa-clock"></i>
        </SubTitle>
        <div className="legend">
          <div className="legend-scale">
            <ul className="legend-labels">
              <li>
                <span style={{ background: "#ff6666" }}></span>
                0%- 23%
              </li>
              <li>
                <span style={{ background: "#fafafa" }}></span>
                24% - 37.4%
              </li>
              <li>
                <span style={{ background: "#85bb65" }}></span>
                37.5% - 55%
              </li>
              <li>
                <span style={{ background: "#fffd6e" }}></span>
                56% - 100%
              </li>
            </ul>
          </div>
        </div>
        <h4>
          <span>
            Sales Total: $
            {calcActiveSalesTotal(this.state.data).toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
          <span>
            Gross Profit Total: $
            {calcActiveMarginTotal(this.state.data).toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
          {isNaN(round(calcTotalMargin(this.state.data))) ? (
            <span>Gross Margin: 0%</span>
          ) : (
            <span>
              Gross Margin: {round(calcTotalMargin(this.state.data))}%
            </span>
          )}
          <span><div onClick={() => this.setState({showHeatMap: !this.state.showHeatMap})}>Show Heat Map</div></span>
        </h4>
        
        {this.state.showHeatMap && (
          <>
          <PageWrapper></PageWrapper>
          <Modal>
            <ModalContent>
              <div onClick={() => this.setState({showHeatMap: !this.state.showHeatMap})}>Hide Heat Map</div>
            </ModalContent>
            <ModalContent>
            <HeatMap 
              points={this.state.data}
            />
            </ModalContent>
          </Modal>
          
          </>
        )}
        <table style={{ position: "relative" }} className="dashboard">
          <thead>
            <tr>
              <MediaQuery minDeviceWidth={900}>
                <th>ID</th>
              </MediaQuery>
              <th>Company</th>
              <th>Date</th>
              <th>Sales</th>
              <MediaQuery minDeviceWidth={900}>
                <th>Gross Profit</th>
                <th>Gross Margin</th>
              </MediaQuery>
              <th>Rep</th>
              <th>Delivery</th>
              <th>Distance</th>
            </tr>
          </thead>
          <tbody>
            {this.state.loaded || (
              <tr>
                <td colSpan="7">
                  <Loader
                    style={{
                      textAlign: "center",
                    }}
                    type="ThreeDots"
                    color="#00BFFF"
                    height={20}
                    width={120}
                  />
                </td>
              </tr>
            )}
            {this.state.loaded && (
              <>
                {this.state.data.length > 0 ? (
                  <>{table}</>
                ) : (
                  <tr>
                    <td
                      colSpan="7"
                      style={{ textAlign: "center", padding: "1em" }}
                    >
                      No quotes
                    </td>
                  </tr>
                )}
                {this.state.data.length > 10 ? (
                  <>
                    {this.state.showAll ? (
                      <button
                        onClick={() => {
                          this.setState({
                            showAll: false,
                          });
                        }}
                      >
                        Hide
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          this.setState({
                            showAll: true,
                          });
                        }}
                      >
                        Show All
                      </button>
                    )}
                  </>
                ) : null}
              </>
            )}
          </tbody>
        </table>
      </Wrapper>
    );
  }
}
