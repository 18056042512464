import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { cGreen, cRed, nQuoteTotalNoDollar, whichRep } from '../../../../assets/functions/calculations';
import moment from 'moment';
import { CustomDateWrapper, Input, SubmitText, GreyTextDiv } from '../../../styled/ui';
import { daysLate, percentTwoDecimal } from '../../../../assets/functions/display';



export default class YearSummaryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <table className="dashboard">
          <thead>
            <tr>
              <th>Type</th>
              <th>Value</th>
              <th>Last 12 Months</th>
              <th>Previous Year</th>
              <th>Value</th>
              <th>Last 12 Months</th>
              <th>Previous Year</th>
            </tr>
          </thead>
          <tbody>
            <br />
            <tr>
              <td rowspan={7}>All</td>
              <td>Completed Quotes</td>
              <td>{this.props.month.total[0].completed.count.all}</td>
              <td>{this.props.month.total[1].completed.count.all}</td>
              <td>Closed Quotes</td>
              <td>{this.props.month.total[0].closed.count.all}</td>
              <td>{this.props.month.total[1].closed.count.all}</td>
            </tr>
            <tr>
              <td>Completed Revenue</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[0].completed.sales.all)}</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[1].completed.sales.all)}</td>
              <td>Closed Sales</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[0].closed.sales.all)}</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[1].closed.sales.all)}</td>
            </tr>
            <tr>
              <td>Target Revenue</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[0].target.sales)}</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[1].target.sales)}</td>
              <td>Target Sales</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[0].target.sales)}</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[1].target.sales)}</td>
            </tr>
            <tr>
              <td>Completed Margin</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[0].completed.margin.all)}</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[1].completed.margin.all)}</td>
              <td>Closed Margin</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[0].closed.margin.all)}</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[1].closed.margin.all)}</td>
            </tr>
            <tr>
              <td>Completed CSN Labor</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[0].completed.labor)}</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[1].completed.labor)}</td>
              <td>Closed CSN Labor</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[0].closed.labor)}</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[1].closed.labor)}</td>
            </tr>
            <tr>
              <td>Completed Margin + Labor</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[0].completed.labor+this.props.month.total[0].completed.margin.all)}</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[1].completed.labor+this.props.month.total[1].completed.margin.all)}</td>
              <td>Closed Margin + Labor</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[0].closed.labor+this.props.month.total[0].closed.margin.all)}</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[1].closed.labor+this.props.month.total[1].closed.margin.all)}</td>
            </tr>
            <tr>
              <td>Target Margin + Labor</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[0].target.margin)}</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[1].target.margin)}</td>
              <td>Target Margin + Labor</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[0].target.margin)}</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[1].target.margin)}</td>
            </tr>
            <br />
            <tr>
              <td rowspan={5}>CSN</td>
              <td>Completed Quotes</td>
              <td>{this.props.month.total[0].completed.count.csn}</td>
              <td>{this.props.month.total[1].completed.count.csn}</td>
              <td>Closed Quotes</td>
              <td>{this.props.month.total[0].closed.count.csn}</td>
              <td>{this.props.month.total[1].closed.count.csn}</td>
            </tr>
            <tr>
              <td>Completed Revenue</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[0].completed.sales.csn)}</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[1].completed.sales.csn)}</td>
              <td>Closed Sales</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[0].closed.sales.csn)}</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[1].closed.sales.csn)}</td>
            </tr>
            <tr>
              <td>Completed Margin</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[0].completed.margin.csn)}</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[1].completed.margin.csn)}</td>
              <td>Closed Margin</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[0].closed.margin.csn)}</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[1].closed.margin.csn)}</td>
            </tr>
            <tr>
              <td>Completed CSN Labor</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[0].completed.labor)}</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[1].completed.labor)}</td>
              <td>Closed CSN Labor</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[0].closed.labor)}</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[1].closed.labor)}</td>
            </tr>
            <tr>
              <td>Completed Margin + Labor</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[0].completed.labor+this.props.month.total[0].completed.margin.csn)}</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[1].completed.labor+this.props.month.total[1].completed.margin.csn)}</td>
              <td>Closed Margin + Labor</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[0].closed.labor+this.props.month.total[0].closed.margin.csn)}</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[1].closed.labor+this.props.month.total[1].closed.margin.csn)}</td>
            </tr>
            <br />
            <tr>
              <td rowspan={3}>Contract</td>
              <td>Completed Quotes</td>
              <td>{this.props.month.total[0].completed.count.contract}</td>
              <td>{this.props.month.total[1].completed.count.contract}</td>
              <td>Closed Quotes</td>
              <td>{this.props.month.total[0].closed.count.contract}</td>
              <td>{this.props.month.total[1].closed.count.contract}</td>
            </tr>
            <tr>
              <td>Completed Revenue</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[0].completed.sales.contract)}</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[1].completed.sales.contract)}</td>
              <td>Closed Sales</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[0].closed.sales.contract)}</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[1].closed.sales.contract)}</td>
            </tr>
            <tr>
              <td>Completed Margin</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[0].completed.margin.contract)}</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[1].completed.margin.contract)}</td>
              <td>Closed Margin</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[0].closed.margin.contract)}</td>
              <td>{nQuoteTotalNoDollar(this.props.month.total[1].closed.margin.contract)}</td>
            </tr>
          </tbody>
        </table>
    );
  }
}
