import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Axios from 'axios';
import Loader from 'react-loader-spinner';
import MediaQuery from 'react-responsive';
import { whichRep } from '../../assets/functions/calculations';

const Wrapper = styled.section`
  padding: 1em 0em;
  text-align: center;

  @media (max-width: 900px) {
    padding-bottom: 5em;
  }
`;

const LoaderWrapper = styled.div`
  margin-top: 25vh;
`;

const Title = styled.h1`
  padding-top: 1em;
  font-size: 2rem;
`;

const SubText = styled.p`
  padding-top: 1em;
  font-size: 1rem;
`;

// Returns the needed string based off the parentQuote
function parentQuoteFormat(parentQuote) {
  if (parentQuote === null || '') {
    return 'QUOTE';
  } else {
    return 'REBILL';
  }
}

// Returns the needed string based off the dtPaid, and sStatus
function dtPaidQuoteFormat(dtPaid, sStatus) {
  if (sStatus === 'Closed') {
    if (dtPaid === null || '') {
      return 'Unpaid';
    } else {
      return 'Paid';
    }
  } else {
    return '';
  }
}

// Returns an abbreviation of the company names
function abbreviate(sQuoteType) {
  if (sQuoteType === 'Rentacomputer.com') {
    return 'RAC';
  } else if (sQuoteType === 'CameraSecurityNow.com') {
    return 'CAM';
  } else if (sQuoteType === 'ComputerServiceNow.com') {
    return 'CSN';
  } else if (sQuoteType === 'Xponex.com') {
    return 'XPO';
  }
}

// If nQuoteTotal is null, returns a zero
function amountOrNull(nQuoteTotal) {
  if (nQuoteTotal != null) {
    return nQuoteTotal;
  } else {
    return '0';
  }
}

class Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      loaded: false,
      search: '',
    };
  }

  // Async Axios
  fetchUser = async () => {
    try {
      const passport = await Axios.get('/userSession', {
        credentials: 'include',
      });
      if (!passport.data.passport) {
        window.location = '/';
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Async Axios
  fetchData = async (search) => {
    try {
      this.setState({
        loaded: false,
      });
      const response = await Axios.get('/search', {
        params: {
          search: search,
          amount: '200',
        },
        headers: { 'Content-Type': 'application/json' },
      });
      this.setState({
        results: response.data.result[0].recordset,
        loaded: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    this.fetchUser();
    this.fetchData(this.props.history.location.pathname.slice(9));
    // Listens for changes to the props.history, if it does change it will do run fetchData() with the new props.history
    this.unlisten = this.props.history.listen((location, action) => {
      this.fetchData(this.props.history.location.pathname.slice(9));
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    return (
      <Wrapper>
        {this.state.loaded || (
          <LoaderWrapper>
            <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} />
          </LoaderWrapper>
        )}
        {this.state.loaded && (
          <>
            <Title>
              Search results for "
              {this.props.history.location.pathname.slice(9)}"
            </Title>
            <SubText>{this.state.results.length} Results</SubText>
            <table className="dashboard">
              <thead>
                <tr>
                  <MediaQuery minDeviceWidth={900}>
                    <th>Type</th>
                  </MediaQuery>
                  <th>ID</th>
                  <MediaQuery minDeviceWidth={900}>
                    <th>Date</th>
                    <th>Type</th>
                    <th>TTA</th>
                  </MediaQuery>
                  <th>Company</th>
                  <MediaQuery minDeviceWidth={900}>
                    <th>Quote Title</th>
                  </MediaQuery>
                  <th>Contact</th>
                  <MediaQuery minDeviceWidth={900}>
                    <th>Amount</th>
                    <th>1st Vendor</th>
                    <th>Status</th>
                    <th>Payment</th>
                  </MediaQuery>
                </tr>
              </thead>
              {this.state.loaded && (
                <tbody>
                  {this.state.results.map((sql) => (
                    <tr
                      style={{
                        fontWeight: sql.sStatus === 'Closed' ? '600' : '',
                        color: sql.sStatus === 'Discontinued' ? '#555' : '',
                        fontSize: sql.sStatus === 'Discontinued' ? '17' : '',
                        fontStyle:
                          sql.sStatus === 'Discontinued' ? 'italic' : '',
                      }}
                      key={sql.id}
                    >
                      <MediaQuery minDeviceWidth={900}>
                        <td>{parentQuoteFormat(sql.nParentQuote)}</td>
                      </MediaQuery>
                      <td>
                        <Link target="_blank" to={`/quote/${sql.id}`}>
                          {sql.id}
                        </Link>{' '}
                        -
                        <Link target="_blank" to={`/quote/${sql.nParentQuote}`}>
                          {sql.nParentQuote}
                        </Link>
                      </td>
                      <MediaQuery minDeviceWidth={900}>
                        <td>
                          {sql.dtQuoteDate
                            .replace('T', ' - ')
                            .replace('Z', '')
                            .slice(0, -4)}
                        </td>
                        <td>{abbreviate(sql.sQuoteType)}</td>
                        <td>{whichRep(sql.nRep)}</td>
                      </MediaQuery>
                      <td>{sql.sCompany.slice(0, 20)}</td>
                      <MediaQuery minDeviceWidth={900}>
                        <td>{sql.sQuoteTitle}</td>
                      </MediaQuery>
                      <td>{sql.sName}</td>
                      <MediaQuery minDeviceWidth={900}>
                        <td>{'$' + amountOrNull(sql.nQuoteTotal)}</td>
                        <td>{sql.sVendor}</td>
                        <td>{sql.sStatus}</td>
                        <td>{dtPaidQuoteFormat(sql.dtPaid, sql.sStatus)}</td>
                      </MediaQuery>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </>
        )}
      </Wrapper>
    );
  }
}

export default Results;
