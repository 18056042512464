import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { nQuoteTotalNoDollar, whichRep } from '../../../../assets/functions/calculations';
import moment from 'moment';
import { CustomDateWrapper, Input, SubmitText, GreyTextDiv, Title } from '../../../styled/ui';



export default class InTransit extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    
    return (
      <>
      <Title>In Transit</Title>
      <div>Orders will be removed from this list when all Outbound tracking has been marked as Delivered.</div>
      <table class="dashboard">
          <thead>
            <tr>
              <th>Quote</th>
              <th>Company</th>
              <th>Contact</th>
              <th>Title</th>
              <th>Price</th>
              <th>Labor</th>
              <th>First Closed</th>
              <th>Rep</th>
              <th style={{width: '300px'}}>Quote Tag</th>
              <th>Hardware Setup</th>
              <th>Ship Date</th>
              <th>Target Start</th>
              <th>Target Complete</th>
            </tr>
          </thead>
          <tbody>
                {this.props.data.length > 0 && (
                    <>
                      {this.props.data.map((sql, index) => (
                        <>
                        <tr key={sql.id}>
                          <td><Link target="_blank" to={`/quote/${sql.id}`}>{sql.id}</Link></td>
                          <td>
                            <div>{sql.sDCompany}</div>
                            <div>{sql.sDCity}, {sql.sDState} {sql.sDZip}</div>
                          </td>
                          <td>
                            <div>{sql.sDName}</div>
                            <div>{sql.sDPhone}</div>
                          </td>
                          <td>{sql.sQuoteTitle}</td>
                          <td>{nQuoteTotalNoDollar(sql.nQuoteTotal)}</td>
                          <td onClick={()=>this.props.handleShowPO(sql.index)}>{nQuoteTotalNoDollar(sql.nLaborTotals)}</td>
                          <td>{moment(sql.dtFirstClosed).utc().format('MM/DD/YYYY')}</td>
                          <td>{sql.nRep ? whichRep(sql.nRep) : null}</td>                    
                          <td>
                            <textarea style={{height: '80px', width: '100%'}} onInput={(event) => this.props.handleUpdateTag(event.target.value, index)} value={sql.sQuoteTags}></textarea>
                            {sql.changed && (<div onClick={() => this.props.handleSaveTag(sql.sQuoteTags, sql.id)}>Save Tag</div>)}
                          </td>
                          
                          <td>
                            <CustomDateWrapper><Input onChange={(e) => {this.props.handleDateChange(e, sql.index, 'dtHardwareSetup')}} type="date" id="date" name="date" 
                              value={moment(sql.dtHardwareSetup).utc().format('YYYY-MM-DD')} />
                              </CustomDateWrapper>
                            <SubmitText onClick={() => this.props.handleDateSubmit(sql.dtHardwareSetup,sql.id,"dtHardwareSetup")}>Submit</SubmitText>
                            <br />
                            <input type="checkbox" checked={sql.bLaborOnly} onClick={()=>this.props.setLaborOnly(sql)} /> Labor Only
                          </td>
                          <td>
                            <CustomDateWrapper><Input onChange={(e) => {this.props.handleDateChange(e, sql.index, 'dtShipDate')}} type="date" id="date" name="date" 
                              value={moment(sql.dtShipDate).utc().format('YYYY-MM-DD')} /></CustomDateWrapper>
                            <SubmitText onClick={() => this.props.handleDateSubmit(sql.dtShipDate,sql.id,"dtShipDate")}>Submit</SubmitText>
                          </td>
                          <td>
                            <CustomDateWrapper><Input onChange={(e) => {this.props.handleDateChange(e, sql.index, 'dtTargetStart')}} type="date" id="date" name="date" 
                              value={moment(sql.dtTargetStart).utc().format('YYYY-MM-DD')} /></CustomDateWrapper>
                            <SubmitText onClick={() => this.props.handleDateSubmit(sql.dtTargetStart,sql.id,"dtTargetStart")}>Submit</SubmitText>
                          </td>
                          <td>
                            <CustomDateWrapper><Input onChange={(e) => {this.props.handleDateChange(e, sql.index, 'dtTargetComplete')}} type="date" id="target" name="target"
                              value={moment(sql.dtTargetComplete).utc().format('YYYY-MM-DD')} />
                            </CustomDateWrapper>
                            <SubmitText onClick={() => this.props.handleDateSubmit(sql.dtTargetComplete,sql.id,"dtTargetComplete")}>Submit</SubmitText>
                          </td>
                        </tr>
                        {sql.showPOs && (
                          <tr>
                            <td colSpan={12}>
                            <table>
                              <thead>
                                <tr>
                                  <th>Vendor</th>
                                  <th>Description</th>
                                  <th>Quantity</th>
                                  <th>Price</th>
                                  <th>Total Price</th>
                                  <th>Item</th>
                                </tr>
                              </thead>
                              {sql.POLines.map((line, lineNumber) => (
                                <tr key={lineNumber}>
                                  <td>{line.sCompany}</td>
                                  <td>{line.sDescription}</td>
                                  <td>{line.nQuantity}</td>
                                  <td>{nQuoteTotalNoDollar(line.nPrice)}</td>
                                  <td>{nQuoteTotalNoDollar(line.nQuantity*line.nPrice)}</td>
                                  <td onClick={()=>this.props.handleSetLabor(sql.index, lineNumber)}>{line.nItem === 832 && (
                                    <>CSN Labor</>
                                  ) || (
                                    <GreyTextDiv>Set as CSN Labor</GreyTextDiv>
                                  )}</td>
                                </tr>
                              ))}
                            </table>
                            </td>
                          </tr>
                        )}
                        </>
                      ))}
                    </>
                )}

          </tbody>
        </table>
        </>
    );
  }
}