module.exports = {
    gpt_queries: {
        template: {
            query: "",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        getEmails: {
            query: "SELECT TOP 50 id, nQuote, sSubject, sBody, sFrom, sTo, sCC, sBCC, dtDate FROM RAC_QUOTE_EMAILS WHERE dtGPT IS NULL AND dtDate >= DATEADD(hour,-2,GETDATE()) AND nQuote IN (SELECT id FROM RAC_CUSTOMER_QUOTES WHERE dtLastUpdated >= DATEADD(year,-5,GETDATE()))",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        saveGPT: {
            query: "UPDATE RAC_QUOTE_EMAILS SET dtGPT = GETDATE(), sGPTResponse='[sGPTResponse]' WHERE id=[id]",
            req: ["sGPTResponse", "id"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
    }
} 