import React, { Component } from 'react'
import { CustomDateContainer, DashboardControlsDiv, Time, Title } from '../../styled/ui';
import Moment from 'moment';
import { dayChangeEnd, dayChangeStart } from '../../../assets/functions/time';

export default class DashboardControls extends Component {
  render() {
    return (
      <DashboardControlsDiv>
        <div>
            <Title>RAC Dashboard</Title>
            <Time>
              <i
                onClick={this.props.handleQuickTimeChange('subtract')}
                class="fas fa-chevron-circle-left"
              ></i>
              {Moment(this.props.startDate).format('dddd, MMMM Do YYYY') +
                ' - ' +
                Moment(this.props.endDate).format('dddd, MMMM Do YYYY')}
              <i
                onClick={this.props.handleQuickTimeChange('add')}
                class="fas fa-chevron-circle-right"
              ></i>
            </Time>
          </div>
          <div>
            <button
              onClick={() => {
                this.props.handleSetState({
                  startDate: dayChangeStart(0),
                  endDate: dayChangeEnd(0),
                  timeFrame: 'Today',
                  showManualDate: false,
                });
              }}
            >
              Today
            </button>
            <button
              onClick={() => {
                this.props.handleSetState({
                  startDate: dayChangeStart(1),
                  endDate: dayChangeEnd(1),
                  timeFrame: 'Yesterday',
                  showManualDate: false,
                });
              }}
            >
              Yesterday
            </button>
            <button
              onClick={() => {
                this.props.handleSetState({
                  startDate: dayChangeStart(7),
                  endDate: dayChangeEnd(0),
                  timeFrame: 'Last 7 Days',
                  showManualDate: false,
                });
              }}
            >
              Last 7 Days
            </button>
            <select
              id="priority"
              name="priority"
              defaultValue={'Select other'}
              onChange={(e) => {
                this.props.handleChange(e);
              }}
            >
              <option value="Select other" disabled hidden>
                Select additional time
              </option>
              <option value="Last Week">Last Week</option>
              <option value="This Week">This Week</option>
              <option value="This Month">This Month</option>
              <option value="This Month-to-Date">This Month-to-Date</option>
              <option value="This Year">This Year</option>
              <option value="This Year-to-Date">This Year-to-Date</option>
              <option value="The Last 365">The Last 365</option>
              <option value="The Last 30">The Last 30</option>
              <option value="Custom Date">Custom Date</option>
            </select>
          </div>
          
          {this.props.showManualDate ? (
            <>
              <CustomDateContainer>
                <div>
                  <label for="startDate">Start Date</label>
                  <input
                    type="date"
                    id="startDate"
                    name="startDate"
                    onChange={this.props.handleStartDateChange}
                  />
                </div>
                <div>
                  <label for="endDate">End Date</label>
                  <input
                    type="date"
                    id="endDate"
                    name="endDate"
                    onChange={this.props.handleEndDateChange}
                  />
                </div>
              </CustomDateContainer>
              <div>
                <button
                  onClick={() => {
                    this.props.handleSetState({
                      startDate: Moment(this.props.customDateStart).format(
                        'YYYY-MM-DD 00:00'
                      ),
                      endDate: Moment(this.props.customDateEnd).format(
                        'YYYY-MM-DD 23:59'
                      ),
                    });
                  }}
                >
                  Submit
                </button>
              </div>
            </>
          ) : null}

      </DashboardControlsDiv>
    )
  }
}
