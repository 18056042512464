import { getTimeSeconds } from "./time";

export function onTimeLeadAmount(state) {
    let white = 0;
    for (let i = 0; i < state.length; i++) {
      let time = getTimeSeconds(state[i].dtDateStamp);
      if (time < 300) {
        white += 1;
      }
    }
    return white;
}

export function lateLeadAmount(state) {
    let red = 0;
    for (let i = 0; i < state.length; i++) {
        let time = getTimeSeconds(state[i].dtDateStamp);
        if (time > 300) {
        red += 1;
        }
    }
    return red;
}

export function displayLeadsCount(leads){
    let red = lateLeadAmount(leads)
    let white = onTimeLeadAmount(leads)

    let redHtml = (<span style={{ color: '#ff6666' }}>{red}</span>)
    let dashHtml = (<span>{' - '}</span>)
    let whiteHtml = (<span>{white}</span>)

    let html = []

    if(red > 0){
        html.push(redHtml)
    }
    if(red > 0 && white > 0){
        html.push(dashHtml)
    }
    if(white > 0){
        html.push(whiteHtml)
    }
    
    return (<>{html}</>)
}

