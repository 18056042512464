import React, { Component } from 'react';
import { Circle, GoogleApiWrapper, InfoWindow, Map, Marker } from 'google-maps-react'
import ReactTooltip from 'react-tooltip';
import { nQuoteTotalFormatter } from '../../assets/functions/calculations';
import { Link } from 'react-router-dom';
const mapStyles = {
  width: 650,
  height: 400,
}

const cRed = '#b00'
const cWhite = '#fff'
const cBlack = '#000'
const cGreen = '#0b0'
const cYellow = '#fff2cc'
const cGold = '#d4af37'



export class MapContainer extends Component {
    state={
        selected: {}
    }

    select = (getPoint) => {
        console.log(getPoint)
        this.setState({selected: getPoint})
    }

    render() {
      return (
          <div style={{textAlign: 'center'}}>
        <Map
          google={this.props.google}
          zoom={4}
          style={mapStyles}
          containerStyle = {{position:'relative'}}
          initialCenter={
            {
              lat: 39.1,
              lng: -96,
            }
          }
        >
            {this.props.points.map((point, index) => (
                <Circle 
                    center={{lat: point.lat ? point.lat : 0, lng: point.lng ? point.lng : 0}}
                    radius={point.nServiceArea ? point.nServiceArea*5280/3.28 : 0.001}
                    strokeColor='transparent'
                    strokeOpacity={0}
                    strokeWeight={5}
                    fillColor='#FF0000'
                    fillOpacity={0.2}
                />  
            ))}
        </Map>
        </div>
      );
    }
  }
  
  export default GoogleApiWrapper({
    apiKey: 'AIzaSyAx08jjHEWZ8jQ62QJVEPbQnoMjlP5LUSs'
  })(MapContainer);