import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Loader from 'react-loader-spinner';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import Moment from 'moment';

const Wrapper = styled.div`
  width: 99%;
  max-width: 1300px;
  margin: 4em auto;

  table {
    width: 100%;
    position: relative;
    ${(props) => {
      if (props.amount > 10)
        return `
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;

    thead + tbody tr:last-child td:last-child,
    tfoot tr:last-child td:last-child {
      border-bottom-right-radius: 0px !important;
    }
    thead + tbody tr:last-child td:first-child,
    tfoot tr:last-child td:first-child {
      border-bottom-left-radius: 0px !important;
    }
    margin-bottom: 7em;
        `;
    }}
  }

  button {
    position: absolute;
    width: 100%;
    bottom: -27px;
     padding: 0.35em 1.2em;
     text-decoration: none;
     color: #fafafa;
     text-align: center;
     transition: all 0.2s;
    background-color: #4aabed;
    border: 1px solid #4aabed;
    cursor: pointer;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    &:hover {
      color: #222;
      background-color: #fafafa;
      border: 1px solid #d7d7d7;
    }
  }
`;

const SubTitle = styled.h2`
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1em;
`;

function isUpdated(state) {
  if (state === false) {
    return <i style={{ color: '#222' }} className="fas fa-bell"></i>;
  } else {
    return <i style={{ color: '#d3d3d3' }} className="far fa-bell"></i>;
  }
}

function boldUpdate(state) {
  if (state === false) {
    return '700';
  }
}

function sStatusColor(state) {
  if (state === 'Closed') {
    return '#6ba449';
  } else if (state === 'Discontinued') {
    return '#a5a5a5';
  } else {
    return '#222222';
  }
}

function quoteTotalOrNull(nQuoteTotal) {
  if (nQuoteTotal != null) {
    return nQuoteTotal.toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  } else {
    return '0.00';
  }
}

export default class RecentActivity extends Component {
  state = {
    data: [],
    user: this.props.nRep,
    loaded: false,
    showAll: false,
  };

  // Async Axios
  fetchData = async () => {
    try {
      const response = await Axios.get('/recent-activity', {
        params: {
          nRep: this.state.user,
        },
        headers: { 'Content-Type': 'application/json' },
      });
      //console.log(response.data);
      // Sets the state using the response data
      this.setState({
        data: response.data.result[0].recordset,
        loaded: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    this.fetchData();
    this.interval = setInterval(() => this.fetchData(), 10000);
  }

  componentWillUnmount() {
    // Stop fetching data when the user leaves the page and component unmounts
    clearInterval(this.interval);
  }

  render() {
    let table;

    // If the state showAll is false then table with return with only 10 rows, else show all 100
    if (this.state.showAll === false) {
      table = (
        <>
          {this.state.data.slice(0, 10).map((sql) => (
            <tr
              style={{
                color: sStatusColor(sql.sStatus),
                fontWeight: boldUpdate(sql.bReviewed),
              }}
              key={sql.id}
            >
              <MediaQuery minDeviceWidth={1100}>
                <td style={{ textAlign: 'center' }}>
                  {isUpdated(sql.bReviewed)}
                </td>
              </MediaQuery>

              <td
                style={{
                  color: sStatusColor(sql.sStatus),
                  fontWeight: boldUpdate(sql.bReviewed),
                  textAlign: 'center',
                }}
              >
                <Link target="_blank" to={`/quote/${sql.id}`}>
                  {sql.id}
                </Link>
              </td>
              <td>
                <Link
                  target="_blank"
                  to={`/crm/master-viewer/${sql.sCompany.replace(
                    /\//g,
                    '%2F'
                  )}`}
                >
                  {sql.sCompany}
                </Link>
              </td>
              <td>{sql.sName}</td>
              <MediaQuery minDeviceWidth={1100}>
                <td>
                  {sql.sCity} {sql.sState}
                </td>
              </MediaQuery>
              <MediaQuery minDeviceWidth={1100}>
                <td>{sql.sQuoteTitle}</td>
              </MediaQuery>
              <MediaQuery minDeviceWidth={1100}>
                <td>
                  {sql.sQuoteType}
                </td>
              </MediaQuery>
              <MediaQuery minDeviceWidth={1100}>
                <td style={{ textAlign: 'center' }}>
                  {'$' + quoteTotalOrNull(sql.nQuoteTotal)}
                </td>
              </MediaQuery>
              <td>{Moment(sql.dtLastActivity).utc().format('MM/DD/YYYY')}</td>
            </tr>
          ))}
        </>
      );
    } else {
      table = (
        <>
          {this.state.data.map((sql) => (
            <tr
              style={{
                color: sStatusColor(sql.sStatus),
                fontWeight: boldUpdate(sql.bReviewed),
              }}
              key={sql.id}
            >
              <MediaQuery minDeviceWidth={1100}>
                <td style={{ textAlign: 'center' }}>
                  {isUpdated(sql.bReviewed)}
                </td>
              </MediaQuery>

              <td
                style={{
                  color: sStatusColor(sql.sStatus),
                  fontWeight: boldUpdate(sql.bReviewed),
                  textAlign: 'center',
                }}
              >
                <Link target="_blank" to={`/quote/${sql.id}`}>
                  {sql.id}
                </Link>
              </td>
              <td>
                <Link
                  target="_blank"
                  to={`/crm/master-viewer/${sql.sCompany.replace(
                    /\//g,
                    '%2F'
                  )}`}
                >
                  {sql.sCompany}
                </Link>
              </td>
              <td>{sql.sName}</td>
              <MediaQuery minDeviceWidth={1100}>
                <td>
                  {sql.sCity} {sql.sState}
                </td>
              </MediaQuery>
              <MediaQuery minDeviceWidth={1100}>
                <td>{sql.sQuoteTitle}</td>
              </MediaQuery>
              <MediaQuery minDeviceWidth={1100}>
                <td style={{ textAlign: 'center' }}>
                  {'$' + quoteTotalOrNull(sql.nQuoteTotal)}
                </td>
              </MediaQuery>
              <td>{Moment(sql.dtLastActivity).utc().format('MM/DD/YYYY')}</td>
            </tr>
          ))}
        </>
      );
    }

    return (
      <Wrapper amount={this.state.data.length}>
        <SubTitle>My Recent Activity</SubTitle>
        <table className="dashboard">
          <thead>
            <tr>
              <MediaQuery minDeviceWidth={1100}>
                <th>Update</th>
              </MediaQuery>
              <th>ID</th>
              <th>Company</th>
              <th>Name</th>
              <MediaQuery minDeviceWidth={1100}>
                <th>City, State</th>
              </MediaQuery>
              <MediaQuery minDeviceWidth={1100}>
                <th>Title</th>
              </MediaQuery>
              <MediaQuery minDeviceWidth={1100}>
                <th>Property</th>
              </MediaQuery>
              <MediaQuery minDeviceWidth={1100}>
                <th>Total</th>
              </MediaQuery>
              <MediaQuery minDeviceWidth={1100}>
                <th>Last Activity</th>
              </MediaQuery>
            </tr>
          </thead>
          <tbody>
            {this.state.loaded || (
              <tr>
                <td colSpan="7">
                  <Loader
                    style={{
                      textAlign: 'center',
                    }}
                    type="ThreeDots"
                    color="#00BFFF"
                    height={20}
                    width={120}
                  />
                </td>
              </tr>
            )}
            {this.state.loaded && (
              <>
                {this.state.data.length > 0 ? (
                  <>{table}</>
                ) : (
                  <tr>
                    <td
                      colSpan="7"
                      style={{ textAlign: 'center', padding: '1em' }}
                    >
                      No quotes
                    </td>
                  </tr>
                )}
                {this.state.data.length > 10 ? (
                  <>
                    {this.state.showAll ? (
                      <button
                        onClick={() => {
                          this.setState({
                            showAll: false,
                          });
                        }}
                      >
                        Hide
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          this.setState({
                            showAll: true,
                          });
                        }}
                      >
                        Show All
                      </button>
                    )}
                  </>
                ) : null}
              </>
            )}
          </tbody>
        </table>
      </Wrapper>
    );
  }
}
