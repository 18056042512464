import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { cGreen, cRed, nQuoteTotalNoDollar, whichRep } from '../../../../assets/functions/calculations';
import moment from 'moment';
import { CustomDateWrapper, Input, SubmitText, GreyTextDiv } from '../../../styled/ui';
import { daysLate, percentTwoDecimal } from '../../../../assets/functions/display';



export default class RecentlyCompletedMonthTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    
    return (
      <table className="dashboard">
          <thead>
            <tr>
              <th>Quote</th>
              <th>Company</th>
              <th>First Closed</th>
              <th>Complete Date</th>
              <th>Target Date</th>
              <th>Open Issue</th>
              <th>Quote Total</th>
              <th>Margin</th>
              <th>Labor</th>
            </tr>
          </thead>
          <tbody>
            {this.props.quotes.map((quote, index)=>(
              <>
              <tr key={index}>
                <td><Link target="_blank" to={`/quote/${quote.id}`}>{quote.id}</Link></td>
                <td>{quote.sCompany}</td>
                <td>{moment(quote.dtFirstClosed).utc().format('MM/DD/YYYY')}</td>
                <td>{moment(quote.dtComplete).format('MM/DD/YYYY')}<br />{moment(quote.dtComplete).diff(quote.dtFirstClosed,'days')} day lead</td>
                <td>{moment(quote.dtTargetComplete).format('MM/DD/YYYY')}<br />{daysLate(quote.dtComplete,quote.dtTargetComplete)}</td>
                <td>{quote.bOpenIssue ? "Yes" : "No"}</td>
                <td>{nQuoteTotalNoDollar(quote.TotalPrice)}</td>
                <td>{nQuoteTotalNoDollar(quote.TotalPrice-quote.POTotal)}<br />{percentTwoDecimal((quote.TotalPrice-quote.POTotal)/quote.TotalPrice)}</td>
                <td onClick={()=>{
                  this.props.handleShowPO(index, this.props.monthIndex)}}>{nQuoteTotalNoDollar(quote.Labor)}</td>
              </tr>
              {quote.POLines && quote.showPOs && (
                <tr>
                  <td colSpan={12}>
                  <table>
                    <thead>
                      <tr>
                        <th>Vendor</th>
                        <th>Description</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Total Price</th>
                        <th>Item</th>
                      </tr>
                    </thead>
                    {quote.POLines.map((line, lineNumber) => (
                      <tr key={lineNumber}>
                        <td>{line.sCompany}</td>
                        <td>{line.sDescription}</td>
                        <td>{line.nQuantity}</td>
                        <td>{nQuoteTotalNoDollar(line.nPrice)}</td>
                        <td>{nQuoteTotalNoDollar(line.nQuantity*line.nPrice)}</td>
                        <td onClick={()=>this.props.handleSetLabor(line.LineID, line.nItem)}>{line.nItem === 832 && (
                          <>CSN Labor</>
                        ) || (
                          <GreyTextDiv>Set as CSN Labor</GreyTextDiv>
                        )}</td>
                      </tr>
                    ))}
                  </table>
                  </td>
                </tr>
                
              )}
              </>
            ))}
          </tbody>
        </table>
    );
  }
}
