import React, { Component } from 'react';
import styled from 'styled-components';
import { Bar } from 'react-chartjs-2';
import Axios from 'axios';
import { weekStart, weekEnd } from '../../../../assets/functions/time.js';
import {
  round,
  calcTotalMargin,
  calcTotalSale,
} from '../../../../assets/functions/calculations.js';
import Loader from 'react-loader-spinner';

const Wrapper = styled.section`
  width: 99%;
  max-width: 1600px;
  margin: 7em auto 5em auto;
`;

const SubTitle = styled.h2`
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  border-bottom: none !important;
  margin-bottom: 1em;
`;

function marginColor(margin) {
  if (margin <= 23) {
    return '#ff6666'; // Red
  } else if (margin >= 24 && margin <= 37.4) {
    return '#cccccc'; // White
  } else if (margin >= 37.5 && margin <= 55) {
    return '#85bb65'; // Green
  } else {
    return '#fffd6e'; // Yellow
  }
}

export default class CamCsnLineChart extends Component {
  state = {
    loaded: false,
    titleStartDate: weekStart(42),
    titleEndDate: weekEnd(7),
    7: [],
    14: [],
    21: [],
    28: [],
    35: [],
    42: [],
  };

  // Async Axios - Gets 6 weeks of data
  fetchData = async (startDate, endDate, i) => {
    const response = await Axios.get('/rac-dashboard/closed-orders', {
      params: {
        startDate: startDate,
        endDate: endDate,
      },
      headers: { 'Content-Type': 'application/json' },
    });
    // Sets the state using the passed counter from plotData
    this.setState({
      [i]: response.data.result[0].recordset,
    });
  };
  catch(error) {
    console.error(error);
  }

  // Runs plotData function when the component mounts
  componentDidMount() {
    this.plotData();
    this.setState({
      loaded: true,
    });
  }

  // Loops 6 times, fetchData provides 6 weeks of data, uses weekStart and weekEnd functions to provide correct dates
  plotData() {
    for (let i = 7; i <= 42; i += 7) {
      this.fetchData(weekStart(i), weekEnd(i), i);
      this.setState({
        [`date${i}`]: weekStart(i).slice(0, 5),
      });
    }
  }

  render() {
    return (
      <Wrapper>
        {this.state.loaded || (
          <tr>
            <td colSpan="8">
              <Loader
                style={{
                  textAlign: 'center',
                }}
                type="ThreeDots"
                color="#00BFFF"
                height={80}
                width={80}
              />
            </td>
          </tr>
        )}
        {this.state.loaded && (
          <>
            <SubTitle>Sales & Margin By Week</SubTitle>
            <div class="legend">
              <div class="legend-scale">
                <ul class="legend-labels">
                  <li>
                    <span style={{ background: '#ff6666' }}></span>0%- 23%
                  </li>
                  <li>
                    <span style={{ background: '#fafafa' }}></span>24% - 37.4%
                  </li>
                  <li>
                    <span style={{ background: '#85bb65' }}></span>37.5% - 55%
                  </li>
                  <li>
                    <span style={{ background: '#fffd6e' }}></span>55% - 100%
                  </li>
                </ul>
              </div>
            </div>
            <Bar
              data={{
                labels: [
                  this.state.date42,
                  this.state.date35,
                  this.state.date28,
                  this.state.date21,
                  this.state.date14,
                  this.state.date7,
                ],
                datasets: [
                  {
                    type: 'line',
                    lineTension: 0,
                    data: [
                      round(calcTotalSale(this.state[42])),
                      round(calcTotalSale(this.state[35])),
                      round(calcTotalSale(this.state[28])),
                      round(calcTotalSale(this.state[21])),
                      round(calcTotalSale(this.state[14])),
                      round(calcTotalSale(this.state[7])),
                    ],
                    label: 'Sales',
                    borderColor: '#666',
                    fill: false,
                    yAxisID: 'y-axis-2',
                  },
                  {
                    type: 'bar',
                    data: [
                      round(calcTotalMargin(this.state[42])),
                      round(calcTotalMargin(this.state[35])),
                      round(calcTotalMargin(this.state[28])),
                      round(calcTotalMargin(this.state[21])),
                      round(calcTotalMargin(this.state[14])),
                      round(calcTotalMargin(this.state[7])),
                    ],
                    label: 'Margins',
                    borderColor: 'rgba(251,98,98, 0.8)',
                    fill: false,
                    yAxisID: 'y-axis-1',
                    backgroundColor: [
                      marginColor(calcTotalMargin(this.state[42])),
                      marginColor(calcTotalMargin(this.state[35])),
                      marginColor(calcTotalMargin(this.state[28])),
                      marginColor(calcTotalMargin(this.state[21])),
                      marginColor(calcTotalMargin(this.state[14])),
                      marginColor(calcTotalMargin(this.state[7])),
                    ],
                  },
                ],
              }}
              width={100}
              height={35}
              options={{
                scales: {
                  yAxes: [
                    {
                      type: 'linear',
                      display: true,
                      position: 'right',
                      id: 'y-axis-1',
                      ticks: {
                        max: 100,
                        min: 0,
                        // Include a percentage sign in the ticks
                        callback: function (value, index, values) {
                          return value + '%';
                        },
                      },
                    },
                    {
                      type: 'linear',
                      display: true,
                      position: 'left',
                      id: 'y-axis-2',
                      ticks: {
                        // Include a dollar sign in the ticks
                        callback: function (value, index, values) {
                          return '$' + value;
                        },
                      },
                      gridLines: {
                        display: false,
                      },
                    },
                  ],
                },
                responsive: true,
                maintainAspectRatio: true,
                bezierCurve: false,
                legend: {
                  display: false,
                },
              }}
            />
          </>
        )}
      </Wrapper>
    );
  }
}
