import React, { Component } from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import './App.css';

import Home from './components/pages/Home';
import DesktopNavbar from './components/navbar/DesktopNavbar';
import MobileNavbar from './components/navbar/MobileNavbar';
import Login from './components/pages/Login';
import AdminDashboard from './components/pages/AdminDashboard';
import AccountingDashboard from './components/pages/AccountingDashboard';
import Results from './components/pages/Results';
import CamCsnDashboard from './components/pages/CamCsnDashboard';
import RacDashboard from './components/pages/RacDashboard';
import CamOperationsDashboard from './components/pages/CamOperationsDashboard';
import Settings from './components/pages/Settings';
import AllQuotes from './components/pages/AllQuotes';
import Quote from './components/pages/Quote';
import MasterViewer from './components/crm/master-viewer/index';
import MediaQuery from 'react-responsive';
import NewQuote from './components/pages/NewQuote'
import TASearch from './components/pages/TASearch';
import SupplierViewer from './components/srm';
import RacOperationsDashboard from './components/pages/RacOperationsDashboard';
import CustomQuery from './components/pages/CustomQuery';
import Tutorial from './components/pages/Tutorial';
import QuoteManager from './components/pages/TierWizard';
import { fetchUser } from './assets/functions/user';
import RacLogisticsHomev2 from './components/pages/home/RacLogisticsHomev2';
import UnderwritingDashboard from './components/pages/UnderwritingDashboard';
import MarketingDashboard from './components/pages/MarketingDashboard';
import SendEmail from './components/email/SendEmail';
import CannedResponses from './components/email/CannedResponses';
import AdminDashboardv2 from './components/pages/AdminDashboardv2';
import NewLeadReview from './components/pages/NewLeadReview';
import TakeNewQuote from './components/pages/TakeNewQuote';
import DiscontinuedQuoteReview from './components/pages/DiscontinuedQuoteReview';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Command Center",
      loaded: false,
    };
    this.updateTitleHandler = this.updateTitleHandler.bind(this)
  }


  updateTitleHandler(title){
    this.setState({title: title})
  }

  async componentDidMount() {
    try {
      let data = await fetchUser()
      if(data){
        this.setState({
          user: data.user,
          permissions: data.permissions,
        })
      }

    } catch(error){

    }
  }

  render() {
    return (
      <Router>
        {this.state.user === undefined && (
          <Route><Login /></Route>
        )}

        {this.state.user && (
        <>
        {/*<MediaQuery minDeviceWidth={900}><DesktopNavbar user={this.state.user} permissions={this.state.permissions} /></MediaQuery>
        <MediaQuery* maxDeviceWidth={900}><MobileNavbar user={this.state.user} permissions={this.state.permissions} /></MediaQuery*/}


          <DesktopNavbar user={this.state.user} permissions={this.state.permissions} title={this.state.title} updateTitle={this.updateTitleHandler} location={window.location.hash} />
          
        <Switch>
          <Route exact path="/"><Home user={this.state.user} updateTitle={this.updateTitleHandler} permissions={this.state.permissions} /></Route>
          <Route exact path="/home"><Home user={this.state.user} updateTitle={this.updateTitleHandler} permissions={this.state.permissions} /></Route>
          <Route exact path="/new-quote" ><NewQuote user={this.state.user} updateTitle={this.updateTitleHandler} permissions={this.state.permissions} /></Route>
          <Route exact path="/ta-search" ><TASearch user={this.state.user} updateTitle={this.updateTitleHandler} permissions={this.state.permissions} /></Route>
          <Route exact path="/admin-dashboard" ><AdminDashboardv2 user={this.state.user} updateTitle={this.updateTitleHandler} permissions={this.state.permissions} /></Route>
          <Route exact path="/accounting-dashboard" ><AccountingDashboard user={this.state.user} updateTitle={this.updateTitleHandler} permissions={this.state.permissions} /></Route>
          <Route exact path="/cam-csn-dashboard" ><CamCsnDashboard user={this.state.user} updateTitle={this.updateTitleHandler} permissions={this.state.permissions} /></Route>
          <Route exact path="/cam-operations" ><CamOperationsDashboard user={this.state.user} updateTitle={this.updateTitleHandler} permissions={this.state.permissions} /></Route>
          <Route exact path="/rac-dashboard" ><RacDashboard user={this.state.user} updateTitle={this.updateTitleHandler} permissions={this.state.permissions} /></Route>
          <Route exact path="/rac-operations" ><RacOperationsDashboard user={this.state.user} updateTitle={this.updateTitleHandler} permissions={this.state.permissions} /></Route>
          <Route exact path="/all-quotes" ><AllQuotes user={this.state.user} updateTitle={this.updateTitleHandler} permissions={this.state.permissions} /></Route>
          <Route exact path="/custom-query" ><CustomQuery user={this.state.user} updateTitle={this.updateTitleHandler} permissions={this.state.permissions} /></Route>
          <Route exact path="/settings" ><Settings user={this.state.user} updateTitle={this.updateTitleHandler} permissions={this.state.permissions} /></Route>
          <Route exact path="/tutorial" ><Tutorial user={this.state.user} updateTitle={this.updateTitleHandler} permissions={this.state.permissions} /></Route>
          <Route exact path="/accounting-dashboard" ><AccountingDashboard user={this.state.user} updateTitle={this.updateTitleHandler} permissions={this.state.permissions} /></Route>
          <Route exact path="/logistics-dashboard" ><RacLogisticsHomev2 user={this.state.user} updateTitle={this.updateTitleHandler} permissions={this.state.permissions} /></Route>
          <Route exact path="/underwriting-dashboard" ><UnderwritingDashboard user={this.state.user} updateTitle={this.updateTitleHandler} permissions={this.state.permissions} /></Route>
          <Route exact path="/marketing-dashboard" ><MarketingDashboard user={this.state.user} updateTitle={this.updateTitleHandler} permissions={this.state.permissions} /></Route>
          <Route exact path="/send-email" ><SendEmail user={this.state.user} updateTitle={this.updateTitleHandler} permissions={this.state.permissions} /></Route>
          <Route exact path="/canned-responses" ><CannedResponses user={this.state.user} updateTitle={this.updateTitleHandler} permissions={this.state.permissions} /></Route>
          <Route exact path="/marketing-tier-wizard" ><QuoteManager user={this.state.user} updateTitle={this.updateTitleHandler} permissions={this.state.permissions} /></Route>
          <Route exact path="/new-lead-review" ><NewLeadReview user={this.state.user} updateTitle={this.updateTitleHandler} permissions={this.state.permissions} /></Route>
          <Route exact path="/take-new-quote" ><TakeNewQuote user={this.state.user} updateTitle={this.updateTitleHandler} permissions={this.state.permissions} /></Route>
          <Route exact path="/discontinued-quote-review" ><DiscontinuedQuoteReview user={this.state.user} updateTitle={this.updateTitleHandler} permissions={this.state.permissions} /></Route>
          <Route exact path="/crm/master-viewer/:customer" component={MasterViewer} permissions={this.state.permissions} />
          <Route exact path="/srm/master-viewer/:supplier" component={SupplierViewer} permissions={this.state.permissions} />          
          <Route exact path="/quote/:id" component={Quote} />
          <Route exact path="/results/:search" component={Results} permissions={this.state.permissions} />

          
          <Route path="*" ><Home user={this.state.user} updateTitle={this.updateTitleHandler} permissions={this.state.permissions} /></Route>

        </Switch>
        </>
        )}
      </Router>
    );
  }
}

export default App;