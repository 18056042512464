import React, { Component } from 'react'
import { getSQLData, setSQLData } from '../../../../assets/functions/fetch';
import moment from 'moment';
import { nQuoteTotalNoDollar } from '../../../../assets/functions/calculations';
import { stateAbbreviations } from '../../../../assets/data/states';
import { Allegheny } from '../../../../assets/data/geography';
import QBInvoice from './QBInvoice';
import QBCustomerInfo from './QBCustomerInfo';

export default class InvoiceTool extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            id: null,
        };
        this.handleUpdateInvoiceData = this.handleUpdateInvoiceData.bind(this)
        this.handleUpdateQBName = this.handleUpdateQBName.bind(this)
        this.changeTerms = this.changeTerms.bind(this)
        this.changeCreditLimit = this.changeCreditLimit.bind(this)
        this.updateCustomerTerms = this.updateCustomerTerms.bind(this)
      }

      componentDidMount(){

      }

      fetchData = async() => {
        try {
            const fetchQuote = await getSQLData("accounting/getInvoiceData", {id: this.state.id})
            const fetchLines = await getSQLData("accounting/getQuoteLineData", {id: this.state.id})
            const fetchPOs = await getSQLData("accounting/getPOData", {id: this.state.id})
            const fetchPOLines = await getSQLData("accounting/getPOLineData", {id: this.state.id})
            
            if(fetchQuote && fetchLines && fetchPOs && fetchPOLines && fetchQuote.data.data[0].recordset[0]){
                let quote = fetchQuote.data.data[0].recordset[0]
                let lines = fetchLines.data.data[0].recordset
                let POs = fetchPOs.data.data[0].recordset
                let POLines = fetchPOLines.data.data[0].recordset

                console.log(quote, lines, POs, POLines)

                this.setState({
                  quote: quote,
                  lines: lines,
                  POs: POs,
                  POLines: POLines,
                }, ()=>this.processData())

            }

        } catch (error) {

        }
      }

      processData() {
        let quote = this.state.quote
        let lines = this.state.lines
        let POs = this.state.POs
        let POLines = this.state.POLines
        let errors = []
        let unmatchedQuoteLines = []
        let unmatchedPOLines = []

        let invoice = {}
        invoice.nParentQuote = quote.nParentQuote
        invoice.sQBCustomer = quote.sQBCustomer ? quote.sQBCustomer : ""
        invoice.needsQBCustomer = quote.sQBCustomer ? false : true
        invoice.sCompany = quote.sCompany
        invoice.ClosedQuotes = quote.ClosedQuotes
        invoice.nCompany = quote.nCompany ? quote.nCompany : -1
        invoice.id = quote.id
        invoice.sQuoteType = quote.sQuoteType
        invoice.dtPaid = quote.dtPaid
        invoice.date = quote.dtDeliveryDate
        invoice.billingAddress = quote.sCompany + "\r\n" 
            + quote.sName + "\n"
            + quote.sAddress + "\n" 
            + (quote.sAddress2 ? quote.sAddress2 + "\n" : "") 
            + quote.sCity + ", " + quote.sState + " " + quote.sZip
        invoice.deliveryAddress = quote.sDCompany + "\n" 
            + quote.sDName + "\n"
            + quote.sDAddress + "\n" 
            + (quote.sDAddress2 ? quote.sDAddress2 + "\n" : "") 
            + quote.sDCity + ", " + quote.sDState + " " + quote.sDZip

        

        invoice.PONumber = quote.sPONumber
        invoice.rep = quote.RepInitials
        

        if(invoice.nParentQuote){
            invoice.dtPickupDate = quote.dtBillingCycleEnd.substring(0,4) === "1900" ? null : quote.dtBillingCycleEnd
            invoice.dtPickupTime = quote.dtPickupTime ? quote.dtPickupTime : null
            invoice.dtDeliveryDate = quote.dtBillingCycleStart.substring(0,4) === "1900" ? null : quote.dtBillingCycleStart
            invoice.dtDeliveryTime = quote.dtDeliveryTime ? quote.dtDeliveryTime : null
        } else {
            invoice.dtPickupDate = quote.dtPickupDate.substring(0,4) === "1900" ? null : quote.dtPickupDate
            invoice.dtPickupTime = quote.dtPickupTime ? quote.dtPickupTime : null
            invoice.dtDeliveryDate = quote.dtDeliveryDate.substring(0,4) === "1900" ? null : quote.dtDeliveryDate
            invoice.dtDeliveryTime = quote.dtDeliveryTime ? quote.dtDeliveryTime : null
        }

        invoice.startDate = invoice.dtDeliveryDate
        invoice.pickupDate = invoice.dtPickupDate

        invoice.deliveryMethod = quote.nDeliveryType === 1 ? "Supplier" : "UPS"

        invoice.lines = []
        invoice.taxRate = quote.nTaxRate
        invoice.total = 0
        invoice.tax = 0
        invoice.taxDistrict = (quote.sDState !== "FL" && quote.sDState !== "PA") 
            ? (stateAbbreviations[quote.sDState] ? stateAbbreviations[quote.sDState] : quote.sDState) 
            : (quote.sDState === "FL" 
                ? quote.sDCity + ", " + quote.sDState 
                : (quote.sDCity == "Philadelphia" 
                    ? "Philadelphia, PA" 
                    : (Allegheny.includes(quote.sDCity) 
                        ? "Allegheny County, PA" 
                        : "Pennsylvania"
                    )
                ) 
            )
    
        let shippingLine = -1
        for(let i=0; i<lines.length; i++){
            let lineCost = lines[i].nQuantity * lines[i].nPrice ? lines[i].nQuantity * lines[i].nPrice : 0
            let line = {
                quantity: lines[i].nQuantity,
                code: null,
                description: lines[i].sDescription,
                price: lines[i].nPrice,
                extended: lineCost,
                tax: "Tax",
                POLineMatch: -1,
            }
            if(lines[i].nItem === 70 || lines[i].nItem === 83 || lines[i].nItem === 236 || 
                lines[i].nItem === 304 || lines[i].nItem === 629 || lines[i].nItem === 630 || 
                lines[i].nItem === 776 || lines[i].nItem === 781 || lines[i].nItem === 76 || 
                lines[i].nItem === 89){
                shippingLine = i
            }
            invoice.lines.push(line)
            
            invoice.total += lineCost
            invoice.tax += lineCost * quote.nTaxRate/100
            
        }

        invoice.delivery = quote.nDelivery
        invoice.taxDelivery = quote.bTaxDel

        if(invoice.delivery){
            if(shippingLine === -1 || (!invoice.taxDelivery && invoice.lines[shippingLine].price > 0)){
                let line = {
                    quantity: 1,
                    code: null,
                    description: quote.nDeliveryType === 1 ? "Local Delivery & Pick Up Based on Normal Hours, M-F 8am-5pm" : "Non-Taxable Shipping",
                    price: invoice.delivery,
                    extended: invoice.delivery,
                    tax: invoice.taxDelivery ? "Tax" : "Non",
                    POLineMatch: -1,
                }
                invoice.lines.push(line)
            } else {
                invoice.lines[shippingLine].quantity = 1
                invoice.lines[shippingLine].price = invoice.lines[shippingLine].price + invoice.delivery
                invoice.lines[shippingLine].extended = invoice.lines[shippingLine].price + invoice.delivery
            }
            
            
            invoice.total += invoice.delivery
            invoice.tax += quote.bTaxDel ? invoice.delivery * quote.nTaxRate/100 : 0
        }

        invoice.Business3Years = quote.Business3Years
        invoice.firstOrder = quote.firstOrder

        invoice.needsTerms = (!quote.sPaymentTerms || (!quote.nCreditLimit && quote.nCreditLimit !== 0))
        invoice.sPaymentTerms = quote.sPaymentTerms ? quote.sPaymentTerms : "Credit Card"
        invoice.nCreditLimit = quote.nCreditLimit ? quote.nCreditLimit : 0

        invoice.ready = !invoice.needsQBCustomer && !invoice.needsTerms
        invoice.viewing = invoice.ready

        for(let i=0; i<POs.length; i++){
            POs.lines = []

            if(moment(POs[i].dtDeliveryDate).diff(moment(invoice.dtDeliveryDate), "days") > 0){errors.push(POs[i].sDisplayId + " Delivery Date Doesn't Match ")}
            if(moment(POs[i].dtDeliveryTime).diff(moment(invoice.dtDeliveryTime), "hours") > 0){errors.push(POs[i].sDisplayId + " Delivery Time Doesn't Match ")}
            if(moment(POs[i].dtPickupDate).diff(moment(invoice.dtPickupDate), "days") > 0){errors.push(POs[i].sDisplayId + " Pickup Date Doesn't Match ")}
            if(moment(POs[i].dtPickupTime).diff(moment(invoice.dtPickupTime), "hours") > 0){errors.push(POs[i].sDisplayId + " Pickup Time Doesn't Match ")}
            
            if(POs[i].sDName !== quote.sDName){errors.push(POs[i].sDisplayId + " Delivery Name Doesn't Match")}
            if(POs[i].sDCompany !== quote.sDCompany){errors.push(POs[i].sDisplayId + " Delivery Company Doesn't Match")}
            if(POs[i].sDAddress !== quote.sDAddress){errors.push(POs[i].sDisplayId + " Delivery Address Doesn't Match")}
            if(POs[i].sDAddress2 !== quote.sDAddress2){errors.push(POs[i].sDisplayId + " Delivery Address2 Doesn't Match")}
            if(POs[i].sDCity !== quote.sDCity){errors.push(POs[i].sDisplayId + " Delivery City Doesn't Match")}
            if(POs[i].sDState !== quote.sDState){errors.push(POs[i].sDisplayId + " Delivery State Doesn't Match")}
            if(POs[i].sDZip !== quote.sDZip){errors.push(POs[i].sDisplayId + " Delivery Zip Doesn't Match")}
        }

        for(let i=0; i<POLines.length; i++){
            POLines[i].quoteLineMatch = -1
            for(let j=0; j<lines.length; j++){
                if(POLines[i].sDescription === invoice.lines[j].description){
                    POLines[i].quoteLineMatch = j
                    invoice.lines[j].POLineMatch = i

                    let fixPOQuantity = POLines[i].nQuantity ? POLines[i].nQuantity : 0
                    let fixInvoiceQuantity = invoice.lines[j].quantity ? invoice.lines[j].quantity : 0
                    if(fixPOQuantity !== fixInvoiceQuantity){
                        errors.push("Quantity mismatch on line " + POLines[i].sDescription)
                        unmatchedPOLines.push(POLines[i])
                        unmatchedQuoteLines.push(invoice.lines[j])
                    }
                }
                if(j === lines.length-1 && POLines[i].quoteLineMatch === -1){
                    errors.push("No match for PO Line " + POLines[i].sDescription)
                    unmatchedPOLines.push(POLines[i])
                }
            }

            for(let j=0; j<POs.length; j++){
                if(POLines[i].sQuote === POs[j].id){
                   POLines[i].PO = POs[j].sDisplayId
                   POs[j].lines.push(POLines[i])
                }
            }
        }

        for(let i=0; i<invoice.lines.length; i++){
            if(invoice.lines[i].POLineMatch === -1){
                errors.push("No match for Quote Line " + invoice.lines[i].description)
                unmatchedQuoteLines.push(invoice.lines[i])
                
            }
        }

        console.log(unmatchedQuoteLines, unmatchedPOLines)

        this.setState({
            invoice: invoice,
            errors: errors,
            unmatchedPOLines: unmatchedPOLines,
            unmatchedQuoteLines: unmatchedQuoteLines,
            loaded: true,
        })
      }

      handleUpdateQBName = async() => {
        try {
            let invoice = this.state.invoice
            const update = await setSQLData("accounting/setQBCustomer",{
                sQBCustomer: invoice.sQBCustomer,
                nCompany: invoice.nCompany,
            })
            if(update){this.fetchData()}
        } catch (error) {

        }
      }

      handleUpdateInvoiceData(data, field){
        let invoice = this.state.invoice
        invoice[field] = data
        this.setState({invoice: invoice,})
      }

      

      changeCreditLimit(amount){
        let invoice = this.state.invoice
        if(parseInt(amount)){invoice.nCreditLimit = parseInt(amount)}
        if(amount === 0){invoice.nCreditLimit = 0}
        this.setState({invoice:invoice})
      }
      changeTerms(event){
        let invoice = this.state.invoice
        let selected = ""
        console.log(event)
        for(let i=0; i<event.target.options.length; i++){
            if(event.target.options[i].selected){selected = event.target.options[i].value;break;}
        }
        invoice.sPaymentTerms = selected
        this.setState({invoice: invoice})
      }

      updateCustomerTerms = async() => {
        try {
            let invoice = this.state.invoice
            let update = await setSQLData("accounting/setCompanyTerms", {
                sPaymentTerms: invoice.sPaymentTerms,
                nCreditLimit: invoice.nCreditLimit,
                nCompany: invoice.nCompany,
            })

            if(update){
                this.fetchData()
            }

        } catch (error) {

        }
      }

      switchView() {
        let invoice = this.state.invoice

        invoice.viewing = !invoice.viewing

        this.setState({invoice: invoice,})
      }


  render() {
    let invoice = this.state.invoice
    let errors = this.state.errors
    let POs = this.state.POs
    let unmatchedPOLines = this.state.unmatchedPOLines
    let unmatchedQuoteLines = this.state.unmatchedQuoteLines
    return (
        <div>
            <input onChange={(event)=>this.setState({id: event.target.value})} value={this.state.id}></input>
            <button onClick={()=>this.fetchData()}>Search</button>
            {this.state.loaded && (
                <div>
                    {invoice.nCompany === -1 && (
                        <div>
                            No Company Record Associated
                        </div>
                    )}

                    
                    {(!invoice.ready || !invoice.viewing) && (
                        <QBCustomerInfo
                            invoice={invoice}
                            handleUpdateInvoiceData = {this.handleUpdateInvoiceData}
                            handleUpdateQBName = {this.handleUpdateQBName}
                            changeTerms = {this.changeTerms}
                            changeCreditLimit = {this.changeCreditLimit}
                            updateCustomerTerms = {this.updateCustomerTerms}
                        />
                    )}
                    
                    
                    {invoice.ready && (
                    <>
                        {errors.map((error,index)=>(
                            <div style={{fontWeight: 'bold'}}>{error}</div>
                        ))}

                        <button onClick={()=>this.switchView()}>{invoice.viewing ? "Show Customer Info" : "Hide Customer Info"}</button>
                        <QBInvoice
                            invoice={invoice}
                        />

                        <table>
                            <tr>
                                <td style={{border: '1px solid black', width: '600px', verticalAlign:'top',}}>
                                <table>
                                    <tr>
                                        <td style={{width: '100px'}}>Quantity</td>
                                        <td style={{width: '100px'}}>Price</td>
                                        <td style={{width: '400px'}}>Description</td>
                                    </tr>
                                    {unmatchedQuoteLines.map((line,index)=>(
                                        <tr>
                                            <td>{line.quantity}</td>
                                            <td>{line.price}</td>
                                            <td>{line.description}</td>
                                        </tr>
                                    ))}
                                </table>
                                </td>
                                <td style={{border: '1px solid black', width: '600px', verticalAlign:'top',}}>
                                <table>
                                    <tr>
                                        <td style={{width: '100px'}}>Quantity</td>
                                        <td style={{width: '100px'}}>Price</td>
                                        <td style={{width: '400px'}}>Description</td>
                                    </tr>
                                    {unmatchedPOLines.map((line,index)=>(
                                        <tr>
                                            <td>{line.nQuantity}</td>
                                            <td>{line.nPrice}</td>
                                            <td>{line.sDescription}</td>
                                        </tr>
                                    ))}
                                </table>
                                </td>
                            </tr>
                        </table>
                        
                    </>
                    )}
                </div>
            )}
        </div>
      
    )
  }
}
