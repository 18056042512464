import React, { Component } from 'react';
import { SubTitle } from '../styled/ui';


export default class HideShowSubTitle extends Component {
    constructor(props) {
        super(props);
        this.state = {
          showChart: false,
        };
      }

  render() {
    
    return (
        <SubTitle onClick={() => this.props.handleHideShow(this.props.index)}>
          {this.props.name}
          {this.props.hideAll ? (
            <i
            style={{ padding: '5px', fontSize: '1.2rem' }}
            className="fas fa-arrow-alt-circle-down"
          ></i>
          ) : (
            <i
            style={{ padding: '5px', fontSize: '1.2rem' }}
            className="fas fa-arrow-alt-circle-up"
          ></i>
          )}
        </SubTitle>
    );
  }
}
