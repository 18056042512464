import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Axios from 'axios';
import Moment from 'moment';
import Loader from 'react-loader-spinner';
import MediaQuery from 'react-responsive';
import { calcMilesFromLatLong, whichRep } from '../../../assets/functions/calculations';
import { getSQLData, setSQLData } from '../../../assets/functions/fetch';
import HideShowSubTitle from '../../misc/HideShowSubTitle';
import OpenIssuesTable from './OpenIssues/OpenIssuesTable';
import { nullDateCheck } from '../../../assets/functions/system';

const Wrapper = styled.div`
  width: 99%;
  margin: 0 auto;
  max-width: 1600px;

  table {
    width: 100%;
    position: relative;
    ${(props) => {
      if (props.amount > 10)
        return `
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;

    thead + tbody tr:last-child td:last-child,
    tfoot tr:last-child td:last-child {
      border-bottom-right-radius: 0px !important;
    }
    thead + tbody tr:last-child td:first-child,
    tfoot tr:last-child td:first-child {
      border-bottom-left-radius: 0px !important;
    }
    margin-bottom: 7em;
        `;
    }}
  }

  button {
    position: absolute;
    width: 100%;
    bottom: -27px;
     padding: 0.35em 1.2em;
     text-decoration: none;
     color: #fafafa;
     text-align: center;
     transition: all 0.2s;
    background-color: #4aabed;
    border: 1px solid #4aabed;
    cursor: pointer;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    &:hover {
      color: #222;
      background-color: #fafafa;
      border: 1px solid #d7d7d7;
    }
  }
`;


export default class OpenIssues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      hideAll: false,
    };
    this.handleUpdateTag = this.handleUpdateTag.bind(this)
    this.handleSaveTag = this.handleSaveTag.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleIssueReportedDateChange = this.handleIssueReportedDateChange.bind(this)
  }


  fetchData = async () => {
    try {
      const fetch = await getSQLData("cam/openIssues", {})
      
      if(fetch){

        let data = fetch.data.data[0].recordset    
        let query = fetch.query
        
        this.setState({
          data: data,
          query: query,
        }, () => this.processData())
      }
         
    } catch (error) {
      console.error(error);
    }
  };

  processData = () => {

    this.setState({
      loaded: true,
    })
  }

  componentDidMount() {
    this.fetchData();
  }

  handleSaveTag = async (tag, id) => {
    try {
      const fetch = await setSQLData("cam/setQuoteTag", {
        quoteNum: id,
        quoteTag: tag,
      })
      
      if(fetch){
        this.fetchData();
      }
      
    } catch (error) {
      console.error(error);
    }
  };

  handleUpdateTag(getTag, getIndex) {
    let data = this.state.data

    data[getIndex].sQuoteTags = getTag
    data[getIndex].changed = true

    this.setState({data: data})
  }

  handleDateChange = (event, id, paramName) => {
    let data = this.state.data
    data[id][paramName] = event.target.value

    this.setState({data: data})
  };

  handleIssueReportedDateChange = async (date, id) => {
    try {
      const post = await setSQLData("cam/setIssueReportedDate", {
        quoteNum: id,
        issueReportedDate: nullDateCheck(date),
      })
      
      if(post){
        this.fetchData();
      }
      
    } catch (error) {
      console.error(error);
    }
  };

  handleCloseIssue = async (id) => {
    try {
      const post = await setSQLData("cam/closeIssue", {
        quoteNum: id,
      })
      
      if(post){
        this.fetchData();
      }
      
    } catch (error) {
      console.error(error);
    }
  };
  

  render() {
    return (
      <>
      {this.state.loaded && (

      <Wrapper amount={this.state.data.length}>
        <HideShowSubTitle name={"Open Issues"} hideAll={this.state.hideAll} handleHideShow={this.handleHideShow} />
         {!this.state.hideAll && (
          <>
            <OpenIssuesTable 
              data={this.state.data}
              handleUpdateTag={this.handleUpdateTag}
              handleSaveTag={this.handleSaveTag}
              handleDateChange={this.handleDateChange}
              handleIssueReportedDateChange={this.handleIssueReportedDateChange}
              handleCloseIssue={this.handleCloseIssue}
            />
          </>
        )}
      </Wrapper>
      )}
      </>
    );
  }
}
