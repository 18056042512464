import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin: 1em 1em 0 0;
  width: 100%;
  height: fit-content;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.1);
  text-align: center;
  border-radius: 15px;

  button {
    cursor: pointer;
    width: 70%;
    margin: 3em auto;
    height: 50px;
    border: none;
    transition: all 0.2s;
    border-radius: 4px;
    background-color: #e8edf1;

    &:hover {
      background-color: #4aabed;
      color: #fff;
    }
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4aabed;
  color: #fff;
  height: 35px;
  font-weight: bold;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
`;

export default class CreditCardInfo extends Component {
  render() {
    return (
      <Wrapper>
        <Title>Credit Card</Title>
        <div>
          <button>Heartland Virtual Terminal</button>
        </div>
      </Wrapper>
    );
  }
}
