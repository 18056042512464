import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Axios from 'axios';
import Loader from 'react-loader-spinner';
import Moment from 'moment';
import { whichRep } from '../../../assets/functions/calculations';

const Wrapper = styled.div`
  width: 99%;
  max-width: 1300px;
  margin: 4em auto;

  table {
    width: 100%;
    position: relative;
    ${(props) => {
      if (props.amount > 10)
        return `
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;

    thead + tbody tr:last-child td:last-child,
    tfoot tr:last-child td:last-child {
      border-bottom-right-radius: 0px !important;
    }
    thead + tbody tr:last-child td:first-child,
    tfoot tr:last-child td:first-child {
      border-bottom-left-radius: 0px !important;
    }
    margin-bottom: 7em;
        `;
    }}
  }

  button {
    position: absolute;
    width: 100%;
    bottom: -27px;
     padding: 0.35em 1.2em;
     text-decoration: none;
     color: #fafafa;
     text-align: center;
     transition: all 0.2s;
    background-color: #4aabed;
    border: 1px solid #4aabed;
    cursor: pointer;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    &:hover {
      color: #222;
      background-color: #fafafa;
      border: 1px solid #d7d7d7;
    }
  }
`;

const SubTitle = styled.h2`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1em;

  i {
    font-size: 16px;
    margin-left: 7px;
  }
`;

const TableRow = styled.tr`
  ${(props) => {
    if (props.sFrom.includes(props.sEmail))
      return `
        background-color: #85bb65;

        &:hover {
          background-color: #85bb65 !important;
        }
        `;
  }}
  ${(props) => {
    if (
      props.sFrom.includes(whichRep(props.nRep)) &&
      props.sEmail === props.sTo
    )
      return `
        font-weight: bold;
        `;
  }}
`;

export default class MyRecentEmails extends Component {
  state = {
    data: [],
    loaded: false,
    showAll: false,
  };

  // Async Axios
  fetchData = async (nRep) => {
    try {
      const response = await Axios.get('/my-recent-emails', {
        params: {
          nRep: nRep,
        },
        headers: { 'Content-Type': 'application/json' },
      });
      // // Sets the state using the response data
      this.setState({
        data: response.data.result[0].recordset,
        loaded: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    this.fetchData(this.props.nRep);
  }

  render() {
    let table;

    // If the state showAll is false then table with return with only 10 rows, else show all 100
    if (this.state.showAll === false) {
      table = (
        <>
          {this.state.data.slice(0, 10).map((sql) => (
            <TableRow
              sFrom={sql.sFrom}
              sEmail={sql.sEmail}
              sTo={sql.sTo}
              nRep={sql.nRep}
              key={sql.id}
            >
              <td style={{ textAlign: 'center' }}>
                <Link target="_blank" to={`/quote/${sql.id}`}>
                  {sql.id}
                </Link>
              </td>
              <td style={{ textAlign: 'center' }}>{whichRep(sql.nRep)}</td>
              <td>
                <Link
                  target="_blank"
                  to={`/crm/master-viewer/${sql.sCompany.replace(
                    /\//g,
                    '%2F'
                  )}`}
                >
                  {sql.sCompany}
                </Link>
              </td>
              <td>{sql.sFrom}</td>
              <td style={{ textAlign: 'center' }}>
                {Moment(sql.dtDate).utc().format('MM/DD/YYYY')}
              </td>
              {sql.nQuoteTotal ? (
                <td style={{ textAlign: 'center' }}>
                  $
                  {sql.nQuoteTotal.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
              ) : (
                <td>{null}</td>
              )}
              <td style={{ textAlign: 'center' }}>{sql.sStatus}</td>
            </TableRow>
          ))}
        </>
      );
    } else {
      table = (
        <>
          {this.state.data.map((sql) => (
            <TableRow
              sFrom={sql.sFrom}
              sEmail={sql.sEmail}
              sTo={sql.sTo}
              nRep={sql.nRep}
              key={sql.id}
            >
              <td style={{ textAlign: 'center' }}>
                <Link target="_blank" to={`/quote/${sql.id}`}>
                  {sql.id}
                </Link>
              </td>
              <td style={{ textAlign: 'center' }}>{whichRep(sql.nRep)}</td>
              <td>
                <Link
                  target="_blank"
                  to={`/crm/master-viewer/${sql.sCompany.replace(
                    /\//g,
                    '%2F'
                  )}`}
                >
                  {sql.sCompany}
                </Link>
              </td>
              <td>{sql.sFrom}</td>
              <td style={{ textAlign: 'center' }}>
                {Moment(sql.dtDate).utc().format('MM/DD/YYYY')}
              </td>
              {sql.nQuoteTotal ? (
                <td style={{ textAlign: 'center' }}>
                  $
                  {sql.nQuoteTotal.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
              ) : (
                <td>{null}</td>
              )}
              <td style={{ textAlign: 'center' }}>{sql.sStatus}</td>
            </TableRow>
          ))}
        </>
      );
    }

    return (
      <Wrapper amount={this.state.data.length}>
        <SubTitle>My Recent Quote Emails</SubTitle>
        <table className="dashboard">
          <thead>
            <tr>
              <th style={{ width: 'auto' }}>Quote</th>
              <th style={{ width: 'auto' }}>Rep</th>
              <th style={{ width: 'auto' }}>Customer</th>
              <th style={{ width: '28%' }}>Sender</th>
              <th style={{ width: 'auto' }}>Sent Date</th>
              <th style={{ width: 'auto' }}>Quote $</th>
              <th style={{ width: 'auto' }}>Status</th>
            </tr>
          </thead>
          <tbody>
            {this.state.loaded || (
              <tr>
                <td colSpan="7">
                  <Loader
                    style={{
                      textAlign: 'center',
                    }}
                    type="ThreeDots"
                    color="#00BFFF"
                    height={20}
                    width={120}
                  />
                </td>
              </tr>
            )}
            {this.state.loaded && (
              <>
                {this.state.data.length > 0 ? (
                  <>{table}</>
                ) : (
                  <tr>
                    <td
                      colSpan="7"
                      style={{ textAlign: 'center', padding: '1em' }}
                    >
                      No Emails
                    </td>
                  </tr>
                )}
                {this.state.data.length > 10 ? (
                  <>
                    {this.state.showAll ? (
                      <button
                        onClick={() => {
                          this.setState({
                            showAll: false,
                          });
                        }}
                      >
                        Hide
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          this.setState({
                            showAll: true,
                          });
                        }}
                      >
                        Show All
                      </button>
                    )}
                  </>
                ) : null}
              </>
            )}
          </tbody>
        </table>
      </Wrapper>
    );
  }
}
