import React, { Component } from 'react'
import { getSQLData, setSQLData } from '../../../../assets/functions/fetch';
import { nQuoteTotalNoDollar, whichRep } from '../../../../assets/functions/calculations';
import moment from 'moment';

export default class CommissionLogLookup extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loaded: false,
            lookup: 0,
        };

      }

      lookupQuote= async() => {
        try {
            const fetch = await getSQLData("accounting/commissionLogLookup", {
                nQuote: parseInt(this.state.lookup)
            })

            if(fetch){
                let data = fetch.data.data[0].recordset

                this.setState({
                    data: data,
                }, ()=>this.processData())
            }
            
        } catch (error) {

        }
      }

      processData() {
        let data = this.state.data
        let quoteData = {
            margin: data[0].linesTotal + data[0].nDelivery - this.state.data[0].POTotal,
            logged: 0,
            exception: 0,
        }

        for(let i=0; i<data.length; i++){
            quoteData.logged += data[i].nMargin
            if(data[i].sException){quoteData.exception += data[i].nMargin}
        }

        this.setState({
            data: data,
            quoteData: quoteData,
            loaded: true,
        })

      }

      deleteLog = async(logID) => {
        try {
          const fetch = await setSQLData("accounting/deleteCommissionLog",{
            logID: logID,
          })
          
          if(fetch){this.lookupQuote()}
        } catch (error) {

        }
      }

  render() {
    return (
        <>
      <div>
        <input onChange={(event)=>this.setState({lookup: event.target.value})} value={this.state.lookup}></input>
        <button onClick={()=>this.lookupQuote()}>Lookup Quote</button>
      </div>
      {this.state.loaded && (
        <>
        <table>
            <tr><td>Quote</td><td>{this.state.data[0].id}</td></tr>
            <tr><td>Status</td><td>{this.state.data[0].sStatus}</td></tr>
            <tr><td>Invoice Date</td><td>{moment(this.state.data[0].dtInvoiced).format("MM/DD/YYYY")}</td></tr>
            <tr><td>First Closed Date</td><td>{moment(this.state.data[0].dtFirstClosed).format("MM/DD/YYYY")}</td></tr>
            <tr><td>Delivery Date</td><td>{moment(this.state.data[0].dtDeliveryDate).format("MM/DD/YYYY")}</td></tr>
            <tr><td>Paid Date</td><td>{moment(this.state.data[0].dtPaid).format("MM/DD/YYYY")}</td></tr>
            <tr><td>Rep</td><td>{whichRep(this.state.data[0].nRep)}</td></tr>
            <tr><td>Sales Total</td><td>{nQuoteTotalNoDollar(this.state.data[0].linesTotal + this.state.data[0].nDelivery)}</td></tr>
            <tr><td>PO Total</td><td>{nQuoteTotalNoDollar(this.state.data[0].POTotal)}</td></tr>
            <tr><td>Calculated Margin</td><td>{nQuoteTotalNoDollar(this.state.quoteData.margin)}</td></tr>
            <tr><td>Commission Log Total</td><td>{nQuoteTotalNoDollar(this.state.quoteData.logged)}</td></tr>
            <tr><td>Exception Total</td><td>{nQuoteTotalNoDollar(this.state.quoteData.exception)}</td></tr>
        </table>

        <table>
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Rep</th>
                    <th>Exception</th>
                    <th></th>
                </tr>
            </thead>
            {this.state.data.map((entry, index)=>(
                <tr>
                    <td>{entry.dtDateEarned}</td>
                    <td>{entry.nMargin}</td>
                    <td>{whichRep(entry.logRep)}</td>
                    <td>{entry.sException}</td>
                    <td><button onClick={()=>this.deleteLog(entry.logID)}>Delete</button></td>
                </tr>
            ))}
        </table>

        </>
      )}
      </>
    )
  }
}
