import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  height: fit-content;
  text-align: center;

  select {
    width: 70%;
    padding: 0.4em;
    margin: 0.2em auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
  }

  input {
    width: 70%;
    padding: 0.7em;
    margin: 0.2em auto;
    border: 1px solid #d7d7d7;
    border-radius: 4px;
    background-color: #fff;
  }

  textarea {
    width: 85%;
    margin: 0.7em auto 0 auto;
    padding: 0.8em;
    border: 1px solid #d7d7d7;
    border-radius: 4px;
    background-color: #fff;
  }

  p {
    font-weight: bold;
    text-align: left;
    margin: 0.35em 0 0 5em;

    &:nth-child(11) {
      margin: 1em 0 0 2.5em;
    }
  }
`;

export default class QuoteActions extends Component {
  state = {};

  handleOnClick = (event) => {
    let tab = event.target.textContent.replace(/\s/g, '');
    this.props.onClick(tab);
  };

  render() {
    return (
      <Wrapper>
        <p>From:</p>
        <select id="from" name="from">
          <option value="Rentacomputer.com">user@cfrinc.us</option>
          <option value="ComputerSecurityNow.com">
            user@camerasecurity.now
          </option>
          <option value="ComputerServiceNow.com">user@rentacomputer</option>
        </select>
        <p>To:</p>
        <input type="text" id="recipient" name="recipient"></input>
        <p>CC:</p>
        <input type="text" id="cc" name="cc"></input>
        <p>BCC:</p>
        <input type="text" id="bcc" name="bcc"></input>
        <p>Subject:</p>
        <input type="text" id="subject" name="subject"></input>
        <p>Body:</p>
        <textarea id="body" name="body" rows="16" cols="50"></textarea>
      </Wrapper>
    );
  }
}
