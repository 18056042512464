import styled from 'styled-components';

export const Wrapper = styled.section``;

export const BodyContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-bottom: 8em;

  h1 {
    font-size: 1.1rem;
    margin: 1em 0.5em 0em 0.5em;
    text-align: center;
    width: 80%;
  }

  h2 {
    font-size: 1rem;
    margin: 1em 0.5em 0.5em 0.5em;
    text-align: left;
    width: 80%;
  }
`;

export const DashboardLinksWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 1em auto;
  border-bottom: solid 1px #c3d1da;
  padding: 0.5em 0 1em 0;

  h2 {
    font-size: 1rem;
    margin: 0em auto 0.5em auto;
    text-align: left;
    width: 80%;
    cursor: pointer;

    &:hover {
      color: #666;
    }
  }

  li {
    width: 100%;
    display: flex;
    list-style-type: none;
    font-weight: 400;
    align-self: center;
    display: inline-block;
    padding: 0.6em 1em;
    transition: 0.3s;
    padding-left: 2.5em;

    &:hover {
      background-color: #d6ecfb;
    }
  }
`;

export const SideBarLink = styled.a`
  width: 95%;
  padding: 0.2em;
  margin: 0.2em 0.4em 0.2em 0;
  position: relative;
  border-radius: 5px;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  background-color: #fff;

  &:hover {
    color: #222;
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }

  p {
    padding: 0.3em 0.5em;
    text-align: left;
  }
`;

export const BottomControls = styled.div`
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 40px;
  width: inherit;
  left: 0;
  bottom: 0;
  background-color: #ebeef0;
`;

export const FollowupsWrapper = styled.div``;