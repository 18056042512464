import React, { Component } from 'react'
import UnderwritingFlag from './UnderwritingFlag'
import { whichRep } from '../../../assets/functions/calculations'
import moment from 'moment'

export default class UnderwritingHeader extends Component {
  render() {
    return (
        <>
    <table>
        <tr>
            <td>                        
                {/*<div>
                    Age: {this.props.data.dtSubmitted.fromNow()}
                </div>
                <div>
                    Sales Rep: {whichRep(this.props.data.nRepRequest)}
                </div>
                <div>
                    Underwriting Rep: {whichRep(this.props.data.nRepComplete)}
                </div>
                <div>
                    Quote: {this.props.data.nQuote}
                </div>
                <div>
                    Customer: {this.props.quote.sCompany}
                </div>
                </div>*/}
            </td>
            <td>
                {this.props.viewing !== 6 && this.props.questions.map((question, index) => (
                    <>
                        <UnderwritingFlag 
                            displayQuestion={true} 
                            question={question}
                            questions={this.props.questions}
                        />
                    </>
                ))}
            </td>
        </tr>
    </table>
    {(this.props.viewing === 0 || this.props.viewing === 1) && (
                <div>
                    <div>Billing Info:</div>
                    <div>{this.props.quote.sCompany}</div>
                    <div>{this.props.quote.sName}</div>
                    <div>{this.props.quote.sAddress}</div>
                    <div>{this.props.quote.sAddress2}</div>
                    <div>{this.props.quote.sCity}, {this.props.quote.sState} {this.props.quote.sZip}</div>
                    <div>{this.props.quote.sPhone}</div>
                    <div>{this.props.quote.sEmail}</div>

                </div>
            )}
            {this.props.viewing === 3 && (
                <div>
                    <div>Delivery Info:</div>
                    <div>{this.props.quote.sDCompany}</div>
                    <div>{this.props.quote.sDName}</div>
                    <div>{this.props.quote.sDAddress}</div>
                    <div>{this.props.quote.sDAddress2}</div>
                    <div>{this.props.quote.sDCity}, {this.props.quote.sDState} {this.props.quote.sDZip}</div>
                    <div>{this.props.quote.sDPhone}</div>
                    <div>Delivery: {moment(this.props.quote.dtDeliveryDate).format("MM/DD/YYYY")} {this.props.quote.nDeliveryType===2 ? this.props.quote.dtDeliveryTime : ""}</div>
                    <div>Pickup: {moment(this.props.quote.dtPickupDate).format("MM/DD/YYYY")} {this.props.quote.nDeliveryType===2 ? this.props.quote.dtPickupTime : ""}</div>
                </div>
            )}
            {this.props.viewing === 4 && (
                <div>
                    <div>Terms: {this.props.quote.bCC ? "Credit Card" : this.props.quote.bNet ? "Net" : ""}</div>
                    <div>Billing Name: {this.props.quote.sName}</div>
                    <div>Delivery Name: {this.props.quote.sDName}</div>
                    <div>Billing Company: {this.props.quote.sCompany}</div>
                    <div>Delivery Company: {this.props.quote.sDCompany}</div>
                    <div>CC Name: {this.props.quote.sCCName}</div>
                    <div>Billing Address: {this.props.quote.sAddress}{this.props.quote.sAddress2 ? <br />: ""}{this.props.quote.sAddress2}</div>
                    <div>Delivery Address: {this.props.quote.sDAddress}{this.props.quote.sDAddress2 ? <br />: ""}{this.props.quote.sDAddress2}</div>
                    <div>CC Address: {this.props.quote.sCCAddress}</div>
                    <div>CC Expiration: {this.props.quote.sCCExDate}</div>
                </div>
            )}
            {this.props.viewing === 5 && (
                <div>
                    <table>
                        <tr>
                            <td style={{width: "50%",verticalAlign:"top", borderRight: '1px solid black'}}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Quantity</th>
                                            <th>Description</th>
                                            <th>Price</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.props.lines.map((line,index)=>(
                                        <tr style={{backgroundColor: (index)%2===0 ? "#dddddd" : "#aaaaaa"}}>
                                            <td>{line.nQuantity}</td>
                                            <td>{line.sDescription}</td>
                                            <td>{line.nPrice}</td>
                                            <td>{line.nQuantity*line.nPrice}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </td>
                            <td style={{width: "50%",verticalAlign:"top"}}>
                            <table>
                                    <thead>
                                        <tr>
                                            <th>Quantity</th>
                                            <th>Description</th>
                                            <th>Price</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.props.POs.map((PO,index)=>(
                                        <>
                                        <tr>
                                            <td colSpan={4} style={{textAlign: "center"}}>
                                            <b>{PO.sDisplayId} {PO.sCompany} {PO.dtDeliveryDate} - {PO.dtPickupDate}</b>
                                            </td>
                                        </tr>
                                        {PO.lines.map((line, index2) => (
                                            <tr style={{backgroundColor: (index2)%2===0 ? "#dddddd" : "#aaaaaa"}}>
                                                <td>{line.nQuantity}</td>
                                                <td>{line.sDescription}</td>
                                                <td>{line.nPrice}</td>
                                                <td>{line.nQuantity*line.nPrice}</td>
                                            </tr>
                                        ))}
                                        
                                        </>
                                    ))}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </table>
                </div>
            )}
    </>
    
    )
  }
}
