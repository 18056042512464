import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: #fff;
  padding: 1em 2em;
  margin: 0 auto;
  width: 100%;
  height: 71px;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.1);
  text-align: center;
  border-radius: 15px;
`;

const Button = styled.button`
   padding: 0.5em 1.2em;
   text-decoration: none;
   color: #c3d1dc;
   text-align: center;
   transition: all 0.2s;
  padding: 0.5em 2em;
  border: 3px #f2f5f7 solid;
  border-radius: 50px;
  background-color: #fff;
  font-weight: 500;

  &:nth-child(1) {
    color: #fff;
    border: 3px #a7d961 solid;
    background-color: #a7d961;
    cursor: pointer;
  }

  &:nth-child(6) {
    &:hover {

    }
  }
`;

export default class PoControls extends Component {
  render() {
    return (
      <Wrapper>
        <Button>New</Button>
        <Button>Save & Push to Quote</Button>
        <Button>Save As New</Button>
        <Button>Save & Print</Button>
        <Button>Save</Button>
        <Button>Delete</Button>
      </Wrapper>
    );
  }
}
