import { Component } from "react";
import { displayFollowupCount } from "../../assets/functions/followups";
import FollowupItem from "./FollowupItem";
import { attentionNoteColor, displayNotesCount } from "../../assets/functions/notes";
import { SideBarLink } from "../styled/nav";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

class NotesTile extends Component {
    state = {
      showNotes: false,
    }

    render() {

        return(
          <>
          {this.props.notes && this.props.notes.length > 0 && (
              <>
                <h2
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.setState({showNotes: !this.state.showNotes,})}
                >
                  Notes ({displayNotesCount(this.props.notes)})
                  <i
                    style={{ float: 'right' }}
                    className="fas fa-chevron-down"
                  ></i>
                </h2>
                {this.state.showNotes ? (
                  <>
                    {this.props.notes.map((sql) => (
                      <SideBarLink
                        style={{
                          backgroundColor: attentionNoteColor(
                            sql.nAttentionPriority
                          ),
                        }}
                      >
                        <Link to={`/quote/${sql.nQuote}`}>
                          <p>
                            <i className="fas fa-calendar-day"></i>{' '}
                            {sql.dtTimeStamp.slice(0, -14)}
                          </p>
                          <p>
                            <i className="fas fa-briefcase"></i> {sql.sCompany}
                          </p>
                          <p>
                            <i className="fas fa-user"></i> {sql.sName}
                          </p>
                          <p>
                            <i className="fas fa-sticky-note"></i>{' '}
                            {sql.sNote.slice(0, 200)}
                          </p>
                        </Link>
                      </SideBarLink>
                    ))}
                  </>
                ) : null}
              </>
            )}
           </>
        )
    }
}

export default NotesTile;