import React, { Component } from 'react';
import { getSQLData } from '../../assets/functions/fetch';

class SendEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loaded: false,
          to: "",
          from: "",
          cc: "",
          bcc: "",
          subject: "",
          body: "",
        };
    
      }
    

    componentDidMount(){
        const queryString = window.location.hash.includes('?') ? window.location.hash.split('?')[1] : '';
        const queryParams = new URLSearchParams(queryString);
        this.setState({
            to: queryParams.get("to"),
            from: queryParams.get("from"),
            cc: queryParams.get("cc"),
            bcc: queryParams.get("bcc"),
            subject: queryParams.get("subject"),
            body: queryParams.get("body"),
            quote: queryParams.get("quote"),
        }, ()=>this.fetchData())
    }

    fetchData = async() => {
        try {
            const fetchCanned = await getSQLData("email/cannedResponses", {nRep: 45})
            const fetchGlobal = await getSQLData("email/globalAttachments", {})
            const fetchAttachments = await getSQLData("email/quoteAttachments", {nQuote: 1234567})

            if(fetchCanned && fetchGlobal && fetchAttachments){
                let cannedResponses = fetchCanned.data.data[0].recordset 
                let globalAttachments = fetchGlobal.data.data[0].recordset 
                let quoteAttachments = fetchAttachments.data.data[0].recordset 

                this.setState({
                    cannedResponses: cannedResponses,
                    globalAttachments: globalAttachments,
                    quoteAttachments: quoteAttachments,
                    loaded: true,
                }, () => this.processData)
            }


        } catch {

        }
    }

    processData() {
        let cannedResponses = this.state.cannedResponses
        let globalAttachments = this.state.globalAttachments
        let quoteAttachments = this.state.quoteAttachments

        let responseTypes = []

        for(let i=0; i<cannedResponses.length; i++){
            if(cannedResponses[i].sType && responseTypes.includes(cannedResponses[i].sType)){
                responseTypes.push(cannedResponses[i].sType)
            }
        }

        this.setState({
            responseTypes: responseTypes,
            loaded: true,
        })
    }

    render() {
        
        return (
            <>
            {this.state.loaded && (


            <div style={{position: 'absolute', top: '0px', left: '0px'}}>
                <div style={{display:'inline-block', verticalAlign: 'top', alignContent: 'left'}}>
                <div>
                    <table>
                        <tr>
                            <td>From:</td>
                            <td><input value={this.state.from} style={{width:'300px'}}></input></td>
                        </tr>
                        <tr>
                            <td>To:</td>
                            <td><input value={this.state.to} style={{width:'300px'}}></input></td>
                        </tr>
                        <tr>
                            <td>CC:</td>
                            <td><input style={{width:'300px'}}></input></td>
                        </tr>
                        <tr>
                            <td>BCC:</td>
                            <td><input style={{width:'300px'}}></input></td>
                        </tr>
                        <tr>
                            <td>Subject:</td>
                            <td><input style={{width:'300px'}}></input></td>
                        </tr>
                    </table>
                </div>
                <div>
                    <textarea style={{width: "750px", height: "250px"}}>
                        
                    </textarea>
                </div>
                <div>
                    <button>Send Email</button>
                    <button>Cancel</button>
                    <button>Clear</button>
                </div>
                <div>
                    <input type='checkbox'></input>Copy Myself On This Email
                </div>
                </div>
                <div style={{display:'inline-block', width: '300px'}}>
                    <div>
                        <b>Quote Attachment Options</b>
                    </div>
                    <div>
                        <b>Global Attachment Options</b>
                    </div>
                    <div>
                        <input type="checkbox"></input>Example
                    </div>
                    <div>
                        <b>Canned Response</b> 
                        <div onClick={()=>{
                window.open(`http://localhost:5000/#/canned-responses`, "_blank")
              }}>
                (edit)
            </div>
                    </div>
                    {this.state.cannedResponses.map((email, index)=>(
                        <div>
                            <a>{email.sName}</a>
                        </div>
                    ))}
                </div>
            </div>
                        )}
            </>
        );
    }
}

export default SendEmail;