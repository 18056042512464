import React, { Component } from 'react'
import { getSQLData, setSQLData } from '../../assets/functions/fetch';
import { nQuoteTotalNoDollar } from '../../assets/functions/calculations';
import moment from 'moment';
import RepCommissions from '../tables/accounting-dashboard/RepCommissions';
import LogMismatch from '../tables/accounting-dashboard/LogMismatch';
import { Wrapper } from '../styled/ui';
import ClosedNotOnLog from '../tables/accounting-dashboard/ClosedNotOnLog';
import LoggedNotClosed from '../tables/accounting-dashboard/LoggedNotClosed';
import LogLookup from '../tables/accounting-dashboard/LogLookup';
import UpdateCommissions from '../tables/accounting-dashboard/UpdateCommissions';
import NeedsInvoiced from '../tables/accounting-dashboard/NeedsInvoiced';
import FRPPoints from '../tables/accounting-dashboard/FRPPoints';
import UnderwritingTool from '../tables/underwiting-dashboard/UnderwritingTool';
import DiscontinuedQuotes from '../tables/underwiting-dashboard/DiscontinuedQuotes';

export default class UnderwritingDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      viewing: "",
    };

  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {

    try {
      
        
      

      
         
    } catch (error) {
      console.error(error);
    }
  }
  
  processData(){

  }


  render() {
    return (
      <Wrapper>
       

        {this.state.viewing === "" && (
            <>
              <input onChange={(event)=>this.setState({quoteID: event.target.value})} value={this.state.quoteID} ></input>
              <button onClick={()=>this.setState({viewing: "Underwriting Tool"})}>Get Data</button>
              <div>
                <button onClick={()=>this.setState({viewing: "Discontinued Quote Review"})}>Discontinued Quote Review</button>
              </div>
              
            </>
        )}
        
        {this.state.viewing === "Underwriting Tool" && (<UnderwritingTool user={this.props.user} quoteID={this.state.quoteID} />)}
        {this.state.viewing === "Discontinued Quote Review" && (<DiscontinuedQuotes />)}


      </Wrapper>
    )
  }
}
