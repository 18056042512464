import React, { Component } from 'react';
import styled from 'styled-components';
import Moment from 'moment';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin: 1em auto 0.5em auto;
  width: 100%;
  height: fit-content;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.1);
  text-align: center;
  border-radius: 15px;

  select {
    width: 70%;
    padding: 0.7em;
    margin: 0.5em auto;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  input {
    width: 70%;
    padding: 0.7em;
    margin: 0.5em auto;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4aabed;
  color: #fff;
  height: 35px;
  font-weight: bold;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  div {
    width: 100%;
    margin: 0.5em;
  }

  p {
    font-weight: bold;
    text-align: left;
    margin: 0.5em 0 0 15%;
  }

  h3 {
    font-size: 1.3rem;
    margin-bottom: 1em;
  }

  i {
    font-size: 1rem;
    ${(props) => {
      if (props.sStatus === 'Open')
        return `
            color: #82c91e;
        `;
      if (props.sStatus === 'Closed')
        return `
            color: red;
        `;
      if (props.sStatus === 'Discontinued')
        return `
            color: #999;
        `;
    }}
  }
`;

export default class LogisticOverview extends Component {
  state = { sent: false, confirmed: false };

  render() {
    let deliveryDate;
    let pickupDate;
    let status;

    // Checks to see if the delivery date is from 1995 and up
    if (Moment(this.props.dtDeliveryDate).utc().format('YYYY') > 1995) {
      deliveryDate = Moment(this.props.dtDeliveryDate)
        .utc()
        .format('YYYY-MM-DD');
    } else {
      deliveryDate = null;
    }

    // Checks to see if the pickup date is from 1995 and up
    if (Moment(this.props.dtPickupDate).utc().format('YYYY') > 1995) {
      pickupDate = Moment(this.props.dtPickupDate).utc().format('YYYY-MM-DD');
    } else {
      pickupDate = null;
    }

    // Checks order status and delivery date to determine what message to display
    if (this.props.sStatus === 'Open') {
      status = 'This order is open';
    } else if (this.props.sStatus === 'Closed') {
      status = 'This order is closed';
    } else if (this.props.sStatus === 'Discontinued') {
      status = 'This order was discontinued';
    } else if (this.props.sStatus != 'Closed' && deliveryDate === null) {
      status = 'This order has not shipped';
    }

    return (
      <Wrapper>
        <Title>Logistic Overview</Title>
        <InputContainer sStatus={this.props.sStatus}>
          <div>
            <div>
              <h3>
                <i class="fas fa-circle"></i> {status}
              </h3>
            </div>
            <div>
              <p>Contract Type:</p>
              <select defaultValue={this.props.nContractType}>
                <option value="0" hidden>
                  Select Contract Type
                </option>
                <option value="1">Fixed Rental</option>
                <option value="2">Open Rental</option>
                <option value="3">Non-Rental</option>
              </select>
            </div>

            <div>
              <p>Delivery Type:</p>
              <select defaultValue={this.props.nDeliveryType}>
                <option value="0" hidden>
                  Select Delivery Type
                </option>
                <option value="1">Delivery</option>
                <option value="2">Shipping</option>
              </select>
            </div>
          </div>
          <div>
            <div>
              <p>Delivery Date:</p>
              <input type="date" value={deliveryDate} />
            </div>
            <div>
              <p>Pickup Date:</p>
              <input type="date" value={pickupDate} />
            </div>
            <div>
              <p>Estimated Days in Transit:</p>
              <input type="text" value="" />
            </div>
          </div>
        </InputContainer>
      </Wrapper>
    );
  }
}
