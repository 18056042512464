import React, { Component } from 'react';
import styled from 'styled-components';
import Moment from 'moment';
import { thisYearStart, dayChangeEnd } from '../../assets/functions/time';
import OpenInstallsBJK from '../tables/cam-operations/OpenInstallsBJK';
import RecentlyCompleted from '../tables/cam-operations/RecentlyCompleted';
import SiteVisitsBJK from '../tables/cam-operations/SiteVisitsBJK';
import LikelyToClose from '../tables/cam-operations/LikelyToClose';
import OpenIssues from '../tables/cam-operations/OpenIssues';
import NoSiteVisits from '../tables/cam-operations/NoSiteVisits';
import TechDashboard from '../tables/cam-operations/TechDashboard';
import CoordinationDashboard from '../tables/cam-operations/CoordinationDashboard';
import SiteVisitsv2 from '../tables/cam-operations/SiteVisitsv2';
import TechDashboardv2 from '../tables/cam-operations/TechDashboard/TechDashboardv2';

const Wrapper = styled.section`
  text-align: center;
  padding-bottom: 2em;

  @media (max-width: 900px) {
    padding-bottom: 5em;
  }
`;

const DashboardControls = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin: 3em auto 1em auto;
  padding: 1em 4em;
  width: fit-content;
  height: fit-content;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.1);
  text-align: center;
  border-radius: 15px;
`;

const Title = styled.h1`
  padding-top: 1em;
  font-size: 2rem;
`;

const Time = styled.h1`
  padding: 0.5em 0 2em 0em;
  font-size: 1rem;
`;

const ScrollDiv = styled.div`
  height: 500px;
  overflow-y: auto;
`

export default class CamOperations extends Component {
  state = {
    viewing: "Open Installs"
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Wrapper>
        <DashboardControls>
          <div>
            <Title>CAM Operations</Title>
          </div>
        </DashboardControls>
        <button className='camBtn' onClick={()=>this.setState({viewing: "Open Installs"})} style={{fontWeight: this.state.viewing==="Open Installs" ? "bold" : ""}}>Open Installs</button>
        <button className='camBtn' onClick={()=>this.setState({viewing: "Recently Completed"})} style={{fontWeight: this.state.viewing==="Recently Completed" ? "bold" : ""}}>Recently Completed</button>
        <button className='camBtn' onClick={()=>this.setState({viewing: "Site Visits"})} style={{fontWeight: this.state.viewing==="Site Visits" ? "bold" : ""}}>Site Visits</button>
        <button className='camBtn' onClick={()=>this.setState({viewing: "Open Issues"})} style={{fontWeight: this.state.viewing==="Open Issues" ? "bold" : ""}}>Open Issues</button>
        <button className='camBtn' onClick={()=>this.setState({viewing: "Tech Dashboard"})} style={{fontWeight: this.state.viewing==="Tech Dashboard" ? "bold" : ""}}>Tech Dashboard</button>
        {this.state.viewing === "Open Installs" && (<OpenInstallsBJK />)}
        {this.state.viewing === "Recently Completed" && (<RecentlyCompleted />)}
        {this.state.viewing === "Site Visits" && (<SiteVisitsv2 />)}
        {this.state.viewing === "Open Issues" && (<OpenIssues />)}
        {this.state.viewing === "Tech Dashboard" && (<TechDashboardv2 nRep={this.props.user} />)}
      </Wrapper>
    );
  }
}
