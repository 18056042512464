import React, { Component } from 'react';
import {
  BrowserRouter as Redirect,
  Switch,
  Route,
  Link,
} from 'react-router-dom';
import Axios from 'axios';
import Loader from 'react-loader-spinner';
import styled from 'styled-components';
import Moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { whichRep } from '../../../assets/functions/calculations';

const Wrapper = styled.div`
  width: 99%;
  max-width: 700px;
  margin: 4em auto;

  table {
    width: 100%;
    position: relative;
    ${(props) => {
      if (props.amount > 10)
        return `
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;

    thead + tbody tr:last-child td:last-child,
    tfoot tr:last-child td:last-child {
      border-bottom-right-radius: 0px !important;
    }
    thead + tbody tr:last-child td:first-child,
    tfoot tr:last-child td:first-child {
      border-bottom-left-radius: 0px !important;
    }
    margin-bottom: 7em;
        `;
    }}
  }

  button {
    position: absolute;
    width: 100%;
    bottom: -27px;
     padding: 0.35em 1.2em;
     text-decoration: none;
     color: #fafafa;
     text-align: center;
     transition: all 0.2s;
    background-color: #4aabed;
    border: 1px solid #4aabed;
    cursor: pointer;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    &:hover {
      color: #222;
      background-color: #fafafa;
      border: 1px solid #d7d7d7;
    }
  }
`;

const SubTitle = styled.h2`
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1em;
`;

const AlertMessage = styled.p`
  color: #4aabed;
  font-weight: bold;
`;

export default class OpenQuotesNoNextFollowup extends Component {
  state = {
    data: [],
    loaded: false,
    showAll: false,
  };

  // Async Axios
  fetchData = async () => {
    try {
      const response = await Axios.get(
        '/admin-dashboard/open-quotes-no-next-followup',
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      // Sets the state using the response data
      this.setState({
        data: response.data.result[0].recordset,
        loaded: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    this.fetchData();
  }

  // Handles sending notes to users
  handleClick(nRep, id, table) {
    if (table === 1) {
      Axios({
        method: 'post',
        url: '/note-rep',
        data: {
          nQuote: id,
          sNote: 'This quote is still in Unknown status',
          nAttention: nRep,
        },
      });
    } else if (table === 2) {
      Axios({
        method: 'post',
        url: '/note-rep',
        data: {
          nQuote: id,
          sNote: 'This quote has no next follow up',
          nAttention: nRep,
        },
      });
    } else {
      Axios({
        method: 'post',
        url: '/note-rep',
        data: {
          nQuote: id,
          sNote: 'This quote has a late follow up',
          nAttention: nRep,
        },
      });
    }
    this.setState({
      [`flashMessage + ${id}`]: 'Note sent!',
    });
    this.interval = setInterval(
      () =>
        this.setState({
          [`flashMessage + ${id}`]: '',
        }),
      3000
    );
  }

  render() {
    let table;

    // If the state showAll is false then table with return with only 10 rows, else show all 100
    if (this.state.showAll === false) {
      table = (
        <>
          {this.state.data.slice(0, 10).map((sql) => (
            <tr key={sql.id}>
              <td>
                <Link target="_blank" to={`/quote/${sql.id}`}>
                  {sql.id}
                </Link>
              </td>
              <td>
                <Link
                  target="_blank"
                  to={`/crm/master-viewer/${sql.sCompany.replace(
                    /\//g,
                    '%2F'
                  )}`}
                >
                  {sql.sCompany}
                </Link>
              </td>
              {sql.dtDateStamp ? (
                <td style={{ textAlign: 'center' }}>
                  {Moment(sql.dtDateStamp).utc().format('MM/DD/YYYY - hh:mm A')}
                </td>
              ) : (
                <td>{null}</td>
              )}
              {sql.nRep ? (
                <td
                  style={{ cursor: 'pointer', textAlign: 'center' }}
                  onClick={() => this.handleClick(sql.nRep, sql.id, 2)}
                  data-tip={'Send Note: "This quote has no next followup"'}
                >
                  <ReactTooltip effect={'solid'} />
                  {this.state[`flashMessage + ${sql.id}`] ? (
                    <AlertMessage>
                      {this.state[`flashMessage + ${sql.id}`]}
                    </AlertMessage>
                  ) : (
                    <>{whichRep(sql.nRep)}</>
                  )}
                </td>
              ) : (
                <td></td>
              )}
            </tr>
          ))}
        </>
      );
    } else {
      table = (
        <>
          {this.state.data.map((sql) => (
            <tr key={sql.id}>
              <td>
                <Link target="_blank" to={`/quote/${sql.id}`}>
                  {sql.id}
                </Link>
              </td>
              <td>
                <Link
                  target="_blank"
                  to={`/crm/master-viewer/${sql.sCompany.replace(
                    /\//g,
                    '%2F'
                  )}`}
                >
                  {sql.sCompany}
                </Link>
              </td>
              {sql.dtDateStamp ? (
                <td style={{ textAlign: 'center' }}>
                  {Moment(sql.dtDateStamp).utc().format('MM/DD/YYYY - hh:mm A')}
                </td>
              ) : (
                <td>{null}</td>
              )}
              {sql.nRep ? (
                <td
                  style={{ cursor: 'pointer', textAlign: 'center' }}
                  onClick={() => this.handleClick(sql.nRep, sql.id, 2)}
                  data-tip={'Send Note: "This quote has no next followup"'}
                >
                  <ReactTooltip effect={'solid'} />
                  {this.state[`flashMessage + ${sql.id}`] ? (
                    <AlertMessage>
                      {this.state[`flashMessage + ${sql.id}`]}
                    </AlertMessage>
                  ) : (
                    <>{whichRep(sql.nRep)}</>
                  )}
                </td>
              ) : (
                <td></td>
              )}
            </tr>
          ))}
        </>
      );
    }

    return (
      <Wrapper amount={this.state.data.length}>
        <SubTitle>Open Quotes with no Next Follow Up</SubTitle>
        <table class="dashboard">
          <thead>
            <tr>
              <th>Quote</th>
              <th>Company</th>
              <th>Submitted Date</th>
              <th>Rep</th>
            </tr>
          </thead>
          <tbody>
            {this.state.loaded || (
              <tr>
                <td colSpan="4">
                  <Loader
                    style={{
                      textAlign: 'center',
                    }}
                    type="ThreeDots"
                    color="#00BFFF"
                    height={20}
                    width={120}
                  />
                </td>
              </tr>
            )}
            {this.state.loaded && (
              <>
                {this.state.data.length > 0 ? (
                  <>{table}</>
                ) : (
                  <tr>
                    <td
                      colSpan="1"
                      style={{ textAlign: 'center', padding: '1em' }}
                    >
                      No quotes
                    </td>
                  </tr>
                )}
                {this.state.data.length > 10 ? (
                  <>
                    {this.state.showAll ? (
                      <button
                        onClick={() => {
                          this.setState({
                            showAll: false,
                          });
                        }}
                      >
                        Hide
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          this.setState({
                            showAll: true,
                          });
                        }}
                      >
                        Show All
                      </button>
                    )}
                  </>
                ) : null}
              </>
            )}
          </tbody>
        </table>
      </Wrapper>
    );
  }
}
