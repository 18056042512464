import React, { Component } from 'react';
import styled from 'styled-components';
import Moment from 'moment';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #fff;
  margin: auto;
  padding: 1em 3em;
  width: 100%;
  height: fit-content;
  text-align: left;
  border: 1px solid #e8edf1;

  span {
    font-weight: bold;
  }
`;

export default class LogItem extends Component {
  render() {
    let name;

    // If the name from the log is 0, use the name "system"
    if (this.props.name != 0) {
      name = this.props.name;
    } else {
      name = 'System';
    }

    return (
      <Wrapper>
        <p>
          <span>
            {Moment(this.props.date).utc().format('L')}
            {' - '}
            {Moment(this.props.date).utc().format('LT')} - {name}
          </span>{' '}
          - {this.props.action}
        </p>
      </Wrapper>
    );
  }
}
