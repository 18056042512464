import React, { Component } from 'react';
import { nQuoteTotalNoDollar } from '../../../../assets/functions/calculations';


export default class CoordinationHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
          showChart: false,
        };
      }

  render() {
    
    return (
        <>
            <div>Open Jobs: {this.props.data.length} /  
            Margin: {nQuoteTotalNoDollar(this.props.chartData.marginTotal)} / 
            Labor: {nQuoteTotalNoDollar(this.props.chartData.laborTotal)}</div>
        </>
    );
  }
}