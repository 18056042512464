import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { nQuoteTotalNoDollar, whichRep } from '../../../../assets/functions/calculations';
import moment from 'moment';
import { CustomDateWrapper, Input, SubmitText, GreyTextDiv, Title } from '../../../styled/ui';
import { numberOfDays } from '../../../../assets/functions/display';



export default class SiteVisitQuoteDiscontinuedBJK extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    
    return (
      <>
      <Title>Site Visit Complete, Quote Sent, Discontinued</Title>

          {this.props.data.length > 0 && (
              <div>
                {this.props.data.map((sql, index) => (
                  <table key={sql.id} style={{border: '1px solid black', borderRadius: '5px 5px 5px 5px'}}>
                    <tr>
                      <td style={{verticalAlign: 'top', textAlign:'left', width: '300px'}}>
                        <div style={{fontWeight: 'bold', fontSize: 16}}><Link target="_blank" to={`/quote/${sql.id}`}>Quote {sql.id} <br /> {sql.sQuoteTitle}</Link></div>
                        <div>{sql.sCompany}</div>
                        <div>{sql.sCity}, {sql.sState} {sql.sZip}</div>
                        <div>{sql.sName}</div>
                      </td>
                      <td style={{verticalAlign: 'top', textAlign:'left', width: '300px'}}>
                      <div><b>Price</b>: {nQuoteTotalNoDollar(sql.nQuoteTotal)}</div>
                      <div><b>Age</b>: {numberOfDays(sql.Age)} old</div>
                      <div><b>Rep</b>: {sql.nRep ? whichRep(sql.nRep) : null}</div>           
                    </td>         
                    <td style={{verticalAlign: 'top', textAlign:'left', width: '300px'}}>
                      <div>
                      <b>Site Visit Planned</b>: 
                        <CustomDateWrapper><Input onChange={(e) => {this.props.handleDateChange(e, sql.index, 'dtSiteVisitPlanned')}} type="date" id="planned" name="planned" 
                          value={moment(sql.dtSiteVisitPlanned).utc().format('YYYY-MM-DD')} /></CustomDateWrapper>
                        <SubmitText onClick={() => this.props.handleDateSubmit(sql.dtSiteVisitPlanned,sql.id,"dtSiteVisitPlanned")}>Submit</SubmitText>
                      </div>
                    </td>
                    <td style={{verticalAlign: 'top', textAlign:'left', width: '300px'}}>
                      <div>
                      <b>Site Visit Date</b>: 
                        <CustomDateWrapper><Input onChange={(e) => {this.props.handleDateChange(e, sql.index, 'dtSiteVisit')}} type="date" id="actual" name="actual"
                          value={moment(sql.dtSiteVisit).utc().format('YYYY-MM-DD')} />
                        </CustomDateWrapper>
                        <SubmitText onClick={() => this.props.handleDateSubmit(sql.dtSiteVisit,sql.id,"dtSiteVisit")}>Submit</SubmitText>
                      </div>
                    </td>
                    <td style={{verticalAlign: 'top', textAlign:'left', width: '300px'}}>
                      <div>
                      <b>Quote Sent Date</b>: 
                        <CustomDateWrapper><Input onChange={(e) => {this.props.handleDateChange(e, sql.index, 'dtSentDate')}} type="date" id="sent" name="sent"
                          value={moment(sql.dtSentDate).utc().format('YYYY-MM-DD')} />
                        </CustomDateWrapper>
                        <SubmitText onClick={() => this.props.handleDateSubmit(sql.dtSentDate,sql.id,"dtSentDate")}>Submit</SubmitText>
                      </div>
                    </td>
                    </tr>
                    <tr>
                    <td colSpan={3} style={{verticalAlign: 'top', textAlign:'left'}}>
                        <div><b>Notes</b></div>
                        {sql.notes.map((note, index)=>(
                          <>
                          {index < 6 && (
                            <div style={{margin: '10px'}}>
                              <div><b>{whichRep(note.nRep)} {moment(note.dtTimeStamp).utc().format('hh:mm A MM/DD/YYYY')}</b></div>
                              <div>{note.sNote}</div>
                            </div>
                          )}
                          
                          </>
                        ))}
                    </td>        
                    <td colSpan={2} style={{verticalAlign: 'top', textAlign:'left', width: '300px'}}>
                      <div><b>Meeting Notes</b></div>
                      <textarea style={{height: '80px', width: '100%'}} onInput={(event) => this.props.handleUpdateTag(event.target.value, index)} value={sql.sQuoteTags}></textarea>
                      {sql.changed && (<div onClick={() => this.props.handleSaveTag(sql.sQuoteTags, sql.id)}>Save Tag</div>)}
                    </td>
                    </tr>
                  {sql.showPOs && (
                    <tr>
                    <td colSpan={"100%"}>
                    <div>
                      <table class="dashboard">
                        <thead>
                          <tr>
                            <th>Vendor</th>
                            <th>Description</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Total Price</th>
                            <th>Item</th>
                          </tr>
                        </thead>
                        {sql.POLines && sql.POLines.map((line, lineNumber) => (
                          <tr key={lineNumber}>
                            <td>{line.sCompany}</td>
                            <td>{line.sDescription}</td>
                            <td>{line.nQuantity}</td>
                            <td>{nQuoteTotalNoDollar(line.nPrice)}</td>
                            <td>{nQuoteTotalNoDollar(line.nQuantity*line.nPrice)}</td>
                            <td onClick={()=>this.props.handleSetLabor(sql.index, lineNumber)}>{line.nItem === 832 && (
                              <>CSN Labor</>
                            ) || (
                              <GreyTextDiv>Set as CSN Labor</GreyTextDiv>
                            )}</td>
                          </tr>
                        ))}
                      </table>
                    </div>
                    </td>
                    </tr>
                  )}
                  
                  </table>
                ))}
                
              </div>
          )}

        </>
    );
  }
}