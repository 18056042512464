import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Loader from 'react-loader-spinner';
import styled from 'styled-components';
import Moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { dateFormat, formatMStoDays, whichRep } from '../../../assets/functions/calculations';

const Wrapper = styled.div`
  width: 99%;
  max-width: 700px;
  margin: 4em auto;

  table {
    width: 100%;
    position: relative;
    ${(props) => {
      if (props.amount > 10)
        return `
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;

    thead + tbody tr:last-child td:last-child,
    tfoot tr:last-child td:last-child {
      border-bottom-right-radius: 0px !important;
    }
    thead + tbody tr:last-child td:first-child,
    tfoot tr:last-child td:first-child {
      border-bottom-left-radius: 0px !important;
    }
    margin-bottom: 7em;
        `;
    }}
  }

  button {
    position: absolute;
    width: 100%;
    bottom: -27px;
     padding: 0.35em 1.2em;
     text-decoration: none;
     color: #fafafa;
     text-align: center;
     transition: all 0.2s;
    background-color: #4aabed;
    border: 1px solid #4aabed;
    cursor: pointer;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    &:hover {
      color: #222;
      background-color: #fafafa;
      border: 1px solid #d7d7d7;
    }
  }
`;

const SubTitle = styled.h2`
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1em;
`;

const AlertMessage = styled.p`
  color: #4aabed;
  font-weight: bold;
`;

export default class RFQPORate extends Component {
  state = {
    data: [],
    loaded: false,
    showAll: false,
  };

  // Async Axios
  fetchData = async () => {
    try {
      const response = await Axios.get('/srm/rfq-po', {
        headers: { 'Content-Type': 'application/json' },
      });
      // Sets the state using the response data
      this.setState({
        data: response.data.result[0].recordset,
        loaded: true,
      }, () => this.prepareData());

      console.log(response.data.result[0].recordset)
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    this.fetchData();
  }

  compareRate = ( a, b ) => {
    if ( a.rate < b.rate ){
      return -1;
    }
    if ( a.rate > b.rate ){
      return 1;
    }
    return 0;
  }

  compareRFQs = ( a, b ) => {
    if ( a.nRFQs < b.nRFQs ){
      return 1;
    }
    if ( a.nRFQs > b.nRFQs ){
      return -1;
    }
    return 0;
  }

  prepareData = () => {
    let data = this.state.data

    for(let i=0; i<this.state.data.length; i++){
      data[i].rate = data[i].nPOs / data[i].nRFQs
    }

    data.sort(this.compareRFQs)
    data.sort(this.compareRate)

    this.setState({data: data})
  }

  render() {
    let table;

    // If the state showAll is false then table with return with only 10 rows, else show all 100
    if (this.state.showAll === false) {
      table = (
        <>
          {this.state.data.slice(0, 10).map((sql) => (
            <tr key={sql.id}>
              <td style={{ textAlign: 'center' }}>
                <Link target="_blank" to={`/srm/master-viewer/${sql.id}`}>
                  {sql.sSupplier}
                </Link>
              </td>

              <td style={{ textAlign: 'center' }}>
                {sql.nRFQs}
              </td>
              <td style={{ textAlign: 'center' }}>
                {sql.nPOs}
              </td>
              <td style={{ textAlign: 'center' }}>
                {dateFormat(sql.dtMostRecent)}
              </td>
              <td style={{ textAlign: 'center' }}>
                {Math.round(sql.rate*1000)/10}%
              </td>
            </tr>
          ))}
        </>
      );
    } else {
      table = (
        <>
          {this.state.data.map((sql) => (
            <tr key={sql.id}>
            <td style={{ textAlign: 'center' }}>
              <Link target="_blank" to={`/srm/master-viewer/${sql.id}`}>
                {sql.sSupplier}
              </Link>
            </td>

            <td style={{ textAlign: 'center' }}>
                {sql.nRFQs}
              </td>
              <td style={{ textAlign: 'center' }}>
                {sql.nPOs}
              </td>
              <td style={{ textAlign: 'center' }}>
                {dateFormat(sql.dtMostRecent)}
              </td>

              <td style={{ textAlign: 'center' }}>
                {Math.round(sql.rate*1000)/10}%
              </td>
            </tr>
          ))}
        </>
      );
    }

    return (
      <Wrapper amount={this.state.data.length}>
        <SubTitle data-tip="Hit rate = (RFQs sent to the vendor) / (Closed POs with the vendor) over the last year">Suppliers with the Worst Hit Rate<ReactTooltip /></SubTitle>
        <table class="dashboard">
          <thead>
            <tr>
              <th>Vendor</th>
              <th>RFQs</th>
              <th>POs</th>
              <th>Most Recent RFQ</th>
              <th data-tip="Hit rate = (RFQs sent to the vendor) / (Closed POs with the vendor) over the last year">Hit Rate<ReactTooltip /></th>
            </tr>
          </thead>
          <tbody>
            {this.state.loaded || (
              <tr>
                <td colSpan="3">
                  <Loader
                    style={{
                      textAlign: 'center',
                    }}
                    type="ThreeDots"
                    color="#00BFFF"
                    height={20}
                    width={120}
                  />
                </td>
              </tr>
            )}
            {this.state.loaded && (
              <>
                {this.state.data.length > 0 ? (
                  <>{table}</>
                ) : (
                  <tr>
                    <td
                      colSpan="3"
                      style={{ textAlign: 'center', padding: '1em' }}
                    >
                      No issues
                    </td>
                  </tr>
                )}
                {this.state.data.length > 10 ? (
                  <>
                    {this.state.showAll ? (
                      <button
                        onClick={() => {
                          this.setState({
                            showAll: false,
                          });
                        }}
                      >
                        Hide
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          this.setState({
                            showAll: true,
                          });
                        }}
                      >
                        Show All
                      </button>
                    )}
                  </>
                ) : null}
              </>
            )}
          </tbody>
        </table>
      </Wrapper>
    );
  }
}
