import React, { Component } from 'react';
import Axios from 'axios';
import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import Moment from 'moment';

const Wrapper = styled.div`
  width: 99%;
  max-width: 700px;
  margin: 4em auto;

  table {
    width: 100%;
  }
`;

const SubTitle = styled.h2`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1em;

  i {
    font-size: 16px;
    margin-left: 7px;
  }
`;

class LeadPickupMetrics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      overnightRacData: [],
      yesterdayRacData: [],
      averageRacData: [],
      overnightCamData: [],
      yesterdayCamData: [],
      averageCamData: [],
      overnightCsnData: [],
      yesterdayCsnData: [],
      averageCsnData: [],
      loaded: false,
    };
  }

  // Async Axios
  fetchData = async () => {
    try {
      let date = new Date();
      let dayOfWeek = date.getDay();

      if (dayOfWeek === 1) {
        const racData = await Axios.get(
          '/admin-dashboard/lead-pickup-metrics',
          {
            params: {
              sQuoteType: 'Rentacomputer.com',
              startDate: Moment()
                .set({ hour: 19, minute: 0, second: 0, millisecond: 0 })
                .subtract(4, 'days')
                .format('YYYY-MM-DD HH:mm:ss'),
              endDate: Moment()
                .set({ hour: 8, minute: 0, second: 0, millisecond: 0 })
                .format('YYYY-MM-DD HH:mm:ss'),
              yesterdayStartDate: Moment()
                .set({ hour: 8, minute: 0, second: 0, millisecond: 0 })
                .subtract(4, 'days')
                .format('YYYY-MM-DD HH:mm:ss'),
              yesterdayEndDate: Moment()
                .set({ hour: 19, minute: 0, second: 0, millisecond: 0 })
                .subtract(4, 'days')
                .format('YYYY-MM-DD HH:mm:ss'),
            },
            headers: { 'Content-Type': 'application/json' },
          }
        );
        const camData = await Axios.get(
          '/admin-dashboard/lead-pickup-metrics',
          {
            params: {
              sQuoteType: 'CameraSecurityNow.com',
              startDate: Moment()
                .set({ hour: 19, minute: 0, second: 0, millisecond: 0 })
                .subtract(1, 'days')
                .format('YYYY-MM-DD HH:mm:ss'),
              endDate: Moment()
                .set({ hour: 8, minute: 0, second: 0, millisecond: 0 })
                .format('YYYY-MM-DD HH:mm:ss'),
              yesterdayStartDate: Moment()
                .set({ hour: 8, minute: 0, second: 0, millisecond: 0 })
                .subtract(1, 'days')
                .format('YYYY-MM-DD HH:mm:ss'),
              yesterdayEndDate: Moment()
                .set({ hour: 19, minute: 0, second: 0, millisecond: 0 })
                .subtract(1, 'days')
                .format('YYYY-MM-DD HH:mm:ss'),
            },
            headers: { 'Content-Type': 'application/json' },
          }
        );
        const csnData = await Axios.get(
          '/admin-dashboard/lead-pickup-metrics',
          {
            params: {
              sQuoteType: 'ComputerServiceNow.com',
              startDate: Moment()
                .set({ hour: 19, minute: 0, second: 0, millisecond: 0 })
                .subtract(1, 'days')
                .format('YYYY-MM-DD HH:mm:ss'),
              endDate: Moment()
                .set({ hour: 8, minute: 0, second: 0, millisecond: 0 })
                .format('YYYY-MM-DD HH:mm:ss'),
              yesterdayStartDate: Moment()
                .set({ hour: 8, minute: 0, second: 0, millisecond: 0 })
                .subtract(1, 'days')
                .format('YYYY-MM-DD HH:mm:ss'),
              yesterdayEndDate: Moment()
                .set({ hour: 19, minute: 0, second: 0, millisecond: 0 })
                .subtract(1, 'days')
                .format('YYYY-MM-DD HH:mm:ss'),
            },
            headers: { 'Content-Type': 'application/json' },
          }
        );
        // Sets the state using the response data
        this.setState({
          overnightRacData: racData.data.result[0].recordset[0],
          yesterdayRacData: racData.data.result[1].recordset[0],
          averageRacData: racData.data.result[2].recordset[0],
          overnightCamData: camData.data.result[0].recordset[0],
          yesterdayCamData: camData.data.result[1].recordset[0],
          averageCamData: camData.data.result[2].recordset[0],
          overnightCsnData: csnData.data.result[0].recordset[0],
          yesterdayCsnData: csnData.data.result[1].recordset[0],
          averageCsnData: csnData.data.result[2].recordset[0],
          loaded: true,
        });
      } else {
        const racData = await Axios.get(
          '/admin-dashboard/lead-pickup-metrics',
          {
            params: {
              sQuoteType: 'Rentacomputer.com',
              startDate: Moment()
                .set({ hour: 19, minute: 0, second: 0, millisecond: 0 })
                .subtract(1, 'days')
                .format('YYYY-MM-DD HH:mm:ss'),
              endDate: Moment()
                .set({ hour: 8, minute: 0, second: 0, millisecond: 0 })
                .format('YYYY-MM-DD HH:mm:ss'),
              yesterdayStartDate: Moment()
                .set({ hour: 8, minute: 0, second: 0, millisecond: 0 })
                .subtract(1, 'days')
                .format('YYYY-MM-DD HH:mm:ss'),
              yesterdayEndDate: Moment()
                .set({ hour: 19, minute: 0, second: 0, millisecond: 0 })
                .subtract(1, 'days')
                .format('YYYY-MM-DD HH:mm:ss'),
            },
            headers: { 'Content-Type': 'application/json' },
          }
        );
        const camData = await Axios.get(
          '/admin-dashboard/lead-pickup-metrics',
          {
            params: {
              sQuoteType: 'CameraSecurityNow.com',
              startDate: Moment()
                .set({ hour: 19, minute: 0, second: 0, millisecond: 0 })
                .subtract(1, 'days')
                .format('YYYY-MM-DD HH:mm:ss'),
              endDate: Moment()
                .set({ hour: 8, minute: 0, second: 0, millisecond: 0 })
                .format('YYYY-MM-DD HH:mm:ss'),
              yesterdayStartDate: Moment()
                .set({ hour: 8, minute: 0, second: 0, millisecond: 0 })
                .subtract(1, 'days')
                .format('YYYY-MM-DD HH:mm:ss'),
              yesterdayEndDate: Moment()
                .set({ hour: 19, minute: 0, second: 0, millisecond: 0 })
                .subtract(1, 'days')
                .format('YYYY-MM-DD HH:mm:ss'),
            },
            headers: { 'Content-Type': 'application/json' },
          }
        );
        const csnData = await Axios.get(
          '/admin-dashboard/lead-pickup-metrics',
          {
            params: {
              sQuoteType: 'ComputerServiceNow.com',
              startDate: Moment()
                .set({ hour: 19, minute: 0, second: 0, millisecond: 0 })
                .subtract(1, 'days')
                .format('YYYY-MM-DD HH:mm:ss'),
              endDate: Moment()
                .set({ hour: 8, minute: 0, second: 0, millisecond: 0 })
                .format('YYYY-MM-DD HH:mm:ss'),
              yesterdayStartDate: Moment()
                .set({ hour: 8, minute: 0, second: 0, millisecond: 0 })
                .subtract(1, 'days')
                .format('YYYY-MM-DD HH:mm:ss'),
              yesterdayEndDate: Moment()
                .set({ hour: 19, minute: 0, second: 0, millisecond: 0 })
                .subtract(1, 'days')
                .format('YYYY-MM-DD HH:mm:ss'),
            },
            headers: { 'Content-Type': 'application/json' },
          }
        );
        // Sets the state using the response data
        this.setState({
          overnightRacData: racData.data.result[0].recordset[0],
          yesterdayRacData: racData.data.result[1].recordset[0],
          averageRacData: racData.data.result[2].recordset[0],
          overnightCamData: camData.data.result[0].recordset[0],
          yesterdayCamData: camData.data.result[1].recordset[0],
          averageCamData: camData.data.result[2].recordset[0],
          overnightCsnData: csnData.data.result[0].recordset[0],
          yesterdayCsnData: csnData.data.result[1].recordset[0],
          averageCsnData: csnData.data.result[2].recordset[0],
          loaded: true,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    this.fetchData();
  }

  render() {
    return (
      <Wrapper>
        <SubTitle>
          Lead Pickup Metrics <i class="far fa-clock"></i>
        </SubTitle>
        <table className="dashboard">
          <thead>
            <tr>
              <th>Type</th>
              <th>RAC</th>
              <th>CAM</th>
              <th>CSN</th>
            </tr>
          </thead>
          <tbody>
            {this.state.loaded || (
              <tr>
                <td colSpan="6">
                  <Loader
                    style={{
                      textAlign: 'center',
                    }}
                    type="ThreeDots"
                    color="#00BFFF"
                    height={80}
                    width={80}
                  />
                </td>
              </tr>
            )}
            {this.state.loaded && (
              <>
                <tr>
                  <td style={{ fontWeight: 'bold' }}># of Overnight Quotes</td>
                  <td style={{ textAlign: 'center' }}>
                    {this.state.overnightRacData.nCount}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {this.state.overnightCamData.nCount}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {this.state.overnightCsnData.nCount}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 'bold' }}>Time to assign</td>
                  <td style={{ textAlign: 'center' }}>
                    {this.state.overnightRacData.dtLastPickup ? (
                      <>
                        {Moment.utc(
                          this.state.overnightRacData.dtLastPickup
                        ).format('MM/DD - hh:mm A')}
                      </>
                    ) : null}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {this.state.overnightCamData.dtLastPickup ? (
                      <>
                        {Moment.utc(
                          this.state.overnightCamData.dtLastPickup
                        ).format('MM/DD - hh:mm A')}
                      </>
                    ) : null}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {this.state.overnightCsnData.dtLastPickup ? (
                      <>
                        {Moment.utc(
                          this.state.overnightCsnData.dtLastPickup
                        ).format('MM/DD - hh:mm A')}
                      </>
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 'bold' }}># of Quotes Yesterday</td>
                  <td style={{ textAlign: 'center' }}>
                    {this.state.yesterdayRacData.nYesterdayCount}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {this.state.yesterdayCamData.nYesterdayCount}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {this.state.yesterdayCsnData.nYesterdayCount}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 'bold' }}>
                    Slowest Quote Assignment
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {this.state.averageRacData.dtSlowestLead ? (
                      <>
                        {Moment.utc(
                          this.state.averageRacData.dtSlowestLead * 1000
                        ).format('HH:mm:ss')}
                      </>
                    ) : null}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {this.state.averageCamData.dtSlowestLead ? (
                      <>
                        {Moment.utc(
                          this.state.averageCamData.dtSlowestLead * 1000
                        ).format('HH:mm:ss')}
                      </>
                    ) : null}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {this.state.averageCsnData.dtSlowestLead ? (
                      <>
                        {Moment.utc(
                          this.state.averageCsnData.dtSlowestLead * 1000
                        ).format('HH:mm:ss')}
                      </>
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 'bold' }}>
                    Average quote assignment
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {this.state.averageRacData.dtAverageLead ? (
                      <>
                        {Moment.utc(
                          this.state.averageRacData.dtAverageLead * 1000
                        ).format('HH:mm:ss')}
                      </>
                    ) : null}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {this.state.averageCamData.dtAverageLead ? (
                      <>
                        {Moment.utc(
                          this.state.averageCamData.dtAverageLead * 1000
                        ).format('HH:mm:ss')}
                      </>
                    ) : null}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {this.state.averageCsnData.dtAverageLead ? (
                      <>
                        {Moment.utc(
                          this.state.averageCsnData.dtAverageLead * 1000
                        ).format('HH:mm:ss')}
                      </>
                    ) : null}
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </Wrapper>
    );
  }
}

export default LeadPickupMetrics;
