import React, { Component } from 'react';
import styled from 'styled-components';
import BrandSalesBarChart from '../charts/bar/admin-dashboard/BrandSalesBarChart.js';
import UserSalesBarChart from '../charts/bar/admin-dashboard/UserSalesBarChart.js';
import BrandSalesPieChart from '../charts/pie/admin-dashboard/BrandSalesPieChart.js';
import UserSalesPieChart from '../charts/pie/admin-dashboard/UserSalesPieChart.js';
import StatsOverview from '../tables/admin-dashboard/StatsOverview';
import RepOverview from '../tables/admin-dashboard/RepOverview';
import LeadPickupMetrics from '../tables/admin-dashboard/LeadPickupMetrics';
import ExpiredQuotes from '../tables/misc/ExpiredQuotes.js';
import MostRecentEmails from '../tables/admin-dashboard/MostRecentEmails.js';
import MostRecentNotes from '../tables/admin-dashboard/MostRecentNotes.js';
import UnknownQuotes from '../tables/admin-dashboard/UnknownQuotes.js';
import OpenQuotesNoNextFollowup from '../tables/admin-dashboard/OpenQuotesNoNextFollowup.js';
import OpenQuotesLateFollowups from '../tables/admin-dashboard/OpenQuotesLateFollowups.js';
import Prospects from '../tables/admin-dashboard/Prospects.js';
import TermsSummary from './sub-pages/terms-summary/TermsSummary.js';
import {
  dayChangeStart,
  dayChangeEnd,
  lastWeekStart,
  lastWeekEnd,
  thisWeekStart,
  thisWeekEnd,
  thisMonthEnd,
  thisMonthStart,
  thisYearStart,
  thisYearEnd,
} from '../../assets/functions/time';
import Moment from 'moment';
import CompanyRepMismatch from '../tables/admin-dashboard/CompanyRepMismatch.js';
import CustomerSummary from '../tables/admin-dashboard/CustomerSummary.js';
import MostRecentReviews from '../tables/admin-dashboard/MostRecentReviews.js';
import POsToReview from '../tables/admin-dashboard/POsToReview.js';
import MostIssues from '../tables/admin-dashboard/MostIssues.js';
import WorstResponse from '../tables/admin-dashboard/WorstResponse.js';
import RFQPORate from '../tables/admin-dashboard/RFQPORate.js';
import MajorIssues from '../tables/admin-dashboard/MajorIssues.js';
import RepItemUsage from '../tables/admin-dashboard/RepItemUsage.js';
import DiscQuotes from '../tables/admin-dashboard/DiscQuotes.js';
import MostRecentEmailsCam from '../tables/admin-dashboard/MostRecentEmailsCam.js';
import EmailedQuoteCam from '../tables/admin-dashboard/EmailedQuoteCam.js';
import EmailedQuoteRac from '../tables/admin-dashboard/EmailedQuoteRac.js';

const Select = styled.select`
  width: 100%;
  height: 60px;
  white-space:pre-wrap;
  margin: 0.5em auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.4em;
`
const Option = styled.option`
max-width: 100%;
overflow: hidden;
word-wrap: normal !important;
white-space: normal;
`

const Wrapper = styled.section`
  text-align: center;
`;

const DashboardControls = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin: 3em auto;
  padding: 2em 4em;
  width: fit-content;
  height: fit-content;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.1);
  text-align: center;
  border-radius: 15px;

  button {
    display: inline-block;
     padding: 0.5em 1.2em;
     margin: 0 0.3em 0.3em 0;
     text-decoration: none;
     color: #333;
     text-align: center;
     transition: all 0.2s;
    background-color: #f2f5f7;
    border: 1px solid rgba(255, 255, 255, 0);
    cursor: pointer;

    &:hover {
      background-color: #36a2eb;
      border: 1px solid #36a2eb;
      color: #fff;
    }
  }

  select {
    display: inline-block;
    color: #333;
     text-align: center;
     padding: 0.3em 1.2em;
     margin: 0 0.3em 0.3em 0;
    background-color: #f2f5f7;
    border: 1px solid #f2f5f7;
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
      background-color: #36a2eb;
      border: 1px solid #36a2eb;
      color: #fff;
    }
  }
`;

const Title = styled.h1`
  font-size: 2rem;
`;

const Time = styled.h1`
  padding: 0.5em 0 2em 0em;
  font-size: 1rem;

  i {
    margin: 0 1em;
    color: #c3d1da;
    cursor: pointer;
    transition: 0.1s;

    &:hover {
      color: #36a2eb;
    }
  }
`;

const CustomDateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1em 0 0 0;

  @media (max-width: 900px) {
    flex-direction: column;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 900px) {
      margin: 1em 0;
    }

    label {
      margin-right: 0.2em;
      font-weight: bold;
    }

    input {
      width: 200px;
       padding: 0.2em 1.2em;
       margin: 0 0.3em 0.3em 0;
      border: 1px solid #ccc;
      box-sizing: border-box;
      margin-top: 6px;
      margin-bottom: 16px;

      @media (max-width: 900px) {
        width: auto;
        margin: auto;
      }
    }
  }
`;

class Admin extends Component {
  state = {
    startDate: dayChangeStart(7),
    endDate: dayChangeEnd(0),
    showManualDate: false,
    customDateStart: '',
    customDateEnd: '',
    timeFrame: '',
    showMain: true,
    showTermsSummary: false,
    showReport: "Stats Overview"
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleChange(e) {
    if (e.target.value === 'Last Week') {
      this.setState({
        startDate: lastWeekStart(),
        endDate: lastWeekEnd(),
      });
    } else if (e.target.value === 'This Week') {
      this.setState({
        startDate: thisWeekStart(),
        endDate: thisWeekEnd(),
      });
    } else if (e.target.value === 'This Month') {
      this.setState({
        startDate: thisMonthStart(),
        endDate: thisMonthEnd(),
      });
    } else if (e.target.value === 'This Month-to-Date') {
      this.setState({
        startDate: thisMonthStart(),
        endDate: dayChangeEnd(0),
      });
    } else if (e.target.value === 'This Year') {
      this.setState({
        startDate: thisYearStart(),
        endDate: thisYearEnd(),
      });
    } else if (e.target.value === 'This Year-to-Date') {
      this.setState({
        startDate: thisYearStart(),
        endDate: dayChangeEnd(0),
      });
    } else if (e.target.value === 'The Last 365') {
      this.setState({
        startDate: dayChangeStart(365),
        endDate: dayChangeEnd(0),
      });
    } else if (e.target.value === 'The Last 30') {
      this.setState({
        startDate: dayChangeStart(30),
        endDate: dayChangeEnd(0),
      });
    } else {
      this.setState({
        showManualDate: true,
      });
    }
  }

  // Calculates time frame for quick change time buttons
  handleQuickTimeChange = (math) => (e) => {
    let start = Moment(this.state.startDate);
    let end = Moment(this.state.endDate);

    if (math === 'subtract') {
      if (
        this.state.timeFrame === 'Yesterday' ||
        this.state.timeFrame === 'Today'
      ) {
        this.setState({
          startDate: Moment(start)
            .subtract(1, 'days')
            .format('YYYY-MM-DD 00:00'),
          endDate: Moment(end).subtract(1, 'days').format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'Last 7 Days') {
        this.setState({
          startDate: Moment(start)
            .subtract(7, 'days')
            .format('YYYY-MM-DD 00:00'),
          endDate: Moment(end).subtract(7, 'days').format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'Last Week') {
        this.setState({
          startDate: Moment(start)
            .subtract(1, 'weeks')
            .startOf('week')
            .format('YYYY-MM-DD 00:00'),
          endDate: Moment(end)
            .subtract(1, 'weeks')
            .endOf('week')
            .format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'This Week') {
        this.setState({
          startDate: Moment(start)
            .subtract(1, 'weeks')
            .format('YYYY-MM-DD 00:00'),
          endDate: Moment(end).subtract(1, 'weeks').format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'This Month') {
        this.setState({
          startDate: Moment(start)
            .subtract(1, 'months')
            .startOf('month')
            .format('YYYY-MM-DD 00:00'),
          endDate: Moment(end)
            .subtract(1, 'months')
            .endOf('month')
            .format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'This Month-to-Date') {
        this.setState({
          startDate: Moment(start)
            .subtract(1, 'months')
            .startOf('month')
            .format('YYYY-MM-DD 00:00'),
          endDate: Moment(end).subtract(1, 'months').format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'This Year') {
        this.setState({
          startDate: Moment(start)
            .subtract(1, 'years')
            .startOf('year')
            .format('YYYY-MM-DD 00:00'),
          endDate: Moment(end)
            .subtract(1, 'years')
            .endOf('year')
            .format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'This Year-to-Date') {
        this.setState({
          startDate: Moment(start)
            .subtract(1, 'years')
            .startOf('year')
            .format('YYYY-MM-DD 00:00'),
          endDate: Moment(end).subtract(1, 'years').format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'The Last 365') {
        this.setState({
          startDate: Moment(start)
            .subtract(1, 'years')
            .format('YYYY-MM-DD 00:00'),
          endDate: Moment(end).subtract(1, 'years').format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'The Last 30') {
        this.setState({
          startDate: Moment(start)
            .subtract(30, 'days')
            .format('YYYY-MM-DD 00:00'),
          endDate: Moment(end).subtract(30, 'days').format('YYYY-MM-DD 23:59'),
        });
      } else {
        let days = end.diff(start, 'days');
        let startDate = Moment(start)
          .subtract(days, 'days')
          .format('YYYY-MM-DD 00:00');
        let endDate = Moment(end)
          .subtract(days, 'days')
          .format('YYYY-MM-DD 23:59');
        this.setState({
          startDate: startDate,
          endDate: endDate,
        });
      }
    }

    if (math === 'add') {
      let start = Moment(this.state.startDate);
      let end = Moment(this.state.endDate);

      if (
        this.state.timeFrame === 'Yesterday' ||
        this.state.timeFrame === 'Today'
      ) {
        this.setState({
          startDate: Moment(start).add(1, 'days').format('YYYY-MM-DD 00:00'),
          endDate: Moment(end).add(1, 'days').format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'Last 7 Days') {
        this.setState({
          startDate: Moment(start).add(7, 'days').format('YYYY-MM-DD 00:00'),
          endDate: Moment(end).add(7, 'days').format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'Last Week') {
        this.setState({
          startDate: Moment(start)
            .add(1, 'weeks')
            .startOf('week')
            .format('YYYY-MM-DD 00:00'),
          endDate: Moment(end)
            .add(1, 'weeks')
            .endOf('week')
            .format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'This Week') {
        this.setState({
          startDate: Moment(start).add(1, 'weeks').format('YYYY-MM-DD 00:00'),
          endDate: Moment(end).add(1, 'weeks').format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'This Month') {
        this.setState({
          startDate: Moment(start)
            .add(1, 'months')
            .startOf('month')
            .format('YYYY-MM-DD 00:00'),
          endDate: Moment(end)
            .add(1, 'months')
            .endOf('month')
            .format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'This Month-to-Date') {
        this.setState({
          startDate: Moment(start)
            .add(1, 'months')
            .startOf('month')
            .format('YYYY-MM-DD 00:00'),
          endDate: Moment(end).add(1, 'months').format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'This Year') {
        this.setState({
          startDate: Moment(start)
            .add(1, 'years')
            .startOf('year')
            .format('YYYY-MM-DD 00:00'),
          endDate: Moment(end)
            .add(1, 'years')
            .endOf('year')
            .format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'This Year-to-Date') {
        this.setState({
          startDate: Moment(start)
            .add(1, 'years')
            .startOf('year')
            .format('YYYY-MM-DD 00:00'),
          endDate: Moment(end).add(1, 'years').format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'The Last 365') {
        this.setState({
          startDate: Moment(start).add(1, 'years').format('YYYY-MM-DD 00:00'),
          endDate: Moment(end).add(1, 'years').format('YYYY-MM-DD 23:59'),
        });
      } else if (this.state.timeFrame === 'The Last 30') {
        this.setState({
          startDate: Moment(start).add(30, 'days').format('YYYY-MM-DD 00:00'),
          endDate: Moment(end).add(30, 'days').format('YYYY-MM-DD 23:59'),
        });
      } else {
        let days = end.diff(start, 'days');
        let startDate = Moment(start)
          .add(days, 'days')
          .format('YYYY-MM-DD 00:00');
        let endDate = Moment(end).add(days, 'days').format('YYYY-MM-DD 23:59');
        this.setState({
          startDate: startDate,
          endDate: endDate,
        });
      }
    }
  };

  handleStartDateChange = (event) => {
    this.setState({ customDateStart: event.target.value });
  };

  handleEndDateChange = (event) => {
    this.setState({ customDateEnd: event.target.value });
  };

  updateView = (event) => {
    this.setState({showReport: event.target.value})
  }

  render() {
    return (
      <Wrapper>
        <DashboardControls>
          <div>
            <Title>Admin Dashboard</Title>
            <Time>
              <i
                onClick={this.handleQuickTimeChange('subtract')}
                class="fas fa-chevron-circle-left"
              ></i>
              {Moment(this.state.startDate).format('dddd, MMMM Do YYYY') +
                ' - ' +
                Moment(this.state.endDate).format('dddd, MMMM Do YYYY')}
              <i
                onClick={this.handleQuickTimeChange('add')}
                class="fas fa-chevron-circle-right"
              ></i>
            </Time>
          </div>
          <div>
            <button
              onClick={() => {
                this.setState({
                  startDate: dayChangeStart(0),
                  endDate: dayChangeEnd(0),
                  timeFrame: 'Today',
                  showManualDate: false,
                });
              }}
            >
              Today
            </button>
            <button
              onClick={() => {
                this.setState({
                  startDate: dayChangeStart(1),
                  endDate: dayChangeEnd(1),
                  timeFrame: 'Yesterday',
                  showManualDate: false,
                });
              }}
            >
              Yesterday
            </button>
            <button
              onClick={() => {
                this.setState({
                  startDate: dayChangeStart(7),
                  endDate: dayChangeEnd(0),
                  timeFrame: 'Last 7 Days',
                  showManualDate: false,
                });
              }}
            >
              Last 7 Days
            </button>
            <select
              id="priority"
              name="priority"
              defaultValue={'Select other'}
              onChange={(e) => {
                this.handleChange(e);
              }}
            >
              <option value="Select other" disabled hidden>
                Select additional time
              </option>
              <option value="Last Week">Last Week</option>
              <option value="This Week">This Week</option>
              <option value="This Month">This Month</option>
              <option value="This Month-to-Date">This Month-to-Date</option>
              <option value="This Year">This Year</option>
              <option value="This Year-to-Date">This Year-to-Date</option>
              <option value="The Last 365">The Last 365</option>
              <option value="The Last 30">The Last 30</option>
              <option value="Custom Date">Custom Date</option>
            </select>
          </div>
          <div>
            <button
              onClick={() => {
                this.setState({
                  showTermsSummary: false,
                  showMain: true,
                });
              }}
            >
              Main
            </button>
            <button
              onClick={() => {
                this.setState({
                  showMain: false,
                  showTermsSummary: true,
                });
              }}
            >
              Terms Summary
            </button>
          </div>
          {this.state.showManualDate ? (
            <>
              <CustomDateContainer>
                <div>
                  <label for="startDate">Start Date</label>
                  <input
                    type="date"
                    id="startDate"
                    name="startDate"
                    onChange={this.handleStartDateChange}
                  />
                </div>
                <div>
                  <label for="endDate">End Date</label>
                  <input
                    type="date"
                    id="endDate"
                    name="endDate"
                    onChange={this.handleEndDateChange}
                  />
                </div>
              </CustomDateContainer>
              <div>
                <button
                  onClick={() => {
                    this.setState({
                      startDate: Moment(this.state.customDateStart).format(
                        'YYYY-MM-DD 00:00'
                      ),
                      endDate: Moment(this.state.customDateEnd).format(
                        'YYYY-MM-DD 23:59'
                      ),
                    });
                  }}
                >
                  Submit
                </button>
              </div>
            </>
          ) : null}
        </DashboardControls>
        {this.state.showMain ? (
          <>
            <Select onChange={(event) => this.updateView(event)} value={this.state.showReport}>
              <optgroup label="Sales Metrics">
              <Option value="Stats Overview">Stats Overview</Option>
              <Option value="Rep Overview">Rep Overview</Option>
              <Option value="Lead Pickup Metrics">Lead Pickup Metrics</Option>
              <Option value="Sales Charts">Sales Charts</Option>
              <Option value="Customer Summary">Customer Summary</Option>
              </optgroup>
              <optgroup label="Sales Management">
              <Option value="Unknown Quotes">Unknown Quotes</Option>
              <Option value="Open Quotes with no Next Followup">Open Quotes with no Next Followup</Option>
              <Option value="Open Quotes with late Follow Ups">Open Quotes with late Follow Ups</Option>
              <Option value="Expired Quotes">Expired Quotes</Option>
              <Option value="Most Recent Emails">Most Recent Emails</Option>
              <Option value="Most Recent Emails Cam">Most Recent Emails - CAM</Option>
              <Option value="Emailed Quotes Rac">Emailed Quotes - RAC</Option>
              <Option value="Emailed Quotes Cam">Emailed Quotes - CAM</Option>
              <Option value="Recent Notes">Recent Notes</Option>
              <Option value="All Prospects">All Prospects</Option>              
              <Option value="Company Rep Mismatch">Company Rep Mismatch</Option>
              
              </optgroup>
              <optgroup label="Tech Army">
              <Option value="Recent Reviews and Comments">Recent Reviews and Comments</Option>
              <Option value="Recent Major Issues">Recent Major Issues</Option>
              <Option value="POs Without Reviews">POs Without Reviews</Option>
              <Option value="Suppliers with Most Issues">Suppliers with Most Issues</Option>
              <Option value="Suppliers with Worst Response">Suppliers with Worst Response</Option>
              <Option value="Suppliers with Worst PO/RFQ Ratio">Suppliers with Worst PO/RFQ Ratio</Option>
              </optgroup>
              <optgroup label="Items">
              <Option value="Rep Item Usage">Rep Item Usage</Option>
              </optgroup>
              <optgroup label="Discontinued Quotes">
              <Option value="Discontinue Reason Summary">Discontinue Reason Summary</Option>
              <Option value="Went With Another Vendor">Went With Another Vendor</Option>
              <Option value="Cannot Render Service">Cannot Render Service</Option>
              <Option value="Quote Too Expensive">Quote Too Expensive</Option>
              
              </optgroup>
            </Select>
            {this.state.showReport === "Stats Overview" && (
              <StatsOverview
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              />
            )}
            
            {this.state.showReport === "Rep Overview" && (
            <RepOverview
              startDate={this.state.startDate}
              endDate={this.state.endDate}
            />
            )}

            {this.state.showReport === "Lead Pickup Metrics" && (
            <LeadPickupMetrics />
            )}

            {this.state.showReport === "Unknown Quotes" && (
            <UnknownQuotes />
            )}

            {this.state.showReport === "Open Quotes with no Next Followup" && (
            <OpenQuotesNoNextFollowup />
            )}

            {this.state.showReport === "Open Quotes with late Follow Ups" && (
            <OpenQuotesLateFollowups />
            )}

            {this.state.showReport === "Expired Quotes" && (
            <ExpiredQuotes nRep="0" />
            )}

            {this.state.showReport === "Most Recent Emails" && (
            <MostRecentEmails
              startDate={this.state.startDate}
              endDate={this.state.endDate}
            />
            )}
            {this.state.showReport === "Most Recent Emails Cam" && (
            <MostRecentEmailsCam
              startDate={this.state.startDate}
              endDate={this.state.endDate}
            />
            )}
            {this.state.showReport === "Emailed Quotes Cam" && (
            <EmailedQuoteCam
              startDate={this.state.startDate}
              endDate={this.state.endDate}
            />
            )}
            {this.state.showReport === "Emailed Quotes Rac" && (
            <EmailedQuoteRac
              startDate={this.state.startDate}
              endDate={this.state.endDate}
            />
            )}

            {this.state.showReport === "Recent Notes" && (
            <MostRecentNotes
              startDate={this.state.startDate}
              endDate={this.state.endDate}
            />
            )}

            {this.state.showReport === "All Prospects" && (
            <Prospects />
            )}

            {this.state.showReport === "Sales Charts" && (
              <>
            <BrandSalesBarChart
              startDate={this.state.startDate}
              endDate={this.state.endDate}
            />
            <BrandSalesPieChart
              startDate={this.state.startDate}
              endDate={this.state.endDate}
            />
            <UserSalesBarChart
              startDate={this.state.startDate}
              endDate={this.state.endDate}
            />
            <UserSalesPieChart
              startDate={this.state.startDate}
              endDate={this.state.endDate}
            />
            </>
            )}

            {this.state.showReport === "Company Rep Mismatch" && (
            <CompanyRepMismatch />
            )}

            {this.state.showReport === "Customer Summary" && (
            <CustomerSummary />
            )}

            {this.state.showReport === "Recent Reviews and Comments" && (
              <MostRecentReviews />
            )}
            {this.state.showReport === "Recent Major Issues" && (
              <MajorIssues />
            )}
            {this.state.showReport === "POs Without Reviews" && (
              <POsToReview />
            )}
            {this.state.showReport === "Suppliers with Most Issues" && (
              <MostIssues />
            )}
            {this.state.showReport === "Suppliers with Worst Response" && (
              <WorstResponse />
            )}
            {this.state.showReport === "Suppliers with Worst PO/RFQ Ratio" && (
              <RFQPORate />
            )}
            {this.state.showReport === "Rep Item Usage" && (
              <RepItemUsage />
            )}
            {this.state.showReport === "Discontinue Reason Summary" && (
              <DiscQuotes report='/disc-quotes-summary' />
            )}
            {this.state.showReport === "Went With Another Vendor" && (
              <DiscQuotes report='/another-vendor' />
            )}
            {this.state.showReport === "Cannot Render Service" && (
              <DiscQuotes report='/cannot-render' />
            )}
            {this.state.showReport === "Quote Too Expensive" && (
              <DiscQuotes report='/too-expensive' />
            )}
            
          </>
        ) : null}
        {this.state.showTermsSummary ? (
          <>
            <TermsSummary
              startDate={this.state.startDate}
              endDate={this.state.endDate}
            />
          </>
        ) : null}
      </Wrapper>
    );
  }
}

export default Admin;
