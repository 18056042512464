module.exports = {
    accounting_queries: {
        template: {
            query: "",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },

        predictFRP: {
            query: "SELECT TOP 20 cq1.*, FLOOR((cq1.Sales - cq1.ShippingLineTotal)/100) AS Earned "
                +"FROM ("
                    + "SELECT rcq.id, (("
                        + "SELECT SUM(rql.nPrice*rql.nQuantity) "
                        + "FROM RAC_QUOTE_LINES rql "
                        + "WHERE rql.nQuote=rcq.id)) "
                    + "AS Sales, c.nFRPid, c.sName, rcq.dtPaid, "
                    + "ISNULL(("
                        + "SELECT SUM(rql.nPrice * rql.nQuantity) "
                        + "FROM RAC_QUOTE_LINES rql "
                        + "WHERE rql.nItem IN (629, 630, 781, 776, 70, 83, 236, 304, 58, 9999931, 331, 9999975) AND rql.nQuote = rcq.id"
                    + "),0) AS ShippingLineTotal, "
                    + "ISNULL(("
                        + "SELECT SUM(fp.nPoints) "
                        + "FROM FRP_POINTS fp "
                        + "WHERE c.nFRPid=fp.nContactId "
                        + "AND NOT fp.bDelete = 1 "
                        + "AND rcq.id=fp.sUsedFor"
                    + "),0) AS Logged "
                    + "FROM RAC_CUSTOMER_QUOTES rcq "
                    + "LEFT JOIN COMPANIES c ON c.id=rcq.nCompany "
                    + "WHERE rcq.sQuoteType='Rentacomputer.com' AND rcq.sStatus='Closed' AND rcq.nPaid>0"
                + ") cq1 "
                + "WHERE ABS(FLOOR((cq1.Sales - cq1.ShippingLineTotal)/100) - cq1.Logged) >= 1 "
                + "ORDER BY cq1.dtPaid desc",
            req: [],
            fields: [
                {key: "id", label: "Quote Number"},
                {key: "Earned", label: "FRPs Earned"},
                {key: "nFRPid", label: "Customer FRP id"},
            ],
            desc: "",
            tables: ["RAC_CUSTOMER_QUOTES", "COMPANIES", "FRP_POINTS"],
            type: "SELECT",
            whereUsed: [],
        },
        deleteFRP: {
            query: "UPDATE FRP_POINTS SET bDelete=1 WHERE id=[id]",
            req: ["id"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Marks a record as bDelete true in FRP_POINTS",
            tables: ["FRP_POINTS"],
            type: "UPDATE",
            whereUsed: [],
        },
        editFRP: {
            query: "UPDATE FRP_POINTS SET nPoints=[nPoints], dtDateEarned='[dtDateEarned]', sUsedFor='[sUsedFor]' WHERE id=[id]",
            req: ["nPoints", "dtDateEarned", "sUsedFor", "id"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Updates a record in the FRP_POINTS database",
            tables: ["FRP_POINTS"],
            type: "UPDATE",
            whereUsed: [],
        },
        createNewCustomer: {
            query: "INSERT INTO FRP_CONTACTS (sContactName,sCompanyName) VALUES ('','[sContactName]')",
            req: ["sContactName"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Insert a new record into FRP_CONTACTS table",
            tables: ["FRP_CONTACTS"],
            type: "INSERT",
            whereUsed: [],
        },
        setCompanyFRPid: {
            query: "UPDATE COMPANIES SET nFRPid=[nFRPid] WHERE id=[nCompanyid]",
            req: ["nFRPid", "nCompanyid"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Updates FRP id property of CUSTOMERS table.",
            tables: ["COMPANIES"],
            type: "UPDATE",
            whereUsed: [],
        },
        setFRPContactDelete: {
            query: "UPDATE FRP_CONTACTS SET bDelete=[bDelete] WHERE id=[nFRPid]",
            req: ["bDelete", "nFRPid"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Updates the bDelete property of a FRP Contact record",
            tables: ["FRP_CONTACTS"],
            type: "UPDATE",
            whereUsed: [],
        },
        findCompanyFRPMatch: {
            query: "SELECT TOP 10 c.* FROM COMPANIES c WHERE c.sName LIKE '%[sName]%' AND (NOT bInactive > 0 OR bInactive IS NULL) ORDER BY c.nTotalDeals desc",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        listFRP: {
            query: "SELECT * FROM FRP_POINTS fp WHERE fp.nContactId=[nContactId] AND NOT bDelete=1 ORDER BY dtDateEarned desc",
            req: ["nContactId"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "List all FRP points for a customer.",
            tables: ["FRP_POINTS"],
            type: "SELECT",
            whereUsed: [],
        },
        addFRP: {
            query: "INSERT INTO FRP_POINTS (nPoints, dtDateEarned, sUsedFor, bDelete, nContactId) VALUES ([nPoints], '[dtDateEarned]', '[sUsedFor]', 0, [nContactId])",
            req: ["nPoints", "dtDateEarned", "sUsedFor", "nContactId"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Insert a new record into the FRP_POINTS database.",
            tables: ["FRP_POINTS"],
            type: "INSERT",
            whereUsed: [],
        },
        findFRPid: {
            query: "SELECT TOP 20 fc.*, c.nFRPid, "
                + "(SELECT SUM(nPoints) FROM FRP_POINTS fp WHERE fp.nContactId=fc.id) AS Balance "
                + "FROM FRP_CONTACTS fc "
                + "LEFT JOIN COMPANIES c on fc.id=c.nFRPid "
                + "WHERE fc.sCompanyName LIKE '%[sCompanyName]%' "
                + "ORDER BY Balance desc",
            req: ["sCompanyName"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Tries to find a company in the FRP database.",
            tables: ["FRP_CONTACTS", "FRP_POINTS"],
            type: "SELECT",
            whereUsed: [],
        },
        
        commission_log_import: {
            query: "INSERT INTO COMMISSION_LOG " 
                + "(nQuote, nMargin, dtDateEarned, nRep) "
                + "VALUES ("
                + "[nQuote], [nMargin], '[dtDate]', [nRep])",
            req: ["nQuote", "nMargin", "dtDate", "nRep"],
            fields: [],
            desc: "Inserts a line into the commission log.",
            tables: ["COMMISSION_LOG"],
            type: "INSERT",
            whereUsed: ["components/pages/AccountingDashboard"],
        },
        addCommissionLogException: {
            query: "INSERT INTO COMMISSION_LOG " 
                + "(nQuote, nMargin, dtDateEarned, nRep, sException) "
                + "VALUES ("
                + "[nQuote], [nMargin], '[dtDate]', [nRep], '[sException]')",
            req: ["nQuote", "nMargin", "dtDate", "nRep", "sException"],
            fields: [],
            desc: "Inserts a line into the commission log.",
            tables: ["COMMISSION_LOG"],
            type: "INSERT",
            whereUsed: ["components/pages/AccountingDashboard"],
        },
        commissionLogMismatch: {
            query: "SELECT sq1.id, sq1.dtInvoiced, sq1.dtFirstClosed, sq1.dtDeliveryDate, sq1.dtPaid, sq1.nRep, "
                + "sq1.linesTotal, sq1.nDelivery, sq1.POTotal, sq1.commissionLog, sq1.logID "
                + "FROM "
                    + "(SELECT rcq.id, rcq.dtInvoiced, rcq.dtFirstClosed, rcq.dtDeliveryDate, rcq.dtPaid, rcq.nRep, "
                    + "(SELECT SUM(rql.nPrice*rql.nQuantity) FROM RAC_QUOTE_LINES rql WHERE rql.nQuote=rcq.id) AS linesTotal, "
                    + "rcq.nTaxRate, rcq.bTaxDel, rcq.nDelivery, "
                    + "(SELECT SUM(rp.nPOTotal) FROM RAC_POS rp WHERE rp.nAttachedQuote = rcq.id AND NOT rp.bDeleted = 1) AS POTotal, "
                    + "(SELECT SUM(c.nMargin) FROM COMMISSION_LOG c WHERE c.nQuote=rcq.id) AS commissionLog, "
                    + "(SELECT c.id FROM COMMISSION_LOG c WHERE c.nQuote=rcq.id) AS logID "
                    + "FROM RAC_CUSTOMER_QUOTES rcq "
                    + "WHERE rcq.sStatus='Closed' AND rcq.sQuoteType='Rentacomputer.com' AND rcq.dtPaid >= '1/1/23' "
                    + "AND rcq.id IN (SELECT c.nQuote FROM COMMISSION_LOG c)) sq1 "
                    + "WHERE ABS(sq1.linesTotal + sq1.nDelivery - sq1.POTotal - sq1.commissionLog) >= 1",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets quotes that a mismatch between logged and predicted.",
            tables: ["RAC_CUSTOMER_QUOTES","COMMISSION_LOG"],
            type: "SELECT",
            whereUsed: [],
        },
        commissionLogLookup: {
            query: "SELECT rcq.id, rcq.dtInvoiced, rcq.dtFirstClosed, rcq.dtDeliveryDate, rcq.dtPaid, rcq.nRep, rcq.sStatus, "
                + "(SELECT SUM(rql.nPrice*rql.nQuantity) FROM RAC_QUOTE_LINES rql WHERE rql.nQuote=rcq.id) AS linesTotal, "
                + "rcq.nTaxRate, rcq.bTaxDel, rcq.nDelivery, "
                + "(SELECT SUM(rp.nPOTotal) FROM RAC_POS rp WHERE rp.nAttachedQuote = rcq.id AND NOT rp.bDeleted = 1) AS POTotal, "
                + "cl.nRep AS logRep, cl.nMargin, cl.dtDateEarned, cl.sException, cl.id AS logID "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "LEFT JOIN COMMISSION_LOG cl ON cl.nQuote=rcq.id "
                + "WHERE rcq.id = [nQuote]",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Finds all commission log entries for a quote.",
            tables: ["RAC_CUSTOMER_QUOTES","COMMISSION_LOG"],
            type: "SELECT",
            whereUsed: [],
        },
        commissionLogPotentialMiss: {
            query: "SELECT rcq.id, rcq.dtInvoiced, rcq.dtFirstClosed, rcq.dtDeliveryDate, rcq.dtPaid, rcq.nRep, "
                + "(SELECT SUM(rql.nPrice*rql.nQuantity) FROM RAC_QUOTE_LINES rql WHERE rql.nQuote=rcq.id) AS linesTotal, "
                + "rcq.nTaxRate, rcq.bTaxDel, rcq.nDelivery, "
                + "(SELECT SUM(rp.nPOTotal) FROM RAC_POS rp WHERE rp.nAttachedQuote = rcq.id AND NOT rp.bDeleted = 1) AS POTotal "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.sStatus='Closed' AND rcq.sQuoteType='Rentacomputer.com' AND rcq.dtPaid >= '1/1/23' AND dtPaid <= '12/31/23' "
                + "AND rcq.id NOT IN (SELECT c.nQuote FROM COMMISSION_LOG c) "
                + "ORDER BY rcq.dtInvoiced asc",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets quotes that have been paid in the system in 2023 but aren't on the archive commission log.",
            tables: ["RAC_CUSTOMER_QUOTES","COMMISSION_LOG"],
            type: "SELECT",
            whereUsed: [],
        },
        getCommissionLogExceptions: {
            query: "SELECT * FROM COMMISSION_LOG WHERE sException IS NOT NULL",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Get all commission log exceptions",
            tables: ["COMMISSION_LOG"],
            type: "SELECT",
            whereUsed: [],
        },
        editCommissionLogException: {
            query: "UPDATE COMMISSION_LOG SET sException='[sException]' WHERE id=[nExceptionId]",
            req: ["sException", "nExceptionId"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Update an exception type",
            tables: ["COMMISSION_LOG"],
            type: "UPDATE",
            whereUsed: [],
        },
        getCommissionsEarned: {
            query: "SELECT cl.* FROM COMMISSION_LOG cl WHERE dtDateEarned >= '1/1/[minYear]'",
            req: ["minYear"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Summarize all commissions earned",
            tables: ["COMMISSION_LOG"],
            type: "SELECT",
            whereUsed: [],
        },
        getCommissionsPaid: {
            query: "SELECT cpl.* FROM COMMISSION_PAID_LOG cpl WHERE nYear >= [minYear] ORDER BY cpl.nRep, cpl.dtDate asc",
            req: ["minYear"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Summarize all commissions paid",
            tables: ["COMMISSION_PAID_LOG"],
            type: "SELECT",
            whereUsed: [],
        },
        updatePaidLog: {
            query: "UPDATE COMMISSION_PAID_LOG SET nAmount=[nAmount] WHERE id=[id]",
            req: ["id", "nAmount"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Updates a commission paid log entry",
            tables: ["COMMISSION_PAID_LOG"],
            type: "UPDATE",
            whereUsed: [],
        },
        paidButNotLogged: {
            query: "SELECT rcq.id, rcq.dtInvoiced, rcq.dtFirstClosed, rcq.dtDeliveryDate, rcq.dtPaid, rcq.nRep, "
                + "(SELECT SUM(rql.nPrice*rql.nQuantity) FROM RAC_QUOTE_LINES rql WHERE rql.nQuote=rcq.id) AS linesTotal, "
                + "rcq.nTaxRate, rcq.bTaxDel, rcq.nDelivery, rcq.nQuoteTotal, "
                + "(SELECT SUM(rp.nPOTotal) FROM RAC_POS rp WHERE rp.nAttachedQuote = rcq.id AND NOT rp.bDeleted = 1) AS POTotal "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.id NOT IN (SELECT cl.nQUOTE FROM COMMISSION_LOG cl) AND rcq.dtPaid >= '1/1/24' AND rcq.sQuoteType='Rentacomputer.com' "
                + "ORDER BY rcq.dtPaid asc",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets all paid quotes not on the log",
            tables: ["RAC_CUSTOMER_QUOTES", "COMMISSION LOG"],
            type: "SELECT",
            whereUsed: [],
        },
        deleteCommissionLog: {
            query: "DELETE FROM COMMISSION_LOG WHERE id=[logID]",
            req: ["logID"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Delete's a commission log entry",
            tables: ["COMMISSION_LOG"],
            type: "DELETE",
            whereUsed: [],
        },
        getRepLog: {
            query: "SELECT * FROM COMMISSION_LOG WHERE dtDateEarned>='[minDate]' AND dtDateEarned<='[maxDate]' AND nRep=[nRep]",
            req: ["minDate", "maxDate", "nRep"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets all log entries for a rep in a date range.",
            tables: ["COMMISSION_LOG"],
            type: "SELECT",
            whereUsed: [],
        },
        setQBCustomer: {
            query: "UPDATE COMPANIES SET sQBCustomer='[sQBCustomer]' WHERE id=[nCompany]",
            req: ["sQBCustomer", "nCompany"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Updates a customer's QB name",
            tables: ["COMPANIES"],
            type: "SELECT",
            whereUsed: [],
        },
        setCompanyTerms: {
            query: "UPDATE COMPANIES SET sPaymentTerms='[sPaymentTerms]', nCreditLimit=[nCreditLimit] WHERE id=[nCompany]",
            req: ["sQBCustomer", "nCreditLimit"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Updates a customer's payment terms",
            tables: ["COMPANIES"],
            type: "SELECT",
            whereUsed: [],
        },
        getInvoiceData: {
            query: "SELECT rcq.*, c.sQBCustomer, c.sPaymentTerms, c.nCreditLimit, "
                + "(SELECT COUNT(rcq2.id) FROM RAC_CUSTOMER_QUOTES rcq2 WHERE rcq.nCompany=rcq2.nCompany AND rcq.sStatus='Closed') AS ClosedQuotes, "
                + "(SELECT SUM(rcq2.nQuoteTotal) FROM RAC_CUSTOMER_QUOTES rcq2 WHERE rcq.nCompany=rcq2.nCompany AND rcq.sStatus='Closed' AND dtFirstClosed >= DATEADD(year,-3,GETDATE())) AS Business3Years, "
                + "(SELECT MIN(rcq2.dtFirstClosed) FROM RAC_CUSTOMER_QUOTES rcq2 WHERE rcq.nCompany=rcq2.nCompany AND rcq.sStatus='Closed') AS firstOrder, "
                + "(SELECT rr.sInitials FROM RAC_REPS rr WHERE rr.id = rcq.nRep) AS RepInitials "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "LEFT JOIN COMPANIES c ON rcq.nCompany=c.id "
                + "WHERE rcq.id=[id]",
            req: ["id"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets all quote info for a particular quote",
            tables: ["RAC_CUSTOMER_QUOTES", "COMPANIES"],
            type: "SELECT",
            whereUsed: [],
        },
        getQuoteLineData: {
            query: "SELECT rql.*, (SELECT i.sName FROM ITEMS i WHERE rql.nItem = i.id) AS itemName FROM RAC_QUOTE_LINES rql WHERE nQuote=[id] ORDER BY nSort asc",
            req: ["id"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets all quote lines for a particular quote",
            tables: ["RAC_QUOTE_LINES"],
            type: "SELECT",
            whereUsed: [],
        },
        getPOData: {
            query: "SELECT * FROM RAC_POS WHERE nAttachedQuote=[id]",
            req: ["id"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets all POs for a particular quote",
            tables: ["RAC_POS"],
            type: "SELECT",
            whereUsed: [],
        },
        getPOLineData: {
            query: "SELECT rpl.* FROM RAC_PO_LINES rpl WHERE rpl.nQuote IN (SELECT rp.id FROM RAC_POS rp WHERE rp.nAttachedQuote=[id])",
            req: ["id"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets all PO lines for a particular quote",
            tables: ["RAC_PO_LINES", "RAC_POS"],
            type: "SELECT",
            whereUsed: [],
        },
        getUnpaidButClosed: {
            query: "SELECT rcq.id, rcq.sCompany, rcq.dtFirstClosed, rcq.nQuoteTotal "
                + "FROM RAC_CUSTOMER_QUOTES rcq "
                + "WHERE rcq.nPaid IS NULL AND rcq.sQuoteType='Rentacomputer.com' AND rcq.dtFirstClosed >= '1/1/2023' "
                + "ORDER BY dtFirstClosed desc",
            req: [""],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Gets all RAC quotes that are closed but not paid.",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: [],
        },
        markAsPaid: {
            query: "UPDATE RAC_CUSTOMER_QUOTES SET nPaid=[repID], dtPaid=GETDATE() WHERE id=[id]",
            req: ["id", "repID"],
            fields: [
                {key: "", label: ""},
            ],
            desc: "Updates a quote's paid status",
            tables: ["RAC_CUSTOMER_QUOTES"],
            type: "SELECT",
            whereUsed: [],
        },
    }

} 