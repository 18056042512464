import React, { Component } from 'react';
import {
  ModalWrapper,
  ModalHeader,
  ModalTitle,
  CloseButton,
  ModalBody,
  ContactList,
  ContactItem,
  InputGroup,
  ButtonGroup,
  SaveButton,
  DeleteButton,
  EditButton,
  Button
} from './StyledComponents';
import { getSQLData, setSQLData } from '../../../assets/functions/fetch';

const repNames = {
  5: 'Andy Wendt',
  54: 'Brian Kubala',
  63: 'Hunter Stewart',
  64: 'Jeff Bruck',
  71: 'Jill Staley',
  1: 'John Beagle',
  45: 'Johnny Beagle',
  44: 'Kevin Boles',
  68: 'Kevin Thompson',
  40: 'Kristen Fisher',
  51: 'Mark Gillaugh',
  22: 'Randy Moore',
  27: 'Robert Beagle',
  14: 'Susan Beagle',
  8: 'Tony Cipollone',
};

class CompanyDetailsModal extends Component {
  state = {
    contacts: [],
    editingContactId: null,
    contactEdits: {},
    loading: true,
    error: null,
    newContact: {
      name: '',
      email: '',
      phone: '',
      rep: '',
      tier: '3'
    },
    creatingContact: false,
    companyDetails: {
      industry: '',
      subIndustry: '',
      employees: '',
      annualSales: '',
    },
    editingCompany: false,
  };

  componentDidMount() {
    this.fetchCompanyContacts();
    this.fetchCompanyDetails();
  }

  componentDidUpdate(prevProps) {
    if (this.props.companyId !== prevProps.companyId) {
      this.fetchCompanyContacts();
      this.fetchCompanyDetails();
    }
  }

  fetchCompanyContacts = async () => {
    const { companyId } = this.props;
    try {
      const contactsFetch = await getSQLData("logistics/getContactsByCompany", { nCompany: companyId });
      if (contactsFetch && contactsFetch.data && contactsFetch.data.data[0]) {
        this.setState({ contacts: contactsFetch.data.data[0].recordset, loading: false });
      } else {
        this.setState({ loading: false, error: 'No contacts found.' });
      }
    } catch (error) {
      console.error('Error fetching contacts:', error);
      this.setState({ loading: false, error: 'Error fetching contacts.' });
    }
  };

  fetchCompanyDetails = async () => {
    const { companyId } = this.props;
    try {
      const response = await getSQLData('marketing/getCustomerData', { customerID: companyId });
      if (response && response.data && response.data.data[0]) {
        const company = response.data.data[0].recordset[0];
        this.setState({
          companyDetails: {
            industry: company.sIndustry || '',
            subIndustry: company.sSubIndustry || '',
            employees: company.nEmployees || '', // Store the employee number
            annualSales: company.nCompanyAnnualSales || '',
          }
        });
      }
    } catch (error) {
      console.error('Error fetching company details:', error);
    }
  };

  handleCompanyInputChange = (field, value) => {
    this.setState((prevState) => ({
      companyDetails: {
        ...prevState.companyDetails,
        [field]: value,
      },
    }));
  };

  handleSaveCompanyDetails = async () => {
    const { companyId } = this.props;
    const { industry, subIndustry, employees, annualSales } = this.state.companyDetails;

    try {
      const response = await setSQLData('marketing/setCompanyProfileData', {
        companyId,
        sIndustry: industry,
        sSubIndustry: subIndustry,
        nEmployees: employees, // Save as a single number
        nCompanyAnnualSales: annualSales,
      });

      if (response.success) {
        this.setState({ editingCompany: false });
      } else {
        console.error('Error saving company details:', response.error);
      }
    } catch (error) {
      console.error('Error saving company details:', error);
    }
  };

  handleEditClick = (contactId) => {
    const { contacts } = this.state;
    const contact = contacts.find(c => c.id === contactId);
    const nRep = Object.keys(repNames).find(key => repNames[key] === contact.sRep) || '';
    this.setState({
      editingContactId: contactId,
      contactEdits: { ...contact, nRep, sTier: contact.sTier || '3' },
    });
  };

  handleInputChange = (field, value) => {
    this.setState((prevState) => ({
      contactEdits: {
        ...prevState.contactEdits,
        [field]: value,
      },
    }));
  };

  handleSaveClick = async () => {
    const { contactEdits } = this.state;

    try {
      const response = await setSQLData("logistics/updateContact", {
        ...contactEdits,
        sRep: contactEdits.nRep,
      });
      if (response.success) {
        this.fetchCompanyContacts();
        this.setState({ editingContactId: null, contactEdits: {} });
      } else {
        console.error('Error saving contact:', response.error);
      }
    } catch (error) {
      console.error('Error saving contact:', error);
    }
  };

  handleCreateContact = async () => {
    const { quoteId } = this.props;

    try {
      const response = await getSQLData('logistics/getQuoteDetailsById', { quoteId });

      if (response && response.data && response.data.data[0] && response.data.data[0].recordset.length > 0) {
        const quoteDetails = response.data.data[0].recordset[0];

        this.setState({
          newContact: {
            name: quoteDetails.sName || '',
            email: quoteDetails.sEmail || '',
            phone: quoteDetails.sPhone || '',
            rep: quoteDetails.nRep || '',
            tier: '3'  // Default to tier 3
          },
          creatingContact: true,
        });
      } else {
        console.error('No data found in the response:', response);
      }
    } catch (error) {
      console.error('Error fetching quote details for auto-population:', error);
    }
  };

  handleNewContactChange = (field, value) => {
    this.setState((prevState) => ({
      newContact: {
        ...prevState.newContact,
        [field]: value,
      },
    }));
  };

  handleSubmitNewContact = async () => {
    const { newContact } = this.state;

    const contactData = {
      sName: newContact.name,
      Email1: newContact.email,
      sPhone: newContact.phone,
      sRep: newContact.rep,
      sTier: newContact.tier,
      nCompany: this.props.companyId, 
    };

    try {
      const response = await setSQLData('logistics/createContact', contactData);

      if (response && response.status === 200) {
        this.setState((prevState) => ({
          contacts: [...prevState.contacts, contactData],
          newContact: { name: '', email: '', phone: '', rep: '', tier: '3' },
          creatingContact: false,
        }));
      } else {
        console.error('Unexpected response:', response);
      }
    } catch (error) {
      console.error('Error creating contact:', error);
    }
  };

  render() {
    const { onClose, companyName } = this.props;
    const { contacts, editingContactId, contactEdits, loading, error, newContact, creatingContact, editingCompany, companyDetails } = this.state;

    return (
      <ModalWrapper>
        <ModalHeader>
          <ModalTitle>{companyName} Details</ModalTitle>
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </ModalHeader>
        <ModalBody>
          {loading ? (
            <p>Loading contacts...</p>
          ) : error ? (
            <p>{error}</p>
          ) : (
            <>
              {/* Company Details */}
              <h3 style={{ marginBottom: '10px' }}>Company Information</h3>
              <div style={{ marginBottom: '20px' }}>
                {editingCompany ? (
                  <>
                    <InputGroup>
                      <label>Industry:</label>
                      <input
                        type="text"
                        value={companyDetails.industry}
                        onChange={(e) => this.handleCompanyInputChange('industry', e.target.value)}
                      />
                    </InputGroup>
                    <InputGroup>
                      <label>Sub-Industry:</label>
                      <input
                        type="text"
                        value={companyDetails.subIndustry}
                        onChange={(e) => this.handleCompanyInputChange('subIndustry', e.target.value)}
                      />
                    </InputGroup>
                    <InputGroup>
                      <label>Employees</label>
                      <input
                        type="number"
                        value={companyDetails.employees}
                        onChange={(e) => this.handleCompanyInputChange('employees', parseInt(e.target.value) || 0)}
                      />
                    </InputGroup>
                    <InputGroup>
                      <label>Annual Revenue</label>
                      <input
                        type="text"
                        value={companyDetails.annualSales.toLocaleString()}  // Display formatted
                        onChange={(e) => this.handleCompanyInputChange('annualSales', parseInt(removeCommas(e.target.value)) || 0)}  // Remove commas for backend
                      />
                    </InputGroup>

                    <ButtonGroup>
                      <SaveButton onClick={this.handleSaveCompanyDetails}>Save</SaveButton>
                      <Button onClick={() => this.setState({ editingCompany: false })} style={{ backgroundColor: '#ccc' }}>Cancel</Button>
                      {/* Link to ZoomInfo */}
                      <a href="https://www.zoominfo.com/" target="_blank" rel="noopener noreferrer" style={{ marginLeft: '10px' }}>
                        Fill info via ZoomInfo
                      </a>
                    </ButtonGroup>
                  </>
                ) : (
                  <>
                    <p><strong>Industry:</strong> {companyDetails.industry || 'N/A'}</p>
                    <p><strong>Sub-Industry:</strong> {companyDetails.subIndustry || 'N/A'}</p>
                    <p><strong>Employees:</strong> {companyDetails.employees || 'N/A'}</p>
                    <p><strong>Annual Sales:</strong> {companyDetails.annualSales ? `$${companyDetails.annualSales.toLocaleString()}` : 'N/A'}</p>
                    <Button style={{ marginTop: '10px' }} onClick={() => this.setState({ editingCompany: true })}>Edit Company Info</Button>
                  </>
                )}
              </div>

              {/* Contact Details */}
              <h3 style={{ marginBottom: '10px' }}>Contacts</h3>
              <ContactList>
                {contacts.length > 0 ? (
                  contacts.map((contact) => (
                    <ContactItem key={contact.id}>
                      {editingContactId === contact.id ? (
                        <>
                          <div>
                            <InputGroup>
                              <label>Name:</label>
                              <input
                                type="text"
                                value={contactEdits.sName}
                                onChange={(e) => this.handleInputChange('sName', e.target.value)}
                              />
                            </InputGroup>
                            <InputGroup>
                              <label>Phone:</label>
                              <input
                                type="text"
                                value={contactEdits.sPhone}
                                onChange={(e) => this.handleInputChange('sPhone', e.target.value)}
                              />
                            </InputGroup>
                            <InputGroup>
                              <label>Email:</label>
                              <input
                                type="email"
                                value={contactEdits.Email1}
                                onChange={(e) => this.handleInputChange('Email1', e.target.value)}
                              />
                            </InputGroup>
                            <InputGroup>
                              <label>Rep:</label>
                              <select
                                value={contactEdits.nRep || ''}
                                onChange={(e) => this.handleInputChange('nRep', e.target.value)}
                              >
                                <option value="">Select Rep</option>
                                {Object.entries(repNames).map(([repId, repName]) => (
                                  <option key={repId} value={repId}>
                                    {repName}
                                  </option>
                                ))}
                              </select>
                            </InputGroup>
                            <InputGroup>
                              <label>Tier:</label>
                              <select
                                value={contactEdits.sTier || '3'}
                                onChange={(e) => this.handleInputChange('sTier', e.target.value)}
                              >
                                <option value="1">Tier 1</option>
                                <option value="2">Tier 2</option>
                                <option value="3">Tier 3</option>
                                <option value="4">Tier 4</option>
                                <option value="5">Tier 5</option>
                                <option value="6">Tier 6</option>
                              </select>
                            </InputGroup>
                          </div>
                          <ButtonGroup>
                            <SaveButton onClick={this.handleSaveClick}>Save</SaveButton>
                            <DeleteButton onClick={this.handleDeleteClick}>Delete</DeleteButton>
                          </ButtonGroup>
                        </>
                      ) : (
                        <>
                          <div>
                            <p><strong>Name:</strong> {contact.sName}</p>
                            <p><strong>Phone:</strong> {contact.sPhone}</p>
                            <p><strong>Email:</strong> {contact.Email1}</p>
                            <p><strong>Rep:</strong> {repNames[contact.sRep] || contact.sRep}</p>
                            <p><strong>Tier:</strong> {contact.sTier || '3'}</p>
                          </div>
                          <EditButton style={{ marginTop: '10px' }} onClick={() => this.handleEditClick(contact.id)}>Edit</EditButton>
                        </>
                      )}
                    </ContactItem>
                  ))
                ) : (
                  <p>No contacts available for this company.</p>
                )}
              </ContactList>

              {!creatingContact && (
                <Button style={{ marginTop: '10px' }} onClick={this.handleCreateContact}>Create Contact</Button>
              )}

              {creatingContact && (
                <>
                  <h3>Create Contact</h3>
                  <InputGroup>
                    <label>Name</label>
                    <input
                      type="text"
                      value={newContact.name}
                      onChange={(e) => this.handleNewContactChange('name', e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup>
                    <label>Email</label>
                    <input
                      type="email"
                      value={newContact.email}
                      onChange={(e) => this.handleNewContactChange('email', e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup>
                    <label>Phone</label>
                    <input
                      type="text"
                      value={newContact.phone}
                      onChange={(e) => this.handleNewContactChange('phone', e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup>
                    <label>Rep</label>
                    <input
                      type="text"
                      value={newContact.rep}
                      onChange={(e) => this.handleNewContactChange('rep', e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup>
                    <label>Tier</label>
                    <select
                      value={newContact.tier}
                      onChange={(e) => this.handleNewContactChange('tier', e.target.value)}
                    >
                      <option value="1">Tier 1</option>
                      <option value="2">Tier 2</option>
                      <option value="3">Tier 3</option>
                      <option value="4">Tier 4</option>
                      <option value="5">Tier 5</option>
                      <option value="6">Tier 6</option>
                    </select>
                  </InputGroup>
                  <ButtonGroup>
                    <Button onClick={this.handleSubmitNewContact}>Save Contact</Button>
                    <Button onClick={() => this.setState({ creatingContact: false })} style={{ backgroundColor: '#ccc' }}>Cancel</Button>
                  </ButtonGroup>
                </>
              )}
            </>
          )}
        </ModalBody>
      </ModalWrapper>
    );
  }
}

const removeCommas = (value) => value.replace(/,/g, '');

export default CompanyDetailsModal;
