import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Moment from 'moment';
import Axios from 'axios';
import Loader from 'react-loader-spinner';
import { dateFormat, dateFormatter, nQuoteTotalNoDollar } from '../../../assets/functions/calculations';

const Wrapper = styled.div`
  width: 99%;
  max-width: 1600px;
  margin: 5em auto;

  table {
    width: 100%;
    position: relative;
    ${(props) => {
      if (props.amount > 10)
        return `
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;

    thead + tbody tr:last-child td:last-child,
    tfoot tr:last-child td:last-child {
      border-bottom-right-radius: 0px !important;
    }
    thead + tbody tr:last-child td:first-child,
    tfoot tr:last-child td:first-child {
      border-bottom-left-radius: 0px !important;
    }
    margin-bottom: 7em;
        `;
    }}
  }

  button {
    position: absolute;
    width: 100%;
    bottom: -27px;
     padding: 0.35em 1.2em;
     text-decoration: none;
     color: #fafafa;
     text-align: center;
     transition: all 0.2s;
    background-color: #4aabed;
    border: 1px solid #4aabed;
    cursor: pointer;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    &:hover {
      color: #222;
      background-color: #fafafa;
      border: 1px solid #d7d7d7;
    }
  }

  i {
    color: #999;
    transition: 0.2s;

    &:hover {
      color: black;
    }
  }
`;

const SubTitle = styled.h2`
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1em;
`;

export default class LostCustomers extends Component {
  state = {
    data: [],
    loaded: false,
    showAll: false,
  };

  // Async Axios
  fetchData = async (sQuoteType) => {
    try {
      const response = await Axios.get('/cam/lost-customers', {
        params: {
          sQuoteType: sQuoteType,
        },
        headers: { 'Content-Type': 'application/json' },
      });
      // // Sets the state using the response data
      this.setState({
        data: response.data.result[0].recordset,
        customers: response.data.result[1].recordset,
        loaded: true,
      }, () => this.fixCustomers());
    } catch (error) {
      console.error(error);
    }
  };

  fixCustomers() {
    let newCustomers = []

    for(let i=0; i<this.state.customers[this.state.customers.length-1].id; i++){
      newCustomers[i] = {sName: "", id: i}
    }

    for(let i=0; i<this.state.customers.length; i++){
      newCustomers[this.state.customers[i].id] = this.state.customers[i]
    }

    console.log(newCustomers)

    this.setState({customers: newCustomers, isLoaded: true,})
  }

  componentDidMount() {
    this.fetchData(this.props.sQuoteType);
  }

  // Handles marking a quote as a valid discontinue
  handleClick(id) {
    console.log('test');
    Axios({
      method: 'post',
      url: '/mark-discontinued-quote',
      data: {
        id: id,
      },
    });
    this.fetchData(this.props.sQuoteType);
  }

  render() {
    let table;

    // If the state showAll is false then table with return with only 10 rows, else show all 100
    if (this.state.showAll === false) {
      table = (
        <>
          {this.state.data.slice(0, 10).map((sql,index) => (
            <tr key={index}>
              <td>
                <Link
                  target="_blank"
                  to={`/crm/master-viewer/${sql.nCompany}`}
                >
                  {this.state.customers[sql.nCompany].sName}
                </Link>
              </td>
              <td>{nQuoteTotalNoDollar(sql.nPastSales)}</td>
              <td>{dateFormat(sql.dtLastOrder)}</td>
              <td>{sql.nNewQuotes}</td>
              <td>{sql.nOpenQuotes}</td>
            </tr>
          ))}
        </>
      );
    } else {
      table = (
        <>
          {this.state.data.map((sql,index) => (
            <tr key={index}>
            <td>
              <Link
                target="_blank"
                to={`/crm/master-viewer/${sql.nCompany}`}
              >
                {this.state.customers[sql.nCompany].sName}
              </Link>
            </td>
            <td>{nQuoteTotalNoDollar(sql.nPastSales)}</td>
            <td>{dateFormat(sql.dtLastOrder)}</td>
            <td>{sql.nNewQuotes}</td>
            <td>{sql.nOpenQuotes}</td>
          </tr>
          ))}
        </>
      );
    }

    return (
      <Wrapper amount={this.state.data.length}>
        <SubTitle>Lost Customers</SubTitle>
        <table className="dashboard">
          <thead>
            <tr>
              <th style={{ width: '40%' }}>Customer</th>
              <th style={{ width: '10%' }}>Past Sales</th>
              <th style={{ width: '10%' }}>Last Order</th>
              <th style={{ width: '10%' }}>New Quotes</th>
              <th style={{ width: '10%' }}>Open Quotes</th>
            </tr>
          </thead>
          <tbody>
            {this.state.loaded || (
              <tr>
                <td colSpan="10">
                  <Loader
                    style={{
                      textAlign: 'center',
                    }}
                    type="ThreeDots"
                    color="#00BFFF"
                    height={20}
                    width={120}
                  />
                </td>
              </tr>
            )}
            {this.state.loaded && (
              <>
              {console.log(this.state.data)}
                {this.state.data.length > 0 ? (
                  <>{table}</>
                ) : (
                  <tr>
                    <td
                      colSpan="10"
                      style={{ textAlign: 'center', padding: '1em' }}
                    >
                      No quotes
                    </td>
                  </tr>
                )}
                {this.state.data.length > 10 ? (
                  <>
                    {this.state.showAll ? (
                      <button
                        onClick={() => {
                          this.setState({
                            showAll: false,
                          });
                        }}
                      >
                        Hide
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          this.setState({
                            showAll: true,
                          });
                        }}
                      >
                        Show All
                      </button>
                    )}
                  </>
                ) : null}
              </>
            )}
          </tbody>
        </table>
      </Wrapper>
    );
  }
}
