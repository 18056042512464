import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin-top: 1em;
  width: 100%;
  height: fit-content;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.1);
  text-align: center;
  border-radius: 15px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4aabed;
  color: #fff;
  height: 35px;
  font-weight: bold;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
`;

const Form = styled.form`
  margin: 1em;

  div {
    width: 98%;
    margin: auto;
  }

  input {
    height: 40px;
    width: 100%;
    padding: 0 0.7em;
    border: 1px solid #fff;
    border-radius: 5px;
    transition: box-shadow 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    background-color: #f2f5f7;
  }

  p {
    text-align: left;
    margin: 0.4em 0 0.4em 0;
    font-weight: 500;
  }
`;

export default class PoInfo extends Component {
  render() {
    return (
      <Wrapper>
        <Title>PO Information</Title>
        <Form autoComplete="off">
          <div>
            <div>
              <p>PO Number</p>
              <input
                id="poNumber"
                name="poNumber"
                type="text"
                value={this.props.sPONumber}
                onChange={(event) => this.props.update("sPONumber", event.target.value)}
              ></input>
            </div>
            <div>
              <p>Contact</p>
              <input
                id="poContact"
                name="poContact"
                type="text"
                value={this.props.sPOContact}
                onChange={(event) => this.props.update("sPOContact", event.target.value)}
              ></input>
            </div>
            <div>
              <p>Phone</p>
              <input
                id="poPhone"
                name="poPhone"
                type="text"
                value={this.props.sPOPhone}
                onChange={(event) => this.props.update("sPOPhone", event.target.value)}
              ></input>
            </div>
          </div>
        </Form>
      </Wrapper>
    );
  }
}
