import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Axios from 'axios';
import Moment from 'moment';
import Loader from 'react-loader-spinner';
import { whichRep, abbreviateBrand, calcMilesFromLatLong, nQuoteTotalNoDollar } from '../../assets/functions/calculations';
import MediaQuery from 'react-responsive';
import { thisWeekEnd } from '../../assets/functions/time';
import HeatMap from '../misc/HeatMap';
import moment from 'moment';
import { CSVLink } from 'react-csv';

const PageWrapper = styled.div`
  display: block;
  position: fixed;
  z-index: 50;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: #222;
  opacity: 0.5;
`;

const Modal = styled.div`
  display: block;
  position: fixed;
  z-index: 55;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 90%;
  overflow: hidden;
  background-color: #fff;
  opacity: 1;
  border-radius: 10px;

`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: left;
  background-color: #fefefe;
  margin: auto;
  padding: 0.5em;
  width: 90%;
  overflow: hidden;

  h2 {
    text-align: center;
  }

  p {
    text-align: left;
    font-size: 1rem;
    margin-top: 1em;
  }

  span {
    font-weight: bold;
  }

  input {
    width: 100%;
    max-width: 400px;
    padding: 0.7em;
    margin: 0.5em auto 0 auto;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  select {
    width: 100%;
    max-width: 400px;
    padding: 0.7em;
    margin: 0.5em auto 0 auto;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
`;

const Wrapper = styled.section`
  padding: 1em 0em;
  text-align: center;

  @media (max-width: 900px) {
    padding-bottom: 5em;
  }
`;

const DashboardControls = styled.section`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin: 3em auto 0 auto;
  padding: 2em 4em;
  width: fit-content;
  height: fit-content;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.1);
  text-align: center;
  border-radius: 15px;

  button {
    display: inline-block;
     padding: 0.5em 1.2em;
     margin: 0 0.3em 0.3em 0;
     text-decoration: none;
     color: #333;
     text-align: center;
     transition: all 0.2s;
    background-color: #f2f5f7;
    border: 1px solid rgba(255, 255, 255, 0);
    cursor: pointer;

    &:hover {
      background-color: #36a2eb;
      border: 1px solid #36a2eb;
      color: #fff;
    }
  }

  select {
    display: inline-block;
    color: #333;
     text-align: center;
     padding: 0.3em 1.2em;
     margin: 0 0.3em 0.3em 0;
    background-color: #f2f5f7;
    border: 1px solid #f2f5f7;
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
      background-color: #36a2eb;
      border: 1px solid #36a2eb;
      color: #fff;
    }
  }
`;

const LoaderWrapper = styled.div`
  margin-top: 25vh;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 1em;
`;

const SubTitle = styled.h2`
  padding: 2em 0 0 0;
  text-align: center;
`;

const CustomDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    margin: 1em 0;
  }

  input {
    width: 140px;
     padding: 0.2em 0em 0.2em 0.2em;
     margin: 0 0 0.5em 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;

    @media (max-width: 900px) {
      width: auto;
      margin: auto;
    }
  }
`;

const AlertMessage = styled.p`
  font-weight: bold;
`;

class CustomQuery extends Component {
  state = {
      query: "",
      name: "",
      queryValid: false,
      results: {},
      columns: [],
      dataValid: false,
      loading: false,
      saved: [],
      saveLoaded: false,
      deleteConfirm: false,
      showDelete: false,
  };

  getSavedQuery = async () => {
    try {
      const response = await Axios.get('/custom-query/load', {
        params: {

        },
        headers: { 'Content-Type': 'application/json' },
      });
      // Sets the state using the response data
      this.setState({
        saved: response.data.result[0].recordset, saveLoaded: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  checkQuery = () => {
    let query = this.state.query;
    let testQuery = this.state.query.toLowerCase();
    let queryValid = (!(testQuery.includes("insert into") 
          || ((testQuery.match(/update/g) || []).length > (testQuery.match(/pickupdate/g) || []).length+(testQuery.match(/lastupdate/g) || []).length) 
          || ((testQuery.match(/delete/g) || []).length > (testQuery.match(/bdeleted/g) ||  []).length > (testQuery.match(/bdelete/g) || []).length) 
          || testQuery.includes("drop table")
          || testQuery.includes("create table") 
          || testQuery.includes("drop database") 
          || testQuery.includes("create database") 
          || testQuery.includes("alter table"))) 
          && testQuery.includes("select")
    
    console.log()
    console.log()
    this.setState({queryValid: queryValid})
    if(queryValid){
      this.setState({loading: true})
      
      this.fetchData()
    }else{
      this.setState({results: {}, dataValid: false, loading: false})
    }
    console.log(queryValid)
  }

  fetchData = async () => {
    try {
      const response = await Axios.get('/custom-query', {
        params: {
          query: this.state.query
        },
        headers: { 'Content-Type': 'application/json' },
      });
      // Sets the state using the response data
      this.setState({
        results: response.data.result[0].recordset
      }, () => this.checkData());
    } catch (error) {
      console.error(error);
    }
  };

  checkData = () => {
    let results = this.state.results
    let columns = []
    if(results){
      for(let key in results[0]){
        columns.push(key)        
      }
      
      if(columns.length>0){
        this.setState({
          columns: columns,
          dataValid: true,
          loading: false,
        })
      }

    }
    if(!results || columns.length == 0){
      this.setState({dataValid: false, loading: false,})
    }
  }

  saveQuery = async () => {
      console.log(this.state.query)
    try {
      const response = await Axios.post(
        "/custom-query/save",
        {
          sQuery: this.state.query,
          sName: this.state.name,
        },
      );
      if(response){this.getSavedQuery()}
    } catch (error) {
      console.error(error)
    }
  }
  deleteQuery = async (id) => {
    if(this.state.deleteConfirm){
      try {
        const response = await Axios.post(
          "/custom-query/delete",
          {
            id: id
          },
        );
        if(response){this.setState({deleteConfirm: false}, ()=>this.getSavedQuery())}
      } catch (error) {
        console.error(error)
      }
    } else {
      this.setState({deleteConfirm: true})
    }
    
  }

  componentDidMount() {
    this.getSavedQuery()
    window.addEventListener('keydown',(event) => {

      if(event.key === '`'){

        this.setState({showDelete: !this.state.showDelete})
        
      }
    })
  }

  componentWillUnmount() {

  }
 
  render() {
    return (
      <Wrapper>
          <DashboardControls>
            <div>
              <Title>Custom Search</Title>
            </div>
            <table>
              <tr>
              <td>
                <div>Custom Query 
                  <textarea style={{width: '600px', height: '200px'}} onChange={(event)=>this.setState({query: event.target.value})} value={this.state.query}></textarea>
                </div>
                <div>
                  <button onClick={()=>this.checkQuery()}>Search</button>
                </div>
              </td>
            
            {this.state.saveLoaded && (
              <td>
                {this.state.saved.map((save,index) => (
                  <div style={{display:'inline-block'}}>
                    <button onClick={()=>this.setState({query: save.sQuery})}>Load {save.sName}</button>
                    {this.state.showDelete && (<button onClick={()=>this.deleteQuery(save.id)}>{this.state.deleteConfirm ? "Confirm Delete" : "Delete"}</button>)}
                  </div>
                ))}
              </td>
            )}
              </tr>
            </table>
          </DashboardControls>
          {this.state.dataValid && (
            <>
            <div><button onClick={()=>this.saveQuery()}>Save Query</button><input onChange={(event)=>this.setState({name: event.target.value})}></input></div>
            <CSVLink data={this.state.results} headers={this.state.columns}>
              Download as CSV
            </CSVLink>
            <table class="dashboard">
              <thead>
                {this.state.columns.map((column, index) => (
                  <th>{column}</th>
                ))}
              </thead>
              {this.state.results.map((result, index) => (
                <tr>
                  {this.state.columns.map((column, index) => (
                    <>
                      {((column === "Quote" && (
                        <td><Link target="_blank" to={`/quote/${result[column]}`}>
                          {result[column]}
                        </Link></td>
                      )) || (
                        <td>{result[column]}</td>
                      ))}
                    </>
                  ))}
                </tr>
              ))}

            </table>
            </>
          )}
          {this.state.loading && (
            <LoaderWrapper>
              <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} />
            </LoaderWrapper>
          )}
      </Wrapper>
    );
  }
}

export default CustomQuery;
