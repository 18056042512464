import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { cGreenText, nQuoteTotalNoDollar, whichRep } from '../../../../assets/functions/calculations';
import moment from 'moment';
import { CustomDateWrapper, Input, SubmitText, GreyTextDiv, Title } from '../../../styled/ui';



export default class ContractorFuture extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    
    return (
      <>
      <Title>Contractor - Future Installs</Title>
      <div>Installs will move to the Contractor - Ongoing Installs report on the Target Start date.</div>
      <table class="dashboard">
          <thead>
            <tr>
              <th>Quote</th>
              <th>Company</th>
              <th>Contact</th>
              <th>Title</th>
              <th>Price</th>
              <th>Labor</th>
              <th>First Closed</th>
              <th>Rep</th>
              <th style={{width: '300px'}}>Quote Tag</th>
              <th>Shipments</th>
              <th>Target Start</th>
              <th>Target Complete</th>
            </tr>
          </thead>
          <tbody>
                {this.props.data.length > 0 && (
                    <>
                      {this.props.data.map((sql, index) => (
                        <>
                        <tr key={sql.id}>
                          <td><Link target="_blank" to={`/quote/${sql.id}`}>{sql.id}</Link></td>
                          <td>
                            <div>{sql.sDCompany}</div>
                            <div>{sql.sDCity}, {sql.sDState} {sql.sDZip}</div>
                          </td>
                          <td>
                            <div>{sql.sDName}</div>
                            <div>{sql.sDPhone}</div>
                          </td>
                          <td>{sql.sQuoteTitle}</td>
                          <td>{nQuoteTotalNoDollar(sql.nQuoteTotal)}</td>
                          <td onClick={()=>this.props.handleShowPO(sql.index)}>{nQuoteTotalNoDollar(sql.nLaborTotals)}</td>
                          <td>{moment(sql.dtFirstClosed).utc().format('MM/DD/YYYY')}</td>
                          <td>{sql.nRep ? whichRep(sql.nRep) : null}</td>                    
                          <td>
                            <textarea style={{height: '80px', width: '100%'}} onInput={(event) => this.props.handleUpdateTag(event.target.value, index)} value={sql.sQuoteTags}></textarea>
                            {sql.changed && (<div onClick={() => this.props.handleSaveTag(sql.sQuoteTags, sql.id)}>Save Tag</div>)}
                          </td>
                          
                          <td style={{fontSize: '11px'}}>
                            {sql.outbound.map((tracking, index2) => (
                              <div style={{color: tracking.sStatusCode === "DE" ? cGreenText : ''}}>
                                <input type="checkbox" checked={tracking.bCompleted} onClick={()=>this.props.handleConfirmShipment(tracking)}></input>
                                <a href={"https://www.google.com/search?q="+tracking.sTrackingNumber} target="_blank">
                                  {tracking.sTrackingNumber} {tracking.sStatusCode} {tracking.sStatusCode === "DE" ? moment(tracking.dtRealDelivery).format("MM-DD") : moment(tracking.dtEstimatedDelivery).format("MM-DD")}
                                </a>
                              </div>
                            ))}
                          </td>
                          <td>
                            <CustomDateWrapper><Input onChange={(e) => {this.props.handleDateChange(e, sql.index, 'dtTargetStart')}} type="date" id="date" name="date" 
                              value={moment(sql.dtTargetStart).utc().format('YYYY-MM-DD')} /></CustomDateWrapper>
                            <SubmitText onClick={() => this.props.handleDateSubmit(sql.dtTargetStart,sql.id,"dtTargetStart")}>Submit</SubmitText>
                          </td>
                          <td>
                            <CustomDateWrapper><Input onChange={(e) => {this.props.handleDateChange(e, sql.index, 'dtTargetComplete')}} type="date" id="target" name="target"
                              value={moment(sql.dtTargetComplete).utc().format('YYYY-MM-DD')} />
                            </CustomDateWrapper>
                            <SubmitText onClick={() => this.props.handleDateSubmit(sql.dtTargetComplete,sql.id,"dtTargetComplete")}>Submit</SubmitText>
                          </td>
                        </tr>
                        {sql.showPOs && (
                          <tr>
                            <td colSpan={12}>
                            <table>
                              <thead>
                                <tr>
                                  <th>Vendor</th>
                                  <th>Description</th>
                                  <th>Quantity</th>
                                  <th>Price</th>
                                  <th>Total Price</th>
                                  <th>Item</th>
                                </tr>
                              </thead>
                              {sql.POLines.map((line, lineNumber) => (
                                <tr key={lineNumber}>
                                  <td>{line.sCompany}</td>
                                  <td>{line.sDescription}</td>
                                  <td>{line.nQuantity}</td>
                                  <td>{nQuoteTotalNoDollar(line.nPrice)}</td>
                                  <td>{nQuoteTotalNoDollar(line.nQuantity*line.nPrice)}</td>
                                  <td onClick={()=>this.props.handleSetLabor(sql.index, lineNumber)}>{line.nItem === 832 && (
                                    <>CSN Labor</>
                                  ) || (
                                    <GreyTextDiv>Set as CSN Labor</GreyTextDiv>
                                  )}</td>
                                </tr>
                              ))}
                            </table>
                            </td>
                          </tr>
                        )}
                        </>
                      ))}
                    </>
                )}

          </tbody>
        </table>
        </>
    );
  }
}