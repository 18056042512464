import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  height: fit-content;
  text-align: center;

  button {
    cursor: pointer;
    width: 85%;
    margin: 0.3em auto;
    height: 40px;
    border: none;
    transition: all 0.2s;
    border-radius: 4px;

    &:hover {
      background-color: #4aabed;
      color: #fff;
    }
  }

  ul {
    list-style: none;
    width: 85%;
    margin: 0.7em auto 0 auto;
    padding: 0.8em;
    border: 1px solid #d7d7d7;
    border-radius: 4px;
    background-color: #fff;

    li {
      text-align: left;
      cursor: pointer;
      margin: 0.3em 0;

      &:hover {
        background-color: #efefef;
      }
    }
  }

  p {
    font-weight: bold;
    text-align: left;
    margin: 0.35em 0 0 8%;

    &:nth-child(11) {
      margin: 1em 0 0 3.4em;
    }
  }

  i {
    position: static;
    font-size: 1rem;
    color: #d5dee5;
    transition: 0.3s;
    cursor: pointer;
    margin-right: 0.3em;

    &:hover {
      color: #444;
    }
  }
`;

export default class Attachments extends Component {
  state = {};

  handleOnClick = (event) => {
    let tab = event.target.textContent.replace(/\s/g, '');
    this.props.onClick(tab);
  };

  render() {
    return (
      <Wrapper>
        <ul>
          {this.props.attachments.map((sql) => (
            <li>
              <i class="fas fa-trash-alt"></i>
              <i class="far fa-edit"></i> {sql.sName}
            </li>
          ))}
        </ul>
        <button>Attach New File</button>
      </Wrapper>
    );
  }
}
