import React, { Component } from 'react';
import { getSQLData, setSQLData } from '../../assets/functions/fetch';

class TakeNewQuote extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            confirm: 0,
        };
      }

    componentDidMount() {
        this.fetchData()
      }
    
      fetchData = async () => {
    
        try {
          const fetch = await getSQLData("quote/bestAvailableQuote", {})

          
          if(fetch){
    
            let data = fetch.data.data[0].recordset ? fetch.data.data[0].recordset[0] : null

            this.setState({
              data: data,

            }, () => this.processData())
          }
             
        } catch (error) {
          console.error(error);
        }
      }

      
      processData() {
        let data = this.state.data
        let loaded = this.state.loaded

        const testQuote = {
            id: 1234567,
            sName: "Test",
            sCompany: "Test",
            sTimeZone: "Test",
            sAddress: "Test",
            sCity: "Test",
            sState: "Test",
            sZip: "Test",
            sPhone: "Test",
            sEmail: "Test",
            sQuote: "Test",
            dtDeliveryDate: "Test",
            sReferringSite: "Test",
            sType: "Test",
            sLeadSource: "Test",
            dtDateStamp: "Test",
        }

        if(!data){
            data = testQuote
        }

        if(false && data.nPotential > 0){
            this.handleClaimLead()
        }

        this.setState({
            data: data,
            loaded: true, 
        })

      }

      handleClaimLead = async() => {
        try {
            let nRep = this.props.user
            let id = this.state.data.id

            const claimLead = setSQLData("quote/claimLead", {
                id: id,
                nRep: nRep,
            })

        } catch (error) {
        console.error(error);
        }
      }

      handleDiscontinueAsSpam = async(id) => {
        try {
            let confirm = this.state.confirm
            if(confirm === id){
                const discontinueQuote = setSQLData("admin/discontinueAsSpam", {
                    id: id,
                })
            } else {
                this.setState({
                    confirm: id,
                })
            }

            
        } catch (error) {
          console.error(error);
        }
      }


    render() {
        let quote = this.state.data
        let confirm = this.state.confirm
        let loaded = this.state.loaded

        return (
            <div>
                {loaded && (
                    <>
                        {quote ? (
                        <table>
                            <tr>
                                <td colSpan={2}><button 
                                    onClick={()=>this.handleDiscontinueAsSpam(quote.id)}>
                                    {confirm === quote.id ? "Confirm Discontinue?" : "Discontinue As Spam"}
                                </button></td>
                            </tr>
                            <tr>
                                <td>Quote</td>
                                <td>{quote.id}</td>
                            </tr>
                            <tr>
                                <td>Name/Company</td>
                                <td>{quote.sName}<br />
                                {quote.sCompany}</td>
                            </tr>
                            <tr>
                                <td>Time Zone</td>
                                <td>{quote.sTimeZone}</td>
                            </tr>
                            <tr>
                                <td>Address</td>
                                <td>{quote.sAddress}<br />
                                {quote.sCity}, {quote.sState} {quote.sZip}</td>
                            </tr>
                            <tr>
                                <td>Contact</td>
                                <td>{quote.sPhone}<br />
                                {quote.sEmail}</td>
                            </tr>
                            <tr>
                                <td>Request</td>
                                <td>{quote.sQuote}</td>
                            </tr>
                            <tr>
                                <td>Delivery-Pickup</td>
                                <td>{quote.dtDeliveryDate} - <br />
                                {quote.dtPickupDate}</td>
                            </tr>
                            <tr>
                                <td>Referring Site</td>
                                <td>{quote.sReferringSite}</td>
                            </tr>
                            <tr>
                                <td>Type-Source</td>
                                <td>{quote.sType} - <br />
                                {quote.sLeadSource}</td>
                            </tr>
                            <tr>
                                <td>Date/Time</td>
                                <td>{quote.dtDateStamp}</td>
                            </tr>
                        </table>
                        ) : (
                            <div>No Leads Available</div>
                        )}
                    </>
                )}
            </div>
        );
    }
}

export default TakeNewQuote;