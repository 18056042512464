import React, { Component } from 'react';
import ClosedQuotesModal from '../tables/marketing-wizard/ClosedQuotesModal';
import DiscontinuedQuotesModal from '../tables/marketing-wizard/DiscontinuedQuotesModal';
import { ButtonContainer, Wrapper, ViewButton } from '../tables/marketing-wizard/StyledComponents';
import BlastEmailEditor from '../tables/marketing-wizard/BlastEmailEditor';

export default class TierWizard extends Component {
  state = {
    view: null,
  };

  handleViewChange = (viewType) => {
    this.setState({ view: viewType });
  };

  render() {
    const { view } = this.state;

    return (
      <Wrapper>
        <ButtonContainer>
          <ViewButton onClick={() => this.handleViewChange('closed')}>
            View Closed Quotes
          </ViewButton>
          <ViewButton onClick={() => this.handleViewChange('discontinued')}>
            View Discontinued Quotes
          </ViewButton>
          <ViewButton onClick={() => this.handleViewChange('blast')}>
            Edit Blast Emails
          </ViewButton>
        </ButtonContainer>

        {view === 'closed' && <ClosedQuotesModal />}
        {view === 'discontinued' && <DiscontinuedQuotesModal />}
        {view === 'blast' && <BlastEmailEditor />}
      </Wrapper>
    );
  }
}