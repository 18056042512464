import moment from 'moment';
import React, { Component } from 'react'
import { getSQLData } from '../../../assets/functions/fetch';
import UnderwritingLinks from './UnderwritingLinks';
import UnderwritingQuestions from './UnderwritingQuestions';
import { checkQuestions, matchPOandLines, showQuestions, underwritingLinks, underwritingQuestions } from '../../../assets/functions/underwriting';
import UnderwritingHeader from './UnderwritingHeader';


export default class UnderwritingTool extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loaded: false,
          taskID: 19498,
          viewing: 0,
          pages: 7,
        };
    
        this.clickLink = this.clickLink.bind(this)
        this.changeAnswer = this.changeAnswer.bind(this)
      }
      componentDidMount() {
        this.fetchData()
      }

      fetchData = async () => {

        try {

          console.log(this.props.quoteID)
          
            const fetchTask = await getSQLData("underwriting/getTask", {taskID: this.state.taskID,})
            const fetchQuote = await getSQLData("underwriting/getQuoteInfo", {quoteID: this.props.quoteID,})
            const fetchLines = await getSQLData("underwriting/getQuoteLines", {quoteID: this.props.quoteID,})  
            const fetchPOs = await getSQLData("underwriting/getQuotePOs", {quoteID: this.props.quoteID,})  
            const fetchPOLines = await getSQLData("underwriting/getQuotePOLines", {quoteID: this.props.quoteID,})  
            
            if(fetchTask && fetchQuote && fetchLines && fetchPOs && fetchPOLines){
                console.log(fetchPOs.data.data[0].recordset, fetchPOLines.data.data[0].recordset)
                let task = fetchTask.data.data[0].recordset[0]
                let quote = fetchQuote.data.data[0].recordset[0]
                let lines = fetchLines.data.data[0].recordset
                let POs = fetchPOs.data.data[0].recordset
                let POLines = fetchPOLines.data.data[0].recordset

                

                this.setState({
                    task: task,
                    quote: quote,
                    lines: lines,
                    POs: POs,
                    POLines: POLines,
                }, () => this.processData())
            }
          
        } catch (error) {
          console.error(error);
        }
      }
      
      processData(){
        let task = this.state.task
        let quote = this.state.quote
        let lines = this.state.lines
        let POs = this.state.POs
        let POLines = this.state.POLines

        let data = {}

        data.dtSubmitted = moment(task.dtAdded)
        data.dtTimeStarted = moment()
        data.nQuote = this.props.quoteID
        data.nRepRequest = task.nAddedBy
        data.nRepComplete = this.props.user

        let links = underwritingLinks(task, quote, lines, data)

        let questions = underwritingQuestions()

        questions = showQuestions(questions)

        for(let i=0; i<questions; i++){
            questions[i].index = i
        }

        POs = matchPOandLines(POs, POLines)

        this.setState({
            task: task,
            quote: quote,
            lines: lines,
            POs: POs,
            data: data,
            links: links,
            questions: questions,
            loaded: true,
        })
      }

      clickLink(index) {
        let links = this.state.links

        links[this.state.viewing][index].clicked = true

        this.setState({
            links: links,
        })
      }

      changeAnswer(index, answer) {
        let questions = this.state.questions

        if(questions[index].type==="int"){
            if(!answer || answer === 0 || answer === "" || answer === null){
                questions[index].answer = 0
            } else {
                if(!isNaN(parseInt(answer))){
                    questions[index].answer = parseInt(answer)
                }
            }
        } else {
            questions[index].answer = answer
        }

        questions = showQuestions(questions)

        this.setState({
            questions: questions,
        })
      }

      tryMoveForward() {
        if(checkQuestions(this.state.questions,this.state.viewing)){this.setState({viewing:this.state.viewing+1})}
      }

  render() {
    return (
      <div>
        {this.state.loaded && (
            <>
                <UnderwritingHeader
                    data={this.state.data}
                    questions={this.state.questions}
                    viewing={this.state.viewing}
                    quote={this.state.quote}
                    lines={this.state.lines}
                    POs={this.state.POs}
                />
                <br />
                <UnderwritingLinks 
                    viewing={this.state.viewing}
                    links={this.state.links}
                    clickLink={this.clickLink}
                />
                <br />
                <br />
                <UnderwritingQuestions
                    questions={this.state.questions}
                    viewing={this.state.viewing}
                    changeAnswer={this.changeAnswer}
                />
                {this.state.viewing > 0 && (
                    <button onClick={()=>this.setState({viewing: this.state.viewing-1})}>&#8592;Back</button>
                )}
                {this.state.viewing < this.state.pages-1 && checkQuestions(this.state.questions,this.state.viewing) && (
                    <button onClick={()=>this.tryMoveForward()}>Next&#8594;</button>
                )}
            </>
        )}
      </div>
    )
  }
}
