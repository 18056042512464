import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Axios from 'axios';
import Moment from 'moment';
import Loader from 'react-loader-spinner';
import MediaQuery from 'react-responsive';
import { calcMilesFromLatLong, whichRep } from '../../../assets/functions/calculations';

const Wrapper = styled.div`
  width: 99%;
  margin: 0 auto;
  max-width: 1600px;

  table {
    width: 100%;
    position: relative;
    ${(props) => {
      if (props.amount > 10)
        return `
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;

    thead + tbody tr:last-child td:last-child,
    tfoot tr:last-child td:last-child {
      border-bottom-right-radius: 0px !important;
    }
    thead + tbody tr:last-child td:first-child,
    tfoot tr:last-child td:first-child {
      border-bottom-left-radius: 0px !important;
    }
    margin-bottom: 7em;
        `;
    }}
  }

  button {
    position: absolute;
    width: 100%;
    bottom: -27px;
     padding: 0.35em 1.2em;
     text-decoration: none;
     color: #fafafa;
     text-align: center;
     transition: all 0.2s;
    background-color: #4aabed;
    border: 1px solid #4aabed;
    cursor: pointer;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    &:hover {
      color: #222;
      background-color: #fafafa;
      border: 1px solid #d7d7d7;
    }
  }
`;

const SubTitle = styled.h2`
  padding: 2em 0 0 0;
  text-align: center;
`;

const CustomDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    margin: 1em 0;
  }

  input {
    width: 140px;
     padding: 0.2em 0em 0.2em 0.2em;
     margin: 0 0 0.5em 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;

    @media (max-width: 900px) {
      width: auto;
      margin: auto;
    }
  }
`;

const AlertMessage = styled.p`
  font-weight: bold;
`;

export default class NoSiteVisits extends Component {
  state = {
    data: [],
    loaded: false,
    showAll: false,
    hideAll: false,
    planned: 0,
    notSent: 0,
    sent: 0,
    totalLead: 0,
    avgLead: 0,
    count: 0,
  };

  // Async Axios
  fetchData = async () => {
    try {
      const response = await Axios.get('/cam-operations/no-site-visits', {
        params: {
          startDate: Moment().startOf('year').format('YYYY-DD-MM 00:00'),
          endDate: Moment().endOf('day').format('YYYY-DD-MM 23:59'),
        },
        headers: { 'Content-Type': 'application/json' },
      });
      // Sets the state using the response data
      this.setState({
        data: response.data.result[0].recordset,
        zipcodes: response.data.result[1].recordset,
        
      }, () => this.summarizeData());
    } catch (error) {
      console.error(error);
    }
  };

  getLatLong = (getZip) => {
    let latitude = 0
    let longitude = 0

    if(getZip){    
      if(getZip.length === 5 && parseInt(getZip)>0 && parseInt(getZip)<=99999){
        
        for(let i=0; i< this.state.zipcodes.length; i++){
          if(parseInt(this.state.zipcodes[i].sZipCode) === parseInt(getZip)){
            latitude = this.state.zipcodes[i].sLatitude
            longitude = this.state.zipcodes[i].sLongitude
            i=this.state.zipcodes.length
          }
        }
      }
    }
    return {latitude: latitude, longitude: longitude}
  }

  summarizeData = () => {
    let data = this.state.data
    let here = this.getLatLong('45005')
    let planned = 0
    let notSent = 0
    let sent = 0
    let totalLead = 0
    let avgLead = 0
    let count = 0

    for(let i=0; i<data.length; i++){
      let coords = this.getLatLong(data[i].sZip)

      data[i].latitude = coords.latitude
      data[i].lat = coords.latitude
      data[i].longitude = coords.longitude
      data[i].lng = coords.longitude

      data[i].distance = calcMilesFromLatLong({latitude: here.latitude, longitude: here.longitude}, coords)

      if(data[i].dtSiteVisit){
        let age = (Moment() - Moment(data[i].dtSiteVisit))/3600/24/1000
        data[i].age = age
        if (age <= 30){
          count++
          if(data[i].dtSentDate && data[i].leadTime){
            sent++
            totalLead += data[i].leadTime
          } else {
            notSent++
          }
        }
      } else {
        planned++
      }
      
    }
    avgLead = totalLead / sent

    console.log(data, totalLead, sent)

    this.setState({
      data: data,
      loaded: true,
      planned: planned,
      count: count,
      sent: sent,
      avgLead: avgLead,
      notSent: notSent,
    })
  }

  componentDidMount() {
    this.fetchData();
    //this.interval = setInterval(() => this.fetchData(), 5000);
  }

  componentWillUnmount() {
    // Stop fetching data when the user leaves the page and component unmounts
    //clearInterval(this.interval);
  }

  handleDateChange = (event, id) => {
    this.setState({
      [`customDate + ${id}`]: event.target.value,
    });
  };

  handleSubmitDate = (date, id) => {
    if (this.state[`customDate + ${id}`] === undefined) {
      this.setState({
        [`flashMessage2 + ${id}`]: 'Please change the date!',
      });
      this.interval = setInterval(
        () =>
          this.setState({
            [`flashMessage2 + ${id}`]: '',
          }),
        3000
      );
    } else {
      Axios({
        method: 'post',
        url: '/cam-operations/site-visit-complete-date',
        data: {
          dtSiteVisit: date,
          id: id,
        },
      });
      this.fetchData();
      this.setState({
        [`flashMessage + ${id}`]: 'Date changed!',
      });
      this.interval = setInterval(
        () =>
          this.setState({
            [`flashMessage + ${id}`]: '',
          }),
        3000
      );
    }
  };

  handleRemoveDate = (id) => {
    Axios({
      method: 'post',
      url: '/cam-operations/site-visit-delete-date',
      data: {
        id: id,
      },
    });
    this.fetchData();
    this.setState({
      [`flashMessage + ${id}`]: 'Date removed!',
    });
    this.interval = setInterval(
      () =>
        this.setState({
          [`flashMessage + ${id}`]: '',
        }),
      3000
    );
  };

  handleSentSubmitDate = (date, id) => {
    if (this.state[`customDate + ${id}`] === undefined) {
      this.setState({
        [`flashSentMessage2 + ${id}`]: 'Please change the date!',
      });
      this.interval = setInterval(
        () =>
          this.setState({
            [`flashSentMessage2 + ${id}`]: '',
          }),
        3000
      );
    } else {
      Axios({
        method: 'post',
        url: '/cam-operations/quote-sent-date',
        data: {
          dtSentDate: date,
          id: id,
        },
      });
      this.fetchData();
      this.setState({
        [`flashSentMessage + ${id}`]: 'Date changed!',
      });
      this.interval = setInterval(
        () =>
          this.setState({
            [`flashSentMessage + ${id}`]: '',
          }),
        3000
      );
    }
  };

  handleSentRemoveDate = (id) => {
    Axios({
      method: 'post',
      url: '/cam-operations/quote-sent-delete-date',
      data: {
        id: id,
      },
    });
    this.fetchData();
    this.setState({
      [`flashSentMessage + ${id}`]: 'Date removed!',
    });
    this.interval = setInterval(
      () =>
        this.setState({
          [`flashSentMessage + ${id}`]: '',
        }),
      3000
    );
  };

  updateTag(getTag, getIndex) {
    let data = this.state.data

    data[getIndex].sQuoteTags = getTag
    data[getIndex].changed = true

    this.setState({data: data})
  }

  saveTag(getRecord, getIndex){
    Axios({
      method: 'post',
      url: '/save-tag',
      data: {
        data: getRecord
      },
    });
    let data = this.state.data
    data[getIndex].changed = false
    this.setState({data: data})

  }

  render() {
    let table;

    // If the state showMore is false then table with return with only 10 rows, else show all 100
      table = (
        <>
          {this.state.data.map((sql,index) => (
            <tr key={sql.id}>
              <td>
                <Link target="_blank" to={`/quote/${sql.id}`}>
                  {sql.id}
                </Link>
              </td>
              <td>
                <Link
                  target="_blank"
                  to={`/crm/master-viewer/${sql.sCompany.replace(
                    /\//g,
                    '%2F'
                  )}`}
                >
                  {sql.sCompany}
                </Link>
              </td>
              <td>
                {sql.nQuoteTotal
                  ? '$' +
                    sql.nQuoteTotal.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })
                  : null}
              </td>
              <td>{Math.round(sql.distance)}</td>
              <MediaQuery minDeviceWidth={900}>
                <td>{sql.nRep ? whichRep(sql.nRep) : null}</td>
                <td>{sql.nRep2 ? whichRep(sql.nRep2) : null}<br />{sql.nRep3 ? whichRep(sql.nRep3) : null}</td>
                <td>{sql.sQuoteTitle}</td>
              </MediaQuery>
              <td>
                  <textarea style={{height: '80px', width: '100%'}} onInput={(event) => this.updateTag(event.target.value, index)}>{sql.sQuoteTags}</textarea>
                  {sql.changed && (<div onClick={() => this.saveTag(sql, index)}>Save Tag</div>)}
                </td>
              <td style={{ textAlign: 'center' }}>{sql.sStatus}</td>
              
              <td style={{ textAlign: 'center' }}>
                <CustomDateWrapper>
                  <input
                    onChange={(e) => {
                      this.handleDateChange(e, sql.id);
                    }}
                    type="date"
                    defaultValue={Moment(sql.dtSentDate)
                      .utc()
                      .format('YYYY-MM-DD')}
                  />
                </CustomDateWrapper>
                {this.state[`flashSentMessage + ${sql.id}`] ? (
                  <AlertMessage>
                    {this.state[`flashSentMessage + ${sql.id}`]}
                  </AlertMessage>
                ) : (
                  <>
                    {this.state[`flashSentMessage2 + ${sql.id}`] ? (
                      <AlertMessage>
                        {this.state[`flashSentMessage2 + ${sql.id}`]}
                      </AlertMessage>
                    ) : (
                      <div>
                        <span
                          onClick={() =>
                            this.handleSentSubmitDate(
                              this.state[`customDate + ${sql.id}`],
                              sql.id
                            )
                          }
                          style={{
                            textAlign: 'center',
                            cursor: 'pointer',
                            marginRight: '0.2em',
                          }}
                        >
                          Submit
                        </span>
                        <span
                          onClick={() => this.handleSentRemoveDate(sql.id)}
                          style={{
                            textAlign: 'center',
                            cursor: 'pointer',
                            marginLeft: '0.2em',
                          }}
                        >
                          Delete
                        </span>
                      </div>
                    )}
                  </>
                )}
              </td>
            </tr>
          ))}
        </>
      );
    

    return (
      <Wrapper amount={this.state.data.length}>
        <SubTitle onClick={() => this.setState({hideAll: !this.state.hideAll})}>Blind Quotes and Hardware Only
        {this.state.hideAll ? (
            <i
            style={{ padding: '5px', fontSize: '1.2rem' }}
            className="fas fa-arrow-alt-circle-down"
          ></i>
          ) : (
            <i
            style={{ padding: '5px', fontSize: '1.2rem' }}
            className="fas fa-arrow-alt-circle-up"
          ></i>
          )}
        </SubTitle>
        <div>Last 30 days</div>
        <div>Quotes Sent: {this.state.sent}</div>
        {!this.state.hideAll && (
          <>
          
        <table className="dashboard">
          <thead>
            <tr>
              <th>ID</th>
              <th>Company</th>
              <th>Quote Total</th>
              <th>Distance</th>
              <MediaQuery minDeviceWidth={900}>
                <th>Rep</th>
                <th>Project Manager</th>
                <th>Title</th>
              </MediaQuery>
              <th>Quote Tag</th>
              <th>Status</th>
              <th>Sent Date</th>
            </tr>
          </thead>
          <tbody>
            {this.state.loaded || (
              <tr>
                <td colSpan="11">
                  <Loader
                    style={{
                      textAlign: 'center',
                    }}
                    type="ThreeDots"
                    color="#00BFFF"
                    height={20}
                  />
                </td>
              </tr>
            )}
            {this.state.loaded && (
              <>
                {this.state.data.length > 0 ? (
                  <>{table}</>
                ) : (
                  <tr>
                    <td
                      colSpan="11"
                      style={{ textAlign: 'center', padding: '1em' }}
                    >
                      No quotes
                    </td>
                  </tr>
                )}
                {this.state.data.length > 10 ? (
                  <>
                    {this.state.showAll ? (
                      <button
                        onClick={() => {
                          this.setState({
                            showAll: false,
                          });
                        }}
                      >
                        Hide
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          this.setState({
                            showAll: true,
                          });
                        }}
                      >
                        Show All
                      </button>
                    )}
                  </>
                ) : null}
              </>
            )}
          </tbody>
        </table>
        </>
        )}
      </Wrapper>
      
    );
  }
}
