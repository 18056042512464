module.exports = {
    inventory_queries: {
        template: {
            query: "",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        getItemCategories: {
            query: "SELECT ic.*, "
                + "(SELECT rs.sTitle FROM [RESELLERS].[dbo].[RESELLERS_Skills] rs WHERE ic.nSkillset = rs.id) AS sTitle "
                + "FROM ITEM_CATEGORIES ic WHERE bDeleted = 0 ORDER BY id asc",
            req: [],
            fields: [

            ],
            desc: "Returns all item categories",
            tables: ["[RESELLERS].[dbo].[RESELLERS_Skills]", "ITEM_CATEGORIES"],
            type: "SELECT",
            whereUsed: ["TASearch.js"],
        },
        getItemList: {
            query: "SELECT i.*,"
                + "(SELECT COUNT(rql.id) FROM RAC_QUOTE_LINES rql WHERE rql.nItem = i.id) AS nUsage, "
                + "(SELECT ic.sName FROM ITEM_CATEGORIES ic WHERE ic.id = i.nCat) AS sCat "
                + "FROM ITEMS i "
                + "WHERE i.bDeleted = 0",
            req: [],
            fields: [

            ],
            desc: "Returns all item categories",
            tables: ["[RESELLERS].[dbo].[RESELLERS_Skills]", "ITEM_CATEGORIES"],
            type: "SELECT",
            whereUsed: ["TASearch.js"],
        },
    }

} 