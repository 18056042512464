import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { nQuoteTotalNoDollar, whichRep } from '../../../../assets/functions/calculations';
import moment from 'moment';
import { CustomDateWrapper, Input, SubmitText, GreyTextDiv } from '../../../styled/ui';



export default class TechDashboardTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    
    return (
      <table class="dashboard">
          <thead>
            <tr>
              <th>ID</th>
              <th>Company</th>
              <th>Customer Name</th>
              <th>Address</th>
              <th>Phone</th>
              <th>Email</th>
              <th style={{width: '300px'}}>Top 3 Notes</th>
              <th>Scheduled Date</th>
            </tr>
          </thead>
          <tbody>
                {this.props.data.length > 0 && (
                    <>
                      {this.props.data.map((sql, index) => (
                        <>
                        <tr key={sql.id}>
                          <td><Link target="_blank" to={`/quote/${sql.id}`}>{sql.id}</Link></td>
                          <td>{sql.sDCompany}</td>
                          <td>{sql.sDName}</td>
                          <td>{sql.sDAddress}{sql.sDAddress2 ? (<><br />{sql.sDAddress2}</>) : ""}<br />{sql.sDCity}, {sql.sDState} {sql.sDZip}</td>
                          <td>{sql.sDPhone}</td>
                          <td>{sql.sEmail}</td>
                          {/* <td>
                            <textarea style={{height: '80px', width: '100%'}} onInput={(event) => this.props.handleUpdateTag(event.target.value, index)} value={sql.sQuoteTags}></textarea>
                            {sql.changed && (<div onClick={() => this.props.handleSaveTag(sql.sQuoteTags, sql.id)}>Save Tag</div>)}
                          </td> */}
                          <td>
                            {sql.nRep ? whichRep(sql.nRep) : null}: {sql.notes[0].sNote}
                            <br /><br />
                            {sql.nRep ? whichRep(sql.nRep) : null}: {sql.notes[1].sNote}
                            <br /><br />
                            {sql.nRep ? whichRep(sql.nRep) : null}: {sql.notes[2].sNote}
                          </td>
                          <td>
                            <CustomDateWrapper><Input onChange={(e) => {this.props.handleDateChange(e, index, 'dtTargetComplete')}} type="date" id="target" name="target"
                              value={moment(sql.dtTargetComplete).utc().format('YYYY-MM-DD')} />
                            </CustomDateWrapper>
                            <SubmitText onClick={() => this.props.handleTargetSubmitDate(sql.dtTargetComplete,sql.id)}>Submit</SubmitText>
                          </td>
                        </tr>
                        {sql.showPOs && (
                          <tr>
                            <td colSpan={12}>
                            <table>
                              <thead>
                                <tr>
                                  <th>Vendor</th>
                                  <th>Description</th>
                                  <th>Quantity</th>
                                  <th>Price</th>
                                  <th>Total Price</th>
                                  <th>Item</th>
                                </tr>
                              </thead>
                              {sql.POLines.map((line, lineNumber) => (
                                <tr key={lineNumber}>
                                  <td>{line.sCompany}</td>
                                  <td>{line.sDescription}</td>
                                  <td>{line.nQuantity}</td>
                                  <td>{nQuoteTotalNoDollar(line.nPrice)}</td>
                                  <td>{nQuoteTotalNoDollar(line.nQuantity*line.nPrice)}</td>
                                  <td onClick={()=>this.props.handleSetLabor(index, lineNumber)}>{line.nItem === 832 && (
                                    <>CSN Labor</>
                                  ) || (
                                    <GreyTextDiv>Set as CSN Labor</GreyTextDiv>
                                  )}</td>
                                </tr>
                              ))}
                            </table>
                            </td>
                          </tr>
                        )}
                        </>
                      ))}
                    </>
                )}

          </tbody>
        </table>
    );
  }
}
