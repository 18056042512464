import React, { Component } from 'react';
import { GoogleApiWrapper, InfoWindow, Map, Marker } from 'google-maps-react'
import ReactTooltip from 'react-tooltip';
import { nQuoteTotalFormatter } from '../../assets/functions/calculations';
import { Link } from 'react-router-dom';
const mapStyles = {
  width: 1300,
  height: 800,
}

const cRed = '#b00'
const cWhite = '#fff'
const cBlack = '#000'
const cGreen = '#0b0'
const cYellow = '#fff2cc'
const cGold = '#d4af37'



export class MapContainer extends Component {
    state={
        selected: {}
    }

    select = (getPoint) => {
        console.log(getPoint)
        this.setState({selected: getPoint})
    }

    render() {
      return (
          <div style={{textAlign: 'center'}}>
              <div style={{height: 50}}>
              {(this.state.selected && (
                <>
                    {(this.state.selected.id > 1000000 && (
                        <>
                            <div>Quote <Link target="_blank" to={`/quote/${this.state.selected.id}`}>{this.state.selected.id}</Link>
                            &nbsp;({this.state.selected.sStatus} {nQuoteTotalFormatter(this.state.selected.nQuoteTotal)})</div>
                            <div> <Link target="_blank" to={`/crm/master-viewer/${this.state.selected.nCompany}`}>{this.state.selected.sCompany}</Link> &nbsp;&nbsp;
                            {(this.state.selected.sDAddress && (
                                <a
                                    target="_blank"
                                    href={`http://maps.google.com/?q=${this.state.selected.sDAddress} ${this.state.selected.sDCity}, ${this.state.selected.sDState} ${this.state.selected.sDZip}`}
                                >
                                    <i class="fas fa-map-marker-alt"></i> {this.state.selected.sDAddress}{' '}
                                    {this.state.selected.sDCity} {this.state.selected.sDState} {this.state.selected.sDZip}
                                </a>
                            )) || (
                                <a
                                    target="_blank"
                                    href={`http://maps.google.com/?q=${this.state.selected.sAddress} ${this.state.selected.sCity}, ${this.state.selected.sState} ${this.state.selected.sZip}`}
                                >
                                    <i class="fas fa-map-marker-alt"></i> {this.state.selected.sAddress}{' '}
                                    {this.state.selected.sCity} {this.state.selected.sState} {this.state.selected.sZip}
                                </a>
                            )} 
                            </div>
                        </>
                    )) || (
                        <>
                            <div><Link target="_blank" to={`/srm/master-viewer/${this.state.selected.id}`}>{this.state.selected.sCompany1} ({Math.round(this.state.selected.adjScore*100)}%)</Link></div>
                            <div><a
                                    target="_blank"
                                    href={`http://maps.google.com/?q=${this.state.selected.sAddress1} ${this.state.selected.sCity1}, ${this.state.selected.sState1} ${this.state.selected.sZip1}`}
                                >
                                    <i class="fas fa-map-marker-alt"></i> {this.state.selected.sAddress1}{' '}
                                    {this.state.selected.sCity1} {this.state.selected.sState1} {this.state.selected.sZip1}
                                </a></div>
                        </>
                    )}
                    
                </>
              )) || (
                <div>Click on points for more info.</div>
              )}
              </div>
              {console.log(this.props.points)}
        <Map
          google={this.props.google}
          zoom={5}
          style={mapStyles}
          initialCenter={
            {
              lat: 39.1,
              lng: -96,
            }
          }
        >
            {this.props.points.map((point, index) => (
                    <Marker
                    icon={{
                        path: 'M8 13A5 5 0 1 0 8 3a5 5 0 0 0 0 10z',
                        fillColor: (point.type === "Delivery Distance" ? point.distance < 500 ? cGreen : point.distance < 1000 ? cGold : cRed
                            : (point.sStatus === "Closed" ? cGreen : point.sStatus ==="Discontinued" 
                            ? (point.sWhyCancel === "Cannot render service requested" ? cGold : cRed) : cBlack)),
                        fillOpacity: ((point.distance < 500) && point.type==="Delivery Distance" ? 0 : 1.0),
                        strokeWeight: 0,
                        scale: (point.type === "Delivery Distance" ? point.nQuoteTotal <= 1000 ? 0.5 : Math.pow(point.nQuoteTotal,1/8)-1.87
                            : (point.adjScore ? .4+point.adjScore : point.adjScore === 0 ? 0.4 : 0.4+point.nPotential/4)),
                        }}       
                    onClick={() => this.select(point)}
                    position={{lat: point.lat, lng: point.lng}}
                    optimized='false'
                    zIndex={(point.sWhyCancel === "Cannot render service requested" ? 10 : 1)+point.nPotential}
                    />  
            ))}
        </Map>
        </div>
      );
    }
  }
  
  export default GoogleApiWrapper({
    apiKey: 'AIzaSyAx08jjHEWZ8jQ62QJVEPbQnoMjlP5LUSs'
  })(MapContainer);