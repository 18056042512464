import React, { Component } from 'react';
import { getSQLData, setSQLData } from '../../assets/functions/fetch';

class NewLeadReview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            refreshMS: 5000,
        };
      }

    componentDidMount() {
        this.fetchData()
      }
    
      fetchData = async () => {
    
        try {
          const fetch = await getSQLData("admin/newLeads", {})

          
          if(fetch){
    
            let data = fetch.data.data[0].recordset  

            this.setState({
              data: data,

            }, () => this.processData())
          }
             
        } catch (error) {
          console.error(error);
        }
      }

      processData() {
        let data = this.state.data
        let loaded = this.state.loaded


        this.setState({
            data: data,
            loaded: true, 
        }, () => setTimeout(()=>this.fetchData(),this.state.refreshMS))

      }

      handleDiscontinueAsSpam = async(id) => {
        try {
            let confirm = this.state.confirm
            if(confirm === id){
                const discontinueQuote = setSQLData("admin/discontinueAsSpam", {
                    id: id,
                })
            } else {
                this.setState({
                    confirm: id,
                })
            }

            
        } catch (error) {
          console.error(error);
        }
      }
      handleSetPotential = async(id,nPotential) => {
        try {

            const setPotential = setSQLData("admin/setPotential", {
                id: id,
                nPotential: nPotential,
            })
            

            
        } catch (error) {
          console.error(error);
        }
      }

    render() {
        let data = this.state.data
        let confirm = this.state.confirm

        return (
            <div>
                {this.state.loaded && (

                
                <table>
                    <thead>
                        <tr>
                            <th>Discontinue</th>
                            <th>Potential</th>
                            <th>Quote</th>
                            <th>Name/Company</th>
                            <th>Time Zone</th>
                            <th>Address</th>
                            <th>Contact</th>
                            <th>Quote Request</th>
                            <th>Delivery-Pickup</th>
                            <th>Referring Site</th>
                            <th>Type-Source</th>
                            <th>Date/Time</th>
                            <th>Brand</th>
                            
                        </tr>
                    </thead>
                    {data.map((quote,index)=>(
                        <tr>
                            <td><button onClick={()=>this.handleDiscontinueAsSpam(quote.id)}>{confirm === quote.id ? "Confirm Discontinue?" : "Discontinue As Spam"}</button></td>
                            <td>
                                {(quote.nPotential === 1) ? (<div>1</div>) : (<button onClick={()=>this.handleSetPotential(quote.id,1)}>1</button>)}
                                {(quote.nPotential === 2) ? (<div>2</div>) : (<button onClick={()=>this.handleSetPotential(quote.id,2)}>2</button>)}
                                {(quote.nPotential === 3) ? (<div>3</div>) : (<button onClick={()=>this.handleSetPotential(quote.id,3)}>3</button>)}
                                {(quote.nPotential === 4) ? (<div>4</div>) : (<button onClick={()=>this.handleSetPotential(quote.id,4)}>4</button>)}
                                {(quote.nPotential === 5) ? (<div>5</div>) : (<button onClick={()=>this.handleSetPotential(quote.id,5)}>5</button>)}
                            </td>
                            <td>{quote.id}</td>
                            <td>{quote.sName}<br />
                                {quote.sCompany}</td>
                            <td>{quote.sTimeZone}</td>
                            <td>{quote.sAddress}<br />
                                {quote.sCity}, {quote.sState} {quote.sZip}</td>
                            <td>{quote.sPhone}<br />
                                {quote.sEmail}</td>
                            <td>{quote.sQuote}</td>
                            <td>{quote.dtDeliveryDate} - <br />
                                {quote.dtPickupDate}</td>
                            <td>{quote.sReferringSite}</td>
                            
                            <td>{quote.sType} - <br />
                                {quote.sLeadSource}</td>
                            <td>{quote.sQuoteType}</td>
                            <td>{quote.dtDateStamp}</td>
                        </tr>
                    ))}
                </table>
                )}
            </div>
        );
    }
}

export default NewLeadReview;