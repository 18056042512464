import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin: 1em auto 0 auto;
  margin: ${(props) =>
    props.side === 'left' ? '1em 0.5em 0 auto;' : '1em auto 0 0.5em;'}
  width: 50%;
  height: fit-content;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.1);
  text-align: center;
  border-radius: 15px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: center;

  padding: 1em;
  margin: 0 auto;

  div {
    margin: 0 1em;
  }

  p {
    text-align: center;
    margin: 0.5em;
    justify-content: space-between;

    span {
      font-weight: bold;
    }
  }
`;

export default class CostSheetBreakdown extends Component {
  render() {
    return (
      <Wrapper side={this.props.side}>
        <Container>
          <div>
            <p>
              <span>Labor</span>
            </p>
            <p>${this.props.labor}</p>
          </div>
          <div>
            <p>
              <span>Insurance</span>
            </p>
            <p>${this.props.insurance}</p>
          </div>
          <div>
            <p>
              <span>Shipping</span>
            </p>
            <p>${this.props.shipping}</p>
          </div>
          <div>
            <p>
              <span>Hardware</span>
            </p>
            <p>${this.props.hardware}</p>
          </div>
          <div>
            <p>
              <span>Cost</span>
            </p>
            <p>${this.props.cost}</p>
          </div>
          <div>
            <p>
              <span>Margin</span>
            </p>
            <p>{this.props.margin}%</p>
          </div>
        </Container>
      </Wrapper>
    );
  }
}
