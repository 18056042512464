import React, { Component } from 'react';
import styled from 'styled-components';
import PoControls from './PoControls';
import PoItem from './PoItem';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 1em 0;
`;

export default class PoLines extends Component {
  render() {
    return (
      <Wrapper>
        <PoControls />
        {this.props.POs.map((sql) => (
          <PoItem
            id={sql.id}
            sDisplayId={sql.sDisplayId}
            nPOTotal={sql.nPOTotal}
            dtQuoteDate={sql.dtQuoteDate}
            sCompany={sql.sCompany}
            sName={sql.sName}
            sAddress={sql.sAddress}
            sCity={sql.sCity}
            sState={sql.sState}
            sZip={sql.sZip}
            sPhone={sql.sPhone}
            sFax={sql.sFax}
            sEmail={sql.sEmail}
            sEstimate={sql.sEstimate}
            sDCompany={sql.sDCompany}
            sDName={sql.sDName}
            sDAddress={sql.sDAddress}
            sDCity={sql.sDCity}
            sDState={sql.sDState}
            sDZip={sql.sDZip}
            sDPhone={sql.sDPhone}
            sDFax={sql.sDFax}
            sDEmail={sql.sDEmail}
            sDNotes={sql.sDNotes}
            sAltNamePhone={sql.sAltNamePhone}
            nDelivery={sql.nDelivery}
            nTaxRate={sql.nTaxRate}
            dtSent={sql.dtSent}
            dtConfirmed={sql.dtConfirmed}
            nTechArmyVendor={sql.nTechArmyVendor}
          />
        ))}
      </Wrapper>
    );
  }
}
