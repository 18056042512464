const { accounting_queries } = require("./queries/accounting")
const { call_log_queries } = require("./queries/call_log")
const { cam_queries } = require("./queries/cam")
const { email_queries } = require("./queries/email")
const { gpt_queries } = require("./queries/gpt")
const { inventory_queries } = require("./queries/inventory")
const { logistics_queries } = require("./queries/logistics")
const { marketing_queries } = require("./queries/marketing")
const { quote_queries } = require("./queries/quote")
const { rac_queries } = require("./queries/rac")
const { supplier_queries } = require("./queries/supplier")
const { tutorial_queries } = require("./queries/tutorial")
const { underwriting_queries } = require("./queries/underwriting")
const { marketing_tier_queries } = require("./queries/marketing-wizard")
const { admin_queries } = require("./queries/admin")

module.exports.queries = {
    template: {
        query: "",
        req: [],
        fields: [
            {key: "", label: ""},
        ],
        desc: "",
        tables: [],
        type: "",
        whereUsed: [],
    },
    test: {
        query: "SELECT TOP 1 [field1] FROM RAC_CUSTOMER_QUOTES"
    },
    tutorial: tutorial_queries,
    marketing: marketing_queries,
    cam: cam_queries,
    accounting: accounting_queries,
    rac: rac_queries,
    quote: quote_queries,
    logistics: logistics_queries,
    supplier: supplier_queries,
    inventory: inventory_queries,
    underwriting: underwriting_queries,
    call_log: call_log_queries,
    gpt: gpt_queries,
    email: email_queries,
    marketingWizard: marketing_tier_queries,
    admin: admin_queries,
}

module.exports.queryAliases = [
    {alias: "tutorial1", path: "tutorial/tutorial1"}
]