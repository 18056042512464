import React, { Component } from 'react';
import Axios from 'axios';
import Loader from 'react-loader-spinner';
import styled from 'styled-components';
import {
  sortData,
  sortDataQuote,
  findTotals,
  findTotalsQuote,
} from '../../../assets/functions/calculations';

const Wrapper = styled.div`
  width: 99%;
  max-width: 700px;
  margin: 4em auto;

  table {
    width: 100%;
  }
`;

const SubTitle = styled.h2`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1em;

  i {
    font-size: 16px;
    margin-left: 7px;
  }
`;

export default class StatsOverview extends Component {
  state = {
    loaded: false,
    showAll: false,
    sqlClosed: [],
    sqlClosedRebill: [],
    sqlPotential: [],
    sqlRebill: [],
    sqlRepeat: [],
  };

  // Async Axios
  fetchData = async (startDate, endDate) => {
    try {
      const response = await Axios.get('/admin-dashboard/stats-overview', {
        params: {
          startDate: startDate,
          endDate: endDate,
        },
        headers: { 'Content-Type': 'application/json' },
      });
      // Sets the state using the response data
      this.setState({
        sqlClosed: response.data.result[0].recordset,
        sqlPotential: response.data.result[1].recordset,
        sqlRebill: response.data.result[2].recordset,
        sqlClosedRebill: response.data.result[3].recordset,
        sqlRepeat: response.data.result[4].recordset,
        loaded: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    this.fetchData(this.props.startDate, this.props.endDate);
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.fetchData(this.props.startDate, this.props.endDate);
    }
  }

  render() {
    let table;

    table = (
      <>
        <tr>
          <td style={{ fontWeight: 'bold' }}>Unknown</td>
          <td style={{ textAlign: 'center' }}>
            {sortData(this.state.sqlPotential, 0)}
          </td>
          <td style={{ textAlign: 'center' }}>
            {sortDataQuote(this.state.sqlPotential, 0, 'Rentacomputer.com')}
          </td>
          <td style={{ textAlign: 'center' }}>
            {sortDataQuote(this.state.sqlPotential, 0, 'CameraSecurityNow.com')}
          </td>
          <td style={{ textAlign: 'center' }}>
            {sortDataQuote(
              this.state.sqlPotential,
              0,
              'ComputerServiceNow.com'
            )}
          </td>
        </tr>
        <tr>
          <td>{'1 - < $250'}</td>
          <td style={{ textAlign: 'center' }}>
            {sortData(this.state.sqlPotential, 1)}
          </td>
          <td style={{ textAlign: 'center' }}>
            {sortDataQuote(this.state.sqlPotential, 1, 'Rentacomputer.com')}
          </td>
          <td style={{ textAlign: 'center' }}>
            {sortDataQuote(this.state.sqlPotential, 1, 'CameraSecurityNow.com')}
          </td>
          <td style={{ textAlign: 'center' }}>
            {sortDataQuote(
              this.state.sqlPotential,
              1,
              'ComputerServiceNow.com'
            )}
          </td>
        </tr>
        <tr>
          <td>{'2 - $250 - $999'}</td>
          <td style={{ textAlign: 'center' }}>
            {sortData(this.state.sqlPotential, 2)}
          </td>
          <td style={{ textAlign: 'center' }}>
            {sortDataQuote(this.state.sqlPotential, 2, 'Rentacomputer.com')}
          </td>
          <td style={{ textAlign: 'center' }}>
            {sortDataQuote(this.state.sqlPotential, 2, 'CameraSecurityNow.com')}
          </td>
          <td style={{ textAlign: 'center' }}>
            {sortDataQuote(
              this.state.sqlPotential,
              2,
              'ComputerServiceNow.com'
            )}
          </td>
        </tr>
        <tr>
          <td>{'3 -$1000 - $4,999'}</td>
          <td style={{ textAlign: 'center' }}>
            {sortData(this.state.sqlPotential, 3)}
          </td>
          <td style={{ textAlign: 'center' }}>
            {sortDataQuote(this.state.sqlPotential, 3, 'Rentacomputer.com')}
          </td>
          <td style={{ textAlign: 'center' }}>
            {sortDataQuote(this.state.sqlPotential, 3, 'CameraSecurityNow.com')}
          </td>
          <td style={{ textAlign: 'center' }}>
            {sortDataQuote(
              this.state.sqlPotential,
              3,
              'ComputerServiceNow.com'
            )}
          </td>
        </tr>
        <tr>
          <td>{'4 - $5000 - $9,999'}</td>
          <td style={{ textAlign: 'center' }}>
            {sortData(this.state.sqlPotential, 4)}
          </td>
          <td style={{ textAlign: 'center' }}>
            {sortDataQuote(this.state.sqlPotential, 4, 'Rentacomputer.com')}
          </td>
          <td style={{ textAlign: 'center' }}>
            {sortDataQuote(this.state.sqlPotential, 4, 'CameraSecurityNow.com')}
          </td>
          <td style={{ textAlign: 'center' }}>
            {sortDataQuote(
              this.state.sqlPotential,
              4,
              'ComputerServiceNow.com'
            )}
          </td>
        </tr>
        <tr>
          <td>{'5 - > $10,000'}</td>
          <td style={{ textAlign: 'center' }}>
            {sortData(this.state.sqlPotential, 5)}
          </td>
          <td style={{ textAlign: 'center' }}>
            {sortDataQuote(this.state.sqlPotential, 5, 'Rentacomputer.com')}
          </td>
          <td style={{ textAlign: 'center' }}>
            {sortDataQuote(this.state.sqlPotential, 5, 'CameraSecurityNow.com')}
          </td>
          <td style={{ textAlign: 'center' }}>
            {sortDataQuote(
              this.state.sqlPotential,
              5,
              'ComputerServiceNow.com'
            )}
          </td>
        </tr>
        <tr>
          <td style={{ fontWeight: 'bold' }}>New Business Quotes</td>
          <td style={{ textAlign: 'center' }}>
            {sortData(this.state.sqlPotential, 0) +
              sortData(this.state.sqlPotential, 1) +
              sortData(this.state.sqlPotential, 2) +
              sortData(this.state.sqlPotential, 3) +
              sortData(this.state.sqlPotential, 4) +
              sortData(this.state.sqlPotential, 5)}
          </td>
          <td style={{ textAlign: 'center' }}>
            {sortDataQuote(this.state.sqlPotential, 0, 'Rentacomputer.com') +
              sortDataQuote(this.state.sqlPotential, 1, 'Rentacomputer.com') +
              sortDataQuote(this.state.sqlPotential, 2, 'Rentacomputer.com') +
              sortDataQuote(this.state.sqlPotential, 3, 'Rentacomputer.com') +
              sortDataQuote(this.state.sqlPotential, 4, 'Rentacomputer.com') +
              sortDataQuote(this.state.sqlPotential, 5, 'Rentacomputer.com')}
          </td>
          <td style={{ textAlign: 'center' }}>
            {sortDataQuote(
              this.state.sqlPotential,
              0,
              'CameraSecurityNow.com'
            ) +
              sortDataQuote(
                this.state.sqlPotential,
                1,
                'CameraSecurityNow.com'
              ) +
              sortDataQuote(
                this.state.sqlPotential,
                2,
                'CameraSecurityNow.com'
              ) +
              sortDataQuote(
                this.state.sqlPotential,
                3,
                'CameraSecurityNow.com'
              ) +
              sortDataQuote(
                this.state.sqlPotential,
                4,
                'CameraSecurityNow.com'
              ) +
              sortDataQuote(
                this.state.sqlPotential,
                5,
                'CameraSecurityNow.com'
              )}
          </td>
          <td style={{ textAlign: 'center' }}>
            {sortDataQuote(
              this.state.sqlPotential,
              0,
              'ComputerServiceNow.com'
            ) +
              sortDataQuote(
                this.state.sqlPotential,
                1,
                'ComputerServiceNow.com'
              ) +
              sortDataQuote(
                this.state.sqlPotential,
                2,
                'ComputerServiceNow.com'
              ) +
              sortDataQuote(
                this.state.sqlPotential,
                3,
                'ComputerServiceNow.com'
              ) +
              sortDataQuote(
                this.state.sqlPotential,
                4,
                'ComputerServiceNow.com'
              ) +
              sortDataQuote(
                this.state.sqlPotential,
                5,
                'ComputerServiceNow.com'
              )}
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td style={{ fontWeight: 'bold' }}>Closed New Orders</td>
          <td style={{ textAlign: 'center' }}>
            {findTotals(this.state.sqlClosed)}
          </td>
          <td style={{ textAlign: 'center' }}>
            {findTotalsQuote(this.state.sqlClosed, 'Rentacomputer.com')}
          </td>
          <td style={{ textAlign: 'center' }}>
            {findTotalsQuote(this.state.sqlClosed, 'CameraSecurityNow.com')}
          </td>
          <td style={{ textAlign: 'center' }}>
            {findTotalsQuote(this.state.sqlClosed, 'ComputerServiceNow.com')}
          </td>
        </tr>
        <tr>
          <td style={{ fontWeight: 'bold' }}>Rebills Closed</td>
          <td style={{ textAlign: 'center' }}>
            {findTotals(this.state.sqlClosedRebill)}
          </td>
          <td style={{ textAlign: 'center' }}>
            {findTotalsQuote(this.state.sqlClosedRebill, 'Rentacomputer.com')}
          </td>
          <td style={{ textAlign: 'center' }}>
            {findTotalsQuote(
              this.state.sqlClosedRebill,
              'CameraSecurityNow.com'
            )}
          </td>
          <td style={{ textAlign: 'center' }}>
            {findTotalsQuote(
              this.state.sqlClosedRebill,
              'ComputerServiceNow.com'
            )}
          </td>
        </tr>
        <tr>
          <td style={{ fontWeight: 'bold' }}>Rebills Generated</td>
          <td style={{ textAlign: 'center' }}>
            {findTotals(this.state.sqlRebill)}
          </td>
          <td style={{ textAlign: 'center' }}>
            {findTotalsQuote(this.state.sqlRebill, 'Rentacomputer.com')}
          </td>
          <td style={{ textAlign: 'center' }}>
            {findTotalsQuote(this.state.sqlRebill, 'CameraSecurityNow.com')}
          </td>
          <td style={{ textAlign: 'center' }}>
            {findTotalsQuote(this.state.sqlRebill, 'ComputerServiceNow.com')}
          </td>
        </tr>
        <tr>
          <td style={{ fontWeight: 'bold' }}>Inquiries</td>
          <td style={{ textAlign: 'center' }}>0</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td style={{ fontWeight: 'bold' }}>Repeats</td>
          <td style={{ textAlign: 'center' }}>
            {findTotals(this.state.sqlRepeat)}
          </td>
          <td style={{ textAlign: 'center' }}>
            {findTotalsQuote(this.state.sqlRepeat, 'Rentacomputer.com')}
          </td>
          <td style={{ textAlign: 'center' }}>
            {findTotalsQuote(this.state.sqlRepeat, 'CameraSecurityNow.com')}
          </td>
          <td style={{ textAlign: 'center' }}>
            {findTotalsQuote(this.state.sqlRepeat, 'ComputerServiceNow.com')}
          </td>
        </tr>
        <tr>
          <td style={{ fontWeight: 'bold' }}>Total Quotes Processed</td>
          <td style={{ textAlign: 'center' }}>
            {sortData(this.state.sqlPotential, 0) +
              sortData(this.state.sqlPotential, 1) +
              sortData(this.state.sqlPotential, 2) +
              sortData(this.state.sqlPotential, 3) +
              sortData(this.state.sqlPotential, 4) +
              sortData(this.state.sqlPotential, 5) +
              findTotals(this.state.sqlRebill)}
          </td>
          <td style={{ textAlign: 'center' }}>
            {sortDataQuote(this.state.sqlPotential, 0, 'Rentacomputer.com') +
              sortDataQuote(this.state.sqlPotential, 1, 'Rentacomputer.com') +
              sortDataQuote(this.state.sqlPotential, 2, 'Rentacomputer.com') +
              sortDataQuote(this.state.sqlPotential, 3, 'Rentacomputer.com') +
              sortDataQuote(this.state.sqlPotential, 4, 'Rentacomputer.com') +
              sortDataQuote(this.state.sqlPotential, 5, 'Rentacomputer.com') +
              findTotalsQuote(this.state.sqlRebill, 'Rentacomputer.com')}
          </td>
          <td style={{ textAlign: 'center' }}>
            {sortDataQuote(
              this.state.sqlPotential,
              0,
              'CameraSecurityNow.com'
            ) +
              sortDataQuote(
                this.state.sqlPotential,
                1,
                'CameraSecurityNow.com'
              ) +
              sortDataQuote(
                this.state.sqlPotential,
                2,
                'CameraSecurityNow.com'
              ) +
              sortDataQuote(
                this.state.sqlPotential,
                3,
                'CameraSecurityNow.com'
              ) +
              sortDataQuote(
                this.state.sqlPotential,
                4,
                'CameraSecurityNow.com'
              ) +
              sortDataQuote(
                this.state.sqlPotential,
                5,
                'CameraSecurityNow.com'
              ) +
              findTotalsQuote(this.state.sqlRebill, 'CameraSecurityNow.com')}
          </td>
          <td style={{ textAlign: 'center' }}>
            {sortDataQuote(
              this.state.sqlPotential,
              0,
              'ComputerServiceNow.com'
            ) +
              sortDataQuote(
                this.state.sqlPotential,
                1,
                'ComputerServiceNow.com'
              ) +
              sortDataQuote(
                this.state.sqlPotential,
                2,
                'ComputerServiceNow.com'
              ) +
              sortDataQuote(
                this.state.sqlPotential,
                3,
                'ComputerServiceNow.com'
              ) +
              sortDataQuote(
                this.state.sqlPotential,
                4,
                'ComputerServiceNow.com'
              ) +
              sortDataQuote(
                this.state.sqlPotential,
                5,
                'ComputerServiceNow.com'
              ) +
              findTotalsQuote(this.state.sqlRebill, 'ComputerServiceNow.com')}
          </td>
        </tr>
        <tr>
          <td style={{ fontWeight: 'bold' }}>Closed New Orders</td>
          <td style={{ textAlign: 'center' }}>
            {findTotals(this.state.sqlClosed) +
              findTotals(this.state.sqlClosedRebill)}
          </td>
          <td style={{ textAlign: 'center' }}>
            {findTotalsQuote(this.state.sqlClosed, 'Rentacomputer.com') +
              findTotalsQuote(this.state.sqlClosedRebill, 'Rentacomputer.com')}
          </td>
          <td style={{ textAlign: 'center' }}>
            {findTotalsQuote(this.state.sqlClosed, 'CameraSecurityNow.com') +
              findTotalsQuote(
                this.state.sqlClosedRebill,
                'CameraSecurityNow.com'
              )}
          </td>
          <td style={{ textAlign: 'center' }}>
            {findTotalsQuote(this.state.sqlClosed, 'ComputerServiceNow.com') +
              findTotalsQuote(
                this.state.sqlClosedRebill,
                'ComputerServiceNow.com'
              )}
          </td>
        </tr>
      </>
    );

    return (
      <Wrapper>
        <SubTitle>
          Stats Overview <i class="far fa-clock"></i>
        </SubTitle>
        <table class="dashboard">
          <thead>
            <tr>
              <th style={{ textAlign: 'left' }}>Type</th>
              <th style={{ textAlign: 'center' }}>Total</th>
              <th style={{ textAlign: 'center' }}>RAC</th>
              <th style={{ textAlign: 'center' }}>CAM</th>
              <th style={{ textAlign: 'center' }}>CSN</th>
            </tr>
          </thead>
          <tbody>
            {this.state.loaded || (
              <tr>
                <td colSpan="5">
                  <Loader
                    style={{
                      textAlign: 'center',
                    }}
                    type="ThreeDots"
                    color="#00BFFF"
                    height={20}
                    width={120}
                  />
                </td>
              </tr>
            )}
            {this.state.loaded && <>{table}</>}
          </tbody>
        </table>
      </Wrapper>
    );
  }
}
