import React, { Component } from 'react'
import { getSQLData, setSQLData } from '../../../../assets/functions/fetch';
import moment from 'moment';
import { nQuoteTotalNoDollar, whichRep } from '../../../../assets/functions/calculations';

export default class RepCommissionPaid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            minYear: 2024,
            selectedYear: parseInt(moment().format("YYYY")),
        };

      }

      componentDidMount(){
        this.fetchData()
      }

      fetchData = async() => {
        try {

            const fetchEarned = await getSQLData("accounting/getCommissionsEarned", {minYear: this.state.minYear})
            const fetchPaid = await getSQLData("accounting/getCommissionsPaid", {minYear: this.state.minYear})

            if(fetchEarned && fetchPaid){
                
                let earned = fetchEarned.data.data[0].recordset
                let paid = fetchPaid.data.data[0].recordset

                this.setState({
                    earned: earned,
                    paid: paid,
                }, () => this.processData())
            }

        } catch (error) {

        }
      }

      createNewRepEntry(){
        return {
            earned: 0,
            rate: 0.1,
            paid: 0,
            exception: 0,
            payrollWeeks: [],
        }
      }

      processData() {
        let earned = this.state.earned
        let paid = this.state.paid

        let reps = []
        let activeReps = []
        let payrollWeeks = []

        console.log("test")

        for(let i=0; i<earned.length; i++){
            if(!reps[earned[i].nRep]){
                reps[earned[i].nRep] = this.createNewRepEntry()
                activeReps.push(earned[i].nRep)
            }

            if(earned[i].sException === "Flag"){
                reps[earned[i].nRep].exception += earned[i].nMargin
            }

            reps[earned[i].nRep].earned += earned[i].nMargin
            
        }
        
        for(let i=0; i<paid.length; i++){
            if(!reps[paid[i].nRep]){
                reps[paid[i].nRep] = this.createNewRepEntry()
                activeReps.push(paid[i].nRep)
            }

            if(!payrollWeeks.includes(moment(paid[i].dtDate).format("MM/DD/YYYY"))){
                payrollWeeks.push(moment(paid[i].dtDate).format("MM/DD/YYYY"))
            }
                
            reps[paid[i].nRep].paid += paid[i].nAmount
            reps[paid[i].nRep].payrollWeeks.push(paid[i])
            
        }
        
        console.log(reps)

        this.setState({
            earned: earned,
            paid: paid,
            reps: reps,
            activeReps: activeReps,
            payrollWeeks: payrollWeeks,
            loaded: true,
        })
      }

      updatePaidLog(rep, weekIndex, amount){
        let reps = this.state.reps

        reps[rep].payrollWeeks[weekIndex].nAmount = amount
        reps[rep].payrollWeeks[weekIndex].changed = true

        this.setState({reps: reps})
      }

      savePaidLog(rep, weekIndex){
        let reps = this.state.reps
        reps[rep].payrollWeeks[weekIndex].changed = false
        setSQLData("accounting/updatePaidLog", {
            id: reps[rep].payrollWeeks[weekIndex].id,
            nAmount: reps[rep].payrollWeeks[weekIndex].nAmount,
        })

        this.setState({reps: reps})
      }

  render() {
    return (
      <div>
        {this.state.loaded && (
            <>
            <div style={{width: '600px', backgroundColor: '#bfbfbf', fontWeight: 'bold', fontSize: '24px', border: '1px solid black', padding: '5px'}}>RAC Commission Worksheet 2024</div>
            <div style={{width: '600px', backgroundColor: '#d9d9d9', padding: '40px'}}>
            <table style={{width: '500px', border: '1px solid black', borderCollapse: 'collapse', borderRadius: '0px', mozBorderRadius: '0px', WebkitBorderRadius: '0px'}}>
                <thead>
                    <tr style={{borderRadius: '0px', mozBorderRadius: '0px', WebkitBorderRadius: '0px'}}>
                        <th style={{backgroundColor: "#8db4e2", border: '1px solid black', borderCollapse: 'collapse',borderRadius: '0px', mozBorderRadius: '0px', WebkitBorderRadius: '0px'}}>Earned</th>
                        <th style={{backgroundColor: "#d8e4bc", border: '1px solid black', borderCollapse: 'collapse',borderRadius: '0px', mozBorderRadius: '0px', WebkitBorderRadius: '0px'}}>Paid</th>
                        <th style={{backgroundColor: "#fff", border: '1px solid black', borderCollapse: 'collapse',borderRadius: '0px', mozBorderRadius: '0px', WebkitBorderRadius: '0px'}}>RAC REP</th>
                        <th style={{backgroundColor: "#fff", border: '1px solid black', borderCollapse: 'collapse',borderRadius: '0px', mozBorderRadius: '0px', WebkitBorderRadius: '0px'}}>Balance</th>
                    </tr>
                </thead>
                {this.state.reps.map((rep, index) => (
                    <tr>
                        <td style={{backgroundColor: "#8db4e2", border: '1px solid black', borderCollapse: 'collapse',}}>{nQuoteTotalNoDollar(rep.earned*rep.rate)}</td>
                        <td style={{backgroundColor: "#d8e4bc", border: '1px solid black', borderCollapse: 'collapse',}}>{nQuoteTotalNoDollar(rep.paid)}</td>
                        <td style={{backgroundColor: "#fff", border: '1px solid black', borderCollapse: 'collapse',}}>{whichRep(index)}</td>
                        <td style={{backgroundColor: "#fff", border: '1px solid black', borderCollapse: 'collapse',}}>{nQuoteTotalNoDollar(rep.earned*rep.rate-rep.paid)}</td>
                    </tr>
                ))}
            </table>
            </div>
            <table style={{width: '900px'}}>
                <thead>
                    <tr>
                        <th style={{width: '150px'}}>Payroll Start</th>
                    {this.state.activeReps.map((rep,index)=>(
                        <th style={{width: '150px'}}>{whichRep(rep)}</th>
                    ))}
                    </tr>
                </thead>
                {this.state.payrollWeeks.map((week,weekIndex)=>(
                    <tr>
                        <td>{moment(week).add(1,'days').format('MM/DD/YYYY')}</td>
                        {this.state.activeReps.map((rep,repIndex)=>(
                            <td>
                                <input 
                                    onChange={(event)=>this.updatePaidLog(rep,weekIndex,event.target.value)}
                                    value={this.state.reps[rep].payrollWeeks[weekIndex].nAmount}
                                    style={{width: '100px'}}
                                />
                                {this.state.reps[rep].payrollWeeks[weekIndex].changed && (
                                    <button onClick={()=>this.savePaidLog(rep,weekIndex)}>Save</button>
                                )}
                            </td>
                        ))}
                    </tr>
                ))}

            </table>
            </>
        )}
      </div>
    )
  }
}
