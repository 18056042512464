import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Loader from 'react-loader-spinner';
import MediaQuery from 'react-responsive';
import styled from 'styled-components';
import Moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { nQuoteTotalFormatter } from '../../../assets/functions/calculations';
import { whichRep } from '../../../assets/functions/calculations';
import HeatMap from '../../misc/HeatMap';
const PageWrapper = styled.div`
  display: block;
  position: fixed;
  z-index: 50;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: #222;
  opacity: 0.5;
`;

const Modal = styled.div`
  display: block;
  position: fixed;
  z-index: 55;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 90%;
  overflow: hidden;
  background-color: #fff;
  opacity: 1;
  border-radius: 10px;

`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: left;
  background-color: #fefefe;
  margin: auto;
  padding: 0.5em;
  width: 90%;
  overflow: hidden;

  h2 {
    text-align: center;
  }

  p {
    text-align: left;
    font-size: 1rem;
    margin-top: 1em;
  }

  span {
    font-weight: bold;
  }

  input {
    width: 100%;
    max-width: 400px;
    padding: 0.7em;
    margin: 0.5em auto 0 auto;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  select {
    width: 100%;
    max-width: 400px;
    padding: 0.7em;
    margin: 0.5em auto 0 auto;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
`;
const Wrapper = styled.div`
  width: 99%;
  max-width: 1600px;
  margin: 5em auto;

  table {
    width: 100%;
    position: relative;
    ${(props) => {
      if (props.amount < 10)
        return `
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;

    thead + tbody tr:last-child td:last-child,
    tfoot tr:last-child td:last-child {
      border-bottom-right-radius: 15px !important;
    }
    thead + tbody tr:last-child td:first-child,
    tfoot tr:last-child td:first-child {
      border-bottom-left-radius: 15px !important;
    }
        `;
    }}
  }

  button {
    position: absolute;
    width: 100%;
    bottom: -30px;
     padding: 0.35em 1.2em;
     text-decoration: none;
     color: #fafafa;
     text-align: center;
     transition: all 0.2s;
    background-color: #4aabed;
    border: 1px solid #4aabed;
    cursor: pointer;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    &:hover {
      color: #222;
      background-color: #fafafa;
      border: 1px solid #d7d7d7;
    }
  }

  span {
    margin: 0 1em;
  }
`;

const SubTitle = styled.h2`
  display: flex;
  justify-content: center;
  text-align: center;
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1em;

  i {
    font-size: 16px;
    margin-left: 7px;
  }
`;

function positiveNegative(sStatus) {
  if (sStatus === 'Open') {
    return '#fff';
  } else if (sStatus === 'Discontinued') {
    return '#ff6666';
  } else {
    return '#85bb65';
  }
}

function calcIfLate(sqlDate) {
  let date = new Date(sqlDate);
  let currentDate = new Date();

  if (date < currentDate) {
    return '#ff5959';
  }
}

export default class RacRFQ extends Component {
  state = {
    data: [],
    loaded: false,
    showAll: false,
    showHeatMat: false,
  };

  // Async Axios
  fetchData = async (startDate, endDate) => {
    try {
      const response = await Axios.get('/rac-dashboard/rfq', {
        params: {
          startDate: startDate,
          endDate: endDate,
        },
        headers: { 'Content-Type': 'application/json' },
      });
      // Sets the state using the response data
      this.setState({
        data: response.data.result[0].recordset,
        zipcodes: response.data.result[1].recordset,
        loaded: true,
      }, () => this.setCoords());
    } catch (error) {
      console.error(error);
    }
  };

  // Async Axios
  fetchFilteredData = async (startDate, endDate, status) => {
    try {
      const response = await Axios.get('/rac-rfq-filtered', {
        params: {
          startDate: startDate,
          endDate: endDate,
          status: status,
        },
        headers: { 'Content-Type': 'application/json' },
      });
      // Sets the state using the response data
      this.setState({
        startDate: startDate,
        endDate: endDate,
        data: response.data.result[0].recordset,
      });
    } catch (error) {
      console.error(error);
    }
  };

  setCoords = () => {
    let data = this.state.data
    let zipcodes = this.state.zipcodes
    let coord = {}

    console.log(data)
    for(let i=0; i<data.length; i++){
      if(data[i].sDZip || data[i].sZip){
        if(data[i].sDZip){coord = this.getLatLng(data[i].sDZip)}else{coord = this.getLatLng(data[i].sZip)}
        
        data[i].lat = coord.lat
        data[i].lng = coord.lng
      }
    }

    this.setState({data: data})
  }

  getLatLng = (getZip) => {
    let latitude = 0
    let longitude = 0

    if(getZip){    
      if(getZip.length === 5 && parseInt(getZip)>0 && parseInt(getZip)<=99999){
        
        for(let i=0; i< this.state.zipcodes.length; i++){
          if(parseInt(this.state.zipcodes[i].sZipCode) === parseInt(getZip)){
            latitude = this.state.zipcodes[i].sLatitude
            longitude = this.state.zipcodes[i].sLongitude
            i=this.state.zipcodes.length
          }
        }
      }
    }
    return {lat: latitude, lng: longitude}
  }

  componentDidMount() {
    this.fetchData(this.props.startDate, this.props.endDate);
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.fetchData(this.props.startDate, this.props.endDate);
    }
  }

  render() {
    let table;
    let total = this.state.data.length;
    let open = 0;
    let closed = 0;
    let discontinued = 0;

    for (let i = 0; i < this.state.data.length; i++) {
      if (this.state.data[i].sStatus === 'Open') {
        open += 1;
      } else if (this.state.data[i].sStatus === 'Closed') {
        closed += 1;
      } else if (this.state.data[i].sStatus === 'Discontinued') {
        discontinued += 1;
      }
    }

    // If the state showAll is false then table with return with only 10 rows, else show all 100
    if (this.state.showAll === false) {
      table = (
        <>
          {this.state.data.slice(0, 10).map((sql) => (
            <tr
              style={{
                backgroundColor: positiveNegative(sql.sStatus),
              }}
              key={sql.id}
            >
              <MediaQuery minDeviceWidth={900}>
                <td>
                  <Link target="_blank" to={`/quote/${sql.id}`}>
                    {sql.id}
                  </Link>
                </td>
              </MediaQuery>
              <td>
                {' '}
                <Link
                  target="_blank"
                  to={`/crm/master-viewer/${sql.sCompany.replace(
                    /\//g,
                    '%2F'
                  )}`}
                >
                  {sql.sCompany}
                </Link>
              </td>
              <MediaQuery minDeviceWidth={900}>
                <td>{sql.sName ? sql.sName.slice(0, 20) : null}</td>
              </MediaQuery>
              <td>
                {sql.sCity} {sql.sState} {sql.sZip}
              </td>
              <MediaQuery minDeviceWidth={900}>
                <td>
                  {sql.dtDateStamp ? (
                    <>
                      {Moment(sql.dtDateStamp).utc().format('MM/DD/YYYY')}
                      <br />
                      {Moment(sql.dtDateStamp).utc().format('hh:mm A')}
                    </>
                  ) : null}
                </td>
              </MediaQuery>
              <td>
                {sql.nRep ? (
                  <>{whichRep(sql.nRep).match(/\b\w/g).join('')}</>
                ) : null}
              </td>
              <td data-tip={sql.sQuote}>
                {sql.sQuote ? (
                  <>
                    {sql.sQuote.slice(0, 110)}
                    <ReactTooltip multiline={true} effect={'solid'} />
                  </>
                ) : null}
              </td>
              <td style={{ textAlign: 'center' }}>
                {sql.sType ? sql.sType : null}
              </td>
              <MediaQuery minDeviceWidth={900}>
                <td style={{ color: calcIfLate(sql.dtFollowup) }}>
                  {sql.dtFollowup ? (
                    <>
                      {Moment(sql.dtFollowup).utc().format('MM/DD/YYYY')}
                      <br />
                      {Moment(sql.dtFollowup).utc().format('hh:mm A')}
                    </>
                  ) : null}
                </td>
                <td>{nQuoteTotalFormatter(sql.nQuoteTotal)}</td>
                <td>
                  {sql.dtDate ? (
                    <>
                      {Moment(sql.dtDate).utc().format('MM/DD/YYYY')}
                      <br />
                      {Moment(sql.dtDate).utc().format('hh:mm A')}
                    </>
                  ) : null}
                </td>
                <td>{sql.sWhyCancel ? <>{sql.sWhyCancel}</> : null} {sql.nRFQ} RFQs Sent</td>
              </MediaQuery>
            </tr>
          ))}
        </>
      );
    } else {
      table = (
        <>
          {this.state.data.map((sql) => (
            <tr
              style={{
                backgroundColor: positiveNegative(sql.sStatus),
              }}
              key={sql.id}
            >
              <MediaQuery minDeviceWidth={900}>
                <td>
                  <Link target="_blank" to={`/quote/${sql.id}`}>
                    {sql.id}
                  </Link>
                </td>
              </MediaQuery>
              <td>
                {' '}
                <Link
                  target="_blank"
                  to={`/crm/master-viewer/${sql.sCompany.replace(
                    /\//g,
                    '%2F'
                  )}`}
                >
                  {sql.sCompany}
                </Link>
              </td>
              <MediaQuery minDeviceWidth={900}>
                <td>{sql.sName ? sql.sName.slice(0, 20) : null}</td>
              </MediaQuery>
              <td>
                {sql.sCity} {sql.sState} {sql.sZip}
              </td>
              <MediaQuery minDeviceWidth={900}>
                <td>
                  {sql.dtDateStamp ? (
                    <>
                      {Moment.utc(sql.dtDateStamp).format('MM-DD-YYYY')}
                      <br />
                      {Moment.utc(sql.dtDateStamp).format('hh:mm A')}
                    </>
                  ) : null}
                </td>
              </MediaQuery>
              <td>
                {sql.nRep ? (
                  <>{whichRep(sql.nRep).match(/\b\w/g).join('')}</>
                ) : null}
              </td>
              <td data-tip={sql.sQuote}>
                {sql.sQuote ? (
                  <>
                    {sql.sQuote.slice(0, 110)}
                    <ReactTooltip multiline={true} effect={'solid'} />
                  </>
                ) : null}
              </td>
              <td style={{ textAlign: 'center' }}>
                {sql.sType ? sql.sType : null}
              </td>
              <MediaQuery minDeviceWidth={900}>
                <td style={{ color: calcIfLate(sql.dtFollowup) }}>
                  {sql.dtFollowup ? (
                    <>
                      {Moment.utc(sql.dtFollowup).format('MM-DD-YYYY')}
                      <br />
                      {Moment.utc(sql.dtFollowup).format('hh:mm A')}
                    </>
                  ) : null}
                </td>
                <td>{nQuoteTotalFormatter(sql.nQuoteTotal)}</td>
                <td>
                  {sql.dtDate ? (
                    <>
                      {Moment.utc(sql.dtDate).format('MM-DD-YYYY')}
                      <br />
                      {Moment.utc(sql.dtDate).format('hh:mm A')}
                    </>
                  ) : null}
                </td>
                <td>{sql.sWhyCancel ? <>{sql.sWhyCancel}</> : null} {sql.nRFQ} RFQs Sent</td>
              </MediaQuery>
            </tr>
          ))}
        </>
      );
    }

    return (
      <Wrapper amount={this.state.data.length}>
        <SubTitle>
          RAC RFQs <i className="far fa-clock"></i>
        </SubTitle>
        <div className="legend">
          <div className="legend-scale">
            <ul className="legend-labels">
              <li
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  this.setState({
                    rfqShowAll: false,
                  });
                  this.fetchFilteredData(
                    this.props.startDate,
                    this.props.endDate,
                    'Discontinued'
                  );
                }}
              >
                <span style={{ background: '#ff6666' }}></span>
                Discontinued
              </li>
              <li
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  this.setState({
                    rfqShowAll: false,
                  });
                  this.fetchFilteredData(
                    this.props.startDate,
                    this.props.endDate,
                    'Open'
                  );
                }}
              >
                <span style={{ background: '#fafafa' }}></span>Open
              </li>
              <li
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  this.setState({
                    rfqShowAll: false,
                  });
                  this.fetchFilteredData(
                    this.props.startDate,
                    this.props.endDate,
                    'Closed'
                  );
                }}
              >
                <span style={{ background: '#85bb65' }}></span>Closed
              </li>
            </ul>
          </div>
        </div>
        <h4>
          <span>Total RFQs: {total}</span>
          <span>Open RFQs: {open}</span>
          <span>Closed RFQs: {closed}</span>
          <span>Discontinued RFQs: {discontinued}</span>
          <span><div onClick={() => this.setState({showHeatMap: !this.state.showHeatMap})}>Show Heat Map</div></span>
        </h4>
        <table className="dashboard">
          <thead>
            <tr>
              <MediaQuery minDeviceWidth={900}>
                <th>ID</th>
              </MediaQuery>
              <th>Company</th>
              <MediaQuery minDeviceWidth={900}>
                <th>Customer</th>
              </MediaQuery>
              <th>Location</th>
              <MediaQuery minDeviceWidth={900}>
                <th>Date</th>
              </MediaQuery>
              <th>Rep</th>
              <th>Quote</th>
              <MediaQuery minDeviceWidth={900}>
                <th>Quote Type</th>
                <th>Next Follow Up</th>
                <th>Quote $</th>
                <th>Last Activity</th>
                <th></th>
              </MediaQuery>
            </tr>
          </thead>
          <tbody>
            {this.state.loaded || (
              <tr>
                <td colSpan="12">
                  <Loader
                    style={{
                      textAlign: 'center',
                    }}
                    type="ThreeDots"
                    color="#00BFFF"
                    height={50}
                    width={120}
                  />
                </td>
              </tr>
            )}
            {this.state.loaded && (
              <>
                {this.state.data.length > 0 ? (
                  <>{table}</>
                ) : (
                  <tr>
                    <td
                      colSpan="12"
                      style={{ textAlign: 'center', padding: '1em' }}
                    >
                      No quotes
                    </td>
                  </tr>
                )}
                {this.state.data.length > 10 ? (
                  <>
                    {this.state.showAll ? (
                      <button
                        onClick={() => {
                          this.setState({
                            showAll: false,
                          });
                        }}
                      >
                        Hide
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          this.setState({
                            showAll: true,
                          });
                        }}
                      >
                        Show All
                      </button>
                    )}
                  </>
                ) : null}
              </>
            )}
          </tbody>
        </table>
        {this.state.showHeatMap && (
          <>
          <PageWrapper></PageWrapper>
          <Modal>
            <ModalContent>
              <div onClick={() => this.setState({showHeatMap: !this.state.showHeatMap})}>Hide Heat Map</div>
            </ModalContent>
            <ModalContent>
            <HeatMap 
              points={this.state.data}
            />
            </ModalContent>
          </Modal>
          
          </>
        )}
      </Wrapper>
    );
  }
}
