import React, { Component } from 'react';
import { getSQLData } from '../../assets/functions/fetch';

class CannedResponses extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loaded: false,
            cannedResponses: {},
            selectedResponse: {},
        };
    
      }
    componentDidMount(){
        this.fetchData()
    }
    fetchData = async () => {
        const fetchCanned = await getSQLData("email/cannedResponses", {nRep: this.props.user})
        if(fetchCanned){
            let cannedResponses = fetchCanned.data.data[0].recordset
            this.setState({
                cannedResponses: cannedResponses,
                loaded: true
            })
        }
             
    }
    selectResponse(index){
        let selectedResponse = this.state.cannedResponses[index]
        this.setState({
            selectedResponse: selectedResponse,
        })
    }
    render() {
        let cannedResponses = this.state.cannedResponses
        let selectedResponse = this.state.selectedResponse
        return (
            <div>
                {selectedResponse?.sName && (
                    <div style={{display: 'inline-block'}}>
                        <div>Name:<input></input></div>
                        <div><textarea></textarea></div>
                        <div>Type:<input></input></div>
                        <div><input type="checkbox"></input>Global</div>
                        <div><button>Delete</button></div>
                    </div>
                )}
                
                <div style={{display: 'inline-block', verticalAlign: 'top'}}>
                <table>
                    <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Personal</th>
                        <th>Delete</th>
                    </tr>
                
                {this.state.loaded && cannedResponses.map((response,index)=>(
                    <tr onClick={()=>this.selectResponse(index)}>
                        <td>{response.sName}</td>
                        <td>{response.sType}</td>
                        <td>{response.nRep > 0 ? "Yes" : "No"}</td>
                        <td></td>
                    </tr>
                ))}
                </table>
                </div>
            </div>

    )}
}

export default CannedResponses;