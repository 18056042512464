import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Loader from 'react-loader-spinner';
import Moment from 'moment';
import { whichRep } from '../../../assets/functions/calculations';

export default class OpenContractsRow extends Component {
  state = {
    quoteLines: [],
    POs: [],
    nPoTotals: 0,
    nSubTotal: 0,
    loaded: false,
  };

  // Async Axios
  fetchData = async (id) => {
    try {
      const response = await Axios.get('/rac-dashboard/open-contracts-row', {
        params: { id: id },
        headers: { 'Content-Type': 'application/json' },
      });
      // Sets the state using the response data
      this.setState({
        POs: response.data.result[0].recordset,
        quoteLines: response.data.result[1].recordset,
      });
      // Calculates nPoTotals
      for (let i = 0; i < this.state.POs.length; i++) {
        this.state.nPoTotals += this.state.POs[i].nPOTotal;
      }
      // Calculates nSubTotal
      for (let i = 0; i < this.state.quoteLines.length; i++) {
        let nItemTotal =
          this.state.quoteLines[i].nPrice * this.state.quoteLines[i].nQuantity;
        this.state.nSubTotal += nItemTotal;
      }
      this.setState({
        loaded: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    this.fetchData(this.props.id);
  }

  render() {
    return (
      <tr key={this.props.id}>
        <td>
          <Link target="_blank" to={`/quote/${this.props.id}`}>
            {this.props.id}
          </Link>
        </td>
        <td>
          <Link
            target="_blank"
            to={`/crm/master-viewer/${this.props.sCompany.replace(
              /\//g,
              '%2F'
            )}`}
          >
            {this.props.sCompany}
          </Link>
        </td>
        <td>{whichRep(this.props.nRep)}</td>
        <td style={{ textAlign: 'center' }}>
          {Moment(this.props.dtDeliveryDate).utc().format('MM/DD/YYYY')}
        </td>
        <td style={{ textAlign: 'center' }}>
          {Moment(this.props.dtRebillDate).utc().format('MM/DD/YYYY')}
        </td>
        <td style={{ textAlign: 'center' }}>
          {this.state.loaded ? (
            <>
              {'$' +
                this.state.nSubTotal.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
            </>
          ) : (
            <Loader type="ThreeDots" color="#00BFFF" height={10} width={30} />
          )}
        </td>
        <td style={{ textAlign: 'center' }}>
          {this.state.loaded ? (
            <>
              {'$' +
                this.state.nPoTotals.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
            </>
          ) : (
            <Loader type="ThreeDots" color="#00BFFF" height={10} width={30} />
          )}
        </td>
      </tr>
    );
  }
}
