import React, { Component } from "react";
import Axios from "axios";
import Loader from "react-loader-spinner";
import styled from "styled-components";
import Moment from "moment";
import Cookies from "js-cookie";
import { formatMStoDays, nQuoteTotalFormatter, nQuoteTotalNoDollar, whichRep } from "../../assets/functions/calculations";
import { Link } from 'react-router-dom';
import ReactTooltip from "react-tooltip";
import { GoogleApiWrapper, Map } from "google-maps-react";
import HeatMap2 from "../misc/HeatMap2";

const Input = styled.input`
`;

const SubTitle = styled.h2`
  padding: 1em 0;
  text-align: center;
`;

const Wrapper = styled.section`
  padding: 2em 0 1em 0;
  text-align: left;
  align-content: left;
  @media (max-width: 1300px) {
    padding-bottom: 5em;
  };
  
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  background-color: #fff;
  max-width: 1300px;
  width: 90vw;
  border-radius: 10px;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.1);
  margin: 3em auto;
  padding: 1em;
`;

const LeftPane = styled.div`
  display: flex;
  justify-content: center;
  width: 20%;
  margin: 1em 0.5em 1em 2em;
`;

const RightPane = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 1em 2em 1em 0.5em;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Button = styled.button`
  display: inline-block;
   padding: 0.8em 2em;
   margin: 1em 0.3em 1em 0;
   text-decoration: none;
   color: #fafafa;
   text-align: center;
   transition: all 0.2s;
  background-color: rgba(54, 162, 235, 0.9);
  border: 1px solid #fafafa;
  cursor: pointer;
  border-radius: 50px;

  &:hover {
    border: 1px solid #d7d7d7;
  }
`;

const Select = styled.select`
  width: 100%;
  white-space:pre-wrap;
  margin: 0.5em auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.4em;
`
const Option = styled.option`
max-width: 100%;
overflow: hidden;
word-wrap: normal !important;
white-space: normal;
`

const Table = styled.table`
  td {
    outline: 1px solid black;
  }
`

export default class SupplierViewer extends Component {
  state = {
    data: [],
    skillsets: [],
    supplierSkills: [],
    comments: [],
    loaded: false,
    terms: {},
    supplier: "No supplier",
    rating: "",
    viewing: "Main",
    comment: "",
    commentQuote: "",
    commentRating: 0,
    user: 0,
    TAUser: 0,
    selectedSkills: [],
    selectedNewSkills: [],
    dataChanged: false,
    performance: {},
    POs: [],
    RFQs: [],
    makeInactive: false,
    items: [],
    itemMaster: [],
    categories: [],
    usage: [],
    locations: [],
    contacts: [],
    selectedCategory: -1,
    selectedItem: -1,
    deleteMode: false,
    RFQsClosed: 0,
    RFQsDisc: 0,
    RFQsDiscAwarded: 0,
    RFQsOpen: 0,
    RFQsOpenAwarded: 0,
    RFQsWon: 0,
    RFQsClosedResponded: 0,
    RFQsOpenResponded: 0,
    RFQsDiscResponded: 0,
    noQuotes: 0,
    showRFQResponse: true,
    showRFQNoResponse: true,
    showRFQOpen: true,
    showRFQClosed: true,
    showRFQDiscontinued: true,
    showRFQWon: true,
    showRFQLost: true,
    showRFQNoDecision: true,
    showShipping: true,
    showDelivery: true,
    showFullMatch: true,
    showProbably: true,
    showProbablyNot: true,
    showNoMatch: true,
    showNoMatchResponse: true,
  };

  fetchUser = async () => {
    try {
      const passport = await Axios.get('/userSession', {
        credentials: 'include',
      });
      if (passport.data.passport) {
        this.setState({
          user: passport.data.passport.user,
        });

      }
      const response = await Axios.get('/user', {
        credentials: 'include',
      });
      if(response){
        this.setState({TAUser: response.data.result[0].recordset[0].nTechArmyId})
      }

      if (
        response.data.result[0].recordset[0].sTTATitle === 'Developer' ||
        response.data.result[0].recordset[0].sTTATitle === 'CEO'
      ) {
        this.setState({
          bAdmin: !this.state.bAdmin,
        });
        Cookies.set('sTTATitle', 'admin');
      } else {
        if (response.data.result[0].recordset[0].bAdmin === true) {
          this.setState({
            bAdmin: !this.state.bAdmin,
          });
          Cookies.set('sTTATitle', 'admin');
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Async Axios
  fetchData = async (sSupplier) => {
    let supplierName = sSupplier

    if(parseInt(sSupplier) == sSupplier){
      try {
        const response = await Axios.get("/srm/get-supplier-data", {
          params: {
            supplier: parseInt(sSupplier),
          },
          headers: { "Content-Type": "application/json" },
        });
        if (response){
          console.log(response.data.result[6])
          this.setState({
            data: response.data.result[0].recordset[0], 
            skillsets: response.data.result[1].recordset, 
            comments: response.data.result[2].recordset, 
            performance: response.data.result[3].recordset[0],
            POs: response.data.result[4].recordset,
            RFQs: response.data.result[5].recordset,
            items: response.data.result[6].recordset,
            locations: response.data.result[7].recordset,
            contacts: response.data.result[8].recordset,
            itemMaster: response.data.result2[0].recordset,
            categories: response.data.result2[1].recordset,
            usage: response.data.result2[2].recordset,
          },  () => this.prepareData())
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  fetchRFQInfo = async (quoteString) => {
    try {
      const response = await Axios.get("/srm/get-RFQ-info", {
        params: {
          quoteString: quoteString,
        },
        headers: { "Content-Type": "application/json" },
      });
      if (response){
        this.setState({
          categories: response.data.result[1].recordset
        }, () => this.prepareRFQData(response.data.result[0].recordset))
        
      }
    } catch (error) {
      console.error(error);
    }
  }

  prepareRFQData(getData){
    let RFQs = this.state.RFQs
    let categories = this.state.categories
    let skillsetData = []
    skillsetData.blank = {
      countTotal: 0,
      countWon: 0,
      salesTotal: 0,
      salesWon: 0,
      name: "No Skillset",
    }
    
    if(getData){
      for(let i=0; i<getData.length; i++){
        if(getData[i].nItem < 100000){
          if(getData[i].sTitle && !RFQs.find(({nQuote}) => nQuote === getData[i].nQuote).skillsets.find(({title}) => title === getData[i].sTitle)){
            RFQs.find(({nQuote}) => nQuote === getData[i].nQuote).skillsets.push({title: getData[i].sTitle, id: getData[i].nSkillset})
          }
          
        } else {
          let item = getData[i].nItem.toString()
          item = item.substring(5)
          let category = categories.find(({id}) => id===parseInt(item))
          
          if(category.sTitle && !RFQs.find(({nQuote}) => nQuote === getData[i].nQuote).skillsets.find(({title}) => title === category.sTitle)){
            RFQs.find(({nQuote}) => nQuote === getData[i].nQuote).skillsets.push({title: category.sTitle, id: category.nSkillset})
          }
        }
        
      }

    }
    
    let count = 0
    let RFQsRated = 0
    let RFQsFullMatch = 0
    let RFQsProbably = 0
    let RFQsProbablyNot = 0
    let RFQsNoMatch = 0

    for(let i=0; i<RFQs.length; i++){
      if(RFQs[i].sApples){
        RFQsRated++
        switch(RFQs[i].sApples){
          case "Full Match":
            RFQsFullMatch++
            break;
          case "Probably":
            RFQsProbably++
            break;
          case "Probably Not":
            RFQsProbablyNot++
            break;
          case "No Match":
            RFQsNoMatch++
            break;
          default:

        }
      }


      if((Moment()-Moment(RFQs[i].dtSent))/3600/24/1000 <= 365 && RFQs[i].nSubTotal > 0){
      if(RFQs[i].skillsets.length > 0){
        
        for(let j=0; j<RFQs[i].skillsets.length; j++){
          
          let skill = RFQs[i].skillsets[j].id
          if(!skillsetData[skill]){
            skillsetData[skill] = {
              countTotal: 0,
              countWon: 0,
              salesTotal: 0,
              salesWon: 0,
              name: RFQs[i].skillsets[j].title,
            }
          }
          skillsetData[skill].countTotal++
          skillsetData[skill].salesTotal += RFQs[i].nSubTotal + RFQs[i].nDelivery
          if(RFQs[i].status === "Closed" && RFQs[i].POs > 0){
            skillsetData[skill].countWon++
            skillsetData[skill].salesWon += RFQs[i].nSubTotal + RFQs[i].nDelivery
          }
        }
      } else {
        skillsetData.blank.countTotal++
        skillsetData.blank.salesTotal += RFQs[i].nSubTotal + RFQs[i].nDelivery
          if(RFQs[i].status === "Closed" && RFQs[i].POs > 0){
            skillsetData.blank.countWon++
            skillsetData.blank.salesWon += RFQs[i].nSubTotal + RFQs[i].nDelivery
          }
      }
      }
    }



    this.setState({
      RFQs: RFQs,
      skillsetData: skillsetData,
      loaded: true,
      RFQsRated: RFQsRated,
      RFQsFullMatch: RFQsFullMatch,
      RFQsProbably: RFQsProbably,
      RFQsProbablyNot: RFQsProbablyNot,
      RFQsNoMatch: RFQsNoMatch,
    })
  }

  prepareData(){
    console.log(this.state.itemMaster, this.state.categories)

    let skillsets = []
    let skills = this.state.skillsets.length
    let maxID = this.state.skillsets[skills-1].id

    for(let i=0; i<=maxID; i++){
      skillsets[i] = {title: "", active: false, supplier: false}
    }

    for(let i=0; i<this.state.skillsets.length; i++){
      skillsets[this.state.skillsets[i].id].title = this.state.skillsets[i].sTitle
      skillsets[this.state.skillsets[i].id].active = this.state.skillsets[i].bActive
      skillsets[this.state.skillsets[i].id].id = this.state.skillsets[i].id
    }
    let supplierSkills = []

    if(this.state.data.sSkillset){
      supplierSkills = this.state.data.sSkillset.split(',')
  
    
      for(let i=0; i<supplierSkills.length; i++){
        skillsets[supplierSkills[i]].supplier = true
        supplierSkills[i] = skillsets[supplierSkills[i]]
      }
    }
    

    let totalRating = 0
    let ratings = 0
    let avgRating = ""

    for(let i=0; i<this.state.comments.length; i++){
      if(parseFloat(this.state.comments[i].nRating) > 0){
        totalRating = totalRating + this.state.comments[i].nRating
        ratings = ratings + 1
      }
    }
    if(ratings > 0){
      avgRating = totalRating / ratings
    }

    let RFQs = this.state.RFQs
    let count = 0
    let open = 0
    let closed = 0
    let won = 0
    let discontinued = 0
    let discontinuedAwarded = 0
    let openAwarded = 0
    let openResponded = 0
    let closedResponded = 0
    let discResponded = 0
    let totalResponseTime = 0
    let RFQString = ""
    let quitCounting = false
    
    for(let i=0; i<RFQs.length; i++){
      RFQs[i].skillsets = []

      if(RFQString === ""){
        RFQString += RFQs[i].nQuote
      } else {
        if(encodeURI(RFQString).split(/%..|./).length-1 < 4000){
          RFQString += "," + RFQs[i].nQuote
        }
      }
      

      if((Moment()-Moment(RFQs[i].dtSent))/3600/24/1000 > 365) {
        
      } else {
        
        count++

        if(RFQs[i].status === "Open"){
          open++
          if(RFQs[i].dtDateSubmitted && RFQs[i].nSubTotal>0){
            openResponded++
            totalResponseTime += (Moment(RFQs[i].dtDateSubmitted) - Moment(RFQs[i].dtSent))/1000/3600
          }
          if(RFQs[i].POs > 0){
            openAwarded++
          }
        }
        if(RFQs[i].status === "Closed" && RFQs[i].nSubTotal>0){
          closed++
          if(RFQs[i].dtDateSubmitted){
            closedResponded++
            totalResponseTime += (Moment(RFQs[i].dtDateSubmitted) - Moment(RFQs[i].dtSent))/1000/3600
          }
          if(RFQs[i].POs > 0){
            won++
          }
        }
        if(RFQs[i].status === "Discontinued" && RFQs[i].nSubTotal>0){
          discontinued++
          if(RFQs[i].dtDateSubmitted){
            discResponded++
            totalResponseTime += (Moment(RFQs[i].dtDateSubmitted) - Moment(RFQs[i].dtSent))/1000/3600
          }
          if(RFQs[i].POs > 0){
            discontinuedAwarded++
          }
        }
      } 
    }

    let POs = this.state.POs
    let noQuotes = 0
    for(let i=0; i<POs.length; i++){
      if(POs[i].RFQs === 0 && POs[i].status ==="Closed"){
        noQuotes++
      }
    }

    let RFQsResponseTime = totalResponseTime / (openResponded+closedResponded+discResponded)

    this.setState({
      skillsets: skillsets, 
      supplierSkills: supplierSkills, 
      rating: avgRating, 
      RFQsResponseTime: RFQsResponseTime,
      RFQsOpen: open,
      RFQsClosed: closed,
      RFQsWon: won,
      RFQsDisc: discontinued,
      RFQsDiscAwarded: discontinuedAwarded,
      RFQsOpenAwarded: openAwarded,
      RFQsOpenResponded: openResponded,
      RFQsClosedResponded: closedResponded,
      RFQsDiscResponded: discResponded,
      RFQsCount: count,
      noQuotes: noQuotes,

    }, () => this.fetchRFQInfo(RFQString))
  }

  componentDidMount() {
    this.fetchUser();
    if (this.props.match.params.supplier) {
      this.setState(
        {
          supplier: this.props.match.params.supplier,
        },
        () => {
          this.fetchData(this.props.match.params.supplier);
        }
      );

      window.addEventListener('keydown',(event) => {

        if(event.key === '`'){
  
          this.setState({deleteMode: !this.state.deleteMode})
          
        }
      })
  
    }

    window.addEventListener('keydown',(event) => {

      if(event.key === '`'){

        this.setState({makeInactive: !this.state.makeInactive})
        
      }
    })
  }

  changeView(getView) {
    this.setState({viewing: getView})
  }

  handleOnChange = (value, target) => {
    if(target.substring(0,4)==="data"){
      let data = this.state.data
      let newTarget = target.substring(5,target.length+1)
      data[newTarget] = value

      this.setState({data: data, dataChanged: true})
    } else {
      console.log(target)
      this.setState({[target]: value})
    }
    

  };

  changeLocation = (value, target, index) => {
    let locations = this.state.locations
    locations[index][target] = value

    this.setState({locations: locations})
  }
  changeContact = (value, target, index) => {
    let contacts = this.state.contacts
    contacts[index][target] = value

    this.setState({contacts: contacts})
  }
  updateContactLocation = (value, index) => {
    let contacts = this.state.contacts
    contacts[index].nLocation = value

    this.setState({contacts: contacts})
  }
  updateLocationPrimaryContact = (value, index) => {
    let locations = this.state.locations
    locations[index].nPrimaryContact = value

    this.setState({locations: locations})
  }
  updatePrimaryContact = (value) => {
    

    let data = this.state.data

    if(value > -1){
      let contact = this.state.contacts.find(x => x.id == value)
      data.nPrimaryContact = contact.id
      data.sFirstName1 = contact.sName
      data.sPhone1 = contact.sPhone
      data.sEmail1 = contact.sEmail
    } else {
      data.nPrimaryContact = ""
      data.sFirstName1 = ""
      data.sPhone1 = ""
      data.sEmail1 = ""
    }
    
    
    this.setState({
      data: data,
      dataChanged: true,
    })
  }
  updatePrimaryLocation = (value) => {


    let data = this.state.data
    if(value > -1){
      let location = this.state.locations.find(x => x.id == value)
      data.sAddress1 = location.sAddress
      data.sCity1 = location.sCity
      data.sState1 = location.sState
      data.sZip1 = location.sZip
      data.sCountry1 = location.sCountry
      data.nPrimaryLocation = location.id
    } else {
      data.sAddress1 = ""
      data.sCity1 = ""
      data.sState1 = ""
      data.sZip1 = ""
      data.sCountry1 = ""
      data.nPrimaryLocation = -1
    }

    this.setState({
      data: data,
      dataChanged: true,
    })

  }

  leaveComment = async () => {
    try {
      const response = await Axios.post(
        "/leave-comment",
        {
          comment: this.state.comment,
          commentRating: this.state.commentRating,
          commentQuote: this.state.commentQuote,
          supplier: this.state.data.id,
          user: this.state.TAUser,
        },
      );
      if(response){
        this.setState({comment: "", commentRating: 0, commentQuote: 0})
        this.fetchUser();
        if (this.props.match.params.supplier) {
          this.setState(
            {
              supplier: this.props.match.params.supplier,
            },
            () => {
              this.fetchData(this.props.match.params.supplier);
            }
          );
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  updateSkillsets = async (getSkillset) => {
    try {
      const response = await Axios.post(
        "/update-skillsets",
        {
          newSkillset: getSkillset,
          supplier: this.state.data.id
        },
      );
      if(response){
        this.fetchUser();
        if (this.props.match.params.supplier) {
          this.setState(
            {
              supplier: this.props.match.params.supplier,
            },
            () => {
              this.fetchData(this.props.match.params.supplier);
            }
          );
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  changeData = async () => {
    try {
      const response = await Axios.post(
        "/update-supplier",
        {
          data: this.state.data
        },
      );
      if(response){
        this.setState({dataChanged: false})
        this.fetchUser();
        if (this.props.match.params.supplier) {
          this.setState(
            {
              supplier: this.props.match.params.supplier,
            },
            () => {
              this.fetchData(this.props.match.params.supplier);
            }
          );
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  selectSkills = (options) => {
    let selectedSkills = []

    for (let i=0; i<options.length; i++){
      if (options[i].selected){
        selectedSkills.push(options[i].value)
      }
    }

    this.setState({selectedSkills: selectedSkills})
  }

  selectNewSkills = (options) => {
    let selectedNewSkills = []

    for (let i=0; i<options.length; i++){
      if (options[i].selected){
        selectedNewSkills.push(options[i].value)
      }
    }

    this.setState({selectedNewSkills: selectedNewSkills})
  }

  addSkills = () => {
    let supplierSkills = this.state.supplierSkills
    let skillsets = this.state.skillsets

    for (let i=0; i<this.state.selectedNewSkills.length; i++){
      skillsets[this.state.selectedNewSkills[i]].supplier = true
      supplierSkills.push(skillsets[this.state.selectedNewSkills[i]])
    }

    let newSkillsetString = ''

    for(let i=0; i<supplierSkills.length; i++){
      if (newSkillsetString === ''){
        newSkillsetString = supplierSkills[i].id
      } else {
        newSkillsetString = newSkillsetString + "," + supplierSkills[i].id
      }
    }

    this.updateSkillsets(newSkillsetString)

    this.setState({supplierSkills: supplierSkills, skillsets: skillsets})
    
  }

  removeSkills = () => {
    let supplierSkills = this.state.supplierSkills
    let skillsets = this.state.skillsets

    for (let i=0; i<this.state.selectedSkills.length; i++){
      skillsets[supplierSkills[this.state.selectedSkills[i]].id].supplier = false
      supplierSkills.splice(this.state.selectedSkills[i], 1)
    }

    let newSkillsetString = ''

    for(let i=0; i<supplierSkills.length; i++){
      if (newSkillsetString === ''){
        newSkillsetString = supplierSkills[i].id
      } else {
        newSkillsetString = newSkillsetString + "," + supplierSkills[i].id
      }
    }

    this.updateSkillsets(newSkillsetString)


    this.setState({supplierSkills: supplierSkills, skillsets: skillsets})
  }

  async saveSupplierItem (getItem) {
    try {
      let item = getItem
      const response = await Axios.post(
        "/save-supplier-item",
        {
          item: item
        },
      );
      if(response){
        let items = this.state.items
        items.find(x => x.id === item.id).changed = false
        this.setState({items: items})
      }
    } catch (error) {
      console.error(error)
    }
  }

  async deleteSupplierItem (getItem) {
    try {
      let item = getItem
      const response = await Axios.post(
        "/delete-supplier-item",
        {
          item: item
        },
      );
      if(response){
        this.fetchData(this.state.data.id)
      }
    } catch (error) {
      console.error(error)
    }
  }

  updateSupplierItem = (getValue, getIndex, getField) => {
    let value = ""
    let type = getField.charAt(0)

    if(getValue){value=getValue}
    if(type==="n" && isNaN(value)){
      value=0
    } else if(value === "null" || !value){
      value = ""
    }
    
    
    let items = this.state.items
    items[getIndex][getField] = value
    items[getIndex].changed = true

    this.setState({items: items})
  }

  sqlAddItemToVendor = async (getVendor) => {
    try {
      const response = await Axios.post(
        "/item-master/add-item-to-vendor",
        {
          vendor: getVendor,
          item: this.state.selectedItem
        },
      );
    } catch (error) {
      console.error(error)
    }
  }

  addItemToVendor = async () => {
    this.sqlAddItemToVendor(this.state.data.id).then(this.fetchData(this.state.data.id))

    return
  }

  newLocation(){
    console.log("test")
    
    let location = {
      nCompany: this.state.data.id,
      sName: "",
      sAddress: "",
      sCity: "",
      sState: "",
      sZip: "",
      sCountry: "",
      nPrimaryContact: -1,
      nServiceArea: 0,
      editing: true,
      new: true,
      bDeleted: false,
    }

    let locations = this.state.locations
    locations.push(location)

    this.setState({locations: locations})
  }
  editLocation(index){
    let locations = this.state.locations
    locations[index].editing=true

    this.setState({locations: locations})
  }
  saveLocation = async (index) => {
    try {
      console.log(this.state.locations[index])
      const response = await Axios.post(
        "/srm/save-location",
        {
          location: this.state.locations[index]
        },
      );

      if(response){this.fetchData(this.state.data.id)}
    } catch (error) {
      console.error(error)
    }
  }
  deleteLocation = async (index) => {
    try {
      console.log(this.state.locations[index])
      const response = await Axios.post(
        "/srm/delete-location",
        {
          id: this.state.locations[index].id
        },
      );

      if(response){this.fetchData(this.state.data.id)}
    } catch (error) {
      console.error(error)
    }
  }

  newContact(){
    let contact = {
      sRole: "",
      sName: "",
      sPhone: "",
      sEmail: "",
      nCompany: this.state.data.id,
      nLocation: -1,
      editing: true,
      new: true,
      bDeleted: false,
    }

    let contacts = this.state.contacts
    contacts.push(contact)

    this.setState({contacts: contacts})
  }
  editContact(index){
    let contacts = this.state.contacts
    contacts[index].editing=true
    this.setState({contacts: contacts})
  }
  saveContact = async (index) => {
    try {
      const response = await Axios.post(
        "/srm/save-contact",
        {
          contact: this.state.contacts[index]
        },
      );

      if(response){this.fetchData(this.state.data.id)}
    } catch (error) {
      console.error(error)
    }
  }
  deleteContact = async (index) => {
    try {
      console.log(this.state.contacts[index])
      const response = await Axios.post(
        "/srm/delete-contact",
        {
          id: this.state.contacts[index].id
        },
      );

      if(response){this.fetchData(this.state.data.id)}
    } catch (error) {
      console.error(error)
    }
  }

  render() {
    let dataKeys = []
    dataKeys = Object.keys(this.state.data)
    
    return (
      <>
      {this.state.loaded && (
        <Wrapper>
          <div style={{textAlign: 'center'}}>
            <SubTitle><div><div style={{display: 'inline-block'}}>{this.state.data.sCompany1} - {this.state.data.sFirstName1} {this.state.data.sLastName1}</div>
            <div style={{display: 'inline-block', paddingLeft: '50px'}}>
              <i style={{color: this.state.rating > 0.25 ? '#FFD700' : 'black'}} class={this.state.rating > 0.75 ? 'fas fa-star' : this.state.rating > 0.25 ? 'fas fa-star-half' : ''}></i>
              <i style={{color: this.state.rating > 1.25 ? '#FFD700' : 'black'}} class={this.state.rating > 1.75 ? 'fas fa-star' : this.state.rating > 1.25 ? 'fas fa-star-half' : ''}></i>
              <i style={{color: this.state.rating > 2.25 ? '#FFD700' : 'black'}} class={this.state.rating > 2.75 ? 'fas fa-star' : this.state.rating > 2.25 ? 'fas fa-star-half' : ''}></i>
              <i style={{color: this.state.rating > 3.25 ? '#FFD700' : 'black'}} class={this.state.rating > 3.75 ? 'fas fa-star' : this.state.rating > 3.25 ? 'fas fa-star-half' : ''}></i>
              <i style={{color: this.state.rating > 4.25 ? '#FFD700' : 'black'}} class={this.state.rating > 4.75 ? 'fas fa-star' : this.state.rating > 4.25 ? 'fas fa-star-half' : ''}></i>
            </div>
            </div>
            <div>{this.state.data.sAddress1} {this.state.data.sCity1}, {this.state.data.sState1} {this.state.data.sZip1}</div>
            </SubTitle>
            <div>{this.state.data.sPhone1}</div>
            <div>{this.state.data.sEmail1}</div>
            
          </div>
          <FormWrapper>
            <div>
              <Button onClick={()=>this.changeView("Main")}>Main</Button>
              <Button onClick={()=>this.changeView("Contact")}>Contact</Button>
              <Button onClick={()=>this.changeView("Skills")}>Skills</Button>
              <Button onClick={()=>this.changeView("Items")}>Items</Button>
              <Button onClick={()=>this.changeView("Comments")}>Comments</Button>
              <Button onClick={()=>this.changeView("POs")}>POs</Button>
              <Button onClick={()=>this.changeView("RFQs")}>RFQs</Button>
              <Button onClick={()=>this.changeView("Performance")}>Performance</Button>
              <Button onClick={()=>this.changeView("Account")}>Account</Button>
              
            </div>
          {this.state.viewing === "Main" && (
            <>
              <table>
                <tr>
                  <td style={{width: "200px"}}>ID</td>
                  <td>{this.state.data.id}</td>
                </tr>
              <tr><td>Company: </td>
              <td><Input onChange={(event) => this.handleOnChange(event.target.value,"data.sCompany1")} type="text" value={this.state.data.sCompany1} placeholder="Company Name"></Input></td></tr>
              <tr><td>Primary Contact:</td>
              <td>
                  {(this.state.contacts.length > 0 && (
                          <select defaultValue={this.state.data.nPrimaryContact} onChange={(event)=>this.updatePrimaryContact(event.target.value)}>
                            <option value={-1}>No Contact</option>
                            {this.state.contacts.map((contact, locId) =>
                            (
                              <option value={contact.id}>{contact.sName}</option>
                            ))}
                          </select>
                        )) || (
                          <>No Contacts</>
                    )}
              </td>
              </tr>
              <tr><td>Primary Location:</td>
              <td>
              {(this.state.locations.length > 0 && (
                          <select defaultValue={this.state.data.nPrimaryLocation} onChange={(event)=>this.updatePrimaryLocation(event.target.value)}>
                            <option value={-1}>No Location Selected</option>
                            {this.state.locations.map((location, locId) =>
                            (
                              <option value={location.id}>{location.sName}</option>
                            ))}
                          </select>
                        )) || (
                          <>No locations</>
                        )}
              </td>
              </tr>
              <tr>
              <td>Website</td>
              <td><Input onChange={(event) => this.handleOnChange(event.target.value,"data.sWebsite1")} type="text" value={this.state.data.sWebsite1} placeholder="Website"></Input>
              </td></tr>
              <tr><td>Domain Authority</td>
              <td><Input onChange={(event) => this.handleOnChange(event.target.value,"data.nDomainAuthority")} type="text" value={this.state.data.nDomainAuthority} placeholder="Domain Authority"></Input></td>
              
              </tr>
              <tr><td>Contact Name: </td>
              <td><Input onChange={(event) => this.handleOnChange(event.target.value,"data.sFirstName1")} type="text" value={this.state.data.sFirstName1} placeholder="Contact Name" disabled></Input></td></tr>
              
              <tr><td>Address: </td>
              <td><Input onChange={(event) => this.handleOnChange(event.target.value,"data.sAddress1")} type="text" value={this.state.data.sAddress1} placeholder="Address" disabled></Input></td></tr>
              <tr><td>City, State Zip:</td>
              <td><Input onChange={(event) => this.handleOnChange(event.target.value,"data.sCity1")} type="text" value={this.state.data.sCity1} placeholder="City" disabled></Input>
              <Input onChange={(event) => this.handleOnChange(event.target.value,"data.sState1")} type="text" value={this.state.data.sState1}  placeholder="State" disabled></Input>
              <Input onChange={(event) => this.handleOnChange(event.target.value,"data.sZip1")} type="text" value={this.state.data.sZip1}  placeholder="Zip" disabled></Input></td></tr>
              <tr><td>Country:</td>
              <td><Input onChange={(event) => this.handleOnChange(event.target.value,"data.sCountry1")} type="text" value={this.state.data.sCountry1} placeholder="Country" disabled></Input></td></tr>
              <tr><td>Primary Phone: </td>
              <td><Input onChange={(event) => this.handleOnChange(event.target.value,"data.sPhone1")} type="text" value={this.state.data.sPhone1} placeholder="Phone #" disabled></Input></td></tr>
              <tr><td>Primary Email: </td>
              <td><Input onChange={(event) => this.handleOnChange(event.target.value,"data.sEmail1")} type="text" value={this.state.data.sEmail1} placeholder="Email" disabled></Input></td></tr>
              <tr><td></td><td></td></tr>
              <tr><td>Alt Contact: </td>
              <td>
              <Input onChange={(event) => this.handleOnChange(event.target.value,"data.sLastName1")} type="text" value={this.state.data.sLastName1} disabled></Input>
                
              <Input onChange={(event) => this.handleOnChange(event.target.value,"data.sFirstName2")} type="text" value={this.state.data.sFirstName2} placeholder="Alt Contact Name" disabled></Input>
              <Input onChange={(event) => this.handleOnChange(event.target.value,"data.sLastName2")} type="text" value={this.state.data.sLastName2} disabled></Input></td></tr>
              <tr><td>Alt Phone: </td>
              <td><Input onChange={(event) => this.handleOnChange(event.target.value,"data.sPhone2")} type="text" value={this.state.data.sPhone2} placeholder="Alt Phone #" disabled></Input></td></tr>
              
              {/*<tr><td>Alt Company: </td>
              <td><Input onChange={(event) => this.handleOnChange(event.target.value,"data.sCompany2")} type="text" value={this.state.data.sCompany2}></Input></td></tr>
              
              <tr><td>Alt Address: </td>
              <td><Input onChange={(event) => this.handleOnChange(event.target.value,"data.sAddress2")} type="text" value={this.state.data.sAddress2}></Input></td></tr>
              <tr><td></td>
              <td><Input onChange={(event) => this.handleOnChange(event.target.value,"data.sCity2")} type="text" value={this.state.data.sCity2}></Input>
              <Input onChange={(event) => this.handleOnChange(event.target.value,"data.sState2")} type="text" value={this.state.data.sState2}></Input>
              <Input onChange={(event) => this.handleOnChange(event.target.value,"data.sZip2")} type="text" value={this.state.data.sZip2}></Input>
              <Input onChange={(event) => this.handleOnChange(event.target.value,"data.sCountry2")} type="text" value={this.state.data.sCountry2}></Input></td></tr>*/}
              
              <tr><td></td><td></td></tr>
              <tr><td>Other Info: </td>
              <td><textarea style={{width: 800, height: 200}} onChange={(event) => this.handleOnChange(event.target.value,"data.sOtherCompanyInfo")} value={this.state.data.sOtherCompanyInfo}></textarea></td></tr>
              <tr><td>Rating: </td>
              <td>{Math.round(this.state.rating*100)/100}</td></tr>
              <tr><td>Rank: </td>
              <td><Input onChange={(event) => this.handleOnChange(event.target.value,"data.nRank")} type="text" value={this.state.data.nRank}></Input></td></tr>
              <tr><td>Send Customer Emails: </td>
              <td><input onChange={(event) => this.handleOnChange(!this.state.data.bSendEmail,"data.bSendEmail")} type="checkbox" checked={this.state.data.bSendEmail}></input></td></tr>
              <tr><td>Preferred Vendor: </td>
              <td><input onChange={(event) => this.handleOnChange(!this.state.data.bPreferredVendor,"data.bPreferredVendor")} type="checkbox" checked={this.state.data.bPreferredVendor}></input></td></tr>
              <tr><td>Hardware Only: </td>
              <td><input onChange={(event) => this.handleOnChange(!this.state.data.bHardwareOnly,"data.bHardwareOnly")} type="checkbox" checked={this.state.data.bHardwareOnly}></input></td></tr>
              <tr><td>Beware: </td>
              <td><input onChange={(event) => this.handleOnChange(!this.state.data.bBeware,"data.bBeware")} type="checkbox" checked={this.state.data.bBeware}></input></td></tr>
              <tr><td>Active: </td>
              <td><input onChange={(event) => this.handleOnChange(!this.state.data.bActive,"data.bActive")} type="checkbox" checked={this.state.data.bActive}></input></td></tr>
              <tr><td>Deleted: </td>
              <td><input onChange={(event) => this.handleOnChange(!this.state.data.bDeleted,"data.bDeleted")} type="checkbox" checked={this.state.data.bDeleted}></input></td></tr>
              </table>
              {this.state.dataChanged && (
                <Button onClick={() => this.changeData()}>Update Supplier Info</Button>
              )}
            </>            
          )}
            
          {this.state.viewing === "Contact" && (
            <div>
              <h1 style={{textAlign: "center"}}>Locations</h1>
              <div style={{height: "450px"}}>
              <HeatMap2 points={this.state.locations} />
              </div>
              <table>
                <thead>
                  <th style={{maxWidth: '120px'}}>Name</th>
                  <th style={{width: '200px'}}>Address</th>
                  <th style={{width: '200px'}}>Address 2</th>
                  <th style={{width: '120px'}}>City</th>
                  <th style={{width: '60px'}}>State</th>
                  <th style={{width: '60px'}}>Zip</th>
                  <th style={{width: '80px'}}>Country</th>
                  <th style={{width: '150px'}}>Primary Contact</th>
                  <th style={{width: '100px'}}>Travel (mi)</th>
                  <th></th>
                </thead>
                {this.state.locations.map((location, index) => (
                  <>
                    {location.editing && (
                    <tr>
                      <td style={{padding: '1px'}}><input style={{width: '150px'}} onChange={(event)=>this.changeLocation(event.target.value,"sName", index)} value={location.sName}></input></td>
                      <td style={{padding: '1px'}}><input style={{width: '200px'}} onChange={(event)=>this.changeLocation(event.target.value,"sAddress", index)} value={location.sAddress}></input></td>
                      <td style={{padding: '1px'}}><input style={{width: '200px'}} onChange={(event)=>this.changeLocation(event.target.value,"sAddress2", index)} value={location.sAddress2}></input></td>
                      <td style={{padding: '1px'}}><input style={{width: '150px'}} onChange={(event)=>this.changeLocation(event.target.value,"sCity", index)} value={location.sCity}></input></td>
                      <td style={{padding: '1px'}}><input style={{width: '60px'}} onChange={(event)=>this.changeLocation(event.target.value,"sState", index)} value={location.sState}></input></td>
                      <td style={{padding: '1px'}}><input style={{width: '60px'}} onChange={(event)=>this.changeLocation(event.target.value,"sZip", index)} value={location.sZip}></input></td>
                      <td style={{padding: '1px'}}><input style={{width: '80px'}} onChange={(event)=>this.changeLocation(event.target.value,"sCountry", index)} value={location.sCountry}></input></td>
                      {/*<td style={{padding: '1px'}}><input style={{width: '150px'}} onChange={(event)=>this.changeLocation(event.target.value,"nPrimaryContact", index)} value={location.nPrimaryContact}></input></td>*/}
                      {(this.state.contacts.length > 0 && (
                          <select defaultValue={location.nPrimaryContact} onChange={(event)=>this.updateLocationPrimaryContact(event.target.value,index)}>
                            <option value={-1}>No Contact</option>
                            {this.state.contacts.map((contact, locId) =>
                            (
                              <option value={contact.id}>{contact.sName}({contact.id})</option>
                            ))}
                          </select>
                        )) || (
                          <>No Contacts</>
                        )}
                      <td style={{padding: '1px'}}><input style={{width: '100px'}} onChange={(event)=>this.changeLocation(event.target.value,"nServiceArea", index)} value={location.nServiceArea}></input></td>
                      <td style={{padding: '1px'}}>
                        {(this.state.deleteMode && (
                          <button onClick={()=>this.deleteLocation(index)}>Delete</button>
                        )) || (
                          <button onClick={()=>this.saveLocation(index)}>Save</button>
                        )}
                        
                      </td>
                    </tr>
                    ) || (
                    <tr>
                      <td>{location.sName}</td>
                      <td>{location.sAddress}</td>
                      <td>{location.sAddress2}</td>
                      <td>{location.sCity}</td>
                      <td>{location.sState}</td>
                      <td>{location.sZip}</td>
                      <td>{location.sCountry}</td>
                      <td>{location.nPrimaryContact == -1 ? "No Primary Contact" : this.state.contacts.find(x => x.id == location.nPrimaryContact).sName}</td>
                      <td>{location.nServiceArea}</td>
                      <td>
                      {(this.state.deleteMode && (
                          <button onClick={()=>this.deleteLocation(index)}>Delete</button>
                        )) || (
                          <button onClick={()=>this.editLocation(index)}>Edit</button>
                        )}
                      </td>
                    </tr>
                    )}
                  </>
                ))}
                <tr>
                  <td colSpan={10} onClick={()=>this.newLocation()}><button>New Location</button></td>
                </tr>
              </table>
              <br />
              <br />
              <h1 style={{textAlign: "center"}}>Contacts</h1>
              <table>
                <thead>
                  <th style={{width: '150px'}}>Role</th>
                  <th style={{width: '150px'}}>Name</th>
                  <th style={{width: '150px'}}>Phone</th>
                  <th style={{width: '150px'}}>Alt Phone</th>
                  <th style={{width: '150px'}}>Email</th>
                  <th style={{width: '150px'}}>Location</th>
                  <th style={{width: '150px'}}></th>
                </thead>
                {this.state.contacts.map((contact, index) => (
                  <tr>
                    {contact.editing && (
                    <>
                      <td style={{padding: '1px'}}><input style={{width: '150px'}} onChange={(event)=>this.changeContact(event.target.value,"sRole",index)} value={contact.sRole}></input></td>
                      <td style={{padding: '1px'}}><input style={{width: '150px'}} onChange={(event)=>this.changeContact(event.target.value,"sName",index)} value={contact.sName}></input></td>
                      <td style={{padding: '1px'}}><input style={{width: '150px'}} onChange={(event)=>this.changeContact(event.target.value,"sPhone",index)} value={contact.sPhone}></input></td>
                      <td style={{padding: '1px'}}><input style={{width: '150px'}} onChange={(event)=>this.changeContact(event.target.value,"sPhone2",index)} value={contact.sPhone2}></input></td>
                      <td style={{padding: '1px'}}><input style={{width: '150px'}} onChange={(event)=>this.changeContact(event.target.value,"sEmail",index)} value={contact.sEmail}></input></td>
                      <td style={{padding: '1px'}}>
                        {/*<input style={{width: '150px'}} onChange={(event)=>this.changeContact(event.target.value,"nLocation",index)} value={contact.nLocation}></input>*/}
                        {(this.state.locations.length > 0 && (
                          <select defaultValue={contact.nLocation} onChange={(event)=>this.updateContactLocation(event.target.value,index)}>
                            <option value={-1}>No Location Selected</option>
                            {this.state.locations.map((location, locId) =>
                            (
                              <option value={location.id}>{location.sName}</option>
                            ))}
                          </select>
                        )) || (
                          <>No locations</>
                        )}
                        
                      </td>
                      <td style={{padding: '1px'}}>
                      {(this.state.deleteMode && (
                          <button onClick={()=>this.deleteContact(index)}>Delete</button>
                        )) || (
                          <button onClick={()=>this.saveContact(index)}>Save</button>
                        )}
                        </td>
                    </>
                    ) || (
                    <>
                      <td>{contact.sRole}</td>
                      <td>{contact.sName}</td>
                      <td>{contact.sPhone}</td>
                      <td>{contact.sPhone2}</td>
                      <td>{contact.sEmail}</td>
                      <td>{contact.nLocation === -1 ? "No Location" : this.state.locations.find(x => x.id === contact.nLocation).sName}</td>
                      <td>
                        {(this.state.deleteMode && (
                          <button onClick={()=>this.deleteContact(index)}>Delete</button>
                        )) || (
                          <button onClick={()=>this.editContact(index)}>Edit</button>
                        )}
                        </td>
                    </>
                    )}
                    
                  </tr>
                ))}
                <tr>
                  <td colspan={6} onClick={()=>this.newContact()}><button>New Contact</button></td>
                </tr>
              </table>

            </div>
          )}

          {this.state.viewing === "Skills" && (
            <div>
              <div style={{display: 'inline-block'}}>
                <div>
                  Supplier's Skillsets: 
                </div>
                <div>
                  <Select onChange={(event) => this.selectSkills(event.target.options)} style={{width: "300px", height: "500px"}} multiple>
                {this.state.supplierSkills.map((skill, index) => (
                <>
                  {skill.active ? (<Option value={index}>{skill.title}</Option>) : ""} 
                </>
                ))}
                  </Select>
                </div>
              </div>
              <div style={{display: 'inline-block', width: '300px', height: "500px", verticalAlign: 'top', textAlign: 'center'}}>
                <div><Button onClick={() => this.addSkills()} style={{width: '250px'}}>&larr; Add Skillset</Button></div>
                <div><Button onClick={() => this.removeSkills()} style={{width: '250px'}}>Remove Skillset &rarr;</Button></div>
              </div>
              <div style={{display: 'inline-block'}}>
                <div>
                  Supplier's Gaps: 
                </div>
                <Select onChange={(event) => this.selectNewSkills(event.target.options)} style={{width: "300px", height: "500px"}} multiple>
                {this.state.skillsets.map((skill, index) => (
                <>
                  {(skill.active && !skill.supplier) ? (<Option value={skill.id}>{skill.title}</Option>) : ""} 
                </>
                ))}
                </Select>
              </div>
            </div>
          )}

          {this.state.viewing === "Items" && (
            <div>
              <table>
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>List Price</th>
                    <th>Supplier Item</th>
                    <th>Notes</th>
                    <th></th>
                  </tr>
                </thead>
                
                {this.state.items.map((item,ind) => (
                  <tr>
                    <td>{item.sName} {item.itemID}</td>
                    <td><input value={item.nPrice} onChange={(event) => this.updateSupplierItem(parseFloat(event.target.value), ind, "nPrice")}></input></td>
                    <td><input value={item.sSupplierItem} onChange={(event) => this.updateSupplierItem(event.target.value, ind, "sSupplierItem")}></input></td>
                    <td><input value={item.sNotes} onChange={(event) => this.updateSupplierItem(event.target.value, ind, "sNotes")}></input></td>
                    <td>
                      {item.changed && (<button onClick={() => this.saveSupplierItem(item)}>Save Changes</button>)}
                      {this.state.makeInactive && (<button onClick={() => this.deleteSupplierItem(item)}>Delete Item</button>)}
                    </td>
                  </tr>
                ))}
              </table>
              <div style={{display: 'inline-block'}}>
                <Select onChange={(event) => this.setState({selectedCategory: event.target.value})} value={this.state.selectedCategory} size={20} style={{width: 500}}>
                  {this.state.categories.map((category, index) => (
                    <>
                      {<Option value={category.id}>{category.sName}</Option>} 
                    </>
                  ))}                      
                </Select>
              </div>
              <div style={{display: 'inline-block'}}>
                <Select onChange={(event) => this.setState({selectedItem: event.target.value})} value={this.state.selectedItem} size={20} style={{width: 500}}>
                  {this.state.selectedCategory >= 0 && this.state.itemMaster.map((item, index) => (
                    <>
                      {(this.state.selectedCategory == item.nCat && this.state.items.filter(x => x.itemID == item.id).length===0) && <Option value={item.id}>{item.sName}</Option>}
                    </>
                  ))}                      
                </Select>
              </div>
              <div>
                {this.state.selectedItem >= 0 && (
                  <button onClick={() => this.addItemToVendor()}>Add Item to Vendor</button>
                )}
              </div>
            </div>
          )}

          {this.state.viewing === "Comments" && (
            <>
              <div>
                <textarea onChange={(event) => this.handleOnChange(event.target.value, "comment")} style={{resize: 'none', width: '100%', height: '300px'}} value={this.state.comment}></textarea>
                <div>
                  <div style={{display:'inline-block', width: '100px'}}>
                    Rating
                  </div>
                  <div style={{display:'inline-block', width: '300px'}}>
                    <Select onChange={(event) => this.handleOnChange(event.target.value, "commentRating")} value={this.state.commentRating}>
                      <Option value={0}>No Rating</Option>
                      <Option value={5}>5 - Best</Option>
                      <Option value={4}>4</Option>
                      <Option value={3}>3</Option>
                      <Option value={2}>2</Option>
                      <Option value={1}>1 - Worst</Option>
                    </Select>
                  </div>
                </div>
                <div>
                  <div style={{display:'inline-block', width: '100px'}}>Quote</div>
                  <input onChange={(event) => this.handleOnChange(event.target.value, "commentQuote")} type="text" style={{width: '300px'}} value={this.state.commentQuote}></input>
                </div>
                <Button onClick={() => this.leaveComment()}>Leave a comment</Button>
              </div>
              <Table>
              {this.state.comments.map((comment, index) => (
                <tr>
                <td style={{width:'180px', minHeight: '100px'}}>
                  <div>{comment.sFirst} {comment.sLast}</div>
                  <div>{parseFloat(comment.nRating)>0 ? (<>Rating: {comment.nRating}/5</>) : "Unrated"}</div>
                  <div>{parseFloat(comment.nQuote)>0 ? "Quote: " + comment.nQuote : ""}</div>
                  <div>{Moment(comment.dtPostDate).utc().format('MM-DD-YYYY')}</div>
                </td>
                <td style={{minHeight: '100px'}}>
                  <div>
                    {comment.sComments}
                  </div>
                </td>                
                </tr>
              ))}
              </Table>
            </>
          )}
          
          {this.state.viewing === "POs" && (
            <div style={{textAlign: 'center'}}>
            <SubTitle>POs</SubTitle>
            <table style={{textAlign: 'center', width: '1100px', display: 'inline-block'}} className="dashboard-mini">
              <thead>
                <th style={{width: '8%'}}>Quote</th>
                <th style={{width: '32%'}}>Customer</th>
                <th style={{width: '25%'}}>Location</th>
                <th style={{width: '6%'}}>Price</th>
                <th style={{width: '13%'}}>Rep</th>
                <th style={{width: '8%'}}>Delivery</th>
                <th style={{width: '8%'}}>Pickup</th>
              </thead>
              {this.state.POs.map((POs) => (
                <tr>
                  <td><Link target="_blank" to={`/quote/${POs.nAttachedQuote}`}>{POs.nAttachedQuote}</Link></td>
                  <td>{POs.sDCompany}</td>
                  <td>{POs.sDCity}, {POs.sDState} {POs.sDZip}</td>
                  <td>{nQuoteTotalFormatter(POs.nPOTotal)}</td>
                  <td>{whichRep(POs.nRep)}</td>
                  <td>{POs.dtDeliveryDate}</td>
                  <td>{POs.dtPickupDate}</td>
                </tr>
              ))}
            </table>
            </div>
          )}

          {this.state.viewing === "RFQs" && (
            <div style={{textAlign: 'center'}}>
            <SubTitle>RFQs</SubTitle>
            <div style={{alignContent: 'center'}}>
            <table style={{margin: 'auto'}}><tr>
              <td>Response: <input type="checkbox" checked={this.state.showRFQResponse} onClick={()=>this.setState({showRFQResponse: !this.state.showRFQResponse})}></input></td>
              <td>No Response: <input type="checkbox" checked={this.state.showRFQNoResponse} onClick={()=>this.setState({showRFQNoResponse: !this.state.showRFQNoResponse})}></input></td>
            </tr></table>
            <table style={{margin: 'auto'}}><tr>
              <td>Open: <input type="checkbox" checked={this.state.showRFQOpen} onClick={()=>this.setState({showRFQOpen: !this.state.showRFQOpen})}></input></td>
              <td>Closed: <input type="checkbox" checked={this.state.showRFQClosed} onClick={()=>this.setState({showRFQClosed: !this.state.showRFQClosed})}></input></td>
              <td>Discontinued: <input type="checkbox" checked={this.state.showRFQDiscontinued} onClick={()=>this.setState({showRFQDiscontinued: !this.state.showRFQDiscontinued})}></input></td>
            </tr></table>
            <table style={{margin: 'auto'}}><tr>
              <td>Won: <input type="checkbox" checked={this.state.showRFQWon} onClick={()=>this.setState({showRFQWon: !this.state.showRFQWon})}></input></td>
              <td>Lost: <input type="checkbox" checked={this.state.showRFQLost} onClick={()=>this.setState({showRFQLost: !this.state.showRFQLost})}></input></td>
              <td>No Decision: <input type="checkbox" checked={this.state.showRFQNoDecision} onClick={()=>this.setState({showRFQNoDecision: !this.state.showRFQNoDecision})}></input></td>
            </tr></table>
            <table style={{margin: 'auto'}}><tr>
              <td>Shipping: <input type="checkbox" checked={this.state.showShipping} onClick={()=>this.setState({showShipping: !this.state.showShipping})}></input></td>
              <td>Delivery: <input type="checkbox" checked={this.state.showDelivery} onClick={()=>this.setState({showDelivery: !this.state.showDelivery})}></input></td>
            </tr></table>
            <table style={{margin: 'auto'}}><tr>
              <td>Full Match: <input type="checkbox" checked={this.state.showFullMatch} onClick={()=>this.setState({showFullMatch: !this.state.showFullMatch})}></input></td>
              <td>Probably: <input type="checkbox" checked={this.state.showProbably} onClick={()=>this.setState({showProbably: !this.state.showProbably})}></input></td>
              <td>Probably Not: <input type="checkbox" checked={this.state.showProbablyNot} onClick={()=>this.setState({showProbablyNot: !this.state.showProbablyNot})}></input></td>
              <td>No Match: <input type="checkbox" checked={this.state.showNoMatch} onClick={()=>this.setState({showNoMatch: !this.state.showNoMatch})}></input></td>
              <td>NR: <input type="checkbox" checked={this.state.showNoMatchResponse} onClick={()=>this.setState({showNoMatchResponse: !this.state.showNoMatchResponse})}></input></td>
            </tr></table>
            </div>
            <table style={{textAlign: 'center', width: '1200px', display: 'inline-block'}} className="dashboard-mini">
              <thead>
                <th style={{width: '8%'}}>Quote</th>
                <th style={{width: '8%'}}>Sent Date</th>
                <th style={{width: '8%'}} data-tip="How long it took the vendor to respond to the RFQ">Response Time<ReactTooltip /></th>
                <th style={{width: '12%'}}>Delivery Date</th>
                <th style={{width: '12%'}}>Pickup Date</th>
                <th style={{width: '15%'}}>Location</th>                
                <th style={{width: '8%'}}>Shipping / Delivery</th>                
                <th style={{width: '12%'}}>Skills</th>
                <th style={{width: '8%'}}>Quote $</th>
                <th style={{width: '9%'}}>Status</th>
              </thead>
              {this.state.RFQs.map((RFQs) => (
                <>
                {
                  ((RFQs.status === "Closed" && this.state.showRFQClosed) || 
                  (RFQs.status === "Open" && this.state.showRFQOpen) || 
                  (RFQs.status === "Discontinued" && this.state.showRFQDiscontinued)) 
                  && 
                  ((RFQs.dtDateSubmitted && this.state.showRFQResponse) || 
                  (!RFQs.dtDateSubmitted && this.state.showRFQNoResponse))
                  &&
                  ((RFQs.POs > 0 && this.state.showRFQWon) || 
                  (RFQs.POs === 0 && RFQs.status === "Closed" && this.state.showRFQLost) ||
                  (RFQs.POs === 0 && (RFQs.status === "Open" || RFQs.status === "Discontinued") && this.state.showRFQNoDecision))
                  && 
                  ((RFQs.deliveryType === 1 && this.state.showDelivery) || (RFQs.deliveryType === 2 && this.state.showShipping))
                  &&
                  ((!RFQs.sApples && this.state.showNoMatchResponse) || (RFQs.sApples === "Full Match" && this.state.showFullMatch) || 
                  (RFQs.sApples === "Probably" && this.state.showProbably) || (RFQs.sApples === "Probably Not" && this.state.showProbablyNot) ||
                  (RFQs.sApples === "No Match" && this.state.showNoMatch))
                  &&
                  (
                    <tr>
                      <td><Link target="_blank" to={`/quote/${RFQs.nQuote}`}>{RFQs.nQuote}</Link></td>
                      <td>{Moment(RFQs.dtSent).utc().format('MM-DD-YYYY hh:mm A')}</td>
                      <td>{formatMStoDays(Date.parse(RFQs.dtDateSubmitted)-Date.parse(RFQs.dtSent))}</td>
                      <td>{RFQs.dtDeliveryDate}</td>
                      <td>{RFQs.dtPickupDate}</td>
                      <td>{RFQs.sCity}, {RFQs.sState} {RFQs.sZip}</td>
                      <td>{RFQs.deliveryType === 1 ? "Delivery" : "Shipping"}</td>
                      <td>
                          {RFQs.skillsets.map((skill, index) => (
                            <>
                              {index > 0 ? <br /> : ""}{skill.title}
                            </>
                          ))}
                      </td>
                      <td>
                        {nQuoteTotalNoDollar(RFQs.nSubTotal + RFQs.nDelivery)}
                      </td>
                      <td>
                        {RFQs.status === "Closed" && RFQs.POs > 0 && (
                          <>Closed - Won</>
                        )}
                        {RFQs.status === "Closed" && RFQs.POs === 0 && (
                          <>Closed - Lost</>
                        )}
                        {RFQs.status !== "Closed" && (
                          <>{RFQs.status} - {RFQs.sAction} - {RFQs.sApples ? RFQs.sApples : "NR"}</>
                        )}
                      </td>
                      
                    </tr>
                  )
                }
                
                </>
              ))}
            </table>
            </div>
          )}

          {this.state.viewing === "Performance" && (
            <div style={{textAlign: "center"}} >
              <SubTitle>RFQs Sent</SubTitle>
            <table class="dashboard">
              <thead>
                <th></th>
                <th>Total</th>
                <th>Closed</th>
                <th>Open</th>
                <th>Discontinued</th>
              </thead>
              <tr>
                <td>Quotes</td>
                <td>{this.state.RFQsCount}</td>
                <td>{this.state.RFQsClosed}</td>
                <td>{this.state.RFQsOpen}</td>
                <td>{this.state.RFQsDisc}</td>
              </tr>
              <tr>
                <td>Responded</td>
                <td>{this.state.RFQsClosedResponded + this.state.RFQsOpenResponded + this.state.RFQsDiscResponded}</td>
                <td>{this.state.RFQsClosedResponded}</td>
                <td>{this.state.RFQsOpenResponded}</td>
                <td>{this.state.RFQsDiscResponded}</td>
              </tr>
              <tr>
                <td>Awarded</td>
                <td>{this.state.RFQsWon + this.state.RFQsOpenAwarded + this.state.RFQsDiscAwarded}</td>
                <td>{this.state.RFQsWon}</td>
                <td>{this.state.RFQsOpenAwarded}</td>
                <td>{this.state.RFQsDiscAwarded}</td>
              </tr>
            </table>
            <div>Average RFQ Response Time {Math.round(this.state.RFQsResponseTime*10)/10} hours</div>
            <div data-tip="% of all RFQs sent to this vendor that were responded to"><ReactTooltip />
              {Math.round((this.state.RFQsClosedResponded + this.state.RFQsOpenResponded + this.state.RFQsDiscResponded)/this.state.RFQsCount*100)}% RFQ Response Rate</div>
            <div data-tip="% of RFQs sent that were closed by Rentacomputer.com (any vendor)"><ReactTooltip />
              {Math.round(this.state.RFQsClosed/(this.state.RFQsClosed + this.state.RFQsDisc)*100)}% Quote Closure Rate</div>
            <div data-tip="% of RFQ Responses that were closed by Rentacomputer.com and have a PO for this vendor"><ReactTooltip />
              {Math.round(this.state.RFQsWon / (this.state.RFQsClosedResponded + this.state.RFQsDiscResponded) * 100)}% Win Rate</div>
            <div data-tip="# of POs for this vendor where no RFQ was sent"><ReactTooltip />
              {this.state.noQuotes} Won With No RFQ</div>
            <SubTitle>RFQ Response Ratings</SubTitle>
            <div>{this.state.RFQsRated} RFQs Rated by Reps</div>
            <div>{this.state.RFQsFullMatch} RFQs Full Match ({Math.round(this.state.RFQsFullMatch/this.state.RFQsRated*1000)/10}%)</div>
            <div>{this.state.RFQsProbably} RFQs Probable Match ({Math.round(this.state.RFQsProbably/this.state.RFQsRated*1000)/10}%)</div>
            <div>{this.state.RFQsProbablyNot} RFQs Probable No Match ({Math.round(this.state.RFQsProbablyNot/this.state.RFQsRated*1000)/10}%)</div>
            <div>{this.state.RFQsNoMatch} RFQs No Match ({Math.round(this.state.RFQsNoMatch/this.state.RFQsRated*1000)/10}%)</div>
              
            {this.state.skillsetData && (
              <>
              <SubTitle>Response Skillset Data</SubTitle>
              <table class='dashboard'>
                <thead>
                  <th>Skill</th>
                  <th>Quotes</th>
                  <th>Won</th>
                  <th>Quote $</th>
                  <th>Won $</th>
                </thead>
              
              {this.state.skillsetData && this.state.skillsetData.map((skill,index)=>(
                <tr>
                  <td>{skill.name}</td>
                  <td>{skill.countTotal}</td>
                  <td>{skill.countWon} ({Math.round(skill.countWon/skill.countTotal*100)}%)</td>
                  <td>{nQuoteTotalNoDollar(skill.salesTotal)}</td>
                  <td>{nQuoteTotalNoDollar(skill.salesWon)} ({Math.round(skill.salesWon/skill.salesTotal*100)}%)</td>
                </tr>
              ))}
                <tr>
                  <td>{this.state.skillsetData.blank.name}</td>
                  <td>{this.state.skillsetData.blank.countTotal}</td>
                  <td>{this.state.skillsetData.blank.countWon} ({Math.round(this.state.skillsetData.blank.countWon/this.state.skillsetData.blank.countTotal*100)}%)</td>
                  <td>{nQuoteTotalNoDollar(this.state.skillsetData.blank.salesTotal)}</td>
                  <td>{nQuoteTotalNoDollar(this.state.skillsetData.blank.salesWon)} ({Math.round(this.state.skillsetData.blank.salesWon/this.state.skillsetData.blank.salesTotal*100)}%)</td>
                </tr>
              </table>
              </>
            )}

              <SubTitle>Rental Review Data</SubTitle> 
              {this.state.performance && (
                <>
                <div>Total Rental Reviews: {this.state.performance.nReviews ? this.state.performance.nReviews : 0}</div>
              <div><br /></div>
              <table class="dashboard">
                <thead>
                  <th></th>
                  <th>Total Issues</th>
                  <th>Vendor Response Acceptable</th>
                  <th>Total Refunds</th>
                  <th>Major Impact or worse</th>
                  <th>Any Impact</th>
                </thead>
                <tr>
                  <td>All Issues</td>
                  <td>{this.state.performance.nIssues} ({Math.round(this.state.performance.nIssues/this.state.performance.nReviews*1000)/10}%)</td>
                  <td>{this.state.performance.nIssuesResponse} ({Math.round(this.state.performance.nIssuesResponse/this.state.performance.nIssues*1000)/10}%)</td>
                  <td>{this.state.performance.nIssuesRefunded} ({Math.round(this.state.performance.nIssuesRefunded/this.state.performance.nReviews*1000)/10}%)</td>
                  <td>{this.state.performance.nIssuesMajor} ({Math.round(this.state.performance.nIssuesMajor/this.state.performance.nReviews*1000)/10}%)</td>
                  <td>{this.state.performance.nIssues - this.state.performance.nIssuesNo} ({Math.round((this.state.performance.nIssues - this.state.performance.nIssuesNo)/this.state.performance.nReviews*1000)/10}%)</td>
                </tr>
                <tr>
                  <td>Logistics Issues</td>
                  <td>{this.state.performance.nLogistics} ({Math.round(this.state.performance.nLogistics/this.state.performance.nReviews*1000)/10}%)</td>
                  <td>{this.state.performance.nLogisticsResponse} ({Math.round(this.state.performance.nLogisticsResponse/this.state.performance.nLogistics*1000)/10}%)</td>
                  <td>{this.state.performance.nLogisticsRefunded} ({Math.round(this.state.performance.nLogisticsRefunded/this.state.performance.nReviews*1000)/10}%)</td>
                  <td>{this.state.performance.nLogisticsMajor} ({Math.round(this.state.performance.nLogisticsMajor/this.state.performance.nReviews*1000)/10}%)</td>
                  <td>{this.state.performance.nLogistics - this.state.performance.nLogisticsNo} ({Math.round((this.state.performance.nLogistics - this.state.performance.nLogisticsNo)/this.state.performance.nReviews*1000)/10}%)</td>
                </tr>
                <tr>
                  <td>Equipment Issues</td>
                  <td>{this.state.performance.nEquipment} ({Math.round(this.state.performance.nEquipment/this.state.performance.nReviews*1000)/10}%)</td>
                  <td>{this.state.performance.nEquipmentResponse} ({Math.round(this.state.performance.nEquipmentResponse/this.state.performance.nEquipment*1000)/10}%)</td>
                  <td>{this.state.performance.nEquipmentRefunded} ({Math.round(this.state.performance.nEquipmentRefunded/this.state.performance.nReviews*1000)/10}%)</td>
                  <td>{this.state.performance.nEquipmentMajor} ({Math.round(this.state.performance.nEquipmentMajor/this.state.performance.nReviews*1000)/10}%)</td>
                  <td>{this.state.performance.nEquipment - this.state.performance.nEquipmentNo} ({Math.round((this.state.performance.nEquipment - this.state.performance.nEquipmentNo)/this.state.performance.nReviews*1000)/10}%)</td>
                </tr>
              </table>
                </>
              )}
            
            </div>
          )}

          {this.state.viewing === "Account" && (
            <>
              <div>Username: <Input onChange={(event) => this.handleOnChange(event.target.value,"data.sUsername")} type="text" value={this.state.data.sUsername}></Input></div>
              <div>Password: <Input onChange={(event) => this.handleOnChange(event.target.value,"data.sPassword")} type="text" value={this.state.data.sPassword}></Input></div>
              <br />
              {this.state.dataChanged && (
                <Button onClick={() => this.changeData()}>Update Password</Button>
              )}
            </>
          )}
           </FormWrapper>
        </Wrapper>
      )}
      </>
      
    )
  }
}