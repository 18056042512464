import React, { Component } from "react";

export class Tutorial1Table extends Component{
    constructor(props) {
        super(props);
        this.state = {
        loaded: false,
        data: {},
        };
    }
    render() {
    return (
        <table>
            <thead>
                {Object.keys(this.props.quotes[0]).map((header, column) => (
                    <th key={column}>{header}</th>
                ))}
            </thead>
            {this.props.quotes.map((data, row) => (
                <tr key={row}>
                {Object.keys(data).map((property, column) => (
                    <td key={column}>{data[property]}</td>
                ))}
                </tr>
            ))}
        </table>
    )}
}