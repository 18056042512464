import React, { Component } from "react";
import Axios from "axios";
import styled from "styled-components";
import Loader from "react-loader-spinner";

const LoaderWrapper = styled.div``;

const PageWrapper = styled.div`
  display: block;
  position: fixed;
  z-index: 50;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: #222;
  opacity: 0.5;
`;

const Modal = styled.div`
  display: block;
  position: fixed;
  z-index: 55;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  height: fit-content;
  overflow: auto;
  background-color: #fff;
  opacity: 1;
  border-radius: 10px;

  @media (max-width: 900px) {
    width: 90%;
    height: auto;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: left;
  background-color: #fefefe;
  margin: auto;
  padding: 3em;
  width: 100%;
  overflow: hidden;

  h2 {
    text-align: center;
  }

  p {
    text-align: left;
    font-size: 1rem;
    margin-top: 1em;
  }

  span {
    font-weight: bold;
  }

  input {
    width: 100%;
    max-width: 400px;
    padding: 0.7em;
    margin: 0.5em auto 0 auto;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  text-align: center;
  margin: 3em 0 1em 0;
  overflow: hidden;

  button {
    width: 100%;
    max-width: 200px;
     text-decoration: none;
     color: #222;
     text-align: center;
     transition: all 0.2s;
    padding: 0.5em 1em;
    margin: 0 0.5em;
    border: 3px #e8edf1 solid;
    border-radius: 4px;
    font-weight: 500;
    align-self: center;
    background-color: #e8edf1;
    cursor: pointer;

    &:hover {
      background-color: #4aabed;
      color: #fff;
      border: 3px #4aabed solid;
    }
  }
`;

const InputContainer = styled.div`
  width: 80%;
  margin: auto;
`;

const Warning = styled.h2`
  font-weight: bold;
  color: red;
  text-align: center !important;
`;

export default class AddContactModal extends Component {
  state = {
    data: [],
    contactSameCustomerID: [],
    contactDifferentCustomerID: [],
    contactDontEmail: [],
    loaded: false,
    firstName: "",
    lastName: "",
    title: "",
    email: "",
    phone: "",
    showConfirmation: false,
    showWarning: false,
  };

  handleConfirmation = () => {
    Axios({
      method: "post",
      url: "/customers/add-new-contact",
      data: {
        nCompany: this.props.nCompany,
        sFirstName: this.state.firstName,
        sLastName: this.state.lastName,
        sPhone: this.state.phone,
        sEmail: this.state.email,
      },
    });
    this.props.handleShowAddContactModal();
  };

  handleCancel = () => {
    this.setState({
      showConfirmation: false,
    });
  };

  handleSubmit = () => {
    if (this.state.firstName.length === 0) {
      this.setState({
        showWarning: true,
      });
    } else {
      this.setState({
        showConfirmation: true,
      });
    }
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
    if (event.target.name === "firstName") {
      this.setState({
        showWarning: false,
      });
    }
  };

  render() {
    let warning = <Warning>First name is required!</Warning>;
    let message = (
      <ModalContent>
        <h2>Please confirm</h2>
        <p>
          Are you sure you want to add <span>{this.state.firstName}</span> as a
          contact?
        </p>
        <ButtonWrapper>
          <button onClick={this.handleConfirmation}>Add Contact</button>
          <button onClick={this.handleCancel}>Cancel</button>
        </ButtonWrapper>
      </ModalContent>
    );

    let modal = (
      <>
        <PageWrapper></PageWrapper>
        <Modal>
          {this.state.showConfirmation ? (
            <ModalContent>{message}</ModalContent>
          ) : (
            <ModalContent>
              {this.state.showWarning ? warning : <h2>Contact Info</h2>}
              <InputContainer>
                <p>First Name</p>
                <input
                  name="firstName"
                  onChange={this.handleChange}
                  type="text"
                  value={this.state.firstName}
                />
              </InputContainer>
              <InputContainer>
                <p>Last Name</p>
                <input
                  name="lastName"
                  onChange={this.handleChange}
                  type="text"
                  value={this.state.lastName}
                />
              </InputContainer>
              <InputContainer>
                <p>Email</p>
                <input
                  name="email"
                  onChange={this.handleChange}
                  type="email"
                  value={this.state.email}
                />
              </InputContainer>
              <InputContainer>
                <p>Phone</p>
                <input
                  name="phone"
                  onChange={this.handleChange}
                  type="number"
                  value={this.state.phone}
                />
              </InputContainer>
              <ButtonWrapper>
                <button onClick={this.handleSubmit}>Add Contact</button>
                <button onClick={this.props.handleShowAddContactModal}>
                  Close
                </button>
              </ButtonWrapper>
            </ModalContent>
          )}
        </Modal>
      </>
    );

    return <>{modal}</>;
  }
}
