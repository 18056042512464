import moment from 'moment'
import React, { Component } from 'react'
import { nQuoteTotalNoDollar } from '../../../../assets/functions/calculations'

export default class QBCustomerInfo extends Component {
  render() {
    let invoice = this.props.invoice
    return (
        <div>
        <div>Closed Quotes: {invoice.ClosedQuotes}</div>
        <div>Company Name: {invoice.sCompany}</div>
        <div>First Order: {moment(invoice.firstOrder).add(5, 'hours').format("MM-DD-YYYY")}{}</div>
        <div>Annual Sales: {nQuoteTotalNoDollar(invoice.Business3Years/Math.max(1,Math.min(3,moment().diff(moment(invoice.firstOrder),'days'))))}</div>
            
        <div>
        {invoice.needsQBCustomer && (
            <div style={{fontWeight:'bold'}}>This company record does not have a QB Name</div>
        )}
            <div>
                Set QB Name: 
                <input onChange={(event)=>this.props.handleUpdateInvoiceData(event.target.value, "sQBCustomer")} value={invoice.sQBCustomer} />
                <button onClick={()=>this.props.handleUpdateQBName()}>Update</button>
            </div>
        </div>
    
   
        <div>
        {invoice.needsTerms && (
            <div style={{fontWeight:'bold'}}>This company record has not been approved for Terms</div>
        )}
            <div>
                Terms: <select value={invoice.sPaymentTerms} onChange={(event)=>this.props.changeTerms(event)}>
                    <option value={"Credit Card"}>Credit Card</option>
                    <option value={"Cash in advance"}>Cash in advance</option>
                    <option value={"50% Down, 50% on completion"}>50% Down, 50% on completion</option>
                    <option value={"Net 30"}>Net 30</option>
                    <option value={"Net 60"}>Net 60</option>
                    <option value={"Net 90"}>Net 90</option>
                </select>
            </div>
            {invoice.sPaymentTerms && invoice.sPaymentTerms !== "Credit Card" && invoice.sPaymentTerms !== "Cash in advance" && (
            <div>
                Limit: <input onChange={(event)=>this.props.changeCreditLimit(event.target.value)} value={invoice.nCreditLimit} />
            </div>
            )}
            {invoice.sPaymentTerms && (
                <div>
                    <button onClick={()=>this.props.updateCustomerTerms()}>Update Terms</button>
                </div>
            )}
        </div>
    
    </div>
    )
  }
}
