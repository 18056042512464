module.exports = {
    tutorial_queries: {
        template: {
            query: "",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        testSetter: {
            query: "SELECT TOP 1 id FROM RAC_CUSTOMER_QUOTES",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        tutorial1: {
            query: "SELECT TOP [numQuotes] id, sName, sCompany, (SELECT sName FROM RAC_REPS WHERE RAC_REPS.id=RAC_CUSTOMER_QUOTES.nRep) as Rep, " +
            "sStatus, nQuoteTotal, dtDateStamp FROM RAC_CUSTOMER_QUOTES ORDER BY id desc",
            req: ["numQuotes"],
            fields: [
                {key: "id", label: "Quote Number"},
                {key: "sName", label: "Contact Name"},
                {key: "sCompany", label: "Company Name"},
                {key: "Rep", label: "Rep Name"},
                {key: "sStatus", label: "Quote Status"},
                {key: "nQuoteTotal", label: "Quote Total Price"},
                {key: "dtDateStamp", label: "Quote Created Date"},
            ],
            desc: "Lists the quotes with the highest quote numbers in the system.  Required param numQuotes determines how many quotes to show.",
            tables: ["RAC_CUSTOMER_QUOTES", "RAC_REPS"],
            type: "SELECT",
            whereUsed: [
                "/components/tutorials/Tutorial1",
                "/components/tutorials/Tutorial1Brian",
                "/components/tutorials/Tutorial1Hunter",
                "/components/tutorials/Tutorial1Larry",
            ],
        },
    }

} 