import React, { Component } from 'react';

import { Helmet } from 'react-helmet';
import { Howl } from 'howler';
import alertSound from '../../assets/audio/lead-alert.m4a';
import Switch from 'react-switch';
import TopNavbar from './TopNavbar';
import { Wrapper, BodyContainer, BottomControls } from '../styled/nav';
import DesktopLeftNav from './DesktopLeftNav';
import LeadsTile from './LeadsTile';
import FollowupsTile from './FollowupsTile';
import NotesTile from './NotesTile';
import { fetchLeadsAndNotes } from '../../assets/functions/user';

let sound = new Howl({
  src: [alertSound],
  volume: 0.8,
  preload: true,
  onplayerror: function () {
    sound.once('unlock', function () {
      sound.play();
    });
  },
});

class NavBars extends Component {
  state = {
    racLeads: [],
    camLeads: [],
    followUps: [],
    notes: [],
    loopSound: false,
    leadSound: false,
    loaded: false,
  };  

  // If loopSound state is true, play the sound
  loopSound = () => {
    if (this.state.loopSound && this.state.leadSound) {
      sound.play();
    }
  };

  async getLeadsAndNotes() {
    try {
      const data = await fetchLeadsAndNotes(this.props.user)

      if(data){
        this.setState({
          racLeads: data.racLeads,
          camLeads: data.camLeads,
          followups: data.followUps,
          notes: data.notes,
          loaded: true,
        })
    }
    } catch(error) {
      console.error(error);
    }
  }

  async componentDidMount() {
    this.getLeadsAndNotes()
    // Fetches new data every 5 seconds
    this.interval = setInterval(async () => this.getLeadsAndNotes(), 5000);
    
    this.interval = setInterval(() => this.loopSound(), 10000);

  }

  componentWillUnmount() {
    // Stop fetching data when the user leaves the page and component unmounts
    clearInterval(this.interval);
  }

  handleChangeLeadSound = () => {
    this.setState({ leadSound: !this.state.leadSound });
  };

  // Disables body scroll when mouse is hovered over sidebar
  disableBodyScroll = () => {
    document.body.style.position = 'fixed';
  };

  // Enables body scroll when mouse is not hovered over sidebar
  enableBodyScroll = () => {
    document.body.style.position = 'static';
  };

  render() {
    return (
      <>
        {this.state.loaded && this.props.location.substring(0,12) !== "#/send-email" && (
          <>
          
        {/* Helmet sets tab title */}

        <Helmet defer={false}>
          <title>{this.props.title}</title>
        </Helmet>

        {/* Start of TopNavBar */}

        <TopNavbar
          user={this.props.user}
          permissions={this.props.permissions}
        />

        {/* End of TopNavBar */}

        {/* Start of SideNavBar */}
        <Wrapper
          onMouseEnter={this.disableBodyScroll}
          onMouseLeave={this.enableBodyScroll}
          className="sideNavBar"
          id="sideNavBar"
        >
          <BodyContainer>
            <h1>CFR Command Center</h1>
            <DesktopLeftNav 
              permissions={this.props.permissions} 
            />

            <LeadsTile 
              permissions={this.props.permissions} 
              racLeads={this.state.racLeads} 
              camLeads={this.state.camLeads} 
              leadSound={this.state.leadSound} 
            />
            <FollowupsTile followups={this.state.followups} />
            <NotesTile notes={this.state.notes} />
            
            
          </BodyContainer>
          <BottomControls>
            <p style={{ marginRight: '10px', fontSize: '0.95rem' }}>
              Lead Sound:
            </p>
            <Switch
              height={22}
              checked={this.state.leadSound}
              onChange={this.handleChangeLeadSound}
              onColor="#4AABED"
              checkedIcon={false}
              uncheckedIcon={false}
            />
          </BottomControls>
        </Wrapper>

        {/* End of SideNavBar */}
        </>
        )}
      </>
      
    );
  }
}

export default NavBars;
