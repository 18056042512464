import React, { Component } from 'react';
import {
  BrowserRouter as Redirect,
  Switch,
  Route,
  Link,
} from 'react-router-dom';
import styled from 'styled-components';
import Axios from 'axios';
import { whichRep } from '../../assets/functions/calculations';

const Button = styled.button`
  display: inline-block;
   padding: 0.8em 2.5em;
   margin: 0.3em 0.3em 0.3em 0;
   text-decoration: none;
   color: #fafafa;
   text-align: center;
   transition: all 0.2s;
  background-color: rgba(54, 162, 235, 0.9);
  border: 1px solid #fafafa;
  cursor: pointer;
  border-radius: 50px;

  &:hover {
    border: 1px solid #d7d7d7;
  }
`;

const NoResult = styled.p`
  color: #222;
  font-size: 14px;
  margin: 0.2em 0;
  padding 0 5em;
  text-align:center;

  &:hover {
    color: rgba(54, 162, 235, 0.9);
  }
`;

class SearchCustomer extends Component {
  state = {
    searchCustomer: '',
    results: [],
    loaded: false,
  };

  // Async Axios
  fetchData = async () => {
    const response = await Axios.get('/search-customer', {
      params: {
        searchCustomer: this.state.searchCustomer,
        amount: '10',
      },
      headers: { 'Content-Type': 'application/json' },
    });
    this.setState({
      results: response.data.result[0].recordset,
    });
    // If the result is not empty, then it sets loaded to true, showing the drop down menu
    if (this.state.results.length > 0) {
      this.setState({
        loaded: true,
      });
    } else {
      this.setState({
        loaded: false,
      });
    }
  };
  catch(error) {
    console.error(error);
  }

  // When the value of the input changes it sets it to the search state, and then does the fetchData() function for axios
  handleOnChange = (event) => {
    // Clears running timer and starts a new one each time the user types
    clearTimeout(this.timer);
    this.setState({ searchCustomer: event.target.value });
    this.timer = setTimeout(() => {
      this.fetchData();
    }, 350);
  };

  // When enter is clicked, search state is set to empty string, and loaded to false, hiding the drop down menu
  handleClick = () => {
    this.setState({ loaded: false, searchCustomer: '' });
  };

  // When enter is clicked nothing will happen, prevent page from refreshing
  handleClickFalse = (e) => {
    e.preventDefault();
  };

  // When input is unfocused it sets search state to an empty string, and loaded to false, hiding the drop down menu
  handleBlur = () => {
    // 150ms delay so user can still click on links before it changes state
    setTimeout(() => this.setState({ loaded: false, searchCustomer: '' }), 400);
  };

  render() {
    return (
      <div className="customer-search-container" style={{display: 'inline-block', width:'80%'}}>
        <form className="customer-search-label" action="">
          <input
            type="text"
            id="customer-search-input"
            value={this.state.searchCustomer}
            placeholder="Type to search"
            onChange={this.handleOnChange}
            autoComplete="off"
            onBlur={this.handleBlur}
          />

        </form>
        {this.state.searchCustomer.length > 0 && (
          <div className="customer-search-results">
            {this.state.results.length > 0 && (
              <>
                {this.state.results.map((sql) => (
                  <Button onClick={() => this.props.callback(sql)}>
                    {sql.id} - {sql.sName} ({sql.nQuotes} - {whichRep(sql.nDefaultRep)})
                  </Button>
                ))}
              </>
            )}
            {this.state.results.length <= 0 && (
              <NoResult>
                <p>No Search Results</p>
              </NoResult>
            )}
            <Button onClick={()=>(this.props.createNewCustomer(this.state.searchCustomer))}>Create New Customer</Button>
          </div>
        )}
      </div>
    );
  }
}

export default SearchCustomer;
