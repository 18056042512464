import React, { Component } from 'react';
import { Link, BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from '../pages/Home';
import styled from 'styled-components';
import RacLogisticsHomev2 from '../pages/home/RacLogisticsHomev2';

const Wrapper = styled.div`
  text-align: center;
  padding: 20px;
`;

const Button = styled.button`
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  margin: 10px;
`;

class HomeSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewing: "Usual Home Page"
    };
  }

  render() {
    return (
      <Wrapper>
        <h1>Welcome to the Home Page Selector</h1>
        <p>Please choose your destination:</p>
        {console.log(this.props)}
        <button onClick={() => this.setState({ viewing: "Usual Home Page" })} style={{ fontWeight: this.state.viewing === "Usual Home Page" ? "bold" : "" }}>Usual Home Page</button>
        <button onClick={() => this.setState({ viewing: "RAC Logistics" })} style={{ fontWeight: this.state.viewing === "RAC Logistics" ? "bold" : "" }}>RAC Logistics</button>
        {this.state.viewing === "Usual Home Page" && (<Home user={this.props.user} updateTitle={this.props.updateTitle} permissions={this.props.permissions} />)}
        {this.state.viewing === "RAC Logistics" && (<RacLogisticsHomev2 user={this.props.user} updateTitle={this.props.updateTitle} permissions={this.props.permissions} />)}
      </Wrapper>
    );
  }
}

export default HomeSelector;