module.exports = {
    supplier_queries: {
        template: {
            query: "",
            req: [],
            fields: [
                {key: "", label: ""},
            ],
            desc: "",
            tables: [],
            type: "",
            whereUsed: [],
        },
        getZipCodes: {
            query: "SELECT rz.sZipCode, rz.sLatitude, rz.sLongitude, rz.sCity, rz.sState FROM [RESELLERS].[dbo].[RESELLERS_ZipCodes] rz",
            req: [],
            fields: [
                {key: "sZipCode", label: "Zip Code"},
                {key: "sLatitude", label: "Latitude"},
                {key: "sLongitude", label: "Longitude"},
                {key: "sCity", label: "City"},
                {key: "sState", label: "State"},
            ],
            desc: "Returns geolocation information for all the zip codes",
            tables: ["[RESELLERS].[dbo].[RESELLERS_ZipCodes]"],
            type: "SELECT",
            whereUsed: ["TASearch.js"],
        },
        getSkillsets: {
            query: "SELECT rs.id, rs.sTitle, rs.bActive FROM [RESELLERS].[dbo].[RESELLERS_Skills] rs ORDER BY id asc",
            req: [],
            fields: [
                {key: "id", label: "Skillset ID"},
                {key: "sTitle", label: "Skillset Name"},
                {key: "bActive", label: "Active?"},
            ],
            desc: "Returns all skillsets",
            tables: ["[RESELLERS].[dbo].[RESELLERS_Skills]"],
            type: "SELECT",
            whereUsed: ["TASearch.js"],
        },
    }

} 