import React, { Component } from 'react';
import Axios from 'axios';
import styled from 'styled-components';
import Moment from 'moment';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin: 1em auto;
  width: 100%;
  height: fit-content;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.1);
  text-align: center;
  border-radius: 15px;
  position: relative;

  table thead th {
    background-color: '#EFEFEF';
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4aabed;
  color: #fff;
  height: 35px;
  font-weight: bold;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 2em auto;
  width: 90%;

  div {
    width: 25%;
    margin: 0 5em;
  }

  p {
    text-align: center;
    margin: 0.4em 0;
  }

  span {
    font-weight: bold;
  }
`;

const ExpandButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #e8edf1;
  cursor: pointer;
  transition: 0.3s;
  height: 35px;
  font-weight: 500;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;

  &:hover {
    background-color: #5eb4ef;
    color: #fff;
  }
`;

const IconWrapper = styled.span`
  display: flex;
  flex-direction: column;

  i {
    font-size: 1.3rem;
    color: #efefef;
    margin: 0.5em 0;
    cursor: pointer;
  }

  i:first-child {
    position: absolute;
    left: 60px;
    top: 45px;
    color: ${(props) => (props.dtSent ? '#A7D961' : '#E8EDF1')};
  }

  i:nth-child(2) {
    position: absolute;
    left: 60px;
    top: 85px;
    color: ${(props) => (props.dtConfirmed ? '#A7D961' : '#E8EDF1')};
  }

  input {
    position: absolute;
    left: 20px;
    top: 75px;
    height: 20px;
    width: 20px;
  }
`;

const NewLineButton = styled.td`
  text-align: center;
  background-color: #eaf5fd;
  cursor: pointer;
  transition: 0.3s;
  font-weight: bold;

  &:hover {
    background-color: #5eb4ef;
    color: #fff;
  }
`;

const Form = styled.form`
  margin: 1em;
  width: 50%;

  div {
    width: 98%;
    margin: auto;
  }

  input {
    height: 40px;
    width: 100%;
    padding: 0 0.7em;
    border: 1px solid #fff;
    border-radius: 5px;
    transition: box-shadow 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    background-color: #f2f5f7;
  }

  textarea {
    height: 100px;
    width: 100%;
    padding: 0 0.7em;
    border: 1px solid #fff;
    border-radius: 5px;
    transition: box-shadow 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    background-color: #f2f5f7;
    resize: none;
  }

  p {
    text-align: left;
    margin: 0.4em 0 0.4em 0;
    font-weight: 500;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  button {
    cursor: pointer;
    width: 50%;
    margin: 0.7em 0.2em;
    height: 50px;
    border: none;
    transition: all 0.2s;
    border-radius: 4px;
    background-color: #e8edf1;

    &:hover {
      background-color: #4aabed;
      color: #fff;
    }
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: space-evenly;
`;

export default class PoLines extends Component {
  state = { data: [], expanded: false, sent: false, confirmed: false, laborConfirm: -1, csnConfirm: false };

  // Async Axios
  fetchData = async () => {
    const response = await Axios.get('/quote/po-line', {
      params: {
        id: this.props.id,
      },
      headers: { 'Content-Type': 'application/json' },
    });
    // Sets the state using the response data
    this.setState({
      data: response.data.result[0].recordset,
      loaded: true,
    });
    console.log(this.state);
  };
  catch(error) {
    console.error(error);
  }

  componentDidMount() {
    this.fetchData();
  }

  handleClick = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  setLabor = async (sql) => {
    if(this.state.laborConfirm !== sql.id){
      this.setState({laborConfirm: sql.id})
    } else {
      if(sql.nItem === 832){
        try {
          const response = await Axios.post(
            "/quote/remove-PO-labor",
            {
              id: sql.id,
            },
          );
          if(response){this.setState({laborConfirm: -1}, () => this.fetchData())}
        } catch (error) {
          console.error(error)
        }
        
      } else {
        try {
          const response = await Axios.post(
            "/quote/update-PO-labor",
            {
              id: sql.id,
            },
          );
          if(response){this.setState({laborConfirm: -1}, () => this.fetchData())}
        } catch (error) {
          console.error(error)
        }
      }
    }
  }

  setCSN = async () => {
    if(!this.state.csnConfirm) {
      this.setState({csnConfirm: true})
    } else {
      if(this.props.nTechArmyVendor == 5278){
        try {
          const response = await Axios.post(
            "/quote/remove-CSN",
            {
              id: this.props.id,
            },
          );
          if(response){this.setState({csnConfirm: false}, () => this.fetchData())}
        } catch (error) {
          console.error(error)
        }
      } else {
        try {
          const response = await Axios.post(
            "/quote/set-CSN",
            {
              id: this.props.id,
            },
          );
          if(response){this.setState({csnConfirm: false}, () => this.fetchData())}
        } catch (error) {
          console.error(error)
        }
      }
    }
  }

  render() {
    let sName;
    let sAddress;
    let nSubTotal = 0;
    let sent;
    let confirmed;

    if (this.props.sName === null) {
      sName = 'No name';
    } else {
      sName = this.props.sName;
    }

    if (this.props.sAddress === null) {
      sAddress = 'No address';
    } else {
      sAddress = this.props.sAddress;
    }

    // Loops through state data and calculates the subtotal
    for (let i = 0; i < this.state.data.length; i++) {
      let nItemTotal = this.state.data[i].nPrice * this.state.data[i].nQuantity;
      nSubTotal += nItemTotal;
    }

    // Calculates Total amount of Tax
    let nTax = (nSubTotal + this.props.nDelivery) * (this.props.nTaxRate / 100);

    // If dtSent has a date then change to a solid envelope icon
    if (this.props.dtSent != null) {
      sent = <i class="fas fa-envelope"></i>;
    } else {
      sent = <i class="far fa-envelope"></i>;
    }

    // If dtSent has a date then change to a solid check mark icon
    if (this.props.dtConfirmed != null) {
      confirmed = <i class="fas fa-check-circle"></i>;
    } else {
      confirmed = <i class="far fa-check-circle"></i>;
    }

    console.log(this.props)

    return (
      <Wrapper>
        <Title>
          {this.props.sDisplayId} - ${this.props.nPOTotal}
        </Title>
        <BodyWrapper>
          <IconWrapper
            dtSent={this.props.dtSent}
            dtConfirmed={this.props.dtConfirmed}
          >
            {sent}
            {confirmed}
            <input type="checkbox" id="" name="" value="" />
          </IconWrapper>
          <div>
            <p>
              <span>Date</span>
            </p>
            <p>{Moment(this.props.dtQuoteDate).utc().format('llll')}</p>
          </div>
          <div style={{backgroundColor: (this.state.csnConfirm ? this.props.nTechArmyVendor === 5278 ? "#b00" : "#0b0" : "")}}>
            <p>
              <span>Vendor</span>
            </p>
            <p>{this.props.sCompany} ({this.props.nTechArmyVendor})</p>
            <p>
            {(!this.props.nTechArmyVendor || this.props.nTechArmyVendor <= 0) && (
              <div onClick={() => this.setCSN()}>Mark CSN</div>
            )}
            {this.props.nTechArmyVendor == 5278 && (
              <div onClick={() => this.setCSN()}>Remove CSN</div>
            )}
            </p>
          </div>
          <div>
            <p>
              <span>Contact</span>
            </p>
            <p>{sName}</p>
          </div>
          <div>
            <p>
              <span>Address</span>
            </p>
            <p>{sAddress}</p>
          </div>
        </BodyWrapper>
        {this.state.expanded ? (
          <>
            <FormWrapper>
              <Form autoComplete="off">
                <div>
                  <div>
                    <p>Company</p>
                    <input type="text" value={this.props.sCompany}></input>
                    
                  </div>
                  <div>
                    <p>Name</p>
                    <input type="text" value={this.props.sName}></input>
                  </div>
                  <div>
                    <p>Address</p>
                    <input type="text" value={this.props.sAddress}></input>
                    <input type="text" value={this.props.sAddress2}></input>
                  </div>
                  <div>
                    <p>City</p>
                    <input type="text" value={this.props.sCity}></input>
                  </div>
                  <div>
                    <p>State</p>
                    <input type="text" value={this.props.sState}></input>
                  </div>
                  <div>
                    <p>Zip</p>
                    <input type="number" value={this.props.sZip}></input>
                  </div>
                  <div>
                    <p>Phone</p>
                    <input type="text" value={this.props.sPhone}></input>
                  </div>
                  <div>
                    <p>Fax</p>
                    <input type="text" value={this.props.sFax}></input>
                  </div>
                  <div>
                    <p>Email</p>
                    <input type="text" value={this.props.sEmail}></input>
                  </div>
                  <div>
                    <p>Estimate #</p>
                    <input type="text" value={this.props.sEstimate}></input>
                  </div>
                </div>
              </Form>
              <Form autoComplete="off">
                <div>
                  <div>
                    <p>Company</p>
                    <input type="text" value={this.props.sDCompany}></input>
                  </div>
                  <div>
                    <p>Name</p>
                    <input type="text" value={this.props.sDName}></input>
                  </div>
                  <div>
                    <p>Address</p>
                    <input type="text" value={this.props.sDAddress}></input>
                    <input type="text" value={this.props.sDAddress2}></input>
                  </div>
                  <div>
                    <p>City</p>
                    <input type="text" value={this.props.sDCity}></input>
                  </div>
                  <div>
                    <p>State</p>
                    <input type="text" value={this.props.sDState}></input>
                  </div>
                  <div>
                    <p>Zip</p>
                    <input type="text" value={this.props.sDZip}></input>
                  </div>
                  <div>
                    <p>Phone</p>
                    <input type="text" value={this.props.sDPhone}></input>
                  </div>
                  <div>
                    <p>Alt Name/Phone</p>
                    <input type="text" value={this.props.sAltNamePhone}></input>
                  </div>
                  <div>
                    <p>Notes</p>
                    <textarea type="text" value={this.props.sDNotes}></textarea>
                  </div>
                  <ButtonWrapper>
                    <button>Copy Info from Quote</button>
                    <button>Fill CFR Info</button>
                  </ButtonWrapper>
                </div>
              </Form>
            </FormWrapper>
            <table
              style={{
                width: '100%',
                boxShadow: 'none',
                borderRadius: '0px',
              }}
              className="dashboard"
            >
              <thead>
                <tr>
                  <th style={{ width: '5%' }}>Quantity</th>
                  <th style={{ width: '65%' }}>Equipment Description</th>
                  <th style={{ width: '5%' }}>Unit Price</th>
                  <th style={{ width: '5%' }}>Total</th>
                  <th style={{ width: '5%' }}>Labor</th>
                </tr>
              </thead>
              <tbody>
                {this.state.data.map((sql) => (
                  <tr key={sql.id}>
                    <td contentEditable="true" style={{ textAlign: 'center' }}>
                      {sql.nQuantity}
                    </td>
                    <td
                      contentEditable="true"
                      style={{ whiteSpace: 'pre-wrap' }}
                    >
                      {sql.sDescription}
                    </td>
                    <td contentEditable="true" style={{ textAlign: 'center' }}>
                      $
                      {sql.nPrice.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </td>
                    <td contentEditable="true" style={{ textAlign: 'center' }}>
                      $
                      {(sql.nPrice * sql.nQuantity).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </td>
                    <td style={{ textAlign: 'center' }} style={{backgroundColor: (this.state.laborConfirm === sql.id ? sql.nItem === 832 ? "#b00" : "#0b0" : "")}} onClick={() => this.setLabor(sql)}>
                      {sql.nItem === 832 ? this.state.laborConfirm === sql.id ? "Remove Labor?" : "CSN Labor" : this.state.laborConfirm === sql.id ? "Mark as Labor?" : "Mark as Labor"}
                    </td>
                  </tr>
                ))}
                <tr>
                  <NewLineButton colSpan="5">New Line</NewLineButton>
                </tr>
                <tr>
                  <td
                    colSpan="3"
                    style={{ textAlign: 'right', fontWeight: 'bold' }}
                  >
                    Sub Total:
                  </td>
                  <td colSpan="1" style={{ textAlign: 'center' }}>
                    $
                    {nSubTotal.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="3"
                    style={{ textAlign: 'right', fontWeight: 'bold' }}
                  >
                    Delivery & Pickup / Shipping:
                  </td>
                  <td colSpan="1" style={{ textAlign: 'center' }}>
                    $
                    {this.props.nDelivery.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </td>
                </tr>
                <tr>
                  <td colSpan="3" style={{ textAlign: 'right' }}>
                    <input type="checkbox" id="" name="" value="" /> Tax
                    Delivery & Pickup?{' '}
                    <span style={{ textAlign: 'right', fontWeight: 'bold' }}>
                      Tax ( {this.props.nTaxRate} %):
                    </span>
                  </td>
                  <td colSpan="1" style={{ textAlign: 'center' }}>
                    $
                    {nTax.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="3"
                    style={{ textAlign: 'right', fontWeight: 'bold' }}
                  >
                    Total:
                  </td>
                  <td colSpan="1" style={{ textAlign: 'center' }}>
                    $
                    {(nSubTotal + this.props.nDelivery + nTax).toLocaleString(
                      undefined,
                      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        ) : null}
        <ExpandButton onClick={this.handleClick}>
          {this.state.expanded ? <p>Close</p> : <p>Expand</p>}
        </ExpandButton>
      </Wrapper>
    );
  }
}
