import React, { Component } from 'react';
import { getSQLData } from '../../assets/functions/fetch';
import { Wrapper } from '../styled/nav';
import { Tutorial1Table } from './functions/tutorial1';
import styled from 'styled-components';

const ExampleComponent = styled.div`
  font-weight: bold;
  border: solid 1px black;
`

class Tutorial1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      data: {},
    };
  }

  componentDidMount = async () => {
   
    try {

      const fetch = await getSQLData("tutorial/tutorial1", {numQuotes: 10})
      
      if(fetch){

        let data = fetch.data.data[0].recordset
        //let query = getQueryInfo("tutorial/tutorial1")
        
        this.setState({
          data: data,
          //query: query,
        }, () => this.processData())
      }
      
      
    } catch (error) {
      console.error(error);
    }
    
  }

  processData() {
    let quotes = this.state.data
    let reps = {}


    this.setState({
      quotes: quotes,
      reps: reps,
      data: {},
      loaded: true,
    })

  }

  render() {
    return (
      <>
      {this.state.loaded && (
      <Wrapper>
        <div>In this tutorial we will:</div>
        <ol>
          <li>Gather data from an SQL query that has already been written for us.</li>
          <li>Place that data into state.</li>
          <li>Process the data.</li>
          <li>Display the data using map.</li>
          <li>Create styled components to make the display look better.</li>
        </ol>
        <div>
          <h2>Gather data from SQL</h2>
          <div>
            Most components we create are going to require some data from our SQL database.  
            As a Front End Developer, all SQL queries will be provided for you prior to tasks being assigned.  
            A function has been written for you called getSQLData.  
            getSQLData requires 1 parameter: the name of the query you want to call.  
            It also has an optional second parameter for you to pass parameters to the SQL query.
          </div>
          <div><br /></div>
          <div>
            The SQL query for this tutorial is called "tutorial1".
            This query is going to give you a list of the most recent quotes created in our system.
            It will provide the following fields:
            <ul>
              <li>id (quote number)</li>
              <li>sName (contact name)</li>
              <li>sCompany (company name)</li>
              <li>Rep (rep name)</li>
              <li>sStatus (quote status)</li>
              <li>nQuoteTotal (quote total $ amount)</li>
              <li>dtDateStamp (time and date the quote was created)</li>
            </ul>
          </div>
          <div><br /></div>
          <div>
            The SQL query has 1 required parameter: numQuotes (how many quotes you want to display).
            For the first exercise: Find where getSQLData is called in your Tutorial1 file, and change the number of quotes.
          </div>
          <div><br /></div>
        </div>
        <div>
          <h2>Place the data into state.</h2>
          <div>
            State is a very important concept in React.
            If you are not comfortable with the state concept, take some time after this tutorial to do some independent research.
            For this tutorial, you need to understand a few basic concepts.
            <ul>
              <li>this.state is an object that we can put data into for access later</li>
              <li>Only the file you are currently working in has access to this.state</li>
              <li>
                It is highly, highly recommended that only modify state directly in the constructor.  
                React does not strictly forbid this practice, but I will never accept any code that violates this principle.
              </li>
              <li>
                Instead of modifying state directly, use this.setState().
              </li>
              <li>
                this.setState() does not happen instantaneously.
              </li>
            </ul>
          </div>
          <div><br /></div>
          <div>
            When we receive data from SQL, we will place that data into state using this.setState.
            Often, we will need to process that data before displaying it.
            For the second exercise: find all of the instances if this.setState in this tutorial.  
            Try to directly set state (the wrong way) by replacing this.setState with this.state=.  
            Open up the console and take a look at the errors.  
            Fix your code so you use this.setState and verify the errors went away.
          </div>
          <div>
            <h2>Process the data and display it using map.</h2>
            Raw data from SQL is often not very useful the end user.
            We will typically need to do some data processing prior to display the data.
            Here is the data displayed in a table:
          </div>
          <Tutorial1Table quotes={this.state.quotes} / >
        </div>
        <div><br /></div>
        <div>
          That's kind of ugly, especially the Quote Total and Date fields!  
          Also, the end user might want to know some summary data, for example, how many quotes each rep has.  
          I have created a function called processData to do this.  
          At this point it is important to understand some best practices:
          <ul>
            <li>We want to run processData only one time, immediately after setState has been updated with raw SQL data.</li>
            <li>
              We need to consider optimizing memory vs processing.  
              Sometimes we may be dealing with thousands or even millions of records.  
              Your browser will literally run out of RAM.
            </li>
            <li>
              For a simple calculation that will create a new column of data, we do not want to store the results in state.  
              Example: formatting nQuoteTotal like dollars and cents $1,234.00
              Instead, we want to perform this calculation in the render() method.
            </li>
            <li>
              For calculations where the result is summarized in one or a small number of data points, we want to perform this calculation in the initial data processing.
              Example: counting the number of quotes each Rep has.
              This is an example of a calculation we want to perform in the processData function.
            </li>
          </ul>
        </div>
        <div><br /></div>
        <div>
          In order to display the data, we will use the array.map function:
          array.map((dataName, indexName) ={">"} (html)))
          <ul>
            <li>
              array.map will repeat the code you put in "html" one time for each object in an array.
              html must contain exactly one HTML element.  
              That HTML element can include other elements as well as Javascript.  
              However, there must be exactly one HTML element wrapping everything.
            </li>
            <li>Inside the html code, each row of data will be referred by the name you put in dataName.</li>
            <li>The number of the row you are on will be referred to by indexName.</li>
          </ul>
          <table>
            <thead>
              <th>id</th>
              <th>Name</th>
              <th>Company</th>
              <th>Rep</th>
              <th>Status</th>
              <th>Quote Total</th>
              <th>Date</th>
            </thead>
            {this.state.quotes.map((data, row) => (
              <tr key={row}>
                <td>{data.id}</td>
                <td>{data.sName}</td>
                <td>{data.sCompany}</td>
                <td>{data.Rep}</td>
                <td>{data.sStatus}</td>
                <td>{data.nQuoteTotal}</td>
                <td>{data.dtDateStamp}</td>
              </tr>
            ))}
          </table>
        </div>
        <div><br /></div>
        <div>
          For the third exercise, modify the last 2 columns to format Quote Total like $1,234.56 using the function nQuoteTotalFormatter and Date to MM-YY-DDDD using dateFormat.
          Then, modify processData to count the number of quotes per rep, and create a table to display that data.
        </div>
        <div>
          <h2>Create some styled component</h2>
          I need your help!
          I am terrible at UI design.
          Look how ugly this tutorial is.
          It's your job to fix it.
          Let's use a package called styled.
          We can use this to create our own HTML-like components with css styling.
          <ExampleComponent>Here's a component called ExampleComponent that puts a border around a div and makes the text inside bold.</ExampleComponent>
          Take a look at the top of the file where const ExampleComponent.
          For the last exercise, fix this tutorial by replacing the HTML components with your own styled components so that it's not so ugly.
        </div>
      </Wrapper>
      )}
      </>
    );
  }
};

export default Tutorial1;
