import React, { Component } from 'react';
import { getSQLData } from '../../../assets/functions/fetch';

class RepActivityReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loaded: false,
          repDataReady: false,
          selected: -1,
        
        };
    
      }
      fetchData = async () => {

        try {

            const fetch = await getSQLData("admin/repActivityReport", {})
            if(fetch){
                let data = fetch.data.data[0].recordset    
                
        
                this.setState({
                    data: data,
                }, () => this.processData())
            }
        }
        catch {

        }
      }
      componentDidMount(){
        this.fetchData()
      }

      processData(){
        let data = this.state.data
        for(let i=0; i<data.length; i++){
            data[i].TotalActivity = data[i].FollowupsCompleted + data[i].Notes
            data[i].NotesRatio = data[i].Notes/data[i].FollowupsCompleted
            data[i].FollowupsRatio = (data[i].FollowupsCompleted + data[i].FollowupsDue + data[i].FollowupsPastDue)/data[i].OpenQuotes
        }
        
        data.sort((a,b)=>a.TotalActivity>b.TotalActivity ? -1 : a.TotalActivity<b.TotalActivity ? 1 : 0)
        this.setState({
            data: data,
            loaded: true,
        })
      }
      handleSelectRep(id){
        this.setState({
            selected: id,
            repDataReady: false,
        }, () => this.getRepData())
      }
      getRepData = async() => {
        try {
            const fetchFollowups = await getSQLData("admin/followUpsByRep", {nRep: this.state.selected})
            const fetchNotes = await getSQLData("admin/notesByRep", {nRep: this.state.selected})
            const fetchOpenQuotes = await getSQLData("admin/openQuotesByRep", {nRep: this.state.selected})
            if(fetchFollowups && fetchNotes && fetchOpenQuotes){
                let followups = fetchFollowups.data.data[0].recordset
                let notes = fetchNotes.data.data[0].recordset
                let openQuotes = fetchOpenQuotes.data.data[0].recordset

                this.setState({
                    followups: followups,
                    notes: notes,
                    openQuotes: openQuotes
                }, () => this.processRepData())
            }
        } catch {
            
        }

      }
      processRepData(){
        let followups = this.state.followups
        let notes = this.state.notes
        let openQuotes = this.state.openQuotes
        console.log(notes)

        for(let i=0; i<openQuotes.length; i++){
            openQuotes[i].followups = []
            openQuotes[i].notes = []
            openQuotes[i].mostRecentFollowUp = {}
            openQuotes[i].nextFollowUp = {}
            openQuotes[i].completedFollowUps = 0
            openQuotes[i].repNotes = 0
            openQuotes[i].lastNote = {}

            for(let j=0; j<followups.length; j++){
                if(followups[j].Quote === openQuotes[i].id){
                    openQuotes[i].followups.push(followups[j])
                }
            }

            for(let j=0; j<notes.length; j++){
                if(notes[j].nQuote === openQuotes[i].id){
                    openQuotes[i].notes.push(notes[j])
                }
            }

            for(let j=0; j<openQuotes[i].followups.length; j++){
                if(openQuotes[i].followups[j].Completed){
                    if(j===0){
                        openQuotes[i].mostRecentFollowUp = {...openQuotes[i].followups[0]}
                    }
                    openQuotes[i].completedFollowUps++
                    
                } else {
                    if(!openQuotes[i].nextFollowUp.FollowUp){
                        openQuotes[i].nextFollowUp = {...openQuotes[i].followups[j]}
                    }
                }
                
            }

            openQuotes[i].repNotes = openQuotes[i].notes.length
            openQuotes[i].lastNote = {...openQuotes[i].notes[0]}
        }

        console.log(openQuotes)
        this.setState({
            openQuotes: openQuotes,
            repDataReady: true,
        })
      }
    render() {
        let data = this.state.data
        let loaded = this.state.loaded
        return (
            <>
            {loaded && (

            
            <div>
                <table>
                    <thead>
                        <tr>
                            {Object.keys(data[0]).map((column,index)=>(
                                <th>{column}</th>
                            ))}
                        </tr>
                    </thead>
                    {data.map((rep,index)=>(
                        <tr onClick={()=>this.handleSelectRep(rep.id)}>

                            {Object.keys(rep).map((column,index)=>(
                                <td>{rep[column]}</td>
                            ))}

                        </tr>
                    ))}
                </table>
            </div>
            )}
            </>
        );
    }
}

export default RepActivityReport;