import React from 'react';

function PercentageLegend() {
  return (
    <div class="legend">
      <div class="legend-scale">
        <ul class="legend-labels">
          <li>
            <span style={{ background: '#ff6666' }}></span>0%- 29%
          </li>
          <li>
            <span style={{ background: '#fafafa' }}></span>30% - 39%
          </li>
          <li>
            <span style={{ background: '#85bb65' }}></span>40% - 55%
          </li>
          <li>
            <span style={{ background: '#fffd6e' }}></span>56% - 100%
          </li>
        </ul>
      </div>
    </div>
  );
}

export default PercentageLegend;
