import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Loader from 'react-loader-spinner';
import styled from 'styled-components';
import Moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { nQuoteTotalFormatter, whichRep } from '../../../assets/functions/calculations';

const Select = styled.select`
  width: 100%;
  height: 60px;
  white-space:pre-wrap;
  margin: 0.5em auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.4em;
`
const Option = styled.option`
max-width: 100%;
overflow: hidden;
word-wrap: normal !important;
white-space: normal;
`

const Wrapper = styled.div`
  width: 99%;
  max-width: 700px;
  margin: 4em auto;

  table {
    width: 100%;
    position: relative;
    ${(props) => {
      if (props.amount > 10)
        return `
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;

    thead + tbody tr:last-child td:last-child,
    tfoot tr:last-child td:last-child {
      border-bottom-right-radius: 0px !important;
    }
    thead + tbody tr:last-child td:first-child,
    tfoot tr:last-child td:first-child {
      border-bottom-left-radius: 0px !important;
    }
    margin-bottom: 7em;
        `;
    }}
  }

  button {
    position: absolute;
    width: 100%;
    bottom: -27px;
     padding: 0.35em 1.2em;
     text-decoration: none;
     color: #fafafa;
     text-align: center;
     transition: all 0.2s;
    background-color: #4aabed;
    border: 1px solid #4aabed;
    cursor: pointer;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    &:hover {
      color: #222;
      background-color: #fafafa;
      border: 1px solid #d7d7d7;
    }
  }
`;

const SubTitle = styled.h2`
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1em;
`;

const AlertMessage = styled.p`
  color: #4aabed;
  font-weight: bold;
`;

export default class CustomerSummary extends Component {
  state = {
    data: {},
    loaded: false,
    showAll: false,
    openNew: 0,
    openNewVal: 0,
    openRepeat: 0,
    openRepeatVal: 0,
    closedNew: 0,
    closedNewVal: 0,
    closedRepeat: 0,
    closedRepeatVal: 0,
    discNew: 0,
    discNewVal: 0,
    discRepeat: 0,
    discRepeatVal: 0,
    newNew: 0,
    newNewVal: 0,
    newRepeat: 0,
    newRepeatVal: 0,
    firstTime: 0,
    firstTimeVal: 0,
    lost: 0,
    lostVal: 0,
    openRental: 0,
    openRentalVal: 0,
    openContract: 0,
    openContractVal: 0,
    repView: -1,
    brandView: "Rentacomputer.com"
  };

  // Async Axios
  fetchData = async () => {
    try {
      
      const response = await Axios.get('/admin-dashboard/customer-summary', {
        params: { rep: this.state.repView, brand: this.state.brandView },
        headers: { 'Content-Type': 'application/json' },
      });

      // Sets the state using the response data
      if (response){
        this.setState({
          data: response.data,
          loaded: true,
        }, () => this.prepData());
      }
      
      console.log(response)
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    this.fetchData();

  }

  prepData() {
    console.log(this.state.data)

    let openNew = this.state.data.result[0].recordset.length
    let openNewVal = 0
    let openRepeat = this.state.data.result[1].recordset.length
    let openRepeatVal = 0

    for(let i=0; i<openNew; i++){
      openNewVal = openNewVal + this.state.data.result[0].recordset[i].nSales
    }

    for(let i=0; i<openRepeat; i++){
      openRepeatVal = openRepeatVal + this.state.data.result[1].recordset[i].nSales
    }

    let closedNew = this.state.data.result[2].recordset.length
    let closedNewVal = 0
    let closedRepeat = this.state.data.result[3].recordset.length
    let closedRepeatVal = 0

    for(let i=0; i<closedNew; i++){
      closedNewVal = closedNewVal + this.state.data.result[2].recordset[i].nSales
    }

    for(let i=0; i<closedRepeat; i++){
      closedRepeatVal = closedRepeatVal + this.state.data.result[3].recordset[i].nSales
    }

    let discNew = this.state.data.result[4].recordset.length
    let discNewVal = 0
    let discRepeat = this.state.data.result[5].recordset.length
    let discRepeatVal = 0

    for(let i=0; i<discNew; i++){
      discNewVal = discNewVal + this.state.data.result[4].recordset[i].nSales
    }

    for(let i=0; i<discRepeat; i++){
      discRepeatVal = discRepeatVal + this.state.data.result[5].recordset[i].nSales
    }

    let newNew = this.state.data.result[6].recordset.length
    let newNewVal = 0
    let newRepeat = this.state.data.result[7].recordset.length
    let newRepeatVal = 0

    for(let i=0; i<newNew; i++){
      newNewVal = newNewVal + this.state.data.result[6].recordset[i].nSales
    }

    for(let i=0; i<newRepeat; i++){
      newRepeatVal = newRepeatVal + this.state.data.result[7].recordset[i].nSales
    }

    let lost = this.state.data.result[8].recordset.length
    let lostVal = 0

    for(let i=0; i<lost; i++){
      lostVal = lostVal + this.state.data.result[8].recordset[i].nSales
    }

    let openRental = this.state.data.result[9].recordset.length
    let openRentalVal = 0
    let openContract = this.state.data.result[10].recordset.length
    let openContractVal = 0

    for(let i=0; i<openRental; i++){
      openRentalVal = openRentalVal + this.state.data.result[9].recordset[i].nSales
    }

    for(let i=0; i<openContract; i++){
      openContractVal = openContractVal + this.state.data.result[10].recordset[i].nSales
    }



    this.setState({
      openNew: openNew,
      openNewVal: openNewVal,
      openRepeat: openRepeat,
      openRepeatVal: openRepeatVal,
      closedNew: closedNew,
      closedNewVal: closedNewVal,
      closedRepeat: closedRepeat,
      closedRepeatVal: closedRepeatVal,
      discNew: discNew,
      discNewVal: discNewVal,
      discRepeat: discRepeat,
      discRepeatVal: discRepeatVal,
      newNew: newNew,
      newNewVal: newNewVal,
      newRepeat: newRepeat,
      newRepeatVal: newRepeatVal,
      lost: lost,
      lostVal: lostVal,
      openRental: openRental,
      openRentalVal: openRentalVal,
      openContract: openContract,
      openContractVal: openContractVal,
    })

  }

  changeRepView = (event) => {
    this.setState({repView: event.target.value, loaded: false}, () => this.fetchData())
    console.log(event)
    
  }

  changeBrandView = (event) => {
    this.setState({brandView: event.target.value, loaded: false}, () => this.fetchData())

  }

  render() {


    return (
      <Wrapper>
        <Select onChange={(event) => this.changeRepView(event)} value={this.state.repView}>
          <Option value={-1}>All Reps</Option>
          <Option value={54}>Brian</Option>
          <Option value={51}>Mark</Option>
          <Option value={22}>Randy</Option>
          <Option value={8}>Tony C</Option>
          <Option value={64}>Jeff</Option>
        </Select>
        <Select onChange={(event) => this.changeBrandView(event)} value={this.state.brandView}>
          <Option value={"All"}>All</Option>
          <Option value={"Rentacomputer.com"}>Rentacomputer.com</Option>
          <Option value={"CameraSecurityNow.com"}>CameraSecurityNow.com</Option>
          <Option value={"ComputerServiceNow.com"}>ComputerServiceNow.com</Option>
        </Select>
        <SubTitle>Customer Summary</SubTitle>
        <table class="dashboard">
          <thead>
            <tr>
              <th>Category</th>
              <th>New #</th>
              <th>New $</th>
              <th>Repeat #</th>
              <th>Repeat $</th>
              <th>Total #</th>
              <th>Total $</th>
            </tr>
          </thead>
          <tbody>
            {this.state.loaded || (
              <tr>
                <td colSpan="3">
                  <Loader
                    style={{
                      textAlign: 'center',
                    }}
                    type="ThreeDots"
                    color="#00BFFF"
                    height={20}
                    width={120}
                  />
                </td>
              </tr>
            )}
            {this.state.loaded && (
                  <>

                  <tr>
                    <td>Open Quotes</td>
                    <td>{this.state.openNew}</td>
                    <td>{nQuoteTotalFormatter(this.state.openNewVal)}</td>
                    <td>{this.state.openRepeat}</td>
                    <td>{nQuoteTotalFormatter(this.state.openRepeatVal)}</td>
                    <td>{this.state.openNew+this.state.openRepeat}</td>
                    <td>{nQuoteTotalFormatter(this.state.openNewVal+this.state.openRepeatVal)}</td>
                  </tr>
                  <tr>
                    <td>Closed Quotes</td>
                    <td>{this.state.closedNew}</td>
                    <td>{nQuoteTotalFormatter(this.state.closedNewVal)}</td>
                    <td>{this.state.closedRepeat}</td>
                    <td>{nQuoteTotalFormatter(this.state.closedRepeatVal)}</td>
                    <td>{this.state.closedNew+this.state.closedRepeat}</td>
                    <td>{nQuoteTotalFormatter(this.state.closedNewVal+this.state.closedRepeatVal)}</td>
                  </tr>
                  <tr>
                    <td>Discontinued Quotes</td>
                    <td>{this.state.discNew}</td>
                    <td>{nQuoteTotalFormatter(this.state.discNewVal)}</td>
                    <td>{this.state.discRepeat}</td>
                    <td>{nQuoteTotalFormatter(this.state.discRepeatVal)}</td>
                    <td>{this.state.discNew+this.state.discRepeat}</td>
                    <td>{nQuoteTotalFormatter(this.state.discNewVal+this.state.discRepeatVal)}</td>
                  </tr>
                  <tr>
                    <td>New Quotes</td>
                    <td>{this.state.newNew}</td>
                    <td>{nQuoteTotalFormatter(this.state.newNewVal)}</td>
                    <td>{this.state.newRepeat}</td>
                    <td>{nQuoteTotalFormatter(this.state.newRepeatVal)}</td>
                    <td>{this.state.newNew+this.state.newRepeat}</td>
                    <td>{nQuoteTotalFormatter(this.state.newNewVal+this.state.newRepeatVal)}</td>
                  </tr>

                  </>
                )}
            
            
          </tbody>
        </table>

        <table class="dashboard">
          <thead>
            <tr>
              <th>Category</th>
              <th>Customers</th>
              <th>Total $</th>
            </tr>
          </thead>
          <tbody>
            {this.state.loaded || (
              <tr>
                <td colSpan="3">
                  <Loader
                    style={{
                      textAlign: 'center',
                    }}
                    type="ThreeDots"
                    color="#00BFFF"
                    height={20}
                    width={120}
                  />
                </td>
              </tr>
            )}
            {this.state.loaded && (
                  <>

                  <tr>
                    <td>First Time Customers</td>
                    <td>{this.state.closedNew}</td>
                    <td>{nQuoteTotalFormatter(this.state.closedNewVal)}</td>
                  </tr>
                  <tr>
                    <td>Lost Customers</td>
                    <td>{this.state.lost}</td>
                    <td>{nQuoteTotalFormatter(this.state.lostVal)}</td>
                  </tr>
                  <tr>
                    <td>Open Rentals</td>
                    <td>{this.state.openRental}</td>
                    <td>{nQuoteTotalFormatter(this.state.openRentalVal)}</td>
                  </tr>
                  <tr>
                    <td>Open Contracts</td>
                    <td>{this.state.openContract}</td>
                    <td>{nQuoteTotalFormatter(this.state.openContractVal)}</td>
                  </tr>

                  </>
                )}
          </tbody>
        </table>
      </Wrapper>
    );
  }
}
