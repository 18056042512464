import React, { Component } from 'react';
import { Wrapper, ButtonContainer, ViewButton } from '../tables/marketing-wizard/StyledComponents';
import CallLog from '../tables/marketing-dashboard/CallLog';
import MarketingProfileWizard from '../tables/marketing-dashboard/MarketingProfileWizard';
import EmailCampaignTool from '../tables/marketing-dashboard/EmailCampaignTool';
import ContactsList from '../tables/marketing-dashboard/ContactsList';
import MarketingEffortsSummary from '../tables/marketing-dashboard/MarketingEffortsSummary';
import MarketingSearch from '../tables/marketing-dashboard/MarketingSearch';

class MarketingDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewing: "Marketing Profile Wizard",
        };
    }

    render() {
        // Placeholder for the numerical representative ID, replace with actual data if available
        const nRep = 54;  // Example representative ID for Brian Kubala

        return (
            <Wrapper>
                <ButtonContainer>
                    <ViewButton onClick={() => this.setState({ viewing: "Marketing Profile Wizard" })}>
                        Marketing Profile Wizard
                    </ViewButton>
                    <ViewButton onClick={() => this.setState({ viewing: "Import Call Log" })}>
                        Import Call Log
                    </ViewButton>
                    <ViewButton onClick={() => this.setState({ viewing: "Email Campaign Tool" })}>
                        Email Campaign Tool
                    </ViewButton>
                    <ViewButton onClick={() => this.setState({ viewing: "Contacts List" })}>
                        Contacts List
                    </ViewButton>
                    <ViewButton onClick={() => this.setState({ viewing: "Marketing Efforts Summary" })}>
                        Marketing Efforts Summary
                    </ViewButton>
                    <ViewButton onClick={() => this.setState({ viewing: "Marketing Search" })}>
                        Search Contacts
                    </ViewButton>
                </ButtonContainer>

                {this.state.viewing === "Import Call Log" && <CallLog />}
                {this.state.viewing === "Marketing Profile Wizard" && <MarketingProfileWizard />}
                {this.state.viewing === "Email Campaign Tool" && <EmailCampaignTool />}
                {this.state.viewing === "Contacts List" && <ContactsList nRep={nRep} />} {/* Pass the numerical nRep */}
                {this.state.viewing === "Marketing Efforts Summary" && <MarketingEffortsSummary />}
                {this.state.viewing === "Marketing Search" && <MarketingSearch />}
            </Wrapper>
        );
    }
}

export default MarketingDashboard;
