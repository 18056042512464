import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 99%;
  max-width: 1300px;
  margin: 4em auto;
`;

export const SubTitle = styled.h2`
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1em;
`;

export const StyledTable = styled.table`
  width: 100%;
  position: relative;
  border-collapse: collapse;
  thead th {
    background-color: #000397;
    color: white;
  }
  tbody tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
  tbody tr:nth-child(even) {
    background-color: #ffffff;
  }
`;

export const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  &::after {
    content: " ";
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 6px solid #4aabed;
    border-color: #4aabed transparent #4aabed transparent;
    animation: spin 1.2s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const ActionButton = styled.button`
  margin-left: 10px;
  padding: 8px 16px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 0.9rem;
  cursor: pointer;
  &:hover {
    background-color: #218838;
  }
`;

export const EditActionButton = styled.button`
  margin-left: 10px;
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 0.9rem;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px; 
  button {
    margin-left: 10px; /* Adds space between buttons */
  }
`;


export const StyledButton = styled.button`
  margin-top: 1em;
  padding: 0.5em 1.5em;
  background-color: #4aabed;
  border: 1px solid #4aabed;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: #fafafa;
    color: #222;
    border-color: #d7d7d7;
  }
  &:not(:last-child) {
    margin-right: 10px;
  }
`;

export const Button = styled.button`
  padding: 10px 20px;
  background-color: #4aabed;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: #357ab8;
  }
`;

export const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  margin-right: 10px;
  color: #000397;
  &:hover {
    color: #4aabed;
  }
`;

export const EmailPreview = styled.div`
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  &:hover {
    background-color: #f0f0f0;
  }
  p {
    margin: 0;
  }
`;

export const ViewButton = styled.button`
  padding: 0.75em 1.5em;
  background-color: #4aabed;
  border: 1px solid #4aabed;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1.1rem;
  font-weight: bold;
  text-transform: uppercase;
  &:hover {
    background-color: #fafafa;
    color: #222;
    border-color: #d7d7d7;
  }
  &:not(:last-child) {
    margin-right: 10px;
  }
`;

export const HoverModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  max-width: 600px;
  width: 100%;
  overflow-y: auto;
  max-height: 90vh;
`;

export const HoverModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

export const HoverModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
`;

export const HoverModalTitle = styled.h2`
  margin: 0;
  font-size: 1.5rem;
`;

export const HoverCloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
  &:hover {
    color: #000;
  }
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
  &:hover {
    color: #000;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  max-width: 500px;
  width: 100%;
`;

// Added ModalContent
export const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
`;

export const ModalTitle = styled.h2`
  margin: 0;
  font-size: 1.5rem;
`;

export const ModalBody = styled.div`
  margin-top: 20px;
`;

export const ContactList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const ContactItem = styled.li`
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  input, select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  button {
    margin-left: 10px;
    padding: 10px 15px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
  }
`;

export const InputField = styled.input`
  padding: 10px;
  width: 300px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
`;

export const ResultItem = styled.div`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  &:hover {
    background-color: #f9f9f9;
  }
`;

export const ResultsContainer = styled.div`
  margin-top: 20px;
  width: 80%;
`;

export const SearchContainer = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const EditButton = styled.button`
  background-color: #4aabed;
  border: 1px solid #4aabed;
  color: white;
  &:hover {
    background-color: #3a9bdb;
  }
`;

export const DeleteButton = styled.button`
  background-color: #ed4a4a;
  border: 1px solid #ed4a4a;
  color: white;
  &:hover {
    background-color: #db3a3a;
  }
`;

export const SaveButton = styled.button`
  background-color: #4aabed;
  border: 1px solid #4aabed;
  color: white;
  &:hover {
    background-color: #3a9bdb;
  }
`;
