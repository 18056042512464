import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin: 1em 0 0 0.5em;
  width: 50%;
  height: 220px;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.1);
  text-align: center;
  border-radius: 15px;

  input {
    width: 70%;
    padding: 0.7em;
    margin: 0.5em auto;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  p {
    font-weight: bold;
    text-align: left;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4aabed;
  color: #fff;
  height: 35px;
  font-weight: bold;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
  margin: 1.2em auto 0 auto;

  div {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
  }

  select {
    width: 50%;
    padding: 0.5em;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
`;

export default class AdditionalInformation extends Component {
  render() {
    let paymentMethod;

    if (this.props.bCC === true) {
      paymentMethod = '1';
    } else if (this.props.bNet === true) {
      paymentMethod = '2';
    } else if (this.props.bCOD === true) {
      paymentMethod = '3';
    }

    return (
      <Wrapper>
        <Title>
          <p>Additional Information</p>
        </Title>
        <Container>
          <InputWrapper>
            <p>Payment Method:</p>
            <select
              id="paymentMethod"
              name="paymentMethod"
              defaultValue={paymentMethod}
              // value={paymentMethod}
            >
              <option value="Select Payment Method" hidden>
                Select Payment Method
              </option>
              <option value="1">Credit Card</option>
              <option value="2">Net Terms</option>
              <option value="3">Prepay Check</option>
            </select>
          </InputWrapper>
          <InputWrapper>
            <p>Contract Type:</p>
            <select
              id="contractType"
              name="contractType"
              defaultValue={this.props.nContractType}
              // value={this.props.nContractType}
            >
              <option value="0" hidden>
                Select Contract Type
              </option>
              <option value="1">Fixed Rental</option>
              <option value="2">Open Rental</option>
              <option value="3">Non-Rental</option>
            </select>
          </InputWrapper>
          <InputWrapper>
            <p>Delivery Type:</p>
            <select
              id="deliveryType"
              name="deliveryType"
              defaultValue={this.props.nDeliveryType}
              // value={this.props.nDeliveryType}
            >
              <option value="0" hidden>
                Select Delivery Type
              </option>
              <option value="1">Delivery</option>
              <option value="2">Shipping</option>
            </select>
          </InputWrapper>
        </Container>
      </Wrapper>
    );
  }
}
