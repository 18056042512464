import styled from "styled-components";

export const SubTitle = styled.h2`
  font-size: 1.2rem;
  margin: 1.5em 0.5em 1em 0.5em;
  text-align: center;
  width: 80%;
`;
export const SideBarLinkMobile = styled.a`
  width: 100%;
  position: relative;
  padding: 0.2em 1em;
  margin: 0.2em 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 5px;
  transition: box-shadow 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.3);
  background-color: #fff;

  &:hover {
    color: #222;
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  }
`;

export const SideBarItem = styled.p`
  padding: 0.3em 0;
`;

export const TopNavbar = styled.nav`
  display: flex;
  height: 3em;
  width: 100vw;
  position: fixed;
  z-index: 20;
  top: 0;
  align-items: center;
  text-align: center;
  flex-direction: row;
  background-color: #36a2eb;
  color: #fff;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.15);
`;

export const BottomNavbar = styled.nav`
  display: flex;
  height: 3.2em;
  width: 100vw;
  position: fixed;
  align-items: center;
  z-index: 21;
  bottom: 0;
  text-align: center;

  background-color: #36a2eb;
  color: #fff;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.15);
`;

export const TopUl = styled.ul`
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0;
  flex-direction: row;
  list-style-type: none;
  justify-content: flex-end;

  > li {
    margin-left: 1em;
    margin-right: 1em;
    font-size: 1.5rem;
  }
`;

export const BottomUl = styled.ul`
  display: flex;
  flex: 1;
  width: 100%;
  margin: 0;
  padding: 0;
  flex-direction: row;
  list-style-type: none;
  justify-content: flex-end;

  > li {
    flex: 1;
    font-size: 1.5rem;
  }
`;

export const MobilePopUpMenuWrapper = styled.div`
  overflow-y: scroll;
`;

export const CloseLine = styled.span`
  height: 2px;
  width: 10px;
  font-size: 2rem;
  background-color: black;
  position: relative;
  top: 0;
`;