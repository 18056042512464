import moment from "moment"

export function percentTwoDecimal(value){return Math.round(value*10000)/100+'%'}

export function daysLate(complete, target){
    let diff = moment(complete).diff(target,'days')
    if(diff>1000){return ""}
    if(diff>1 && diff<=1000){return diff + " days late"}
    if(diff==1){return "1 day late"}
    if(diff==0){return "On Time"}
    if(diff==-1){return "1 day early"}
    if(diff<-1){return -diff + " days early"}
}

export function numberOfDays(number){
    if(number == 1){return number + " day"}else{return number + " days"}
}

export function formatAbbreviatedDollar(number){
    let formattedNumber = ""
    number = parseInt(number)
    if(number < 1000000){
        formattedNumber = "$" + number.toLocaleString()
    } else if(number < 1000000000) { 
        formattedNumber = "$" + (number/1000000).toLocaleString() + " M"
    } else if(number < 1000000000000) {
        formattedNumber = "$" + (number/1000000000).toLocaleString() + " B"
    } else {
        formattedNumber = "$" + (number/1000000000000).toLocaleString() + " T"
    }

    return formattedNumber
}