import React, { Component } from 'react'
import { getSQLData } from '../../../assets/functions/fetch';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { whichRep } from '../../../assets/functions/calculations';

export default class EmailCampaignTool extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loaded: false,
          callListReady: false,
          callListHeaders: [],
        };
        this.matchData = this.matchData.bind(this)
      }

    componentDidMount() {
    this.fetchData()
    }

    fetchData = async() => {
        try {
            const fetchOpen = await getSQLData("marketing/racOpenQuotes", {})
            const fetchCustomers = await getSQLData("marketing/racCustomers3Y", {})
            const fetchRecentQuotes = await getSQLData("marketing/racLastWeekQuotes", {})
            //const fetchDiscontinuedQuotes = await getSQLData("marketing/racDiscontinuedQuotes", {})
            const fetchCustomerQuoteData = await getSQLData("marketing/racCustomerQuoteData", {})

            if(fetchOpen && fetchCustomers && fetchRecentQuotes && fetchCustomerQuoteData){
                let openCustomers = fetchOpen.data.data[0].recordset
                let pastCustomers = fetchCustomers.data.data[0].recordset
                let recentCustomers = fetchRecentQuotes.data.data[0].recordset
                //let discontinuedCustomers = fetchDiscontinuedQuotes.data.data[0].recordset
                let customerQuoteData = fetchCustomerQuoteData.data.data[0].recordset

                let openCustomerHeaders = fetchOpen.query.fields
                let pastCustomerHeaders = fetchCustomers.query.fields
                let recentCustomerHeaders = fetchRecentQuotes.query.fields
                //let discontinuedCustomerHeaders = fetchDiscontinuedQuotes.query.fields
                let customerQuoteDataHeaders = fetchCustomerQuoteData.query.fields


                this.setState({
                    openCustomers: openCustomers,
                    pastCustomers: pastCustomers,
                    recentCustomers: recentCustomers,
                    //discontinuedCustomers: discontinuedCustomers,
                    customerQuoteData: customerQuoteData,
                    openCustomerHeaders: openCustomerHeaders,
                    pastCustomerHeaders: pastCustomerHeaders,
                    recentCustomerHeaders: recentCustomerHeaders,
                    //discontinuedCustomerHeaders: discontinuedCustomerHeaders,
                    customerQuoteDataHeaders: customerQuoteDataHeaders,
                }, () => this.processData())
            }


        } catch (error) {

        }
    }

    processData() {
        let openCustomers = this.state.openCustomers
        let pastCustomers = this.state.pastCustomers
        let recentCustomers = this.state.recentCustomers
        //let discontinuedCustomers = this.state.discontinuedCustomers
        let customerQuoteData = this.state.customerQuoteData
        let lostCustomers = []
        let lostCustoffDate = moment().subtract(2, 'years')
        let inactiveCutoffDate = moment().subtract(1, 'years')

        for(let i=0; i<customerQuoteData.length; i++){
            let isInactive = moment(customerQuoteData[i].LastUpdate).diff(inactiveCutoffDate) < 0
            let isLost = moment(customerQuoteData[i].LastSale).diff(lostCustoffDate) < 0
            if(isLost && isInactive){
                customerQuoteData[i].LastUpdate = moment(customerQuoteData[i].LastUpdate).format("MM/DD/YYYY")
                customerQuoteData[i].LastSale = moment(customerQuoteData[i].LastSale).format("MM/DD/YYYY")
                customerQuoteData[i].Rep = whichRep(customerQuoteData[i].Rep)
                lostCustomers.push(customerQuoteData[i])
            }
        }

        lostCustomers.sort((a,b) => b.Sales - a.Sales)

        this.setState({
            openCustomers: openCustomers,
            pastCustomers: pastCustomers,
            recentCustomers: recentCustomers,
            //discontinuedCustomers: discontinuedCustomers,
            customerQuoteData: customerQuoteData,
            lostCustomers: lostCustomers,
            loaded: true,
        })
    }

    uploadZoomInfo = (e) => {
        this.setState({zoomInfo: e.target.files[0]})
      }
    uploadHubSpot = (e) => {
        this.setState({hubSpot: e.target.files[0]})
      }

      handleOnSubmit = () => {
        const zoomInfoReader = new FileReader();
        const hubSpotReader = new FileReader();
        let zoomInfo = this.state.zoomInfo
        let hubSpot = this.state.hubSpot


        zoomInfoReader.onload = function (file, name) {
            const text = file.target.result
            const rows = text.split('\n')
            const headers = rows[0].split(',')
            const data = []

            for(let i=0; i<headers.length; i++){
                if(headers[i].substring(0,13) == "Contact Owner"){
                    headers[i]="Contact Owner"
                    
                }
            }

            for(let i=1; i<rows.length; i++){
                let call = {}
                let row = rows[i].split(',')
                for(let j=0; j<headers.length; j++){
                    call[headers[j]] = row[j]
                }
                data.push(call)
            }

            this.setState({
                zoomInfoData: data,
            })
        }.bind(this)

        hubSpotReader.onload = function (file, name) {
            const text = file.target.result
            const rows = text.split('\n')
            const headers = rows[0].split(',')
            headers[headers.length-1] = "Unsubscribed"
            const data = []

            for(let i=1; i<rows.length; i++){
                let call = {}
                let row = rows[i].split(',')
                for(let j=0; j<headers.length; j++){
                    call[headers[j]] = row[j]
                }
                data.push(call)
            }

            this.setState({
                hubSpotData: data,
            })
        }.bind(this)

        

        zoomInfoReader.readAsText(zoomInfo)
        hubSpotReader.readAsText(hubSpot)

        this.setState({combined: true})
      }

      matchData() {
        let zoomInfoData = this.state.zoomInfoData
        let hubSpotData = this.state.hubSpotData
        let callListData = []

        if(zoomInfoData && hubSpotData){
            for(let i=0; i<zoomInfoData.length; i++){
                let openRecord = hubSpotData.find(x => x["Recipient"] === zoomInfoData[i]["Email Address"])
                if(openRecord && openRecord["Unsubscribed"].charAt(0) !== "t"){
                    console.log(zoomInfoData[i])
                    let newRecord = {
                        "Rep": zoomInfoData[i]["Contact Owner"],
                        "Name": zoomInfoData[i]["First Name"] + " " + zoomInfoData[i]["Last Name"],
                        "Job Title": zoomInfoData[i]["Job Title"],
                        "Direct Phone": zoomInfoData[i]["Direct Phone Number"],
                        "Email Address": zoomInfoData[i]["Email Address"],
                        "Company Name": zoomInfoData[i]["Company Name"],
                        "Revenue Range": zoomInfoData[i]["Revenue Range (in USD)"],
                        "Employees": zoomInfoData[i]["Employees"],
                        "Primary Industry": zoomInfoData[i]["Primary Industry"],
                        "Full Address": zoomInfoData[i]["Full Address"],
                        "Campaign": zoomInfoData[i]["Campaign"],
                    }
                    callListData.push(newRecord)
                    
                }
            }
            const callListHeaders = [
                {key: "Rep", label: "Rep"},
                {key: "Name", label: "Name"},
                {key: "Job Title", label: "Job Title"},
                {key: "Direct Phone", label: "Direct Phone"},
                {key: "Email Address", label: "Email Address"},
                {key: "Company Name", label: "Company Name"},
                {key: "Revenue Range", label: "Revenue Range"},
                {key: "Employees", label: "Employees"},
                {key: "Primary Industry", label: "Primary Industry"},
                {key: "Full Address", label: "Full Address"},
                {key: "Campaign", label: "Campaign"},
            ]

            this.setState({
                callListData: callListData, 
                callListReady: true, 
                callListHeaders: callListHeaders,
            })
        }

        
      }

    render() {
        let loaded = this.state.loaded
        let openCustomers = this.state.openCustomers
        let pastCustomers = this.state.pastCustomers
        let recentCustomers = this.state.recentCustomers
        //let discontinuedCustomers = this.state.discontinuedCustomers
        let lostCustomers = this.state.lostCustomers
        let callListData = this.state.callListData

        let openCustomerHeaders = this.state.openCustomerHeaders
        let pastCustomerHeaders = this.state.pastCustomerHeaders
        let recentCustomerHeaders = this.state.recentCustomerHeaders
        //let discontinuedCustomerHeaders = this.state.discontinuedCustomerHeaders
        let customerQuoteDataHeaders = this.state.customerQuoteDataHeaders
        let callListHeaders = this.state.callListHeaders

        let todaysDate = moment().format("MMDDYY")
        return (
            <>
                {loaded && (
                    <div>
                        <br /><br />
                        <div><button>
                        <CSVLink data={openCustomers} headers={openCustomerHeaders} filename={todaysDate + " Open.csv"}>
                            Companies with Open Quotes
                        </CSVLink>
                        </button></div>
                        <div><button>
                        <CSVLink data={pastCustomers} headers={pastCustomerHeaders} filename={todaysDate + " Past.csv"}>
                            Companies with Closed Quotes in last 3 years
                        </CSVLink>
                        </button></div>
                        <div><button>
                        <CSVLink data={recentCustomers} headers={recentCustomerHeaders} filename={todaysDate + " Recent.csv"}>
                            Companies who filled out a Quote Request in the last week
                        </CSVLink>
                        </button></div>
                        {/*<div><button>
                        <CSVLink data={discontinuedCustomers} headers={discontinuedCustomerHeaders} filename={todaysDate + " Discontinued.csv"}>
                            Companies who have a Discontinued Quote between 11-36 months ago
                        </CSVLink>
                        </button></div>*/}
                        <div><button>
                        <CSVLink data={lostCustomers} headers={customerQuoteDataHeaders} filename={todaysDate + " Lost Customers.csv"}>
                            Lost Customers
                        </CSVLink>
                        </button></div>
                        <br />
                        <div>
                        {!(this.state.zoomInfo && this.state.zoomInfo.name) && (<form>
                            Upload ZoomInfo Export:&nbsp;&nbsp;
                            <input type={"file"} id={"csvFileInput"} accept={".csv"} onChange={this.uploadZoomInfo} />
                        </form>)}
                        {!(this.state.hubSpot && this.state.hubSpot.name) && (<form>
                            Upload HubSpot Export:&nbsp;&nbsp;
                            <input type={"file"} id={"csvFileInput"} accept={".csv"} onChange={this.uploadHubSpot} />
                            
                        </form>)}
                        {(this.state.zoomInfo && this.state.zoomInfo.name) && (this.state.hubSpot && this.state.hubSpot.name) && !this.state.combined && (
                        <div>
                            <button onClick={(e) => {this.handleOnSubmit()}}>Combine Data</button>
                        </div>)}
                        {this.state.combined && !this.state.callListReady && (
                            <div>
                                <button onClick={()=>this.matchData()}>
                                    Create File
                                </button>
                            </div>
                        )}
                        {this.state.callListReady && (
                            <div>
                                <button>
                                <CSVLink data={callListData} headers={callListHeaders} filename={todaysDate + " Call List.csv"}>
                                    Download Call List
                                </CSVLink>
                                </button>
                            </div>
                        )}
                        </div>
                    </div>
                )}
            </>
        )
    }
}
