import React, { Component } from "react";
import Axios from "axios";
import styled from "styled-components";
import Loader from "react-loader-spinner";
import Moment from "moment";
import { whichRep } from "../../../assets/functions/calculations";

const Wrapper = styled.div`
  width: 99%;
  max-width: 1300px;
  margin: 4em auto;

  table {
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;

    thead + tbody tr:last-child td:last-child,
    tfoot tr:last-child td:last-child {
      border-bottom-right-radius: 15px !important;
    }
    thead + tbody tr:last-child td:first-child,
    tfoot tr:last-child td:first-child {
      border-bottom-left-radius: 15px !important;
    }
`;

const SubTitle = styled.h2`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1em;

  i {
    font-size: 16px;
    margin-left: 7px;
  }
`;

function calculateSales(beforeSalesTax, beforeTotalCost) {
  let total = ((beforeSalesTax - beforeTotalCost) / beforeSalesTax) * 100;

  if (isNaN(total)) {
    return 0;
  } else {
    return total;
  }
}
export default class SalesRepContest extends Component {
  state = {
    data: [],
    loaded: false,
  };

  // Async Axios
  fetchData = async () => {
    try {
      const response = await Axios.get("/rac-dashboard/sales-rep-contest", {
        params: {
          startDate: "2021-10-01 00:00",
          endDate: "2021-10-31 23:59",
        },
        headers: { "Content-Type": "application/json" },
      });
      // Sets the state using the response data
      this.setState({
        data: Object.entries({
          54: calculateSales(
            response.data.result[0].recordset[0].brianBeforeTaxSales,
            response.data.result[0].recordset[0].brianTotalCost
          ),
          51: calculateSales(
            response.data.result[0].recordset[0].markBeforeTaxSales,
            response.data.result[0].recordset[0].markTotalCost
          ),

          22: calculateSales(
            response.data.result[0].recordset[0].randyBeforeTaxSales,
            response.data.result[0].recordset[0].randyTotalCost
          ),

          8: calculateSales(
            response.data.result[0].recordset[0].tonyCBeforeTaxSales,
            response.data.result[0].recordset[0].tonyCTotalCost
          ),
        }).sort((a, b) => b[1] - a[1]),
        loaded: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    this.fetchData();
  }

  render() {
    console.log(this.state.data)
    let table = (
      <>
        {this.state.data.map((sql, index) => (
          <tr>
            <td style={{ textAlign: "center" }}>{index + 1}</td>
            <td style={{ textAlign: "center" }}>
              {whichRep(parseInt(sql[0]))}
            </td>
            <td style={{ textAlign: "center" }}>{sql[1].toFixed(2)}%</td>
          </tr>
        ))}
      </>
    );

    return (
      <Wrapper>
        <SubTitle>Sales Rep Contest</SubTitle>
        <table className="dashboard">
          <thead>
            <tr>
              <th>Position</th>
              <th>Name</th>
              <th>Sales Margin</th>
            </tr>
          </thead>
          <tbody>
            {this.state.loaded || (
              <tr>
                <td colSpan="8">
                  <Loader
                    style={{
                      textAlign: "center",
                    }}
                    type="ThreeDots"
                    color="#00BFFF"
                    height={80}
                    width={80}
                  />
                </td>
              </tr>
            )}
            {this.state.loaded && table}
          </tbody>
        </table>
      </Wrapper>
    );
  }
}
